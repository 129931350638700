import { useState } from 'react';

import resourcePersistence from 'persistence/resourcePersistence';
import useStatePersistence from 'helpers/useStatePersistence';

const useResource = (cacheKey, fetchFunc, defaultValue, options = {}) => {
  const { noFetchIfCached } = options;
  const [value, setValue] = useStatePersistence(resourcePersistence, cacheKey, defaultValue);

  const fetch = async () => {
    if (resourcePersistence.get(cacheKey) !== null && noFetchIfCached) {
      return;
    }

    const fetchedValue = await fetchFunc();
    setValue(fetchedValue);
  };

  return [fetch, value, setValue];
};

export default useResource;
