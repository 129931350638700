import Page from 'components/Page';
import React from 'react';
import { Card, Container, Typography } from '@mui/material';
import Breadcrumbs from 'components/Breadcrumbs';
import { CardContent } from '@material-ui/core';
import AllMappedIssuersTable from 'components/masterData/CreditInsurer/AllMappedIssuersTable';

const AllMappedIssuers = () => {
  return (
    <Page title="All Mapped Issuers">
      <Container>
        <Typography
          sx={{
            fontSize: '1.125rem',
            fontWeight: 'bold',
            mt: '1rem'
          }}
        >
          All Mapped Issuers
        </Typography>
        <Card sx={{ mt: 2 }}>
          <CardContent>
            <AllMappedIssuersTable />
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
};

export default AllMappedIssuers;
