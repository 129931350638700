import React from 'react';
import { Box, Card, CardContent, Typography, IconButton, Button } from '@mui/material';
import { useNavigate } from 'react-router';

const EntityCard = ({ title, buttonName, buttonRoute, count, icon }) => {
  const navigate = useNavigate();
  return (
    <Box>
      <Card
        sx={{
          height: '260px',
          width: '270px',
          borderRadius: '6px'
        }}
      >
        <CardContent>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexDirection: 'row'
            }}
          >
            <Box
              sx={{
                m: 1,
                height: '60px',
                width: '60px',
                borderRadius: '6px',
                backgroundColor: '#fff',
                color: '#000',
                boxShadow: '0 18px 16px 0 rgba(0, 0, 0, 0.16)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              {icon}
            </Box>
            <Button variant="gradient" onClick={() => navigate(buttonRoute)}>
              {buttonName}
            </Button>
          </Box>

          <Card
            sx={{
              mt: 2,
              backgroundColor: '#f5f8fa',
              height: '116px',
              boxShadow: 'none',
              borderRadius: '4px'
            }}
          >
            <CardContent sx={{ p: 2 }}>
              <Typography sx={{ fontSize: '1.125rem', color: '#161c2d', fontWeight: 600 }}>
                {title}
              </Typography>
              <Typography sx={{ fontSize: '2.375rem', color: '#161646' }}>{count}</Typography>
            </CardContent>
          </Card>
        </CardContent>
      </Card>
    </Box>
  );
};

export default EntityCard;
