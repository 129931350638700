import React, { useEffect } from 'react';
import EnhancedTable from 'components/tables/EnhancedTable';
import { Chip } from '@mui/material';
import { useBusinessEntityState } from 'state/useBusinessEntityState';
import { getChipColor } from 'helpers/handler';

export default function InsurerTokenTable({ fetchData: _fetchData }) {
  const { insurerIssuerTokens, fetchInsurerIssuerTokens, currCreditIssuer, currCreditInsurer } =
    useBusinessEntityState();

  // const _fetchData = async () => {
  //   await fetchInsurerIssuerTokens(currCreditIssuer.issuer_id, currCreditInsurer.insurer_id);
  // };

  // useEffect(() => {
  //   _fetchData();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);
  const headCells = [
    {
      accessor: 'token_name',
      Header: 'Name',
      show: true,
      width: 110
    },
    {
      accessor: 'issue_type_id.issue_type_name',
      Header: 'Issue Type',
      show: true
    },
    {
      accessor: 'token_currency.currency_code',
      Header: 'Currency',
      show: true,
      width: 110
    },
    {
      accessor: 'issue_size',
      Header: 'Issue Size',
      show: true
    },

    {
      accessor: 'token_issue_price',
      Header: 'Issue Price',
      show: true
    },
    {
      accessor: 'total_supply',
      Header: 'Supply',
      show: true,
      width: 120
    },
    {
      accessor: 'token_status_id.token_status',
      Header: 'Status',
      Cell: ({ value }) => {
        return <Chip label={value} color={getChipColor(value)} />;
      },
      show: true
    }
  ];
  return (
    <>
      <EnhancedTable
        tableTitle="Token"
        columns={headCells}
        refreshFunction={_fetchData}
        data={insurerIssuerTokens}
      />
    </>
  );
}
