import React, { useEffect, useState } from 'react';
import EnhancedTable from 'components/tables/EnhancedTable';
import { Box, IconButton, Button, Typography } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { ethToXdcAddress } from 'helpers/web3';
import { HeaderName } from 'helpers/tableHelper';
import {
  useAppState,
  useAuthStateShared,
  useBusinessEntityState,
  useHttpApi,
  useWeb3
} from 'state';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import rows from '../../mocks/Issuers.json';
import { useSnackbar } from 'notistack';
import NAIcon from 'components/NAIcon';
import { LoadingButton } from '@mui/lab';
import LoadingActionButton from 'helpers/LoadingActionButton';

const InvestorName = HeaderName('Investor Name');
const RedemptionDueDate = HeaderName('Redemption Due Date');

const ActionButtons = ({ row, account }) => {
  // const { paymentTokenGiveAllowance, swapRedeem } = useWeb3();
  const { tvtSwapRedeemContractApi } = useHttpApi();
  const { enqueueSnackbar } = useSnackbar();
  const { throwErrorMessage } = useAppState();
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          m: 0,
          p: 0,
          alignItems: 'center'
        }}
      >
        {/*   <Button
          variant="contained"
          color="success"
          sx={{ mb: 1, mr: 1 }}
          onClick={() => {
            // console.log(issuerId);
          }}
        >
          <Typography sx={{ fontSize: '12px' }}>Notify Investor</Typography>
        </Button> */}
        <LoadingActionButton
          loadingPosition="start"
          variant="contained"
          color="secondary"
          sx={{ ml: 1 }}
          onClick={async () => {
            console.log('row', row);
            try {
              const data = {
                escrow_order_id: row.escrow_order_id,
                investor_address: account
              };

              // await swapRedeem(row.escrow_order_id);
              await tvtSwapRedeemContractApi(row.redemption_escrow_id, data);
              enqueueSnackbar('Swap Redeem Started!', { variant: 'info' });
            } catch (e) {
              throwErrorMessage(e);
            }
          }}
        >
          <Typography sx={{ fontSize: '12px' }}>Initiate TvT </Typography>
        </LoadingActionButton>
      </Box>
    </>
  );
};

const IssuerEscrowStatus = ({ status }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      {status ? <CheckCircleIcon color="success" /> : <CancelIcon color="error" />}
    </Box>
  );
};

const SubcriberEscrowStatus = ({ status }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      {status ? <CheckCircleIcon color="success" /> : <CancelIcon color="error" />}
    </Box>
  );
};

export default function InvestorRedemptionEscrowTable({ poolAddr }) {
  const [data, setData] = useState([]);

  const [skipPageReset, setSkipPageReset] = React.useState(false);
  const [owner, setOwner] = useState(false);
  const { role } = useAuthStateShared();
  const { account, active, getRedemptionEscrowConditions } = useWeb3();
  const {
    fetchAllRedemptionEscrowByInvestorAddress: redemptionEscrowByAddr,
    investorRedemptionEscrow: rows
  } = useBusinessEntityState();

  const fetchAccount = poolAddr || account;

  useEffect(() => {
    if (role === 'OWNER') {
      setOwner(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Fetch all redemption escrow
  const _fetchData = async () => {
    await redemptionEscrowByAddr(fetchAccount);
  };
  useEffect(() => {
    _fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [poolAddr, account]);

  const headCells = [
    {
      accessor: 'issuer_name',
      Header: 'Issuer Name',
      width: 150,
      show: true
    },
    {
      accessor: 'token_symbol',
      Header: 'Token Symbol',
      width: 200,
      show: true
    },
    {
      accessor: 'trade_token_amount',
      Header: 'Alloted Qty.',
      width: 100,
      show: true
    },
    {
      accessor: 'payment_token.contract_name',
      Header: 'Payment',
      width: 110,
      show: true,
      Cell: ({ value }) => (value ? <>{value}</> : 'CASH')
    },
    // {
    //   accessor: 'payment_token_amount',
    //   Header: 'Payment token(Value)',
    //   width: 120,
    //   show: true,
    //   Cell: ({ value }) => (value ? <>{value}</> : 'CASH')
    // },
    // {
    //   accessor: 'collateral_value',
    //   Header: 'Collateral Amount(XDC)',
    //   width: 110,
    //   show: true,
    //   Cell: ({ value }) => (value === 0 ? <NAIcon /> : <>{value}</>)
    // },
    {
      accessor: 'issuer_status',
      Header: 'Issuer Escrowed',
      width: 110,
      show: true
    },
    {
      accessor: 'subcriber_status',
      Header: 'Investor Escrowed',
      width: 110,
      show: true
    }
    // {
    //   accessor: 'actions',
    //   Header: 'Actions',
    //   width: 200,
    //   show: true
    // }
  ];

  useEffect(() => {
    // TODO: Fetch Investors
    const _fetchData = async () => {
      const _rows = await Promise.all(
        rows.map(async (row) => {
          const condition = await getRedemptionEscrowConditions(row.escrow_order_id);
          // row.xinfin_address = CopyAddress(ethToXdcAddress(row.primary_issuer_xinfin_address));
          console.log('Check', condition);
          row.issuer_status = <IssuerEscrowStatus status={condition[0]} />;
          row.subcriber_status = <SubcriberEscrowStatus status={condition[1]} />;
          row.actions = <ActionButtons row={row} account={account} />;
          console.log('here', row);
          return row;
        })
      );
      setData(_rows);
    };
    _fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows]);
  const updateMyData = (rowIndex, columnId, value) => {
    setSkipPageReset(true);
    setData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value
          };
        }
        return row;
      })
    );
  };

  return (
    <>
      <EnhancedTable
        tableTitle="InvestorRedemptionEscrow"
        columns={headCells}
        data={data}
        setData={setData}
        updateMyData={updateMyData}
        skipPageReset={skipPageReset}
        refreshFunction={_fetchData}
      />
    </>
  );
}
