import React, { useCallback, useEffect, useMemo, useState } from 'react';
import EnhancedTable from 'components/tables/EnhancedTable';
import {
  Box,
  IconButton,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  Alert,
  AlertTitle
} from '@mui/material';

import Delete from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useBusinessEntityState } from 'state/useBusinessEntityState';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { ethToXdcAddress, xdcToEthAddress } from 'helpers/web3';
import { useNavigate } from 'react-router';
import { useHttpApi, useWeb3, useAuthStateShared, useAppState } from 'state';
import { useSnackbar } from 'notistack';
import { HeaderName } from 'helpers/tableHelper';
import TransactionHandler from 'components/TransactionHandler';
import { LoadingButton } from '@mui/lab';
import CopyAddress from 'components/CopyAddress';
import { useIsOwner } from 'helpers/rbac';

const IssuerName = HeaderName('Issuer Name');
const CountryCode = HeaderName('Country Code');

const ActionButtons = ({ issuerId, issuerStatus, issuerXInfin, issuerName }) => {
  const { fetchIssuer, setEditIssuer, fetchIssuerInsurerMappingByIssuerId } =
    useBusinessEntityState();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { throwErrorMessage } = useAppState();
  const { getIssuerById, deleteIssuer } = useHttpApi();
  const navigate = useNavigate();
  const { removeIssuer: removeIssuerBlockchain } = useWeb3();
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const [loadingState, setLoadingState] = useState(false);
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          m: 0,
          p: 0,
          alignItems: 'center'
        }}
      >
        <IconButton
          aria-label="subs detail"
          onClick={async () => {
            // setBusinessEntity(issuerId);
            const response = await getIssuerById(issuerId);
            console.log('ressss', response);
            response.primary_issuer_xinfin_address = ethToXdcAddress(
              response.primary_issuer_xinfin_address
            );
            setEditIssuer(response);
            fetchIssuerInsurerMappingByIssuerId(issuerId);
            navigate('/masterdata/issuer/edit-issuer');
            console.log('edit issuer id', issuerId);
          }}
        >
          <EditIcon sx={{ fontSize: '2.188rem', m: 0, p: 1, backgroundColor: '#F5F8FA' }} />
        </IconButton>

        <IconButton aria-label="subs detail" onClick={handleOpen}>
          <Delete sx={{ fontSize: '2.188rem', p: 1, backgroundColor: '#F5F8FA' }} />
        </IconButton>
      </Box>
      <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title">
        <Alert severity="warning">
          <AlertTitle>Delete Issuer</AlertTitle>
          <DialogContent>Are you sure you want to delete Issuer, {issuerName}?</DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleClose}
              sx={{ width: '6.7rem' }}
            >
              Cancel
            </Button>
            <LoadingButton
              variant="outlined"
              color="error"
              loadingPosition="start"
              sx={{ width: '6.7rem' }}
              loading={loadingState}
              onClick={async () => {
                setLoadingState(true);
                console.log('delete issuer id', issuerId, issuerStatus);
                if (issuerStatus === 'ONCHAIN') {
                  try {
                    const res = await removeIssuerBlockchain(xdcToEthAddress(issuerXInfin));
                    if (res) {
                      enqueueSnackbar('Issuer is submitted to Delete', {
                        variant: 'info'
                      });
                      fetchIssuer();
                    }
                  } catch (e) {
                    throwErrorMessage(e);
                  }
                } else {
                  await deleteIssuer(issuerId);
                  enqueueSnackbar('Issuer deleted successfully', {
                    variant: 'success'
                  });
                  fetchIssuer();
                }
                setLoadingState(false);
                handleClose();
              }}
            >
              Delete
            </LoadingButton>
          </DialogActions>
        </Alert>
      </Dialog>
    </>
  );
};

const IssuerChainStatus = ({ status, countryCode, address }) => {
  const { addIssuer } = useWeb3();

  const commitTransaction = useCallback(
    (hash) => {
      return addIssuer(countryCode, address, false, hash);
    },
    [addIssuer, address, countryCode]
  );

  const { fetchIssuer } = useBusinessEntityState();

  const onConfirmed = useCallback(() => {
    fetchIssuer();
  }, [fetchIssuer]);

  return (
    <TransactionHandler
      id={`add-issuer-${address}`}
      commitTransaction={commitTransaction}
      done={status === 'ONCHAIN'}
      onConfirmed={onConfirmed}
    >
      {({ status: txStatus, error, receipt, send }) => {
        console.log('TransactionHandler', txStatus, error && error.message, receipt);

        return (
          <Chip
            // onClick={status==="OFFCHAIN"?send:null}
            label={status}
            color={status === 'OFFCHAIN' ? 'warning' : 'success'}
          />
        );
      }}
    </TransactionHandler>
  );
};

export default function IssuerTable() {
  const { issuers, fetchIssuer } = useBusinessEntityState();
  console.log(issuers);
  // const [data, setData] = useState(issuers);

  // const [skipPageReset, setSkipPageReset] = React.useState(false);
  // const [owner, setOwner] = useState(false);

  // const { role } = useAuthStateShared();

  // useEffect(() => {
  //   fetchIssuer();
  // }, []);

  // useEffect(() => {
  //   if (role === 'OWNER') {
  //     setOwner(true);
  //   }
  // }, [role]);

  const isOwner = useIsOwner();

  const headCells = useMemo(
    () => [
      {
        accessor: 'issuer_name',
        Header: IssuerName,
        show: true
      },
      // {
      //   accessor: 'lei',
      //   Header: 'LEI',
      //   show: true
      // },
      // {
      //   accessor: 'license_No',
      //   Header: 'License No',
      //   show: true
      // },
      {
        accessor: 'primary_issuer_xinfin_address',
        Header: 'Xinfin Address',
        width: 200,
        Cell: ({ value }) => {
          return <CopyAddress addr={ethToXdcAddress(value)} />;
        },
        show: true
      },

      {
        accessor: 'issuer_country_code.country_code',
        Header: CountryCode,
        show: true
      },
      {
        accessor: 'status',
        Header: 'Status',
        Cell: ({ row: { original } }) => {
          return (
            <IssuerChainStatus
              status={original.status}
              countryCode={original.issuer_country_code.country_code}
              address={original.primary_issuer_xinfin_address}
            />
          );
        },
        show: true
      },
      {
        accessor: 'actions',
        Header: 'Actions',
        Cell: ({ row: { original } }) => {
          return (
            <ActionButtons
              issuerId={original.issuer_id}
              issuerName={original.issuer_name}
              issuerStatus={original.status}
              issuerXInfin={original.primary_issuer_xinfin_address}
            />
          );
        },
        show: isOwner
      }
    ],
    [isOwner]
  );

  // useEffect(() => {
  //   const _issuers = issuers.map((issuer) => {
  // issuer.xinfin_address = (
  //   <CopyAddress addr={ethToXdcAddress(issuer.primary_issuer_xinfin_address)} />
  // );

  // issuer.issuer_status = (
  //   <IssuerChainStatus
  //     status={issuer.status}
  //     countryCode={issuer.issuer_country_code.country_code}
  //     address={issuer.primary_issuer_xinfin_address}
  //   />
  // );
  //     issuer.actions = (
  //       <ActionButtons
  //         issuerId={issuer.issuer_id}
  //         issuerStatus={issuer.status}
  //         issuerXInfin={issuer.primary_issuer_xinfin_address}
  //         issuerName={issuer.issuer_name}
  //       />
  //     );

  //     return issuer;
  //   });
  //   setData(_issuers);
  // }, [issuers]);
  // We need to keep the table from resetting the pageIndex when we
  // Update data. So we can keep track of that flag with a ref.

  // When our cell renderer calls updateMyData, we'll use
  // the rowIndex, columnId and new value to update the
  // original data
  // const updateMyData = (rowIndex, columnId, value) => {
  //   // We also turn on the flag to not reset the page
  //   setSkipPageReset(true);
  //   setData((old) =>
  //     old.map((row, index) => {
  //       if (index === rowIndex) {
  //         return {
  //           ...old[rowIndex],
  //           [columnId]: value
  //         };
  //       }
  //       return row;
  //     })
  //   );
  // };

  return (
    <>
      <EnhancedTable
        tableTitle="Issuer"
        columns={headCells}
        refreshFunction={fetchIssuer}
        data={issuers}
        // setData={setData}
        // updateMyData={updateMyData}
        // skipPageReset={skipPageReset}
      />
    </>
  );
}
