import React, { useEffect } from 'react';
import { Modal, Grid, Box, Typography } from '@mui/material';
import PieChartData from './PieChartsData';
import { useBusinessEntityState } from 'state';
import TokenSummaryTable from './viewAllotment/TokenSummaryTable';
import { formatAddressShort } from 'helpers/web3';
import CloseIcon from '@mui/icons-material/Close';

const TokenSummaryModal = ({ open, handleClose }) => {
  const { tokenSummary } = useBusinessEntityState();
  console.log(tokenSummary);
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography fontWeight="bold">Token Summary</Typography>
          <CloseIcon
            onClick={handleClose}
            sx={{
              cursor: 'pointer'
            }}
          />
        </Box>
        <Grid container>
          <Grid item xs={12}>
            <PieChartData
              data={tokenSummary.map((subscriber) => ({
                name:
                  subscriber.investor_xinfin_account === 'Available'
                    ? subscriber.investor_xinfin_account
                    : formatAddressShort(subscriber.investor_xinfin_account),
                value: subscriber.alloted_quantity,
                address: subscriber.investor_xinfin_account
              }))}
            />
          </Grid>
          <Grid item xs={12}>
            <TokenSummaryTable />
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default TokenSummaryModal;
