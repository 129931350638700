import { useState, useEffect } from 'react';
import { useWeb3 } from 'state';
import { Chip, Box, Skeleton } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export default function CanIssueChip({ tokenAddress, investor, value, nonce }) {
  const { canIssue } = useWeb3();
  const [canIssueResponse, setcanIssueResponse] = useState('0');

  useEffect(() => {
    canIssue(tokenAddress, investor, value).then((res) => {
      console.log(res);
      setcanIssueResponse(res);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nonce]);

  switch (canIssueResponse) {
    case '0':
      return <Skeleton variant="circular" width={20} height={20} />;
    case '0x51':
      return <CheckCircleIcon color="success" />;
    default:
      return <CancelIcon color="error" />;
  }
}
