import { Tooltip, IconButton, Typography } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Zoom from '@mui/material/Zoom';
import { Link } from 'react-router-dom';

const InfoButton = ({ btnTitle }) => {
  return (
    <Tooltip TransitionComponent={Zoom} title={btnTitle} placement="right" arrow>
      <IconButton
        disableRipple
        sx={{
          my: 0,
          py: 0,
          '&:hover': {
            bgcolor: 'transparent'
          }
        }}
      >
        <InfoOutlinedIcon sx={{ fontSize: '1rem' }} />
      </IconButton>
    </Tooltip>
  );
};

export const LeiInfoButton = () => {
  return (
    <InfoButton
      btnTitle={
        <>
          <Typography variant="p">
            <b>Legal Entity Identifier (LEI)</b>
          </Typography>
          <li>
            LEI must be in <b>Uppercase.</b>
          </li>
          <li>
            <b>No Spaces</b> are allowed.
          </li>
          <li>
            First 4 Charcter must be <b>Numeric.</b> [0-9]
          </li>
          <li>5th and 6th Charcter must be a digit (reserved identifier) i.e. '00'</li>
          <li>
            Character between 7th and 18th should be <b>Alpha-Numeric.</b> i.e. [A-Z, 0-9]
          </li>
          <li>
            19th and 20th Charcter must be <b>Numeric.</b> i.e. [0-9]
          </li>
        </>
      }
    />
  );
};

export const XdcInfoButton = () => {
  return (
    <InfoButton
      btnTitle={
        <>
          <Typography variant="p">
            <b>XDC Address</b>
          </Typography>
          <li>XDC Address must be a valid XDCPay wallet address.</li>
          <li>
            XDC Address must start from 'xdc' e.g. xdc08c7b249a76aa982b01fabc9a4d990bd39d3119a
          </li>
          <li>XDC Address must be 43 character long.</li>
          <br />
          <i>
            In case of custom rpc network (using metmask), make sure to use correct rpc and
            networkID and XDC Address must start with '0x' e.g.
            0x08c7b249a76aa982b01fabc9a4d990bd39d3119a (42 Characters long).
          </i>
        </>
      }
    />
  );
};

export const BicInfoButton = () => {
  return (
    <InfoButton
      btnTitle={
        <>
          <Typography variant="p">
            <b>Bank Identifier Code (BIC)</b>
          </Typography>
          <li>
            BIC must be <b>11 Characters</b> long.
          </li>
          <li>
            <b>No Spaces</b> are allowed.
          </li>
          <li>
            First 4 Charcter must be <b>Letters.</b> specifying bank code.
          </li>
          <li>
            5th and 6th Charcter must be <b>Country Code</b>
          </li>
          <li>
            7th and 8th should be <b>Alpha-Numeric.</b> i.e. [A-Z, 0-9] specifying Bank Location.
          </li>
          <li>
            Last Three Characters indicates <b>Specific Branch</b>.
          </li>
          <br />
          <i>
            In Case your BIC is less than 11 characters, fill rest of the character with zero (i.e.
            0)
          </i>
        </>
      }
    />
  );
};

export const IbanInfoButton = () => {
  return (
    <InfoButton
      btnTitle={
        <>
          <Typography variant="p">
            <b>International Bank Account Number (IBAN)</b>
          </Typography>
          <li>
            IBAN must be <b>34 Characters</b> long.
          </li>
          <li>
            <b>No Spaces</b> are allowed.
          </li>
          <li>
            First 2 Charcter must be <b>Letters.</b> specifying country code.
          </li>
          <li>IBAN consist of check digits followed by Country Code.</li>
          <li>After Check Digits, IBAN consist of Bank Code, Sort Code, Account Number.</li>
          {/* <li>
            For more information on IBAN, please refer to{' '}
            <Link to="https://www.worldfirst.com/au/help-support/what-is-an-iban-number/0/">
              this post.
            </Link>
          </li> */}
          <br />
          <i>
            In Case your IBAN is less than 34 characters, fill rest of the character with zero (i.e.
            0)
          </i>
        </>
      }
    />
  );
};
