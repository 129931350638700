import { Container, Card, Grid, Box, CardContent, Typography } from '@mui/material';
import Page from 'components/Page';
import React, { useEffect, useState } from 'react';
import Breadcrumbs from 'components/Breadcrumbs';
import EntityCard from 'components/masterData/Dashboard/EnitityCard';
import investor from '../../assets/images/masterdata-dashboard/investor.svg';
import issuer from '../../assets/images/masterdata-dashboard/issuer.svg';
import bankAccount from '../../assets/images/masterdata-dashboard/bankAccount.svg';
import cashTxn from '../../assets/images/masterdata-dashboard/cashTxn.svg';
import insurer from '../../assets/images/masterdata-dashboard/custodian.svg';
import distributionCriteria from '../../assets/images/masterdata-dashboard/distributionCriteria.svg';
import kycProvider from '../../assets/images/masterdata-dashboard/kycProvider.svg';
import restrictionCriteria from '../../assets/images/masterdata-dashboard/restrictionCriteria.svg';
import userspic from '../../assets/images/masterdata-dashboard/users.svg';
import xinfinAccount from '../../assets/images/masterdata-dashboard/xinfinAccount.svg';
import cryptoAssetTxn from '../../assets/images/masterdata-dashboard/cryptoAssetTxn.svg';
import { useBusinessEntityState, useAuthStateShared } from 'state';
import bankAccountRows from '../../mocks/BankAccount.json';
import cashTxnRows from '../../mocks/CashTxn.json';
import stableCoinTxn from '../../assets/images/dashboard-icon/stableCoinTxn.svg';
import cryptoAssetTxnRows from '../../mocks/CryptoAssetTxn.json';

// import {
//   useBusinessEntityState,
//   useAuthStateShared,
//   useHttpApi,
//   useWeb3,
//   useAppState
// } from 'state';
const MasterdataDashboard = () => {
  const {
    loadBusinessEntityState,
    issuers,
    // custodians,
    kycProviders,
    originators,
    users,
    insurers,
    investorsByIssuer,
    fetchInvestorsByIssuer,
    cryptoAssetTxnBySenderXinfin,
    allXinfinAccounts,
    liquidityPools,
    collateralTransactions,
    issuerInvestorMapByIssuerId,
    allInvestors,
    fundManagers
  } = useBusinessEntityState();
  const { role, user } = useAuthStateShared();
  // const { investorsByIssuer, fetchInvestorsByIssuer, fetchWhiteList, currentToken } =
  //   useBusinessEntityState();
  // const [investors, setInvestors] = useState("")
  // if(role !== 'OWNER'){

  //   setInvestors(investorsByIssuer);

  // }
  useEffect(() => {
    if (role !== 'OWNER') {
      fetchInvestorsByIssuer(user.xinfin_address.xinfin_account_address);
    }
  }, [user]);
  return (
    <>
      <Page title="Master Data">
        <Container sx={{ my: 2 }}>
          <Breadcrumbs pageHead="Masterdata" aria-label="breadcrumb" />

          <Card sx={{ my: 3, boxShadow: 'none' }}>
            <CardContent>
              <Typography sx={{ fontSize: '1.275rem', fontWeight: 600 }}>
                Business Entities
              </Typography>
            </CardContent>
          </Card>
          <Grid container spacing={3}>
            {role === 'OWNER' && (
              <>
                <Grid item lg={3} md={4} sm={6}>
                  <EntityCard
                    buttonRoute="/masterdata/issuer"
                    title="Issuer"
                    buttonName="View Issuer"
                    count={issuers?.length}
                    icon={
                      <Box
                        component="img"
                        sx={{
                          p: 1
                        }}
                        src={issuer}
                      />
                    }
                  />
                </Grid>
                <Grid item lg={3} md={4} sm={6}>
                  <EntityCard
                    buttonRoute="/masterdata/investor"
                    title="investor"
                    buttonName="View investor"
                    count={allInvestors?.length}
                    icon={
                      <Box
                        component="img"
                        sx={{
                          p: 1
                        }}
                        src={investor}
                      />
                    }
                  />
                </Grid>
              </>
            )}

            <Grid item lg={3} md={4} sm={6}>
              <EntityCard
                buttonRoute="/masterdata/fund-manager"
                title="Fund Manager"
                buttonName="View Fund Manager"
                count={fundManagers?.length}
                icon={
                  <Box
                    component="img"
                    sx={{
                      p: 1
                    }}
                    src={investor}
                  />
                }
              />
            </Grid>
            {/* <Grid item lg={3} md={4} sm={6}>
              <EntityCard
                buttonRoute="/masterdata/custodian"
                title="Custodian"
                buttonName="View Custodian"
                count={custodians?.length}
                icon={
                  <Box
                    component="img"
                    sx={{
                      p: 1
                    }}
                    src={custodian}
                  />
                }
              />
            </Grid> */}
            <Grid item lg={3} md={4} sm={6}>
              <EntityCard
                buttonRoute="/masterdata/kyc-provider"
                title="KYC Provider"
                buttonName="View KYC Provider"
                count={kycProviders?.length}
                icon={
                  <Box
                    component="img"
                    sx={{
                      p: 1
                    }}
                    src={kycProvider}
                  />
                }
              />
            </Grid>

            {/* <Grid item lg={3} md={4} sm={6}>
              <EntityCard
                buttonRoute="/masterdata/originator"
                title="Originator"
                buttonName="View Originator"
                count={originators?.length}
                icon={
                  <Box
                    component="img"
                    sx={{
                      p: 1
                    }}
                    src={distributionCriteria}
                  />
                }
              />
            </Grid> */}
            {/* <Grid item lg={3} md={4} sm={6}>
              <EntityCard
                buttonRoute="/masterdata/credit-insurer"
                title="Credit Insurer"
                buttonName="View Credit Insurer"
                count={insurers?.length}
                icon={
                  <Box
                    component="img"
                    sx={{
                      p: 1
                    }}
                    src={investor}
                  />
                }
              />
            </Grid> */}
            {/* {role !== 'PRIMARY_ISSUER' && (
              <Grid item lg={3} md={4} sm={6}>
                <EntityCard
                  buttonRoute="/masterdata/liquidity-pool"
                  title="Liquidity Pools"
                  buttonName="View Liquidity Pools"
                  count={liquidityPools?.length}
                  icon={
                    <Box
                      component="img"
                      sx={{
                        p: 1
                      }}
                      src={stableCoinTxn}
                    />
                  }
                />
              </Grid>
            )} */}
          </Grid>
          {/* Account Set Up */}
          {role !== 'OWNER' && (
            <>
              <Card sx={{ my: 3, boxShadow: 'none' }}>
                <CardContent>
                  <Typography sx={{ fontSize: '1.275rem', fontWeight: 600 }}>
                    Account Set Up
                  </Typography>
                </CardContent>
              </Card>

              <Grid container spacing={3}>
                <Grid item lg={3} md={4} sm={6}>
                  <EntityCard
                    buttonRoute="/masterdata/investor"
                    title="Investor"
                    buttonName="View Investor"
                    count={investorsByIssuer?.length}
                    icon={
                      <Box
                        component="img"
                        sx={{
                          p: 1
                        }}
                        src={investor}
                      />
                    }
                  />
                </Grid>
                {/* <Grid item lg={3} md={4} sm={6}>
                  <EntityCard
                    buttonRoute="/masterdata/bank-account"
                    title="Bank Account"
                    buttonName="View Bank A/C"
                    count={bankAccountRows?.length}
                    icon={
                      <Box
                        component="img"
                        sx={{
                          p: 1
                        }}
                        src={bankAccount}
                      />
                    }
                  />
                </Grid> */}

                {/* <Grid item lg={3} md={4} sm={6}>
                  <EntityCard
                    buttonRoute="/masterdata/cash-txn"
                    title="Cash Transaction"
                    buttonName="View Cash Transaction"
                    count={cashTxnRows?.length}
                    icon={
                      <Box
                        component="img"
                        sx={{
                          p: 1
                        }}
                        src={cashTxn}
                      />
                    }
                  />
                </Grid> */}
                {/* <Grid item lg={3} md={4} sm={6}>
                  <EntityCard
                    buttonRoute="/masterdata/crypto-asset-txn"
                    title="Crypto Asset Transaction"
                    buttonName="View Crypto Asset Transaction"
                    count={cryptoAssetTxnBySenderXinfin?.length}
                    icon={
                      <Box
                        component="img"
                        sx={{
                          p: 1
                        }}
                        src={cryptoAssetTxn}
                      />
                    }
                  />
                </Grid> */}
                {/* <Grid item lg={3} md={4} sm={6}>
                  <EntityCard
                    buttonRoute="/masterdata/xinfin"
                    title="XinFin Account"
                    buttonName="View XinFin Account"
                    count={allXinfinAccounts?.length}
                    icon={
                      <Box
                        component="img"
                        sx={{
                          p: 1
                        }}
                        src={xinfinAccount}
                      />
                    }
                  />
                </Grid> */}
                {/* <Grid item lg={3} md={4} sm={6}>
                  <EntityCard
                    buttonRoute="/masterdata/user"
                    title="Users"
                    buttonName="View Users"
                    count={users?.length}
                    icon={
                      <Box
                        component="img"
                        sx={{
                          p: 1
                        }}
                        src={userspic}
                      />
                    }
                  />
                </Grid> */}
                {/* <Grid item lg={3} md={4} sm={6}>
                  <EntityCard
                    buttonRoute="/masterdata/collateral-txn"
                    title="Collateral Txn"
                    buttonName="View Collateral Txn"
                    count={collateralTransactions?.length}
                    icon={
                      <Box
                        component="img"
                        sx={{
                          p: 1
                        }}
                        src={cashTxn}
                      />
                    }
                  />
                </Grid> */}
              </Grid>
            </>
          )}
        </Container>
      </Page>
    </>
  );
};

export default MasterdataDashboard;
