import React from 'react';
import { Box, Stepper, Step, StepLabel, styled } from '@mui/material';

import PropTypes from 'prop-types';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)'
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#1D7114'
    }
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#1D7114'
    }
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1
  }
}));

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
  display: 'flex',
  height: 22,
  alignItems: 'center',
  ...(ownerState.active && {
    color: '#784af4'
  }),
  '& .QontoStepIcon-completedIcon': {
    color: '#784af4',
    zIndex: 1,
    fontSize: 18
  },
  '& .QontoStepIcon-circle': {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor'
  }
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <CheckCircleRoundedIcon
          sx={{
            color: '#2BA41E',
            borderRadius: '50%',
            border: '1px dashed #1D7114'
          }}
        />
      ) : (
        <CheckCircleRoundedIcon
          sx={{
            color: '#8D8D8D',
            borderRadius: '50%',
            border: '1px dashed #8D8D8D'
          }}
        />
      )}
    </QontoStepIconRoot>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool
};

// eslint-disable-next-line react/prop-types
const TokenTimelineOpenTxn = (props) => {
  const steps = [
    'Applied',
    'KYC',
    'Whitelisted',
    // 'Trade Docs',
    'Issuance Escrowed',
    'Issued',
    // 'Redemption Escrowed',
    'Redeemed'
  ];
  return (
    <Box
      sm={12}
      xs={12}
      sx={{
        width: '100%',
        px: 2
      }}
    >
      <Stepper alternativeLabel activeStep={props.statusNumber} connector={<QontoConnector />}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default TokenTimelineOpenTxn;
