import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { visuallyHidden } from '@mui/utils';
import rows from '../../../mocks/CashTxn.json';
import { Pagination, Stack } from '@mui/material';
import Delete from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'txn_type_id',
    numeric: false,
    disablePadding: false,
    label: 'Transaction type id'
  },

  {
    id: 'currency_code',
    numeric: false,
    disablePadding: false,
    label: 'Currency code'
  },
  {
    id: 'txn_amt',
    numeric: true,
    disablePadding: false,
    label: 'Transaction amount'
  },

  {
    id: 'token_id',
    numeric: true,
    disablePadding: false,
    label: 'Token id'
  },
  {
    id: 'bank_acc_id',
    numeric: true,
    disablePadding: false,
    label: 'Bank account id'
  },
  {
    id: 'Token_subscription_id',
    numeric: true,
    disablePadding: false,
    label: 'Token subscription id'
  },
  {
    id: 'Direction',
    numeric: false,
    disablePadding: false,
    label: 'Direction'
  },
  {
    id: 'actions',
    numeric: true,
    disablePadding: false,
    label: 'Actions'
  }
];

function CashTxnTaleHeads(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'center' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

CashTxnTaleHeads.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired
};

export default function CashTxnTable() {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('name');
  const [selected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Box sx={{ width: '100%', boxShadow: 0 }}>
      {selected.length > 0 && (
        <Typography variant="h6" gutterBottom>
          {selected.length} Selected
        </Typography>
      )}
      <TableContainer sx={{ boxShadow: 0 }}>
        <Table
          sx={{ minWidth: 750, boxShadow: 0 }}
          aria-labelledby="tableTitle"
          size={dense ? 'small' : 'medium'}
        >
          <CashTxnTaleHeads
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
          />
          <TableBody>
            {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
            {stableSort(rows, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow hover tabIndex={-1} key={row.allotteName}>
                    <TableCell
                      component="th"
                      align="left"
                      id={labelId}
                      scope="row"
                      padding="normal"
                      sx={{ fontWeight: 'bold' }}
                    >
                      {row.txn_type_id}
                    </TableCell>
                    <TableCell align="left">{row.currency_code}</TableCell>
                    <TableCell align="left" sx={{ fontWeight: 'bold' }}>
                      {row.txn_amt}
                    </TableCell>

                    <TableCell align="left">{row.token_id}</TableCell>
                    <TableCell align="left">{row.bank_acc_id}</TableCell>
                    <TableCell align="left">{row.Token_subscription_id}</TableCell>
                    <TableCell align="left" style={{ width: '25%' }}>
                      {row.Direction}
                    </TableCell>

                    <TableCell align="center" style={{ width: '14%' }}>
                      <IconButton aria-label="subs detail" onClick={() => {}}>
                        <EditIcon sx={{ fontSize: '2.188rem', p: 1, backgroundColor: '#F5F8FA' }} />
                      </IconButton>
                      <IconButton aria-label="subs detail" onClick={() => {}}>
                        <Delete sx={{ fontSize: '2.188rem', p: 1, backgroundColor: '#F5F8FA' }} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: (dense ? 33 : 53) * emptyRows
                }}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <Stack
        spacing={2}
        sx={{ mt: 2, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}
      >
        <Pagination
          count={Math.ceil(rows.length / 5)}
          variant="outlined"
          shape="rounded"
          showFirstButton
          defaultPage={0}
          onChange={handleChangePage}
          page={Number(page)}
          sx={{ color: '#112B3D' }}
        />
      </Stack>

      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
    </Box>
  );
}
