import React, { useCallback, useEffect, useMemo, useState } from 'react';
import EnhancedTable from 'components/tables/EnhancedTable';
import {
  Box,
  IconButton,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  Alert,
  AlertTitle,
  Typography
} from '@mui/material';

import Delete from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useBusinessEntityState, useCoreTableState, useAppState, useHttpApi } from 'state';
import { useSnackbar } from 'notistack';
import { useIsOwner } from 'helpers/rbac';
import LoadingActionButton from 'helpers/LoadingActionButton';
import { HeaderName } from 'helpers/tableHelper';
import EditDocSubType from './EditDocSubType';

const DocSubName = HeaderName('Document Sub Type');
const ActionButtons = ({ original, id, docTypeName, docSubTypeName }) => {
  useBusinessEntityState();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { deleteDocumentSubType } = useHttpApi();
  const { fetchAllDocumentSubTypes, allDocumentTypes } = useBusinessEntityState();

  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const { throwErrorMessage } = useAppState();
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleEditClose = () => {
    setEditOpen(false);
  };
  const handleEditOpen = () => {
    setEditOpen(true);
  };
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          m: 0,
          p: 0,
          alignItems: 'center'
        }}
      >
        <IconButton
          aria-label="subs detail"
          onClick={() => {
            handleEditOpen();
          }}
        >
          <EditIcon sx={{ fontSize: '2.188rem', m: 0, p: 1, backgroundColor: '#F5F8FA' }} />
        </IconButton>
        <Dialog open={editOpen} onClose={handleEditClose} aria-labelledby="alert-dialog-title">
          <DialogContent sx={{ m: 3 }}>
            <EditDocSubType handleEditClose={handleEditClose} original={original} />
          </DialogContent>
        </Dialog>
        <IconButton aria-label="subs detail" onClick={handleOpen}>
          <Delete sx={{ fontSize: '2.188rem', p: 1, backgroundColor: '#F5F8FA' }} />
        </IconButton>
      </Box>
      <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title">
        <Alert severity="warning">
          <AlertTitle>Delete Document Sub Type</AlertTitle>
          <DialogContent>
            Are you sure you want to delete Document Sub Type, {docSubTypeName} associated with
            Document Type, {docTypeName} ?
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleClose}
              sx={{ width: '6.7rem' }}
            >
              Cancel
            </Button>
            <LoadingActionButton
              variant="gradient"
              color="error"
              loadingPosition="start"
              sx={{ width: '6.7rem' }}
              onClick={async () => {
                try {
                  const res = await deleteDocumentSubType(id);
                  enqueueSnackbar('Document Sub Type deleted successfully', {
                    variant: 'success'
                  });
                  await fetchAllDocumentSubTypes();
                } catch (e) {
                  throwErrorMessage(e);
                }
                handleClose();
              }}
            >
              Delete
            </LoadingActionButton>
          </DialogActions>
        </Alert>
      </Dialog>
    </>
  );
};

export default function AllDocSubTypes() {
  const { fetchAllDocumentSubTypes, allDocumentSubTypes } = useBusinessEntityState();
  const [data, setData] = useState([]);
  const isOwner = useIsOwner();

  const fetchData = async () => {
    const _fetchAllDocumentSubTypes = async () => {
      await fetchAllDocumentSubTypes();
    };
    _fetchAllDocumentSubTypes();
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    setData(allDocumentSubTypes);
    console.log(allDocumentSubTypes);
  }, [allDocumentSubTypes]);

  const headCells = useMemo(
    () => [
      {
        accessor: 'name',
        Header: DocSubName,
        show: true
      },
      {
        accessor: 'document_type.name',
        Header: 'Document Type',
        show: true
      },
      {
        accessor: 'actions',
        Header: 'Actions',
        Cell: ({ row: { original } }) => {
          return (
            <ActionButtons
              original={original}
              id={original.id}
              docSubTypeName={original.name}
              docTypeName={original.document_type.name}
            />
          );
        },
        show: isOwner
      }
    ],
    [isOwner]
  );

  return (
    <>
      <EnhancedTable
        tableTitle="Document Sub Types"
        columns={headCells}
        refreshFunction={fetchData}
        data={data}
      />
    </>
  );
}
