import React, { useEffect, useState } from 'react';
import { Box, IconButton, Chip } from '@mui/material';
import { useBusinessEntityState } from 'state/useBusinessEntityState';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { ethToXdcAddress } from 'helpers/web3';
import { useAuthStateShared } from 'state';
import CopyAddress from 'components/CopyAddress';
import EnhancedTable from 'components/tables/EnhancedTable';

// const ActionButtons = ({ investor }) => {
//   const [open, setOpen] = React.useState(false);
//   const handleOpen = () => setOpen(true);
//   const handleClose = () => setOpen(false);
//   return (
//     <>
//       <IconButton aria-label="subs detail" onClick={handleOpen} sx={{ mt: -1 }}>
//         <VisibilityIcon sx={{ fontSize: '2rem', p: 1, m: 0, backgroundColor: '#F5F8FA' }} />
//       </IconButton>
//       <Modal
//         open={open}
//         onClose={handleClose}
//         aria-labelledby="modal-modal-title"
//         aria-describedby="modal-modal-description"
//       >
//         <SubsDetails investor={investor} handleClose={handleClose} />
//       </Modal>
//     </>
//   );
// };

// const AllotmentChainStatus = ({ status }) => {
//   return (
//     <Chip sx={{ mb: 1.5 }} label={status} color={status === 'ONCHAIN' ? 'success' : 'warning'} />
//   );
// };
// const RedeemRequestStatus = ({ status }) => {
//   return (
//     <Chip sx={{ mb: 1.5 }} label={status ? 'YES' : 'NO'} color={status ? 'success' : 'warning'} />
//   );
// };
export default function TokenSummaryTable() {
  const { tokenSummary, fetchTokenSummaryData, currentToken: token } = useBusinessEntityState();

  const [data, setData] = useState(tokenSummary);
  const { role } = useAuthStateShared();

  const [skipPageReset, setSkipPageReset] = React.useState(false);

  // We need to keep the table from resetting the pageIndex when we
  // Update data. So we can keep track of that flag with a ref.

  // When our cell renderer calls updateMyData, we'll use
  // the rowIndex, columnId and new value to update the
  // original data
  const updateMyData = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true);
    setData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value
          };
        }
        return row;
      })
    );
  };

  const headCells = [
    //   {
    //     accessor: 'token_subscription_id.investor_id.investor_name',
    //     Header: 'Name',
    //     width: 100,
    //     show: true
    //   },
    {
      accessor: 'investor_xinfin_account',
      Header: 'Holder',
      width: 200,
      show: true,
      Cell: (value) =>
        value?.row?.original?.investor_xinfin_account === 'Available' ? (
          value.row.original.investor_xinfin_account
        ) : (
          <CopyAddress addr={ethToXdcAddress(value.row.original.investor_xinfin_account)} />
        )
    },
    //   {
    //     accessor: 'allotment_date',
    //     Header: 'Allotment Date',
    //     width: 100,
    //     show: true
    //   },
    //   {
    //     accessor: 'allotment_maturity_date',
    //     Header: 'Maturity Date',
    //     width: 100,

    //     show: true
    //   },
    {
      accessor: 'alloted_quantity',
      Header: 'Qty',
      width: 80,
      show: true
    },
    //   {
    //     accessor: 'onchain_status',
    //     Header: 'Status',
    //     show: true
    //   },
    {
      Header: 'Token NAV',
      show: true,
      Cell: () => <>{token?.token_nav}</>
    }
  ];

  return (
    <>
      <EnhancedTable
        tableTitle="View Allotment"
        columns={headCells}
        refreshFunction={fetchTokenSummaryData}
        // setSelectedRowsToState={fetchTokenSummaryData}
        data={data}
        setData={setData}
        updateMyData={updateMyData}
        skipPageReset={skipPageReset}
        isRoleAllowed={['PRIMARY_ISSUER', 'ISSUER_OPERATOR', 'INVESTOR'].includes(role)}
      />
    </>
  );
}
