import React, { useEffect, useState } from 'react';
import { businessEntityPersistence } from 'persistence';
import { useBusinessEntityState } from 'state';
import MapInsurertoIssuer from 'components/masterData/Issuer/MapInsurertoIssuer';
import Page from 'components/Page';
import { Card, CardContent, Container } from '@mui/material';
import Breadcrumbs from 'components/Breadcrumbs';

const IssuerInsurerMap = () => {
  const [currIssuer, setCurrIssuer] = useState();
  const { setEditIssuer, fetchIssuerInsurerMappingByIssuerId } = useBusinessEntityState();
  useEffect(() => {
    const currentIssuer = JSON.parse(businessEntityPersistence.get('entity'));
    setEditIssuer(currentIssuer);
    setCurrIssuer(currentIssuer);
    fetchIssuerInsurerMappingByIssuerId(currentIssuer.issuer_id);
  }, []);

  return (
    <Page title="Manage Credit Insurer">
      <Container sx={{ my: 2 }}>
        <Breadcrumbs aria-label="breadcrumb" pageHead="Insurer Account" />
        <Card sx={{ mt: 2 }}>
          <CardContent sx={{ m: 4 }}>
            <MapInsurertoIssuer />
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
};

export default IssuerInsurerMap;
