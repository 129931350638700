import { Container, Typography, Box, Card, CardContent, Button, Alert } from '@mui/material';
import Breadcrumbs from 'components/Breadcrumbs';
// import tokenTerms from '../../mocks/TokenTerms.json';
import CurrentWhiteList from 'components/whitelist/CurrentWhiteListTable';
import PendingWhiteList from 'components/whitelist/PendingWhiteListTable';
import {
  useBusinessEntityState,
  useHttpApi,
  useWeb3,
  useAuthStateShared,
  useAppState
} from 'state';
import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import { onlyKycProvider, onlyPrimaryIssuerOrKycProvider } from 'helpers/rbac';
import ReadOnlyNotifier from 'components/ReadOnlyNotifier';
import LoadingActionButton from 'helpers/LoadingActionButton';
import TokenTable from 'components/issuerDashboard/TokenTable';

const MaintainWhitelist = () => {
  const {
    currentToken: token,
    allSubscriptions,
    addWhiteListAddresses,
    removeWhiteListAddresses,
    currentIssuer,
    currentToken,
    fetchWhiteList
  } = useBusinessEntityState();
  const { removeFromWhitelist, addToWhitelist, rejectInvestors, acceptInvestors } = useHttpApi();
  const { addWhiteList, removeWhiteList, checkRemoveWhiteList, addIssuerWhiteList } = useWeb3();
  const { enqueueSnackbar } = useSnackbar();
  console.log('Check current Token in Whitelist', token);
  console.log('All Subcriptions (Pending+Current WhiteList)', allSubscriptions);
  const navigate = useNavigate();
  const { role, user } = useAuthStateShared();
  const { addToIssuerWhitelist, updateIssuerInvestorMapping } = useHttpApi();
  const { throwErrorMessage } = useAppState();
  console.log('uegwudfgwjhfdwdjfcbwdj', token);

  useEffect(() => {
    fetchWhiteList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const removeFromWhitelistFunction = async () => {
    if (token.whitelist_type === 'issuer') {
      enqueueSnackbar('Remove Issuer Whitelisted Investors from MasterData ➔ Investors', {
        variant: 'warning'
      });
    } else {
      const data = {};
      let hasIssuerWhitelistedInvestors = false;
      data.token_id = token.token_id;
      data.addresses_list = removeWhiteListAddresses.map((sub) => {
        if (sub.uses_issuer_whitelist_rule) {
          hasIssuerWhitelistedInvestors = true;
        }
        return sub.xinfin_setl_account;
      });
      if (data.addresses_list.length === 0) {
        enqueueSnackbar('No address to remove!', { variant: 'warning' });
      } else {
        try {
          console.log('Remove From Whitelist', data);
          console.log({ deployment_address: token.deployment_address });

          const checkBlockChain = await checkRemoveWhiteList(
            token.deployment_address,
            data.addresses_list
          );
          console.log('Check Remove From Whitelist Response', checkBlockChain);
          const blockchainResponse = await removeWhiteList(
            token.deployment_address,
            data.addresses_list
          );
          enqueueSnackbar('Address removed from whitelist!', { variant: 'success' });
          if (hasIssuerWhitelistedInvestors) {
            enqueueSnackbar('Remove Issuer Whitelisted Investors from MasterData ➔ Investors', {
              variant: 'warning'
            });
          }
          const res = await removeFromWhitelist(data);
          if (res.message) {
            fetchWhiteList();
          }
        } catch (e) {
          throwErrorMessage(e);
        }
      }
    }
  };

  return (
    <Container>
      <Breadcrumbs aria-label="breadcrumb" pageHead="Maintain Whitelist" />

      {/* Token Table */}
      <TokenTable currToken={token} />

      {!onlyPrimaryIssuerOrKycProvider(role) && <ReadOnlyNotifier userRole={role} />}
      {currentToken.is_paused && (
        <Alert severity="error" sx={{ height: '3rem', mt: 2 }}>
          Cannot add or remove Whitelist For Paused Token
        </Alert>
      )}
      {/* Pending Whitelist  Table */}
      <Box sx={{ mt: 2 }}>
        <Typography variant="body2" color="initial" sx={{ fontWeight: 'bold', my: 2 }}>
          Pending Whitelist
        </Typography>
        <Card>
          <CardContent>
            <PendingWhiteList />
          </CardContent>
        </Card>
        <Card
          sx={{ my: 2, p: 2, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}
        >
          {/* <LoadingActionButton
            loadingPosition="start"
            variant="outlined"
            sx={{ width: '15rem', mr: '1rem', px: 1 }}
            onClick={async () => {
              if (addWhiteListAddresses.length > 0) {
                try {
                  const tokenId = currentToken.token_id;
                  const data = {
                    investors: addWhiteListAddresses.map((a) => a.investor_id.investor_id)
                  };
                  const res = await rejectInvestors(tokenId, data);
                  if (res.message) {
                    fetchWhiteList();
                  }
                } catch (e) {
                  throwErrorMessage(e);
                }
              } else {
                enqueueSnackbar('No addresses selected', { variant: 'error' });
              }
            }}
          >
            Reject Subscription
          </LoadingActionButton> */}
          {token.whitelist_type === 'any' || token.whitelist_type === 'issuer' ? (
            <LoadingActionButton
              loadingPosition="start"
              variant="gradient"
              sx={{ width: '15rem', mr: '1rem', px: 1 }}
              disabled={!onlyPrimaryIssuerOrKycProvider(role) || token.is_paused}
              onClick={async () => {
                if (addWhiteListAddresses.length > 0) {
                  try {
                    addWhiteListAddresses.forEach((addr) => {
                      if (addr.kyc_status !== 'ONCHAINCOMPLETE') {
                        throw new Error('An investor KYC has not update KYC ONCHAIN');
                      }
                    });
                    const data = {
                      current_token_id: token.token_id,
                      investors: addWhiteListAddresses.map((a) => a.investor_id)
                    };

                    const addresses = data.investors.map((i) => i.xinfin_account);
                    // console.log(addresses);

                    // blockchain call
                    const blockchainResponse = await addIssuerWhiteList(
                      user.xinfin_address.xinfin_account_address,
                      addresses
                    );
                    console.log(blockchainResponse);

                    // django call to add to issuer whitelist
                    const res = await addToIssuerWhitelist(
                      user.xinfin_address.xinfin_account_address,
                      data
                    );

                    // django call to 'un-reject' subscriptions
                    const res2 = await acceptInvestors(currentToken.token_id, {
                      investors: data.investors.map((i) => i.investor_id)
                    });

                    if (res.message && res2.message) {
                      fetchWhiteList();
                    }
                    enqueueSnackbar('Address added to Issuer Whitelist!', { variant: 'success' });
                  } catch (e) {
                    throwErrorMessage(e);
                  }
                } else {
                  enqueueSnackbar('No address to add', { variant: 'error' });
                }
              }}
            >
              Add to Issuer Whitelist
            </LoadingActionButton>
          ) : (
            <></>
          )}

          {token.whitelist_type === 'any' || token.whitelist_type === 'token' ? (
            <LoadingActionButton
              loadingPosition="start"
              variant="gradient"
              sx={{ width: '15rem', px: 1 }}
              disabled={
                !onlyPrimaryIssuerOrKycProvider(role) ||
                token.token_status_id.token_status === 'BURNT' ||
                token.is_paused
              }
              onClick={async () => {
                const data = {};
                data.token_id = token.token_id;
                data.addresses_list = addWhiteListAddresses.map((sub) => sub.xinfin_setl_account);
                if (addWhiteListAddresses.length > 0) {
                  try {
                    addWhiteListAddresses.forEach((addr) => {
                      if (addr.kyc_status !== 'ONCHAINCOMPLETE') {
                        throw new Error('An investor KYC has not update KYC ONCHAIN');
                      }
                    });
                    addWhiteListAddresses.forEach((addr) => {
                      if (!addr.allowed_events.includes('WHITELIST')) {
                        throw new Error('An investor has not uploaded the required documents');
                      }
                    });

                    console.log('Add to Whitelist', data);
                    console.log({ deployment_address: token.deployment_address });

                    // enqueueSnackbar('Add Whitelist Blockchain Call Ongoing...', {
                    //   variant: 'info',
                    //   autoHideDuration: 10000
                    // });

                    const blockchainResponse = await addWhiteList(
                      token.deployment_address,
                      data.addresses_list
                    );
                    console.log('Add to Whitelist Response', blockchainResponse);

                    enqueueSnackbar('Address added to whitelist!', { variant: 'success' });
                    const res = await addToWhitelist(data);
                    if (res.message) {
                      fetchWhiteList();
                    }
                  } catch (e) {
                    throwErrorMessage(e);
                  }
                } else {
                  enqueueSnackbar('No address to add', { variant: 'error' });
                }
              }}
            >
              Add to Token WhiteList
            </LoadingActionButton>
          ) : (
            <></>
          )}
        </Card>
      </Box>
      {/* Current Whitelist  Table */}
      <Box sx={{ mt: 2 }}>
        <Typography variant="body2" color="initial" sx={{ fontWeight: 'bold', my: 2 }}>
          Current Whitelist
        </Typography>
        <Card>
          <CardContent>
            <CurrentWhiteList />
          </CardContent>
        </Card>
        <Card
          sx={{ my: 2, p: 2, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}
        >
          <Button
            variant="contained"
            sx={{
              mx: 2,
              px: 3,
              fontWeight: 400,
              width: '7.75rem'
            }}
            color="secondary"
            onClick={() => {
              navigate('/home/token-dashboard');
            }}
          >
            Close
          </Button>

          {/* <LoadingActionButton
            loadingPosition="start"
            variant="outlined"
            sx={{ width: '15rem', mr: '1rem', px: 1 }}
            onClick={async () => {
              if (removeWhiteListAddresses.length > 0) {
                try {
                  const tokenId = currentToken.token_id;
                  const data = {
                    investors: removeWhiteListAddresses.map((a) => a.investor_id.investor_id)
                  };
                  const res = await rejectInvestors(tokenId, data);
                  if (res.message) {
                    removeFromWhitelistFunction();
                  }
                } catch (e) {
                  throwErrorMessage(e);
                }
              } else {
                enqueueSnackbar('No addresses selected', { variant: 'error' });
              }
            }}
          >
            Reject Subscription
          </LoadingActionButton> */}

          <LoadingActionButton
            loadingPosition="start"
            disabled={!onlyPrimaryIssuerOrKycProvider(role) || token.is_paused}
            onClick={removeFromWhitelistFunction}
            color="error"
            // sx={{ width: '11rem' }}
            variant="gradient"
          >
            Remove from Token Whitelist
          </LoadingActionButton>
        </Card>
      </Box>
    </Container>
  );
};

export default MaintainWhitelist;
