import React, { useState, useRef, useEffect } from 'react';
import { Grid, Divider, Typography, TextField, FormLabel, Box, Alert, Button } from '@mui/material';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { useBusinessEntityState, useHttpApi, useAppState } from 'state';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router';

const EditIssueType = ({ handleEditClose, original }) => {
  const { createIssueType } = useHttpApi();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { throwErrorMessage } = useAppState();
  const [error, setError] = useState('');
  const EditIssueTypeSchema = Yup.object().shape({
    issue_type_name: Yup.string().required('Issue Type Name is required')
  });
  const formik = useFormik({
    initialValues: original,
    validationSchema: EditIssueTypeSchema,
    onSubmit: async (data, { resetForm }) => {
      console.log(data);
      try {
        console.log(JSON.stringify(data, null, ' '));
      } catch (e) {
        throwErrorMessage(e);
      }
    }
  });
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <>
      <Typography sx={{ fontWeight: 600 }}>Edit IssueType</Typography>
      <Divider
        sx={{
          width: '100%',
          backgroundColor: '#f5f8fa',
          border: '2px solid #f5f8fa',
          my: 2
        }}
      />
      <FormikProvider value={formik}>
        <Form autoComplete="off" onSubmit={handleSubmit}>
          {error && (
            <Box mb={4}>
              <Alert
                severity="error"
                sx={{
                  textTransform: 'capitalize'
                }}
              >
                {error}
              </Alert>
            </Box>
          )}

          <Grid container spacing={4} sx={{ width: '100%' }}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <FormLabel>Issue Type</FormLabel>
              <TextField
                sx={{ mt: 1.5 }}
                fullWidth
                size="small"
                autoComplete="off"
                type="text"
                {...getFieldProps('issue_type_name')}
                error={Boolean(touched.issue_type_name && errors.issue_type_name)}
                helperText={touched.issue_type_name && errors.issue_type_name}
              />
            </Grid>
          </Grid>
          <Box display="flex" sx={{ mt: 1 }}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleEditClose}
              sx={{ width: '8rem', height: '2.5rem', mt: 2 }}
            >
              Cancel
            </Button>
            <LoadingButton
              sx={{
                width: '8rem',
                height: '2.5rem',
                mt: 2,
                ml: 2
              }}
              variant="gradient"
              loadingPosition="start"
              id="create-token"
              type="submit"
            >
              Edit
            </LoadingButton>
          </Box>
        </Form>
      </FormikProvider>
    </>
  );
};

export default EditIssueType;
