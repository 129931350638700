import React from 'react';
import yplogo from '../assets/images/yplogo.png';
import { Box, Grid, Container, Typography } from '@mui/material';
import xinfinnetworklogo from '../assets/images/xinfinnetworklogo.png';

const XinfinLogin = () => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'absolute',
          bottom: '1rem',
          right: '1rem',
          mt: '3.125rem'
        }}
      >
        {/* <Grid container lg={12} md={12} sm={12} sx={{
                display: 'flex',
                flexDirection: 'row',
                textAlign: 'center',
                justifyContent: 'space-around',
                alignItems: 'center',
            }}> */}
        {/* <Grid item lg={7} md={4} sm={4}  > */}
        <Typography variant="h6" sx={{ fontSize: '1.5rem', mr: 2, color: '#8e8ea7' }}>
          Co-Powered By :
        </Typography>
        <Box sx={{ height: '3rem', width: 'auto', mr: 2 }} component="img" src={yplogo} />
        {/* </Grid> */}
        {/* <Grid item lg={5} md={4} sm={4} > */}
        <Box sx={{ height: '3.5rem', width: 'auto' }} component="img" src={xinfinnetworklogo} />
        {/* </Grid> */}
        {/* </Grid> */}
      </Box>
    </>
  );
};

export default XinfinLogin;
