import React, { useEffect, useMemo } from 'react';
import EnhancedTable from 'components/tables/EnhancedTable';
import { useBusinessEntityState, useWeb3 } from 'state';
import CopyAddress from 'components/CopyAddress';
import { Chip } from '@mui/material';
import { titleCase } from 'helpers/text';

const PaymentTxnTable = () => {
  const { paymentTransactionByXinfinList, fetchPaymentTransactionByXinfin } =
    useBusinessEntityState();

  const { account } = useWeb3();

  const fetchPaymentTransaction = () => {
    fetchPaymentTransactionByXinfin(account);
  };

  useEffect(() => {
    fetchPaymentTransaction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  const headCells = useMemo(
    () => [
      {
        accessor: 'issuer_name',
        Header: 'Issuer',
        show: true
      },
      {
        accessor: 'token_id.token_symbol',
        Header: 'Token',
        show: true
      },
      {
        accessor: 'payment_token_id.symbol',
        Header: 'Payment Token',
        show: true
      },
      {
        accessor: 'crypto_asset_transaction_type.transaction_type',
        Header: 'Txn. Type',
        show: true,
        width: 180,
        Cell: ({ value }) => <Chip label={value} color="success" />
      },
      {
        accessor: 'transaction_amount',
        Header: 'Payment received',
        show: true,
        Cell: ({ value }) => Number(value).toFixed(2)
      },
      {
        accessor: 'transaction_hash',
        Header: 'Tnx. Id',
        show: true,
        Cell: ({ value }) => <CopyAddress addr={value} ethAddr txHash />
      },
      {
        accessor: 'payment_date',
        Header: 'Txn. Date',
        show: true
      }
    ],

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [paymentTransactionByXinfinList]
  );

  return (
    <>
      <EnhancedTable
        tableTitle="Payment Transactions"
        columns={headCells}
        refreshFunction={fetchPaymentTransaction}
        data={paymentTransactionByXinfinList}
      />
    </>
  );
};

export default PaymentTxnTable;
