import { alpha, styled } from '@mui/material/styles';
import { Button } from '@mui/material';

const BlueButton = styled(Button)(() => ({
  width: '124px',
  height: '50px',
  bordeRadius: '6px',
  backgroundColor: ' #5178f9',
  fontFamily: 'Roboto',
  fontSize: '16px',
  textAlign: 'center',
  color: '#fcfcfc',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: alpha('#5178f9', 0.5)
  }
}));

export default BlueButton;
