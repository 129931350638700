import Page from 'components/Page';
import React from 'react';
import { Card, Container } from '@mui/material';
import Breadcrumbs from 'components/Breadcrumbs';
import { CardContent } from '@material-ui/core';
import CashTxnTable from 'components/masterData/cashtxn/CashTxnTable';

const AllCashTxn = () => {
  return (
    <Page title="Cash Transaction - Master Data">
      <Container>
        <Breadcrumbs pageHead="Cash Transaction" />

        <Card sx={{ mt: 2 }}>
          <CardContent>
            <CashTxnTable />
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
};

export default AllCashTxn;
