import React, { useState, useEffect } from 'react';
import { Grid, TextField, FormLabel, Box, Alert, Divider } from '@mui/material';
import { useBusinessEntityState, useHttpApi, useWeb3, useAppState, useCoreTableState } from 'state';
import LoadingActionButton from 'helpers/LoadingActionButton';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router';
import { floor } from 'lodash';
import { parseTokenDecimal } from 'helpers/numbers';
import { DesktopDatePicker, LocalizationProvider } from '@mui/lab';
import NAIcon from 'components/NAIcon';
import DateAdapter from '@mui/lab/AdapterDateFns';
import {
  format,
  addDays,
  differenceInMonths,
  isAfter,
  isBefore,
  addWeeks,
  addMonths,
  addYears
} from 'date-fns';

const UpdateTokenTerms = (currencyRate) => {
  const { tokenById, currentToken, setCurrentTokenById, setToken, fetchTokens } =
    useBusinessEntityState();
  const { throwErrorMessage } = useAppState();

  const { enqueueSnackbar } = useSnackbar();
  const [newTokenNav, setNewTokenNav] = useState('');
  const [newInterestAccuralStartDate, setNewInterestAccuralStartDate] = useState(null);
  const { editToken, getTokenDetailsById, getNav } = useHttpApi();
  const [newTokenSupply, setNewTokenSupply] = useState('');
  const [newIssuePrice, setNewIssuePrice] = useState('');
  const [newIssuanceSwapRatio, setNewIssuanceSwapRatio] = useState(0);
  const [newNextPaymentDate, setNextPaymentDate] = useState(null);
  const navigate = useNavigate();
  const { updateTokenIssuanceSwapRatio, setMaxSupply } = useWeb3();
  const { issuanceEscrowOrders, fetchIssuanceEscrowByToken } = useBusinessEntityState();

  const { paymentTokens } = useCoreTableState();

  const handleIssuePriceSwapRatio = (e) => {
    setNewIssuePrice(e.target.value);
    // if (currentToken.payment_leg_id.payment_leg_name === 'Stable Coin') {
    setNewIssuanceSwapRatio((e.target.value * currencyRate.currencyRate).toFixed(2));
    // } else {
    //   setNewIssuanceSwapRatio(0);
    // }
  };

  const UpdateData = async (data, msg, status) => {
    try {
      const _editToken = await editToken(tokenById.token_id, data);
      if (_editToken.token_id) {
        setCurrentTokenById(_editToken);
        const res = await getTokenDetailsById(_editToken.token_id);
        if (res.token_id) {
          setToken(res);
        }
        enqueueSnackbar(`${msg}`, {
          variant: `${status}`
        });
        navigate('/home');
        fetchTokens();
      }
    } catch (e) {
      throwErrorMessage(e);
    }
  };

  useEffect(() => {
    const _fetchData = async () => {
      await fetchIssuanceEscrowByToken();
    };
    _fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Grid container spacing={3} sx={{ width: '100%', pl: 3, mt: 1 }}>
        {<Grid container spacing={3}>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <FormLabel>Token NAV </FormLabel>
            <TextField
              sx={{ mt: 1.5 }}
              value={currentToken.token_nav}
              fullWidth
              inputProps={{
                readOnly: true
              }}
              size="small"
              autoComplete="off"
              type="number"
            />
          </Grid>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <FormLabel>New Token NAV</FormLabel>
            <TextField
              sx={{ mt: 1.5 }}
              fullWidth
              size="small"
              autoComplete="off"
              type="number"
              value={newTokenNav}
              onChange={(e) => setNewTokenNav(e.target.value)}
            />
          </Grid>
          {/* <Grid item lg={2} md={2} sm={12} xs={12}>
            <LoadingActionButton
              sx={{
                width: '7.5rem',
                height: '2.5rem',
                mt: '2.188rem'
              }}
              variant="gradient"
              loadingPosition="start"
              onClick={async () => {
                try {
                  const res = await getNav(tokenById.token_id);
                  setNewTokenNav(res.nav);
                } catch (e) {
                  throwErrorMessage(e);
                }
              }}
            >
              Calculate
            </LoadingActionButton>
          </Grid> */}
          <Grid item lg={2} md={2} sm={12} xs={12}>
            <LoadingActionButton
              sx={{
                width: '7.5rem',
                height: '2.5rem',
                mt: '2.188rem',
                // ml: '1rem'
              }}
              variant="gradient"
              loadingPosition="start"
              onClick={async () => {
                try {
                  if (newTokenNav === '') {
                    enqueueSnackbar('Token Nav is required', {
                      variant: 'error'
                    });
                  } else {
                    console.log('🚀 ~ onClick={ ~ currentToken:', currentToken);
                    if (currentToken.payment_token_id) {
                      if (currentToken.issue_type_id.issue_calc_type === 'NAV') {
                        const weiNewIssuanceSwapRatio = parseTokenDecimal(
                          newTokenNav,
                          currentToken.payment_token_id?currentToken.payment_token_id.decimals:paymentTokens[0]?.decimals
                        );
                        const res = await updateTokenIssuanceSwapRatio(
                          currentToken.deployment_address,
                          weiNewIssuanceSwapRatio
                        );
                      }
                    }
                    const data = {};
                    data.token_nav = newTokenNav;
                    await UpdateData(data, 'Token Nav updated sucessfully', 'success');
                  }
                } catch (e) {
                  throwErrorMessage(e);
                }
              }}
            >
              Update
            </LoadingActionButton>
          </Grid>
        </Grid>}

        <Grid container spacing={3}>
          <Grid item lg={4} md={4} sm={12} xs={12} sx={{ mt: 1.5 }}>
            <FormLabel>Max Supply</FormLabel>
            <TextField
              sx={{ mt: 1.5 }}
              fullWidth
              size="small"
              autoComplete="off"
              type="number"
              inputProps={{
                readOnly: true
              }}
              value={currentToken.total_supply}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={12} xs={12} sx={{ mt: 1.5 }}>
            <FormLabel>New Max Supply</FormLabel>
            <TextField
              sx={{ mt: 1.5 }}
              fullWidth
              size="small"
              autoComplete="off"
              type="number"
              value={newTokenSupply}
              onChange={(e) => {
                setNewTokenSupply(e.target.value);
              }}
            />
          </Grid>

          <Grid item lg={2} md={2} sm={12} xs={12} sx={{ mt: 1.5 }}>
            <LoadingActionButton
              sx={{
                width: '7.5rem',
                height: '2.5rem',
                mt: '2.188rem'
              }}
              variant="gradient"
              loadingPosition="start"
              onClick={async () => {
                // console.log("check", currentToken.existing_token_subscription)
                if (newTokenSupply === '') {
                  enqueueSnackbar('Token Supply is required', {
                    variant: 'error'
                  });
                } else if (currentToken.existing_token_subscription > newTokenSupply) {
                  return enqueueSnackbar(
                    'New Max Supply cannot be less than existing issued count',
                    {
                      variant: 'error'
                    }
                  );
                } else if (currentToken.token_status_id.token_status === 'REVIEWED') {
                  const data = {};
                  data.total_supply = newTokenSupply;
                  data.issue_size = newTokenSupply * tokenById.token_value;
                  await UpdateData(data, 'Token Supply updated sucessfully', 'success');
                } else {
                  try {
                    const res = await setMaxSupply(
                      currentToken.deployment_address,
                      Number(newTokenSupply)
                    );
                    if (res) {
                      const data = {};
                      data.total_supply = newTokenSupply;
                      data.issue_size = newTokenSupply * tokenById.token_value;
                      await UpdateData(data, 'Token Supply submitted to update', 'info');
                    }
                  } catch (e) {
                    throwErrorMessage(e);
                  }
                }
              }}
            >
              Update
            </LoadingActionButton>
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item lg={4} md={4} sm={12} xs={12} sx={{ mt: 1.5 }}>
            <FormLabel>Accrual Start Date</FormLabel>
            <LocalizationProvider dateAdapter={DateAdapter}>
              <DesktopDatePicker
                readOnly
                minDate={new Date()}
                fullWidth
                size="small"
                inputFormat="MM-dd-yyyy"
                sx={{ mt: 1.5 }}
                value={currentToken.interest_accrual_start_date}
                // onChange={finalMaturityDatehandleChange}
                renderInput={(params) => (
                  <TextField sx={{ mt: 1.5 }} fullWidth size="small" {...params} />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item lg={4} md={4} sm={12} xs={12} sx={{ mt: 1.5 }}>
            <FormLabel>New Accrual Start Date</FormLabel>
            <LocalizationProvider dateAdapter={DateAdapter}>
              <DesktopDatePicker
                readOnly={currentToken?.token_issue_price !== currentToken?.token_nav}
                minDate={new Date()}
                fullWidth
                size="small"
                inputFormat="MM-dd-yyyy"
                sx={{ mt: 1.5 }}
                value={newInterestAccuralStartDate}
                onChange={(e) => {
                  setNewInterestAccuralStartDate(format(e, 'yyyy-MM-dd'));
                }}
                renderInput={(params) => (
                  <TextField sx={{ mt: 1.5 }} fullWidth size="small" {...params} />
                )}
              />
            </LocalizationProvider>
          </Grid>

          <Grid item lg={2} md={2} sm={12} xs={12} sx={{ mt: 1.5 }}>
            <LoadingActionButton
              disabled={currentToken?.token_issue_price !== currentToken?.token_nav}
              sx={{
                width: '7.5rem',
                height: '2.5rem',
                mt: '2.188rem'
              }}
              variant="gradient"
              loadingPosition="start"
              onClick={async () => {
                if (!newInterestAccuralStartDate) {
                  enqueueSnackbar('New Accrual Start Date is required', {
                    variant: 'error'
                  });
                } else {
                  const data = {};
                  // data.final_maturity_date = newInterestAccuralStartDate;
                  data.interest_accrual_start_date = newInterestAccuralStartDate;
                  await UpdateData(data, 'Accrual Start Date updated sucessfully', 'success');
                }
              }}
            >
              Update
            </LoadingActionButton>
          </Grid>
        </Grid>

        {/* <Grid container spacing={3}>
          <Grid item lg={4} md={4} sm={12} xs={12} sx={{ mt: 1.5 }}>
            <FormLabel>Next Payment Due Date </FormLabel>
            <TextField
              sx={{ mt: 1.5 }}
              fullWidth
              inputProps={{
                readOnly: true
              }}
              size="small"
              value={
                currentToken.next_payment_due_date ? (
                  format(new Date(currentToken.next_payment_due_date), 'MM-dd-yyyy')
                ) : (
                  <NAIcon />
                )
              }
            />
          </Grid>
          <Grid item lg={4} md={4} sm={12} xs={12} sx={{ mt: 1.5 }}>
            <FormLabel>New Next Payment Due Date </FormLabel>
            <LocalizationProvider dateAdapter={DateAdapter}>
              <DesktopDatePicker
                fullWidth
                size="small"
                inputFormat="MM-dd-yyyy"
                sx={{ mt: 1.5 }}
                value={newNextPaymentDate}
                onChange={(newValue) => {
                  setNextPaymentDate(format(newValue, 'yyyy-MM-dd'));
                }}
                minDate={new Date()}
                renderInput={(params) => (
                  <TextField
                    sx={{ mt: 1.5 }}
                    fullWidth
                    size="small"
                    {...params}
                    value={newNextPaymentDate}
                    id="newNextPaymentDate"
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item lg={2} md={2} sm={12} xs={12} sx={{ mt: 1.5 }}>
            <LoadingActionButton
              sx={{
                width: '7.5rem',
                height: '2.5rem',
                mt: '2.188rem'
              }}
              variant="gradient"
              loadingPosition="start"
              onClick={async () => {
                if (newNextPaymentDate === null) {
                  enqueueSnackbar('Next Payment Due Date is required', {
                    variant: 'error'
                  });
                } else {
                  const data = {};
                  data.next_payment_due_date = newNextPaymentDate;
                  await UpdateData(data, 'Next Payment Due Date updated sucessfully', 'success');
                }
              }}
            >
              Update
            </LoadingActionButton>
          </Grid>
        </Grid> */}

        {/* <Grid container spacing={3}>
          {issuanceEscrowOrders.length !== 0 && (
            <>
              <Grid item lg={12} md={2} sm={12} xs={12} sx={{ mt: 3 }}>
                <Divider sx={{ mb: 1.5, border: '2px solid #f5f8fa' }} />
                <Alert severity="warning">
                  Token {currentToken.token_name}, currently has Issuance Escrow Open, cannot Update
                  Issue Price and Issuance Swap Ratio!
                </Alert>
              </Grid>
            </>
          )}
          <Grid item lg={4} md={4} sm={12} xs={12} sx={{ mt: 1.5 }}>
            <FormLabel>Issue Price</FormLabel>
            <TextField
              sx={{ mt: 1.5 }}
              fullWidth
              type="number"
              inputProps={{
                readOnly: true
              }}
              size="small"
              value={currentToken.token_issue_price}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={12} xs={12} sx={{ mt: 1.5 }}>
            <FormLabel>New Issue Price</FormLabel>
            <TextField
              sx={{ mt: 1.5 }}
              fullWidth
              type="number"
              size="small"
              autoComplete="off"
              value={newIssuePrice}
              onChange={handleIssuePriceSwapRatio}
            />
          </Grid>
          <Grid item lg={2} md={2} sm={12} xs={12} sx={{ mt: 1.5 }}>
            <LoadingActionButton
              sx={{
                width: '7.5rem',
                height: '2.5rem',
                mt: '2.188rem'
              }}
              variant="gradient"
              disabled={issuanceEscrowOrders.length !== 0}
              loadingPosition="start"
              onClick={async () => {
                if (newIssuePrice === '') {
                  enqueueSnackbar('Issue Price is required', {
                    variant: 'error'
                  });
                } else if (currentToken.token_status_id.token_status === 'REVIEWED') {
                  const data = {};
                  data.token_issue_price = newIssuePrice;
                  data.swap_ratio_issue = newIssuanceSwapRatio;
                  await UpdateData(
                    data,
                    // currentToken.payment_leg_id.payment_leg_name === 'Stable Coin'
                    //   ? 'Issue Price and Swap Ratio is updated sucessfully'
                    //   : 'Issue Price is updated sucessfully',
                    // 'success'
                    'Issue Price and Swap Ratio is updated sucessfully',
                    'success'
                  );
                } else {
                  try {
                    const weiNewIssuanceSwapRatio = parseTokenDecimal(
                      newIssuanceSwapRatio,
                      currentToken.payment_token_id.decimals
                    );
                    const res = await updateTokenIssuanceSwapRatio(
                      currentToken.deployment_address,
                      weiNewIssuanceSwapRatio
                    );
                    if (res) {
                      const data = {};
                      data.token_issue_price = newIssuePrice;
                      // data.swap_ratio_issue = newIssuanceSwapRatio;
                      await UpdateData(
                        data,
                        'Issue Price and Swap Ratio is submitted to update',
                        'info'
                      );
                    }
                  } catch (e) {
                    throwErrorMessage(e);
                  }
                }
              }}
            >
              Update
            </LoadingActionButton>
          </Grid>
        </Grid> */}

        {/* <Grid container spacing={3}>
          <Grid item lg={4} md={4} sm={12} xs={12} sx={{ mt: 1.5 }}>
            <FormLabel>Issuance Swap Ratio</FormLabel>
            <TextField
              sx={{ mt: 1.5 }}
              fullWidth
              size="small"
              autoComplete="off"
              type="number"
              value={currentToken.swap_ratio_issue}
              inputProps={{ 'aria-label': 'Without label', readOnly: true }}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={12} xs={12} sx={{ mt: 1.5 }}>
            <FormLabel>New Issuance Swap Ratio</FormLabel>
            <TextField
              sx={{ mt: 1.5 }}
              fullWidth
              size="small"
              autoComplete="off"
              type="number"
              value={newIssuanceSwapRatio}
              inputProps={{ 'aria-label': 'Without label', readOnly: true }}
            />
          </Grid>
        </Grid> */}
      </Grid>
    </>
  );
};

export default UpdateTokenTerms;
