import {
  Container,
  Card,
  Table,
  TableCell,
  TableBody,
  Box,
  Chip,
  TableRow,
  TableHead,
  CardContent,
  IconButton,
  Typography,
  Modal,
  Alert,
  AlertTitle
} from '@mui/material';
import Page from 'components/Page';
import React, { useEffect, useState } from 'react';
import Breadcrumbs from 'components/Breadcrumbs';
import SubsDetails from 'components/masterData/Dashboard/SubsDetails';
import VisibilityIcon from '@mui/icons-material/Visibility';
import InvestorDocMgt from 'components/DocMangement/InvestorDocMgt';
import { useBusinessEntityState, useAppState, useHttpApi } from 'state';
import CopyAddress from 'components/CopyAddress';
import { ethToXdcAddress } from 'helpers/web3';
import { useSnackbar } from 'notistack';
import LoadingActionButton from 'helpers/LoadingActionButton';
import ScrollToBottom from 'components/ScrollToBottom';

const DocMgtUser = () => {
  const { currentToken: token } = useBusinessEntityState();
  const {
    currentKycInvestor: investor,
    currentDocumentPageDocuments: uploadedDocuments,
    currentSubscriptionId: subscriptionId,
    setDocumentPageDocuments,
    kycStatus
  } = useBusinessEntityState();

  const { enqueueSnackbar } = useSnackbar();
  const [modalOpen, setModalOpen] = React.useState(false);
  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);
  const { throwErrorMessage } = useAppState();
  const { updateSubscriptionDocumentsDirectly } = useHttpApi();

  const [requiredDocuments, setrequiredDocuments] = useState(uploadedDocuments);
  const [stateChanged, setstateChanged] = useState(false);

  const handleRadioButtonSelect = (event) => {
    const updatedRequiredDocs = requiredDocuments.map((doc) => {
      if (doc.id === event.target.name) {
        doc.is_approved = event.target.value === 'VERIFIED';
      }
      return doc;
    });
    setrequiredDocuments(updatedRequiredDocs);
    setstateChanged(true);
  };

  const handleSubmit = async () => {
    try {
      const updatedRequiredDocs = {
        ...requiredDocuments,
        direct: true,
        subscription_id: subscriptionId
      };
      console.log(updatedRequiredDocs);
      const res = await updateSubscriptionDocumentsDirectly(updatedRequiredDocs);
      if (res) {
        enqueueSnackbar('Documents Updated Successfully', { variant: 'success' });
        setstateChanged(false);
      }
    } catch (e) {
      throwErrorMessage(e);
    }
  };

  return (
    <>
      <ScrollToBottom />
      <Modal
        open={modalOpen}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <SubsDetails investor={investor} handleClose={closeModal} />
      </Modal>
      <Page title="Document Management">
        <Container sx={{ mt: 3 }}>
          <Breadcrumbs aria-label="breadcrumb" pageHead="Investor Document Management" />
          <Card sx={{ mt: 2, p: 3 }}>
            <Box>
              <Card
                sx={{
                  mt: 2,
                  backgroundColor: '#f5f8fa',
                  boxShadow: 'none',
                  border: 'solid 2px #eff1f2'
                }}
              >
                <CardContent>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Symbol</TableCell>
                        <TableCell>Issue Type</TableCell>
                        <TableCell>Currency</TableCell>
                        <TableCell>Issue Size</TableCell>
                        <TableCell>Price</TableCell>
                        <TableCell>Supply</TableCell>
                        <TableCell>Status</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>{token.token_name}</TableCell>
                        <TableCell>{token.token_symbol}</TableCell>
                        <TableCell>{token.issue_type_id.issue_type_name}</TableCell>
                        <TableCell>{token.token_currency.currency_code}</TableCell>
                        <TableCell>{token.issue_size}</TableCell>
                        <TableCell>{token.token_issue_price}</TableCell>
                        <TableCell>{token.total_supply}</TableCell>
                        <TableCell>
                          <Chip label={token.token_status_id.token_status_desc} color="success" />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </CardContent>
              </Card>
            </Box>
            <Box>
              <Card sx={{ mt: 2, boxShadow: 'none', border: 'solid 2px #eff1f2' }}>
                <CardContent>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>XinFin Address</TableCell>
                        <TableCell>KYC Status</TableCell>
                        {/* <TableCell>Phone Verification</TableCell> */}
                        <TableCell>Sub Details</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>{investor.investor_name}</TableCell>
                        <TableCell>
                          <CopyAddress addr={ethToXdcAddress(investor.xinfin_account)} />
                        </TableCell>
                        <TableCell>
                          {(() => {
                            switch (kycStatus) {
                              case 'COMPLETE':
                                return <Chip label="VERIFIED" color="success" />;
                              case 'ONCHAINCOMPLETE':
                                return <Chip label="ONCHAIN KYC UPDATED" color="primary" />;
                              default:
                                return <Chip label="PENDING" color="warning" />;
                            }
                          })()}
                        </TableCell>
                        {/* <TableCell>
                          <Chip label="Verfified" color="success" />
                        </TableCell> */}
                        <TableCell>
                          <IconButton aria-label="subs detail" onClick={openModal}>
                            <VisibilityIcon
                              sx={{ fontSize: '2.188rem', p: 1, backgroundColor: '#F5F8FA' }}
                            />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </CardContent>
              </Card>
            </Box>
          </Card>
          <Card sx={{ my: 2, p: 1 }}>
            <Typography
              sx={{
                fontSize: '1.275rem',
                p: 2,
                my: 1,
                fontWeight: 'bold'
              }}
            >
              Investor Documents
            </Typography>
            <InvestorDocMgt
              requiredDocuments={requiredDocuments}
              handleRadioButtonSelect={handleRadioButtonSelect}
            />
          </Card>
          <Card
            sx={{
              my: 2,
              p: 2,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end'
            }}
          >
            <LoadingActionButton
              variant={stateChanged ? 'gradient' : 'outlined'}
              color="primary"
              sx={{ borderColor: '#24ABDF', width: '15rem' }}
              loadingPosition="start"
              onClick={handleSubmit}
              disabled={!stateChanged}
            >
              Update
            </LoadingActionButton>
          </Card>
        </Container>
      </Page>
    </>
  );
};

export default DocMgtUser;
