import React, { useCallback, useEffect, useMemo, useState } from 'react';
import EnhancedTable from 'components/tables/EnhancedTable';
import {
  Box,
  IconButton,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  Alert,
  AlertTitle
} from '@mui/material';

import Delete from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useBusinessEntityState } from 'state/useBusinessEntityState';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { ethToXdcAddress, xdcToEthAddress } from 'helpers/web3';
import { useNavigate } from 'react-router';
import { useHttpApi, useWeb3, useAuthStateShared, useAppState } from 'state';
import { useSnackbar } from 'notistack';
import { HeaderName } from 'helpers/tableHelper';
import TransactionHandler from 'components/TransactionHandler';
import { LoadingButton } from '@mui/lab';
import CopyAddress from 'components/CopyAddress';
import { useIsIssuer, useIsOwner } from 'helpers/rbac';

const FundManagerName = HeaderName('Fund Manager Name');
const CountryCode = HeaderName('Country Code');

const ActionButtons = ({
  fundManagerId,
  fundManagerStatus,
  fundManagerXinfin,
  fundManagerName
}) => {
  const { fetchFundManagers, setEditFundManager } = useBusinessEntityState();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { throwErrorMessage } = useAppState();
  const { getFundManagerById, deleteFundManager } = useHttpApi();
  const navigate = useNavigate();
  const { removeFundManager: removeFundManagerBlockchain } = useWeb3();
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const [loadingState, setLoadingState] = useState(false);
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          m: 0,
          p: 0,
          alignItems: 'center'
        }}
      >
        <IconButton
          aria-label="subs detail"
          onClick={async () => {
            const response = await getFundManagerById(fundManagerId);
            console.log('ressss', response);
            response.primary_fund_manager_xinfin_address = ethToXdcAddress(
              response.primary_fund_manager_xinfin_address
            );
            setEditFundManager(response);
            navigate('/masterdata/fund-manager/edit-fund-manager');
            console.log('edit fund-manager id', fundManagerId);
          }}
        >
          <EditIcon sx={{ fontSize: '2.188rem', m: 0, p: 1, backgroundColor: '#F5F8FA' }} />
        </IconButton>

        <IconButton aria-label="subs detail" onClick={handleOpen}>
          <Delete sx={{ fontSize: '2.188rem', p: 1, backgroundColor: '#F5F8FA' }} />
        </IconButton>
      </Box>
      <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title">
        <Alert severity="warning">
          <AlertTitle>Delete Fund Manager</AlertTitle>
          <DialogContent>
            Are you sure you want to delete Fund Manager, {fundManagerName}?
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleClose}
              sx={{ width: '6.7rem' }}
            >
              Cancel
            </Button>
            <LoadingButton
              variant="outlined"
              color="error"
              loadingPosition="start"
              sx={{ width: '6.7rem' }}
              loading={loadingState}
              onClick={async () => {
                setLoadingState(true);
                console.log('delete fundManager id', fundManagerId, fundManagerStatus);
                if (fundManagerStatus === 'ONCHAIN') {
                  try {
                    const res = await removeFundManagerBlockchain(
                      xdcToEthAddress(fundManagerXinfin)
                    );
                    if (res) {
                      enqueueSnackbar('Fund Manager is submitted to Delete', {
                        variant: 'info'
                      });
                      fetchFundManagers();
                    }
                  } catch (e) {
                    throwErrorMessage(e);
                  }
                } else {
                  await deleteFundManager(fundManagerId);
                  enqueueSnackbar('Fund Manager deleted successfully', {
                    variant: 'success'
                  });
                  fetchFundManagers();
                }
                setLoadingState(false);
                handleClose();
              }}
            >
              Delete
            </LoadingButton>
          </DialogActions>
        </Alert>
      </Dialog>
    </>
  );
};

const FundManagerChainStatus = ({ status, countryCode, address }) => {
  const { addFundManager } = useWeb3();

  const commitTransaction = useCallback(
    (hash) => {
      return addFundManager(countryCode, address, false, hash);
    },
    [addFundManager, address, countryCode]
  );

  const { fetchFundManager } = useBusinessEntityState();

  const onConfirmed = useCallback(() => {
    fetchFundManager();
  }, [fetchFundManager]);

  return (
    <TransactionHandler
      id={`add-fund-manager-${address}`}
      commitTransaction={commitTransaction}
      done={status === 'ONCHAIN'}
      onConfirmed={onConfirmed}
    >
      {({ status: txStatus, error, receipt, send }) => {
        console.log('TransactionHandler', txStatus, error && error.message, receipt);

        return (
          <Chip
            // onClick={status==="OFFCHAIN"?send:null}
            label={status}
            color={status === 'OFFCHAIN' ? 'warning' : 'success'}
          />
        );
      }}
    </TransactionHandler>
  );
};

export default function FundManagerTable() {
  const { fundManagers, fetchFundManagers } = useBusinessEntityState();

  const isOwner = useIsOwner();
  const isIssuer = useIsIssuer();
  const headCells = useMemo(
    () => [
      {
        accessor: 'fund_manager_name',
        Header: FundManagerName,
        show: true
      },
      {
        accessor: 'primary_fund_manager_xinfin_address',
        Header: 'Xinfin Address',
        width: 200,
        Cell: ({ value }) => {
          return <CopyAddress addr={ethToXdcAddress(value)} />;
        },
        show: true
      },

      {
        accessor: 'fund_manager_country_code.country_code',
        Header: CountryCode,
        show: true
      },
      {
        accessor: 'status',
        Header: 'Status',
        Cell: ({ row: { original } }) => {
          return (
            <FundManagerChainStatus
              status={original.status}
              countryCode={original.fund_manager_country_code.country_code}
              address={original.primary_fund_manager_xinfin_address}
            />
          );
        },
        show: true
      },
      {
        accessor: 'actions',
        Header: 'Actions',
        Cell: ({ row: { original } }) => {
          return (
            <ActionButtons
              fundManagerId={original.fund_manager_id}
              fundManagerName={original.fund_manager_name}
              fundManagerStatus={original.status}
              fundManagerXinfin={original.primary_fund_manager_xinfin_address}
            />
          );
        },
        show: isOwner
      }
    ],
    [isOwner, isIssuer]
  );
  return (
    <>
      <EnhancedTable
        tableTitle="Fund Managers"
        columns={headCells}
        refreshFunction={fetchFundManagers}
        data={fundManagers}
      />
    </>
  );
}
