import React from 'react';
import { useAuthStateShared } from 'state';

const RbacShowHideComponent = ({ children, allowedRoles }) => {
  const { role } = useAuthStateShared();

  const condition = allowedRoles.includes(role);

  return condition ? children : null;
};

export default RbacShowHideComponent;
