import { toChecksumAddress } from 'helpers/web3';
import { useAuthState, useWeb3 } from 'state';
import Web3 from 'web3';

const RequireUserXinFinAccount = ({ children, not = false }) => {
  const { account, active } = useWeb3();
  const { user, isAuthorized } = useAuthState();
  const xinfinAddress = user !== null ? user.xinfin_address.xinfin_account_address : null;
  const condition =
    // eslint-disable-next-line no-nested-ternary
    xinfinAddress === null
      ? false
      : !active || !account || toChecksumAddress(account) === xinfinAddress
        ? toChecksumAddress(xinfinAddress)
        : false;
  const show = not ? !condition : condition;

  return show ? children : null;
};

export default RequireUserXinFinAccount;
