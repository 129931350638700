import React, { useEffect, useMemo, useState } from 'react';
import EnhancedTable from 'components/tables/EnhancedTable';
import {
  Box,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  Alert,
  AlertTitle
} from '@mui/material';
import Delete from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useBusinessEntityState } from 'state/useBusinessEntityState';
import { useNavigate } from 'react-router';
import { useAuthStateShared, useHttpApi, useAppState } from 'state';
import { useSnackbar } from 'notistack';
import { HeaderName } from 'helpers/tableHelper';
import { LoadingButton } from '@mui/lab';
import { useIsOwner } from 'helpers/rbac';

const OriginatorName = HeaderName('Originator Name');
const CountryCode = HeaderName('Country Code');

const ActionButtons = ({ originatorId, originatorName }) => {
  const [open, setOpen] = useState(false);
  const { fetchOriginator, setEditOriginator } = useBusinessEntityState();
  const { getOriginatorById, deleteOriginator } = useHttpApi();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { throwErrorMessage } = useAppState();
  const navigate = useNavigate();
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const [loadingState, setLoadingState] = useState(false);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          m: 0,
          p: 0,
          alignItems: 'center'
        }}
      >
        <IconButton
          aria-label="subs detail"
          onClick={async () => {
            const response = await getOriginatorById(originatorId);
            console.log('ressss', response);
            setEditOriginator(response);
            navigate('/masterdata/originator/edit-originator');
            console.log('edit originator id', originatorId);
          }}
        >
          <EditIcon sx={{ fontSize: '2.188rem', m: 0, p: 1, backgroundColor: '#F5F8FA' }} />
        </IconButton>

        <IconButton aria-label="subs detail" onClick={handleOpen}>
          <Delete sx={{ fontSize: '2.188rem', p: 1, backgroundColor: '#F5F8FA' }} />
        </IconButton>
      </Box>
      <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title">
        <Alert severity="warning">
          <AlertTitle>Delete Originator</AlertTitle>
          <DialogContent>
            Are you sure you want to delete Originator, {originatorName}?
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" color="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <LoadingButton
              variant="outlined"
              color="error"
              onClick={async () => {
                setLoadingState(true);
                console.log('delete originator id', originatorId);
                try {
                  const res = await deleteOriginator(originatorId);
                  console.log('res', res);
                  if (res) {
                    console.log('deleted');
                    enqueueSnackbar('Originator deleted successfully', {
                      variant: 'success'
                    });
                    fetchOriginator();
                  }
                } catch (e) {
                  throwErrorMessage(e);
                }
                setLoadingState(false);
                handleClose();
              }}
            >
              Delete
            </LoadingButton>
          </DialogActions>
        </Alert>
      </Dialog>
    </>
  );
};

export default function OriginatorTable() {
  const { fetchOriginator, originators } = useBusinessEntityState();
  // const [data, setData] = useState(originators);
  // const [skipPageReset, setSkipPageReset] = React.useState(false);
  // const [owner, setOwner] = useState(false);
  // const { role } = useAuthStateShared();

  const isOwner = useIsOwner();

  const headCells = useMemo(
    () => [
      {
        accessor: 'originator_name',
        Header: OriginatorName,
        show: true
      },
      // {
      //   accessor: 'lei',
      //   Header: 'LEI',
      //   show: true
      // },

      {
        accessor: 'originator_country_code.country_code',
        Header: CountryCode,
        show: true
      },

      {
        accessor: 'actions',
        Header: 'Actions',
        Cell: ({ row: { original } }) => (
          <ActionButtons
            originatorId={original.originator_id}
            originatorName={original.originator_name}
          />
        ),
        show: isOwner
      }
    ],
    [isOwner]
  );

  // useEffect(() => {
  //   fetchOriginator();
  // }, []);

  // useEffect(() => {
  //   if (role === 'OWNER') {
  //     setOwner(true);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // useEffect(() => {
  //   const _originators = originators.map((originators) => {
  //     console.log('here', originators);

  //     originators.actions = (
  //       <ActionButtons
  //         originatorId={originators.originator_id}
  //         originatorName={originators.originator_name}
  //       />
  //     );
  //     return originators;
  //   });

  //   setData(_originators);
  // }, [originators]);

  // const updateMyData = (rowIndex, columnId, value) => {
  //   setSkipPageReset(true);
  //   setData((old) =>
  //     old.map((row, index) => {
  //       if (index === rowIndex) {
  //         return {
  //           ...old[rowIndex],
  //           [columnId]: value
  //         };
  //       }
  //       return row;
  //     })
  //   );
  // };

  return (
    <>
      <EnhancedTable
        refreshFunction={fetchOriginator}
        tableTitle="Origintor"
        columns={headCells}
        data={originators}
        // setData={setData}
        // updateMyData={updateMyData}
        // skipPageReset={skipPageReset}
      />
    </>
  );
}
