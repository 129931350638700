import React, { useEffect, useState } from 'react';
import EnhancedTable from 'components/tables/EnhancedTable';
import { Box } from '@mui/material';
import { useBusinessEntityState } from 'state/useBusinessEntityState';
import { ethToXdcAddress } from 'helpers/web3';
import { useWeb3 } from 'state';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CopyAddress from 'components/CopyAddress';
import NAIcon from 'components/NAIcon';

const IssuerEscrowStatus = ({ status }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      {status ? <CheckCircleIcon color="success" /> : <CancelIcon color="error" />}
    </Box>
  );
};

const SubcriberEscrowStatus = ({ status }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      {status ? <CheckCircleIcon color="success" /> : <CancelIcon color="error" />}
    </Box>
  );
};

const CollateralStatus = ({ status }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      {status ? <CheckCircleIcon color="success" /> : <CancelIcon color="error" />}
    </Box>
  );
};

export default function InsurerIssuanceEscrowTable({ fetchData: _fetchData }) {
  const [data, setData] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const { getIssuanceEscrowCondition } = useWeb3();
  const {
    insurerIssuerIssuanceEscrowOrder,
    fetchIssuanceEscrowOrderByInsurerIssuerId,
    currCreditIssuer,
    currCreditInsurer
  } = useBusinessEntityState();

  // const _fetchData = async () => {
  //   await fetchIssuanceEscrowOrderByInsurerIssuerId(
  //     currCreditIssuer.issuer_id,
  //     currCreditInsurer.insurer_id
  //   );
  // };
  // useEffect(() => {
  //   _fetchData();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const headCells = [
    {
      accessor: 'investor.investor_name',
      Header: 'Investor Name',
      width: 100,
      show: true
    },
    {
      accessor: 'investor_xinfin_account',
      Header: 'Xinfin Address',
      width: 200,
      show: true,
      Cell: ({ value }) => <CopyAddress addr={ethToXdcAddress(value)} />
    },
    {
      accessor: 'trade_token_amount',
      Header: 'Qty',
      width: 100,
      show: true
    },
    {
      accessor: 'insurer_collateral',
      Header: 'Collateral required',
      width: 100,
      show: true,
      Cell: ({ value }) => (value === 0 ? 'NA' : <>{value}</>)
    },
    {
      accessor: 'subcriber_status',
      Header: 'Investor Escrowed',
      width: 110,
      show: true
    },
    {
      accessor: 'issuer_status',
      Header: 'Issuer Escrowed',
      width: 100,
      show: true
    },
    {
      accessor: 'insurer_collateral_status',
      Header: 'Collateral Status',
      width: 100,
      show: true
    },
    {
      accessor: 'issuer_collateral_status',
      Header: 'Issuer Collateral Status',
      width: 100,
      show: true
    }
  ];

  useEffect(() => {
    // TODO: Fetch Investors
    const _prepareRows = async () => {
      if (insurerIssuerIssuanceEscrowOrder.length !== 0) {
        console.log({ insurerIssuerIssuanceEscrowOrder });
        const _rows = await Promise.all(
          insurerIssuerIssuanceEscrowOrder.map(async (row) => {
            const condition = await getIssuanceEscrowCondition(row.escrow_order_id);
            console.log(condition);
            row.issuer_status = <IssuerEscrowStatus status={condition[0]} />;
            row.subcriber_status = <SubcriberEscrowStatus status={condition[2]} />;
            row.issuer_collateral_status =
              row.collateral_value === 0 ? <NAIcon /> : <CollateralStatus status={condition[1]} />;
            row.insurer_collateral_status =
              row.collateral_value === 0 ? <NAIcon /> : <CollateralStatus status={condition[3]} />;
            return row;
          })
        );
        setData(_rows);
        setIsLoaded(true);
      }
    };
    _prepareRows();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insurerIssuerIssuanceEscrowOrder]);
  return (
    <>
      <EnhancedTable
        tableTitle="InsurerIssuanceEscrow"
        columns={headCells}
        data={data}
        setData={setData}
        refreshFunction={_fetchData}
      />
    </>
  );
}
