import { AuthStateSharedProvider } from './useAuthStateShared';
import { HttpApiProvider } from './useHttpApi';
import { RbacProvider } from './useRbac';
import { AuthStateProvider } from './useAuthState';
import { CoreTablesProvider } from './useCoreTableState';
import { Web3StateProvider } from './useWeb3State';
import { CurrentAccountBalanceProvider } from './useCurrentAccountBalance';
import { BusinessEntityProvider } from './useBusinessEntityState';
import { SubscriptionStateProvider } from './useSubscriptionState';
import { AppStateProvider } from './useAppState';
import { MagicStateProvider } from './useMagicState';

export const GlobalAppStateProvider = ({ children }) => {
  const Providers = [
    MagicStateProvider,
    AuthStateSharedProvider,
    HttpApiProvider,
    RbacProvider,
    AuthStateProvider,
    CoreTablesProvider,
    BusinessEntityProvider,
    SubscriptionStateProvider,
    Web3StateProvider,
    CurrentAccountBalanceProvider,
    AppStateProvider
  ];

  let element = children;

  while (Providers.length > 0) {
    const Component = Providers.pop();

    element = <Component>{element}</Component>;
  }

  return element;
};

export { useAuthStateShared, useSetLoggedOutState, useUser } from './useAuthStateShared';
export { useHttpApi, useHttpApiLoading } from './useHttpApi';
export { useRbacActions, useRbacPredicates } from './useRbac';
export { useAuthState } from './useAuthState';
export { useMagicState } from './useMagicState';
export { useWeb3State as useWeb3 } from './useWeb3State';
export { useCoreTableState } from './useCoreTableState';
export { useBusinessEntityState } from './useBusinessEntityState';
export { useSubscriptionState } from './useSubscriptionState';
export { useCurrentAccountBalance } from './useCurrentAccountBalance';
export { useAppState } from './useAppState';
