import { Fab, Box, IconButton } from '@mui/material';
import React from 'react';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import EmailIcon from '@mui/icons-material/Email';

const HelpButton = () => {
  const handleEmailClick = () => {
    window.location.href = 'mailto:mail@totusrecall.com';
  };
  return (
    <button onClick={handleEmailClick}>
      <Fab
        sx={{
          margin: 0,
          top: 'auto',
          right: 20,
          bottom: 20,
          left: 'auto',
          position: 'fixed',
          color: 'white',
          textTransform: 'none',
          backgroundColor: '#112B3D',
          '&:hover': {
            background: '#34495e'
          },
          'z-index': 3
        }}
        variant="extended"
      >
        <Box>
          Contact
          <IconButton
            component="div"
            sx={{
              background: `linear-gradient(to right, #25aae1 7%, #18bfc5 57%, #0dd1ae 97%)`,
              m: 0,
              ml: 1,
              p: 0.7,
              color: 'white'
            }}
          >
            <EmailIcon />
          </IconButton>
        </Box>
      </Fab>
    </button>
  );
};

export default HelpButton;
