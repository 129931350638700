import constate from 'constate';
import coreTablePersistence from 'persistence/coreTablePersistence';
import { useState, useEffect } from 'react';
import { useHttpApi } from 'state/useHttpApi';
import { useAuthState } from 'state/useAuthState';
import useResource from 'helpers/useResource';

const useCoreTableState_ = () => {
  const {
    getAllCountries,
    getAllOrganizations,
    getAllCurrencies,
    getIssueType,
    getKYCStatus,
    // getAllbankAccounts,
    // getAllbeneficieryTypes,
    getAllIssueCategories,
    getAllIssueSubCategories,
    getAllPaymentLegs,
    getAllPaymentBasis,
    getAllPaymentFreq,
    getAllInterestType,
    getAllInterestCalcMethod,
    getAllAccrualFreq,
    getAllTransactionType,
    getAllTokenStatus,
    getAllTokens,
    getAllPaymentTokens,
    // getAllCollateralTokens,
    getCollateralProvider,
    getAllInvestorClassifications,
    getAllPoolPaymentTokens,
    getAllPoolPaymentTokenByPoolAddr,
    getPaymentTokenCurrencies,
    getAllTokenFeildValues,
    getAllDefaultValues
  } = useHttpApi();
  const { isAuthorized } = useAuthState();
  const [fetchCountries, countries] = useResource('countries', () => getAllCountries(), [], {
    noFetchIfCached: true
  });
  const [fetchOrganizations, orgnizations] = useResource(
    'organizations',
    () => getAllOrganizations(),
    []
  );
  const [fetchCurrencies, currencies] = useResource('currencies', () => getAllCurrencies(), [], {
    noFetchIfCached: true
  });
  const [fetchPaymentTokenCurrencies, paymentTokenCurrencies] = useResource(
    'paymentTokenCurrencies',
    () => getPaymentTokenCurrencies(),
    [],
    {
      noFetchIfCached: true
    }
  );
  const [fetchIssueType, issueType] = useResource('issueType', () => getIssueType(), [], {
    noFetchIfCached: true
  });
  const [fetchKycStatus, kycStatus] = useResource('kycStatus', () => getKYCStatus(), [], {
    noFetchIfCached: true
  });
  const [fetchIssueCategories, issueCategories] = useResource(
    'issueCategories',
    () => getAllIssueCategories(),
    [],
    { noFetchIfCached: true }
  );
  const [fetchIssueSubCategories, issueSubCategories] = useResource(
    'issueSubCategories',
    () => getAllIssueSubCategories(),
    [],
    { noFetchIfCached: true }
  );
  const [fetchPaymentLegs, paymentLegs] = useResource(
    'paymentLegs',
    () => getAllPaymentLegs(),
    [],
    { noFetchIfCached: true }
  );
  const [fetchPaymentBasis, paymentBasis] = useResource(
    'paymentBasis',
    () => getAllPaymentBasis(),
    [],
    { noFetchIfCached: true }
  );
  const [fetchPaymentFreq, paymentFreq] = useResource(
    'paymentFreq',
    () => getAllPaymentFreq(),
    [],
    { noFetchIfCached: true }
  );
  const [fetchInterestType, interestType] = useResource(
    'interestType',
    () => getAllInterestType(),
    [],
    { noFetchIfCached: true }
  );
  const [fetchInterestCalcMethod, interestCalcMethod] = useResource(
    'interestCalcMethod',
    () => getAllInterestCalcMethod(),
    [],
    { noFetchIfCached: true }
  );
  const [fetchAccrualFreq, accrualFreq] = useResource(
    'accrualFreq',
    () => getAllAccrualFreq(),
    [],
    { noFetchIfCached: true }
  );
  const [fetchTransactionType, transactionType] = useResource(
    'transactionType',
    () => getAllTransactionType(),
    [],
    { noFetchIfCached: true }
  );
  const [fetchTokenStatus, tokenStatus] = useResource(
    'tokenStatus',
    () => getAllTokenStatus(),
    [],
    { noFetchIfCached: true }
  );
  // const [fetchCollateralTokens, collateralTokens] = useResource(
  //   'collateralTokens',
  //   () => getAllCollateralTokens(),
  //   [],
  //   { noFetchIfCached: true }
  // );
  const [fetchCollateralProvider, collateralProvider] = useResource(
    'collateralProvider',
    () => getCollateralProvider(),
    [],
    { noFetchIfCached: true }
  );
  const [fetchInvestorClassification, investorClassification] = useResource(
    'investorClassification',
    () => getAllInvestorClassifications(),
    [],
    { noFetchIfCached: true }
  );

  const [fetAllTokenValuesByIssueType, defaultDataAsPerIssueType] = useResource(
    'defaultDataAsPerIssueType',
    () => getAllTokenFeildValues(),
    [],
    { noFetchIfCached: true }
  );

  const [fetchDefaultTokenValues, defaultTokenValues] = useResource(
    'defaultTokenValues',
    () => getAllDefaultValues(),
    [],
    { noFetchIfCached: true }
  );

  const [tokens, setTokens] = useState([]);
  // const [bankAccounts, setbankAccounts] = useState([]);
  // const [beneficieryTypes, setbeneficieryTypes] = useState([]);
  const [paymentTokens, setPaymentTokens] = useState([]);
  const [poolPaymentTokens, setPoolPaymentTokens] = useState([]);

  useEffect(() => {
    _loaddata();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchAllTokens = async () => {
    const _tokens = await getAllTokens();
    setTokens(_tokens);
  };

  // const fetchBankAccounts = async () => {
  //   const _bankAccounts = getAllbankAccounts();
  //   if (_bankAccounts) {
  //     setbankAccounts(_bankAccounts);
  //   }
  // };

  // const fetchBeneficieryTypes = async () => {
  //   const _beneficieryTypes = getAllbeneficieryTypes();
  //   if (_beneficieryTypes) {
  //     setbeneficieryTypes(_beneficieryTypes);
  //   }
  // };

  const fetchPaymentTokens = async () => {
    const _paymentTokens = await getAllPaymentTokens();
    if (_paymentTokens) {
      setPaymentTokens(_paymentTokens);
    }
  };

  const fetchPoolPaymentTokens = async () => {
    const _paymentTokens = await getAllPoolPaymentTokens();
    if (_paymentTokens) {
      setPoolPaymentTokens(_paymentTokens);
    }
  };

  const fetchPoolPaymentTokensByPoolAddr = async (addr) => {
    const _paymentTokens = await getAllPoolPaymentTokenByPoolAddr(addr);
    if (_paymentTokens) {
      setPoolPaymentTokens(_paymentTokens);
    }
  };

  const _loaddata = async () => {
    fetchCountries();
    fetchOrganizations();
    fetchCurrencies();
    fetchIssueType();
    fetchKycStatus();
    fetchIssueCategories();
    fetchIssueSubCategories();
    fetchPaymentLegs();
    fetchPaymentBasis();
    fetchPaymentFreq();
    fetchInterestType();
    fetchInterestCalcMethod();
    fetchAccrualFreq();
    fetchTokenStatus();
    fetchTransactionType();
    fetchCollateralProvider();

    fetchAllTokens();
    // fetchBankAccounts();
    // fetchBeneficieryTypes();
    fetchPaymentTokens();
    // fetchCollateralTokens();
    fetchInvestorClassification();
    fetchPaymentTokenCurrencies();
    fetAllTokenValuesByIssueType();
    fetchDefaultTokenValues();
  };

  return {
    countries,
    orgnizations,
    fetchOrganizations,
    currencies,
    issueType,
    kycStatus,
    // bankAccounts,
    // beneficieryTypes,
    issueCategories,
    issueSubCategories,
    paymentLegs,
    paymentBasis,
    paymentFreq,
    interestType,
    interestCalcMethod,
    accrualFreq,
    tokenStatus,
    transactionType,
    tokens,
    paymentTokens,
    fetchPaymentTokens,
    // collateralTokens,
    collateralProvider,
    fetchInvestorClassification,
    investorClassification,
    poolPaymentTokens,
    fetchPoolPaymentTokens,
    fetchPoolPaymentTokensByPoolAddr,
    paymentTokenCurrencies,
    defaultTokenValues,
    defaultDataAsPerIssueType
  };
};

export const [CoreTablesProvider, useCoreTableState] = constate(useCoreTableState_);
