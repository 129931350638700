import React, { useEffect } from 'react';
import {
  Collapse,
  Table,
  TableCell,
  TableBody,
  Box,
  Button,
  TableContainer,
  Chip,
  TableRow,
  TableHead,
  IconButton,
  Typography,
  Modal
} from '@mui/material';

import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import InvestorStatus from './InvestorStatus';
import { useHttpApi, useWeb3, useSubscriptionState } from 'state';
import { toChecksumAddress } from 'helpers/web3';
import EmptyDashboard from 'assets/images/emptyassets.png';
import AddressFieldTools from 'components/AddressFieldTools';
import SyncIcon from '@mui/icons-material/Sync';
import { titleCase } from 'helpers/text';
import InvestorDocVewUploadModal from 'components/DocMangement/InvestorDocViewUploadModal';
import UploadIcon from '@mui/icons-material/Upload';
import Synaps from '@synaps-io/react-verify';
import KYCModal from './KYCModal';
import { useSnackbar } from 'notistack';

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [kycModalOpen, setKycModalOpen] = React.useState(false);
  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);
  const { enqueueSnackbar } = useSnackbar();

  // const openKycModal = () => {
  //   if (row.kyc_provider_name === 'SYNAPS') {
  //     setKycModalOpen(true);
  //   } else {
  //     enqueueSnackbar('KYC Provider not integrated', {
  //       variant: 'error'
  //     });
  //   }
  // };
  // const closeKycModal = () => setKycModalOpen(false);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 3
  };

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell component="th" align="left" scope="row" padding="normal">
          {row.token_name}
        </TableCell>
        <TableCell component="th" align="left" scope="row" padding="normal">
          {row.token_symbol}
        </TableCell>
        <TableCell sx={{ width: '1rem' }} component="th" align="left" scope="row" padding="normal">
          <AddressFieldTools
            address={row.deployment_address}
            symbol={row.token_symbol}
            decimals={0}
            // showAddToWallet
            showCopyButton
          />
        </TableCell>

        <TableCell align="center">
          <Chip color="success" label={titleCase(row.current_status)} />
        </TableCell>
        {/* <TableCell alight="center">
          <IconButton onClick={openModal}>
            <UploadIcon sx={{ fontSize: '1.5rem' }} />
          </IconButton>
        </TableCell> */}
        {/* KYC Docs */}
        <TableCell alight="center">
          <IconButton
          // onClick={openKycModal}
          >
            <PersonSearchIcon sx={{ fontSize: '1.5rem' }} />
          </IconButton>
        </TableCell>
        <TableCell align="center">
          <IconButton size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <InvestorStatus
                issuerName={row.issuer_name}
                tokenValue={row.token_value}
                maturityDate={row.final_maturity_date} // TODO
                susbscribedqty={row.requested_qty}
                previousAllotment={row.previous_allotment}
                activeAllotment={row.active_allotment}
                status={row.current_status}
                allotmentDate={row.allotment_date} // TODO
              />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <Modal
        open={modalOpen}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <InvestorDocVewUploadModal handleClose={closeModal} row={row} />
      </Modal>

      {/* KYC Modal */}

      {/* <KYCModal
        sessionId={row.session_id}
        service={row.investor_type.toLowerCase()}
        kycModalOpen={kycModalOpen}
        closeKycModal={closeKycModal}
      /> */}
    </>
  );
}

export default function OpenTransaction() {
  // Get  Current logged in web3 account
  const { account, active } = useWeb3();
  // API call to get open transaction
  // const { getAllSubscriptionsByXinFinAddress } = useHttpApi();
  // const [openTransaction, setOpenTransaction] = React.useState([]);
  const { openTransaction, setOpenTransaction, fetchOpenTransactionData } = useSubscriptionState();
  // Load the rows from a get request
  //    const fetchData = async () => {
  //   const result = await getAllSubscriptionsByXinFinAddress(toChecksumAddress(account));
  //   setOpenTransaction(result);
  // };

  const fetchData = () => {
    fetchOpenTransactionData(account);
  };
  useEffect(() => {
    // fetchData();
    fetchOpenTransactionData(account);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, active]);

  return (
    <>
      <Box sx={{ mt: 2, ml: 2, display: 'flex', justifyContent: 'flex-between' }}>
        <Typography
          sx={{
            fontSize: '1.125rem',
            fontWeight: 'bold',
            mt: '5px'
          }}
        >
          Open Transactions
        </Typography>
        <IconButton sx={{ ml: 1 }} onClick={fetchData}>
          <SyncIcon />
        </IconButton>
      </Box>
      <TableContainer>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold', fontSize: '1rem' }}>Token Name</TableCell>
              <TableCell sx={{ fontWeight: 'bold', fontSize: '1rem' }}>Symbol</TableCell>
              <TableCell sx={{ fontWeight: 'bold', fontSize: '1rem' }}>Token Address</TableCell>

              <TableCell align="center" sx={{ fontWeight: 'bold', fontSize: '1rem' }}>
                Transaction Status
              </TableCell>
              {/* <TableCell sx={{ fontWeight: 'bold', fontSize: '1rem' }}>Trade Doc.</TableCell> */}
              <TableCell sx={{ fontWeight: 'bold', fontSize: '1rem' }}>KYC Doc.</TableCell>
              <TableCell align="center" sx={{ fontWeight: 'bold', fontSize: '1rem' }}>
                Status
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {openTransaction.map((row) => (
              <Row key={row.name} row={row} />
            ))}
            {openTransaction.length === 0 && (
              <TableCell
                colSpan={4}
                sx={{
                  textAlign: 'center'
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <Box
                    img
                    sx={{ ml: '10rem' }}
                    component="img"
                    src={EmptyDashboard}
                    alt="Empty Dashboard"
                  />
                  <Typography sx={{ ml: '10rem' }} variant="p">
                    {active ? 'No open transaction found' : 'Please connect to Web3 Wallet'}
                  </Typography>
                </Box>
              </TableCell>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
