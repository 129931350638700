import { useAuthStateShared } from 'state';

export const PRIMARY_ISSUER = 'PRIMARY_ISSUER';
export const ISSUER_OPERATOR = 'ISSUER_OPERATOR';
// export const PRIMARY_CUSTODIAN = 'PRIMARY_CUSTODIAN';
export const CUSTODIAN_OPERATOR = 'CUSTODIAN_OPERATOR';
export const PRIMARY_KYC_PROVIDER = 'PRIMARY_KYC_PROVIDER';
export const KYC_PROVIDER_OPERATOR = 'KYC_PROVIDER_OPERATOR';
export const PRIMARY_INSURER = 'PRIMARY_INSURER';
export const OWNER = 'OWNER';
export const LIQUIDITY_POOL_OWNER = 'LIQUIDITY_POOL_OWNER';
export const INVESTOR = 'INVESTOR';
export const FUND_MANAGER = 'FUND_MANAGER';

export const onlyPrimaryIssuer = (userRole) => {
  return userRole === PRIMARY_ISSUER;
};

export const onlyIssuer = (userRole) => {
  return userRole === PRIMARY_ISSUER || userRole === ISSUER_OPERATOR;
};

export const onlyFundManager = (userRole) => {
  return userRole === FUND_MANAGER;
};
export const onlyKycProvider = (userRole) => {
  return userRole === PRIMARY_KYC_PROVIDER || userRole === KYC_PROVIDER_OPERATOR;
};

// export const onlyCustodian = (userRole) => {
//   return userRole === PRIMARY_CUSTODIAN || userRole === CUSTODIAN_OPERATOR;
// };

export const onlyInsurer = (userRole) => {
  return userRole === PRIMARY_INSURER;
};

export const onlyOwner = (userRole) => {
  return userRole === OWNER;
};

export const onlyPrimaryIssuerOrKycProvider = (userRole) => {
  return (
    userRole === PRIMARY_ISSUER ||
    userRole === PRIMARY_KYC_PROVIDER ||
    userRole === KYC_PROVIDER_OPERATOR
  );
};

export const onlyPrimaryUser = (userRole) => {
  return (
    userRole === PRIMARY_ISSUER ||
    // userRole === PRIMARY_CUSTODIAN ||
    userRole === PRIMARY_KYC_PROVIDER
  );
};

export const onlyPrimaryUserOrOwner = (userRole) => {
  return (
    userRole === OWNER ||
    userRole === PRIMARY_ISSUER ||
    // userRole === PRIMARY_CUSTODIAN ||
    userRole === PRIMARY_KYC_PROVIDER
  );
};

export const onlyLiquidityPoolOwner = (userRole) => {
  return userRole === LIQUIDITY_POOL_OWNER;
};

export const onlyInvestor = (userRole) => {
  return userRole === INVESTOR;
};
export const useIsOwner = () => {
  const { role } = useAuthStateShared();

  return onlyOwner(role);
};

export const useKycProvider = () => {
  const { role } = useAuthStateShared();

  return onlyKycProvider(role);
};

export const useIssuer = () => {
  const { role } = useAuthStateShared();

  return onlyIssuer(role);
};

export const useInsurer = () => {
  const { role } = useAuthStateShared();

  return onlyInsurer(role);
};

export const useIsPrimaryUser = () => {
  const { role } = useAuthStateShared();

  return onlyPrimaryUser(role);
};

export const useIsPrimaryUserOrOwner = () => {
  const { role } = useAuthStateShared();

  return onlyPrimaryUserOrOwner(role);
};

export const useIsIssuer = () => {
  const { role } = useAuthStateShared();

  return onlyIssuer(role);
};

export const useIsFundManager = () => {
  const { role } = useAuthStateShared();

  return onlyFundManager(role);
};

export const useIsPrimaryIssuer = () => {
  const { role } = useAuthStateShared();

  return onlyPrimaryIssuer(role);
};

export const useIsLiquidityPoolOwner = () => {
  const { role } = useAuthStateShared();

  return onlyLiquidityPoolOwner(role);
};

export const useIsInvestor = () => {
  const { role } = useAuthStateShared();
  return onlyInvestor(role);
};
