import React from 'react';
import Page from 'components/Page';
import {
  Container,
  Typography,
  Box,
  Table,
  TableHead,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TableRow,
  TableCell,
  Card,
  CardContent,
  TableBody,
  Chip,
  Button
} from '@mui/material';
import Breadcrumbs from 'components/Breadcrumbs';
import { useBusinessEntityState } from 'state';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IssuanceEscrowTable from 'components/escrow/IssuanceEscrowTable';
import RedemptionEscrowTable from 'components/escrow/RedemptionEscrowTable';
import Collateral from 'components/escrow/Collateral';
import { useNavigate } from 'react-router-dom';
import AccordionLayout from 'helpers/AccordionLayout';
import TokenTable from 'components/issuerDashboard/TokenTable';

const EscrowSettlement = () => {
  const navigate = useNavigate();
  const { currentToken: token } = useBusinessEntityState();
  return (
    <>
      <Page title="Escrow & Settlement">
        <Container>
          <Breadcrumbs aria-label="breadcrumb" pageHead="Escrow & Settlement" />
          <TokenTable currToken={token} />
          <AccordionLayout title="Issuance Escrow" content={<IssuanceEscrowTable />} />
          <AccordionLayout title="Redemption Escrow" content={<RedemptionEscrowTable />} />
          {/* <AccordionLayout title="Collateral" content={<Collateral />} /> */}
          <Card sx={{ mt: 4, p: 0 }}>
            <CardContent
              sx={{
                p: '1 !important',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                flexDirection: 'row'
              }}
            >
              <Button
                variant="contained"
                sx={{
                  mx: 2,
                  px: 3,
                  fontWeight: 400,
                  width: '7.75rem'
                }}
                color="secondary"
                onClick={() => {
                  navigate('/home/token-dashboard');
                }}
              >
                Close
              </Button>
            </CardContent>
          </Card>
        </Container>
      </Page>
    </>
  );
};

export default EscrowSettlement;
