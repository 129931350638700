import React, { useEffect, useState } from 'react';
import { Box, IconButton, Chip } from '@mui/material';
import { useBusinessEntityState } from 'state/useBusinessEntityState';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { ethToXdcAddress } from 'helpers/web3';
import EnhancedTableCheck from 'components/tables/EnhancedTableCheck';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SubsDetails from 'components/masterData/Dashboard/SubsDetails';
import Modal from '@mui/material/Modal';
import { useAuthStateShared, useWeb3 } from 'state';
import CopyAddress from 'components/CopyAddress';
import CanIssueChip from 'components/issuerDashboard/CanIssueChip';

const headCells = [
  {
    accessor: 'investor_id.investor_name',
    Header: 'Investor',
    show: true
  },
  {
    accessor: 'xinfin_address',
    Header: 'Xinfin Address',
    width: 200,
    show: true,
    Cell: (c) => <CopyAddress addr={ethToXdcAddress(c.row.original.xinfin_setl_account)} />
  },
  // {
  //   accessor: 'requested_qty',
  //   Header: 'Requested Qty',
  //   show: true
  // },
  {
    accessor: 'kyc_status',
    Header: 'KYC Status',
    show: true,
    Cell: (c) =>
      c.value === 'ONCHAINCOMPLETE' ? (
        <Chip label="COMPLETE" color="success" />
      ) : (
        <Chip label={c.value === 'PROGRESS' ? 'IN PROGRESS' : c.value} />
      )
  },
  {
    accessor: 'is_rejected',
    Header: 'Subscription Status',
    show: true,
    Cell: (c) => {
      console.log('x', c.row.original);
      return c.row.original.is_rejected ? (
        <Chip label="REJECTED" color="warning" />
      ) : (
        <Chip label="ACCEPTED" color="success" />
      );
    }
  },
  {
    Header: 'Can Issue',
    show: true,
    Cell: (c) => {
      return (
        <CanIssueChip
          tokenAddress={c.row.original.token_deployment_address}
          investor={c.row.original.investor_id.xinfin_account}
          value={c.row.original.requested_qty}
          nonce={Math.random()}
        />
      );
    }
  },
  {
    accessor: 'actions',
    Header: 'Subs. Details',
    show: true,
    Cell: (c) => <ActionButtons investor={c.row.original.investor_id} />
  }
];

const ActionButtons = ({ investor }) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <>
      <IconButton aria-label="subs detail" onClick={handleOpen} sx={{ mt: -1 }}>
        <VisibilityIcon sx={{ fontSize: '2rem', p: 1, m: 0, backgroundColor: '#F5F8FA' }} />
      </IconButton>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <SubsDetails investor={investor} handleClose={handleClose} />
      </Modal>
    </>
  );
};

export default function PendingWhiteListTable() {
  const { pendingWhiteList, setAddToWhiteListAddresses, fetchWhiteList } = useBusinessEntityState();
  console.log('Pending Whitelist in Table', pendingWhiteList);
  const { role } = useAuthStateShared();
  const [data, setData] = useState(pendingWhiteList);

  const [skipPageReset, setSkipPageReset] = React.useState(false);

  useEffect(() => {
    setData(pendingWhiteList);
    console.log('x', pendingWhiteList);
  }, [pendingWhiteList]);

  // We need to keep the table from resetting the pageIndex when we
  // Update data. So we can keep track of that flag with a ref.

  // When our cell renderer calls updateMyData, we'll use
  // the rowIndex, columnId and new value to update the
  // original data
  const updateMyData = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true);
    setData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value
          };
        }
        return row;
      })
    );
  };

  return (
    <>
      <EnhancedTableCheck
        tableTitle="PendingWhitelist"
        columns={headCells}
        refreshFunction={fetchWhiteList}
        setSelectedRowsToState={setAddToWhiteListAddresses}
        data={data}
        setData={setData}
        updateMyData={updateMyData}
        skipPageReset={skipPageReset}
        isRoleAllowed={['PRIMARY_ISSUER', 'PRIMARY_KYC_PROVIDER', 'KYC_PROVIDER_OPERATOR'].includes(
          role
        )}
      />
    </>
  );
}
