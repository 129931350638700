import React, { useState } from 'react';
import {
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  FormLabel,
  Chip,
  Divider,
  Select,
  MenuItem,
  FormControl,
  FormHelperText
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useCoreTableState, useHttpApi, useWeb3, useAppState } from 'state';
import { xdcRegex } from 'helpers/regex';
import { useSnackbar } from 'notistack';
import { toChecksumAddress, xdcToEthAddress } from 'helpers/web3';
import { useFormik, Form, FormikProvider, Field } from 'formik';
import * as Yup from 'yup';

const AddPaymentTokens = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [loadingState, setLoadingState] = useState(false);
  const [fetchLoadingState, setfetchLoadingState] = useState(false);
  const { throwErrorMessage } = useAppState();

  const {
    getERC20Name,
    getERC20Symbol,
    getERC20Decimals,
    addPaymentToken: addPaymentTokenBlockchain
  } = useWeb3();
  const [stableCoinAddress, setStableCoinAddress] = useState('');

  const { addPaymentToken } = useHttpApi();
  const { currencies } = useCoreTableState();
  const PaymentTokenSchema = Yup.object().shape({
    contract_name: Yup.string().required(),
    symbol: Yup.string().required(),
    decimals: Yup.number().required().min(0),
    backed_currency: Yup.string().required()
  });

  const formik = useFormik({
    initialValues: {
      contract_address: '',
      contract_name: '',
      symbol: '',
      decimals: '',
      backed_currency: '',
      contract_description: 'Stable Coin for payment method',
      onchain: true
    },
    validationSchema: PaymentTokenSchema,
    onSubmit: async (data, { resetForm }) => {
      // console.log('payment token value', data);
      try {
        const blockchainRes = await addPaymentTokenBlockchain(data.contract_address);
        if (blockchainRes) {
          const _addPaymentToken = await addPaymentToken(data);
          enqueueSnackbar('Added Payment Token Successfully', { variant: 'success' });
        }
      } catch (e) {
        throwErrorMessage(e);
      }
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;
  return (
    <>
      <Grid
        conatiner
        sx={{
          display: 'flex',
          flexDirection: 'row'
        }}
      >
        {/* <Grid item lg={2} md={6} sm={12} xs={12}>
          <Chip
            variant="outlined"
            color="secondary"
            label="Stable Coin"
            sx={{ width: '9.5rem', height: '2.45rem' }}
          />
        </Grid> */}
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <FormLabel>Stable Coin Address</FormLabel>
          <TextField
            fullWidth
            size="small"
            onChange={(e) => setStableCoinAddress(e.target.value)}
            autoComplete="off"
            type="text"
            InputProps={{
              pattern: xdcRegex,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    onClick={(event) => navigator.clipboard.writeText(stableCoinAddress)}
                  >
                    <ContentCopyIcon sx={{ fontSize: '1rem' }} />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid item lg={2} md={6} sm={12} xs={12} display="flex" alignItems="end">
          <LoadingButton
            loadingPosition="start"
            variant="outlined"
            onClick={async () => {
              setfetchLoadingState(true);
              try {
                if (stableCoinAddress.match(xdcRegex)) {
                  let _address = xdcToEthAddress(stableCoinAddress);
                  _address = toChecksumAddress(_address);
                  const erc20Name = await getERC20Name(_address);
                  if (erc20Name) {
                    formik.setFieldValue('contract_name', erc20Name);
                    formik.setFieldValue('contract_address', _address);
                  }
                  const erc20Symbol = await getERC20Symbol(_address);
                  if (erc20Symbol) {
                    formik.setFieldValue('symbol', erc20Symbol);
                  }
                  const erc20Decimals = await getERC20Decimals(_address);
                  if (erc20Decimals) {
                    formik.setFieldValue('decimals', erc20Decimals);
                  }
                } else {
                  enqueueSnackbar('Invalid XDC Address', { variant: 'error' });
                }
              } catch (e) {
                throwErrorMessage(e);
              }
              setfetchLoadingState(false);
            }}
            sx={{ ml: 1, width: '8rem', height: '2.45rem' }}
            loading={fetchLoadingState}
          >
            Fetch
          </LoadingButton>
        </Grid>
      </Grid>
      <Divider
        sx={{
          width: '100%',
          mt: 3
        }}
      />

      <FormikProvider value={formik}>
        <Form autoComplete="off" onSubmit={handleSubmit}>
          <Grid
            conatiner
            spacing={2}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              mt: 2
            }}
          >
            <Grid item lg={2} md={6} sm={12} xs={12} sx={{ mt: 1, mr: 1 }}>
              <FormLabel>Name</FormLabel>
              <TextField
                sx={{ mt: 1 }}
                fullWidth
                {...getFieldProps('contract_name')}
                error={Boolean(touched.contract_name && errors.contract_name)}
                inputProps={{ readOnly: true }}
                size="small"
                autoComplete="off"
                type="text"
                // placeholder="US Nota"
              />
            </Grid>

            <Grid item lg={2} md={6} sm={12} xs={12} sx={{ mt: 1, mr: 1 }}>
              <FormLabel>Symbol</FormLabel>
              <TextField
                sx={{ mt: 1 }}
                fullWidth
                size="small"
                {...getFieldProps('symbol')}
                error={Boolean(touched.symbol && errors.symbol)}
                inputProps={{ readOnly: true }}
                autoComplete="off"
                type="text"
                // placeholder="USN"
              />
            </Grid>

            <Grid item lg={2} md={6} sm={12} xs={12} sx={{ mt: 1, mr: 1 }}>
              <FormLabel>Decimal</FormLabel>
              <TextField
                sx={{ mt: 1 }}
                fullWidth
                size="small"
                {...getFieldProps('decimals')}
                error={Boolean(touched.decimals && errors.decimals)}
                inputProps={{ readOnly: true }}
                autoComplete="off"
                type="number"
                // placeholder="1"
              />
            </Grid>

            <Grid item lg={2} md={6} sm={12} xs={12} sx={{ mt: 1, mr: 1 }}>
              <FormLabel>Pegged CCY</FormLabel>
              <FormControl size="small" variant="outlined" fullWidth sx={{ mt: 1 }}>
                <Select
                  displayEmpty
                  inputProps={{ 'aria-label': 'Without label' }}
                  {...getFieldProps('backed_currency')}
                  id="backed_currency"
                  error={Boolean(touched.backed_currency && errors.backed_currency)}
                >
                  {currencies &&
                    currencies.map((currency) => {
                      return (
                        <MenuItem key={currency.currency_code} value={currency.currency_code}>
                          {currency.currency_code} | {currency.currency_name}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item lg={2} md={6} sm={12} xs={12} sx={{ mt: 2, ml: 1 }}>
              <LoadingButton
                loadingPosition="start"
                variant="gradient"
                type="submit"
                sx={{ mt: 3, height: '2.6rem', width: '7.813rem' }}
                loading={isSubmitting}
              >
                Set Token
              </LoadingButton>
            </Grid>
          </Grid>
        </Form>
      </FormikProvider>
    </>
  );
};

export default AddPaymentTokens;
