import React from 'react';
import { Box, Card, CardContent, Typography, IconButton } from '@mui/material';
import actionmask from '../../assets/images/ActionMask.svg';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import { useNavigate } from 'react-router';
import { useBusinessEntityState, useHttpApi } from 'state';

// eslint-disable-next-line react/prop-types
const TokenActionsCard = ({ title, cardColor, count, icon, linkName, onClick }) => {
  const {
    currentToken,
    setToken,
    setAllSubscriptionById,
    setPendingWhiteListById,
    setCurrentWhiteListById
  } = useBusinessEntityState();
  const { getAllSubscriptionByToken } = useHttpApi();
  const navigate = useNavigate();
  return (
    <Box>
      <Card
        sx={{
          backgroundColor: cardColor,
          height: '200px',
          color: '#fff',
          backgroundSize: 'cover',
          backgroundImage: `url(${actionmask})`,
          backgroundBlendMode: 'luminosity'
        }}
      >
        <CardContent>
          <Box
            sx={{
              mt: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexDirection: 'row'
            }}
          >
            <Box
              sx={{
                height: '50px',
                width: '50px',
                borderRadius: '6px',
                backgroundColor: '#fff',
                color: '#000',
                boxShadow: 4,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              {icon}
            </Box>
            <Typography
              variant="h4"
              sx={{
                mr: 2,
                fontWeight: 'bold'
              }}
            >
              {count}
            </Typography>
          </Box>

          <Box
            sx={{
              mt: 5,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexDirection: 'row'
            }}
          >
            <Box>
              <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                {title}
              </Typography>
            </Box>
            <Box>
              <IconButton
                sx={{
                  backgroundColor: '#fff',
                  opacity: 0.5,
                  p: 0
                }}
                onClick={onClick}
              >
                <KeyboardArrowRightRoundedIcon />
              </IconButton>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default TokenActionsCard;
