import {
  Container,
  Card,
  Grid,
  Box,
  CardContent,
  Divider,
  Typography,
  TextField,
  FormLabel,
  Button,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  Alert,
  Modal,
  IconButton
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';

import Page from 'components/Page';
import React, { useEffect, useState, useRef } from 'react';
import Breadcrumbs from 'components/Breadcrumbs';
import EditIssuerAccodian from 'components/masterData/Issuer/EditIssuerAccodian';
import { useBusinessEntityState } from 'state/useBusinessEntityState';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import NotifierSnackbar from 'components/Notifier/NotifierSnackbar';
import { useHttpApi, useCoreTableState, useWeb3, useAuthStateShared, useAppState } from 'state';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { xdcRegex } from 'helpers/regex';
import { xdcToEthAddress, toChecksumAddress, ethToXdcAddress } from 'helpers/web3';
import { useNavigate } from 'react-router';
import { LeiInfoButton, XdcInfoButton } from 'helpers/infoButton';
import CommonOverlay from 'components/CommonOverlay';
import { onlyOwner } from 'helpers/rbac';
import LogoPreview from 'components/masterData/LogoPreview';
import MapInsurertoIssuer from 'components/masterData/Issuer/MapInsurertoIssuer';
import AccordionLayout from 'helpers/AccordionLayout';
import MapLiqudityPoolToIssuer from 'components/masterData/Issuer/MapLIquidityPoolToIssuer';

const EditIssuer = () => {
  const { editIssuer } = useHttpApi();
  const { role } = useAuthStateShared();

  const { currentIssuer, fetchIssuer, fetchAllKycProviders, kycProviders } =
    useBusinessEntityState();
  const { throwErrorMessage } = useAppState();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [error, setError] = useState(null);

  // Issuer Schema
  const EditIssuerSchema = Yup.object().shape({
    issuer_name: Yup.string().required('Issuer Name is required'),

    issuer_country_code: Yup.string().required('Country Code is required'),

    primary_issuer_xinfin_address: Yup.string()
      .matches(xdcRegex, 'Please enter a valid XDCPay Wallet Address')
      .required('Issuer XinFin Address is required')
  });

  // blockchain call
  const { addIssuer: addIssuerBlockchain } = useWeb3();
  useEffect(async () => {
    fetchAllKycProviders();
  }, []);
  // Formik
  const formik = useFormik({
    initialValues: currentIssuer,
    validationSchema: EditIssuerSchema,
    onSubmit: async (data, { resetForm }) => {
      // console.log('EditIssuer', data);
      try {
        setError(null);
        delete data.issuer_logo;

        if (issuerLogo.current.files[0]) {
          const issuerLogoFile = issuerLogo.current.files[0];
          data.issuer_logo = issuerLogoFile;
        }

        data.primary_issuer_xinfin_address = xdcToEthAddress(data.primary_issuer_xinfin_address);
        data.primary_issuer_xinfin_address = toChecksumAddress(data.primary_issuer_xinfin_address);
        console.log('EditIssuer', data);
        const countryCode = countries.find((country) => country.id === data.issuer_country_code);
        // console.log('countryCode', countryCode.country_code);

        if (currentIssuer.status === 'OFFCHAIN') {
          try {
            const res = await addIssuerBlockchain(
              countryCode.country_code,
              data.primary_issuer_xinfin_address
            );
          } catch (err) {
            console.log(err);
          }
        }
        delete data.status;

        const _editIssuer = await editIssuer(currentIssuer.issuer_id, data);
        if (_editIssuer.issuer_id) {
          enqueueSnackbar('Issuer Updated Successfully', {
            variant: 'success'
          });
          resetForm();
          fetchIssuer();
          navigate('/masterdata/issuer');
        }
      } catch (e) {
        throwErrorMessage(e);
      }
    }
  });

  const issuerLogo = useRef(null);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const { countries, orgnizations } = useCoreTableState();
  const navigate = useNavigate();

  const allowedRolesList = ['OWNER'];

  return (
    <>
      <CommonOverlay
        allowedRoles={allowedRolesList}
        overlayTitle="You don't have access to Edit Account"
      />
      <Page title="Edit Issuer - Master Data">
        <Container sx={{ my: 2 }}>
          <Box sx={{ my: 2 }}>
            <Breadcrumbs pageHead="Edit Issuer" aria-label="breadcrumb" />
          </Box>
          <Card>
            {error && (
              <Box mb={4}>
                <Alert severity="error">{error}</Alert>
              </Box>
            )}
            <Container>
              <Typography
                sx={{
                  fontSize: '1.125rem',
                  fontWeight: 'bold',
                  mt: 4,
                  mb: 1,
                  ml: 4
                }}
              >
                Issuer Details
              </Typography>
              <Divider
                sx={{
                  width: '100%',
                  backgroundColor: '#f5f8fa',
                  border: '2px solid #f5f8fa'
                }}
              />
              <FormikProvider value={formik}>
                <Form autocomplete="off" onSubmit={handleSubmit}>
                  <CardContent sx={{ m: 2 }}>
                    <Grid container spacing={3} sx={{ width: '100%' }}>
                      <Grid item lg={6} sm={12} md={6} xs={12} sx={{ pr: 1 }}>
                        <FormLabel>Name</FormLabel>
                        <TextField
                          sx={{ mt: 1.5 }}
                          fullWidth
                          size="small"
                          autoComplete="off"
                          type="text"
                          // placeholder="Enter Issuer Name"
                          {...getFieldProps('issuer_name')}
                          error={Boolean(touched.issuer_name && errors.issuer_name)}
                          helperText={touched.issuer_name && errors.issuer_name}
                        />
                      </Grid>
                      <Grid item lg={6} md={6} xs={12} sm={12}>
                        <FormLabel>Username</FormLabel>
                        <TextField
                          inputProps={{ readOnly: true }}
                          sx={{ mt: 1.5 }}
                          fullWidth
                          size="small"
                          autoComplete="off"
                          type="text"
                          {...getFieldProps('username')}
                          error={Boolean(touched.username && errors.username)}
                          helperText={touched.username && errors.username}
                        />
                      </Grid>
                      <Grid item lg={6} md={6} xs={12} sm={12}>
                        <FormLabel>Email</FormLabel>
                        <TextField
                          sx={{ mt: 1.5 }}
                          inputProps={{ readOnly: true }}
                          fullWidth
                          size="small"
                          autoComplete="off"
                          type="text"
                          {...getFieldProps('email')}
                          error={Boolean(touched.email && errors.email)}
                          helperText={touched.email && errors.email}
                        />
                      </Grid>
                      <Grid item lg={6} xs={12} sm={12} md={6}>
                        <FormLabel>
                          XinFin Address
                          <XdcInfoButton />
                        </FormLabel>
                        <TextField
                          inputProps={{ readOnly: true }}
                          sx={{ mt: 1.5 }}
                          fullWidth
                          type="text"
                          size="small"
                          // placeholder="Primary XinFin Address"
                          {...getFieldProps('primary_issuer_xinfin_address')}
                          error={Boolean(
                            touched.primary_issuer_xinfin_address &&
                              errors.primary_issuer_xinfin_address
                          )}
                          helperText={
                            touched.primary_issuer_xinfin_address &&
                            errors.primary_issuer_xinfin_address
                          }
                        />
                      </Grid>
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <FormLabel>Logo</FormLabel>
                        <Grid
                          Container
                          sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                        >
                          <Grid item lg={11} md={11} sm={11} xs={10}>
                            <TextField
                              sx={{ mt: 1.5 }}
                              fullWidth
                              size="small"
                              autoComplete="off"
                              type="file"
                              inputRef={issuerLogo}
                              inputProps={{ accept: '.jpg, .jpeg, .png, .svg' }}
                              {...getFieldProps('issuer_logo_doc')}
                              id="issuer_logo_doc"
                              error={Boolean(touched.issuer_logo_doc && errors.issuer_logo_doc)}
                              helperText={touched.issuer_logo_doc && errors.issuer_logo_doc}
                            />
                            <Grid container sx={{ width: '100%', mt: 2 }}>
                              <Grid item lg={4} md={4} sm={4}>
                                <Typography
                                  sx={{
                                    fontSize: '0.688rem',
                                    textAlign: 'left',
                                    color: '#161c2d'
                                  }}
                                >
                                  Maximum 50KB file size
                                </Typography>
                              </Grid>
                              <Grid item lg={8} md={8} sm={8}>
                                <Typography
                                  sx={{
                                    fontSize: '0.688rem',
                                    textAlign: 'right',
                                    color: '#8e8ea7'
                                  }}
                                >
                                  Accepted File Type .jpg, .jpeg, .png & .svg only
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item lg={1} md={1} sm={1} xs={1}>
                            <IconButton
                              aria-label="subs detail"
                              onClick={handleOpen}
                              sx={{ mt: -3 }}
                            >
                              <VisibilityIcon
                                sx={{ fontSize: '2rem', p: 1, m: 0, backgroundColor: '#F5F8FA' }}
                              />
                            </IconButton>
                            <Modal
                              open={open}
                              onClose={handleClose}
                              aria-labelledby="modal-modal-title"
                              aria-describedby="modal-modal-description"
                            >
                              <LogoPreview
                                imgSrc={currentIssuer.issuer_logo}
                                handleClose={handleClose}
                              />
                            </Modal>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item lg={6} xs={12} sm={12} md={6}>
                        <FormLabel>Issuer country code</FormLabel>
                        <FormControl size="small" variant="outlined" fullWidth sx={{ mt: 1.5 }}>
                          <Select
                            // placeholder="Select Country"
                            inputProps={{ readOnly: true }}
                            {...getFieldProps('issuer_country_code')}
                            error={Boolean(
                              touched.issuer_country_code && errors.issuer_country_code
                            )}
                            helperText={touched.issuer_country_code && errors.issuer_country_code}
                          >
                            {countries &&
                              countries.map((country, index) => {
                                return (
                                  <MenuItem key={country.id} value={country.id}>
                                    {country.country_name}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                          <FormHelperText sx={{ color: '#d32f2f' }}>
                            {touched.issuer_country_code && errors.issuer_country_code}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item lg={6} md={6} xs={12} sm={12}>
                        <FormLabel>KYC Provider</FormLabel>

                        <FormControl size="small" variant="outlined" fullWidth sx={{ mt: 1.5 }}>
                          <Select
                            // placeholder="Select Country"
                            inputProps={{ readOnly: true }}
                            {...getFieldProps('kyc_provider')}
                            error={Boolean(touched.kyc_provider && errors.kyc_provider)}
                          >
                            {kycProviders &&
                              kycProviders.map((kycProvider) => {
                                return (
                                  <MenuItem
                                    key={kycProvider.kyc_provider_id}
                                    value={kycProvider.kyc_provider_id}
                                  >
                                    {kycProvider.kyc_provider_name}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                          <FormHelperText sx={{ color: '#d32f2f' }}>
                            {touched.kyc_provider && errors.kyc_provider}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                    </Grid>

                    <Box
                      sx={{
                        mt: 4,
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end'
                      }}
                    >
                      <Button
                        sx={{
                          margin: 1,
                          borderColor: '#24ABDF',
                          px: 3,
                          width: '7.75rem',
                          height: '2.5rem'
                        }}
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                          navigate('/masterdata/issuer');
                        }}
                      >
                        Cancel
                      </Button>
                      <LoadingButton
                        type="submit"
                        disabled={!onlyOwner(role)}
                        variant="gradient"
                        fullWidth
                        size="large"
                        loadingPosition="start"
                        loading={isSubmitting}
                        sx={{ margin: 1, px: 3, width: '7.75rem', height: '2.5rem' }}
                      >
                        Save
                      </LoadingButton>
                    </Box>

                    {/* <EditIssuerAccodian
                      rows={custodianrows}
                      title="Custodian Mapping"
                      tableTitle="Custodian Name"
                    />
                    <EditIssuerAccodian
                      rows={originatorrows}
                      title="Originator Mapping"
                      tableTitle="Originator Name"
                    />
                    <EditIssuerAccodian
                      rows={trusteerows}
                      title="Trustee Mapping"
                      tableTitle="Trustee Name"
                    />
                    <EditIssuerAccodian rows={kycrows} title="KYC Mapping" tableTitle="KYC Name" /> */}
                  </CardContent>
                </Form>
              </FormikProvider>
              {/* <Box sx={{ mb: 4 }}>
                <AccordionLayout title="Insurer Mapping" content={<MapInsurertoIssuer />} />
              </Box>
              <Box sx={{ mb: 4 }}>
                <AccordionLayout
                  title="Liquidity Pool Mapping"
                  content={<MapLiqudityPoolToIssuer />}
                />
              </Box> */}
            </Container>
          </Card>
        </Container>
      </Page>
    </>
  );
};

export default EditIssuer;
