import { Container, Box, Typography } from '@mui/material';
import React from 'react';
import banner from '../../assets/images/banner-image.png';

const SubscriptionHead = ({ title }) => {
  return (
    <Box sx={{ background: '#003663' }}>
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <Typography
          variant="h4"
          sx={{
            color: '#fff',
            fontSize: '21px',
            fontWeight: '600'
          }}
        >
          {title}
        </Typography>
        <img src={banner} alt="banner" sx={{}} />
      </Container>
    </Box>
  );
};

export default SubscriptionHead;
