import { Box } from '@mui/material';

export const HeaderName = (name) => {
  return (
    <Box
      sx={{
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        width: '25rem'
      }}
    >
      {name}
    </Box>
  );
};

export const AllotmentAge = (allotmentAge) => {
  const _allotmentAge = Number(allotmentAge);
  let age;
  switch (true) {
    case _allotmentAge < 7:
      age = `${_allotmentAge.toFixed(1)} Days`;
      break;

    case _allotmentAge >= 7 && _allotmentAge < 30:
      age = `${(_allotmentAge / 7).toFixed(1)} Weeks`;
      break;

    case _allotmentAge >= 30 && _allotmentAge < 365:
      age = `${(_allotmentAge / 30).toFixed(1)} Months`;
      break;

    case _allotmentAge >= 365:
      age = `${(_allotmentAge / 365).toFixed(1)} Years`;
      break;

    default:
      age = `${_allotmentAge.toFixed(1)} Days`;
      break;
  }
  return age;
};
