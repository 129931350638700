import {
  Container,
  Card,
  Grid,
  CardContent,
  Divider,
  Typography,
  TextField,
  FormLabel,
  Button,
  Box,
  IconButton,
  Select,
  MenuItem,
  InputAdornment,
  FormControl
} from '@mui/material';
import Page from 'components/Page';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import Breadcrumbs from 'components/Breadcrumbs';
import React, { useState } from 'react';
import { Avatar } from '@material-ui/core';
import CommonOverlay from 'components/CommonOverlay';

const EditUser = () => {
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(true);
  const [showConfirmPassword, setShowConfirmPassword] = useState(true);
  const [userName, setUserName] = useState('');

  const allowedRolesList = ['OWNER', 'PRIMARY_ISSUER', 'PRIMARY_CUSTODIAN', 'PRIMARY_KYC_PROVIDER'];

  return (
    <>
      <CommonOverlay
        allowedRoles={allowedRolesList}
        overlayTitle="You don't have access to Edit User"
      />
      <Page title="Edit User - Master Data">
        <Container sx={{ my: 2 }}>
          <Box sx={{ my: 2 }}>
            <Breadcrumbs pageHead="Edit User" aria-label="breadcrumb" />
          </Box>
          <Card>
            <Container>
              <Typography
                sx={{
                  fontSize: '1.125rem',
                  fontWeight: 'bold',
                  mt: 4,
                  mb: 1,
                  ml: 4
                }}
              >
                Edit User Account Details
              </Typography>
              <Divider
                sx={{
                  width: '100%',
                  backgroundColor: '#f5f8fa',
                  border: '2px solid #f5f8fa'
                }}
              />

              <CardContent sx={{ m: 2 }}>
                <Grid container spacing={3} sx={{ width: '100%' }}>
                  <Grid item lg={6} sm={12} md={6} xs={12} sx={{ pr: 1 }}>
                    <FormLabel>Full Name</FormLabel>
                    <TextField
                      sx={{ mt: 1.5 }}
                      fullWidth
                      size="small"
                      autoComplete="off"
                      type="text"
                      // placeholder="Enter Full Name"
                    />
                  </Grid>
                  <Grid item lg={6} sm={12} md={6} xs={12} sx={{ pr: 1 }}>
                    <FormLabel>Username</FormLabel>
                    <TextField
                      sx={{ mt: 1.5 }}
                      value={userName}
                      name="userName"
                      onChange={(e) => {
                        setUserName(e.target.value);
                      }}
                      fullWidth
                      size="small"
                      autoComplete="off"
                      type="text"
                      // placeholder="Enter UserName"
                    />
                  </Grid>
                  <Grid item lg={6} sm={12} md={6} xs={12} sx={{ pr: 1 }}>
                    <FormLabel>Select Avatars</FormLabel>
                    <FormControl sx={{ mt: 1.5 }} fullWidth size="small">
                      <Select displayEmpty inputProps={{ 'aria-label': 'Without label' }}>
                        {/* // avatar api */}
                        <MenuItem value={10}>
                          <Avatar
                            size="small"
                            sx={{ width: 24, height: 24 }}
                            src={`https://avatars.dicebear.com/api/initials/${userName}.svg`}
                          />
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item lg={6} sm={12} md={6} xs={12} sx={{ pl: 0.5 }}>
                    <FormLabel>Email</FormLabel>
                    <TextField
                      sx={{ mt: 1.5 }}
                      fullWidth
                      size="small"
                      autoComplete="off"
                      type="text"
                      // placeholder="Enter valid Email Id"
                    />
                  </Grid>
                  <Grid item lg={6} sm={12} md={6} xs={12}>
                    <FormLabel>Old Password</FormLabel>
                    <TextField
                      sx={{ mt: 1.5 }}
                      fullWidth
                      size="small"
                      autoComplete="off"
                      type="text"
                      // placeholder="***********"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => setShowOldPassword((show) => !show)}
                              edge="end"
                            >
                              <Icon icon={showOldPassword ? eyeFill : eyeOffFill} />
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                  </Grid>
                  <Grid item lg={6} sm={12} md={6} xs={12}>
                    <FormLabel>New Password</FormLabel>
                    <TextField
                      sx={{ mt: 1.5 }}
                      fullWidth
                      size="small"
                      autoComplete="off"
                      type="text"
                      // placeholder="New Password"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => setShowNewPassword((show) => !show)}
                              edge="end"
                            >
                              <Icon icon={showNewPassword ? eyeFill : eyeOffFill} />
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                  </Grid>
                  <Grid item lg={6} sm={12} md={6} xs={12}>
                    <FormLabel>Confirm New Password</FormLabel>
                    <TextField
                      sx={{ mt: 1.5 }}
                      fullWidth
                      size="small"
                      autoComplete="off"
                      type="text"
                      // placeholder="**********"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => setShowConfirmPassword((show) => !show)}
                              edge="end"
                            >
                              <Icon icon={showConfirmPassword ? eyeFill : eyeOffFill} />
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                  </Grid>
                  <Grid item lg={12} xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                      variant="contained"
                      color="secondary"
                      sx={{ mt: 4, ml: 2, height: '2.7rem', width: '7rem' }}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="gradient"
                      sx={{ mt: 4, ml: 2, height: '2.7rem', width: '7rem' }}
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Container>
          </Card>
        </Container>
      </Page>
    </>
  );
};

export default EditUser;
