import { Chip } from '@mui/material';
import CopyAddress from 'components/CopyAddress';
import EnhancedTable from 'components/tables/EnhancedTable';
import { HeaderName } from 'helpers/tableHelper';
import { titleCase } from 'helpers/text';
import React, { useEffect, useMemo } from 'react';
import { useBusinessEntityState } from 'state';

export default function CryptoTxnTable() {
  const { cryptoAssetTxnBySenderXinfin, fetchCryptoAssetTxnBySenderXinfin } =
    useBusinessEntityState();

  const recipientName = HeaderName('Recipient Name');
  const senderName = HeaderName('Sender Name');

  const fetchdata = () => {
    fetchCryptoAssetTxnBySenderXinfin();
  };

  useEffect(() => {
    // fetchdata();
    fetchCryptoAssetTxnBySenderXinfin();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const headCells = useMemo(
    () => [
      {
        accessor: 'sender_name',
        Header: senderName,
        show: true
      },
      {
        accessor: 'recipient_name',
        Header: recipientName,
        show: true
      },
      {
        accessor: 'sender_address.xinfin_account_address',
        Header: 'From',
        show: true,
        Cell: ({ value }) => {
          return <CopyAddress addr={value} />;
        }
      },
      {
        accessor: 'recipient_xinfin_address.xinfin_account_address',
        Header: 'To',
        show: true,
        Cell: ({ value }) => {
          return <CopyAddress addr={value} />;
        }
      },
      {
        accessor: 'transaction_hash',
        Header: 'Txn. Hash',
        width: 200,
        Cell: ({ value }) => {
          return <CopyAddress addr={value} ethAddr />;
        },
        show: true
      },
      {
        accessor: 'transaction_status',
        Header: 'Txn. Status',
        show: true,
        Cell: ({ value }) => (
          <Chip label={titleCase(value)} color={value === 'SUCCESS' ? 'success' : 'warning'} />
        )
      },
      {
        accessor: 'recipient_xinfin_address.role',
        Header: 'Recipient Role',
        show: true,
        Cell: ({ value }) => <Chip label={titleCase(value)} color="success" />
      },
      {
        accessor: 'transaction_amount',
        Header: 'Amount',
        show: true
      },
      {
        accessor: 'transaction_type',
        Header: 'Txn. Type',
        show: true,
        Cell: ({ value }) => <Chip label={titleCase(value)} color="success" />
      },
      {
        accessor: 'payment_date',
        Header: 'Payment Date',
        show: true
      }
    ],
    [recipientName]
  );

  return (
    <>
      <EnhancedTable
        tableTitle="Issuer"
        columns={headCells}
        refreshFunction={fetchdata}
        data={cryptoAssetTxnBySenderXinfin}
        // setData={setData}
        // updateMyData={updateMyData}
        // skipPageReset={skipPageReset}
      />
    </>
  );
}
