import React, { useCallback } from 'react';
import {
  Box,
  Card,
  CardContent,
  Divider,
  ListItemText,
  Chip,
  Typography,
  Grid
} from '@mui/material';
import { Ellipsis } from 'helpers/Ellipsis';
import xrc20 from 'assets/images/xrc20.png';
import { useBusinessEntityState, useWeb3 } from 'state';
import TransactionHandler from 'components/TransactionHandler';
import cryptoAssetTxn from '../../assets/images/masterdata-dashboard/cryptoAssetTxn.svg';
import { parseTokenDecimal } from 'helpers/numbers';
import { getChipColor } from 'helpers/handler';

// eslint-disable-next-line react/prop-types
const CardField = ({ label, value }) => {
  return (
    <ListItemText
      sx={{ ml: 2, mt: '1rem' }}
      primary={label}
      secondary={value}
      primaryTypographyProps={{
        fontSize: '0.688rem',
        color: '#8a8a8a'
      }}
      secondaryTypographyProps={{
        fontSize: '0.875',
        color: '#000'
      }}
    />
  );
};

// eslint-disable-next-line react/prop-types
const TokenStatus = ({
  status,
  tokenName,
  tokenSymbol,
  tokenTotalSupply,
  tokenValue,
  tokenCurrency,
  issuerPrimaryAddress,
  // custodianPrimaryAddress,
  kycproviderPrimaryAddress,
  earlyRedemption,
  minSubscription,
  paymentToken,
  issuanceSwapMultiple,
  redemptionSwapMultiple,
  maturityPeriod,
  settlementPeriod,
  collateral
}) => {
  const { publishToken } = useWeb3();

  const commitTransaction = useCallback(
    (hash) => {
      const collateralValue = parseTokenDecimal(collateral !== 0 ? collateral : 0, 18);

      const issueSwapRatio = parseTokenDecimal(
        issuanceSwapMultiple !== 0 ? issuanceSwapMultiple : 0,
        paymentToken.decimals
      );

      const redeemSwapRatio = parseTokenDecimal(
        redemptionSwapMultiple !== 0 ? redemptionSwapMultiple : 0,
        paymentToken.decimals
      );

      const _paymentTokenList = [];
      const _issuanceRatio = [];
      const _redeemRatio = [];
      if (paymentToken.symbol) {
        _paymentTokenList.push(paymentToken.contract_address);
        _issuanceRatio.push(issueSwapRatio);
        _redeemRatio.push(redeemSwapRatio);
      }
      return publishToken(
        tokenSymbol,
        tokenName,
        Number(tokenTotalSupply),
        Number(tokenValue),
        tokenCurrency,
        issuerPrimaryAddress,
        // custodianPrimaryAddress,
        kycproviderPrimaryAddress,
        earlyRedemption,
        Number(minSubscription),
        _paymentTokenList,
        _issuanceRatio,
        _redeemRatio,
        Number(maturityPeriod),
        Number(settlementPeriod),
        collateralValue,
        false,
        hash
      );
    },
    [
      publishToken,
      tokenSymbol,
      tokenName,
      tokenTotalSupply,
      tokenValue,
      tokenCurrency,
      issuerPrimaryAddress,
      // custodianPrimaryAddress,
      kycproviderPrimaryAddress,
      earlyRedemption,
      minSubscription,
      paymentToken,
      issuanceSwapMultiple,
      redemptionSwapMultiple,
      maturityPeriod,
      settlementPeriod,
      collateral
    ]
  );

  const { fetchTokens } = useBusinessEntityState();

  const onConfirmed = useCallback(() => {
    fetchTokens();
  }, [fetchTokens]);
  return (
    <TransactionHandler
      id={`token-status-${tokenSymbol}`}
      commitTransaction={commitTransaction}
      done={['NEW', 'PUBLISHED', 'ISSUED', 'REDEEMED'].includes(status)}
      onConfirmed={onConfirmed}
    >
      {({ status: txStatus, error, receipt, send }) => {
        return <Chip label={status} color={getChipColor(status)} />;
        //  <Typography sx={{ fontWeight: 'bold', fontSize: '0.9rem' ,mx:2}}>{status}</Typography>;
      }}
    </TransactionHandler>
  );
};

// eslint-disable-next-line react/prop-types
const TokenCard = ({ token }) => {
  return (
    <Card sx={{ backgroundColor: '#fff', boxShadow: 5, cursor: 'pointer' }}>
      <CardContent sx={{ m: 0, p: 0 }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <Typography
            sx={{
              m: 2,
              p: 0,
              fontWeight: 600
            }}
            variant="h6"
          >
            {Ellipsis(token.token_symbol, '12rem')}
          </Typography>
          <Box
            component="img"
            sx={{
              height: '2rem',
              mr: 2
            }}
            src={cryptoAssetTxn}
          />
        </Box>

        <Divider
          sx={{
            width: '100%',
            backgroundColor: '#f5f8fa',
            border: '2px solid #f5f8fa'
          }}
        />
        <Grid container>
          <Grid item lg={6} md={6} xs={6} sm={6}>
            <CardField label="Token Name" value={Ellipsis(token.token_name)} />
          </Grid>
          <Grid item lg={6} md={6} xs={6} sm={6}>
            <CardField label="Issue Type" value={Ellipsis(token.issue_type_id.issue_type_name)} />
          </Grid>
        </Grid>

        <Grid container>
          <Grid item lg={6} md={6} xs={6} sm={6}>
            <CardField label="Currency" value={token.token_currency.currency_code} />
          </Grid>
          <Grid item lg={6} md={6} xs={6} sm={6}>
            <CardField label="Issue Size" value={Ellipsis(token.issue_size)} />
          </Grid>
        </Grid>

        <Grid container>
          <Grid item lg={6} md={6} xs={6} sm={6}>
            <CardField label="Price" value={Ellipsis(token.token_issue_price)} />
          </Grid>
          {token.issue_type_id.issue_calc_type === 'DISCOUNT' ? (
            <>
              <Grid item lg={6} md={6} xs={6} sx={{ pr: 1 }}>
                <CardField label="Discount (% P.A)" value={token.token_issue_desc_percent} />
              </Grid>
            </>
          ) : (
            <>
              <Grid item lg={6} md={6} xs={6} sx={{ pr: 1 }}>
                <CardField label="Interest (% P.A)" value={token.token_issue_interest_percent} />
              </Grid>
            </>
          )}
          {/* <Grid item lg={6} md={6} xs={6} sm={6}>
            <CardField
              label="Yield %"
              value={(
                ((token.token_redemption_price - token.token_issue_price) * 100) /
                token.token_issue_price
              ).toFixed(2)}
            />
          </Grid> */}
        </Grid>
        <Grid container>
          <Grid item lg={6} md={6} xs={6} sm={6}>
            <CardField label="Supply" value={Ellipsis(token.total_supply)} />
          </Grid>
          <Grid item lg={6} md={6} xs={6} sm={6}>
            <CardField label="Allot. Qty." value={token.total_allotment} />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item lg={6} md={6} xs={6} sm={6}>
            <CardField
              label={token?.payment_token_id?.contract_name ? 'Payment Token' : 'Payment Leg'}
              value={
                token?.payment_token_id?.contract_name
                  ? token?.payment_token_id?.contract_name
                  : 'Cash'
              }
            />
          </Grid>
          {/* <Grid item lg={6} md={6} xs={6} sm={6}>
            <CardField label="Collateralized" value={token.is_collateralized ? 'YES' : 'NO'} />
          </Grid> */}
          <Grid item lg={6} md={6} xs={6} sx={{ pr: 1, mt: 2, ml: 2 }}>
            <TokenStatus
              status={token.token_status_id.token_status}
              tokenName={token.token_name}
              tokenSymbol={token.token_symbol}
              tokenTotalSupply={token.total_supply}
              tokenValue={token.token_issue_price}
              tokenCurrency={token.token_currency.currency_code}
              issuerPrimaryAddress={token.issuer_id.primary_issuer_xinfin_address}
              // custodianPrimaryAddress={token.custodian_id.primary_custodian_xinfin_address}
              kycproviderPrimaryAddress={token.kyc_provider_id.primary_kycprovider_xinfin_address}
              earlyRedemption={token.allow_early_redemption}
              minSubscription={token.minimum_subscription}
              paymentToken={token.payment_token_id}
              issuanceSwapMultiple={token.swap_ratio_issue}
              redemptionSwapMultiple={token.swap_ratio_redeem}
              maturityPeriod={token.application_processing_time}
              settlementPeriod={token.tenure_months}
              collateral={token.collateral_per_token}
            />
          </Grid>
        </Grid>
        {/* <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            mx:2,
            mt:2
          
          }}
        >
          {/* <Chip label={token.token_status_id.token_status} color="success" /> */}
        {/* <TokenStatus
            status={token.token_status_id.token_status}
            tokenName={token.token_name}
            tokenSymbol={token.token_symbol}
            tokenTotalSupply={token.total_supply}
            tokenValue={token.token_issue_price}
            tokenCurrency={token.token_currency.currency_code}
            issuerPrimaryAddress={token.issuer_id.primary_issuer_xinfin_address}
            // custodianPrimaryAddress={token.custodian_id.primary_custodian_xinfin_address}
            kycproviderPrimaryAddress={token.kyc_provider_id.primary_kycprovider_xinfin_address}
            earlyRedemption={token.allow_early_redemption}
            minSubscription={token.minimum_subscription}
            paymentToken={token.payment_token_id}
            issuanceSwapMultiple={token.swap_ratio_issue}
            redemptionSwapMultiple={token.swap_ratio_redeem}
            maturityPeriod={token.application_processing_time}
            settlementPeriod={token.tenure_months}
            collateral={token.collateral_per_token}
          /> */}
        {/* <IconButton sx={{ mr: 3 }}>
            <DeleteIcon />
          </IconButton> */}
        {/* </Box> */}
      </CardContent>
    </Card>
  );
};

export default TokenCard;
