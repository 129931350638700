/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import Page from 'components/Page';

import {
  Container,
  Card,
  Grid,
  Box,
  CardContent,
  Typography,
  TextField,
  FormLabel,
  Button,
  Divider,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  Alert
} from '@mui/material';
import Breadcrumbs from 'components/Breadcrumbs';

import {
  useHttpApi,
  useCoreTableState,
  useBusinessEntityState,
  useAuthStateShared,
  useAppState
} from 'state';
import { useFormik, Form, FormikProvider, Field } from 'formik';
import * as Yup from 'yup';

import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router';
import CommonOverlay from 'components/CommonOverlay';
import { useIsOwner } from 'helpers/rbac';

const AddOrganization = () => {
  const { UpdateOrganization } = useHttpApi();
  const { throwErrorMessage } = useAppState();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [error, setError] = useState(null);

  const { countries, orgnizations } = useCoreTableState();

  const organizationLogo = useRef(null);
  const isOwner = useIsOwner();

  useEffect(() => {
    if (isOwner && orgnizations[0].is_updated === true) {
      navigate('/home');
    } else if (!isOwner) {
      navigate('/home');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOwner, orgnizations]);

  // Organization Schema
  const AddOrganizationSchema = Yup.object().shape({
    name: Yup.string()
      .required('Organization Name is required')
      .max(100, 'Organization Name cannot be more than 100 characters'),
    description: Yup.string()
      .required('Organization Description is required')
      .max(1000, 'Organization Description cannot be more than 1000 characters'),
    country: Yup.string().required('Country Code is required'),
    logo: Yup.mixed()
      .required('Upload Orgaization Logo')
      .test('type', 'Only .jpg .jpeg .png .svg formats are accepted', (value) => {
        let allGood = false;
        console.log(value);
        if (value) {
          allGood =
            (value && value.substring(value.lastIndexOf('.')) === '.jpeg') ||
            (value && value.substring(value.lastIndexOf('.')) === '.jpg') ||
            value.substring(value.lastIndexOf('.')) === '.png' ||
            value.substring(value.lastIndexOf('.')) === '.svg';
        } else {
          allGood = true;
        }
        return allGood;
      })
  });

  // Formik
  const formik = useFormik({
    initialValues: {
      name: '',
      description: '',
      logo: '',
      country: ''
    },
    validationSchema: AddOrganizationSchema,
    onSubmit: async (data, { resetForm }) => {
      console.log('add Organization', data);
      try {
        setError(null);
        const orgLogoFile = organizationLogo.current.files[0];
        data.logo = orgLogoFile;
        data.is_updated = true;
        const response = await UpdateOrganization(orgnizations[0].id, data);
        console.log('Add Organization', response);
        formik.setFieldValue('logo', '');
        enqueueSnackbar('Organization Added successfully', { variant: 'success' });
        navigate('/home/change-password');
      } catch (e) {
        formik.setFieldValue('logo', '');
        throwErrorMessage(e);
      }
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const allowedRolesList = ['OWNER'];

  return (
    <Page title="Add Organization - Owner Set Up">
      <CommonOverlay
        allowedRoles={allowedRolesList}
        overlayTitle="You don't have access to Add Organization"
      />
      <Container sx={{ my: 2 }}>
        {error && (
          <Box mb={4}>
            <Alert severity="error">{error}</Alert>
          </Box>
        )}
        <Card>
          <Container>
            <Typography
              sx={{
                fontSize: '1.125rem',
                fontWeight: 'bold',
                mt: 4,
                mb: 1,
                ml: 4
              }}
            >
              Organization Details
            </Typography>
            <Divider
              sx={{
                width: '100%',
                backgroundColor: '#f5f8fa',
                border: '2px solid #f5f8fa'
              }}
            />
            <FormikProvider value={formik}>
              <Form autoComplete="off" onSubmit={handleSubmit}>
                <CardContent sx={{ m: 2 }}>
                  <Grid container spacing={3} sx={{ width: '100%' }}>
                    <Grid item lg={6} md={6} xs={12} sm={12}>
                      <FormLabel>Organization Name</FormLabel>
                      <TextField
                        sx={{ mt: 1.5 }}
                        fullWidth
                        size="small"
                        autoComplete="off"
                        type="text"
                        // placeholder="Enter Issuer Name"
                        {...getFieldProps('name')}
                        error={Boolean(touched.name && errors.name)}
                        helperText={touched.name && errors.name}
                      />
                    </Grid>

                    <Grid item lg={6} md={6} xs={12} sm={12}>
                      <FormLabel>Country Code</FormLabel>

                      <FormControl size="small" variant="outlined" fullWidth sx={{ mt: 1.5 }}>
                        <Select
                          // placeholder="Select Country"
                          inputProps={{ 'aria-label': 'Without label' }}
                          {...getFieldProps('country')}
                          error={Boolean(touched.country && errors.country)}
                        >
                          {countries &&
                            countries.map((item, index) => {
                              return (
                                <MenuItem key={item.id} value={item.id}>
                                  {item.country_name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                        <FormHelperText sx={{ color: '#d32f2f' }}>
                          {touched.country && errors.country}
                        </FormHelperText>
                      </FormControl>
                    </Grid>

                    <Grid item lg={6} md={6} sm={12} xs={12} sx={{ pr: 1 }}>
                      <FormLabel>Organization Logo</FormLabel>
                      <TextField
                        sx={{ mt: 1.5 }}
                        fullWidth
                        size="small"
                        autoComplete="off"
                        type="file"
                        inputRef={organizationLogo}
                        inputProps={{ accept: '.jpg, .jpeg, .png, .svg' }}
                        {...getFieldProps('logo')}
                        id="logo"
                        error={Boolean(touched.logo && errors.logo)}
                        helperText={touched.logo && errors.logo}
                      />
                      <Grid container sx={{ width: '100%', mt: 2 }}>
                        <Grid item lg={4} md={4} sm={4}>
                          <Typography
                            sx={{
                              fontSize: '0.688rem',
                              textAlign: 'left',
                              color: '#161c2d'
                            }}
                          >
                            Maximum 50KB file size
                          </Typography>
                        </Grid>
                        <Grid item lg={8} md={8} sm={8}>
                          <Typography
                            sx={{
                              fontSize: '0.688rem',
                              textAlign: 'right',
                              color: '#8e8ea7'
                            }}
                          >
                            Accepted File Type .jpg, .jpeg, .png & .svg only
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item lg={6} md={6} xs={12}>
                      <FormLabel>Organization Description</FormLabel>
                      <TextField
                        sx={{ mt: 1.5 }}
                        multiline="true"
                        rows={3}
                        fullWidth
                        type="text"
                        size="small"
                        // placeholder="Your zip code"
                        {...getFieldProps('description')}
                        error={Boolean(touched.description && errors.description)}
                        helperText={touched.description && errors.description}
                      />
                    </Grid>
                  </Grid>

                  <Box
                    sx={{
                      mt: 4,
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-end'
                    }}
                  >
                    <LoadingButton
                      type="submit"
                      variant="gradient"
                      disabled={!useIsOwner()}
                      fullWidth
                      size="large"
                      loadingPosition="start"
                      loading={isSubmitting}
                      sx={{ margin: 1, px: 3, width: '7.75rem', height: '2.5rem' }}
                    >
                      Submit
                    </LoadingButton>
                  </Box>
                </CardContent>
              </Form>
            </FormikProvider>
          </Container>
        </Card>
      </Container>
    </Page>
  );
};

export default AddOrganization;
