import React, { useMemo } from 'react';
import EnhancedTable from 'components/tables/EnhancedTable';
import { useBusinessEntityState } from 'state/useBusinessEntityState';
import { useIsOwner } from 'helpers/rbac';
import { getChipColor } from 'helpers/handler';
import { Chip } from '@mui/material';

export default function OwnerTokenTable() {
  const { tokens, fetchTokens } = useBusinessEntityState();
  const isOwner = useIsOwner();
  const headCells = useMemo(
    () => [
      {
        accessor: 'issuer_id.issuer_name',
        Header: 'Issuer Name',
        show: true
      },
      {
        accessor: 'token_name',
        Header: 'Name',
        show: true,
        width: 110
      },
      {
        accessor: 'issue_type_id.issue_type_name',
        Header: 'Issue Type',
        show: true
      },

      {
        accessor: 'token_currency.currency_code',
        Header: 'Currency',
        show: true,
        width: 110
      },
      {
        accessor: 'issue_size',
        Header: 'Issue Size',
        show: true,
        width: 120
      },

      {
        accessor: 'token_issue_price',
        Header: 'Issue Price',
        show: true,
        width: 110
      },
      {
        accessor: 'total_supply',
        Header: 'Supply',
        show: true,
        width: 120
      },
      {
        accessor: 'token_status_id.token_status',
        Header: 'Status',
        Cell: ({ value }) => {
          return <Chip label={value} color={getChipColor(value)} />;
        },
        show: true
      }
    ],
    []
  );

  return (
    <>
      <EnhancedTable
        tableTitle="Token"
        columns={headCells}
        refreshFunction={fetchTokens}
        data={tokens}
      />
    </>
  );
}
