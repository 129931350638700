const FORCE_CONSOLE_OUTPUT_FLAG_KEY = 'FORCE_CONSOLE_OUTPUT_FLAG';

if (
  process.env.NODE_ENV === 'production' &&
  localStorage.getItem(FORCE_CONSOLE_OUTPUT_FLAG_KEY) !== 'true'
) {
  let _calls = [];
  const collectCalls =
    (func) =>
    (...args) => {
      const timestamp = new Date(Date.now()).toLocaleString();
      _calls.push(() => func(timestamp, ...args));
    };

  console.log = collectCalls(console.log);
  console.warn = collectCalls(console.warn);
  console.info = collectCalls(console.info);
  console.debug = collectCalls(console.debug);
  console.error = collectCalls(console.error);

  console.flush = () => {
    _calls.forEach((func) => func());
    _calls = [];
  };
}
