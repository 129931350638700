import React, { useEffect, useState, useMemo } from 'react';
import EnhancedTable from 'components/tables/EnhancedTable';
import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  Alert,
  AlertTitle,
  IconButton,
  Box
} from '@mui/material';
import { useBusinessEntityState } from 'state/useBusinessEntityState';
import { useAuthStateShared, useCoreTableState, useHttpApi, useWeb3, useAppState } from 'state';
import { LoadingButton } from '@mui/lab';
import Delete from '@mui/icons-material/Delete';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useSnackbar } from 'notistack';
import CopyAddress from 'components/CopyAddress';
import AddressFieldTools from 'components/AddressFieldTools';
import { useIsOwner } from 'helpers/rbac';

const ActionButtons = ({ tokenAddress, tokenId, tokenName, tokenSymbol, tokenDecimals }) => {
  const { removePaymentToken } = useHttpApi();
  const { removePaymentToken: removePaymentTokenBlocakchain, chainId } = useWeb3();
  const { enqueueSnackbar } = useSnackbar();
  const { throwErrorMessage } = useAppState();

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const [loadingState, setLoadingState] = useState(false);
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          m: 0,
          p: 0,
          alignItems: 'center'
        }}
      >
        <IconButton aria-label="subs detail" onClick={handleOpen}>
          <Delete sx={{ fontSize: '1.8rem', p: 0.7, backgroundColor: '#F5F8FA' }} />
        </IconButton>
        <AddressFieldTools
          showAddToWallet
          showInBlockExplorer
          showAddress={false}
          address={tokenAddress}
          symbol={tokenSymbol}
          decimals={tokenDecimals}
          withBackground
        />
      </Box>
      <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title">
        <Alert severity="warning">
          <AlertTitle>Delete Stable Coin</AlertTitle>
          <DialogContent>Are you sure you want to delete Stable Coin, {tokenName}?</DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleClose}
              sx={{ width: '6.7rem' }}
            >
              Cancel
            </Button>
            <LoadingButton
              variant="outlined"
              color="error"
              loadingPosition="start"
              sx={{ width: '6.7rem' }}
              loading={loadingState}
              onClick={async () => {
                try {
                  setLoadingState(true);
                  const _res = await removePaymentToken(tokenId);
                  console.log({ _res });
                  const _blockChainRes = await removePaymentTokenBlocakchain(tokenAddress);
                  console.log({ _blockChainRes });
                  enqueueSnackbar('Payment Token deleted successfully', {
                    variant: 'success'
                  });
                } catch (e) {
                  throwErrorMessage(e);
                }
                setLoadingState(false);
                handleClose();
              }}
            >
              Delete
            </LoadingButton>
          </DialogActions>
        </Alert>
      </Dialog>
    </>
  );
};

export default function ManageTokenTable() {
  // const { tokens, fetchTokens } = useBusinessEntityState(); // fetch stable coin
  const { paymentTokens: tokens, fetchPaymentTokens } = useCoreTableState();
  console.log(tokens);
  /*  const [data, setData] = useState(tokens);

  const [skipPageReset, setSkipPageReset] = React.useState(false);
  const [owner, setOwner] = useState(false);

  const { role } = useAuthStateShared();

  useEffect(() => {
    if (role === 'OWNER') {
      setOwner(true);
    }
  }, [role]); */
  const isOwner = useIsOwner();
  const headCells = useMemo(
    () => [
      {
        accessor: 'contract_name',
        Header: 'Name',
        show: true
      },
      {
        accessor: 'symbol',
        Header: 'Symbol',
        show: true
      },
      {
        accessor: 'decimals',
        Header: 'Decimal',
        show: true,
        width: 100
      },
      {
        accessor: 'backed_currency',
        Header: 'Pegged CCY',
        show: true
      },
      {
        accessor: 'contract_address',
        Header: 'Contract Address',
        show: true,
        width: 200,
        Cell: ({ value }) => <CopyAddress addr={value} />
      },
      {
        accessor: 'actions',
        Header: 'Actions',
        Cell: ({ row: { original } }) => (
          <ActionButtons
            tokenId={original.payment_token_id}
            tokenName={original.contract_name}
            tokenSymbol={original.symbol}
            tokenDecimals={original.decimals}
            tokenAddress={original.contract_address}
          />
        ),
        show: isOwner
      }
    ],
    [isOwner]
  );

  /*   useEffect(() => {
    const _tokens = tokens.map((token) => {
      token.actions = (
        <ActionButtons
          tokenId={token.payment_token_id}
          tokenName={token.contract_name}
          tokenSymbol={token.symbol}
          tokenDecimals={token.decimals}
          tokenAddress={token.contract_address}
        />
      );
      return token;
    });
    setData(_tokens);
  }, [tokens]);
  const updateMyData = (rowIndex, columnId, value) => {
    setSkipPageReset(true);
    setData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value
          };
        }
        return row;
      })
    );
  }; */

  return (
    <>
      <EnhancedTable
        tableTitle="Token"
        columns={headCells}
        refreshFunction={fetchPaymentTokens}
        data={tokens}
        // setData={setData}
        // updateMyData={updateMyData}
        // skipPageReset={skipPageReset}
      />
    </>
  );
}
