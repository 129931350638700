import React from 'react';
import { useAuthStateShared } from 'state';

const CommonOverlayCondition = ({ children, not = false, allowedRoles }) => {
  const { role } = useAuthStateShared();

  const condition = !allowedRoles.includes(role);

  return condition ? children : null;
};

export default CommonOverlayCondition;
