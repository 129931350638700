import React, { useEffect, useState, useRef } from 'react';
import {
  Box,
  IconButton,
  Chip,
  TextField,
  Card,
  CardContent,
  Button,
  Typography,
  Alert,
  Grid
} from '@mui/material';
import { useBusinessEntityState } from 'state/useBusinessEntityState';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { ethToXdcAddress } from 'helpers/web3';
import EnhancedTableCheck from 'components/tables/EnhancedTableCheck';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SubsDetails from 'components/masterData/Dashboard/SubsDetails';
import Modal from '@mui/material/Modal';
import { useSnackbar } from 'notistack';
import { useAppState, useAuthStateShared, useHttpApi, useWeb3 } from 'state';
import { useNavigate } from 'react-router-dom';
import CreateAllotmentCustomTable from 'components/tables/CreateAllotmentCustomTable';
import { LoadingButton } from '@mui/lab';
import { onlyIssuer } from 'helpers/rbac';
import CopyAddress from 'components/CopyAddress';
import LoadingActionButton from 'helpers/LoadingActionButton';
import CanIssueChip from './CanIssueChip';
import { format, isBefore } from 'date-fns';
import _ from 'lodash';

const headCells = [
  {
    accessor: 'investor_id.investor_name',
    Header: 'Investor'
  },
  {
    accessor: 'xinfin_address',
    Header: 'Xinfin Address',
    width: 150
  },
  {
    accessor: 'requested_qty',
    Header: 'Req. Qty'
  },
  {
    Header: 'Can Issue',
    Cell: (c) => {
      return (
        <CanIssueChip
          tokenAddress={c.row.original.token_deployment_address}
          investor={c.row.original.investor_id.xinfin_account}
          value={c.row.original.requested_qty}
          nonce={Math.random()}
        />
      );
    }
  },
  {
    accessor: 'no_of_tokens',
    Header: 'Allot. Qty'
  },
  {
    accessor: 'required_funds',
    Header: 'Req. Funds'
    // Cell: (c) => "c.row.original.required_funds"
    // Cell: (c) => {
    //   // console.log("check latest nav",c.row.original.required_funds , c.row.original.latest_nav)
    //   // const rFunds = c.row.original.required_funds * c.row.original.latest_nav
    //   // return Number.isNaN(rFunds) ? 0 : rFunds;
    // }
  },
  {
    accessor: 'current_active_allotment',
    Header: 'Ongoing Allot.'
  },
  {
    accessor: 'previous_allotment',
    Header: 'Prev. Allot.'
  },
  {
    accessor: 'actions',
    Header: 'Details'
  }
];
const ActionButtons = ({ investor }) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <IconButton aria-label="subs detail" onClick={handleOpen} sx={{ mt: -1 }}>
        <VisibilityIcon sx={{ fontSize: '2rem', p: 1, m: 0, backgroundColor: '#F5F8FA' }} />
      </IconButton>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <SubsDetails investor={investor} handleClose={handleClose} />
      </Modal>
    </>
  );
};

export default function CreateAllotmentTable({ tokenPrice }) {
  const {
    whitelistedSubscriptions,
    setCurrentCreateAllotmentList,
    createAllotmentsList,
    currentToken,
    allSubscriptions,
    fetchWhiteList,
    setAllSubscriptionById,
    fetchWhiteListSubscriptions
  } = useBusinessEntityState();

  const {
    createAllotment,
    checkAllotmentDocuments,
    rejectInvestors,
    getAllSubscriptionByToken,
    updateIssuanceInitiatedStatus
  } = useHttpApi();

  const [data, setData] = useState(whitelistedSubscriptions);

  const { tvtIssue } = useWeb3();

  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();
  const { role } = useAuthStateShared();
  const [skipPageReset, setSkipPageReset] = React.useState(false);
  const [addressToQtyMap, setAddressToQtyMap] = React.useState({});
  const [loadingState, setLoadingState] = React.useState(false);
  const { throwErrorMessage } = useAppState();

  const [confirmRejectionModal, setConfirmRejectionModal] = useState(false);

  const refreshAllSubscriptions = async () => {
    const _allSubs = await getAllSubscriptionByToken(currentToken.token_id);
    setAllSubscriptionById(_allSubs);
  };
  const today = new Date();
  // get today as per issue date format
  const todayDate = format(today, 'yyyy-MM-dd');

  useEffect(() => {
    const subscriptions = whitelistedSubscriptions.filter(
      (sub) =>
        !sub.is_rejected &&
        (sub.transaction_status_id.transaction_status_desc === 'APPLIED' ||
          sub.transaction_status_id.transaction_status_desc === 'ALLOTED_PARTIAL')
    );

    const _currentWhiteList = subscriptions.map((subscriber, index) => {
      // default value set
      setAddressToQtyMap((prev) => {
        const name = subscriber?.id;
        return {
          ...prev,
          [name]: parseInt(subscriber.requested_qty, 10)
        };
      });

      subscriber.xinfin_address = (
        <CopyAddress addr={ethToXdcAddress(subscriber.xinfin_setl_account)} />
      );
      subscriber.actions = <ActionButtons investor={subscriber.investor_id} />;
      const _allotmentPrice =
        currentToken?.issue_type_id?.issue_calc_type === 'NAV'
          ? currentToken?.token_nav
          : tokenPrice;

      console.log(
        '🚀 ~ const_currentWhiteList=subscriptions.map ~ currentToken?.issue_type_id?.issue_calc_type:',
        currentToken?.issue_type_id?.issue_calc_type,
        _allotmentPrice,
        currentToken
      );
      subscriber.no_of_tokens = (
        <TextField
          type="number"
          inputProps={{ readOnly: true }}
          onWheel={(e) => {
            e.target.blur();
          }}
          onChange={(event) => {
            setAddressToQtyMap((prev) => {
              const { name } = event.target;
              return {
                ...prev,
                [name]: parseInt(event.target.value, 10)
              };
            });
            updateMyData(
              index,
              'required_funds',
              parseInt(event.target.value, 10) * Number(_allotmentPrice)
            );
          }}
          size="small"
          name={subscriber.id}
          defaultValue={subscriber.requested_qty}
        />
      );

      subscriber.required_funds =
        (addressToQtyMap[subscriber.id]
          ? addressToQtyMap[subscriber.id]
          : subscriber.requested_qty) * Number(_allotmentPrice);

      return subscriber;
    });
    setData(_currentWhiteList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [whitelistedSubscriptions]);
  // We need to keep the table from resetting the pageIndex when we
  // Update data. So we can keep track of that flag with a ref.

  // When our cell renderer calls updateMyData, we'll use
  // the rowIndex, columnId and new value to update the
  // original data
  const updateMyData = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(false);
    setData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value
          };
        }
        return row;
      })
    );
  };

  return (
    <>
      <Modal open={confirmRejectionModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 3,
            borderRadius: '0.5rem'
          }}
        >
          <Typography variant="h6">
            <b>
              Are you sure you want to reject the subscription of{' '}
              {createAllotmentsList[0] === undefined
                ? ''
                : createAllotmentsList[0].investor_id.investor_name}
              ?
            </b>
          </Typography>
          <p>This cannot be undone.</p>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
            <Button
              sx={{ mr: 2, mt: 2 }}
              variant="outlined"
              color="info"
              onClick={async () => {
                setConfirmRejectionModal(false);
              }}
            >
              CANCEL
            </Button>
            <Button
              sx={{ mt: 2 }}
              variant="contained"
              color="error"
              // disabled={currentToken.token_status_id.token_status === 'BURNT'}
              onClick={async () => {
                try {
                  const tokenId = currentToken.token_id;
                  const data = {
                    investors: createAllotmentsList.map((sub) => sub.investor_id.investor_id)
                  };
                  const res = await rejectInvestors(tokenId, data);
                  if (res.message) {
                    enqueueSnackbar('Subscription Rejected!', { variant: 'success' });
                    setConfirmRejectionModal(false);
                    fetchWhiteListSubscriptions();
                  }
                } catch (e) {
                  throwErrorMessage(e);
                }
              }}
            >
              REJECT
            </Button>
          </Box>
        </Box>
      </Modal>
      <Card sx={{ mt: 4 }}>
        <CardContent>
          <CreateAllotmentCustomTable
            tableTitle="CurrentWhitelist"
            columns={headCells}
            refreshFunction={fetchWhiteListSubscriptions}
            setSelectedRowsToState={setCurrentCreateAllotmentList}
            data={data}
            setData={setData}
            updateMyData={updateMyData}
            skipPageReset={skipPageReset}
            isRoleAllowed={['PRIMARY_ISSUER', 'ISSUER_OPERATOR'].includes(role)}
          />
        </CardContent>
      </Card>
      <Card sx={{ mt: 4, p: 0 }}>
        <CardContent
          sx={{
            p: '1 !important',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            flexDirection: 'row'
          }}
        >
          {currentToken.is_paused && (
            <Alert severity="error" sx={{ height: '3rem' }}>
              Cannot Issue For Paused Token
            </Alert>
          )}

          <Button
            variant="contained"
            sx={{
              mx: 2,
              px: 3,
              fontWeight: 400,
              width: '7.75rem'
            }}
            color="secondary"
            onClick={() => {
              navigate('/home/token-dashboard');
            }}
          >
            Close
          </Button>
          <LoadingActionButton
            loadingPosition="start"
            variant="gradient"
            disabled={!onlyIssuer(role) || currentToken.is_paused}
            sx={{ width: '15rem', mr: '1rem', px: 1 }}
            onClick={() => {
              if (createAllotmentsList.length > 0) {
                setConfirmRejectionModal(true);
              } else {
                enqueueSnackbar('Please select a subscription', { variant: 'error' });
              }
            }}
          >
            Reject Subscription
          </LoadingActionButton>
          <LoadingActionButton
            loadingPosition="start"
            variant="gradient"
            disabled={
              !onlyIssuer(role) ||
              currentToken.token_status_id.token_status === 'BURNT' ||
              currentToken.is_paused
              // isBefore(new Date(currentToken.final_maturity_date), new Date(todayDate))
            }
            sx={{
              mx: 2,
              px: 3,
              fontWeight: 500,
              width: '7.75rem'
            }}
            color="primary"
            onClick={async () => {
              try {
                const data = {};
                data.token_id = currentToken.token_id;
                data.deployment_address = currentToken.deployment_address;
                console.log('allotment data', createAllotmentsList);

                const allotmentData = createAllotmentsList.map((sub) => {
                  return {
                    xinfin_account: sub.xinfin_setl_account,
                    ioi_id: sub.id,
                    allotment_qty: addressToQtyMap[sub.id],
                    requested_qty: sub.requested_qty,
                    previous_allotment_qty: sub.previous_allotment,
                    ongoing_allotment_qty: sub.current_active_allotment,
                    documentCheck: sub.allowed_events.includes('ISSUE'),
                    approvalPending: sub.approval_pending,
                    tranche: sub.tranche,
                    mandatoryDocsCheck: sub.mandatory_docs_check
                  };
                });

                data.addresses_list = allotmentData.map((sub) => sub.xinfin_account);
                data.ioi_list = allotmentData.map((sub) => sub.ioi_id);
                data.values = allotmentData.map((sub) => sub.allotment_qty);
                data.tranches = allotmentData.map((sub) => sub.tranche);

                const apiData = {};
                apiData.token_id = data.token_id;
                apiData.ioi_list = allotmentData;
                let isError = false;

                // if (currentToken.token_type !== 'POOL_LENDING') {
                //   allotmentData.forEach((element) => {
                //     if (!element.documentCheck || !element.mandatoryDocsCheck) {
                //       console.log('yo');
                //       throw new Error(
                //         element.approvalPending
                //           ? 'Investor Documents Not Approved'
                //           : 'Investor Documents Not Uploaded',
                //         {
                //           variant: 'error'
                //         }
                //       );
                //     }
                //   });
                // }

                if (allotmentData.length === 0) {
                  enqueueSnackbar('Please select atleast one allotment', {
                    variant: 'error'
                  });
                  isError = true;
                }

                allotmentData.forEach((element) => {
                  const _total = element.allotment_qty;
                  // const _total =
                  //   element.allotment_qty +
                  //   element.previous_allotment_qty +
                  //   element.ongoing_allotment_qty;
                  if (_total > element.requested_qty) {
                    throw new Error('Cannot allot more than Token Requested Qty');
                  }
                });
                allotmentData.forEach((element) => {
                  const _total = element.allotment_qty;
                  // console.log('element', element);
                  if (_total < currentToken.minimum_subscription) {
                    throw new Error('Cannot allot less than Minimum Subscription');
                  }
                });
                allotmentData.forEach((element) => {
                  if (!element.allotment_qty || element.allotment_qty < 0) {
                    enqueueSnackbar('Qty cannot be Null or Negative!', { variant: 'error' });
                    isError = true;
                  }
                });

                if (!isError) {
                  console.log('data', data);
                  const isIssuanceInitiated = await updateIssuanceInitiatedStatus(
                    createAllotmentsList[0].id,
                    { status: true }
                  );
                  const res = await tvtIssue(
                    currentToken.deployment_address,
                    data.addresses_list[0],
                    data.values[0],
                    data.tranches[0] === 'SENIOR' ? 0 : 1
                  );

                  // const apires = await createAllotment(apiData);
                  // if (apires) {
                  // }
                  // navigate('/home/token-dashboard');
                  enqueueSnackbar('Allotment Data submitted!', { variant: 'info' });
                }
              } catch (e) {
                console.log('errrrrror', e);
                // MetaMask Tx Signature: User denied transaction signature.
                if (e.message.includes('User denied transaction signature')) {
                  const res = await updateIssuanceInitiatedStatus(createAllotmentsList[0].id, {
                    status: false
                  });
                  console.log('res', res);
                }
                throwErrorMessage(e);
              }
            }}
          >
            Submit
          </LoadingActionButton>
        </CardContent>
      </Card>
    </>
  );
}
