import * as React from 'react';
import Modal from '@mui/material/Modal';
import {
  Typography,
  Box,
  TextField,
  Button,
  Grid,
  IconButton,
  InputAdornment
} from '@mui/material';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import { useNavigate } from 'react-router';
import CloseIcon from '@mui/icons-material/Close';

const BankWireModal = ({ open, handleClose }) => {
  const handleCopyToClipboard = (value) => {
    navigator.clipboard.writeText(value);
    console.log(`Copied to clipboard: ${value}`);
  };

  const navigate = useNavigate();

  const endIcon = (value) => {
    return (
      <InputAdornment position="end">
        <IconButton
          aria-label="copy"
          onClick={() => handleCopyToClipboard(value)}
          size="small"
          sx={{ p: '6px', ml: '-12px' }}
        >
          <FileCopyOutlinedIcon fontSize="small" />
        </IconButton>
      </InputAdornment>
    );
  };

  return (
    <Modal
      BackdropProps={{
        timeout: 500,
        onClick: (e) => e.stopPropagation() // This prevents closing the modal by clicking on the backdrop
      }}
      open={open}
      onClose={handleClose}
      aria-labelledby="bank-wire-modal-title"
      aria-describedby="bank-wire-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          width: 800,
          p: 4,
          boxShadow: 24,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          borderRadius: '10px'
        }}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{ position: 'absolute', right: 8, top: 8, color: 'grey.500' }}
        >
          <CloseIcon />
        </IconButton>
        <Typography id="bank-wire-modal-title" fontWeight="bold" variant="h4" component="h2">
          Beneficiary bank account details
        </Typography>

        <Typography id="bank-wire-modal-description" variant="body1" component="p" sx={{ my: 2 }}>
          This information will be required by your bank to complete the wire transfer.
        </Typography>

        {/* Beneficiary Account Details */}
        <Grid container spacing={2}>
          {/* Left Column: Beneficiary Account Details */}
          <Grid item xs={6}>
            <TextField
              label="Beneficiary Account Currency"
              defaultValue="USD"
              variant="outlined"
              fullWidth
              disabled
              margin="dense"
              InputProps={{
                endAdornment: endIcon('USD')
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Beneficiary Name"
              defaultValue="CIRCLE INTERNET FINANCIAL, INC."
              variant="outlined"
              fullWidth
              disabled
              margin="dense"
              InputProps={{
                endAdornment: endIcon('CIRCLE INTERNET FINANCIAL, INC.')
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Beneficiary Account Number"
              defaultValue="123994123623"
              variant="outlined"
              fullWidth
              disabled
              margin="dense"
              InputProps={{
                endAdornment: endIcon('123994123623')
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Beneficiary Address"
              defaultValue="1 MAIN STREET SUITE 1"
              variant="outlined"
              fullWidth
              disabled
              margin="dense"
              InputProps={{
                endAdornment: endIcon('1 MAIN STREET SUITE 1')
              }}
            />
          </Grid>
          {/* Right Column: Bank Details */}
          <Grid item xs={6}>
            <TextField
              label="Routing Number"
              defaultValue="9999999999"
              variant="outlined"
              fullWidth
              disabled
              margin="dense"
              InputProps={{
                endAdornment: endIcon('9999999999')
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Bank Name"
              defaultValue="CRYPTO BANK"
              variant="outlined"
              fullWidth
              disabled
              margin="dense"
              InputProps={{
                endAdornment: endIcon('CRYPTO BANK')
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="SWIFT/BIC Code (If required by bank)"
              defaultValue="CYPTO99"
              variant="outlined"
              fullWidth
              disabled
              margin="dense"
              InputProps={{
                endAdornment: endIcon('CYPTO99')
              }}
            />
          </Grid>
        </Grid>

        <Box sx={{ mt: 3 }}>
          <Button
            variant="contained"
            onClick={async () => {
              handleClose();
              navigate('/subscription/investor-dashboard');
            }}
          >
            Continue
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default BankWireModal;
