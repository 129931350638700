import { useMemo, useState } from 'react';
import axios from 'axios';
import constate from 'constate';

import { API_URL } from 'config';
import { toFormData, toURLSearchParams, toFormDataRecurse } from 'helpers/http';
import userTokenPersistence from 'persistence/userTokenPersistence';
import { useSetLoggedOutState } from 'state/useAuthStateShared';
import { update } from 'lodash';

const buildApi = (setLoggedOutState, setIsLoading) => {
  const processResponse = async (callInstance) => {
    try {
      setIsLoading(true);

      const { data } = await callInstance;
      if (data && data.error) {
        throw new Error(data.error);
      }

      return data;
    } catch (e) {
      if (e.response && e.response.status) {
        switch (e.response.status) {
          case 401:
            setLoggedOutState();
            break;
          default:
        }
      }

      throw e;
    } finally {
      setIsLoading(false);
    }
  };

  const getAuthHeaders = () => {
    const token = userTokenPersistence.get();
    // console.log('✅✅🚀 ~ getAuthHeaders ~ token:', token);

    if (!token) {
      return {};
    }

    return { Authorization: `Token ${token}` };
  };

  const callAxiosMethod = (methodName, path, ...args) =>
    processResponse(
      axios[methodName](`${API_URL}${path}`, ...args, {
        headers: { ...getAuthHeaders() }
      })
    );

  const get = (...args) => callAxiosMethod('get', ...args);
  const post = (...args) => callAxiosMethod('post', ...args);
  // eslint-disable-next-line no-unused-vars
  const put = (...args) => callAxiosMethod('put', ...args);
  const patch = (...args) => callAxiosMethod('patch', ...args);
  // eslint-disable-next-line no-unused-vars
  const Delete = (...args) => callAxiosMethod('delete', ...args);

  const userLogin = (data) => post('user/login/', data);
  const userRegister = (data) => post('user/createuser/', data);
  const userLogout = (id) => get(`user/logout/${id}`);
  const getUser = (id) => get(`user/${id}/`);
  const userChangePassword = (data) => patch('user/change-password/', data);
  const userForgotPassword = (data) => post('user/reset-password/', data);
  const confirmForgotPassword = (data) => post('user/reset-password/confirm/', data);
  // eslint-disable-next-line no-unused-vars
  const getCurrentUser = (id) => get(`user/getuser/`);

  // Organization API calls
  const getAllOrganizations = () => get('business-entities/get-all-organizations/');
  const UpdateOrganization = (id, data) =>
    patch(`business-entities/update-organization/${id}/`, toFormData(data));

  const getAllUsers = () => get('user/');
  const removeUser = (id) => Delete(`user/${id}/`);
  const checkUserNameAvailbility = (username) => get(`user/check-username/${username}/`);

  // Investor calls
  const getAllInvestors = () => get('business-entities/get-all-investors/');
  const approveInvestorbyId = (id) => put(`business-entities/approve-investor/${id}/`, {});
  const createInvestor = (data) => post('business-entities/investor-create/', data);
  const getInvestorsByIssuer = (issuerId) =>
    get(`business-entities/get-investors-by-issuer/${issuerId}`);
  const getInvestorIssuerMapping = (issuerId, investorId) =>
    get(
      `business-entities/get-investorsinsurermapping-by-issuer-and-investor/${issuerId}/${investorId}`
    );
  const addToIssuerWhitelist = (issuerId, data) =>
    put(`business-entities/add-to-issuer-whitelist/${issuerId}`, data);
  const removeFromIssuerWhitelist = (issuerId, data) =>
    put(`business-entities/remove-from-issuer-whitelist/${issuerId}`, data);
  const updateIssuerInvestorMapping = (issuerId, investorId, data) =>
    patch(`business-entities/update-issuer-investor-mapping/${issuerId}/${investorId}`, data);
  const rejectInvestors = (tokenId, data) =>
    put(`business-entities/reject-investors/${tokenId}`, data);
  const acceptInvestors = (tokenId, data) =>
    put(`business-entities/accept-investors/${tokenId}`, data);

  // Xinfin Calls
  const getAllXinfinAccounts = () => get('user/get-all-xinfin-accounts/');

  // Issuer calls
  const getAllIssuers = () => get('business-entities/get-all-issuer/');
  const createIssuer = (data) => post('business-entities/issuer-create/', toFormData(data));
  const getIssuerById = (id) => get(`business-entities/get-issuer/${id}`);
  const editIssuer = (id, data) =>
    patch(`business-entities/update-issuer/${id}/`, toFormData(data));
  const deleteIssuer = (id) => Delete(`business-entities/delete-issuer/${id}/`);
  const getIssuersByLiquidityPoolAddr = (addr) =>
    get(`business-entities/get-issuers-by-liquidity-pool-address/${addr}`);
  // Fund manager calls
  const createFundManager = (data) =>
    post(`business-entities/fund-manager-create/`, toFormData(data));
  const getAllFundManagers = () => get('business-entities/get-all-fund-managers/');
  const getFundManagerById = (id) => get(`business-entities/get-fund-manager/${id}`);
  const editFundManager = (id, data) =>
    patch(`business-entities/update-fund-manager/${id}/`, toFormData(data));
  const deleteFundManager = (id) => Delete(`business-entities/delete-fund-manager/${id}`);
  // Token calls
  const getAllTokens = () => get('token/get-all-tokens/');
  const getAllDefaultValues = () => get('token/get-default-token-values/');
  const createToken = (data) => post('token/create-token/', toFormData(data));
  const editToken = (id, data) => patch(`token/update-token-by-id/${id}/`, toFormData(data));
  const getTokenById = (id) => get(`token/get-token-by-id/${id}/`);
  const getTokenByDeploymentAddress = (deploymentAddress) =>
    get(`token/get-token-by-deployment-address/${deploymentAddress}/`);
  const getTokenDetailsById = (id) => get(`token/get-tokendetails-by-id/${id}/`);
  const getAllTokenStatus = () => get('coretables/tokenstatus/');
  const updateTokenStatus = (id, data) => post(`token/update-token-status-by-id/${id}/`, data);
  const getTokenDetailsByIssuerIdandInsurerId = (issuerId, insurerId) =>
    get(`token/get-token-by-issuer-insurer-id/${issuerId}/${insurerId}/`);
  const getPaymentTokenByCurrency = (currency) =>
    get(`token/get-payment-token-by-currency/${currency}`);
  const getAllTokenFeildValues = () => get('token/get-all-token-form-values/');
  const getNav = (id) => get(`token/get-nav/${id}/`);
  //  Custodian calls
  // const getAllCustodian = () => get('business-entities/get-all-custodians/');
  // const createCustodian = (data) => post('business-entities/custodian-create/', toFormData(data));
  // const editCustodian = (id, data) =>
  //   patch(`business-entities/update-custodian/${id}/`, toFormData(data));
  // const getCustodianById = (id) => get(`business-entities/get-custodian/${id}`);
  // const deleteCustodian = (id) => Delete(`business-entities/delete-custodian/${id}/`);
  // // cash-leg calls
  // const getAllbankAccounts = () => get('cash-leg/get-all-bank-account/');
  // const getAllbeneficieryTypes = () => get('cash-leg/get-all-beneficiery-type/');

  // KYC Provider calls
  const getAllKycProvider = () => get('business-entities/get-all-kycprovider/');
  const createKycProvider = (data) =>
    post('business-entities/kycprovider-create/', toFormData(data));
  const getKycProviderById = (id) => get(`business-entities/get-kycprovider/${id}/`);
  const editKycProvider = (id, data) =>
    patch(`business-entities/update-kycprovider/${id}/`, toFormData(data));
  const updateKycVerification = (issuerId, investorId, data) =>
    patch(`business-entities/update-kyc-verification/${issuerId}/${investorId}/`, data);
  const getKycVerificationWithToken = (issuerId, investorId, tokenId) =>
    get(`business-entities/get-kyc-verification/${issuerId}/${investorId}/${tokenId}`);
  const getKycVerification = (issuerId, investorId) =>
    get(`business-entities/get-kyc-verification/${issuerId}/${investorId}/`);
  const deleteKycProvider = (id) => Delete(`business-entities/delete-kycprovider/${id}/`);
  const updateKycOnChain = (issuerId, investorId) =>
    patch(`business-entities/update-kyc-status-on-chain/${issuerId}/${investorId}/`);

  // Originator calls
  const getAlloriginator = () => get('business-entities/get-all-originator/');
  const createOriginator = (data) => post('business-entities/originator-create/', data);
  const getOriginatorById = (id) => get(`business-entities/get-originator/${id}/`);
  const editOriginator = (id, data) => patch(`business-entities/update-originator/${id}/`, data);
  const deleteOriginator = (id) => Delete(`business-entities/delete-originator/${id}/`);

  //  Insurer calls
  const getAllInsurers = () => get('business-entities/get-all-insurers/');
  const createInsurer = (data) => post('business-entities/insurer-create/', toFormData(data));
  const editInsurer = (id, data) =>
    patch(`business-entities/update-insurer/${id}/`, toFormData(data));
  const getInsurerById = (id) => get(`business-entities/get-insurer/${id}`);
  const deleteInsurer = (id) => Delete(`business-entities/delete-insurer/${id}/`);

  // Liquidity Pool calls
  const createLiquidityPool = (data) =>
    post('business-entities/create-liquidity-pool/', toFormData(data));
  const getLiquidityPoolById = (id) => get(`business-entities/get-liquidity-pool/${id}/`);
  const getAllLiquidityPools = () => get('business-entities/get-all-liquidity-pool/');
  const getAllOnChainLiquidityPools = () =>
    get('business-entities/get-all-on-chain-liquidity-pool/');
  const editLiquidityPool = (id, data) =>
    patch(`business-entities/update-liquidity-pool/${id}/`, toFormData(data));
  const deleteLiquidityPool = (id) => Delete(`business-entities/delete-liquidity-pool/${id}/`);
  const getLiquidityPoolsByIssuerId = (id) =>
    get(`business-entities/get-liquidity-pool-by-issuer-id/${id}`);
  const getPoolByPoolAddress = (addr) => get(`token/get-pool-by-pool-address/${addr}/`);
  const getAssetsByPoolAddress = (addr) => get(`token/get-assets-by-pool-address/${addr}/`);

  // Liquidity Pool Payment Token

  const getAllPoolPaymentTokens = () => get('token/get-all-pool-payment-token/');
  const addPoolPaymentToken = (data) => post('token/create-pool-payment-token/', data);
  const deletePoolPaymentToken = (id) => Delete(`token/delete-pool-payment-token/${id}/`);
  const getAllPoolPaymentTokenByPoolAddr = (addr) =>
    get(`token/get-pool-payment-token-by-pool-address/${addr}/`);

  // Insurer Issuer Mapping
  const createIssuerInsurerMapping = (data) =>
    post('business-entities/create-issuer-insurer-mapping/', toFormData(data));
  const editIssuerInsurerMapping = (issuerId, insurerId, data) =>
    patch(
      `business-entities/update-issuer-insurer-mapping/${issuerId}/${insurerId}/`,
      toFormData(data)
    );
  const getIssuerInsurerMappingByIssuerId = (id) =>
    get(`business-entities/get-issuer-insurer-mappings-by-issuer-id/${id}`);
  const getIssuerInsurerMappingByInsurerId = (id) =>
    get(`business-entities/get-issuer-insurer-mappings-by-insurer-id/${id}`);
  const getAllIssuerInsurerMapping = () =>
    get('business-entities/get-all-issuer-insurer-mappings/');
  const deleteIssuerInsurerMapping = (issuerId, insurerId) =>
    Delete(`business-entities/delete-issuer-insurer-mapping/${issuerId}/${insurerId}`);

  // Liquidity Pool Issuer Mapping
  const createIssuerLiquidityPoolMapping = (data) =>
    post('business-entities/create-issuer-liquidity-pool-mapping/', toFormData(data));
  const getAllIssuerLiquidityPoolMapping = () =>
    get('business-entities/get-all-issuer-liquidity-pool-mappings/');
  const getIssuerLiquidityPoolMappingByIssuerId = (id) =>
    get(`business-entities/get-issuer-liquidity-pool-mappings-by-issuer-id/${id}`);
  const deleteIssuerLiquidityPoolMapping = (issuerId, insurerId) =>
    Delete(`business-entities/delete-issuer-liquidity-pool-mapping/${issuerId}/${insurerId}`);

  // CORETABLES API CALLS
  const getAllCountries = () => get('coretables/country/');
  const getAllCurrencies = () => get('coretables/currency/');
  const getPaymentTokenCurrencies = () => get('coretables/payment-token-currencies/');
  const getIssueType = () => get('coretables/issuetype/');
  const getIssueTypeBySubCategory = (subcategoryid) =>
    get(`coretables/issue-type-by-sub-category/${subcategoryid}/`);
  const getAllIssueCategories = () => get('coretables/issuecategory/');
  const getAllIssueSubCategories = () => get('coretables/issuesubcategory/');
  const getAllIssueSubCategoriesByCategory = (categoryid) =>
    get(`coretables/issue-sub-category-by-category/${categoryid}/`);
  const getAllPaymentLegs = () => get('coretables/paymentleg/');
  const getAllPaymentFreq = () => get('coretables/paymentfrequency/');
  const getAllPaymentBasis = () => get('coretables/paymentbasis/');
  const getAllInterestType = () => get('coretables/interesttype/');
  const getAllInterestCalcMethod = () => get('coretables/interestcalcmethod/');
  const getAllAccrualFreq = () => get('coretables/accrualfrequency/');
  const getAllTransactionType = () => get('coretables/transactiontype/');
  const getKYCStatus = () => get('coretables/kycstatus/');
  const getCollateralProvider = () => get('coretables/collateralprovider/');

  // Subscription Calls
  const getAllPublishedTokens = () => get('token/get-all-published-dl-tokens/');
  const getAllPublishedPTTokens = () => get('token/get-all-published-pt-tokens/');
  const createSubscription = (data) => post('subscription/create-subscription/', toFormData(data));
  const getSubscriptionByAddress = (address, tokenid) =>
    get(`subscription/get-subscription-details/${tokenid}/${address}/`);
  const getAllSubscriptionByToken = (tokenid) =>
    get(`subscription/get-all-subscription-details/${tokenid}/`);
  const addToWhitelist = (data) => post('subscription/add-whitelist/', data);
  const removeFromWhitelist = (data) => post('subscription/remove-whitelist/', data);
  const getAllWhitelistedSubscription = (tokenid) =>
    get(`subscription/get-whitelisted-subscription/${tokenid}/`);
  const createAllotment = (data) => post('subscription/create-allotment/', data);
  const getAllAllotments = (id) => get(`subscription/get-allotments/${id}/`);
  const updateAllotmentRedeemRequestStatus = (id, data) =>
    put(`subscription/update-allotment-redeem-request/${id}/`, data);
  const getAllSubscriptionsByXinFinAddress = (xinfinAddress) =>
    get(`subscription/get-subscription-details-by-xinfin-address/${xinfinAddress}/`);
  const getAllRedeemedAllotments = (xinfinAddress) =>
    get(`subscription/get-redeemed-allotements-by-xinfin-address/${xinfinAddress}/`);
  const getAllRedeemedAllotmentsByToken = (tokenid) =>
    get(`subscription/get-redeemed-allotements-by-token/${tokenid}/`);
  const getAllSubscribersByKycProviderXinfinAddr = (xinfinAddress) =>
    get(`subscription/get-subscribers-by-kyc-provider-xinfin-addr/${xinfinAddress}/`);
  const updateIssuanceInitiatedStatus = (subscriptionId, data) =>
    put(
      `subscription/update-issuance-initiated-status-by-subscription-id/${subscriptionId}/`,
      data
    );
  // Subscription Contract Calls using Liminal
  const tvtSwapRedeemContractApi = (id, data) => post(`subscription/tvt-swap-redeem/${id}`, data);

  const requestRedeemApi = (data) => post('subscription/request-redeem/', data);
  const getAllotmentsByXinFinAddress = (xinfinAddress) =>
    get(`subscription/get-allotements-by-xinfin-address/${xinfinAddress}/`);
  const checkAllotmentDocuments = (subId) =>
    post(`subscription/check-allotment-documents/${subId}/`);

  const createIssuerInvestorMapping = (data) =>
    post('business-entities/create-issuer-investor-mapping/', data);

  const getTokenSummaryDataByTokenId = (tokenId) =>
    get(`subscription/get-allotment-summary-data/${tokenId}/`);

  // Payment Token calls
  const addPaymentToken = (data) => post('token/create-payment-token/', data);
  const getAllPaymentTokens = () => get('token/get-all-payment-tokens/');
  const removePaymentToken = (id) => Delete(`token/delete-payment-token-by-id/${id}/`);
  const getPaymentTokenByAddress = (address) =>
    get(`token/get-payment-token-by-address/${address}/`);

  // Collateral Token calls
  // const getAllCollateralTokens = () => get('coretables/collateralasset/');
  // INvestor Classifications
  const getAllInvestorClassifications = () => get('coretables/investorclassification/');
  const getIssuanceEscrowByToken = (tokenid) =>
    get(`subscription/get-issuance-by-token/${tokenid}`);

  // Escrow Issuance calls (Investor)
  const getAllIssuanceEscrowByAddress = (address) =>
    get(`subscription/get-all-issuance-escrows-by-xinfin-addr/${address}`);

  // Redemption Escrow calls
  const getAllRedemptionEscrowByAddress = (address) =>
    get(`subscription/get-all-redemption-escrows-by-xinfin-addr/${address}`);
  const getAllRedemptionEscrowBIssuerAddress = (address) =>
    get(`subscription/get-all-redemption-escrows-by-issuer-xinfin-addr/${address}`);

  const getAllRedemptionEscrowByTokenId = (tokenId) =>
    get(`subscription/get-redemption-escrow-by-token/${tokenId}/`);
  // Tx Actions
  const createTransactionAction = (data) => post('blockchain/transaction-action/', data);
  const getTransactionAction = (id) => get(`blockchain/transaction-action/${id}/`);

  // Get ALL RuleTemplates
  const getAllRuleTemplates = () => get('restrictions/get-all-rule-templates-by-user/');
  // Create Rule Template
  const createRuleTemplate = (data) => post('restrictions/create-rule-template/', data);
  const updateRuleTemplate = (data) => post('restrictions/update-rule-template/', data);
  const setupTokenRuleTemplate = (data) =>
    post('restrictions/create-token-rule-template-mapping/', data);
  const getRuleTemplateByToken = (tokenid) =>
    get(`restrictions/get-token-rule-template/${tokenid}/`);
  const getRuleTemplateDetailedByToken = (tokenid) =>
    get(`restrictions/get-token-detail-rule-template/${tokenid}/`);

  // Payment Transactions
  const createPaymentTransaction = (data) => post('crypto-leg/create-payment-transaction/', data);
  const getAllPaymentTransaction = () => get(`crypto-leg/get-all-payment-transactions/`);
  const getPaymentTransactionByTokenId = (tokenId) =>
    get(`crypto-leg/get-payment-transaction-by-token-id/${tokenId}/`);
  const getPaymentTransactionByXinfinAddress = (xinfinAddress) =>
    get(`crypto-leg/get-payment-transaction-by-xinfin/${xinfinAddress}/`);

  // Crypto Asset Transaction
  const createCryptoAssetTransaction = (data) =>
    post('crypto-leg/create-crypto-asset-transaction/', data);
  const getAllCryptoAssetTransaction = () => get(`crypto-leg/get-all-crypto-asset-transactions/`);
  const getCryptoAssetTransactionByIssuerXinfin = (xinfinAddress) =>
    get(`crypto-leg/get-crypto-asset-transaction-by-issuer-xinfin/${xinfinAddress}/`);
  const getCryptoAssetTransactionByIssuerInsurerXinfin = (issuerXinfin, insurerXinfin) =>
    get(
      `crypto-leg/get-crypto-asset-transaction-by-issuer-insurer-xinfin/${issuerXinfin}/${insurerXinfin}/`
    );

  // insurer-issuer Escrow
  const getIssuanceEscrowOrderByInsurerIssuerId = (issuerId, insurerId) =>
    get(`subscription/get-issuance-escrow-orders-by-issuer-and-insurer/${issuerId}/${insurerId}/`);
  const getRedemptionEscrowOrderByInsurerIssuerId = (issuerId, insurerId) =>
    get(
      `subscription/get-redemption-escrow-orders-by-issuer-and-insurer/${issuerId}/${insurerId}/`
    );
  const getAllotmentsByInsurerIssuerId = (issuerId, insurerId) =>
    get(`subscription/get-allotments-by-issuer-and-insurer/${issuerId}/${insurerId}/`);
  const getAllRedemptionsByInsurerIssuerId = (issuerId, insurerId) =>
    get(`subscription/get-redemptions-by-issuer-and-insurer/${issuerId}/${insurerId}/`);

  const getErrorString = (code) =>
    get(`coretables/business-status-error-code/?contract_message=${code}`);

  // document calls
  const getSha256Hash = (data) =>
    post('document-management/get-file-sha256-hash/', toFormData(data));
  const get0xSha256Hash = (data) =>
    post('document-management/get-file-0x-sha256-hash/', toFormData(data));
  const getBlockchainTransactions = () => get('blockchain/get-blockchain-transactions/');
  const getCollateralTransactionsByIssuer = (xinfinaddress) =>
    get(`collateral/get-all-collateral-transactions/?issuer_xinfin_address=${xinfinaddress}`);
  const getCollateralTransactionsByInsurer = (xinfinaddress) =>
    get(`collateral/get-all-collateral-transactions/?insurer_xinfin_address=${xinfinaddress}`);

  // Notifications API Calls
  const getLoggedInUserNotifications = () => get('notifications/logged-in-user/');
  const markAllNotificationsAsRead = (data) =>
    post('notifications/logged-in-user/mark-all-as-read/', data);
  const getLoggedInUserAllReadNotifications = () => get('notifications/logged-in-user/read/');
  const markNotificationAsRead = (id, data) => put(`notifications/mark-as-read/${id}/`, data);
  const getNotificationsByAddress = (address) =>
    get(`notifications/notification-by-address/${address}/`);
  const getAllReadNotificationByAddress = (address) =>
    get(`notifications/read-notification-by-address/${address}`);
  const markAllNotificationAsReadByAddress = (data) =>
    post('notifications/markall-notification-as-read-by-address/', data);

  // Health CHECK API

  const getHealth = () => get('health-check/');
  const getAllCollateralTransactions = () => get('collateral/get-all-collateral-transactions/');

  // subscription documents
  const updateSubscriptionDocuments = (data) =>
    post('subscription/update-subscription-documents/', toFormData(data));
  const updateSubscriptionDocumentsDirectly = (data) =>
    post('subscription/update-subscription-documents/', data);
  const getAllDocumentTypes = () => get('coretables/get-all-document-types/');
  const addDocumentType = (data) => post('coretables/add-document-type/', data);
  const updateDocumentType = (id, data) => patch(`coretables/update-document-type/${id}/`, data);
  const deleteDocumentType = (id) => Delete(`coretables/delete-document-type/${id}/`);
  const getAllDocumentSubTypes = () => get('coretables/get-all-document-subtypes/');
  const addDocumentSubType = (data) => post('coretables/add-document-subtype/', data);
  const updateDocumentSubType = (id, data) =>
    patch(`coretables/update-document-subtype/${id}/`, data);
  const deleteDocumentSubType = (id) => Delete(`coretables/delete-document-subtype/${id}/`);
  const getTokenLifecycleDocumentMappingsByIssueType = (id) =>
    get(`coretables/get-all-token-lifecycle-document-mappings-by-issue-type/${id}`);
  const createUpdateTokenLifecycleDocumentMappingsByIssueType = (data) =>
    post('coretables/create-update-token-lifecycle-document-mappings/', data);
  const getTokenNavPool = (id) => get(`token/get-token-nav-history/${id}`);
  const getPoolProxyToken = (id) => get(`token/get-liquidity-pool-proxy-token/${id}`);
  const getPoolHoldings = (id) => get(`token/get-liquidity-pool-holdings/${id}`);

  // TVL

  const getDailyTvl = () => get('token/get-daily-tvl/');

  // Payment urls
  const createLinkToken = (product) => get(`payment/api/create_link_token/${product}`);
  const getIDVList = () => get(`payment/api/get_kyc_auth_status`);
  const exchangePublicToken = (publicToken, accountId) => get(`payment/api/exchange_public_token/${publicToken}/${accountId}`);
  const getProcessorToken = () => get(`payment/api/get_processor_token`);
  const getAstraAccessToken = (authCode) => get(`payment/api/astra/get_access_token/${authCode}`);
  const createAstraRoutine = (data) => post('payment/api/astra/create_routine/', data)
  return {
    getPoolByPoolAddress,
    getAssetsByPoolAddress,
    userLogin,
    userLogout,
    getUser,
    userRegister,
    getCurrentUser,
    userChangePassword,
    userForgotPassword,
    getAllUsers,
    getAllCountries,
    getAllOrganizations,
    getAllCurrencies,
    getIssueType,
    getAllIssuers,
    createIssuer,
    editIssuer,
    getIssuerById,
    deleteIssuer,
    createFundManager,
    getAllFundManagers,
    getKYCStatus,
    getAllDefaultValues,
    // getAllCustodian,
    // createCustodian,
    // editCustodian,
    // getCustodianById,
    // deleteCustodian,
    createKycProvider,
    getAllKycProvider,
    getKycProviderById,
    editKycProvider,
    deleteKycProvider,
    // getAllbankAccounts,
    // getAllbeneficieryTypes,
    getAllIssueCategories,
    getAllIssueSubCategories,
    getIssueTypeBySubCategory,
    getAllIssueSubCategoriesByCategory,
    getAllPaymentLegs,
    getAllPaymentFreq,
    getAllPaymentBasis,
    getAllInterestType,
    getAllInterestCalcMethod,
    getAllAccrualFreq,
    getAllTransactionType,
    getCollateralProvider,
    getAllTokenStatus,
    getAllTokens,
    createToken,
    getAlloriginator,
    createOriginator,
    editOriginator,
    getOriginatorById,
    deleteOriginator,
    getAllPublishedTokens,
    createSubscription,
    getSubscriptionByAddress,
    getAllSubscriptionByToken,
    editToken,
    getTokenById,
    getTokenByDeploymentAddress,
    getTokenDetailsById,
    updateTokenStatus,
    removeUser,
    addToWhitelist,
    removeFromWhitelist,
    getAllWhitelistedSubscription,
    createAllotment,
    getAllAllotments,
    requestRedeemApi,
    checkUserNameAvailbility,
    addPaymentToken,
    getAllPaymentTokens,
    removePaymentToken,
    // getAllCollateralTokens,
    getAllSubscriptionsByXinFinAddress,
    createTransactionAction,
    getTransactionAction,
    getIssuanceEscrowByToken,
    getAllIssuanceEscrowByAddress,
    getAllRedemptionEscrowByAddress,
    getAllotmentsByXinFinAddress,
    getAllRedemptionEscrowByTokenId,
    getAllInvestors,
    approveInvestorbyId,
    getAllRedeemedAllotments,
    getAllRedeemedAllotmentsByToken,
    getAllXinfinAccounts,
    getAllSubscribersByKycProviderXinfinAddr,
    UpdateOrganization,
    getAllInsurers,
    createInsurer,
    editInsurer,
    getInsurerById,
    deleteInsurer,
    getInvestorsByIssuer,
    addToIssuerWhitelist,
    removeFromIssuerWhitelist,
    createIssuerInsurerMapping,
    editIssuerInsurerMapping,
    getIssuerInsurerMappingByIssuerId,
    getIssuerInsurerMappingByInsurerId,
    deleteIssuerInsurerMapping,
    getAllIssuerInsurerMapping,
    getAllInvestorClassifications,
    getAllRuleTemplates,
    createRuleTemplate,
    updateKycVerification,
    getKycVerification,
    updateRuleTemplate,
    setupTokenRuleTemplate,
    getRuleTemplateByToken,
    createPaymentTransaction,
    getAllPaymentTransaction,
    getPaymentTransactionByTokenId,
    getPaymentTransactionByXinfinAddress,
    createInvestor,
    getRuleTemplateDetailedByToken,
    getTokenDetailsByIssuerIdandInsurerId,
    updateIssuerInvestorMapping,
    rejectInvestors,
    acceptInvestors,
    getIssuanceEscrowOrderByInsurerIssuerId,
    getRedemptionEscrowOrderByInsurerIssuerId,
    getAllotmentsByInsurerIssuerId,
    getAllRedemptionsByInsurerIssuerId,
    createCryptoAssetTransaction,
    getAllCryptoAssetTransaction,
    getCryptoAssetTransactionByIssuerXinfin,
    getCryptoAssetTransactionByIssuerInsurerXinfin,
    getErrorString,
    getSha256Hash,
    get0xSha256Hash,
    getBlockchainTransactions,
    getCollateralTransactionsByIssuer,
    getCollateralTransactionsByInsurer,
    confirmForgotPassword,
    getLoggedInUserNotifications,
    getLoggedInUserAllReadNotifications,
    markAllNotificationsAsRead,
    markNotificationAsRead,
    getHealth,
    getNotificationsByAddress,
    getAllReadNotificationByAddress,
    markAllNotificationAsReadByAddress,
    getAllCollateralTransactions,
    updateSubscriptionDocuments,
    updateSubscriptionDocumentsDirectly,
    checkAllotmentDocuments,
    getKycVerificationWithToken,
    getAllDocumentTypes,
    addDocumentType,
    updateDocumentType,
    deleteDocumentType,
    getAllDocumentSubTypes,
    addDocumentSubType,
    updateDocumentSubType,
    deleteDocumentSubType,
    getTokenLifecycleDocumentMappingsByIssueType,
    createUpdateTokenLifecycleDocumentMappingsByIssueType,
    createLiquidityPool,
    getLiquidityPoolById,
    getAllLiquidityPools,
    getAllOnChainLiquidityPools,
    editLiquidityPool,
    deleteLiquidityPool,
    getAllPublishedPTTokens,
    createIssuerLiquidityPoolMapping,
    getPaymentTokenByAddress,
    getAllPoolPaymentTokens,
    addPoolPaymentToken,
    deletePoolPaymentToken,
    getAllIssuerLiquidityPoolMapping,
    getIssuerLiquidityPoolMappingByIssuerId,
    deleteIssuerLiquidityPoolMapping,
    getAllPoolPaymentTokenByPoolAddr,
    getTokenNavPool,
    getIssuersByLiquidityPoolAddr,
    getPoolProxyToken,
    getPoolHoldings,
    getDailyTvl,
    getPaymentTokenCurrencies,
    getAllRedemptionEscrowBIssuerAddress,
    updateAllotmentRedeemRequestStatus,
    getLiquidityPoolsByIssuerId,
    getPaymentTokenByCurrency,
    updateKycOnChain,
    getInvestorIssuerMapping,
    getAllTokenFeildValues,
    getNav,
    createIssuerInvestorMapping,
    getTokenSummaryDataByTokenId,
    tvtSwapRedeemContractApi,
    updateIssuanceInitiatedStatus,
    getFundManagerById,
    deleteFundManager,
    editFundManager,
    createLinkToken,
    getIDVList,
    exchangePublicToken,
    getProcessorToken,
    getAstraAccessToken,
    createAstraRoutine
  };
};

export const useHttpApi_ = () => {
  const setLoggedOutState = useSetLoggedOutState();
  const [isLoading, setIsLoading] = useState(false);

  const api = useMemo(() => buildApi(setLoggedOutState, setIsLoading), [setLoggedOutState]);

  return { api, isLoading };
};

export const [HttpApiProvider, useHttpApi, useHttpApiLoading] = constate(
  useHttpApi_,
  ({ api }) => api,
  ({ isLoading }) => isLoading
);
