import { motion } from 'framer-motion';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Button, Typography, Container } from '@mui/material';
import { MotionContainer, varBounceIn } from '../components/animate';
import Page from '../components/Page';
import image from '../assets/images/404.png';
import Logo from '../components/Logo';

const Page404 = () => {
  return (
    <>
      <Box>
        <Logo sx={{ p: 2, width: '11rem' }} />
      </Box>
      <Page title="404 Page Not Found">
        <Container>
          <MotionContainer initial="initial" open>
            <Box sx={{ maxWidth: 600, margin: 'auto', textAlign: 'center' }}>
              <motion.div variants={varBounceIn}>
                <Box component="img" src={image} />
              </motion.div>
              <Typography sx={{ fontSize: '1.688rem', fontWeight: 600 }} paragraph>
                The page you were looking for does not exist
              </Typography>

              <Button
                to="/"
                size="large"
                variant="gradient"
                component={RouterLink}
                sx={{ width: '20rem', mt: 2 }}
              >
                <Typography>Go to Homepage</Typography>
              </Button>
            </Box>
          </MotionContainer>
        </Container>
      </Page>
    </>
  );
};

export default Page404;
