import { Box, Card, CardContent, Container, Divider, Typography } from '@mui/material';
import React from 'react';
import Yield from 'assets/images/drop.svg';
import { useBusinessEntityState, useWeb3, useSubscriptionState, useAppState } from 'state';

const PoolDetail = ({ header, info, otherComp }) => {
  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          {otherComp}
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            {header}
          </Typography>
        </Box>
        <Typography variant="p">{info}</Typography>
      </Box>
    </>
  );
};

const InfoIcon = ({ icon }) => {
  return (
    <Box
      component="img"
      src={icon}
      sx={{ height: '1rem', width: '1rem', objectFit: 'contain', mr: 2 }}
    />
  );
};

const PoolInfo = () => {
  const { currentSubsToken, poolDetails } = useBusinessEntityState();
  console.log(poolDetails);
  const header = 'Trade Finance Finance Lending';
  const info = 'Asset Type';
  const header1 = '3 Years';
  const info1 = 'Asset Maturity';
  const header2 = `${poolDetails.senior_apy} % `;
  const info2 = 'APY (30 Days)';
  const header3 = ` ${poolDetails.current_supply * poolDetails.nav} USD`;
  const info3 = 'Pool Value';

  return (
    <Container sx={{ mt: 2 }}>
      <Card>
        <CardContent
          sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', p: 2 }}
        >
          <PoolDetail header={currentSubsToken.issue_type_id.issue_type_name} info={info} />
          <Divider orientation="vertical" variant="middle" flexItem />
          <PoolDetail header={`${currentSubsToken.tenure_months} Days`} info={info1} />
          <Divider orientation="vertical" variant="middle" flexItem />
          <PoolDetail header={header2} info={info2} otherComp={<InfoIcon icon={Yield} />} />
          <Divider orientation="vertical" variant="middle" flexItem />
          <PoolDetail header={header3} info={info3} />
        </CardContent>
      </Card>
    </Container>
  );
};

export default PoolInfo;
