/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import Page from 'components/Page';

import {
  Container,
  Card,
  Grid,
  Box,
  CardContent,
  Typography,
  TextField,
  FormLabel,
  Button,
  Divider,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  Alert
} from '@mui/material';
import Breadcrumbs from 'components/Breadcrumbs';
import {
  useHttpApi,
  useCoreTableState,
  useBusinessEntityState,
  useAuthStateShared,
  useAppState
} from 'state';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { leiRegex, xdcRegex } from 'helpers/regex';
import { useNavigate } from 'react-router';
import { LeiInfoButton, XdcInfoButton } from 'helpers/infoButton';
import CommonOverlay from 'components/CommonOverlay';
import { onlyIssuer } from 'helpers/rbac';
import { toChecksumAddress, xdcToEthAddress } from 'helpers/web3';

const AddInvestor = () => {
  const { createInvestor } = useHttpApi();
  const { role } = useAuthStateShared();
  const { throwErrorMessage } = useAppState();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  // Investor Schema
  const AddInvestorSchema = Yup.object().shape({
    investor_name: Yup.string().required('Name is required'),
    investor_email_id: Yup.string().email('Invalid Email Format').required('Email is required'),
    investor_phone_no: Yup.number()
      .required('Phone Number is required')
      .positive('Phone Number cannot be negative')
      .integer('Phone Number must be an integer')
      .max(9999999999, 'Please Enter Valid Phone Number'),
    lei: Yup.string()
      .matches(leiRegex, 'please enter a valid LEI')
      .min(20, 'LEI must be 20 characters')
      .max(20, 'LEI must be 20 characters')
      .required('LEI is required'),
    investor_country_code: Yup.string().required('Country code is required'),
    xinfin_address: Yup.string()
      .matches(xdcRegex, 'Please enter a valid XDCPay Wallet Address')
      .required('Investor XDCPay Wallet Address is required'),
    address_line_1: Yup.string()
      .required('Address line 1 is required')
      .max(100, 'Address line 1 cannot be more than 100 characters'),
    address_line_2: Yup.string()
      .required('Address line 2 is required')
      .max(100, 'Address line 2 cannot be more than 100 characters'),
    city: Yup.string()
      .required('City is required')
      .max(30, 'City cannot be more than 30 characters'),
    state: Yup.string()
      .required('State is required')
      .max(30, 'State cannot be more than 30 characters'),
    zip_code: Yup.string()
      .required('zip code is required')
      .max(30, 'zip code cannot be more than 30 characters')
  });

  // Formik
  const formik = useFormik({
    initialValues: {
      investor_name: '',
      lei: '',
      investor_country_code: '',
      xinfin_address: '',
      investor_email_id: '',
      investor_phone_no: '',
      address_line_1: '',
      address_line_2: '',
      city: '',
      state: '',
      zip_code: ''
    },
    validationSchema: AddInvestorSchema,
    onSubmit: async (data, { resetForm }) => {
      console.log('AddInvestor', data);
      try {
        setError(null);
        data.xinfin_address = xdcToEthAddress(data.xinfin_address);
        data.xinfin_address = toChecksumAddress(data.xinfin_address);
        const _investor = await createInvestor(data);
        if (_investor) {
          enqueueSnackbar('Investor Created Successfully', {
            variant: 'success'
          });
          resetForm();
          navigate('/masterdata/investor');
        }
      } catch (e) {
        const errorMessage = await throwErrorMessage(e);
        setError(errorMessage);
      }
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;
  const { countries } = useCoreTableState();
  const allowedRolesList = ['PRIMARY_ISSUER'];

  return (
    <Page title="Add Investor - Master Data">
      <CommonOverlay
        allowedRoles={allowedRolesList}
        overlayTitle="You don't have access to Add Investor Account"
      />
      <Container sx={{ my: 2 }}>
        <Box sx={{ my: 2 }}>
          <Breadcrumbs aria-label="breadcrumb" pageHead="Add Investor" />
        </Box>
        {error && (
          <Box mb={4}>
            <Alert severity="error">{error}</Alert>
          </Box>
        )}
        <Card>
          <Container>
            <Typography
              sx={{
                fontSize: '1.125rem',
                fontWeight: 'bold',
                mt: 4,
                mb: 1,
                ml: 4
              }}
            >
              Investor Details
            </Typography>
            <Divider
              sx={{
                width: '100%',
                backgroundColor: '#f5f8fa',
                border: '2px solid #f5f8fa'
              }}
            />
            <FormikProvider value={formik}>
              <Form autocomplete="off" onSubmit={handleSubmit}>
                <CardContent sx={{ m: 2 }}>
                  <Grid container spacing={3} sx={{ width: '100%' }}>
                    <Grid item lg={6} md={6} xs={12} sm={12}>
                      <FormLabel>Investor name</FormLabel>
                      <TextField
                        sx={{ mt: 1.5 }}
                        fullWidth
                        size="small"
                        autoComplete="off"
                        type="text"
                        // placeholder="Enter Investor Name"
                        {...getFieldProps('investor_name')}
                        error={Boolean(touched.investor_name && errors.investor_name)}
                        helperText={touched.investor_name && errors.investor_name}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xs={12} sx={{ pl: 0.5 }}>
                      <FormLabel>Email</FormLabel>
                      <TextField
                        sx={{ mt: 1.5 }}
                        fullWidth
                        size="small"
                        autoComplete="email"
                        type="email"
                        // placeholder="Enter Email address"
                        {...getFieldProps('investor_email_id')}
                        error={Boolean(touched.investor_email_id && errors.investor_email_id)}
                        helperText={touched.investor_email_id && errors.investor_email_id}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xs={12}>
                      <FormLabel>Phone Number</FormLabel>
                      <TextField
                        sx={{ mt: 1.5 }}
                        fullWidth
                        size="small"
                        autoComplete="Phone number"
                        type="number"
                        // placeholder="Enter Phone Number"
                        {...getFieldProps('investor_phone_no')}
                        error={Boolean(touched.investor_phone_no && errors.investor_phone_no)}
                        helperText={touched.investor_phone_no && errors.investor_phone_no}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xs={12} sm={12}>
                      <FormLabel>
                        LEI
                        <LeiInfoButton />
                      </FormLabel>
                      <TextField
                        maxLength={50}
                        sx={{ mt: 1.5 }}
                        fullWidth
                        size="small"
                        autoComplete="off"
                        type="text"
                        // placeholder="LEI"
                        {...getFieldProps('lei')}
                        error={Boolean(touched.lei && errors.lei)}
                        helperText={touched.lei && errors.lei}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xs={12} sm={12}>
                      <FormLabel>
                        Investor Primary XinFin Address
                        <XdcInfoButton />
                      </FormLabel>
                      <TextField
                        sx={{ mt: 1.5 }}
                        fullWidth
                        type="text"
                        size="small"
                        // placeholder="Primary XinFin Address"
                        {...getFieldProps('xinfin_address')}
                        error={Boolean(touched.xinfin_address && errors.xinfin_address)}
                        helperText={touched.xinfin_address && errors.xinfin_address}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xs={12} sm={12}>
                      <FormLabel>Investor Country Code</FormLabel>

                      <FormControl size="small" variant="outlined" fullWidth sx={{ mt: 1.5 }}>
                        <Select
                          // placeholder="Select Country"
                          inputProps={{ 'aria-label': 'Without label' }}
                          {...getFieldProps('investor_country_code')}
                          error={Boolean(
                            touched.investor_country_code && errors.investor_country_code
                          )}
                          helperText={touched.investor_country_code && errors.investor_country_code}
                        >
                          {countries &&
                            countries.map((country, index) => {
                              return (
                                <MenuItem key={country.id} value={country.id}>
                                  {country.country_name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                        <FormHelperText sx={{ color: '#d32f2f' }}>
                          {touched.investor_country_code && errors.investor_country_code}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item lg={6} md={6} xs={12}>
                      <FormLabel>Address Line 1</FormLabel>
                      <TextField
                        sx={{ mt: 1.5 }}
                        fullWidth
                        size="small"
                        autoComplete="phone number"
                        type="text"
                        // placeholder="Home / Street"
                        {...getFieldProps('address_line_1')}
                        error={Boolean(touched.address_line_1 && errors.address_line_1)}
                        helperText={touched.address_line_1 && errors.address_line_1}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xs={12}>
                      <FormLabel>Address Line 2</FormLabel>
                      <TextField
                        sx={{ mt: 1.5 }}
                        fullWidth
                        size="small"
                        autoComplete="Landmark , etc."
                        type="text"
                        // placeholder="Home / Street"
                        {...getFieldProps('address_line_2')}
                        error={Boolean(touched.address_line_2 && errors.address_line_2)}
                        helperText={touched.address_line_2 && errors.address_line_2}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xs={12}>
                      <FormLabel>City</FormLabel>

                      <TextField
                        sx={{ mt: 1.5 }}
                        size="small"
                        fullWidth
                        type="text"
                        // placeholder="City"
                        {...getFieldProps('city')}
                        error={Boolean(touched.city && errors.city)}
                        helperText={touched.city && errors.city}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xs={12}>
                      <FormLabel>State / Province / Region</FormLabel>
                      <TextField
                        sx={{ mt: 1.5 }}
                        fullWidth
                        size="small"
                        type="text"
                        // placeholder="Enter your State / Province / Region"
                        {...getFieldProps('state')}
                        error={Boolean(touched.state && errors.state)}
                        helperText={touched.state && errors.state}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xs={12}>
                      <FormLabel>ZipCode</FormLabel>
                      <TextField
                        sx={{ mt: 1.5 }}
                        fullWidth
                        type="text"
                        size="small"
                        // placeholder="Your zip code"
                        {...getFieldProps('zip_code')}
                        error={Boolean(touched.zip_code && errors.zip_code)}
                        helperText={touched.zip_code && errors.zip_code}
                      />
                    </Grid>
                  </Grid>

                  <Box
                    sx={{
                      mt: 4,
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-end'
                    }}
                  >
                    <Button
                      sx={{
                        margin: 1,
                        borderColor: '#24ABDF',
                        px: 3,
                        width: '7.75rem',
                        height: '2.5rem'
                      }}
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        navigate('/masterdata/investor');
                      }}
                    >
                      Cancel
                    </Button>
                    <LoadingButton
                      type="submit"
                      disabled={!onlyIssuer(role)}
                      variant="gradient"
                      fullWidth
                      size="large"
                      loadingPosition="start"
                      loading={isSubmitting}
                      sx={{ margin: 1, px: 3, width: '7.75rem', height: '2.5rem' }}
                    >
                      Save
                    </LoadingButton>
                  </Box>
                </CardContent>
              </Form>
            </FormikProvider>
          </Container>
        </Card>
      </Container>
    </Page>
  );
};

export default AddInvestor;
