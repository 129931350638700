import React, { useEffect, useState, useMemo } from 'react';
import EnhancedTable from 'components/tables/EnhancedTable';
import {
  Box,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  Alert,
  AlertTitle
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { ethToXdcAddress, xdcToEthAddress } from 'helpers/web3';
import Delete from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
  useBusinessEntityState,
  useWeb3,
  useHttpApi,
  useAuthStateShared,
  useAppState
} from 'state';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { titleCase } from 'helpers/text';
import {
  ISSUER_OPERATOR,
  CUSTODIAN_OPERATOR,
  KYC_PROVIDER_OPERATOR,
  useIsPrimaryUser
} from 'helpers/rbac';
import CopyAddress from 'components/CopyAddress';

const ActionButtons = ({ userDetail }) => {
  console.log('Check', userDetail);
  const { removeIssuerAccounts, removeCustodianAccounts, removeKycProviderAccounts } = useWeb3();
  const { removeUser } = useHttpApi();
  const { enqueueSnackbar } = useSnackbar();
  const { fetchUser } = useBusinessEntityState();
  const { throwErrorMessage } = useAppState();
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const [loadingState, setLoadingState] = useState(false);
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          m: 0,
          p: 0,
          alignItems: 'center'
        }}
      >
        {/* <IconButton aria-label="subs detail" onClick={() => {}}>
          <EditIcon sx={{ fontSize: '2rem', m: 0, p: 1, backgroundColor: '#F5F8FA' }} />
        </IconButton> */}

        <IconButton aria-label="subs detail" onClick={handleOpen}>
          <Delete sx={{ fontSize: '2rem', p: 1, backgroundColor: '#F5F8FA' }} />
        </IconButton>
      </Box>
      <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title">
        <Alert severity="warning">
          <AlertTitle>Delete User</AlertTitle>
          <DialogContent>Are you sure you want to delete User, {userDetail.name} ?</DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleClose}
              sx={{ width: '6.7rem' }}
            >
              Cancel
            </Button>
            <LoadingButton
              variant="outlined"
              color="error"
              loadingPosition="start"
              sx={{ width: '6.7rem' }}
              loading={loadingState}
              onClick={async () => {
                console.log('userDetail', userDetail);
                console.log('usertype', userDetail.xinfin_address.role);
                try {
                  if (userDetail.xinfin_address.role === ISSUER_OPERATOR) {
                    await removeIssuerAccounts(
                      userDetail.parent_xinfin_account.xinfin_account_address,
                      [userDetail.xinfin_address.xinfin_account_address]
                    );
                  }
                  if (userDetail.xinfin_address.role === CUSTODIAN_OPERATOR) {
                    await removeCustodianAccounts(
                      userDetail.parent_xinfin_account.xinfin_account_address,
                      [userDetail.xinfin_address.xinfin_account_address]
                    );
                  }
                  if (userDetail.xinfin_address.role === KYC_PROVIDER_OPERATOR) {
                    await removeKycProviderAccounts(
                      userDetail.parent_xinfin_account.xinfin_account_address,
                      [userDetail.xinfin_address.xinfin_account_address]
                    );
                  }
                  if (
                    userDetail.xinfin_address.role === ISSUER_OPERATOR ||
                    userDetail.xinfin_address.role === CUSTODIAN_OPERATOR ||
                    userDetail.xinfin_address.role === KYC_PROVIDER_OPERATOR
                  ) {
                    const res = await removeUser(userDetail.id);
                    if (res) {
                      enqueueSnackbar('User deleted successfully', { variant: 'success' });
                    }
                    fetchUser();
                  }
                  if (
                    userDetail.xinfin_address.role !== ISSUER_OPERATOR &&
                    userDetail.xinfin_address.role !== CUSTODIAN_OPERATOR &&
                    userDetail.xinfin_address.role !== KYC_PROVIDER_OPERATOR
                  ) {
                    enqueueSnackbar(
                      "User can't be deleted, Please Delete the Business Entity to delete the user.",
                      { variant: 'error' }
                    );
                  }
                } catch (e) {
                  throwErrorMessage(e);
                }
                setLoadingState(false);
                handleClose();
              }}
            >
              Delete
            </LoadingButton>
          </DialogActions>
        </Alert>
      </Dialog>
    </>
  );
};

export default function UserTable() {
  const { users, fetchUser } = useBusinessEntityState();
  console.log(users);
  // const [data, setData] = useState(users);
  // const [skipPageReset, setSkipPageReset] = React.useState(false);
  // const [user, setUser] = useState(false);
  // const { role } = useAuthStateShared();

  const isPrimaryUser = useIsPrimaryUser();

  const headCells = useMemo(
    () => [
      {
        accessor: 'name',
        Header: 'Name',
        show: true
      },
      {
        accessor: 'username',
        Header: 'Username',
        show: true
      },

      {
        accessor: 'email',
        Header: 'Email',
        show: true
      },
      {
        accessor: 'xinfin_account_address',
        Header: 'Xinfin Address',
        width: 300,
        Cell: ({ row: { original } }) => (
          <CopyAddress addr={ethToXdcAddress(original.xinfin_address.xinfin_account_address)} />
        ),
        show: true
      },
      {
        accessor: 'role',
        Header: 'Type',
        Cell: ({ row: { original } }) => titleCase(original.xinfin_address.role),
        show: true
      },

      {
        accessor: 'actions',
        Header: 'Actions',
        Cell: ({ row: { original } }) => <ActionButtons userDetail={original} />,
        show: isPrimaryUser
      }
    ],
    [isPrimaryUser]
  );

  // useEffect(() => {
  //   fetchUser();
  // }, []);

  // useEffect(() => {
  //   if (
  //     role === 'OWNER' ||
  //     role === 'PRIMARY_ISSUER' ||
  //     role === 'PRIMARY_KYC_PROVIDER' ||
  //     role === 'PRIMARY_CUSTODIAN'
  //   ) {
  //     setUser(true);
  //   }
  // }, [role]);

  // useEffect(() => {
  //   const _users = users.map((user) => {
  //     user.xinfin = (
  //       <CopyAddress addr={ethToXdcAddress(user.xinfin_address.xinfin_account_address)} />
  //     );

  //     user.actions = <ActionButtons userDetail={user} />;
  //     user.role = titleCase(user.xinfin_address.role);
  //     return user;
  //   });
  //   setData(_users);
  // }, [users]);
  // We need to keep the table from resetting the pageIndex when we
  // Update data. So we can keep track of that flag with a ref.

  // When our cell renderer calls updateMyData, we'll use
  // the rowIndex, columnId and new value to update the
  // original data
  // const updateMyData = (rowIndex, columnId, value) => {
  //   // We also turn on the flag to not reset the page
  //   setSkipPageReset(true);
  //   setData((old) =>
  //     old.map((row, index) => {
  //       if (index === rowIndex) {
  //         return {
  //           ...old[rowIndex],
  //           [columnId]: value
  //         };
  //       }
  //       return row;
  //     })
  //   );
  // };

  return (
    <>
      <EnhancedTable
        refreshFunction={fetchUser}
        columns={headCells}
        data={users}
        // setData={setData}
        // updateMyData={updateMyData}
        // skipPageReset={skipPageReset}
      />
    </>
  );
}
