import React, { useEffect, useState } from 'react';
import EnhancedTable from 'components/tables/EnhancedTable';
import { Box, IconButton, Button, Typography } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { ethToXdcAddress } from 'helpers/web3';
import { HeaderName } from 'helpers/tableHelper';
import {
  useAppState,
  useAuthStateShared,
  useSubscriptionState,
  useWeb3,
  useBusinessEntityState,
  useHttpApi
} from 'state';
import rows from '../../mocks/Investor.json';
import { useSnackbar } from 'notistack';
import LoadingActionButton from 'helpers/LoadingActionButton';
import NAIcon from 'components/NAIcon';

const ActionButtons = (row) => {
  console.log('🚀 ~ file: InvestorViewAllotment.js ~ line 13 ~ ActionButtons ~ row', row);
  const { enqueueSnackbar } = useSnackbar();
  const { account, tvtRedeem, poolRedeemTvt, web3 } = useWeb3();
  const { throwErrorMessage } = useAppState();
  const { role } = useAuthStateShared();
  const redeemValue = row.row.is_redeem_requested;
  const [buttonDisable, setButtonDisable] = useState(redeemValue);
  const { investorRedemptionEscrow } = useBusinessEntityState();
  useEffect(() => {
    setButtonDisable(row.row.is_redeem_requested);
  }, [row]);
  const { updateAllotmentRedeemRequestStatus } = useHttpApi();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        m: 0,
        p: 0,
        alignItems: 'center'
      }}
    >
      <LoadingActionButton
        sx={{ mr: 1 }}
        variant="contained"
        color="primary"
        loadingPosition="start"
        disabled={buttonDisable}
        onClick={async () => {
          try {
            if (!row.row.allowed_events.includes('REDEEM')) {
              throw new Error('Required Documents Not Uploaded');
            }
            if (row.row.matured_balance === 0) {
              throw new Error('No matured balance');
            }
            setButtonDisable(true);
            console.log('buttondisable inside ', buttonDisable);
            if (role === 'LIQUIDITY_POOL_OWNER') {
              console.log(
                row.poolAddr,
                row.row.token_subscription_id.token_id.deployment_address,
                row.row.alloted_qty
              );
              await poolRedeemTvt(
                row.poolAddr,
                row.row.token_subscription_id.token_id.deployment_address,
                row.row.alloted_qty
              );
            } else {
              if(row.row.token_subscription_id.token_id.lockin_till>=new Date().toJSON().slice(0, 10)){
                throw Error("Lock-in period is not completed")
              }
              // console.log("check rwo data",row.row.token_subscription_id.token_id.lockin_till)
              const res = await tvtRedeem(
                row.row.token_subscription_id.token_id.deployment_address,
                account,
                row.row.alloted_qty,
                row.row.order_id

              );
              
              const data = {
                token_address: row.row.token_subscription_id.token_id.deployment_address,
                investor_address: account,
                alloted_qty: row.row.alloted_qty
              };
              if (res) {
                const result = await updateAllotmentRedeemRequestStatus(row.row.id, data);
              }
            }
            enqueueSnackbar('Token is submitted to Redeem', { variant: 'info' });
          } catch (e) {
            console.log(e);
            console.log("in error section")
            throwErrorMessage(e);
            setButtonDisable(false);
          }
        }}
      >
        Redeem
      </LoadingActionButton>
      {/* Add token to wallet */}
      {/* <Button
        variant="contained"
        color="secondary"
        sx={{ mr: 1 }}
        onClick={() => {
          const provider = web3.currentProvider;
          provider.sendAsync(
            {
              method: 'metamask_watchAsset',
              params: {
                type: 'ERC20',
                options: {
                  address: row.row.token_subscription_id.token_id.deployment_address,
                  symbol: row.row.token_subscription_id.token_id.token_symbol,
                  decimals: 0
                }
              },
              id: Math.round(Math.random() * 100000)
            },
            (err, added) => {
              console.log('provider returned', err, added);
              if (err || 'error' in added) {
                enqueueSnackbar('Something went wrong!', { variant: 'error' });
              } else if (added.result) {
                enqueueSnackbar('Token added to wallet!', { variant: 'success' });
              }
            }
          );
        }}
      >
        Add to wallet
      </Button> */}
    </Box>
  );
};

export default function InvestorViewAllotment({ poolAddr }) {
  const [data, setData] = useState(rows);

  const [skipPageReset, setSkipPageReset] = React.useState(false);
  const [owner, setOwner] = useState(false);
  const { role } = useAuthStateShared();
  const { account, active, matureBalanceOf } = useWeb3();
  const [loading, setLoading] = useState(false);
  const { fetchAllotmentsByXinfinAddress, allotments } = useSubscriptionState();
  console.log('allotments', allotments);
  const fetchAccount = poolAddr || account;

  useEffect(() => {
    if (role === 'OWNER') {
      setOwner(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _fetchData = async () => {
    await fetchAllotmentsByXinfinAddress(fetchAccount);
  };

  useEffect(() => {
    _fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [poolAddr, account]);

  const headCells = [
    {
      accessor: 'token_subscription_id.token_id.token_symbol',
      Header: 'Token Symbol',
      width: 70,
      show: true
    },

    {
      accessor: 'alloted_qty',
      Header: 'Token Alloted',
      width: 50,
      show: true
    },

    {
      accessor: 'token_subscription_id.token_id.token_value',
      Header: 'Token Value',
      width: 60,
      show: true
    },
    {
      accessor: 'allotment_date',
      Header: 'Allotment Date',
      width: 90,
      show: true
    },
    {
      accessor: 'token_subscription_id.token_id.lockin_till',
      Header: 'Lock In Till Date',
      width: 90,
      show: true
    },
    {
      accessor: 'accrued_interest',
      Header: 'Accrued Interest',
      Cell: (c) => {
        // if (c.row.original.token_cal_type === 'INTEREST') {
        //   return Number(c.value).toFixed(2);
        // } else {
        //   return <NAIcon />;
        // }
        return Number(c.value).toFixed(2);
      },
      width: 70,
      show: true
    },
    {
      accessor: 'token_nav',
      Header: 'Token NAV',
      width: 90,
      show: true
    },
    {
      accessor: 'allotment_maturity_date',
      Header: 'Maturity Date',
      width: 90,
      show: true
    },
    {
      accessor: 'action_button',
      Header: 'Actions',
      width: 130,
      show: true
    }
  ];

  useEffect(() => {
    const _prepareRows = async () => {
      console.log('allotments in useffect', allotments);
      const _rows = await Promise.all(
        allotments.map(async (row) => {
          console.log('row', row);
          const maturedBalance = await matureBalanceOf(
            row.token_subscription_id.token_id.deployment_address,
            fetchAccount
          );
          row.matured_balance = Number(maturedBalance) >= row.alloted_qty ? row.alloted_qty : 0;
          console.log('requested status', row, row.is_redeem_requested);
          row.action_button = <ActionButtons row={row} poolAddr={poolAddr} />;
          return row;
        })
      );
      setData(_rows);
    };
    _prepareRows();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allotments]);
  const updateMyData = (rowIndex, columnId, value) => {
    setSkipPageReset(true);
    setData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value
          };
        }
        return row;
      })
    );
  };

  return (
    <>
      <EnhancedTable
        tableTitle="InvestorViewAllotment"
        columns={headCells}
        data={data}
        setData={setData}
        updateMyData={updateMyData}
        skipPageReset={skipPageReset}
        refreshFunction={_fetchData}
      />
    </>
  );
}
