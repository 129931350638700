import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { visuallyHidden } from '@mui/utils';
import rows from '../../../mocks/BankAccount.json';
import Delete from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'bank_name',
    numeric: false,
    disablePadding: false,
    label: 'Bank Name'
  },
  {
    id: 'account_no',
    numeric: true,
    disablePadding: false,
    label: 'Account No'
  },
  {
    id: 'bic_code',
    numeric: false,
    disablePadding: true,
    label: 'BIC Code'
  },
  {
    id: 'iban_code',
    numeric: false,
    disablePadding: false,
    label: 'IBAN CODE'
  },
  {
    id: 'address',
    numeric: false,
    disablePadding: false,
    label: 'Address'
  },
  {
    id: 'account_currency',
    numeric: true,
    disablePadding: true,
    label: 'Account Currency'
  },
  {
    id: 'account_type',
    numeric: true,
    disablePadding: false,
    label: 'Account Type'
  },
  {
    id: 'beneficiary_type',
    numeric: true,
    disablePadding: false,
    label: 'Beneficiary Type'
  },
  {
    id: 'Actions',
    numeric: true,
    disablePadding: true,
    label: 'Actions'
  }
];

function BankAccountTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'center' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

BankAccountTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired
};

export default function BankAccountTable() {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('name');
  const [selected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Box sx={{ width: '100%', boxShadow: 0 }}>
      {selected.length > 0 && (
        <Typography variant="h6" gutterBottom>
          {selected.length} Selected
        </Typography>
      )}
      <TableContainer sx={{ boxShadow: 0 }}>
        <Table
          sx={{ minWidth: 750, boxShadow: 0 }}
          aria-labelledby="tableTitle"
          size={dense ? 'small' : 'medium'}
        >
          <BankAccountTableHead
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
          />
          <TableBody>
            {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
            {stableSort(rows, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow hover tabIndex={-1} key={row.bank_name}>
                    <TableCell
                      component="th"
                      align="left"
                      id={labelId}
                      scope="row"
                      padding="normal"
                    >
                      <Box>
                        <Typography variant="body2" sx={{ ml: 2 }}>
                          {row.bank_name}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell align="center">{row.account_no}</TableCell>
                    <TableCell align="left" padding="none">
                      {row.bic_code}
                    </TableCell>
                    <TableCell align="left">{row.iban_code}</TableCell>

                    <TableCell align="left">{row.address}</TableCell>
                    <TableCell align="left" padding="none">
                      {row.account_currency}
                    </TableCell>
                    <TableCell align="left">{row.account_type}</TableCell>
                    <TableCell align="left">{row.beneficiary_type}</TableCell>

                    <TableCell
                      align="center"
                      sx={{
                        display: 'flex'
                      }}
                    >
                      <IconButton aria-label="subs detail" onClick={() => {}}>
                        <EditIcon sx={{ fontSize: '2.188rem', p: 1, backgroundColor: '#F5F8FA' }} />
                      </IconButton>
                      <IconButton aria-label="subs detail" onClick={() => {}}>
                        <Delete sx={{ fontSize: '2.188rem', p: 1, backgroundColor: '#F5F8FA' }} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: (dense ? 33 : 53) * emptyRows
                }}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
    </Box>
  );
}
