import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  Typography,
  TableHead,
  Table,
  TableRow,
  TableCell,
  TableBody
} from '@mui/material';
import Breadcrumbs from 'components/Breadcrumbs';
import React, { useEffect } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Page from 'components/Page';
import { useBusinessEntityState, useAuthStateShared } from 'state';
import NavCalculation from 'components/masterData/LiquidityPool/NavCalculation';
import NavHistory from 'components/masterData/LiquidityPool/NavHistory';
import { currentNetwork, ethToXdcAddress } from 'helpers/web3';

const PoolPerfomanaceDashboard = () => {
  const { fetchTokenNavHistoryPool, fetchPoolToken } = useBusinessEntityState();
  const { entity } = useAuthStateShared();

  //   const { loggedInPool } = useBusinessEntityState();

  const poolAddr =
    entity?.liquidity_pool_xinfin_address && ethToXdcAddress(entity?.liquidity_pool_xinfin_address);
  useEffect(() => {
    fetchTokenNavHistoryPool(entity?.liquidity_pool_xinfin_address);
    fetchPoolToken(entity?.liquidity_pool_xinfin_address);
  }, []);

  return (
    <Page title="Performance Dashboard">
      <Container>
        <Breadcrumbs pageHead="Performance Dashboard" />
        <Accordion
          defaultExpanded
          sx={{
            boxShadow: 'none',
            border: '1px solid #D2D2D2',
            borderRadius: '6px',
            px: 4,
            py: 1,
            mt: '29px'
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography
              sx={{
                fontSize: '1.125rem',
                fontWeight: 'bold'
              }}
            >
              Pool Portfolio
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <NavCalculation poolAddr={entity?.liquidity_pool_xinfin_address} />
          </AccordionDetails>
        </Accordion>

        {/* NAV History */}
        <Accordion
          defaultExpanded
          sx={{
            boxShadow: 'none',
            border: '1px solid #D2D2D2',
            borderRadius: '6px',
            px: 4,
            py: 1,
            mt: '29px'
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography
              sx={{
                fontSize: '1.125rem',
                fontWeight: 'bold'
              }}
            >
              NAV History
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <NavHistory />
          </AccordionDetails>
        </Accordion>

        {/* Pool APY */}
        {/* <Accordion
          defaultExpanded
          sx={{
            boxShadow: 'none',
            border: '1px solid #D2D2D2',
            borderRadius: '6px',
            px: 4,
            py: 1,
            mt: '29px'
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography
              sx={{
                fontSize: '1.125rem',
                fontWeight: 'bold'
              }}
            >
              Pool APY
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus
              ex, sit amet blandit leo lobortis eget.
            </Typography>
          </AccordionDetails>
        </Accordion> */}
      </Container>
    </Page>
  );
};

export default PoolPerfomanaceDashboard;
