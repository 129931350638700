import * as React from 'react';
import Modal from '@mui/material/Modal';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { Typography, IconButton, Box, TextField, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import BankWireModal from './BankWireModal';
import { useSnackbar } from 'notistack';
import { useNavigate, useLocation } from 'react-router';

const PaymentModal = ({ open, handleClose }) => {
  const [paymentMethod, setPaymentMethod] = React.useState(''); // State to manage selected payment method
  const [showBankWireModal, setShowBankWireModal] = React.useState(false); // State to control bank wire modal
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const location = useLocation();
  const handlePaymentChange = (event) => {
    const selectedMethod = event.target.value;
    setPaymentMethod(selectedMethod);
  };

  const handleProceedCard = () => {
    // Handle adding card logic here
    if (!paymentMethod) {
      // If no payment method is selected, display an error message
      enqueueSnackbar('Please select a payment method!', { variant: 'error' });
      return;
    }
    console.log('Adding card details:', paymentMethod);
    // You can implement the logic to save the card details
    if (paymentMethod === 'bank_wire') {
      setShowBankWireModal(true); // Show bank wire modal on selecting bank wire option
    } else {
      console.log('handled close on click of proceed  - -- - - -')
      handleClose(); // Close the main modal after saving the details
    }
  };

  const handleBankWireModalClose = () => {
    setShowBankWireModal(false);
    handleClose();
    if (location.pathname.includes('subscription')) {
      navigate('/subscription/investor-dashboard');
    }
  }; // Close bank wire modal

  const radioStyles = {
    border: '1px solid #ccc',
    borderRadius: '10px',
    mt: 2,
    ml: 1
  };

  return (
    <>
      {/* Main Payment Modal */}
      <Modal
        BackdropProps={{
          timeout: 500,
          onClick: (e) => e.stopPropagation() // This prevents closing the modal by clicking on the backdrop
        }}
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableEscapeKeyDown
        disableEnforceFocus
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            width: 400,
            p: 4,
            boxShadow: 24,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            borderRadius: '10px'
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Select Payment Method
          </Typography>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{ position: 'absolute', right: 8, top: 8, color: 'grey.500' }}
          >
            <CloseIcon />
          </IconButton>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <FormControl
              defaultValue="debit_card"
              component="fieldset"
              sx={{
                mt: 2,
                width: '100%'
              }}
            >
              <RadioGroup
                aria-label="payment-method"
                name="payment-method"
                value={paymentMethod}
                defaultValue="debit_card"
                onChange={handlePaymentChange}
              >
                <FormControlLabel
                  sx={radioStyles}
                  value="bank_wire"
                  control={<Radio />}
                  label="Bank Wire"
                />
                <FormControlLabel
                  sx={radioStyles}
                  value="other_payment_options"
                  control={<Radio />}
                  label="Other Payment Options"
                />
                {/* <FormControlLabel
                  sx={radioStyles}
                  defaultChecked
                  value="debit_card"
                  control={<Radio />}
                  label="Debit Card"
                /> */}
              </RadioGroup>
            </FormControl>
          </Box>

          {paymentMethod === 'debit_card' && (
            <Box sx={{ width: '100%', mt: 2 }}>
              <TextField
                label="Name on Card"
                sx={{
                  mt: 2
                }}
                variant="outlined"
                fullWidth
              />
              <TextField
                label="Debit Card Number"
                sx={{
                  mt: 2
                }}
                variant="outlined"
                fullWidth
              />
              <TextField
                label="CVC"
                sx={{
                  mt: 2
                }}
                variant="outlined"
                fullWidth
              />
              <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
                <TextField label="Expiry Month/Year" variant="outlined" />
                <TextField label="ZIP/Postal Code" variant="outlined" />
              </Box>
            </Box>
          )}

          <Box sx={{ mt: 3 }}>
            <Button variant="contained" onClick={handleProceedCard}>
              Proceed
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* Bank Wire Modal */}
      <BankWireModal open={showBankWireModal} handleClose={handleBankWireModalClose} />
    </>
  );
};

export default PaymentModal;
