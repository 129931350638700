import { Link as RouterLink, Outlet } from 'react-router-dom';

import { experimentalStyled as styled } from '@mui/material';

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  left: 0,
  lineHeight: 0,
  width: '100%',
  position: 'absolute',
  padding: theme.spacing(3, 3, 0),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(5, 5, 0)
  }
}));

const LogoOnlyLayout = () => {
  return (
    <>
      <HeaderStyle>
        <RouterLink to="/" />
      </HeaderStyle>
      <Outlet />
    </>
  );
};

export default LogoOnlyLayout;
