import React, { useEffect, useMemo, useState } from 'react';
import EnhancedTable from 'components/tables/EnhancedTable';
import { Box, IconButton, Button, Typography, Chip } from '@mui/material';
import { useBusinessEntityState } from 'state/useBusinessEntityState';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { ethToXdcAddress } from 'helpers/web3';
import { HeaderName } from 'helpers/tableHelper';
import { useAuthStateShared, useWeb3, useAppState } from 'state';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import rows from '../../mocks/Investor.json';
import { useSnackbar } from 'notistack';
import CopyAddress from 'components/CopyAddress';
import DataNotAvailable from 'helpers/DataNotAvailable';
import NAIcon from 'components/NAIcon';

const ActionButtons = ({ row }) => {
  console.log('row');
  const { swapIssuance, cancelIssuance } = useWeb3();
  const { throwErrorMessage } = useAppState();
  const { currentToken: token } = useBusinessEntityState();
  const { enqueueSnackbar } = useSnackbar();
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          m: 0,
          p: 0,
          alignItems: 'center'
        }}
      >
        {/* <Button
          variant="contained"
          color="success"
          sx={{ mb: 1, mr: 1 }}
          onClick={() => {
            // console.log(issuerId);
          }}
        >
          <Typography sx={{ fontSize: '12px' }}>Notify Investor</Typography>
        </Button> */}
        <Button
          // disabled={token.token_status_id.token_status === 'BURNT'}
          variant="gradient"
          color="secondary"
          sx={{ ml: 1, width: '80px' }}
        >
          <Typography
            sx={{ fontSize: '12px' }}
            onClick={async () => {
              try {
                await swapIssuance(row?.escrow_order_id);
                enqueueSnackbar('Swap Issuance Started!', { variant: 'info' });
              } catch (e) {
                throwErrorMessage(e);
              }
            }}
          >
            Issue
          </Typography>
        </Button>
        {/* <Button variant="gradient" color="success" sx={{ mb: 1, ml: 1 }}>
          <Typography sx={{ fontSize: '12px' }}>Escrow Trade Token</Typography>
        </Button> */}
        {!row?.subcriber_status?.props?.status && (
          <Button variant="contained" color="secondary" sx={{ ml: 1 }}>
            <Typography
              sx={{ fontSize: '12px' }}
              onClick={async () => {
                try {
                  await cancelIssuance(row?.escrow_order_id);
                } catch (e) {
                  throwErrorMessage(e);
                }
              }}
            >
              Reject
            </Typography>
          </Button>
        )}
      </Box>
    </>
  );
};

const IssuerEscrowStatus = ({ status }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      {status ? <CheckCircleIcon color="success" /> : <CancelIcon color="error" />}
    </Box>
  );
};

const SubcriberEscrowStatus = ({ status }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      {status ? <CheckCircleIcon color="success" /> : <CancelIcon color="error" />}
    </Box>
  );
};

const CollateralStatus = ({ status }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      {status ? <CheckCircleIcon color="success" /> : <CancelIcon color="error" />}
    </Box>
  );
};

export default function IssuanceEscrowTable() {
  const [data, setData] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const { getIssuanceEscrowCondition } = useWeb3();
  const [skipPageReset, setSkipPageReset] = React.useState(false);
  const [owner, setOwner] = useState(false);
  const { role } = useAuthStateShared();

  const { issuanceEscrowOrders, fetchIssuanceEscrowByToken } = useBusinessEntityState();

  const _fetchData = async () => {
    await fetchIssuanceEscrowByToken();
  };
  useEffect(() => {
    _fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // TODO: change accesor

  const headCells = [
    {
      accessor: 'investor.investor_name',
      Header: 'Investor Name',
      width: 100,
      show: true
    },
    {
      accessor: 'investor_xinfin_account',
      Header: 'Xinfin Address',
      width: 200,
      show: true,
      Cell: ({ value }) => <CopyAddress addr={ethToXdcAddress(value)} />
    },
    {
      accessor: 'trade_token_amount',
      Header: 'Qty',
      width: 100,
      show: true
    },
    // {
    //   accessor: 'issuer_collateral',
    //   Header: 'Collateral required',
    //   width: 100,
    //   show: true,
    //   Cell: ({ value }) => (value === 0 ? <NAIcon /> : <>{value}</>)
    // },
    {
      accessor: 'subcriber_status',
      Header: 'Investor Escrowed',
      width: 110,
      show: true
    },
    {
      accessor: 'issuer_status',
      Header: 'Issuer Escrowed',
      width: 100,
      show: true
    },
    // {
    //   accessor: 'collateral_status',
    //   Header: 'Collateral Status',
    //   width: 100,
    //   show: true
    // },
    // {
    //   accessor: 'insurer_collateral_status',
    //   Header: 'Insurer Collateral',
    //   width: 100,
    //   show: true
    // },
    {
      accessor: 'actions',
      Header: 'Actions',
      width: 200,
      show: true,
      Cell: ({ row: { original } }) => <ActionButtons row={original} />
    }
  ];

  useEffect(() => {
    // TODO: Fetch Investors
    const _prepareRows = async () => {
      if (issuanceEscrowOrders.length !== 0) {
        console.log({ issuanceEscrowOrders });
        const _rows = await Promise.all(
          issuanceEscrowOrders.map(async (row) => {
            // row.xinfin_address = (
            //   <CopyAddress addr={ethToXdcAddress(row.investor_xinfin_account)} />
            // );
            const condition = await getIssuanceEscrowCondition(row.escrow_order_id);
            console.log(condition);
            row.issuer_status = <IssuerEscrowStatus status={condition[0]} />;
            row.subcriber_status = <SubcriberEscrowStatus status={condition[2]} />;
            row.collateral_status =
              row.collateral_value === 0 ? <NAIcon /> : <CollateralStatus status={condition[1]} />;
            // row.actions = <ActionButtons row={row} />;
            row.insurer_collateral_status =
              row.insurer_collateral === 0 ? (
                <NAIcon />
              ) : (
                <CollateralStatus status={condition[3]} />
              );
            return row;
          })
        );
        setData(_rows);
        setIsLoaded(true);
      }
    };
    _prepareRows();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [issuanceEscrowOrders]);

  // const updateMyData = (rowIndex, columnId, value) => {
  //   setSkipPageReset(true);
  //   setData((old) =>
  //     old.map((row, index) => {
  //       if (index === rowIndex) {
  //         return {
  //           ...old[rowIndex],
  //           [columnId]: value
  //         };
  //       }
  //       return row;
  //     })
  //   );
  // };

  return (
    <>
      <EnhancedTable
        tableTitle="IssuanceEscrow"
        columns={headCells}
        data={issuanceEscrowOrders}
        setData={setData}
        // updateMyData={updateMyData}
        // skipPageReset={skipPageReset}
        refreshFunction={_fetchData}
      />
    </>
  );
}
