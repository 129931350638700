import Page from 'components/Page';
import React from 'react';
import { Card, Container, CardContent, Typography, Button, Box } from '@mui/material';
import Breadcrumbs from 'components/Breadcrumbs';
import PoolTable from 'components/subscritiption/PoolTable';
import { useCoreTableState, useWeb3 } from 'state';

import { parseTokenDecimal } from 'helpers/numbers';
import TVLChart from 'components/masterData/LiquidityPool/TVLChart';

const PoolHome = () => {
  const { publishPool } = useWeb3();
  const [loading, setLoading] = React.useState(false);
  const [res, setRes] = React.useState(null);
  const { orgnizations } = useCoreTableState();

  return (
    <Page title="Liquidity Pool">
      <Container>
        {/* <Breadcrumbs pageHead="Liquidity Pools" /> */}
        <Box
          sx={{
            mt: 2,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            alignItems: 'center'
          }}
        >
          <TVLChart />
        </Box>
        <Box sx={{ mt: 2 }}>
          <Typography variant="p">
            {orgnizations[0] ? `${orgnizations[0].name}` : 'Yodaplus'} Liquditiy Pool is an open
            DeFi protocol and marketplace for real-world asset pools. You can create your own pool
            by clicking
            <Button
              variant="text"
              onClick={async () => {
                try {
                  setLoading(true);
                  const res = await publishPool();
                  console.log(res);
                  setRes(res);
                  const element = document.createElement('a');
                  const file = new Blob(
                    [`Pool Deployed to: ${res.events.PoolCreated.returnValues.pool}`],
                    { type: 'text/plain;charset=utf-8' }
                  );
                  element.href = URL.createObjectURL(file);
                  element.download = `Pool - ${res.events.PoolCreated.returnValues.pool}.txt`;
                  document.body.appendChild(element);
                  element.click();
                  setLoading(false);
                } catch (e) {
                  console.log(e);
                }
              }}
            >
              here
            </Button>
          </Typography>
        </Box>
        {loading && (
          <Typography variant="p" sx={{ mt: 2 }}>
            Transaction Pending...
          </Typography>
        )}
        <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 2 }}>
          Liquidity Pools
        </Typography>
        <Card sx={{ mt: 2 }}>
          <CardContent>
            <PoolTable />
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
};

export default PoolHome;
