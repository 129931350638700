import { Grid, Typography, Container, Chip, Button, TextField } from '@mui/material';
import React from 'react';

const AllComponents = () => {
  return (
    <div>
      <Typography variant="h4">All Components</Typography>
      <hr />
      <Container>
        <Grid container maxWidth="sm" spacing={3}>
          <Grid item lg={12}>
            <Typography variant="h5">Indicators / Chips</Typography>
            <Chip label="primary" color="primary" sx={{ margin: 1 }} />
            <Chip label="secondary" color="secondary" sx={{ margin: 1 }} />
            <Chip label="info" color="info" sx={{ margin: 1 }} />
            <Chip label="error" color="error" sx={{ margin: 1 }} />
            <Chip label="default" color="default" sx={{ margin: 1 }} />
            <Chip label="success" color="success" sx={{ margin: 1 }} />
            <Chip label="warning" color="warning" sx={{ margin: 1 }} />
          </Grid>
        </Grid>
        <Grid container maxWidth="sm" spacing={3}>
          <Grid item lg={12}>
            <Typography variant="h5">Buttons</Typography>
            <Button sx={{ margin: 1 }} variant="text">
              Basic
            </Button>
            <Button sx={{ margin: 1 }} variant="contained">
              Basic
            </Button>
            <Button sx={{ margin: 1 }} variant="contained" color="secondary">
              Basic
            </Button>
            <Button sx={{ margin: 1 }} variant="contained" color="error">
              Basic
            </Button>
            <Button sx={{ margin: 1 }} variant="outlined">
              Basic
            </Button>
            <Button sx={{ margin: 1 }} variant="gradient">
              Basic
            </Button>
            <TextField type="file" />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default AllComponents;
