import React, { useEffect, useState } from 'react';
import EnhancedTable from 'components/tables/EnhancedTable';
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  Table,
  TableCell,
  Typography,
  TableRow,
  IconButton,
  styled,
  DialogActions,
  DialogContent,
  Divider,
  Chip
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { ethToXdcAddress } from 'helpers/web3';
import { HeaderName } from 'helpers/tableHelper';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { useAuthStateShared, useWeb3, useBusinessEntityState, useAppState } from 'state';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import AddressFieldTools from 'components/AddressFieldTools';
import NAIcon from 'components/NAIcon';
import { parseTokenDecimal } from 'helpers/numbers';
import PaymentModal from 'components/PaymentModal';

const ActionButtons = (row) => {
  const [openPaymentModal, setOpenPaymentModal] = useState(false);
  const handleOpenPaymentModal = () => setOpenPaymentModal(true);
  const handleClosePaymentModal = () => {
    setOpenPaymentModal(false);
  };

  console.log(
    '🚀 ~ file: InvestorIssuanceEscrowTable.js ~ line 33 ~ ActionButtons ~ poolAddr',
    row.poolAddr
  );
  const [open, setOpen] = React.useState(false);
  const { poolAllowanceOnPaymentToken, paymentTokenGiveAllowance, swapIssuance, web3, chainId } =
    useWeb3();
  const { enqueueSnackbar } = useSnackbar();
  const { throwErrorMessage } = useAppState();
  const { role } = useAuthStateShared();

  const [loadingState, setLoadingState] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          m: 0,
          p: 0,
          alignItems: 'center'
        }}
      >
        {row.row?.payment_token && (
          <Button variant="gradient" color="success" sx={{ mr: 1 }} onClick={handleClickOpen}>
            <Typography sx={{ fontSize: '12px' }}>Escrow</Typography>
          </Button>
        )}
        {!row.row?.payment_token && (
          <Button
            variant="gradient"
            color="success"
            // disabled
            sx={{ mr: 1 }}
            onClick={handleOpenPaymentModal}
          >
            <Typography sx={{ fontSize: '12px' }}>Initiate Payment</Typography>
          </Button>
        )}
        {row.row?.payment_token && (
          <Button variant="contained" color="secondary" sx={{ ml: 1 }}>
            <Typography
              sx={{ fontSize: '12px' }}
              onClick={async () => {
                try {
                  await swapIssuance(row.row.escrow_order_id);
                } catch (e) {
                  throwErrorMessage(e);
                }
              }}
            >
              Initiate TvT
            </Typography>
          </Button>
        )}
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Escrow Payment Token</DialogTitle>
        <DialogContent>
          <Table aria-label="simple table">
            <TableRow sx={{ p: 5 }}>
              <TableCell>
                <Typography sx={{ fontWeight: '600' }}>Escrow Requirement</Typography>
              </TableCell>
              <TableCell>
                <Typography>{row?.row?.payment_token_amount}</Typography>
              </TableCell>
            </TableRow>
            <TableRow sx={{ p: 5 }}>
              <TableCell>
                <Typography sx={{ fontWeight: '600' }}>Issue Price</Typography>
              </TableCell>
              <TableCell>
                <Typography>{row.row.token_issue_price}</Typography>
              </TableCell>
            </TableRow>
            <TableRow sx={{ p: 5 }}>
              <TableCell>
                <Typography sx={{ fontWeight: '600' }}>Alloted Qty.</Typography>
              </TableCell>
              <TableCell>
                <Typography>{row.row.trade_token_amount}</Typography>
              </TableCell>
            </TableRow>
            <TableRow sx={{ p: 5 }}>
              <TableCell>
                <Typography sx={{ fontWeight: '600' }}>Payment Token Name</Typography>
              </TableCell>
              <TableCell>
                <Typography>{row?.row?.payment_token?.contract_name}</Typography>
              </TableCell>
            </TableRow>
            <TableRow sx={{ p: 5 }}>
              <TableCell>
                <Typography sx={{ fontWeight: '600' }}>Payment Token Symbol</Typography>
              </TableCell>
              <TableCell>
                <Typography>{row?.row?.payment_token?.symbol}</Typography>
              </TableCell>
            </TableRow>
            <TableRow sx={{ p: 5 }}>
              <TableCell>
                <Typography sx={{ fontWeight: '600' }}>Payment Token Address</Typography>
              </TableCell>
              <TableCell>
                <AddressFieldTools
                  address={row?.row?.payment_token?.contract_address}
                  symbol={row?.row?.payment_token?.symbol}
                  decimals={row?.row?.payment_token?.decimals}
                  showAddToWallet
                  showCopyButton
                  showAddress={false}
                />
              </TableCell>
            </TableRow>
          </Table>
        </DialogContent>
        <DialogActions sx={{ mt: 2, mb: 2, mr: 1 }}>
          <Button autoFocus variant="contained" color="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <LoadingButton
            autoFocus
            variant="gradient"
            sx={{ width: '9rem' }}
            loadingPosition="start"
            loading={loadingState}
            onClick={async () => {
              setLoadingState(true);

              console.log(row?.row?.payment_token_amount);
              console.log(row?.row?.payment_token?.contract_address);
              try {
                const _paymentTokenAmount = parseTokenDecimal(
                  row?.row?.payment_token_amount,
                  row?.row?.payment_token?.decimals
                );
                if (role === 'LIQUIDITY_POOL_OWNER') {
                  console.log('check');
                  await poolAllowanceOnPaymentToken(
                    row.poolAddr,
                    row?.row?.payment_token?.contract_address,
                    _paymentTokenAmount
                  );
                } else {
                  await paymentTokenGiveAllowance(
                    row?.row?.payment_token?.contract_address,
                    _paymentTokenAmount
                  );
                }
              } catch (e) {
                throwErrorMessage(e);
              }
              setLoadingState(false);
              handleClose();
            }}
          >
            Submit
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <PaymentModal open={openPaymentModal} handleClose={handleClosePaymentModal} />
    </>
  );
};

const IssuerEscrowStatus = ({ status }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      {status ? <CheckCircleIcon color="success" /> : <CancelIcon color="error" />}
    </Box>
  );
};

const SubcriberEscrowStatus = ({ status }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      {status ? <CheckCircleIcon color="success" /> : <CancelIcon color="error" />}
    </Box>
  );
};

export default function InvestorIssuanceEscrowTable({ poolAddr }) {
  const [data, setData] = useState([]);
  const [skipPageReset, setSkipPageReset] = React.useState(false);
  const [owner, setOwner] = useState(false);
  const { role } = useAuthStateShared();
  const { account, active, getIssuanceEscrowCondition } = useWeb3();
  const {
    fetchAllIssuanceEscrowByInvestorAddress: issuanceEscrowByAddr,
    investorIssuanceEscrow: rows
  } = useBusinessEntityState();

  console.log({ account }, { active });

  const fetchAccount = poolAddr || account;

  useEffect(() => {
    if (role === 'OWNER') {
      setOwner(true);
    }
  }, [role]);

  // Fetch Issuance Escrow Data
  const _fetchData = async () => {
    await issuanceEscrowByAddr(fetchAccount);
  };
  useEffect(() => {
    _fetchData();
    // console.log({ rows });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [poolAddr, account]);

  const headCells = [
    {
      accessor: 'issuer_name',
      Header: 'Issuer Name',
      width: 100,
      show: true
    },
    {
      accessor: 'token_symbol',
      Header: 'Token Symbol',
      width: 100,
      show: true
    },
    // {
    //   accessor: 'qty_applied',
    //   Header: 'Applied Qty.',
    //   width: 100,
    //   show: true
    // },
    {
      accessor: 'trade_token_amount',
      Header: 'Alloted Qty.',
      width: 100,
      show: true
    },
    {
      accessor: 'payment_token',
      Header: 'Payment token',
      width: 110,
      show: true,
      Cell: ({ row: { original } }) => (original?.payment_token?.contract_name ? <>{original?.payment_token?.contract_name}</> : <>CASH</>)
    },
    {
      accessor: 'issuer_status',
      Header: 'Issuer Escrowed',
      width: 100,
      show: true
    },
    {
      accessor: 'subcriber_status',
      Header: 'Investor Escrowed',
      width: 110,
      show: true
    },
    // {
    //   accessor: 'collateral_status',
    //   Header: 'Collateral status',
    //   width: 110,
    //   show: true
    // },
    // {
    //   accessor: 'collateral_value',
    //   Header: 'Collateral Amount(XDC)',
    //   width: 110,
    //   show: true,
    //   Cell: ({ value }) => (value === 0 ? <NAIcon /> : <>{value}</>)
    // },
    {
      accessor: 'actions',
      Header: 'Actions',
      width: 350,
      show: true
    }
  ];

  useEffect(() => {
    // TODO: Fetch Investors
    const _prepareRow = async () => {
      const _rows = await Promise.all(
        rows.map(async (row) => {
          const condition = await getIssuanceEscrowCondition(row.escrow_order_id);
          console.log(condition);
          row.issuer_status = <IssuerEscrowStatus status={condition[0]} />;
          row.subcriber_status = <SubcriberEscrowStatus status={condition[2]} />;
          row.collateral_status =
            row.collateral_value === 0 ? (
              <NAIcon />
            ) : (
              <SubcriberEscrowStatus status={condition[1] && condition[3]} />
            );
          row.actions = <ActionButtons row={row} poolAddr={fetchAccount} />;
          return row;
        })
      );
      setData(_rows);
    };
    _prepareRow();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows]);
  const updateMyData = (rowIndex, columnId, value) => {
    setSkipPageReset(true);
    setData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value
          };
        }
        return row;
      })
    );
  };

  return (
    <>
      <EnhancedTable
        tableTitle="InvestorIssuanceEscrow"
        columns={headCells}
        data={data}
        setData={setData}
        updateMyData={updateMyData}
        skipPageReset={skipPageReset}
        refreshFunction={_fetchData}
      />
    </>
  );
}
