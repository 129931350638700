import { useEffect, useState, useRef, useCallback } from 'react';

import transactionHashPersistence from 'persistence/transactionHashPersistence';
import useStatePersistence from 'helpers/useStatePersistence';
import { useHttpApi } from 'state';

const TRANSACTION_STATUS = {
  INITIAL: 'INITIAL',
  READY: 'READY',
  WAITING_HASH: 'WAITING_HASH',
  WAITING_RECEIPT: 'WAITING_RECEIPT',
  WAITING_BACKEND_CONFIRMATION: 'WAITING_BACKEND_CONFIRMATION',
  RECEIPT_FAIL: 'RECEIPT_FAIL',
  DONE: 'DONE',
  ERROR: 'ERROR'
};

const EMPTY_REF = {};

const useEffectOnChange = (func, value, match, skipInitial = true) => {
  const prevValueRef = useRef(skipInitial ? value : EMPTY_REF);

  useEffect(() => {
    let returnValue;

    if (value !== prevValueRef.current && value === match) {
      returnValue = func();
    }

    prevValueRef.current = value;

    return returnValue;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);
};

const POLL_INTERVAL = 3000;

const TransactionHandler = ({ id, commitTransaction, children, done = false, onConfirmed }) => {
  const [status, setStatus] = useState(done ? TRANSACTION_STATUS.DONE : TRANSACTION_STATUS.INITIAL);
  const [hash, setHash] = useStatePersistence(transactionHashPersistence, id, null);
  const [receipt, setReceipt] = useState(null);
  const [error, setError] = useState(null);
  const eventEmitterRef = useRef(null);

  const { getTransactionAction } = useHttpApi();

  const detachEmitterListeners = useCallback(() => {
    if (eventEmitterRef.current) {
    }

    eventEmitterRef.current = null;
  }, []);

  const attachEmitterListeners = useCallback(
    (eventEmitter) => {
      detachEmitterListeners();
      eventEmitterRef.current = eventEmitter;

      eventEmitter.on('hash', (value) => {
        setStatus(TRANSACTION_STATUS.WAITING_RECEIPT);
        setHash(value);
      });

      eventEmitter.on('receipt_fail', (value) => {
        setStatus(TRANSACTION_STATUS.RECEIPT_FAIL);
        setReceipt(value);
      });

      eventEmitter.on('receipt_success', (value) => {
        setStatus(TRANSACTION_STATUS.WAITING_BACKEND_CONFIRMATION);
        setReceipt(value);
      });

      eventEmitter.on('error', (value) => {
        setStatus(TRANSACTION_STATUS.ERROR);
        setError(value);
      });

      eventEmitter.catch(() => {});
    },
    [detachEmitterListeners, setHash]
  );

  const queryTransactionAction = useCallback(async () => {
    try {
      const { transaction_hash: transactionHash, status, message } = await getTransactionAction(id);

      detachEmitterListeners();

      if (status) {
        setStatus(TRANSACTION_STATUS.DONE);
        setHash(transactionHash);
      } else {
        setStatus(TRANSACTION_STATUS.ERROR);
        setError(message);
      }
      return true;
    } catch {
      return false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const process = async () => {
      if (status !== TRANSACTION_STATUS.INITIAL) {
        return;
      }

      const queryStatus = await queryTransactionAction();

      if (queryStatus) {
        return;
      }

      if (hash !== null) {
        setStatus(TRANSACTION_STATUS.WAITING_RECEIPT);
        attachEmitterListeners(commitTransaction(hash));
      } else {
        setStatus(TRANSACTION_STATUS.READY);
      }
    };

    process();

    return () => {
      detachEmitterListeners();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pollEffectFunc = () => {
    let canceled = false;
    let timerId;

    const poll = async () => {
      const queryStatus = await queryTransactionAction();

      if (queryStatus) {
        canceled = true;
      }

      if (!canceled) {
        timerId = setTimeout(poll, POLL_INTERVAL);
      }
    };

    timerId = setTimeout(poll, POLL_INTERVAL);

    return () => {
      canceled = true;
      clearTimeout(timerId);
    };
  };

  useEffectOnChange(pollEffectFunc, status, TRANSACTION_STATUS.WAITING_BACKEND_CONFIRMATION);
  useEffectOnChange(pollEffectFunc, status, TRANSACTION_STATUS.READY);

  useEffectOnChange(
    () => {
      if (typeof onConfirmed === 'function') {
        onConfirmed();
      }
    },
    status,
    TRANSACTION_STATUS.DONE
  );

  const send = () => {
    if (status !== TRANSACTION_STATUS.READY && status !== TRANSACTION_STATUS.ERROR) {
      return;
    }

    setError(null);
    setStatus(TRANSACTION_STATUS.WAITING_RECEIPT);

    attachEmitterListeners(commitTransaction());
  };

  const cancel = () => {
    setError(null);
    setHash(null);
    setStatus(TRANSACTION_STATUS.READY);
  };

  return children({ status, error, receipt, send, cancel });
};

export default TransactionHandler;
