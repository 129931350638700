import React, { useEffect, useMemo, useState } from 'react';
import {
  Card,
  CardContent,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Table,
  TableRow,
  TableCell,
  Typography,
  DialogActions,
  TextField
} from '@mui/material';
import {
  useBusinessEntityState,
  useAuthStateShared,
  useHttpApi,
  useWeb3,
  useAppState
} from 'state';
import CreateAllotmentCustomTable from 'components/tables/CreateAllotmentCustomTable';
import LoadingActionButton from 'helpers/LoadingActionButton';
import { useNavigate } from 'react-router-dom';
import { useIssuer } from 'helpers/rbac';
import { useSnackbar } from 'notistack';
import CopyAddress from 'components/CopyAddress';
import { parseTokenDecimal } from 'helpers/numbers';
import { AllotmentAge } from 'helpers/tableHelper';
import { xdcToEthAddress } from 'helpers/web3';

const PendingPaymentTxn = () => {
  const { role } = useAuthStateShared();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { throwErrorMessage } = useAppState();
  const {
    allAllotments,
    fetchAllAllotments,
    setCurrentCreateAllotmentList,
    createAllotmentsList,
    currentToken
  } = useBusinessEntityState();
  const { transferPaymentToken, paymentTokenGiveAllowance, sendInterimPayment } = useWeb3();

  const { createPaymentTransaction } = useHttpApi();

  const paymentToken = currentToken.payment_token_id;

  // const [open, setOpen] = useState(false);
  // const handleClickOpen = () => {
  //   setOpen(true);
  // };
  // const handleClose = () => {
  //   setOpen(false);
  // };

  const [data, setData] = useState(allAllotments);
  const [qtyMap, setQtyMap] = useState({});
  // const [originalData] = React.useState(data);
  React.useEffect(() => {
    setSkipPageReset(false);
  }, [data]);
  const [skipPageReset, setSkipPageReset] = React.useState(false);

  // const resetData = () => setData(originalData);

  const updateMyData = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true);
    setData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value
          };
        }
        return row;
      })
    );
  };

  const EditableCell = ({
    value: initialValue,
    row: { index, original },
    column: { id },
    updateMyData // This is a custom function that we supplied to our table instance
  }) => {
    // We need to keep and update the state of the cell normally
    const [value, setValue] = React.useState(Number(original.accrued_interest).toFixed(2));

    const onChange = (e) => {
      const { name } = e.target;
      setQtyMap({
        ...qtyMap,
        [name]: Number(e.target.value).toFixed(2)
      });
    };

    // We'll only update the external data when the input is blurred
    const onBlur = () => {
      updateMyData(index, id, Number(value).toFixed(2));
    };

    // If the initialValue is changed external, sync it up with our state
    React.useEffect(() => {
      // const name = original.id;
      // setQtyMap({
      //   ...qtyMap,
      //   [name]: Number(original.accrued_interest).toFixed(2)
      // });
      setValue(Number(original.accrued_interest).toFixed(2));
    }, [original]);

    return (
      <TextField
        name={original.id}
        defaultValue={qtyMap[original.id] || ''}
        // defaultValue={qtyMap[original.id] || Number(initialValue).toFixed(2)}
        onChange={onChange}
        onBlur={onBlur}
        size="small"
      />
    );
  };

  const headCells = useMemo(
    () => [
      {
        accessor: 'token_subscription_id.investor_id.investor_name',
        Header: 'Name',
        show: true
      },
      {
        accessor: 'token_subscription_id.xinfin_setl_account.xinfin_account_address',
        Header: 'Xinfin Address',
        show: true,
        Cell: ({ value }) => <CopyAddress addr={value} />
      },
      {
        accessor: 'allotment_date',
        Header: 'Allotment Date',
        show: true
      },
      {
        accessor: 'alloted_qty',
        Header: 'Alloted Qty',
        show: true,
        width: 85
      },
      // {
      //   accessor: 'allotment_age',
      //   Header: 'Allotment Age',
      //   show: true,
      //   Cell: ({ value }) => AllotmentAge(value)
      // },
      {
        accessor: 'accrued_interest_age',
        Header: 'Accrual Period',
        show: true,
        Cell: ({ value }) => `${value} Days`
      },
      {
        accessor: 'accrued_interest',
        Header: 'Accrued Interest',
        show: true,
        Cell: ({ value }) => Number(value).toFixed(2)
      }
      // {
      //   accessor: 'accrued_interest_readonly',
      //   Header: 'Payment Amount',
      //   show: true,
      //   Cell: EditableCell
      // }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    fetchAllAllotments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Card sx={{ mt: 2 }}>
        <CardContent>
          <CreateAllotmentCustomTable
            columns={headCells}
            // data={allAllotments}
            refreshFunction={fetchAllAllotments}
            setSelectedRowsToState={setCurrentCreateAllotmentList}
            data={allAllotments}
            setData={setData}
            updateMyData={updateMyData}
            skipPageReset={skipPageReset}
            isRoleAllowed={['PRIMARY_ISSUER', 'ISSUER_OPERATOR'].includes(role)}
          />
        </CardContent>
      </Card>

      {/* <Card sx={{ mt: 4, p: 0 }}> */}
      {/* <CardContent
          sx={{
            p: '1 !important',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            flexDirection: 'row'
          }}
        >
          {/* <Button
            variant="contained"
            sx={{
              mx: 2,
              px: 3,
              fontWeight: 400
              // width: '7.75rem'
            }}
            color="primary"
            onClick={handleClickOpen}
          >
            Escrow Payment Token
          </Button> */}

      {/* Escrow Dialog Box */}
      {/* <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Escrow Payment Token</DialogTitle>
            <DialogContent>
              <Table aria-label="simple table">
                <TableRow sx={{ p: 5 }}>
                  <TableCell>
                    <Typography sx={{ fontWeight: '600' }}>Escrow Requirement</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{data?.accrued_interest}</Typography>
                  </TableCell>
                </TableRow>
              </Table>
            </DialogContent>
            <DialogActions sx={{ mt: 2, mb: 2, mr: 1 }}>
              <Button autoFocus variant="contained" color="secondary" onClick={handleClose}>
                Cancel
              </Button>
              <LoadingActionButton
                autoFocus
                variant="gradient"
                sx={{ width: '9rem' }}
                loadingPosition="start"
                onClick={async () => {
                  try {
                    const accruedInterest = parseTokenDecimal(
                      data.accrued_interest,
                      paymentToken.decimals
                    );
                    await paymentTokenGiveAllowance(paymentToken.contract_address, accruedInterest);
                  } catch (e) {
                    if (e.message) {
                      enqueueSnackbar(e.message, { variant: 'error' });
                    }
                  }
                  handleClose();
                }}
              >
                Submit
              </LoadingActionButton>
            </DialogActions>
          </Dialog> */}

      {/* Pay Accrued Interest */}
      {/* <LoadingActionButton
            loadingPosition="start"
            variant="gradient"
            disabled={!useIssuer()}
            sx={{
              mx: 2,
              px: 3,
              fontWeight: 500,
              width: '15rem'
            }}
            color="primary"
            onClick={async () => {
              try {
                const data = createAllotmentsList[0];
                data.accrued_interest_new = qtyMap[data.id];
                console.log(data);
                if (!data.accrued_interest_new || data.accrued_interest_new === '0.00') {
                  throw new Error('Enter Payment Amount', {
                    variant: 'error'
                  });
                }
                const accruedInterest = parseTokenDecimal(
                  data.accrued_interest_new,
                  paymentToken.decimals
                );
                // const res = await sendInterimPayment(
                //   paymentToken.contract_address,
                //   data.token_subscription_id.xinfin_setl_account.xinfin_account_address,
                //   accruedInterest
                // );

                const res = await transferPaymentToken(
                  paymentToken.contract_address,
                  data.token_subscription_id.xinfin_setl_account.xinfin_account_address,
                  accruedInterest
                );
                console.log({ res });
                if (res) {
                  const resTxn = await createPaymentTransaction({
                    transaction_hash: res,
                    transaction_amount: Number(data.accrued_interest_new).toFixed(2),
                    recipient_xinfin_address:
                      data.token_subscription_id.xinfin_setl_account.xinfin_account_address,
                    payment_token_id: paymentToken.payment_token_id,
                    token_id: currentToken.token_id,
                    token_allotment_id: data.id,
                    transaction_status: 'PENDING'
                  });
                  console.log({ resTxn });
                }
              } catch (e) {
                if (createAllotmentsList.length === 0) {
                  enqueueSnackbar('Please select atleast one allotment', { variant: 'error' });
                } else if (e?.message) {
                  enqueueSnackbar(e.message, { variant: 'error' });
                } else {
                  enqueueSnackbar('Something went wrong, Please check your Balance', {
                    variant: 'error'
                  });
                  // throwErrorMessage(e);
                }
              }
            }}
          >
            Initiate Payment
          </LoadingActionButton> */}
      {/* </CardContent> */}
      {/* </Card> */}
    </>
  );
};

export default PendingPaymentTxn;
