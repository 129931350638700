import { Box, Container, Typography, Button, Chip } from '@mui/material';
import React from 'react';
import poolLogo from 'assets/images/yplogo.png';
import { useBusinessEntityState, useWeb3, useSubscriptionState, useAppState } from 'state';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router';
import { format, isBefore } from 'date-fns';

// TODO: Receive Props
const PoolDetailsBasicDesc = () => {
  const { currentSubsToken } = useBusinessEntityState();
  const { getSubscriberDetails } = useSubscriptionState();
  const { account } = useWeb3();
  const { enqueueSnackbar } = useSnackbar();
  const { throwErrorMessage } = useAppState();
  const navigate = useNavigate();
  const today = new Date();
  // get today as per issue date format
  const todayDate = format(today, 'yyyy-MM-dd');
  return (
    <Container
      sx={{ mt: 4, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
    >
      <Box spacing={3} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <Box
          component="img"
          src={currentSubsToken.liquidity_pool.liquidity_pool_logo}
          sx={{
            height: '6.25rem',
            width: '6.25rem',
            borderRadius: '0.8rem',
            objectFit: 'contain'
            // background: `linear-gradient(to right, #25aae1 7%, #18bfc5 57%, #0dd1ae 97%)`
          }}
        />
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant="h6" sx={{ ml: 2, fontWeight: 'bold' }}>
            {currentSubsToken.liquidity_pool.liquidity_pool_name}
            <Chip
              label={`${currentSubsToken.issue_size} - ${currentSubsToken.payment_token_id.symbol}`}
              color="success"
              sx={{ ml: 2 }}
            />
          </Typography>
          <Typography variant="p" sx={{ ml: 2 }}>
            Overview
          </Typography>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <Button
          onClick={async () => {
            try {
              if (isBefore(new Date(currentSubsToken.final_maturity_date), new Date(todayDate))) {
                enqueueSnackbar('Cannot Subscribe After Issue Closer Date', {
                  variant: 'warning'
                });
              } else {
                navigate(`/subscription/form/${currentSubsToken.deployment_address}`);
              }
            } catch (e) {
              throwErrorMessage(e);
            }
          }}
          variant="gradient"
        >
          Invest
        </Button>
      </Box>
    </Container>
  );
};

export default PoolDetailsBasicDesc;
