export const getCurrencySymbol = (currency) => {
  switch (currency) {
    case 'USD':
      return '$';
    case 'EUR':
      return '€';
    case 'GBP':
      return '£';
    case 'CAD':
      return '$';
    case 'AUD':
      return '$';
    case 'NZD':
      return '$';
    case 'CHF':
      return 'Fr';
    case 'JPY':
      return '¥';
    case 'SGD':
      return '$';
    case 'HKD':
      return '$';
    case 'INR':
      return '₹';
    default:
      return '$';
  }
};
