import React, { useEffect, useState } from 'react';
import {
  Box,
  Container,
  Grid,
  Card,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  Typography
} from '@mui/material';
import TokenCard from 'components/issuerDashboard/TokenCard';
import Page from 'components/Page';
import EmptyDashboard from 'assets/images/emptyassets.png';
import { useAuthState, useCoreTableState, useBusinessEntityState } from 'state';
import { useNavigate } from 'react-router';
import CircularProgress from '@mui/material/CircularProgress';

const DataNotAvailable = () => {
  return (
    <>
      <Box
        sx={{
          height: '60vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <img src={EmptyDashboard} alt="Empty Dashboard" />
        <Typography variant="p">No Token available</Typography>
      </Box>
    </>
  );
};

const TokenCardList = ({ item }) => {
  return (
    <>
      <TokenCard token={item} />
    </>
  );
};

const FundManagerHome = () => {
  const existToken = true;
  const navigate = useNavigate();
  const { issueType, currencies, tokenStatus } = useCoreTableState();
  const { tokens, setToken, setTokens, fetchTokens } = useBusinessEntityState();
  useEffect(() => {
    fetchTokens();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [sortissueType, setSortissueType] = useState('');
  const [sortbyStatus, setSortbyStatus] = useState('');
  const [sortbyCurrency, setSortbyCurrency] = useState('');
  const [filter, setFilter] = useState('');
  const [filterTokens, setFilterTokens] = useState([]);
  useEffect(() => {
    const _tokens = tokens.filter(
      (item) =>
        item.issue_type_id.issue_type_name === sortissueType ||
        item.token_status_id.token_status === sortbyStatus ||
        item.token_currency.currency_code === sortbyCurrency
    );
    setFilterTokens(_tokens);
  }, [sortbyStatus, sortbyCurrency, sortissueType, tokens]);

  return (
    <Page title="Issuer Dashboard">
      <Container sx={{ my: 2 }}>
        <h2>To be implemented</h2>
      </Container>
    </Page>
  );
};

export default FundManagerHome;
