import React, { useState, useRef, useEffect } from 'react';
import {
  Grid,
  Divider,
  Typography,
  TextField,
  FormLabel,
  Box,
  FormControl,
  Select,
  FormHelperText,
  MenuItem,
  Alert,
  Button,
  IconButton
} from '@mui/material';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { useAppState, useBusinessEntityState, useHttpApi } from 'state';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router';
import DownloadIcon from '@mui/icons-material/Download';

const EditMapInsurertoIssuer = ({ handleEditClose }) => {
  const {
    onChainInsurers: insurers,
    currentIssuer,
    currentIssuerInsurerMap,
    fetchIssuerInsurerMappingByIssuerId
  } = useBusinessEntityState();
  const { editIssuerInsurerMapping } = useHttpApi();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const contractDoc = useRef(null);
  const [error, setError] = useState('');
  const { throwErrorMessage } = useAppState();
  const EditMapInsurertoIssuerSchema = Yup.object().shape({
    insurer_id: Yup.string().required('Select Insurer'),
    insurer_contri: Yup.number()
      .required('Insurer Contribution is required')
      .max(100, 'Contribution cannot be more than 100%'),
    issuer_contri: Yup.number().required('Issuer Contribution is required'),
    fees_per_month: Yup.number().required('Fees Per Month is required')
    /*   contract_doc_hash: Yup.mixed()
      .required('Upload Contract Document')
      .test('type', 'Only .pdf formats are accepted', (value) => {
        return value && value.substring(value.lastIndexOf('.')) === '.pdf';
      }) */
  });
  const formik = useFormik({
    initialValues: currentIssuerInsurerMap,
    validationSchema: EditMapInsurertoIssuerSchema,
    onSubmit: async (data, { resetForm }) => {
      console.log('edit chehcl', data);
      try {
        console.log('issuer id', currentIssuer.issuer_id);
        await editIssuerInsurerMapping(currentIssuer.issuer_id, data.insurer_id, data);
        resetForm();
        enqueueSnackbar('Insurer Mapped Edited successfully', {
          variant: 'success'
        });
        fetchIssuerInsurerMappingByIssuerId(currentIssuer.issuer_id);
        handleEditClose();
      } catch (e) {
        const errorMessage = throwErrorMessage(e);
        setError(e.message);
      }
    }
  });
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  useEffect(() => {
    if (formik.values.insurer_contri <= 100) {
      formik.setFieldValue('issuer_contri', 100 - values.insurer_contri);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.insurer_contri]);

  return (
    <>
      <Typography sx={{ fontWeight: 600 }}>Edit Credit Insurer Mapping</Typography>
      <Divider
        sx={{
          width: '100%',
          backgroundColor: '#f5f8fa',
          border: '2px solid #f5f8fa',
          my: 2
        }}
      />
      <FormikProvider value={formik}>
        <Form autoComplete="off" onSubmit={handleSubmit}>
          {error && (
            <Box mb={4}>
              <Alert
                severity="error"
                sx={{
                  textTransform: 'capitalize'
                }}
              >
                {error}
              </Alert>
            </Box>
          )}

          <Grid container spacing={4} sx={{ width: '100%' }}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <FormLabel>Insurer</FormLabel>
              <FormControl size="small" variant="outlined" fullWidth sx={{ mt: 1.5 }}>
                <Select
                  inputProps={{ readOnly: true }}
                  {...getFieldProps('insurer_id')}
                  error={Boolean(touched.insurer_id && errors.insurer_id)}
                  helperText={touched.insurer_id && errors.insurer_id}
                >
                  {insurers.map((insurer) => {
                    return (
                      <MenuItem value={insurer.insurer_id} key={insurer.insurer_id}>
                        {insurer.insurer_name}
                      </MenuItem>
                    );
                  })}
                </Select>

                <FormHelperText sx={{ color: '#d32f2f' }}>
                  {touched.insurer_id && errors.insurer_id}
                </FormHelperText>
              </FormControl>
            </Grid>
            {/*  <Grid item lg={12} md={12} sm={12} xs={12}>
              <FormLabel>Contract Document</FormLabel>
              <Grid Container sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Grid item lg={11} md={11} sm={11} xs={10}>
                  <TextField
                    sx={{ mt: 1.5 }}
                    fullWidth
                    size="small"
                    autoComplete="off"
                    type="file"
                    inputRef={contractDoc}
                    inputProps={{ accept: '.pdf' }}
                    {...getFieldProps('contract_doc_hash')}
                    id="contract_doc_hash"
                    error={Boolean(touched.contract_doc_hash && errors.contract_doc_hash)}
                    helperText={touched.contract_doc_hash && errors.contract_doc_hash}
                  />
                  <Grid container sx={{ width: '100%', mt: 2 }}>
                    <Grid item lg={4} md={4} sm={4}>
                      <Typography
                        sx={{
                          fontSize: '0.688rem',
                          textAlign: 'left',
                          color: '#161c2d'
                        }}
                      >
                        Maximum 50KB file size
                      </Typography>
                    </Grid>
                    <Grid item lg={8} md={8} sm={8}>
                      <Typography
                        sx={{
                          fontSize: '0.688rem',
                          textAlign: 'right',
                          color: '#8e8ea7'
                        }}
                      >
                        Accepted File Type .jpg, .jpeg, .png & .svg only
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item lg={1} md={1} sm={1} xs={1}>
                  <IconButton aria-label="subs detail" sx={{ mt: -3 }}>
                    <DownloadIcon
                      sx={{ fontSize: '2rem', p: 1, m: 0, backgroundColor: '#F5F8FA' }}
                    />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid> */}
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <FormLabel>Insurer Contribution in %</FormLabel>
              <TextField
                sx={{ mt: 1.5 }}
                fullWidth
                size="small"
                autoComplete="off"
                type="number"
                {...getFieldProps('insurer_contri')}
                error={Boolean(touched.insurer_contri && errors.insurer_contri)}
                helperText={touched.insurer_contri && errors.insurer_contri}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <FormLabel>Issuer Contribution in %</FormLabel>
              <TextField
                sx={{ mt: 1.5 }}
                fullWidth
                size="small"
                autoComplete="off"
                type="number"
                inputProps={{ readOnly: true }}
                {...getFieldProps('issuer_contri')}
                error={Boolean(touched.issuer_contri && errors.issuer_contri)}
                helperText={touched.issuer_contri && errors.issuer_contri}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <FormLabel>Fees Per Month</FormLabel>
              <TextField
                sx={{ mt: 1.5 }}
                fullWidth
                size="small"
                autoComplete="off"
                type="number"
                {...getFieldProps('fees_per_month')}
                error={Boolean(touched.fees_per_month && errors.fees_per_month)}
                helperText={touched.fees_per_month && errors.fees_per_month}
              />
            </Grid>
          </Grid>
          <Box display="flex" justifyContent="flex-end">
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleEditClose}
              sx={{ width: '8rem', height: '2.5rem', mt: 2 }}
            >
              Cancel
            </Button>
            <LoadingButton
              sx={{
                width: '8rem',
                height: '2.5rem',
                mt: 2,
                ml: 2
              }}
              variant="gradient"
              loadingPosition="start"
              id="create-token"
              type="submit"
            >
              Edit
            </LoadingButton>
          </Box>
        </Form>
      </FormikProvider>
    </>
  );
};

export default EditMapInsurertoIssuer;
