import React, { useEffect, useState } from 'react';
import { Chip, Typography } from '@mui/material';
import { useAppState, useBusinessEntityState, useHttpApi, useWeb3 } from 'state/index';
import { ethToXdcAddress, stringToBytes32 } from 'helpers/web3';
import EnhancedTable from 'components/tables/EnhancedTable';
import CopyAddress from 'components/CopyAddress';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';

const headCells = [
  {
    accessor: 'investor_id.investor_name',
    Header: 'Investor',
    show: true
  },
  {
    accessor: 'xinfin_address',
    Header: 'Xinfin Address',
    width: 250,
    show: true
  },

  {
    accessor: 'kyc_status',
    Header: 'KYC Status',
    show: true,
    Cell: (c) => {
      switch (c.row.original.kyc_status) {
        case 'COMPLETE':
          return <Chip label="VERIFIED" color="success" sx={{ width: '6rem' }} />;
        case 'ONCHAINCOMPLETE':
          return <Chip label="ONCHAIN" color="primary" sx={{ width: '6rem' }} />;
        default:
          return <Chip label="PENDING" color="warning" sx={{ width: '6rem' }} />;
      }
    }
  },
  {
    accessor: 'actions',
    Header: 'KYC ON-CHAIN',
    show: true
  }
];
const ActionButtons = ({ investor, issuer, kycProvider, kycStatus }) => {
  const { currentToken, fetchSubscription } = useBusinessEntityState();
  const { updateKycOnChain } = useHttpApi();
  const [saveButtonLoadingState, setSaveButtonLoadingState] = useState(false);
  const { updateKyc } = useWeb3();
  const { throwErrorMessage } = useAppState();
  const { enqueueSnackbar } = useSnackbar();

  return (
    <>
      <LoadingButton
        variant="gradient"
        color="primary"
        disabled={kycStatus !== 'COMPLETE'}
        loadingPosition="start"
        loading={saveButtonLoadingState}
        sx={{ borderColor: '#24ABDF', width: '8rem' }}
        onClick={async () => {
          try {
            setSaveButtonLoadingState(true);
            await updateKyc(issuer.primary_issuer_xinfin_address, investor.xinfin_account, {
              countryCode: stringToBytes32(investor.investor_country_code.country_code),
              kycStatus: true
            });

            const res = await updateKycOnChain(issuer.issuer_id, investor.investor_id);
            console.log('check ', res);
            if (res) {
              enqueueSnackbar('KYC Details Updated and Published On-Chain Succesfully!', {
                variant: 'success'
              });
            }
            setSaveButtonLoadingState(false);
            fetchSubscription(currentToken.token_id);
          } catch (e) {
            throwErrorMessage(e);
            setSaveButtonLoadingState(false);
          }
        }}
      >
        <Typography sx={{ fontSize: '12px' }}>
          {
            {
              PENDING: 'Pending',
              COMPLETE: 'Update',
              ONCHAINCOMPLETE: 'Completed'
            }[kycStatus]
          }
        </Typography>
      </LoadingButton>
    </>
  );
};

export default function CurrentWhiteListTable() {
  const { allSubscriptions, currentToken, fetchSubscription } = useBusinessEntityState();
  const [data, setData] = useState(allSubscriptions);

  const [skipPageReset, setSkipPageReset] = React.useState(false);
  const _fetchData = async () => {
    await fetchSubscription(currentToken.token_id);
  };
  useEffect(() => {
    _fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.log('xx', allSubscriptions);
    const _allSubscriptions = allSubscriptions.map((subscriber) => {
      subscriber.xinfin_address = (
        <CopyAddress addr={ethToXdcAddress(subscriber.xinfin_setl_account)} />
      );
      subscriber.actions = (
        <ActionButtons
          investor={{
            ...subscriber.investor_id
          }}
          issuer={subscriber.issuer}
          kycProvider={subscriber.kyc_provider}
          kycStatus={subscriber.kyc_status}
        />
      );
      return subscriber;
    });
    setData(_allSubscriptions);
  }, [allSubscriptions]);

  const updateMyData = (rowIndex, columnId, value) => {
    setSkipPageReset(true);
    setData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value
          };
        }
        return row;
      })
    );
  };

  return (
    <>
      <EnhancedTable
        tableTitle="AllSubscription"
        columns={headCells}
        data={data}
        setData={setData}
        updateMyData={updateMyData}
        refreshFunction={_fetchData}
        skipPageReset={skipPageReset}
      />
    </>
  );
}
