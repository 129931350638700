import rows from '../../../mocks/Holding.json';
import React, { useEffect, useState } from 'react';
import EnhancedTable from 'components/tables/EnhancedTable';
import { Box, IconButton, Button, Typography } from '@mui/material';
import { useBusinessEntityState } from 'state/useBusinessEntityState';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { ethToXdcAddress } from 'helpers/web3';
import { HeaderName } from 'helpers/tableHelper';

const InvestorName = HeaderName('Investor Name');
const RedemptionDueDate = HeaderName('Redemption Due Date');

export default function Holding() {
  const [data, setData] = useState(rows);
  const [skipPageReset, setSkipPageReset] = React.useState(false);

  const headCells = [
    {
      accessor: 'token_name',
      Header: 'Token Name',
      show: true
    },
    {
      accessor: 'issuer_name',
      Header: 'Issuer Name',
      show: true
    },
    {
      accessor: 'symbol',
      Header: 'Symbol',
      show: true
    },
    {
      accessor: 'token_value',
      Header: 'Token value',
      width: 100,
      show: true
    },
    {
      accessor: 'holding_qty',
      Header: 'Holding Quantity',
      show: true
    },
    {
      accessor: 'allotment_date',
      Header: 'Allotment date',
      show: true
    },
    {
      accessor: 'maturity_date',
      Header: 'Maturity date',
      show: true
    }
  ];

  const updateMyData = (rowIndex, columnId, value) => {
    setSkipPageReset(true);
    setData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value
          };
        }
        return row;
      })
    );
  };

  return (
    <>
      <EnhancedTable
        tableTitle="IssuanceEscrow"
        columns={headCells}
        data={data}
        setData={setData}
        updateMyData={updateMyData}
        skipPageReset={skipPageReset}
      />
    </>
  );
}
