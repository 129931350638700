import React, { useRef, useState } from 'react';
import {
  Container,
  Card,
  Grid,
  CardContent,
  Divider,
  Typography,
  TextField,
  FormLabel,
  Button,
  Box,
  Alert,
  Select,
  MenuItem,
  FormHelperText,
  FormControl,
  IconButton,
  Modal
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { LoadingButton } from '@mui/lab';
import Page from 'components/Page';

import Breadcrumbs from 'components/Breadcrumbs';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router';
import { xdcRegex } from 'helpers/regex';
import { useFormik, Form, FormikProvider } from 'formik';
import { toChecksumAddress, xdcToEthAddress } from 'helpers/web3';
import {
  useBusinessEntityState,
  useHttpApi,
  useWeb3,
  useAuthStateShared,
  useAppState
} from 'state';
import { useCoreTableState } from 'state/useCoreTableState';
import { LeiInfoButton, XdcInfoButton } from 'helpers/infoButton';
import CommonOverlay from 'components/CommonOverlay';
import { onlyOwner } from 'helpers/rbac';
import LogoPreview from 'components/masterData/LogoPreview';

const EditCreditInsurer = () => {
  const [error, setError] = useState(null);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const { editInsurer } = useHttpApi();
  const { currentInsurer, fetchInsurer } = useBusinessEntityState();
  const { role } = useAuthStateShared();

  const creditInsurerLogo = useRef(null);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const EditCreditInsurerSchema = Yup.object().shape({
    insurer_name: Yup.string().required('Full Name is required'),

    insurer_country_code: Yup.string().required('Country Code is required'),

    primary_insurer_xinfin_address: Yup.string()
      .matches(xdcRegex, 'Invalid XDC Address Format')
      .required('XinFin Address is required')
  });

  const { addInsurer: addInsurerBlockchain } = useWeb3();
  const { throwErrorMessage } = useAppState();

  const formik = useFormik({
    initialValues: currentInsurer,
    validationSchema: EditCreditInsurerSchema,
    onSubmit: async (data, { resetForm }) => {
      console.log('Edit Credit Insurer', data);
      try {
        setError(null);
        delete data.insurer_logo;

        if (creditInsurerLogo.current.files[0]) {
          const creditInsurerLogoFile = creditInsurerLogo.current.files[0];
          data.insurer_logo = creditInsurerLogoFile;
        }

        data.primary_insurer_xinfin_address = xdcToEthAddress(data.primary_insurer_xinfin_address);
        data.primary_insurer_xinfin_address = toChecksumAddress(
          data.primary_insurer_xinfin_address
        );
        const countryCode = countries.find((country) => country.id === data.insurer_country_code);
        if (currentInsurer.status === 'OFFCHAIN') {
          try {
            const blockchainRes = await addInsurerBlockchain(
              countryCode.country_code,
              data.primary_insurer_xinfin_address
            );
            console.log('blockchainRes', blockchainRes);
          } catch (e) {
            console.log(e);
          }
        }
        delete data.status;
        const _editinsurerresponse = await editInsurer(currentInsurer.insurer_id, data); // change
        if (_editinsurerresponse.insurer_id) {
          enqueueSnackbar('Credit Insurer updated successfully', {
            variant: 'success'
          });
          resetForm();
          fetchInsurer();
          navigate('/masterdata/credit-insurer/');
        }
      } catch (e) {
        throwErrorMessage(e);
      }
    }
  });

  const { errors, touched, isSubmitting, values, handleSubmit, getFieldProps } = formik;

  const { countries, orgnizations } = useCoreTableState();

  const allowedRolesList = ['OWNER'];
  return (
    <>
      <CommonOverlay
        allowedRoles={allowedRolesList}
        overlayTitle="You don't have access to Edit Primary Credit Insurer Account"
      />
      <Page title="Edit Credit Insurer - Master Data">
        <Container sx={{ my: 2 }}>
          <Box sx={{ my: 2 }}>
            <Breadcrumbs pageHead="Edit Credit Insurer" aria-label="breadcrumb" />
          </Box>
          <Card>
            <Container>
              <Typography
                sx={{
                  fontSize: '1.125rem',
                  fontWeight: 'bold',
                  mt: 4,
                  mb: 1,
                  ml: 4
                }}
              >
                Credit Insurer Details
              </Typography>
              <Divider
                sx={{
                  width: '100%',
                  backgroundColor: '#f5f8fa',
                  border: '2px solid #f5f8fa'
                }}
              />
              <FormikProvider value={formik}>
                <Form autoComplete="off" onSubmit={handleSubmit}>
                  {error && (
                    <Box mb={4}>
                      <Alert
                        severity="error"
                        sx={{
                          textTransform: 'capitalize'
                        }}
                      >
                        {error}
                      </Alert>
                    </Box>
                  )}
                  <CardContent sx={{ m: 2 }}>
                    <Grid container spacing={3} sx={{ width: '100%' }}>
                      <Grid item lg={6} sm={12} md={6} xs={12}>
                        <FormLabel> Name</FormLabel>
                        <TextField
                          sx={{ mt: 1.5 }}
                          fullWidth
                          size="small"
                          autoComplete="off"
                          type="text"
                          // placeholder="Enter  Name"
                          {...getFieldProps('insurer_name')}
                          error={Boolean(touched.insurer_name && errors.insurer_name)}
                          helperText={touched.insurer_name && errors.insurer_name}
                        />
                      </Grid>
                      <Grid item lg={6} md={6} xs={12} sm={12}>
                        <FormLabel> Username</FormLabel>
                        <TextField
                          inputProps={{ readOnly: true }}
                          sx={{ mt: 1.5 }}
                          fullWidth
                          size="small"
                          autoComplete="off"
                          type="text"
                          {...getFieldProps('username')}
                          error={Boolean(touched.username && errors.username)}
                          helperText={touched.username && errors.username}
                        />
                      </Grid>
                      <Grid item lg={6} md={6} xs={12} sm={12}>
                        <FormLabel> Email</FormLabel>
                        <TextField
                          sx={{ mt: 1.5 }}
                          inputProps={{ readOnly: true }}
                          fullWidth
                          size="small"
                          autoComplete="off"
                          type="text"
                          {...getFieldProps('email')}
                          error={Boolean(touched.email && errors.username)}
                          helperText={touched.email && errors.email}
                        />
                      </Grid>
                      <Grid item lg={6} sm={12} md={6} xs={12}>
                        <FormLabel>
                          Xinfin Address
                          <XdcInfoButton />
                        </FormLabel>
                        <TextField
                          inputProps={{ readOnly: true }}
                          sx={{ mt: 1.5 }}
                          fullWidth
                          size="small"
                          autoComplete="off"
                          type="text"
                          // placeholder="Primary XinFin Address"
                          {...getFieldProps('primary_insurer_xinfin_address')}
                          error={Boolean(
                            touched.primary_insurer_xinfin_address &&
                              errors.primary_insurer_xinfin_address
                          )}
                          helperText={
                            touched.primary_insurer_xinfin_address &&
                            errors.primary_insurer_xinfin_address
                          }
                        />
                      </Grid>
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <FormLabel>Logo</FormLabel>
                        <Grid
                          Container
                          sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                        >
                          <Grid item lg={11} md={11} sm={11} xs={10}>
                            <TextField
                              sx={{ mt: 1.5 }}
                              fullWidth
                              size="small"
                              autoComplete="off"
                              type="file"
                              inputRef={creditInsurerLogo}
                              inputProps={{ accept: '.jpg, .jpeg, .png, .svg' }}
                              {...getFieldProps('insurer_logo_doc')}
                              id="insurer_logo_doc"
                              error={Boolean(touched.insurer_logo_doc && errors.insurer_logo_doc)}
                              helperText={touched.insurer_logo_doc && errors.insurer_logo_doc}
                            />
                            <Grid container sx={{ width: '100%', mt: 2 }}>
                              <Grid item lg={4} md={4} sm={4}>
                                <Typography
                                  sx={{
                                    fontSize: '0.688rem',
                                    textAlign: 'left',
                                    color: '#161c2d'
                                  }}
                                >
                                  Maximum 50KB file size
                                </Typography>
                              </Grid>
                              <Grid item lg={8} md={8} sm={8}>
                                <Typography
                                  sx={{
                                    fontSize: '0.688rem',
                                    textAlign: 'right',
                                    color: '#8e8ea7'
                                  }}
                                >
                                  Accepted File Type .jpg, .jpeg, .png & .svg only
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item lg={1} md={1} sm={1} xs={1}>
                            <IconButton
                              aria-label="subs detail"
                              onClick={handleOpen}
                              sx={{ mt: -3 }}
                            >
                              <VisibilityIcon
                                sx={{ fontSize: '2rem', p: 1, m: 0, backgroundColor: '#F5F8FA' }}
                              />
                            </IconButton>
                            <Modal
                              open={open}
                              onClose={handleClose}
                              aria-labelledby="modal-modal-title"
                              aria-describedby="modal-modal-description"
                            >
                              <LogoPreview
                                imgSrc={currentInsurer.insurer_logo}
                                handleClose={handleClose}
                              />
                            </Modal>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item lg={6} sm={12} md={6} xs={12}>
                        <FormLabel> Country Code</FormLabel>

                        <FormControl size="small" variant="outlined" fullWidth sx={{ mt: 1.5 }}>
                          <Select
                            // placeholder="Select Country"
                            inputProps={{ readOnly: true }}
                            {...getFieldProps('insurer_country_code')}
                            error={Boolean(
                              touched.insurer_country_code && errors.insurer_country_code
                            )}
                            helperText={touched.insurer_country_code && errors.insurer_country_code}
                          >
                            {countries &&
                              countries.map((country, index) => {
                                return (
                                  <MenuItem key={country.id} value={country.id}>
                                    {country.country_name}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                          <FormHelperText sx={{ color: '#d32f2f' }}>
                            {touched.insurer_country_code && errors.insurer_country_code}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                    </Grid>

                    <Box
                      sx={{
                        mt: 4,
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end'
                      }}
                    >
                      <Button
                        sx={{
                          margin: 1,
                          borderColor: '#24ABDF',
                          px: 3,
                          width: '7.75rem',
                          height: '2.5rem'
                        }}
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                          navigate('/masterdata/credit-insurer');
                        }}
                      >
                        Cancel
                      </Button>
                      <LoadingButton
                        type="submit"
                        disabled={!onlyOwner(role)}
                        variant="gradient"
                        fullWidth
                        size="large"
                        loadingPosition="start"
                        loading={isSubmitting}
                        sx={{ margin: 1, px: 3, width: '7.75rem', height: '2.5rem' }}
                      >
                        Save
                      </LoadingButton>
                    </Box>
                  </CardContent>
                </Form>
              </FormikProvider>
            </Container>
          </Card>
        </Container>
      </Page>
    </>
  );
};

export default EditCreditInsurer;
