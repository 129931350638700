import React, { useEffect, useState } from 'react';
import { IconButton, Button, Typography, Chip } from '@mui/material';
import { useBusinessEntityState, useAuthStateShared } from 'state/index';
import { ethToXdcAddress } from 'helpers/web3';
import EnhancedTable from 'components/tables/EnhancedTable';
import SubsDetails from 'components/masterData/Dashboard/SubsDetails';
import Modal from '@mui/material/Modal';
import CopyAddress from 'components/CopyAddress';
import { useNavigate } from 'react-router';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

const headCells = [
  {
    accessor: 'investor_id.investor_name',
    Header: 'Investor',
    show: true
  },
  {
    accessor: 'xinfin_address',
    Header: 'Xinfin Address',
    width: 200,
    show: true,
    Cell: (c) => <CopyAddress addr={ethToXdcAddress(c.row.original.investor_id.xinfin_account)} />
  },
  {
    accessor: 'requested_qty',
    Header: 'Requested Qty',
    show: true
  },
  {
    accessor: 'kyc_status',
    Header: 'KYC Status',
    show: true,
    Cell: (c) => {
      switch (c.row.original.kyc_status) {
        case 'COMPLETE':
          return <Chip label="COMPLETE" color="success" sx={{ mt: '8px', mb: '4px' }} />;
        case 'PROGRESS':
          return <Chip label="IN PROGRESS" color="primary" sx={{ mt: '8px', mb: '4px' }} />;
        default:
          return <Chip label="PENDING" color="warning" sx={{ mt: '8px', mb: '4px' }} />;
      }
    }
  },
  {
    accessor: 'actions',
    Header: 'KYC/AML/CTF',
    show: true,
    Cell: (c) => {
      console.log(c.row.original);
      return (
        <ActionButtons
          investor={{
            ...c.row.original.investor_id
          }}
          issuer={c.row.original.issuer}
          kycProvider={c.row.original.kyc_provider}
          kycStatus={c.row.original.kyc_status}
        />
      );
    }
  }
];
const ActionButtons = ({ investor, issuer, kycProvider, kycStatus }) => {
  const navigate = useNavigate();
  const { setToken, currentToken } = useBusinessEntityState();
  const { setKycInvestor, setKycIssuer, setKycKycProvider } = useBusinessEntityState();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Button
      variant={
        {
          PENDING: 'contained',
          PROGRESS: 'gradient',
          COMPLETE: 'outlined'
        }[kycStatus]
      }
      color={
        {
          PENDING: 'secondary',
          PROGRESS: 'secondary',
          COMPLETE: 'primary'
        }[kycStatus]
      }
      sx={{ ml: 1, mt: '8px', mb: '4px' }}
      onClick={() => {
        setToken(currentToken);
        setKycInvestor(investor);
        setKycIssuer(issuer);
        setKycKycProvider(kycProvider);
        navigate('/kyc/subscriber');
      }}
    >
      <Typography sx={{ fontSize: '12px' }}>
        {
          {
            PENDING: 'Initiate',
            PROGRESS: 'Continue',
            COMPLETE: 'Update'
          }[kycStatus]
        }
      </Typography>
    </Button>
  );
};

export default function KYCSubscriberTable() {
  const { allSubscribersbyKycProvider, currentToken, fetchAllSubscribersByKycProvider } =
    useBusinessEntityState();
  const { user } = useAuthStateShared();
  const [data, setData] = useState(allSubscribersbyKycProvider);

  const [skipPageReset, setSkipPageReset] = React.useState(false);

  useEffect(() => {
    fetchAllSubscribersByKycProvider(user.xinfin_address.xinfin_account_address);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setData(allSubscribersbyKycProvider);
  }, [allSubscribersbyKycProvider]);

  const updateMyData = (rowIndex, columnId, value) => {
    setSkipPageReset(true);
    setData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value
          };
        }
        return row;
      })
    );
  };

  return (
    <>
      <EnhancedTable
        tableTitle="AllSubscription"
        columns={headCells}
        data={data}
        setData={setData}
        updateMyData={updateMyData}
        refreshFunction={fetchAllSubscribersByKycProvider}
        skipPageReset={skipPageReset}
      />
    </>
  );
}
