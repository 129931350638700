import { Box, IconButton, Modal, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const TermsAndConditionsmodal = ({ open, handleClose }) => {
  return (
    <>
      <Modal
        BackdropProps={{
          timeout: 500,
          onClick: (e) => e.stopPropagation() // This prevents closing the modal by clicking on the backdrop
        }}
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableEscapeKeyDown
        disableEnforceFocus
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            width: 800,
            p: 4,
            boxShadow: 24,
            display: 'flex',
            flexDirection: 'column',
            borderRadius: '10px'
          }}
        >
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{ position: 'absolute', right: 8, top: 8, color: 'grey.500' }}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h4" sx={{ my: 2 }}>
            Terms and Conditions
          </Typography>
          <Typography variant="body1" sx={{ lineHeight: 2, mb: 2 }}>
            Tailored Digital Assets develop real estate projects globally by acquiring large land
            parcels and working in collaboration with renowned real estate developers. The projects
            are financed by tokenising the Safi issued by recognised banks in the project country.
            The tokenised Safi are issued for investment by accredited US investors. These tokens
            provide safe and lucrative investment options for the US investors with an option to
            diversify and invest in the assets that provide stable yield and are un-corelated to US
            Bond & equity market assets.
          </Typography>
        </Box>
      </Modal>
    </>
  );
};

export default TermsAndConditionsmodal;
