import Page from 'components/Page';
import React from 'react';
import { Card, Container, CardContent } from '@mui/material';
import Breadcrumbs from 'components/Breadcrumbs';
import IssuerTable from 'components/masterData/Issuer/IssuerTable';
import CommonOverlay from 'components/CommonOverlay';

const AllIssuer = () => {
  const allowedRolesList = ['OWNER'];
  return (
    <Page title="Issuer - Master Data">
      <CommonOverlay
        allowedRoles={allowedRolesList}
        overlayTitle="You don't have access to View Primary Issuer Account"
      />
      <Container>
        <Breadcrumbs pageHead="All Issuers" />

        <Card sx={{ mt: 2 }}>
          <CardContent>
            <IssuerTable />
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
};

export default AllIssuer;
