import constate from 'constate';
import { businessEntityPersistence } from 'persistence';
import { useState, useEffect } from 'react';
import { useHttpApi } from 'state/useHttpApi';
import { useAuthState } from './useAuthState';
import { useAuthStateShared } from './useAuthStateShared';

const useBusinessEntityState_ = () => {
  const {
    getAllIssuers,
    // getAllCustodian,
    getAllKycProvider,
    getAllUsers,
    getAllInsurers,
    getAlloriginator,
    getAllTokens,
    getTokenById,
    getAllSubscriptionByToken,
    getAllWhitelistedSubscription,
    getAllAllotments,
    getIssuanceEscrowByToken,
    getAllIssuanceEscrowByAddress,
    getAllRedemptionEscrowByAddress,
    getAllRedemptionEscrowByTokenId,
    getAllInvestors,
    getAllRedeemedAllotmentsByToken,
    getAllXinfinAccounts,
    getAllSubscribersByKycProviderXinfinAddr,
    getInvestorsByIssuer,
    getIssuerInsurerMappingByIssuerId,
    getAllIssuerInsurerMapping,
    getAllRuleTemplates,
    getAllPaymentTransaction,
    getPaymentTransactionByTokenId,
    getPaymentTransactionByXinfinAddress,
    getIssuerInsurerMappingByInsurerId,
    getRuleTemplateDetailedByToken,
    getTokenDetailsByIssuerIdandInsurerId,
    getIssuanceEscrowOrderByInsurerIssuerId,
    getRedemptionEscrowOrderByInsurerIssuerId,
    getAllotmentsByInsurerIssuerId,
    getAllRedemptionsByInsurerIssuerId,
    getAllCryptoAssetTransaction,
    getCryptoAssetTransactionByIssuerXinfin,
    getCryptoAssetTransactionByIssuerInsurerXinfin,
    getBlockchainTransactions,
    getCollateralTransactionsByInsurer,
    getCollateralTransactionsByIssuer,
    getIssueType,
    getAllDocumentTypes,
    getAllDocumentSubTypes,
    getTokenLifecycleDocumentMappingsByIssueType,
    getAllLiquidityPools,
    getAllOnChainLiquidityPools,
    getTokenNavPool,
    getPoolProxyToken,
    getPoolHoldings,
    getPoolByPoolAddress,
    getAssetsByPoolAddress,
    getTokenSummaryDataByTokenId,
    getAllFundManagers
  } = useHttpApi();

  const { role, user, entity } = useAuthStateShared();

  const { isAuthorized } = useAuthState();
  const [users, setUsers] = useState([]);
  const [id, setId] = useState('');
  const [issuers, setIssuers] = useState([]);
  const [onChainIssuers, setOnChainIssuers] = useState([]);
  const [fundManagers, setFundManagers] = useState([]);
  const [onChainFundManagers, setOnChainFundManagers] = useState([]);
  const [currentIssuer, setCurrentIssuer] = useState({});
  const [currentFundManager, setCurrentFundManager] = useState({});
  const [currentInsurer, setCurrentInsurer] = useState({});
  const [currentIssuerInsurerMap, setCurrentIssuerInsurerMap] = useState({});
  const [kycStatus, setKycStatus] = useState('');
  // const [currentCustodian, setCurrentCustodian] = useState({});
  // const [custodians, setCustodian] = useState([]);
  const [insurers, setInsurer] = useState([]);
  const [onChainCustodians, setOnChainCustodians] = useState([]);
  const [onChainInsurers, setOnChainInsurers] = useState([]);
  const [kycProviders, setKycProviders] = useState([]);
  const [onChainKycProviders, setOnChainKycProviders] = useState([]);
  const [currentKycProvider, setCurrentKycProvider] = useState({});
  const [originators, setOriginators] = useState([]);
  const [currentOriginator, setCurrentOriginator] = useState({});
  const [tokens, setTokens] = useState([]);

  const [currentToken, setCurrentToken] = useState({});
  const [tokenById, setTokenById] = useState({});
  const [currentSubsToken, setCurrentSubsToken] = useState({});
  const [subscription, setSubscription] = useState([]);
  const [allSubscriptions, setAllSubscriptions] = useState([]);
  const [currentWhiteList, setCurrentWhiteList] = useState([]);
  const [pendingWhiteList, setPendingWhiteList] = useState([]);
  const [addWhiteListAddresses, setAddWhiteListAddresses] = useState([]);
  const [removeWhiteListAddresses, setRemoveWhiteListAddresses] = useState([]);
  const [whitelistedSubscriptions, setWhitelistedSubscriptions] = useState([]);
  const [createAllotmentsList, setCreateAllotmentsList] = useState([]);
  const [allAllotments, setAllAllotments] = useState([]);
  const [redeemAllotmentsList, setRedeemAllotmentsList] = useState([]);
  const [issuanceEscrowOrders, setIssuanceEscrowOrders] = useState([]);
  const [investorIssuanceEscrow, setInvestorIssuanceEscrow] = useState([]);
  const [investorRedemptionEscrow, setInvestorRedemptionEscrow] = useState([]);
  const [issuerRedemptionEscrow, setIssuerRedemptionEscrow] = useState([]);
  const [loggedInIssuer, setLoggedInIssuer] = useState({});
  const [loggedInInsurer, setLoggedInInsurer] = useState({});
  const [allInvestors, setAllInvestors] = useState([]);
  const [reedemedTokens, setRedeemedToken] = useState([]);
  const [allXinfinAccounts, setAllXinfinAccounts] = useState([]);
  const [allSubscribersbyKycProvider, setAllSubscribersByKycProvider] = useState([]);
  const [currentKycInvestor, setCurrentKycInvestor] = useState({});
  const [currentKycIssuer, setCurrentKycIssuer] = useState({});
  const [currentKycKycProvider, setCurrentKycKycProvider] = useState({});
  const [currentDocumentPageInvestor, setcurrentDocumentPageInvestor] = useState({});
  const [currentDocumentPageIssuer, setcurrentDocumentPageIssuer] = useState({});
  const [currentDocumentPageDocuments, setcurrentDocumentPageDocuments] = useState({});
  const [currentDocumentPageRequiredDocuments, setcurrentDocumentPageRequiredDocuments] = useState(
    {}
  );
  const [currentSubscriptionId, setCurrentSubscriptionId] = useState('');
  const [investorsByIssuer, setInvestorsByIssuer] = useState([]);
  const [issuerInvestorMapByIssuerId, setIssuerInvestorMapByIssuerId] = useState([]);
  const [issuerInvestorMapByInsurerId, setIssuerInvestorMapByInsurerId] = useState([]);
  const [allIssuerInvestorMap, setAllIssuerInvestorMap] = useState([]);
  const [ruleTemplates, setRuleTemplates] = useState([]);
  const [currentTokenRuleDetails, setCurrentTokenRuleDetails] = useState([]);
  const [paymentTransactionList, setPaymentTransactionList] = useState([]);
  const [paymentTransactionByTokenIdList, setPaymentTransactionByTokenIdList] = useState([]);
  const [paymentTransactionByXinfinList, setPaymentTransactionByXinfinList] = useState([]);
  const [insurerIssuerTokens, setInsurerIssuerTokens] = useState([]);

  const [currCreditIssuer, setCurrCreditIssuer] = useState({});
  const [currCreditInsurer, setCurrCreditInsurer] = useState({});

  const [insurerIssuerIssuanceEscrowOrder, setInsurerIssuerIssuanceEscrowOrder] = useState([]);
  const [insurerIssuerRedemptionEscrowOrder, setInsurerIssuerRedemptionEscrowOrder] = useState([]);
  const [insurerIssuerAllotments, setInsurerIssuerAllotments] = useState([]);
  const [insurerIssuerAllRedemptions, setInsurerIssuerAllRedemptions] = useState([]);

  const [cryptoAssetTxn, setCryptoAssetTxn] = useState([]);
  const [cryptoAssetTxnBySenderXinfin, setCryptoAssetTxnBySenderXinfin] = useState([]);
  const [cryptoAssetTxnByIssuerInsurerXinfin, setCryptoAssetTxnByIssuerInsurerXinfin] = useState(
    []
  );
  const [blockchainTransactions, setBlockchainTransactions] = useState([]);
  const [collateralTransactions, setAllCollateralTransactions] = useState([]);
  const [issueTypes, setIssueTypes] = useState([]);
  const [allDocumentTypes, setAllDocumentTypes] = useState([]);
  const [allDocumentSubTypes, setAllDocumentSubTypes] = useState([]);
  const [
    allTokenLifecycleDocumentTypeMappingsByIssueType,
    setallTokenLifecycleDocumentTypeMappingsByIssueType
  ] = useState([]);
  const [liquidityPools, setLiquidityPools] = useState([]);
  const [onChainLiquidityPools, setOnChainLiquidityPools] = useState([]);
  const [currentLiquidityPool, setCurrentLiquidityPool] = useState({});
  const [loggedInPool, setLoggedInPool] = useState({});
  const [tokenNavHistoryPool, setTokenNavHistoryPool] = useState([]);
  const [poolProxyToken, setPoolProxyToken] = useState();
  const [poolHoldings, setPoolHoldings] = useState([]);
  const [poolDetails, setPoolDetails] = useState([]);
  const [poolAssetDetails, setPoolAssetDetails] = useState([]);
  const [pendingDocsCount, setPendingDocsCount] = useState(0);
  const [pendingKYCCount, setPendingKYCCount] = useState(0);
  const [tokenSummary, setTokenSummary] = useState([]);

  const fetchPoolAssetDetails = async (address) => {
    const _poolAssetDetails = await getAssetsByPoolAddress(address);
    setPoolAssetDetails(_poolAssetDetails);
  };

  const fetchPoolDetails = async (address) => {
    const _poolDetails = await getPoolByPoolAddress(address);
    setPoolDetails(_poolDetails);
  };

  const fetchAllTokenLifecycleDocumentTypeMappingsByIssueType = async (id) => {
    const res = await getTokenLifecycleDocumentMappingsByIssueType(id);
    setallTokenLifecycleDocumentTypeMappingsByIssueType(res);
  };

  const fetchAllDocumentSubTypes = async () => {
    const res = await getAllDocumentSubTypes();
    setAllDocumentSubTypes(res);
  };

  const fetchAllDocumentTypes = async () => {
    const res = await getAllDocumentTypes();
    setAllDocumentTypes(res);
  };

  const fetchIssueTypes = async () => {
    const res = await getIssueType();
    setIssueTypes(res);
  };

  const fetchTokens = async () => {
    const response = await getAllTokens();
    console.log(response);
    setTokens(response);
  };

  const fetchInsurerIssuerTokens = async (issuerId, insurerId) => {
    const response = await getTokenDetailsByIssuerIdandInsurerId(issuerId, insurerId);
    setInsurerIssuerTokens(response);
  };

  const fetchIssuanceEscrowOrderByInsurerIssuerId = async (issuerId, insurerId) => {
    const response = await getIssuanceEscrowOrderByInsurerIssuerId(issuerId, insurerId);
    setInsurerIssuerIssuanceEscrowOrder(response);
  };

  const fetchRedemptionEscrowOrderByInsurerIssuerId = async (issuerId, insurerId) => {
    const response = await getRedemptionEscrowOrderByInsurerIssuerId(issuerId, insurerId);
    setInsurerIssuerRedemptionEscrowOrder(response);
  };

  const fetchAllotmentsByInsurerIssuerId = async (issuerId, insurerId) => {
    const response = await getAllotmentsByInsurerIssuerId(issuerId, insurerId);
    setInsurerIssuerAllotments(response);
  };

  const fetchAllRedemptionsByInsurerIssuerId = async (issuerId, insurerId) => {
    const response = await getAllRedemptionsByInsurerIssuerId(issuerId, insurerId);
    setInsurerIssuerAllRedemptions(response);
  };

  const fetchAllSubscribersByKycProvider = async (xinfinAddress) => {
    const res = await getAllSubscribersByKycProviderXinfinAddr(xinfinAddress);
    setAllSubscribersByKycProvider(res);
  };

  const setKycInvestor = (data) => {
    setCurrentKycInvestor(data);
    businessEntityPersistence.set('currKycInvestor', JSON.stringify(data));
  };

  const setKycIssuer = (data) => {
    setCurrentKycIssuer(data);
    businessEntityPersistence.set('currKycIssuer', JSON.stringify(data));
  };

  const setKycKycProvider = (data) => {
    setCurrentKycKycProvider(data);
    businessEntityPersistence.set('currKycKycProvider', JSON.stringify(data));
  };

  const setDocumentPageInvestor = (data) => {
    setCurrentKycInvestor(data);
    businessEntityPersistence.set('currDocumentPageInvestor', JSON.stringify(data));
  };

  const setDocumentPageIssuer = (data) => {
    setcurrentDocumentPageIssuer(data);
    businessEntityPersistence.set('currDocumentPageIssuer', JSON.stringify(data));
  };

  const setDocumentPageDocuments = (data) => {
    setcurrentDocumentPageDocuments(data);
    businessEntityPersistence.set('currDocumentPageDocuments', JSON.stringify(data));
  };

  const setDocumentPageRequiredDocuments = (data) => {
    setcurrentDocumentPageRequiredDocuments(data);
    businessEntityPersistence.set('currDocumentPageRequiredDocuments', JSON.stringify(data));
  };

  const setSubscriptionId = (data) => {
    setCurrentSubscriptionId(data);
    businessEntityPersistence.set('currentSubscriptionId', JSON.stringify(data));
  };

  const setCurrentCreditIssuer = (data) => {
    setCurrCreditIssuer(data);
    businessEntityPersistence.set('currCreditIssuer', JSON.stringify(data));
  };

  const setCurrentCreditInsurer = (data) => {
    setCurrCreditInsurer(data);
    businessEntityPersistence.set('currCreditInsurer', JSON.stringify(data));
  };

  const fetchAllXinfinAccounts = async () => {
    const response = await getAllXinfinAccounts();
    setAllXinfinAccounts(response);
  };

  const fetchAllInvestors = async () => {
    const fetchedInvestors = await getAllInvestors();
    setAllInvestors(fetchedInvestors);
  };

  const fetchCurrentToken = (data) => {
    setCurrentToken(data);
    businessEntityPersistence.set('currToken', JSON.stringify(data));
  };

  const fetchCurrentTokenRuleDetails = async () => {
    const _rules = await getRuleTemplateDetailedByToken(currentToken.token_id);
    setCurrentTokenRuleDetails(_rules);
    businessEntityPersistence.set('currTokenRules', JSON.stringify(_rules));
  };

  const fetchCryptoAssetTxn = async () => {
    const response = await getAllCryptoAssetTransaction();
    setCryptoAssetTxn(response);
  };

  //  CurrCreditIssuer and CurrCreditInsurer are used in PRIMARY_INSURER Login to get the Issuer and Insurer details
  // So Only be used in PRIMARY_INSURER Login
  // const fetchCryptoAssetTxnByIssuerXinfin = async () => {
  //   const response = await getCryptoAssetTransactionByIssuerXinfin(
  //     currCreditIssuer.issuer_xinfin_address
  //   );
  //   setCryptoAssetTxnByIssuerXinfin(response);
  // };

  const fetchCryptoAssetTxnBySenderXinfin = async () => {
    const response = await getCryptoAssetTransactionByIssuerXinfin(
      user.parent_xinfin_account.xinfin_account_address
    );
    setCryptoAssetTxnBySenderXinfin(response);
  };

  const fetchCryptoAssetTxnByIssuerInsurerXinfin = async () => {
    const response = await getCryptoAssetTransactionByIssuerInsurerXinfin(
      currCreditIssuer.issuer_xinfin_address,
      currCreditInsurer.primary_insurer_xinfin_address
    );
    setCryptoAssetTxnByIssuerInsurerXinfin(response);
  };

  const fetchBlockchainTransactions = async () => {
    const response = await getBlockchainTransactions();
    setBlockchainTransactions(response);
  };

  const fetchAllCollateralTransactions = async () => {
    if (user.parent_xinfin_account.role === 'PRIMARY_ISSUER') {
      const response = await getCollateralTransactionsByIssuer(
        user.parent_xinfin_account.xinfin_account_address
      );
      setAllCollateralTransactions(response);
    } else {
      const response = await getCollateralTransactionsByInsurer(
        user.parent_xinfin_account.xinfin_account_address
      );
      setAllCollateralTransactions(response);
    }
  };

  const fetchPendingDocCount = (allSubscriptions) => {
    const pendingDocs = allSubscriptions.filter(
      (investor) => investor.document_upload_status === 'PENDING'
    );
    setPendingDocsCount(pendingDocs.length);
  };

  const fetchPendingKYCCount = (allSubscriptions) => {
    const pendingKYC = allSubscriptions.filter(
      (investor) => investor.kyc_status !== 'ONCHAINCOMPLETE'
    );
    setPendingKYCCount(pendingKYC.length);
  };

  const loadCurrentToken = () => {
    if (businessEntityPersistence.get('currToken')) {
      setCurrentToken(JSON.parse(businessEntityPersistence.get('currToken')));
    }
    if (businessEntityPersistence.get('currTokenById')) {
      setTokenById(JSON.parse(businessEntityPersistence.get('currTokenById')));
    }
    if (businessEntityPersistence.get('CurrentWhiteList')) {
      setCurrentWhiteList(JSON.parse(businessEntityPersistence.get('CurrentWhiteList')));
    }
    if (businessEntityPersistence.get('PendingWhiteList')) {
      setPendingWhiteList(JSON.parse(businessEntityPersistence.get('PendingWhiteList')));
    }
  };

  const loadCurrentCrediter = () => {
    console.log('loadCurrentCrediter');
    if (businessEntityPersistence.get('currCreditIssuer')) {
      setCurrCreditIssuer(JSON.parse(businessEntityPersistence.get('currCreditIssuer')));
    }
    if (businessEntityPersistence.get('currCreditInsurer')) {
      setCurrCreditInsurer(JSON.parse(businessEntityPersistence.get('currCreditInsurer')));
    }
  };

  const fetchIssuer = async () => {
    const _issuers = await getAllIssuers();
    setIssuers(_issuers);
    const _onChainIssuers = _issuers.filter((issuer) => issuer.status === 'ONCHAIN');
    setOnChainIssuers(_onChainIssuers);
  };

  const fetchFundManagers = async () => {
    const _fundManagers = await getAllFundManagers();
    setFundManagers(_fundManagers);
    const _onChainFundManagers = _fundManagers.filter(
      (fundManager) => fundManager.status === 'ONCHAIN'
    );
    setOnChainFundManagers(_onChainFundManagers);
  };

  const fetchKycProvider = async () => {
    const _kycProvider = await getAllKycProvider();
    setKycProviders(_kycProvider);
    const _onChainKycProvider = _kycProvider.filter(
      (kycProvider) => kycProvider.status === 'ONCHAIN'
    );
    setOnChainKycProviders(_onChainKycProvider);
  };

  // const fetchCustodian = async () => {
  //   const _custodians = await getAllCustodian();
  //   setCustodian(_custodians);
  //   const _onChainCustodians = _custodians.filter((custodian) => custodian.status === 'ONCHAIN');
  //   setOnChainCustodians(_onChainCustodians);
  // };

  const fetchOriginator = async () => {
    const _originator = await getAlloriginator();
    setOriginators(_originator);
  };

  const fetchInsurer = async () => {
    const _insurers = await getAllInsurers();
    setInsurer(_insurers);
    const _onChainInsurers = _insurers.filter((insurer) => insurer.status === 'ONCHAIN');
    setOnChainInsurers(_onChainInsurers);
  };

  const fetchUser = async () => {
    const _users = await getAllUsers();
    setUsers(_users);
  };

  const fetchLiquidityPool = async () => {
    const _liquidityPool = await getAllLiquidityPools();
    setLiquidityPools(_liquidityPool);
    const _onChainLiquidityPool = _liquidityPool.filter(
      (liquidityPool) => liquidityPool.status === 'ONCHAIN'
    );
    setOnChainLiquidityPools(_onChainLiquidityPool);
  };

  const fetchSubscription = async (data) => {
    const _subscription = await getAllSubscriptionByToken(data);
    setSubscription(_subscription);
    setAllSubscriptionById(_subscription);
  };

  const fetchInvestorsByIssuer = async (issuerId) => {
    const res = await getInvestorsByIssuer(issuerId);
    setInvestorsByIssuer(res);
  };

  const setEditIssuer = (data) => {
    setCurrentIssuer(data);
  };

  const setEditFundManager = (data) => {
    setCurrentFundManager(data);
  };
  const setEditInsurer = (data) => {
    setCurrentInsurer(data);
  };

  // const setEditCustodian = (data) => {
  //   setCurrentCustodian(data);
  // };

  const setEditKycProvider = (data) => {
    setCurrentKycProvider(data);
  };

  const setEditOriginator = (data) => {
    setCurrentOriginator(data);
  };

  const setEditLiquidityPool = (data) => {
    setCurrentLiquidityPool(data);
  };

  const setEditCurrentIssuerInsurer = (data) => {
    setCurrentIssuerInsurerMap(data);
  };

  const setToken = (data) => {
    setCurrentToken(data);
    businessEntityPersistence.set('currToken', JSON.stringify(data));
  };

  const setSubsToken = (data) => {
    setCurrentSubsToken(data);
  };

  const setCurrentTokenById = (data) => {
    setTokenById(data);
    businessEntityPersistence.set('currTokenById', JSON.stringify(data));
  };

  const setAllSubscriptionById = (data) => {
    setAllSubscriptions(data);
  };

  const setCurrentWhiteListById = (data) => {
    setCurrentWhiteList(data);
    businessEntityPersistence.set('CurrentWhiteList', JSON.stringify(data));
  };

  const setPendingWhiteListById = (data) => {
    setPendingWhiteList(data);
    businessEntityPersistence.set('PendingWhiteList', JSON.stringify(data));
  };

  const setLoggedInIssuerState = (data) => {
    setLoggedInIssuer(data);
    businessEntityPersistence.set('CurrentIssuer', JSON.stringify(data));
  };

  const setAddToWhiteListAddresses = (data) => {
    setAddWhiteListAddresses(data);
  };
  const setRemoveFromWhiteListAddresses = (data) => {
    setRemoveWhiteListAddresses(data);
  };
  const setCurrentCreateAllotmentList = (data) => {
    setCreateAllotmentsList(data);
  };

  const fetchWhiteList = async () => {
    const response = await getAllSubscriptionByToken(currentToken.token_id);
    setCurrentIssuer(currentToken.issuer_id);
    // setToken(currentToken);
    setAllSubscriptionById(response);
    const _pendingWhiteList = response.filter((val) => val.is_pending);
    setPendingWhiteListById(_pendingWhiteList);
    const _currentWhiteList = response.filter((val) => !val.is_pending);
    setCurrentWhiteListById(_currentWhiteList);
  };
  const fetchWhiteListSubscriptions = async () => {
    const response = await getAllWhitelistedSubscription(currentToken.token_id);
    setWhitelistedSubscriptions(response);
  };
  const fetchAllAllotments = async () => {
    const response = await getAllAllotments(currentToken.token_id);
    setAllAllotments(response);
  };
  const fetchTokenSummaryData = async () => {
    const response = await getTokenSummaryDataByTokenId(currentToken.token_id);
    setTokenSummary(response);
  };
  const fetchAllReddemedAllotmentsByToken = async () => {
    const response = await getAllRedeemedAllotmentsByToken(currentToken.token_id);
    setRedeemedToken(response);
  };
  const setCurrentRedeemAllotmentList = (data) => {
    setRedeemAllotmentsList(data);
  };
  const fetchIssuanceEscrowByToken = async () => {
    const response = await getIssuanceEscrowByToken(currentToken.token_id);
    setIssuanceEscrowOrders(response);
  };

  const fetchAllIssuanceEscrowByInvestorAddress = async (addr) => {
    const response = await getAllIssuanceEscrowByAddress(addr);
    setInvestorIssuanceEscrow(response);
  };

  const fetchAllRedemptionEscrowByInvestorAddress = async (addr) => {
    const response = await getAllRedemptionEscrowByAddress(addr);
    setInvestorRedemptionEscrow(response);
  };

  const fetchAllRedemptionEscrow = async () => {
    const res = await getAllRedemptionEscrowByTokenId(currentToken.token_id);
    setIssuerRedemptionEscrow(res);
  };

  const fetchIssuerInsurerMappingByIssuerId = async (id) => {
    const res = await getIssuerInsurerMappingByIssuerId(id);
    setIssuerInvestorMapByIssuerId(res);
  };

  const fetchIssuerInsurerMappingByInsurerId = async (id) => {
    const res = await getIssuerInsurerMappingByInsurerId(id);
    setIssuerInvestorMapByInsurerId(res);
  };

  const fetchAllIssuerInsurerMapping = async () => {
    const res = await getAllIssuerInsurerMapping();
    setAllIssuerInvestorMap(res);
  };

  const fetchLoggedInIssuer = () => {
    console.log('user parent xinfin account', user.parent_xinfin_account.xinfin_account_address);
    const _issuer = onChainIssuers.filter(
      (issuer) =>
        issuer.primary_issuer_xinfin_address === user.parent_xinfin_account.xinfin_account_address
    );
    console.log(_issuer);
    const _currentIssuer = _issuer[0];
    setLoggedInIssuerState(_currentIssuer);
  };
  const fetchLoggedInInsurer = () => {
    console.log('user parent xinfin account', user.parent_xinfin_account.xinfin_account_address);
    console.log({ onChainInsurers });
    const _insurer = onChainInsurers.filter(
      (insurer) =>
        insurer.primary_insurer_xinfin_address === user.parent_xinfin_account.xinfin_account_address
    );
    console.log(_insurer);
    const _currentInsurer = _insurer[0];
    setLoggedInInsurer(_currentInsurer);
  };
  const loadTokenState = async () => {
    loadCurrentToken();

    // await fetchTokens();
    await fetchSubscription();
  };

  const fetchRuleTemplates = async () => {
    const res = await getAllRuleTemplates();
    setRuleTemplates(res);
  };

  // payment Transaction
  const fetchPaymentTransaction = async () => {
    const res = await getAllPaymentTransaction();
    setPaymentTransactionList(res);
  };

  const fetchPaymentTransactionByTokenId = async (tokenId) => {
    const res = await getPaymentTransactionByTokenId(tokenId);
    setPaymentTransactionByTokenIdList(res);
  };

  const fetchPaymentTransactionByXinfin = async (addr) => {
    const res = await getPaymentTransactionByXinfinAddress(addr);
    setPaymentTransactionByXinfinList(res);
  };

  const setLoggedInPoolDetail = () => {
    setLoggedInPool({
      pool_owner: entity.liquidity_pool_owner_xinfin_address,
      pool_address: entity.liquidity_pool_xinfin_address,
      pool_settlement_address: entity.liquidity_pool_settlement_address
    });
  };
  const fetchTokenNavHistoryPool = async (id) => {
    const res = await getTokenNavPool(id);

    if (res) {
      setTokenNavHistoryPool(res);
    }
  };

  const fetchAllKycProviders = async () => {
    const _kycProviders = await getAllKycProvider();
    if (_kycProviders) {
      setKycProviders(_kycProviders);
    }
  };
  const fetchPoolToken = async (id) => {
    const res = await getPoolProxyToken(id);
    console.log('🚀 ~ file: useBusinessEntityState.js ~ line 610 ~ fetchPoolToken ~ res', res);
    if (res) {
      setPoolProxyToken(res[0]);
    }
  };
  const fetchPoolHoldings = async (id) => {
    const res = await getPoolHoldings(id);
    console.log('🚀 ~ file: useBusinessEntityState.js ~ line 619 ~ fetchPoolHoldings ~ res', res);
    if (res) {
      setPoolHoldings(res);
    }
  };

  const loadBusinessEntityState = async () => {
    await fetchOriginator();
    await fetchIssuer();
    await fetchKycProvider();
    await fetchFundManagers();
    // await fetchCustodian();
    await fetchInsurer();
    await fetchUser();
    await fetchLiquidityPool();
    await fetchAllInvestors();
  };

  const _clearState = () => {
    setCurrentToken(null);
    setCurrentIssuer(null);
    // setCurrentCustodian(null);
    setCurrentKycProvider(null);
    setCurrentOriginator(null);
    setCurrentInsurer(null);
    setCurrentSubsToken(null);
    setCurrentIssuerInsurerMap(null);
    setTokenById(null);
    setAllSubscriptions([]);
    setCurrentWhiteList([]);
    setPendingWhiteList([]);
    setAddWhiteListAddresses([]);
    setRemoveWhiteListAddresses([]);
    setCreateAllotmentsList([]);
    setAllAllotments([]);
    setRedeemAllotmentsList([]);
    setAllInvestors([]);
  };
  useEffect(() => {
    loadTokenState();
    loadBusinessEntityState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isAuthorized) {
      loadTokenState();
    } else {
      _clearState();
    }
    loadCurrentToken();
    loadCurrentCrediter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthorized]);

  // useEffect(() => {
  //   console.log('useEffect Count');
  //   if (role === 'LIQUIDITY_POOL_OWNER') {
  //     setLoggedInPoolDetail();
  //   }
  // }, [entity]);

  return {
    fetchAllKycProviders,
    fetchPoolDetails,
    poolDetails,
    fetchPoolAssetDetails,
    poolAssetDetails,
    id,
    loadBusinessEntityState,
    issuers,
    allInvestors,
    fetchAllInvestors,
    onChainIssuers,
    currentIssuer,
    fetchIssuer,
    currentFundManager,
    fetchFundManagers,
    fundManagers,
    setEditFundManager,
    tokens,
    fetchTokens,
    // custodians,
    insurers,
    fetchInsurer,
    setEditInsurer,
    onChainCustodians,
    onChainInsurers,
    // currentCustodian,
    currentInsurer,
    currentIssuerInsurerMap,
    setEditCurrentIssuerInsurer,
    // fetchCustodian,
    setEditIssuer,
    // setEditCustodian,
    kycProviders,
    onChainKycProviders,
    fetchKycProvider,
    currentKycProvider,
    setEditKycProvider,
    users,
    fetchUser,
    originators,
    currentOriginator,
    fetchOriginator,
    setEditOriginator,
    currentToken,
    setToken,
    currentSubsToken,
    setSubsToken,
    tokenById,
    setCurrentTokenById,
    fetchSubscription,
    allSubscriptions,
    setAllSubscriptionById,
    currentWhiteList,
    setCurrentWhiteListById,
    pendingWhiteList,
    setPendingWhiteListById,
    addWhiteListAddresses,
    setAddToWhiteListAddresses,
    fetchWhiteList,
    removeWhiteListAddresses,
    setRemoveFromWhiteListAddresses,
    whitelistedSubscriptions,
    fetchWhiteListSubscriptions,
    createAllotmentsList,
    setCurrentCreateAllotmentList,
    fetchCurrentToken,
    allAllotments,
    fetchAllAllotments,
    setCurrentRedeemAllotmentList,
    redeemAllotmentsList,
    issuanceEscrowOrders,
    fetchIssuanceEscrowByToken,
    fetchAllIssuanceEscrowByInvestorAddress,
    investorIssuanceEscrow,
    fetchAllRedemptionEscrowByInvestorAddress,
    investorRedemptionEscrow,
    issuerRedemptionEscrow,
    fetchAllRedemptionEscrow,
    fetchLoggedInIssuer,
    loggedInIssuer,
    reedemedTokens,
    fetchAllReddemedAllotmentsByToken,
    allXinfinAccounts,
    fetchAllXinfinAccounts,
    allSubscribersbyKycProvider,
    fetchAllSubscribersByKycProvider,
    currentKycInvestor,
    setKycInvestor,
    investorsByIssuer,
    fetchInvestorsByIssuer,
    issuerInvestorMapByIssuerId,
    fetchIssuerInsurerMappingByIssuerId,
    allIssuerInvestorMap,
    fetchAllIssuerInsurerMapping,
    fetchRuleTemplates,
    ruleTemplates,
    setKycIssuer,
    currentKycIssuer,
    currentKycKycProvider,
    setKycKycProvider,
    fetchPaymentTransaction,
    paymentTransactionList,
    fetchPaymentTransactionByXinfin,
    fetchPaymentTransactionByTokenId,
    paymentTransactionByTokenIdList,
    paymentTransactionByXinfinList,
    fetchCurrentTokenRuleDetails,
    currentTokenRuleDetails,
    setCurrentTokenRuleDetails,
    fetchIssuerInsurerMappingByInsurerId,
    issuerInvestorMapByInsurerId,
    loggedInInsurer,
    fetchLoggedInInsurer,
    insurerIssuerTokens,
    fetchInsurerIssuerTokens,
    setCurrentCreditIssuer,
    setCurrentCreditInsurer,
    currCreditIssuer,
    currCreditInsurer,
    insurerIssuerIssuanceEscrowOrder,
    insurerIssuerRedemptionEscrowOrder,
    insurerIssuerAllotments,
    insurerIssuerAllRedemptions,
    fetchIssuanceEscrowOrderByInsurerIssuerId,
    fetchRedemptionEscrowOrderByInsurerIssuerId,
    fetchAllotmentsByInsurerIssuerId,
    fetchAllRedemptionsByInsurerIssuerId,
    cryptoAssetTxn,
    fetchCryptoAssetTxn,
    fetchCryptoAssetTxnBySenderXinfin,
    fetchCryptoAssetTxnByIssuerInsurerXinfin,
    cryptoAssetTxnBySenderXinfin,
    cryptoAssetTxnByIssuerInsurerXinfin,
    blockchainTransactions,
    fetchBlockchainTransactions,
    collateralTransactions,
    fetchAllCollateralTransactions,
    currentDocumentPageInvestor,
    setDocumentPageInvestor,
    currentDocumentPageIssuer,
    setDocumentPageIssuer,
    currentDocumentPageDocuments,
    setDocumentPageDocuments,
    currentDocumentPageRequiredDocuments,
    setDocumentPageRequiredDocuments,
    setSubscriptionId,
    currentSubscriptionId,
    fetchIssueTypes,
    issueTypes,
    fetchAllDocumentTypes,
    allDocumentTypes,
    fetchAllDocumentSubTypes,
    allDocumentSubTypes,
    fetchAllTokenLifecycleDocumentTypeMappingsByIssueType,
    allTokenLifecycleDocumentTypeMappingsByIssueType,
    liquidityPools,
    onChainLiquidityPools,
    currentLiquidityPool,
    fetchLiquidityPool,
    setEditLiquidityPool,
    loggedInPool,
    fetchTokenNavHistoryPool,
    tokenNavHistoryPool,
    poolProxyToken,
    fetchPoolToken,
    fetchPoolHoldings,
    poolHoldings,

    pendingDocsCount,
    fetchPendingDocCount,
    pendingKYCCount,
    fetchPendingKYCCount,
    kycStatus,
    setKycStatus,
    fetchTokenSummaryData,
    tokenSummary
  };
};

export const [BusinessEntityProvider, useBusinessEntityState] = constate(useBusinessEntityState_);
