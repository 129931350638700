import React, { useCallback, useEffect, useMemo, useState } from 'react';
import EnhancedTable from 'components/tables/EnhancedTable';
import {
  Box,
  IconButton,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  Alert,
  AlertTitle
} from '@mui/material';

import Delete from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useCoreTableState } from 'state/useCoreTableState';
import { useBusinessEntityState } from 'state/useBusinessEntityState';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { ethToXdcAddress, xdcToEthAddress } from 'helpers/web3';
import Web3 from 'web3';
import { useNavigate } from 'react-router';
import { useAuthStateShared, useHttpApi, useWeb3, useAppState } from 'state';
import { useSnackbar } from 'notistack';
import { HeaderName } from 'helpers/tableHelper';
import { LoadingButton } from '@mui/lab';
import TransactionHandler from 'components/TransactionHandler';
import CopyAddress from 'components/CopyAddress';
import { useIsOwner } from 'helpers/rbac';

const KycProviderName = HeaderName('KYC Provider Name');
const CountryCode = HeaderName('Country Code');

const ActionButtons = ({
  kycProviderId,
  // kycProviderStatus,
  // kycProviderXInfin,
  kycProviderName
}) => {
  const { fetchKycProvider, setEditKycProvider } = useBusinessEntityState();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { throwErrorMessage } = useAppState();
  const { getKycProviderById, deleteKycProvider } = useHttpApi();
  const navigate = useNavigate();
  const { removeKycProvider: removeKycProviderBlockchain } = useWeb3();
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const [loadingState, setLoadingState] = useState(false);
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          m: 0,
          p: 0,
          alignItems: 'center'
        }}
      >
        <IconButton
          aria-label="subs detail"
          onClick={async () => {
            const response = await getKycProviderById(kycProviderId);
            console.log('ressss', response);
            // response.primary_kycprovider_xinfin_address = ethToXdcAddress(
            //   response.primary_kycprovider_xinfin_address
            // );
            setEditKycProvider(response);
            navigate('/masterdata/kyc-provider/edit-kyc-provider');
            console.log('edit issuer id', kycProviderId);
          }}
        >
          <EditIcon sx={{ fontSize: '2.188rem', m: 0, p: 1, backgroundColor: '#F5F8FA' }} />
        </IconButton>

        {/* <IconButton aria-label="subs detail" onClick={handleOpen}>
          <Delete sx={{ fontSize: '2.188rem', p: 1, backgroundColor: '#F5F8FA' }} />
        </IconButton> */}
      </Box>
      {/* <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title">
        <Alert severity="warning">
          <AlertTitle>Delete KYC Provider</AlertTitle>
          <DialogContent>
            Are you sure you want to delete KYC Provider, {kycProviderName}?
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" color="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <LoadingButton
              variant="outlined"
              color="error"
              loadingPosition="start"
              sx={{ width: '6.7rem' }}
              loading={loadingState}
              onClick={async () => {
                setLoadingState(true);
                console.log('delete issuer id', kycProviderId, kycProviderStatus);
                if (kycProviderStatus === 'ONCHAIN') {
                  try {
                    const res = await removeKycProviderBlockchain(
                      xdcToEthAddress(kycProviderXInfin)
                    );

                    if (res) {
                      enqueueSnackbar('KYC Provider is submitted to Delete', {
                        variant: 'info'
                      });
                      fetchKycProvider();
                    }
                  } catch (e) {
                    throwErrorMessage(e);
                  }
                } else {
                  await deleteKycProvider(kycProviderId);
                  enqueueSnackbar('KYC Provider deleted successfully', {
                    variant: 'success'
                  });
                  fetchKycProvider();
                }
                setLoadingState(false);
                handleClose();
              }}
            >
              Delete
            </LoadingButton>
          </DialogActions>
        </Alert>
      </Dialog> */}
    </>
  );
};

const KycProviderChainStatus = ({ status, countryCode, address }) => {
  const { addKycProvider } = useWeb3();
  const commitTransaction = useCallback(
    (hash) => {
      return addKycProvider(countryCode, address, false, hash);
    },
    [addKycProvider, countryCode, address]
  );

  const { fetchKycProvider } = useBusinessEntityState();

  const onConfirmed = useCallback(() => {
    fetchKycProvider();
  }, [fetchKycProvider]);

  return (
    <TransactionHandler
      id={`add-kycprovider-${address}`}
      commitTransaction={commitTransaction}
      done={status === 'ONCHAIN'}
      onConfirmed={onConfirmed}
    >
      {({ status: txStatus, error, receipt, send }) => {
        console.log('TransactionHandler', txStatus, error && error.message, receipt);
        return (
          <Chip
            // onClick={status==="OFFCHAIN"?send:null}
            label={status}
            color={status === 'OFFCHAIN' ? 'warning' : 'success'}
          />
        );
      }}
    </TransactionHandler>
  );
};

export default function KycProviderTable() {
  const { kycProviders, fetchKycProvider } = useBusinessEntityState();
  console.log(kycProviders);
  // const [data, setData] = useState(kycProviders);
  // const [owner, setOwner] = useState(false);

  // const [skipPageReset, setSkipPageReset] = React.useState(false);
  // const { role } = useAuthStateShared();

  const isOwner = useIsOwner();

  const headCells = useMemo(
    () => [
      {
        accessor: 'kyc_provider_name',
        Header: KycProviderName,
        show: true
      },
      // {
      //   accessor: 'lei',
      //   Header: 'LEI',
      //   show: true
      // },

      // {
      //   accessor: 'primary_kycprovider_xinfin_address',
      //   Header: 'Xinfin Address',
      //   width: 200,
      //   Cell: ({ value }) => {
      //     return <CopyAddress addr={ethToXdcAddress(value)} />;
      //   },
      //   show: true
      // },
      // {
      //   accessor: 'kyc_provider_country_code.country_code',
      //   Header: CountryCode,
      //   show: true
      // },
      // {
      //   accessor: 'status',
      //   Header: 'Status',
      //   Cell: ({ row: { original } }) => {
      //     return (
      //       <KycProviderChainStatus
      //         status={original.status}
      //         countryCode={original.kyc_provider_country_code.country_code}
      //         address={original.primary_kycprovider_xinfin_address}
      //       />
      //     );
      //   },
      //   show: true
      // },

      {
        accessor: 'actions',
        Header: 'Actions',
        Cell: ({ row: { original } }) => {
          return (
            <ActionButtons
              kycProviderId={original.kyc_provider_id}
              kycProviderName={original.kyc_provider_name}
              // kycProviderStatus={original.status}
              // kycProviderXInfin={original.primary_kycprovider_xinfin_address}
            />
          );
        },
        show: isOwner
      }
    ],
    [isOwner]
  );

  // useEffect(() => {
  //   fetchKycProvider();
  // }, []);

  // useEffect(() => {
  //   if (role === 'OWNER') {
  //     setOwner(true);
  //   }
  // }, [role]);

  // useEffect(() => {
  // const _kycProviders = kycProviders.map((KycProvider) => {
  //   KycProvider.xinfin_address = (
  //     <CopyAddress addr={ethToXdcAddress(KycProvider.primary_kycprovider_xinfin_address)} />
  //   );

  // KycProvider.kycprovider_status = (
  //   <KycProviderChainStatus
  //     status={KycProvider.status}
  //     countryCode={KycProvider.kyc_provider_country_code.country_code}
  //     address={KycProvider.primary_kycprovider_xinfin_address}
  //   />
  // );

  //     KycProvider.actions = (
  //       <ActionButtons
  //         kycProviderId={KycProvider.kyc_provider_id}
  //         kycProviderStatus={KycProvider.status}
  //         kycProviderXInfin={KycProvider.primary_kycprovider_xinfin_address}
  //         kycProviderName={KycProvider.kyc_provider_name}
  //       />
  //     );

  //     return KycProvider;
  //   });
  //   setData(_kycProviders);
  // }, [kycProviders]);
  // We need to keep the table from resetting the pageIndex when we
  // Update data. So we can keep track of that flag with a ref.

  // When our cell renderer calls updateMyData, we'll use
  // the rowIndex, columnId and new value to update the
  // original data
  // const updateMyData = (rowIndex, columnId, value) => {
  //   // We also turn on the flag to not reset the page
  //   setSkipPageReset(true);
  //   setData((old) =>
  //     old.map((row, index) => {
  //       if (index === rowIndex) {
  //         return {
  //           ...old[rowIndex],
  //           [columnId]: value
  //         };
  //       }
  //       return row;
  //     })
  //   );
  // };

  return (
    <>
      <EnhancedTable
        tableTitle="KYC Provider"
        refreshFunction={fetchKycProvider}
        columns={headCells}
        data={kycProviders}
        // setData={setData}
        // updateMyData={updateMyData}
        // skipPageReset={skipPageReset}
      />
    </>
  );
}
