import Page from 'components/Page';
import React from 'react';
import { Card, Container, CardContent } from '@mui/material';
import Breadcrumbs from 'components/Breadcrumbs';
import OriginatorTable from 'components/masterData/Originator/OriginatorTable';

const AllOriginator = () => {
  return (
    <Page title="Originator - Master Data">
      <Container>
        <Breadcrumbs pageHead="All Originators" />

        <Card sx={{ mt: 2 }}>
          <CardContent>
            <OriginatorTable />
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
};

export default AllOriginator;
