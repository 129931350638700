import {
  Container,
  Card,
  Table,
  TableCell,
  TableBody,
  Box,
  Chip,
  TableRow,
  Button,
  TableHead,
  CardContent
} from '@mui/material';
import Page from 'components/Page';
import React from 'react';
import Breadcrumbs from 'components/Breadcrumbs';
import KYCTable from 'components/kyc_provider/KYCSubscriberTable';
import { useBusinessEntityState, useWeb3, useAuthStateShared } from 'state';
import { useNavigate } from 'react-router';

const KYCHome = () => {
  const navigate = useNavigate();
  const { currentToken: token } = useBusinessEntityState();
  return (
    <>
      <Page title="KYC Dashboard">
        <Container sx={{ mt: 3 }}>
          <Breadcrumbs aria-label="breadcrumb" pageHead="KYC Dashboard" />
          <Box sx={{ width: '100%', typography: 'body1', mt: 3 }}>
            <Card sx={{ p: 2 }}>
              <KYCTable />
            </Card>
          </Box>
        </Container>
      </Page>
    </>
  );
};

export default KYCHome;
