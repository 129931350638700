import React from 'react';
import { PieChart, Pie, Sector, Cell, Legend, Tooltip } from 'recharts';
import { Typography, Box, Card } from '@mui/material';

export default function PieChartData({ data }) {
  // const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#000000', '#6f4e7c', '#8dddd0'];

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const total = data.reduce((acc, current) => acc + current.value, 0);
      const percent = ((payload[0].value / total) * 100).toFixed(2);
      return (
        <Card
          sx={{
            backgroundColor: '#ffffff',
            p: 1,
            borderRadius: 2
          }}
        >
          <Typography variant="h6">{`${payload[0].name} : ${percent} %`}</Typography>
        </Card>
      );
    }

    return null;
  };

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(2)}%`}
      </text>
    );
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <PieChart width={400} height={400}>
        <Pie
          data={data}
          dataKey="value"
          nameKey="name"
          cx="50%"
          cy="50%"
          fill="#8884d8"
          labelLine={false}
          label={renderCustomizedLabel}
        >
          {data.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={entry.address === 'Available' ? '#b2b2b2' : `#${entry.address.slice(-6)}`}
            />
          ))}
        </Pie>
        <Tooltip content={<CustomTooltip />} />
        {/* <Legend layout="vertical" align="right" verticalAlign="middle" /> */}
      </PieChart>
    </Box>
  );
}
