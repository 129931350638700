import Page from 'components/Page';
import React from 'react';
import { Card, Container } from '@mui/material';
import Breadcrumbs from 'components/Breadcrumbs';
import { CardContent } from '@material-ui/core';
import IssuerWhiteListTable from 'components/masterData/Issuer/IssuerWhiteListTable';
import InvestorTable from 'components/masterData/Investor/InvestorTable';
import { useAuthStateShared } from 'state';

const IssuerWhitelist = () => {
  const { role } = useAuthStateShared();

  return (
    <Page title="All Investors">
      <Container>
        <Breadcrumbs pageHead="All Investors" />

        <Card>
          <CardContent>
            {role === 'OWNER' ? (
              <>
                <InvestorTable />
              </>
            ) : (
              <>
                <IssuerWhiteListTable />
              </>
            )}
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
};

export default IssuerWhitelist;
