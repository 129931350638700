import React, { useCallback, useEffect, useMemo, useState } from 'react';
import EnhancedTable from 'components/tables/EnhancedTable';
import {
  Box,
  IconButton,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  Alert,
  AlertTitle
} from '@mui/material';
import Delete from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useCoreTableState } from 'state/useCoreTableState';
import { useBusinessEntityState } from 'state/useBusinessEntityState';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { ethToXdcAddress, xdcToEthAddress } from 'helpers/web3';
import Web3 from 'web3';
import { useNavigate } from 'react-router';
import { useAppState, useAuthStateShared, useHttpApi, useWeb3 } from 'state';
import { useSnackbar } from 'notistack';
import { HeaderName } from 'helpers/tableHelper';
import { LoadingButton } from '@mui/lab';
import TransactionHandler from 'components/TransactionHandler';
import CopyAddress from 'components/CopyAddress';
import { useIsOwner } from 'helpers/rbac';

const CustodianName = HeaderName('Custodian Name');
const CountryCode = HeaderName('Country Code');

// TODO: Delete

const ActionButtons = ({ custodianId, custodianStatus, custodianXInfin, custodianName }) => {
  const { fetchCustodian, setEditCustodian } = useBusinessEntityState();
  const { getCustodianById, deleteCustodian } = useHttpApi();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { removeCustodian: removeCustodianBlockchain } = useWeb3();
  const [open, setOpen] = useState(false);
  const { throwErrorMessage } = useAppState();
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const [loadingState, setLoadingState] = useState(false);
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          m: 0,
          p: 0,
          alignItems: 'center'
        }}
      >
        <IconButton
          aria-label="subs detail"
          onClick={async () => {
            // setBusinessEntity(issuerId);
            const response = await getCustodianById(custodianId);
            console.log('ressss', response);
            response.primary_custodian_xinfin_address = ethToXdcAddress(
              response.primary_custodian_xinfin_address
            );
            setEditCustodian(response);
            navigate('/masterdata/custodian/edit-custodian');
            console.log('edit custodian id', custodianId);
          }}
        >
          <EditIcon sx={{ fontSize: '2.188rem', m: 0, p: 1, backgroundColor: '#F5F8FA' }} />
        </IconButton>
        <IconButton aria-label="subs detail" onClick={handleOpen}>
          <Delete sx={{ fontSize: '2.188rem', p: 1, backgroundColor: '#F5F8FA' }} />
        </IconButton>
      </Box>
      <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title">
        <Alert severity="warning">
          <AlertTitle>Delete Custodian</AlertTitle>
          <DialogContent>Are you sure you want to delete Custodian, {custodianName}?</DialogContent>
          <DialogActions>
            <Button variant="outlined" color="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <LoadingButton
              variant="outlined"
              color="error"
              loadingPosition="start"
              sx={{ width: '6.7rem' }}
              loading={loadingState}
              onClick={async () => {
                setLoadingState(true);
                console.log('delete custodian id', custodianId, custodianStatus);
                if (custodianStatus === 'ONCHAIN') {
                  try {
                    const res = await removeCustodianBlockchain(xdcToEthAddress(custodianXInfin));

                    if (res) {
                      console.log('deleted');
                      enqueueSnackbar('Custodian is submitted to Delete', {
                        variant: 'info'
                      });

                      fetchCustodian();
                    }
                  } catch (e) {
                    throwErrorMessage(e);
                  }
                } else {
                  await deleteCustodian(custodianId);

                  fetchCustodian();
                  enqueueSnackbar('Custodian deleted successfully', {
                    variant: 'error'
                  });
                }
                setLoadingState(false);
                handleClose();
              }}
            >
              Delete
            </LoadingButton>
          </DialogActions>
        </Alert>
      </Dialog>
    </>
  );
};

const CustodianChainStatus = ({ status, countryCode, address }) => {
  const { addCustodian } = useWeb3();

  const commitTransaction = useCallback(
    (hash) => {
      return addCustodian(countryCode, address, false, hash);
    },
    [addCustodian, countryCode, address]
  );

  const { fetchCustodian } = useBusinessEntityState();

  const onConfirmed = useCallback(() => {
    fetchCustodian();
  }, [fetchCustodian]);

  return (
    <TransactionHandler
      id={`add-custodian-${address}`}
      commitTransaction={commitTransaction}
      done={status === 'ONCHAIN'}
      onConfirmed={onConfirmed}
    >
      {({ status: txStatus, error, receipt, send }) => {
        console.log('TransactionHandler', txStatus, error && error.message, receipt);

        return (
          <Chip
            // onClick={status==="OFFCHAIN"?send:null}
            label={status}
            color={status === 'OFFCHAIN' ? 'warning' : 'success'}
          />
        );
      }}
    </TransactionHandler>
  );
};

export default function CustodianTable() {
  const { fetchCustodian, custodians } = useBusinessEntityState();
  console.log(custodians);
  // const [data, setData] = useState(custodians);
  // const [skipPageReset, setSkipPageReset] = React.useState(false);
  // const [owner, setOwner] = useState(false);
  // const { role } = useAuthStateShared();

  const isOwner = useIsOwner();

  const headCells = useMemo(
    () => [
      {
        accessor: 'custodian_name',
        Header: CustodianName,
        show: true
      },
      {
        accessor: 'lei',
        Header: 'LEI',
        show: true
      },
      {
        accessor: 'license_No',
        Header: 'License No',
        show: true
      },
      {
        accessor: 'primary_custodian_xinfin_address',
        Header: 'Xinfin Address',
        width: 250,
        Cell: ({ value }) => <CopyAddress addr={ethToXdcAddress(value)} />,
        show: true
      },
      {
        accessor: 'custodian_country_code.country_code',
        Header: CountryCode,
        show: true
      },
      {
        accessor: 'status',
        Header: 'Status',
        Cell: ({ row: { original } }) => (
          <CustodianChainStatus
            status={original.status}
            countryCode={original.custodian_country_code.country_code}
            address={original.primary_custodian_xinfin_address}
          />
        ),
        show: true
      },

      {
        accessor: 'actions',
        Header: 'Actions',
        Cell: ({ row: { original } }) => (
          <ActionButtons
            custodianId={original.custodian_id}
            custodianStatus={original.status}
            custodianXInfin={original.primary_custodian_xinfin_address}
            custodianName={original.custodian_name}
          />
        ),
        show: isOwner
      }
    ],
    [isOwner]
  );

  // useEffect(() => {
  //   fetchCustodian();
  // }, []);

  // useEffect(() => {
  //   if (role === 'OWNER') {
  //     setOwner(true);
  //   }
  // }, [role]);

  // useEffect(() => {
  //   const _custodians = custodians.map((custodian) => {
  // custodian.xinfin_address = (
  //   <CopyAddress addr={ethToXdcAddress(custodian.primary_custodian_xinfin_address)} />
  // );

  // custodian.custodian_status = (
  //   <CustodianChainStatus
  //     status={custodian.status}
  //     CountryCode={custodian.custodian_country_code.country_code}
  //     address={custodian.primary_custodian_xinfin_address}
  //   />
  // );

  //     custodian.actions = (
  //       <ActionButtons
  //         custodianId={custodian.custodian_id}
  //         custodianStatus={custodian.status}
  //         custodianXInfin={custodian.primary_custodian_xinfin_address}
  //         custodianName={custodian.custodian_name}
  //       />
  //     );
  //     return custodian;
  //   });

  //   setData(_custodians);
  // }, [custodians]);
  // We need to keep the table from resetting the pageIndex when we
  // Update data. So we can keep track of that flag with a ref.

  // When our cell renderer calls updateMyData, we'll use
  // the rowIndex, columnId and new value to update the
  // original data
  // const updateMyData = (rowIndex, columnId, value) => {
  //   // We also turn on the flag to not reset the page
  //   setSkipPageReset(true);
  //   setData((old) =>
  //     old.map((row, index) => {
  //       if (index === rowIndex) {
  //         return {
  //           ...old[rowIndex],
  //           [columnId]: value
  //         };
  //       }
  //       return row;
  //     })
  //   );
  // };

  return (
    <>
      <EnhancedTable
        refreshFunction={fetchCustodian}
        tableTitle="Custodian"
        columns={headCells}
        data={custodians}
        // setData={setData}
        // updateMyData={updateMyData}
        // skipPageReset={skipPageReset}
      />
    </>
  );
}
