import {
  styled,
  Typography,
  Stack,
  TextField,
  Box,
  Grid,
  FormLabel,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Alert,
  Link,
  AlertTitle
} from '@mui/material';
import React, { useState } from 'react';
import Page from '../components/Page';
import loginImage from '../assets/images/loginimage.png';
import { useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { useAuthState, useSubscriptionState, useAppState } from 'state';
import XinfinLogin from 'components/XinfinLogin';
import * as Yup from 'yup';

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    background: '#fff',
    height: '100vh'
  }
}));

const RequestForgotPassword = () => {
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const { forgotPassword } = useAuthState();
  const { orgLogo } = useSubscriptionState();
  const { throwErrorMessage } = useAppState();
  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string().email('Invalid Email Format').required('Email is required')
  });
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const formik = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: ForgotPasswordSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        setError(null);
        await forgotPassword(values);
        enqueueSnackbar('Email Sent Successfully', { variant: 'success' });
        handleOpen();
      } catch (e) {
        const errorMessage = await throwErrorMessage(e);
        setError(errorMessage);
      }
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;
  return (
    <RootStyle title="Forgot Password">
      <Grid container>
        <Grid item lg={6}>
          <Box
            component="img"
            sx={{ height: '90vh', marginTop: '10vh' }}
            src={loginImage}
            alt="login "
          />
          <Typography
            variant="h4"
            sx={{
              marginTop: '-10vh',
              marginLeft: '10vh',
              color: '#fff'
            }}
          >
            Taylored Digital Assets
          </Typography>
        </Grid>
        <Grid alignSelf="center" sx={{ mx: 'auto' }} item lg={4}>
          {/* </Box> */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              mb: '2rem'
            }}
          >
            <Box component="img" src={orgLogo} sx={{ height: '3.125rem', width: 'auto' }} />
          </Box>
          <XinfinLogin />
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Typography sx={{ fontWeight: 600, mb: 2, fontSize: '1.25rem', textAlign: 'center' }}>
                Reset Password of your Account
              </Typography>
              {error && (
                <Box mb={4}>
                  <Alert severity="error">{error}</Alert>
                </Box>
              )}
              <Typography sx={{ mb: 4, fontSize: '1rem', color: '#8e8ea7' }}>
                Enter Email associated with your account and we'll send you an email with
                instructions to reset your password.
              </Typography>
              <Stack spacing={3}>
                <FormLabel>Email Address</FormLabel>
                <TextField
                  fullWidth
                  autoComplete="username"
                  type="email"
                  {...getFieldProps('email')}
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                  placeholder="Enter Email address"
                />
              </Stack>
              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="gradient"
                loadingPosition="start"
                loading={isSubmitting}
                sx={{ mt: 4 }}
              >
                Submit
              </LoadingButton>
            </Form>
          </FormikProvider>
          <Box
            sx={{
              mx: 'auto',
              p: 1,
              textAlign: 'center',
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <Button
              onClick={() => {
                navigate('/login');
              }}
            >
              Login Page
            </Button>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              sx={{ textAlign: 'center' }}
            >
              <Alert severity="success">
                <AlertTitle sx={{ fontWeight: 600, fontSize: '1.2rem' }}>
                  Email Sent Successfully
                </AlertTitle>
              </Alert>
              <DialogContent sx={{ fontWeight: 500, fontSize: '1rem' }}>
                Check your mail, we have sent a password recover link to your email.
              </DialogContent>
              <DialogContent>
                <Button
                  variant="gradient"
                  color="secondary"
                  sx={{ textAlign: 'center', width: '10rem' }}
                  onClick={() => {
                    setOpen(false);
                    navigate('/login');
                  }}
                >
                  Login Page
                </Button>
              </DialogContent>
              <DialogContent sx={{ fontSize: '0.8rem', color: '#8e8ea7', mt: 2 }}>
                Did not receive the email? Check your spam folder or
                <Link
                  sx={{ fontSize: '0.82rem', cursor: 'pointer', ml: '0.1875rem' }}
                  onClick={handleClose}
                  underline="hover"
                >
                  entered email address is correct.
                </Link>
              </DialogContent>
            </Dialog>
          </Box>
        </Grid>
      </Grid>
    </RootStyle>
  );
};

export default RequestForgotPassword;
