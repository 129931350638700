import { Alert, AlertTitle, Box } from '@mui/material';

import RequireNetwork from 'components/RequireNetwork';
import Overlay from 'components/Overlay';
import { currentNetwork, getNetworkName } from 'helpers/web3';
import { useWeb3 } from 'state';

const RequireNetworkOverlay = () => {
  const { name: targetNetworkName } = currentNetwork;
  const { chainId } = useWeb3();
  const selectedNetworkName = getNetworkName(chainId);

  return (
    <RequireNetwork not>
      <Overlay show />
      <Alert
        severity="warning"
        sx={{
          position: 'fixed',
          top: '30vh',
          left: 'calc(50vw - 550px / 2)',
          'z-index': 1100,
          width: '550px'
        }}
      >
        <AlertTitle>Wrong Network</AlertTitle>
        The wallet is connected to <strong>{selectedNetworkName}</strong>, please switch to{' '}
        <strong>{targetNetworkName}</strong>.
      </Alert>
    </RequireNetwork>
  );
};

export default RequireNetworkOverlay;
