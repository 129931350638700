import { Box, Container, Grid } from '@mui/material';
import Page from 'components/Page';
import PoolAssetOriginator from 'components/subscritiption/PoolAssetOriginator';
import PoolDetailsBasicDesc from 'components/subscritiption/PoolDetailsBasicDesc';
import PoolInfo from 'components/subscritiption/PoolInfo';
import PoolStatus from 'components/subscritiption/PoolStatus';
import PoolTranch from 'components/subscritiption/PoolTranch';
import React, { useEffect } from 'react';

import { useBusinessEntityState, useHttpApi } from 'state';

const PoolDetails = () => {
  const { getPoolByPoolAddress, getAssetsByPoolAddress } = useHttpApi();
  const {
    currentSubsToken,
    poolDetails,
    fetchPoolDetails,
    poolAssetDetails,
    fetchPoolAssetDetails
  } = useBusinessEntityState();
  useEffect(async () => {
    fetchPoolDetails(currentSubsToken?.liquidity_pool?.liquidity_pool_xinfin_address);
    fetchPoolAssetDetails(currentSubsToken?.liquidity_pool?.liquidity_pool_xinfin_address);
  }, [currentSubsToken]);

  return (
    <Page title="Pool Details">
      {/* TODO: passing props */}
      <PoolDetailsBasicDesc />
      {/* TODO: passing props */}
      <PoolInfo />
      <PoolAssetOriginator />
      <Container>
        <Grid container spacing={3}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <PoolStatus />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <PoolTranch />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default PoolDetails;
