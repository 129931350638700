import {
  Container,
  Card,
  Grid,
  CardContent,
  Divider,
  Typography,
  TextField,
  FormLabel,
  Button,
  Box,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  Alert
} from '@mui/material';

import Page from 'components/Page';
import React, { useState } from 'react';
import Breadcrumbs from 'components/Breadcrumbs';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { useCoreTableState } from 'state/useCoreTableState';

const AddCashTxn = () => {
  const { currencies } = useCoreTableState();
  const [error, setError] = useState('');

  const AddCashTransactionSchema = Yup.object().shape({
    cash_transaction_type_id: Yup.string().required('Select Cash Transaction ID'),
    transaction_currency_code: Yup.string().required('Select Transaction Currency Code'),
    transaction_amount: Yup.string().required('Enter Trasaction Amount'),
    token_id: Yup.string().required('Select Token ID'),
    back_account_id: Yup.string().required('Select Bank Account ID'),
    token_subscription_id: Yup.string().required('Select Subscription ID'),
    direction: Yup.string().required('Enter Direction')
  });

  const formik = useFormik({
    initialValues: {
      cash_transaction_type_id: '',
      transaction_currency_code: '',
      transaction_amount: '',
      token_id: '',
      back_account_id: '',
      token_subscription_id: '',
      direction: ''
    },
    validationSchema: AddCashTransactionSchema,
    onSubmit: (values) => {
      alert(JSON.stringify(values));
    }
  });
  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <>
      <Page title="Add Cash Transaction - Master Data">
        <Container sx={{ my: 2 }}>
          <Box sx={{ my: 2 }}>
            <Breadcrumbs pageHead=" Cash Transaction" aria-label="breadcrumb" />
          </Box>
          <Card>
            <Container>
              <Typography
                sx={{
                  fontSize: '1.125rem',
                  fontWeight: 'bold',
                  mt: 4,
                  mb: 1,
                  ml: 4
                }}
              >
                Add Cash Transaction
              </Typography>
              <Divider
                sx={{
                  width: '100%',
                  backgroundColor: '#f5f8fa',
                  border: '2px solid #f5f8fa'
                }}
              />
              <FormikProvider value={formik}>
                <Form autoComplete="off" onSubmit={handleSubmit}>
                  {error && (
                    <Box mb={4}>
                      <Alert
                        severity="error"
                        sx={{
                          textTransform: 'capitalize'
                        }}
                      >
                        {error}
                      </Alert>
                    </Box>
                  )}
                  <CardContent sx={{ m: 2 }}>
                    <Grid container spacing={3} sx={{ width: '100%' }}>
                      <Grid item lg={6} sm={12} md={6} xs={12} sx={{ pr: 1 }}>
                        <FormLabel>Cash Transaction Type ID</FormLabel>
                        <FormControl size="small" variant="outlined" fullWidth sx={{ mt: 1.5 }}>
                          <Select
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                            {...getFieldProps('cash_transaction_type_id')}
                            error={Boolean(
                              touched.cash_transaction_type_id && errors.cash_transaction_type_id
                            )}
                            helperText={
                              touched.cash_transaction_type_id && errors.cash_transaction_type_id
                            }
                          >
                            {/* TODO: ADD GET cash_trasaction_id */}
                            {currencies &&
                              currencies.map((currency, index) => {
                                return (
                                  <MenuItem
                                    key={currency.currency_code}
                                    value={currency.currency_code}
                                  >
                                    {currency.currency_code} | {currency.currency_name}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                          <FormHelperText sx={{ color: '#d32f2f' }}>
                            {touched.cash_transaction_type_id && errors.cash_transaction_type_id}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item lg={6} sm={12} md={6} xs={12} sx={{ pl: 0.5 }}>
                        <FormLabel>Transaction Currency Code</FormLabel>
                        <FormControl size="small" variant="outlined" fullWidth sx={{ mt: 1.5 }}>
                          <Select
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                            {...getFieldProps('transaction_currency_code')}
                            error={Boolean(
                              touched.transaction_currency_code && errors.transaction_currency_code
                            )}
                            helperText={
                              touched.transaction_currency_code && errors.transaction_currency_code
                            }
                          >
                            {currencies &&
                              currencies.map((currency, index) => {
                                return (
                                  <MenuItem
                                    key={currency.currency_code}
                                    value={currency.currency_code}
                                  >
                                    {currency.currency_code} | {currency.currency_name}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                          <FormHelperText sx={{ color: '#d32f2f' }}>
                            {touched.transaction_currency_code && errors.transaction_currency_code}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item lg={6} sm={12} md={6} xs={12}>
                        <FormLabel>
                          Transaction Amount {formik.values.transaction_currency_code}
                        </FormLabel>
                        <TextField
                          sx={{ mt: 1.5 }}
                          fullWidth
                          size="small"
                          autoComplete="off"
                          type="text"
                          // placeholder="Enter Transaction Amount"
                          {...getFieldProps('transaction_amount')}
                          error={Boolean(touched.transaction_amount && errors.transaction_amount)}
                          helperText={touched.transaction_amount && errors.transaction_amount}
                        />
                      </Grid>
                      <Grid item lg={6} sm={12} md={6} xs={12}>
                        <FormLabel>Token ID</FormLabel>
                        <FormControl size="small" variant="outlined" fullWidth sx={{ mt: 1.5 }}>
                          <Select
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                            {...getFieldProps('token_id')}
                            error={Boolean(touched.token_id && errors.token_id)}
                            helperText={touched.token_id && errors.token_id}
                          >
                            {/* TODO:ADD GET token_id */}
                            {currencies &&
                              currencies.map((currency, index) => {
                                return (
                                  <MenuItem
                                    key={currency.currency_code}
                                    value={currency.currency_code}
                                  >
                                    {currency.currency_code} | {currency.currency_name}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                          <FormHelperText sx={{ color: '#d32f2f' }}>
                            {touched.token_id && errors.token_id}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      {/* <Grid item lg={6} sm={12} md={6} xs={12}>
                        <FormLabel>Bank Account ID</FormLabel>
                        <FormControl size="small" variant="outlined" fullWidth sx={{ mt: 1.5 }}>
                          <Select
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                            {...getFieldProps('back_account_id')}
                            error={Boolean(touched.back_account_id && errors.back_account_id)}
                            helperText={touched.back_account_id && errors.back_account_id}
                          >
                            {bankAccounts &&
                              bankAccounts.map((bankAccounts, index) => {
                                return (
                                  <MenuItem
                                    key={bankAccounts.acct_num}
                                    value={bankAccounts.acct_num}
                                  >
                                    {bankAccounts.acct_num}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                          <FormHelperText sx={{ color: '#d32f2f' }}>
                            {touched.back_account_id && errors.back_account_id}
                          </FormHelperText>
                        </FormControl>
                      </Grid> */}
                      <Grid item lg={6} sm={12} md={6} xs={12}>
                        <FormLabel>Token Subscription ID</FormLabel>
                        <FormControl size="small" variant="outlined" fullWidth sx={{ mt: 1.5 }}>
                          <Select
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                            {...getFieldProps('token_subscription_id')}
                            error={Boolean(
                              touched.token_subscription_id && errors.token_subscription_id
                            )}
                            helperText={
                              touched.token_subscription_id && errors.token_subscription_id
                            }
                          >
                            {/* TODO:ADD GET token_subscription_id */}
                            {currencies &&
                              currencies.map((currency, index) => {
                                return (
                                  <MenuItem
                                    key={currency.currency_code}
                                    value={currency.currency_code}
                                  >
                                    {currency.currency_code} | {currency.currency_name}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                          <FormHelperText sx={{ color: '#d32f2f' }}>
                            {touched.token_subscription_id && errors.token_subscription_id}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Divider
                      sx={{
                        width: '100%',
                        backgroundColor: '#f5f8fa',
                        border: '2px solid #f5f8fa',
                        my: 4
                      }}
                    />

                    <Grid>
                      <Grid item lg={6} sm={12} md={6} xs={12}>
                        <FormLabel>Direction</FormLabel>
                        <TextField
                          multiline="true"
                          rows="3"
                          sx={{ mt: 1.5 }}
                          fullWidth
                          size="small"
                          maxRows="4"
                          autoComplete="off"
                          type="text"
                          {...getFieldProps('direction')}
                          error={Boolean(touched.direction && errors.direction)}
                          helperText={touched.direction && errors.direction}
                          // placeholder="Enter directions"
                        />
                      </Grid>

                      <Grid
                        item
                        lg={12}
                        sm={12}
                        md={12}
                        xs={12}
                        sx={{ display: 'flex', justifyContent: 'flex-end' }}
                      >
                        <Button
                          variant="outlined"
                          color="secondary"
                          sx={{ mt: 4, ml: 2, height: '2.7rem', width: '7rem' }}
                        >
                          Cancel
                        </Button>
                        <LoadingButton
                          variant="gradient"
                          sx={{ mt: 4, ml: 2, height: '2.7rem', width: '7rem' }}
                          onClick={handleSubmit}
                        >
                          Save
                        </LoadingButton>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Form>
              </FormikProvider>
            </Container>
          </Card>
        </Container>
      </Page>
    </>
  );
};

export default AddCashTxn;
