import React, { useEffect, useMemo } from 'react';
import EnhancedTable from 'components/tables/EnhancedTable';
import { IconButton } from '@mui/material';
import { ethToXdcAddress } from 'helpers/web3';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useBusinessEntityState, useAuthStateShared } from 'state';
import { HeaderName } from 'helpers/tableHelper';
import { useNavigate } from 'react-router-dom';
import CopyAddress from 'components/CopyAddress';

const IssuerName = HeaderName('Issuer Name');
const InsurerContri = HeaderName('Insurer Contribution');
const IssuerContri = HeaderName('Issuer Contribution');

const ActionButtons = ({ rowIssuerId }) => {
  const { entity } = useAuthStateShared();
  const navigate = useNavigate();
  const {
    // insurerIssuerTokens,
    // fetchInsurerIssuerTokens,
    setCurrentCreditIssuer,
    setCurrentCreditInsurer
    // insurerIssuerIssuanceEscrowOrder,
    // insurerIssuerRedemptionEscrowOrder,
    // insurerIssuerAllotments,
    // fetchIssuanceEscrowOrderByInsurerIssuerId,
    // fetchRedemptionEscrowOrderByInsurerIssuerId,
    // fetchAllotmentsByInsurerIssuerId,
    // fetchAllRedemptionsByInsurerIssuerId
  } = useBusinessEntityState();

  return (
    <IconButton
      onClick={() => {
        setCurrentCreditIssuer(rowIssuerId);
        setCurrentCreditInsurer(entity);
        // fetchInsurerIssuerTokens(rowIssuerId.issuer_id, entity.insurer_id);
        // fetchIssuanceEscrowOrderByInsurerIssuerId(rowIssuerId.issuer_id, entity.insurer_id);
        // fetchRedemptionEscrowOrderByInsurerIssuerId(rowIssuerId.issuer_id, entity.insurer_id);
        // fetchAllotmentsByInsurerIssuerId(rowIssuerId.issuer_id, entity.insurer_id);
        // fetchAllRedemptionsByInsurerIssuerId(rowIssuerId.issuer_id, entity.insurer_id);
        navigate('/credit-insurer/dashboard');
      }}
    >
      <OpenInNewIcon sx={{ fontSize: '1.8rem', p: 0.7, backgroundColor: '#F5F8FA' }} />
    </IconButton>
  );
};
export default function AllMappedIssuersTable() {
  const { fetchIssuerInsurerMappingByInsurerId, issuerInvestorMapByInsurerId } =
    useBusinessEntityState();

  const { entity } = useAuthStateShared();
  console.log({ entity });
  useEffect(() => {
    if (entity && entity.insurer_id) {
      fetchIssuerInsurerMappingByInsurerId(entity.insurer_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entity]);

  const fetchData = () => {
    fetchIssuerInsurerMappingByInsurerId(entity.insurer_id);
  };

  const headCells = useMemo(
    () => [
      {
        accessor: 'issuer_name',
        Header: IssuerName,
        show: true
      },
      {
        accessor: 'issuer_xinfin_address',
        Header: 'Xinfin Address',
        width: 200,
        Cell: ({ value }) => {
          return <CopyAddress addr={ethToXdcAddress(value)} />;
        },
        show: true
      },
      {
        accessor: 'issuer_contri',
        Header: IssuerContri,
        show: true
      },
      {
        accessor: 'insurer_contri',
        Header: InsurerContri,
        show: true
      },
      {
        accessor: 'fees_per_month',
        Header: 'Fees per Month',
        show: true
      },
      {
        accessor: 'actions',
        Header: 'Insurer Actions',
        show: true,
        width: 160,
        Cell: (c) => {
          console.log('row', c.row.original);
          return <ActionButtons rowIssuerId={c.row.original} />;
        }
      }
    ],
    []
  );
  return (
    <>
      <EnhancedTable
        tableTitle="Issuer"
        columns={headCells}
        data={issuerInvestorMapByInsurerId}
        refreshFunction={fetchData}
      />
    </>
  );
}
