import Page from 'components/Page';
import React from 'react';
import { Card, Container } from '@mui/material';
import Breadcrumbs from 'components/Breadcrumbs';
import { CardContent } from '@material-ui/core';
import CryptoTxnTable from 'components/masterData/cryptoTxn/CryptoTxnTable';

const CryptoAssetTxn = () => {
  return (
    <Page title="Crypto Asset Transaction - Master Data">
      <Container>
        <Breadcrumbs pageHead="Crypto Asset Transaction" />

        <Card sx={{ mt: 2 }}>
          <CardContent>
            <CryptoTxnTable />
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
};

export default CryptoAssetTxn;
