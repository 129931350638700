import { Magic } from 'magic-sdk';
import { useWeb3 } from 'state';
import { useRef, useState } from 'react';
import {
  Container,
  Card,
  Grid,
  Box,
  CardContent,
  Typography,
  TextField,
  FormLabel,
  Button,
  Alert,
  AlertTitle,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  IconButton,
  InputAdornment
} from '@mui/material';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';
import Modal from '@mui/material/Modal';

const MagicLinkModal = ({ openModal, userEmail, setOpenOtpModal }) => {
  const {
    verifyOtp,
    otpVerificationOngoing,
    setOtpVerificationOngoing,
    connectedAccount,
    otpVerificationComplete
  } = useWeb3();

  const [tryCount, setTryCount] = useState(0);
  const otpModalCloseRef = useRef(null);
  const [otp, setOtp] = useState(['', '', '', '', '', '']);
  const inputRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null)
  ];

  const incrementTryCount = () => {
    setTryCount((prev) => prev + 1);
  };

  const handleInputChange = async (index, value, event) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    // Move to the next input field if value is not empty
    if (value !== '' && index < 5 && inputRefs[index + 1]?.current) {
      if (inputRefs[index + 1].current) {
        inputRefs[index + 1]?.current?.focus();
      }
    }

    // Move to the previous input field if backspace is pressed and the current input is empty
    if (event.key === 'Backspace' && value === '' && index > 0 && inputRefs[index - 1]?.current) {
      console.log('backspace');
      if (inputRefs[index - 1].current) {
        inputRefs[index - 1]?.current?.focus();
      }
    }

    // Call submit otp api if all the otp fields are filled

    if (newOtp.join('').length >= 6) {
      setOtpVerificationOngoing(true);
      console.log('OTP IS', newOtp.join(''));
      if (tryCount <= 2) {
        incrementTryCount();
        await verifyOtp(newOtp.join(''), otpModalCloseRef, tryCount);

        // formik.resetForm();
      }
    }
  };

  const handlePaste = async (event) => {
    const pastedData = event.clipboardData.getData('text/plain');
    const pastedChars = pastedData.split('');

    // Update OTP values
    const newOtp = [...otp];
    for (let i = 0; i < pastedChars.length && i < 6; i += 1) {
      newOtp[i] = pastedChars[i];
    }
    setOtp(newOtp);

    // Move focus to the next input
    if (pastedChars.length < 6 && inputRefs[pastedChars.length]?.current) {
      if (inputRefs[pastedChars.length].current) {
        inputRefs[pastedChars.length]?.current?.focus();
      }
    }
    // Call submit otp api if all the otp fields are filled
    if (pastedChars.length >= 6) {
      setOtpVerificationOngoing(true);
      console.log('OTP IS', newOtp.join(''));
      if (tryCount <= 2) {
        incrementTryCount();
        const responseOtp = await verifyOtp(newOtp.join(''), otpModalCloseRef, tryCount);
      }
    }
  };

  return (
    <Modal
      open={openModal}
      // onClose={()=>{setOpenOtpModal(false)}}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '30%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          //   border: '2px solid #000',
          boxShadow: 24,
          borderRadius: '10px',
          p: 4
        }}
      >
        <Box display="flex" justifyContent="flex-end" sx={{ display: 'none' }}>
          <IconButton
            aria-label="close"
            ref={otpModalCloseRef}
            onClick={() => {
              setOpenOtpModal(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box textAlign="center" mb={2}>
          <img
            src="https://staging.devdmpl.com/mletr/admin/assets/media/svg/misc/email.svg"
            alt="logo"
            style={{
              width: '100px',
              height: '100px',
              margin: 'auto'
            }}
          />
          <Typography variant="h6" mt={2}>
            Please enter the code sent to <br />
            <strong>{userEmail}</strong>
          </Typography>
        </Box>
        {otpVerificationOngoing ? (
          <Box display="flex" justifyContent="center" my={2}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Box display="flex" justifyContent="center" mt={2}>
              {otp.map((value, index) => (
                <TextField
                  key={index}
                  autoFocus={(index === 0 && !value) || (index === 5 && value)}
                  inputProps={{
                    style: { textAlign: 'center' },
                    maxLength: 1
                  }}
                  value={value}
                  onChange={(e) => handleInputChange(index, e.target.value, e.nativeEvent)}
                  onKeyDown={(e) => {
                    if (e.key === 'Backspace' && !value) {
                      handleInputChange(index, '', e);
                    }
                  }}
                  onPaste={(e) => handlePaste(e)}
                  inputRef={inputRefs[index]}
                  style={{ margin: '0 8px', width: '40px' }}
                />
              ))}
            </Box>
            <Box mt={2}>
              <Typography variant="body2">Tries remaining: {3 - tryCount}</Typography>
            </Box>
            <Box mt={2} textAlign="center">
              <Typography variant="body2">
                Secured By{' '}
                <a className="text-dark" href="#">
                  Magic
                </a>
              </Typography>
            </Box>
          </>
        )}
      </Box>
    </Modal>
  );
};

export default MagicLinkModal;
