import { Table, TableCell, TableBody, TableRow, TableHead, Radio } from '@mui/material';
import React from 'react';
import DownloadIcon from '@mui/icons-material/Download';
import UploadIcon from '@mui/icons-material/Upload';

const KYCBasicDetails = ({ verificationDataState, handleRadioButtonSelect, basicInfo }) => {
  const rows = [
    {
      verification: 'LEI Verification',
      doc: 'Document Name',
      icon: <DownloadIcon />,
      verificationStatus: verificationDataState.lei_verification,
      basic_info: basicInfo.lei,
      property_name: 'lei_verification'
    },
    {
      verification: 'Bank Verification',
      doc: 'Document Name',
      icon: <DownloadIcon />,
      verificationStatus: verificationDataState.bank_verification,
      basic_info: basicInfo.bank,
      property_name: 'bank_verification'
    },
    {
      verification: 'Citizenship Verification',
      doc: 'Document Name',
      icon: <DownloadIcon />,
      verificationStatus: verificationDataState.citizenship_verification,
      basic_info: basicInfo.citizenship,
      property_name: 'citizenship_verification'
    },
    {
      verification: 'Address Verification',
      doc: 'Upload Document',
      icon: <UploadIcon />,
      verificationStatus: verificationDataState.address_verification,
      basic_info: basicInfo.address,
      property_name: 'address_verification'
    }
  ];

  return (
    <>
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: '20%' }}>Verification Type</TableCell>
            {/* <TableCell sx={{ width: '20%' }}>Details</TableCell> */}
            <TableCell sx={{ width: '10%' }}>Not Applicable</TableCell>
            <TableCell sx={{ width: '10%' }}>Unverified</TableCell>
            <TableCell sx={{ width: '10%' }}>Verified</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.verification}
              sx={{ '&:last-child td, &:last-child th': { border: 0 }, height: '2px' }}
            >
              <TableCell component="th" scope="row" sx={{ fontWeight: 600 }}>
                {row.verification}
              </TableCell>

              {/* <TableCell
                sx={{
                  fontStyle: 'italic',
                  color: '#7C7C7C'
                }}
              >
                {row.basic_info}
              </TableCell> */}

              <TableCell>
                <Radio
                  checked={row.verificationStatus === 'NA'}
                  value="NA"
                  name={row.property_name}
                  onChange={handleRadioButtonSelect}
                  color="default"
                />
              </TableCell>

              <TableCell>
                <Radio
                  checked={row.verificationStatus === 'UNVERIFIED'}
                  value="UNVERIFIED"
                  name={row.property_name}
                  onChange={handleRadioButtonSelect}
                  color="error"
                />
              </TableCell>

              <TableCell>
                <Radio
                  checked={row.verificationStatus === 'VERIFIED'}
                  value="VERIFIED"
                  name={row.property_name}
                  onChange={handleRadioButtonSelect}
                  color="success"
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};

export default KYCBasicDetails;
