import { Table, TableCell, TableHead, TableRow, Box } from '@mui/material';
import { parseTokenValue } from 'helpers/numbers';
import { getCurrencySymbol } from 'helpers/currency';
import React, { useEffect } from 'react';
import { useAuthStateShared, useBusinessEntityState, useSubscriptionState, useWeb3 } from 'state';

const NavCalculation = ({ poolAddr }) => {
  const { getBalanceOf } = useWeb3();
  const { poolProxyToken, poolHoldings, fetchPoolHoldings } = useBusinessEntityState();
  const [poolBalance, setPoolBalance] = React.useState(0);
  const [currencySymbol, setCurrencySymbol] = React.useState('');
  // const { entity } = useAuthStateShared();

  const { fetchAllotmentsByXinfinAddress, allotments } = useSubscriptionState();

  const _fetchData = async () => {
    await fetchPoolHoldings(poolAddr);
  };

  useEffect(() => {
    _fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [poolAddr]);

  useEffect(() => {
    const fetchData = async () => {
      if (poolProxyToken) {
        const balance = await getBalanceOf(
          poolProxyToken.payment_token_id.contract_address,
          poolAddr
        );
        const _balance = parseTokenValue(balance, poolProxyToken.payment_token_id.decimals);
        const _currencySymbol = getCurrencySymbol(poolProxyToken.pegged_currency);
        setPoolBalance(_balance);
        setCurrencySymbol(_currencySymbol);
      }
    };
    fetchData();
  }, [poolAddr, poolProxyToken]);
  return (
    <Box>
      <Table>
        <TableHead>
          <TableCell
            sx={{
              color: '#8e8ea7',
              fontWeight: 'bold'
            }}
          >
            Asset
          </TableCell>
          <TableCell
            sx={{
              color: '#8e8ea7',
              fontWeight: 'bold'
            }}
          >
            Qty
          </TableCell>
          <TableCell
            sx={{
              color: '#8e8ea7',
              fontWeight: 'bold'
            }}
          >
            Value
          </TableCell>
        </TableHead>
        {poolProxyToken && (
          <TableRow>
            <TableCell>{poolProxyToken.payment_token_id.symbol}</TableCell>
            <TableCell>{`${poolBalance}`}</TableCell>
            <TableCell>{`${currencySymbol}${poolBalance}`}</TableCell>
          </TableRow>
        )}
        {poolHoldings &&
          poolHoldings.map((allotment) => (
            <TableRow key={allotment.token_name}>
              <TableCell>{allotment.token_name}</TableCell>
              <TableCell>{allotment.alloted_qty}</TableCell>
              <TableCell>{`${currencySymbol}${allotment.asset_value}`}</TableCell>
            </TableRow>
          ))}
      </Table>
    </Box>
  );
};

export default NavCalculation;
