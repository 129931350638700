import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  Button,
  Divider,
  Modal,
  Typography
} from '@mui/material';
import Breadcrumbs from 'components/Breadcrumbs';
import TokenActionsCard from 'components/issuerDashboard/TokenActionsCard';
import TokenTimeline from 'components/issuerDashboard/TokenTimeline';
import React, { useState, useEffect, useCallback } from 'react';
import SimpleTokenActionCard from 'components/issuerDashboard/SimpleTokenActionCard';
import ToggleCard, {
  ToggleBlockExplorer,
  ViewNotificationCard
} from 'components/issuerDashboard/ToggleCard';
import PublishTokenDialog from 'components/CreateToken/PublishTokenDialog';
import accessControl from 'assets/images/dashboard-icon/accessControl.svg';
import allotments from 'assets/images/dashboard-icon/allotments.svg';
import amendment from 'assets/images/dashboard-icon/amendment.svg';
import auditReport from 'assets/images/dashboard-icon/auditReport.svg';
import cashTxn from 'assets/images/dashboard-icon/cashTxn.svg';
import docManagement from 'assets/images/dashboard-icon/docManagement.svg';
import enterpriseApp from 'assets/images/dashboard-icon/enterpriseApp.svg';
import escrowSettlement from 'assets/images/dashboard-icon/escrowSettlement.svg';
import externalDataInterface from 'assets/images/dashboard-icon/externalDataInterface.svg';
import KYCAMLCTF from 'assets/images/dashboard-icon/KYCAMLCTF.svg';
import manageTokenRule from 'assets/images/dashboard-icon/manageTokenRule.svg';
import newSubscription from 'assets/images/dashboard-icon/newSubscription.svg';
import notification from 'assets/images/dashboard-icon/notification.svg';
import priceLiquidity from 'assets/images/dashboard-icon/priceLiquidity.svg';
import redemptions from 'assets/images/dashboard-icon/redemptions.svg';
import stableCoinTxn from 'assets/images/dashboard-icon/stableCoinTxn.svg';
import {
  useBusinessEntityState,
  useHttpApi,
  useWeb3,
  useAuthStateShared,
  useAppState
} from 'state';
import { useNavigate } from 'react-router';
import { useSnackbar } from 'notistack';
import { toChecksumAddress } from 'helpers/web3';
import { businessEntityPersistence } from 'persistence';
import RbacShowHideComponent from 'components/RbacShowHideComponent';
import BurnToken from 'components/issuerDashboard/BurnToken';
import TransactionHandler from 'components/TransactionHandler';
import { parseTokenDecimal } from 'helpers/numbers';
import { format, isBefore, isAfter, isToday } from 'date-fns';
import LoadingActionButton from 'helpers/LoadingActionButton';

const TokenStatusStepper = ({
  status,
  tokenName,
  tokenSymbol,
  tokenTotalSupply,
  tokenValue,
  tokenCurrency,
  issuerPrimaryAddress,
  // custodianPrimaryAddress,
  kycproviderPrimaryAddress,
  earlyRedemption,
  minSubscription,
  paymentToken,
  issuanceSwapMultiple,
  redemptionSwapMultiple,
  maturityPeriod,
  settlementPeriod,
  collateral,
  statusNumber,
  tokenId
}) => {
  const { publishToken } = useWeb3();

  const commitTransaction = useCallback(
    (hash) => {
      const collateralValue = parseTokenDecimal(collateral !== 0 ? collateral : 0, 18);

      const issueSwapRatio = parseTokenDecimal(
        issuanceSwapMultiple !== 0 ? issuanceSwapMultiple : 0,
        paymentToken.decimals
      );

      const redeemSwapRatio = parseTokenDecimal(
        redemptionSwapMultiple !== 0 ? redemptionSwapMultiple : 0,
        paymentToken.decimals
      );

      const _paymentTokenList = [];
      const _issuanceRatio = [];
      const _redeemRatio = [];
      if (paymentToken.symbol) {
        _paymentTokenList.push(paymentToken.contract_address);
        _issuanceRatio.push(issueSwapRatio);
        _redeemRatio.push(redeemSwapRatio);
      }
      return publishToken(
        tokenSymbol,
        tokenName,
        Number(tokenTotalSupply),
        Number(tokenValue),
        tokenCurrency,
        issuerPrimaryAddress,
        // custodianPrimaryAddress,
        kycproviderPrimaryAddress,
        earlyRedemption,
        Number(minSubscription),
        _paymentTokenList,
        _issuanceRatio,
        _redeemRatio,
        Number(maturityPeriod),
        Number(settlementPeriod),
        collateralValue,
        false,
        hash
      );
    },
    [
      publishToken,
      tokenSymbol,
      tokenName,
      tokenTotalSupply,
      tokenValue,
      tokenCurrency,
      issuerPrimaryAddress,
      // custodianPrimaryAddress,
      kycproviderPrimaryAddress,
      earlyRedemption,
      minSubscription,
      paymentToken,
      issuanceSwapMultiple,
      redemptionSwapMultiple,
      maturityPeriod,
      settlementPeriod,
      collateral
    ]
  );

  const { fetchTokens, fetchCurrentToken } = useBusinessEntityState();
  const { getTokenDetailsById } = useHttpApi();

  const onConfirmed = useCallback(async () => {
    fetchTokens();
    const res = await getTokenDetailsById(tokenId);
    fetchCurrentToken(res);
  }, [fetchTokens, getTokenDetailsById, fetchCurrentToken, tokenId]);

  return (
    <TransactionHandler
      id={`token-status-${tokenSymbol}`}
      commitTransaction={commitTransaction}
      done={['PUBLISHED', 'ISSUED', 'REDEEMED', 'BURNT'].includes(status)}
      onConfirmed={onConfirmed}
    >
      {({ status: txStatus, error, receipt, send }) => {
        return <TokenTimeline statusNumber={statusNumber} />;
      }}
    </TransactionHandler>
  );
};

const TokenDashboard = () => {
  // publish token dailog box
  const { finalizeIssuance } = useWeb3();
  const [openPopup, setOpenPopup] = useState(false);
  const [burn, setBurn] = useState(false);
  const today = new Date();
  const todayDate = format(today, 'yyyy-MM-dd');
  const { currentToken, fetchCurrentToken, fetchCurrentTokenRuleDetails, pendingWhiteList } =
    useBusinessEntityState();
  const {
    setToken,

    setCurrentTokenById,
    setAllSubscriptionById,
    fetchWhiteListSubscriptions,
    fetchAllAllotments,
    fetchWhiteList,
    allSubscriptions,
    fetchIssuerInsurerMappingByIssuerId,
    whitelistedSubscriptions,
    fetchPendingDocCount,
    pendingDocsCount,
    fetchPendingKYCCount,
    pendingKYCCount
  } = useBusinessEntityState();
  const { entity } = useAuthStateShared();
  const {
    getTokenById,
    getAllSubscriptionByToken,
    getTokenDetailsById,
    editToken,
    getRuleTemplateDetailedByToken
  } = useHttpApi();
  const navigate = useNavigate();
  const {
    throwErrorMessage,
    fetchAllReadNotifications,
    tokenNotifications,
    fetchNotificationsByAddress
  } = useAppState();
  const [statusNumber, setStatusNumber] = useState(0);
  const [statusName, setStatusName] = useState('');
  const { account, pause: pauseBlockchain, unpause: unpauseBlockchain } = useWeb3();
  const [toggleStatus, setToggleStatus] = useState(true);
  const [tokenIssuanceCardCount, setTokenIssuanceCardCount] = useState(0);

  const isPause = async () => {
    try {
      if (currentToken.is_paused) {
        const res = await unpauseBlockchain(currentToken.deployment_address);
        console.log('unpause', res);
        if (res) {
          enqueueSnackbar('Token Submitted for Unpause', { variant: 'success' });
        }
      } else {
        const res = await pauseBlockchain(currentToken.deployment_address);
        console.log('pause', res);
        if (res) {
          enqueueSnackbar('Token Submitted for Pause', { variant: 'success' });
        }
      }
    } catch (e) {
      enqueueSnackbar(
        'You are not authorised to Pause/unpause this token, Please use a issuer account',
        {
          variant: 'error'
        }
      );
      console.log(e);
    }
  };

  const updateToken = async (data) => {
    const res = await editToken(currentToken.token_id, data);
    if (res) {
      const res = await getTokenDetailsById(currentToken.token_id);
      fetchCurrentToken(res);
      enqueueSnackbar('Token Updated', { variant: 'success' });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (currentToken) {
        const tokenId = currentToken.token_id;
        const res = await getTokenDetailsById(tokenId);
        const fetch = fetchCurrentToken(res);
        await fetchAllReadNotifications();
        await fetchNotificationsByAddress();
        await fetchWhiteList();
        const _allSubs = await getAllSubscriptionByToken(currentToken.token_id);
        setAllSubscriptionById(_allSubs);
        fetchWhiteListSubscriptions();
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const _filteredSubscriptions = whitelistedSubscriptions.filter(
      (sub) =>
        !sub.is_rejected &&
        (sub.transaction_status_id.transaction_status_desc === 'APPLIED' ||
          sub.transaction_status_id.transaction_status_desc === 'ALLOTED_PARTIAL')
    );
    setTokenIssuanceCardCount(_filteredSubscriptions.length);
  }, [whitelistedSubscriptions]);

  useEffect(() => {
    fetchPendingDocCount(allSubscriptions);
    fetchPendingKYCCount(allSubscriptions);
  }, [allSubscriptions]);

  // Fetch Logged in IssuerId

  useEffect(() => {
    if (entity && entity.issuer_id) {
      fetchIssuerInsurerMappingByIssuerId(entity.issuer_id);
      // console.log({ issuerInvestorMapByIssuerId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entity]);

  useEffect(() => {
    if (currentToken.token_id) {
      const _statusName = currentToken.token_status_id.token_status;
      if (_statusName === 'NEW') {
        setToggleStatus(false);
      }
      if (currentToken.token_type === 'POOL_LENDING') {
        setToggleStatus(true);
      }
      setStatusName(_statusName);
      switch (_statusName) {
        case 'NEW':
          setStatusNumber(1);
          break;
        // case 'REVIEWED':
        //   setStatusNumber();
        //   break;
        case 'PUBLISHED':
          setStatusNumber(2);
          break;
        case 'ISSUED':
          setStatusNumber(3);
          break;
        case 'REDEEMED':
          setStatusNumber(4);
          break;
        case 'BURNT':
          setStatusNumber(5);
          break;
        default:
          setStatusNumber(1);
      }
    } else {
      navigate('/home');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentToken]);
  console.log('🚀 ~ file: TokenDashboard.js ~ line 294 ~ TokenDashboard ~ ̥', currentToken);

  const { enqueueSnackbar } = useSnackbar();

  return (
    <Container fixed>
      {/* Box to show the page title and breadcrumb component */}

      <Breadcrumbs aria-label="breadcrumb" pageHead={currentToken.token_name} />

      {/* Starting a new container and dividing it into 2 parts of size 9 and 3 to fit the action card and timelines */}
      <Grid container spacing={3}>
        <Grid item lg={9} md={9} sm={9} sx={{ mt: 2 }}>
          <Grid container spacing={3}>
            <Grid item lg={3} md={4} sm={6}>
              <TokenActionsCard
                title="Tokens Rules"
                cardColor="#7465d9"
                onClick={async () => {
                  if (currentToken.token_type === 'POOL_LENDING') {
                    enqueueSnackbar('PooL Finance Token has no access', { variant: 'error' });
                  } else {
                    navigate('/home/token-dashboard/manage-criteria');
                  }
                }}
                icon={
                  <Box
                    component="img"
                    sx={{
                      p: 1
                    }}
                    src={manageTokenRule}
                  />
                }
                linkName="/home/token-dashboard/dashboard"
              />
            </Grid>

            <Grid item lg={3} md={4} sm={6}>
              <TokenActionsCard
                title="Know Your Customer"
                cardColor="#c71492"
                count={pendingKYCCount}
                icon={<Box component="img" sx={{ p: 1 }} src={KYCAMLCTF} />}
                linkName="/home/token-dashboard/kyc-dashboard"
                onClick={async () => {
                  if (currentToken.token_type === 'POOL_LENDING') {
                    enqueueSnackbar('PooL Finance Token has no access', { variant: 'error' });
                  } else {
                    const response = await getAllSubscriptionByToken(currentToken.token_id);
                    setToken(currentToken);
                    setAllSubscriptionById(response);
                    navigate('/home/token-dashboard/kyc-dashboard');
                  }
                }}
              />
            </Grid>
            <Grid item lg={3} md={4} sm={6}>
              <TokenActionsCard
                title="Whitelist"
                cardColor="#3edba5"
                count={pendingWhiteList.length}
                onClick={async () => {
                  if (currentToken.token_type === 'POOL_LENDING') {
                    enqueueSnackbar('PooL Finance Token has no access', { variant: 'error' });
                  } else {
                    fetchWhiteList();
                    navigate('/home/token-dashboard/maintain-whitelist');
                  }
                }}
                icon={
                  <Box
                    component="img"
                    sx={{
                      p: 1
                    }}
                    src={newSubscription}
                  />
                }
                linkName="/home/token-dashboard/maintain-whitelist"
              />
            </Grid>
            <Grid item lg={3} md={4} sm={6}>
              <TokenActionsCard
                title="Document Management"
                cardColor="#c54a45"
                count={pendingDocsCount}
                onClick={async () => {
                  if (currentToken.token_type === 'POOL_LENDING') {
                    enqueueSnackbar('PooL Finance Token has no access', { variant: 'error' });
                  } else {
                    const response = await getAllSubscriptionByToken(currentToken.token_id);
                    setToken(currentToken);
                    setAllSubscriptionById(response);
                    navigate('/home/token-dashboard/doc-manage-dashboard');
                  }
                }}
                icon={<Box component="img" sx={{ p: 1 }} src={docManagement} />}
                linkName="/home/token-dashboard/dashboard"
              />
            </Grid>
            <Grid item lg={3} md={4} sm={6}>
              <TokenActionsCard
                title="Token Issuance"
                cardColor="#3672f7"
                count={tokenIssuanceCardCount}
                icon={
                  <Box
                    component="img"
                    sx={{
                      p: 1
                    }}
                    src={allotments}
                  />
                }
                onClick={async () => {
                  try {
                    if (isAfter(new Date(currentToken.token_create_date), new Date(todayDate))) {
                      enqueueSnackbar('Token Issue Date has not yet come', { variant: 'info' });
                      console.log('Token Issue Date has not yet come');
                    } else {
                      await fetchWhiteListSubscriptions();
                      navigate('/home/token-dashboard/create-allotment');
                      console.log('Token Issue Date has come');
                    }
                  } catch (e) {
                    console.log('Error', e);
                  }
                }}
                linkName="/home/token-dashboard/create-allotment"
              />
            </Grid>
            <Grid item lg={3} md={4} sm={6}>
              <TokenActionsCard
                title="Escrow & Settlement"
                cardColor="#d9a765"
                count={
                  currentToken?.active_issunace_escrow !== 0 ||
                  currentToken?.active_redeem_escrow !== 0
                    ? `${currentToken?.active_issunace_escrow} | ${currentToken?.active_redeem_escrow}`
                    : 0
                }
                onClick={() => {
                  navigate('/home/token-dashboard/escrow-settlement');
                }}
                icon={<Box component="img" sx={{ p: 1 }} src={escrowSettlement} />}
                linkName="/home/token-dashboard/dashboard"
              />
            </Grid>
            <Grid item lg={3} md={4} sm={6}>
              <TokenActionsCard
                title="Allotments & Redemptions"
                cardColor="#fb7427"
                count={
                  currentToken.active_allotments !== 0 || currentToken.redeemed_allotments !== 0
                    ? `${currentToken.active_allotments} | ${currentToken.redeemed_allotments}`
                    : 0
                }
                onClick={async () => {
                  await fetchAllAllotments();
                  navigate('/home/token-dashboard/view-allotment');
                }}
                icon={
                  <Box
                    component="img"
                    sx={{
                      p: 1
                    }}
                    src={redemptions}
                  />
                }
                linkName="/home/token-dashboard/view-allotment"
              />
            </Grid>
            <Grid item lg={3} md={4} sm={6}>
              <TokenActionsCard
                title="Interest Accruals"
                cardColor="#29c714"
                count={currentToken.active_allotments !== 0 ? currentToken.active_allotments : 0}
                onClick={async () => {
                  await fetchAllAllotments();

                  navigate('/home/token-dashboard/payment-transactions');
                }}
                icon={
                  <Box
                    component="img"
                    sx={{
                      p: 1
                    }}
                    src={cashTxn}
                  />
                }
                linkName="/masterdata/cash-txn"
              />
            </Grid>
          </Grid>
          <RbacShowHideComponent allowedRoles={['PRIMARY_ISSUER', 'ISSUER_OPERATOR']}>
            <Grid container spacing={3} sx={{ mt: 1 }}>
              <Grid item lg={12} md={9} sm={9}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'end'
                  }}
                >
                  <LoadingActionButton
                    loadingPosition="start"
                    variant="contained"
                    color="primary"
                    sx={{ width: '200px' }}
                    onClick={async () => {
                      const response = await getTokenById(currentToken.token_id);
                      console.log('ressss', response);
                      setCurrentTokenById(response);
                      navigate(`/home/token-dashboard/edit-token`);
                    }}
                  >
                    View and Edit
                  </LoadingActionButton>

                  {/* Review Token Only Primary Issuer */}
                  {/* <RbacShowHideComponent allowedRoles={['ISSUER']}>
                    <Button
                      variant="contained"
                      color="secondary"
                      sx={{ width: '100%', ml: 1 }}
                      onClick={async () => {
                        const res = await getRuleTemplateDetailedByToken(currentToken.token_id);

                        if (statusName === 'NEW') {
                          if (currentToken.onchain_restrictions && !res.template_id) {
                            enqueueSnackbar(
                              j
                              {
                                variant: 'warning'
                              }
                            );
                          } else {
                            navigate(`/home/token-dashboard/review`);
                            setToken(currentToken);
                            fetchCurrentTokenRuleDetails();
                          }
                        } else {
                          enqueueSnackbar('Token is already Reviewed');
                        }
                      }}
                    >
                      Review
                    </Button>
                  </RbacShowHideComponent> */}

                  {/* Publish Token Only Primary Issuer */}
                  <RbacShowHideComponent allowedRoles={['PRIMARY_ISSUER']}>
                    <Button
                      variant="gradient"
                      sx={{ width: '200px', ml: 1 }}
                      onClick={async () => {
                        const res = await getRuleTemplateDetailedByToken(currentToken.token_id);
                        const _status = currentToken.token_status_id.token_status;
                        console.log('_status', _status);
                        console.log(
                          'Issuer Closure Date',
                          currentToken.final_maturity_date,
                          currentToken.token_create_date,
                          isBefore(new Date(currentToken.token_create_date), new Date(todayDate))
                        );

                        if (statusName === 'NEW') {
                          if (currentToken.onchain_restrictions && !res.template_id) {
                            enqueueSnackbar(
                              'You have enabled OnChain Restrictions but have not setup any rules yet',
                              {
                                variant: 'error'
                              }
                            );
                          } else if (
                            isBefore(new Date(currentToken.token_create_date), new Date(todayDate))
                          ) {
                            enqueueSnackbar('Cannot publish token after Issue Start Date', {
                              variant: 'warning'
                            });
                          } else if (
                            isBefore(
                              new Date(currentToken.final_maturity_date),
                              new Date(todayDate)
                            )
                          ) {
                            enqueueSnackbar('Cannot publish token after Issue Closure Date', {
                              variant: 'warning'
                            });
                          } else if (
                            isAfter(
                              new Date(currentToken.final_maturity_date),
                              new Date(todayDate)
                            ) ||
                            isToday(new Date(currentToken.final_maturity_date))
                          ) {
                            setOpenPopup(true);
                          }
                        } else {
                          enqueueSnackbar('Token is already published', {
                            variant: 'warning'
                          });
                        }
                      }}
                    >
                      Publish
                    </Button>
                  </RbacShowHideComponent>
                  <Button
                    variant="contained"
                    color="error"
                    sx={{ width: '200px', ml: 1 }}
                    onClick={async () => {
                      const _status = currentToken.token_status_id.token_status;
                      if (_status === 'ISSUED') {
                        // Call the finalizeIssuance function on ERC20
                        try {
                          await finalizeIssuance(currentToken.deployment_address);
                          enqueueSnackbar('Token is successfully finalized', {
                            variant: 'success'
                          });
                        } catch (e) {
                          throwErrorMessage(e);
                        }
                      } else {
                        enqueueSnackbar('Token is not issued yet', {
                          variant: 'warning'
                        });
                      }
                    }}
                  >
                    Finalize Issuance
                  </Button>

                  {/* <Button
                    onClick={() => {
                      if (statusName === 'NEW') {
                        enqueueSnackbar('Token is not yet published!', {
                          variant: 'warning'
                        });
                      } else if (currentToken.total_allotment > 0) {
                        enqueueSnackbar('Token has allotments!', {
                          variant: 'warning'
                        });
                      } else if (statusName === 'REDEEMED') {
                        setBurn(true);
                      } else if (currentToken.total_allotment === 0) {
                        setBurn(true);
                      } else {
                        enqueueSnackbar('Token cannot be burnt!', {
                          variant: 'error'
                        });
                      }
                    }}
                    variant="contained"
                    color="error"
                    sx={{ width: '100%', ml: 1 }}
                  >
                    Burn
                  </Button> */}
                </Box>
              </Grid>
            </Grid>
          </RbacShowHideComponent>
          {/* <Grid
            container
            sx={{
              display: 'flex',
              mt: 1,
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}
          >
            <Grid item lg={2} md={4} sm={12} sx={{ mt: 2 }}>
              <SimpleTokenActionCard
                title="Enterprise Integration"
                icon={<Box component="img" sx={{ p: 0.7 }} src={enterpriseApp} />}
                linkName="/home/token-dashboard/dashboard"
              />
            </Grid>
            <Grid item lg={2} md={4} sm={12} sx={{ mt: 2 }}>
              <SimpleTokenActionCard
                title="Document Management"
                icon={<Box component="img" sx={{ p: 0.7 }} src={docManagement} />}
                linkName="/home/token-dashboard/dashboard"
                onClick={async () => {
                  const response = await getAllSubscriptionByToken(currentToken.token_id);
                  setToken(currentToken);
                  setAllSubscriptionById(response);
                  navigate('/home/token-dashboard/doc-manage-dashboard');
                }}
              />
            </Grid>
            <Grid item lg={2} md={4} sm={12} sx={{ mt: 2 }}>
              <SimpleTokenActionCard
                title="Audit & Reports"
                icon={<Box component="img" sx={{ p: 0.7 }} src={auditReport} />}
                linkName="/home/token-dashboard"
              />
            </Grid>
            <Grid item lg={2} md={4} sm={12} sx={{ mt: 2 }}>
              <SimpleTokenActionCard
                title="Price & Liquidity"
                icon={<Box component="img" sx={{ p: 0.7 }} src={priceLiquidity} />}
                linkName="/home/token-dashboard"
              />
            </Grid>
            <Grid item lg={2} md={4} sm={12} sx={{ mt: 2 }}>
              <SimpleTokenActionCard
                title="External Interfaces"
                icon={<Box component="img" sx={{ p: 0.7 }} src={externalDataInterface} />}
                linkName="/home/token-dashboard"
              />
            </Grid>
          </Grid> */}
        </Grid>
        <Grid item lg={3} md={3} sm={3} sx={{ mt: 2 }}>
          <Card>
            <CardContent>
              <TokenStatusStepper
                status={currentToken.token_status_id.token_status}
                tokenName={currentToken.token_name}
                tokenSymbol={currentToken.token_symbol}
                tokenTotalSupply={currentToken.total_supply}
                tokenValue={currentToken.token_issue_price}
                tokenCurrency={currentToken.token_currency.currency_code}
                issuerPrimaryAddress={currentToken.issuer_id.primary_issuer_xinfin_address}
                // custodianPrimaryAddress={currentToken.custodian_id.primary_custodian_xinfin_address}
                kycproviderPrimaryAddress={
                  currentToken.kyc_provider_id.primary_kycprovider_xinfin_address
                }
                earlyRedemption={currentToken.allow_early_redemption}
                minSubscription={currentToken.minimum_subscription}
                paymentToken={currentToken.payment_token_id}
                issuanceSwapMultiple={currentToken.swap_ratio_issue}
                redemptionSwapMultiple={currentToken.swap_ratio_redeem}
                maturityPeriod={currentToken.application_processing_time}
                settlementPeriod={currentToken.tenure_months}
                collateral={currentToken.collateral_per_token}
                statusNumber={statusNumber}
                tokenId={currentToken.token_id}
              />
            </CardContent>
          </Card>
          <Box>
            {currentToken.deployment_address && (
              <ToggleBlockExplorer
                title="View on Block Scan"
                contractAdd={currentToken.deployment_address}
              />
            )}
            {currentToken.deployment_address && (
              <ToggleCard
                title="Pause"
                currPauseState={currentToken.is_paused}
                handleChange={isPause}
              />
            )}
            <ToggleCard
              title="On Chain Restrictions"
              disabled={toggleStatus}
              currPauseState={currentToken.onchain_restrictions}
              handleChange={async () => {
                const data = {};
                data.onchain_restrictions = !currentToken.onchain_restrictions;
                if (!currentToken.onchain_restrictions) {
                  data.onchain_kyc = true;
                }
                await updateToken(data);
              }}
            />
            {/* <ToggleCard
              title="On Chain KYC"
              disabled={toggleStatus}
              currPauseState={currentToken.onchain_kyc}
              handleChange={async () => {
                const data = {};

                if (currentToken.onchain_restrictions) {
                  enqueueSnackbar('KYC is not allowed when On Chain Restrictions is enabled', {
                    variant: 'warning'
                  });
                } else {
                  data.onchain_kyc = !currentToken.onchain_kyc;
                  await updateToken(data);
                }
              }}
            /> */}
            <ViewNotificationCard title="Notifications" tokenNotifications={tokenNotifications} />
            {/* <Button
                        variant="contained"
                        color="secondary"
                        // sx={{
                        //   width: '100%',
                        //   ml: 1,
                        //   display: 'flex',
                        //   flexDirection: 'row',
                        //   justifyContent: 'space-between'
                        // }}
                        onClick={() => {
                          navigate('/home/token-dashboard/token-notifications');
                        }}
                      >
                        <Typography sx={{ fontSize: '0.9rem' }}>Notifications</Typography>
                        <Typography sx={{ fontSize: '0.9rem' }}>
                          {tokenNotifications.length !== 0 ? tokenNotifications.length : 0}
                        </Typography>
                      </Button> */}
          </Box>
        </Grid>
      </Grid>

      {currentToken.token_id && (
        <PublishTokenDialog openPopup={openPopup} setOpenPopup={setOpenPopup} />
      )}
    </Container>
  );
};

export default TokenDashboard;

/* <BurnToken burn={burn} setBurn={setBurn} /> */

/* <BurnToken
            tokenId={currentToken.token_id}
            tokenSymbol={currentToken.token_symbol}
            tokenAddr={currentToken.deployment_address}
            openBurnPopUp={burn}
            closeBurnPopUp={closeBurnPopUp}
          /> */
