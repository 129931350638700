import { Box, Card, CardContent } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const LogoPreview = ({ imgSrc, handleClose }) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 3
  };

  return (
    <Box sx={style}>
      <Card>
        <CloseIcon
          sx={{ float: 'right', fontSize: '2rem', cursor: 'pointer' }}
          onClick={handleClose}
        />
        <CardContent sx={{ boxShadow: 'none' }}>
          <Box component="img" src={imgSrc} sx={{ height: '25rem' }} />
        </CardContent>
      </Card>
    </Box>
  );
};

export default LogoPreview;
