import React, { useState, useRef, useEffect } from 'react';
import {
  Grid,
  Divider,
  Typography,
  TextField,
  FormLabel,
  Box,
  FormControl,
  Select,
  FormHelperText,
  MenuItem,
  Alert,
  Container,
  Card,
  CardContent,
  Chip,
  Table,
  TableCell,
  TableBody,
  TableRow,
  TableHead,
  Radio,
  TableContainer,
  Checkbox
} from '@mui/material';
import LoadingActionButton from 'helpers/LoadingActionButton';
import { useFormik, Form, FormikProvider, Field } from 'formik';
import { useBusinessEntityState, useHttpApi, useAppState } from 'state';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';

const AssociateDocTypeTable = ({ rows }) => {
  const { enqueueSnackbar } = useSnackbar();
  const {
    getTokenLifecycleDocumentMappingsByIssueType,
    createUpdateTokenLifecycleDocumentMappingsByIssueType
  } = useHttpApi();
  const [mappings, setMappings] = useState([]);
  const { throwErrorMessage } = useAppState();

  const fetchMappings = async (id) => {
    const fetchedMappings = await getTokenLifecycleDocumentMappingsByIssueType(id);

    setMappings(fetchedMappings);
  };

  useEffect(() => {
    fetchMappings(rows.id);
  }, []);

  useEffect(() => {
    console.log('Row', rows, 'row name', rows.issue_type_name);
    console.log('Check map', mappings);
  }, [mappings]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      issue_type_name: rows.issue_type_name,
      doc_types: rows.doc_type.map((d) => {
        const mappedDoc = mappings.find((m) => m.document_type === d.id);
        return {
          document_type: d.id,
          doc_type_name: d.doc_type_name,
          is_mandatory: mappedDoc?.is_mandatory || false,
          token_lifecycle: 'ISSUE',
          issue_type: rows.id
        };
      })
    },
    onSubmit: async (values, { resetForm }) => {
      let count = 0;
      values.doc_types.forEach((r) => {
        if (r.is_mandatory === true) {
          if (r.token_lifecycle === '') {
            count += 1;
          }
        }
      });
      if (count >= 1) {
        enqueueSnackbar('Please Select Mandatory Stage', { variant: 'error' });
        return;
      }
      try {
        console.log('data', values.doc_types);
        const res = await createUpdateTokenLifecycleDocumentMappingsByIssueType(values.doc_types);
        console.log('res', res);
        enqueueSnackbar(`Document type, ${values.issue_type_name} mapped successfully`, {
          variant: 'success'
        });
      } catch (e) {
        throwErrorMessage(e);
      }
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;
  return (
    <>
      <CardContent>
        <FormikProvider value={formik}>
          <Form autoComplete="off" onSubmit={handleSubmit}>
            <Table size="small" aria-label="a dense table">
              <TableContainer>
                <TableHead>
                  <Typography sx={{ fontSize: '1rem', fontWeight: 'bold', ml: 2 }}>
                    {values.issue_type_name}
                  </Typography>
                </TableHead>
                <TableHead>
                  <TableRow>
                    <TableCell>Document Type</TableCell>
                    <TableCell>Madantory?</TableCell>
                    <TableCell>Madantory at Stage</TableCell>
                  </TableRow>
                </TableHead>
                {values.doc_types.map((doc, i) => (
                  <TableRow
                    key={doc.doc_type_name}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, height: '2px' }}
                  >
                    <TableCell
                      sx={{
                        fontStyle: 'italic',
                        color: '#7C7C7C'
                      }}
                    >
                      {doc.doc_type_name}
                    </TableCell>

                    <TableCell>
                      <Checkbox
                        color="success"
                        {...getFieldProps(`doc_types[${i}].is_mandatory`)}
                        checked={doc.is_mandatory}
                      />
                    </TableCell>

                    <TableCell>
                      <FormControl size="small" variant="outlined" fullWidth sx={{ mt: 1.5 }}>
                        <Select
                          displayEmpty
                          inputProps={{ readOnly: true }}
                          value="ISSUE"
                          {...getFieldProps(`doc_types[${i}].token_lifecycle`)}
                        >
                          <MenuItem value="ISSUE">Issuance</MenuItem>
                          {/* <MenuItem value="APPROVE_KYC">Approve KYC</MenuItem>
                          <MenuItem value="WHITELIST">Whitelist</MenuItem>
                          <MenuItem value="ISSUE">Issue</MenuItem>
                          <MenuItem value="REDEEM">Redeem</MenuItem> */}
                        </Select>
                      </FormControl>
                    </TableCell>
                  </TableRow>
                ))}
                <LoadingButton
                  type="submit"
                  variant="gradient"
                  fullWidth
                  size="large"
                  loadingPosition="start"
                  loading={isSubmitting}
                  sx={{ margin: 1, width: '15rem', height: '2.5rem' }}
                >
                  Update
                </LoadingButton>
              </TableContainer>
            </Table>
          </Form>
        </FormikProvider>
      </CardContent>
    </>
  );
};

export default AssociateDocTypeTable;
