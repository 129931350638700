/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import Page from 'components/Page';

import {
  Container,
  Card,
  Grid,
  Box,
  CardContent,
  Typography,
  TextField,
  FormLabel,
  Button,
  Divider,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  Alert,
  IconButton,
  InputAdornment
} from '@mui/material';
import Breadcrumbs from 'components/Breadcrumbs';
import {
  useHttpApi,
  useCoreTableState,
  useWeb3,
  useBusinessEntityState,
  useAuthStateShared,
  useAppState
} from 'state';
import { useFormik, Form, FormikProvider, Field } from 'formik';
import * as Yup from 'yup';
import NotifierSnackbar from 'components/Notifier/NotifierSnackbar';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { xdcRegex, leiRegex } from 'helpers/regex';
import { xdcToEthAddress, toChecksumAddress } from 'helpers/web3';
import { useNavigate } from 'react-router';
import { LeiInfoButton, XdcInfoButton } from 'helpers/infoButton';
import CommonOverlay from 'components/CommonOverlay';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { Icon } from '@iconify/react';
import { onlyOwner } from 'helpers/rbac';
import FocusError from 'components/FocusError';

const AddCreditInsurer = () => {
  const { createInsurer, checkUserNameAvailbility } = useHttpApi();
  const { fetchInsurer } = useBusinessEntityState();
  const { role } = useAuthStateShared();
  const { throwErrorMessage } = useAppState();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const creditInsurerLogo = useRef(null);

  const AddCreditInsurerSchema = Yup.object().shape({
    insurer_name: Yup.string()
      .required('Unique Credit Insurer Name is required')
      .max(30, 'Credit Insurer Name cannot be more than 30 characters'),
    insurer_country_code: Yup.string().required('Country Code is required'),
    primary_insurer_xinfin_address: Yup.string()
      .matches(xdcRegex, 'Please enter a valid XDCPay Wallet Address')
      .required('Credit Insurer XDCPay Wallet Address is required'),
    username: Yup.string().required('Username is required'),
    email: Yup.string().email('Enter A Valid Email').required('Email is Required'),
    password: Yup.string().required('Password is required'),
    insurer_logo: Yup.mixed().required('Credit Insurer logo is required')
  });

  const { addInsurer: addInsurerBlockchain } = useWeb3();

  // Formik
  const formik = useFormik({
    validate: (values) => {
      const errors = {};
      if (values.insurer_logo) {
        if (creditInsurerLogo && creditInsurerLogo.current.files[0].size > 50000) {
          errors.insurer_logo = 'File size greater than 50KB';
        }
      }

      return errors;
    },
    initialValues: {
      insurer_name: '',
      insurer_country_code: '',
      primary_insurer_xinfin_address: '',
      username: '',
      password: '',
      email: '',
      insurer_logo: ''
    },
    validationSchema: AddCreditInsurerSchema,
    onSubmit: async (data, { resetForm }) => {
      console.log('AddCreditInsurer', data);
      try {
        const userNameRes = await checkUserNameAvailbility(data.username);
        if (userNameRes.exist) {
          formik.setFieldError('username', 'Username already exist');
          formik.setFieldValue('username', '');
          const e = new Error('Username Already Exist');
          e.name = 'Username';
          throw e;
        }

        const creditInsurerLogoFile = creditInsurerLogo.current.files[0];
        data.insurer_logo = creditInsurerLogoFile;
        data.primary_insurer_xinfin_address = xdcToEthAddress(data.primary_insurer_xinfin_address);
        data.primary_insurer_xinfin_address = toChecksumAddress(
          data.primary_insurer_xinfin_address
        );
        const countryCode = countries.find((country) => country.id === data.insurer_country_code);
        console.log('countryCode', countryCode.country_code);

        // Django Call
        const _creditinsurer = await createInsurer(data);
        formik.setFieldValue('insurer_logo', '');
        if (_creditinsurer.insurer_id) {
          enqueueSnackbar('Credit Insurer Created Successfully', {
            variant: 'success'
          });
          fetchInsurer();
          resetForm();
          navigate('/masterdata/credit-insurer/');
        }
        // Blockchain Call
        const res = await addInsurerBlockchain(
          countryCode.country_code,
          data.primary_insurer_xinfin_address
        );
        console.log('res', res);
      } catch (e) {
        if (e.name !== 'Username') {
          formik.setFieldValue('insurer_logo', '');
          throwErrorMessage(e);
        } else {
          throwErrorMessage(e);
        }
      }
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;
  const { countries } = useCoreTableState();
  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const allowedRolesList = ['OWNER'];
  return (
    <Page title="Add Credit Insurer - Master Data">
      <CommonOverlay
        allowedRoles={allowedRolesList}
        overlayTitle="You don't have access to Add Primary Credit Insurer Account"
      />
      <Container sx={{ my: 2 }}>
        <Box sx={{ my: 2 }}>
          <Breadcrumbs aria-label="breadcrumb" pageHead="Add Credit Insurer" />
        </Box>
        {error && (
          <Box mb={4}>
            <Alert severity="error">{error}</Alert>
          </Box>
        )}
        <Card>
          <Container>
            <Typography
              sx={{
                fontSize: '1.125rem',
                fontWeight: 'bold',
                mt: 4,
                mb: 1,
                ml: 4
              }}
            >
              Credit Insurer Details
            </Typography>
            <Divider
              sx={{
                width: '100%',
                backgroundColor: '#f5f8fa',
                border: '2px solid #f5f8fa'
              }}
            />
            <FormikProvider value={formik}>
              <Form autoComplete="off" onSubmit={handleSubmit}>
                <CardContent sx={{ m: 2 }}>
                  <Grid container spacing={3} sx={{ width: '100%' }}>
                    <Grid item lg={6} md={6} xs={12} sm={12}>
                      <FormLabel>Name</FormLabel>
                      <TextField
                        sx={{ mt: 1.5 }}
                        fullWidth
                        size="small"
                        autoComplete="off"
                        type="text"
                        // placeholder="Enter Issuer Name"
                        {...getFieldProps('insurer_name')}
                        error={Boolean(touched.insurer_name && errors.insurer_name)}
                        helperText={touched.insurer_name && errors.insurer_name}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xs={12} sm={12}>
                      <FormLabel>Username</FormLabel>
                      <TextField
                        sx={{ mt: 1.5 }}
                        fullWidth
                        size="small"
                        autoComplete="off"
                        type="text"
                        {...getFieldProps('username')}
                        error={Boolean(touched.username && errors.username)}
                        helperText={touched.username && errors.username}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xs={12} sm={12}>
                      <FormLabel>Email</FormLabel>
                      <TextField
                        sx={{ mt: 1.5 }}
                        fullWidth
                        size="small"
                        autoComplete="off"
                        type="text"
                        {...getFieldProps('email')}
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xs={12} sm={12}>
                      <FormLabel>Password</FormLabel>
                      <TextField
                        sx={{ mt: 1.5 }}
                        fullWidth
                        size="small"
                        autoComplete="off"
                        type={showPassword ? 'text' : 'password'}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton onClick={handleShowPassword} edge="end">
                                <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                        {...getFieldProps('password')}
                        error={Boolean(touched.password && errors.password)}
                        helperText={touched.password && errors.password}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xs={12} sm={12}>
                      <FormLabel>
                        XinFin Address
                        <XdcInfoButton />
                      </FormLabel>
                      <TextField
                        sx={{ mt: 1.5 }}
                        fullWidth
                        type="text"
                        size="small"
                        // placeholder="Primary XinFin Address"
                        {...getFieldProps('primary_insurer_xinfin_address')}
                        error={Boolean(
                          touched.primary_insurer_xinfin_address &&
                            errors.primary_insurer_xinfin_address
                        )}
                        helperText={
                          touched.primary_insurer_xinfin_address &&
                          errors.primary_insurer_xinfin_address
                        }
                      />
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12} sx={{ pr: 1 }}>
                      <FormLabel>Logo</FormLabel>
                      <TextField
                        sx={{ mt: 1.5 }}
                        fullWidth
                        size="small"
                        autoComplete="off"
                        type="file"
                        inputRef={creditInsurerLogo}
                        inputProps={{ accept: '.jpg, .jpeg, .png, .svg' }}
                        {...getFieldProps('insurer_logo')}
                        id="insurer_logo"
                        error={Boolean(touched.insurer_logo && errors.insurer_logo)}
                        helperText={touched.insurer_logo && errors.insurer_logo}
                      />
                      <Grid container sx={{ width: '100%', mt: 2 }}>
                        <Grid item lg={4} md={4} sm={4}>
                          <Typography
                            sx={{
                              fontSize: '0.688rem',
                              textAlign: 'left',
                              color: '#161c2d'
                            }}
                          >
                            Maximum 50KB file size
                          </Typography>
                        </Grid>
                        <Grid item lg={8} md={8} sm={8}>
                          <Typography
                            sx={{
                              fontSize: '0.688rem',
                              textAlign: 'right',
                              color: '#8e8ea7'
                            }}
                          >
                            Accepted File Type .jpg, .jpeg, .png & .svg only
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item lg={6} md={6} xs={12} sm={12}>
                      <FormLabel>Country Code</FormLabel>

                      <FormControl size="small" variant="outlined" fullWidth sx={{ mt: 1.5 }}>
                        <Select
                          // placeholder="Select Country"
                          inputProps={{ 'aria-label': 'Without label' }}
                          {...getFieldProps('insurer_country_code')}
                          error={Boolean(
                            touched.insurer_country_code && errors.insurer_country_code
                          )}
                        >
                          {countries &&
                            countries.map((country, index) => {
                              return (
                                <MenuItem key={country.id} value={country.id}>
                                  {country.country_name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                        <FormHelperText sx={{ color: '#d32f2f' }}>
                          {touched.insurer_country_code && errors.insurer_country_code}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Box
                    sx={{
                      mt: 4,
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-end'
                    }}
                  >
                    <Button
                      sx={{
                        margin: 1,
                        borderColor: '#24ABDF',
                        px: 3,
                        width: '7.75rem',
                        height: '2.5rem'
                      }}
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        navigate('/masterdata/credit-insurer');
                      }}
                    >
                      Cancel
                    </Button>
                    <LoadingButton
                      type="submit"
                      variant="gradient"
                      disabled={!onlyOwner(role)}
                      fullWidth
                      size="large"
                      loadingPosition="start"
                      loading={isSubmitting}
                      sx={{ margin: 1, px: 3, width: '7.75rem', height: '2.5rem' }}
                    >
                      Save
                    </LoadingButton>
                  </Box>
                  <FocusError />
                </CardContent>
              </Form>
            </FormikProvider>
          </Container>
        </Card>
      </Container>
    </Page>
  );
};

export default AddCreditInsurer;
