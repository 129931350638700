// import * as React, {useEffect} from 'react';
import { useEffect, useState } from 'react';
import { useBusinessEntityState, useHttpApi } from 'state';
import EnhancedTable from 'components/tables/EnhancedTable';
// import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  Typography
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { Cell } from 'recharts';
import { ethToXdcAddress } from 'helpers/web3';
import CopyAddress from 'components/CopyAddress';

export default function InvestorTable() {
  const { allInvestors, fetchAllInvestors } = useBusinessEntityState();
  const { approveInvestorbyId } = useHttpApi();
  const { enqueueSnackbar } = useSnackbar();

  const [data, setData] = useState(allInvestors);
  const [skipPageReset, setSkipPageReset] = useState(false);
  const [showInvestorApprovalModal, setShowInvestorApprovalModal] = useState(false);

  const handleClose = () => {
    setShowInvestorApprovalModal(false);
  };

  useEffect(() => {
    fetchAllInvestors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ApprovalSubmitModal = ({ open, handleClose, row }) => {
    return (
      <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title">
        <Alert severity="info" sx={{ p: 2 }}>
          <AlertTitle>Approve Investor</AlertTitle>
          <DialogContent>
            Are you sure you want to Approve <b>{row.investor_name}</b> ?
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" color="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              variant="gradient"
              color="secondary"
              onClick={async () => {
                try {
                  const res = await approveInvestorbyId(row.investor_id);
                  console.log('🚀 ~ onClick={ ~ res:', res);
                  if (res) {
                    enqueueSnackbar('Investor Approved successfully', {
                      variant: 'success'
                    });
                    fetchAllInvestors();
                    handleClose();
                  }
                } catch (error) {
                  enqueueSnackbar('Failed to approve Investor', {
                    variant: 'error'
                  });
                }
              }}
              autoFocus
            >
              Approve
            </Button>
          </DialogActions>
        </Alert>
      </Dialog>
    );
  };

  const headCells = [
    {
      accessor: 'investor_name',
      Header: 'Investor Name',
      show: true
    },
    {
      accessor: 'xinfin_account',
      Header: 'Xinfin Address',
      show: true,
      width: 200,
      Cell: ({ value }) => <CopyAddress addr={ethToXdcAddress(value)} />
    },
    {
      accessor: 'investor_country_code.country_name',
      Header: 'Country',
      show: true
    },
    // {
    //   accessor: 'lei',
    //   Header: 'LEI',
    //   show: true
    // }
    {
      accessor: 'is_approved',
      Header: 'Approval Status',
      show: true,
      Cell: ({ value, row: { original } }) => {
        return value ? (
          // <Button
          //   disabled
          //   variant="contained"
          //   color="secondary"
          //   sx={{ width: '100px' }}
          //   // inputProps={{ readOnly: true }}
          // >
          //   <Typography sx={{ fontSize: '12px' }}>Approved</Typography>
          // </Button>
          <>
            <Chip
              label="Approved"
              color="success"
              sx={{ width: '6.25rem', borderRadius: 1.5, my: 0.6, height: '2rem' }}
            />
          </>
        ) : (
          <>
            <Button
              variant="contained"
              color="secondary"
              sx={{ width: '6.25rem', my: 0.6, height: '2rem' }}
            >
              <Typography
                sx={{ fontSize: '12px' }}
                onClick={() => setShowInvestorApprovalModal(true)}
              >
                Approve
              </Typography>
            </Button>
            <ApprovalSubmitModal
              open={showInvestorApprovalModal}
              handleClose={handleClose}
              row={original}
            />
          </>
        );
      }
    }
  ];

  const updateMyData = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true);
    setData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value
          };
        }
        return row;
      })
    );
  };

  return (
    <>
      <EnhancedTable
        tableTitle="Investors"
        columns={headCells}
        refreshFunction={fetchAllInvestors}
        data={allInvestors}
        setData={setData}
        updateMyData={updateMyData}
        skipPageReset={skipPageReset}
      />
    </>
  );
}
