import React, { useState, useEffect } from 'react';
import {
  IconButton,
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  DialogActions,
  Button,
  ButtonBase,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import xdcPayIcon from 'assets/images/icon-xdcpay.png';
import { useBusinessEntityState, useWeb3, useAppState } from 'state';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import SyncIcon from '@mui/icons-material/Sync';
import LoadingActionButton from 'helpers/LoadingActionButton';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';

const useDialog = () => {
  const [open, setOpen] = useState(false);
  const openDialog = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const props = {
    open,
    handleClose
  };
  return [openDialog, props];
};

const AddCollateralDailogBox = ({ open, handleClose, title, children }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { depositInsurerCollateral } = useWeb3();
  const { currCreditIssuer, currCreditInsurer } = useBusinessEntityState();
  const { throwErrorMessage } = useAppState();
  const formik = useFormik({
    initialValues: {
      collateralValue: ''
    },
    validationSchema: Yup.object().shape({
      collateralValue: Yup.number()
        .required('Collateral Value is required')
        .min(1, 'Collateral value must be positive number')
        .max(10000000000000000000, 'Collateral value must be less')
    }),

    onSubmit: async (values, { setSubmitting, resetForm }) => {
      try {
        const res = await depositInsurerCollateral(
          currCreditInsurer.primary_insurer_xinfin_address,
          currCreditIssuer.issuer_xinfin_address,
          values.collateralValue.toString()
        );
        if (res) {
          enqueueSnackbar('Collateral deposit Request submitted successfully', {
            variant: 'info'
          });
        }
      } catch (e) {
        throwErrorMessage(e);
      }
      setSubmitting(false);
      handleClose();
      resetForm();
    }
  });
  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <FormikProvider value={formik}>
          <Form autoComplete="off" onSubmit={handleSubmit}>
            <DialogTitle id="alert-dialog-title">Deposit</DialogTitle>
            <DialogContent sx={{ p: 3 }}>
              <DialogContentText>
                Enter Collateral Value , Please make sure you have same balance in your wallet.
              </DialogContentText>
              <TextField
                {...getFieldProps('collateralValue')}
                error={errors.collateralValue && touched.collateralValue}
                helperText={
                  errors.collateralValue && touched.collateralValue && errors.collateralValue
                }
                autoFocus
                margin="dense"
                id="name"
                type="number"
                fullWidth
                variant="standard"
              />
            </DialogContent>
            <DialogActions sx={{ mr: 3, mb: 3 }}>
              <Button
                variant="contained"
                color="secondary"
                sx={{ mr: 1, width: '9rem' }}
                onClick={() => {
                  formik.resetForm();
                  handleClose();
                }}
              >
                Cancel
              </Button>
              <LoadingButton
                sx={{ width: '9rem' }}
                loadingPosition="start"
                variant="gradient"
                type="submit"
                loading={isSubmitting}
              >
                Deposit
              </LoadingButton>
            </DialogActions>
          </Form>
        </FormikProvider>
      </Dialog>
    </>
  );
};

const RemoveCollateralDailogBox = ({ open, handleClose, title, children }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { withdrawInsurerCollateral } = useWeb3();
  const { currCreditIssuer, currCreditInsurer } = useBusinessEntityState();
  const { throwErrorMessage } = useAppState();
  const formik = useFormik({
    initialValues: {
      collateralValue: ''
    },
    validationSchema: Yup.object().shape({
      collateralValue: Yup.number()
        .required('Collateral Value is required')
        .min(1, 'Collateral value must be positive number')
        .max(10000000000000000000, 'Collateral value must be less')
    }),

    onSubmit: async (values, { setSubmitting, resetForm }) => {
      try {
        const res = await withdrawInsurerCollateral(
          currCreditInsurer.primary_insurer_xinfin_address,
          currCreditIssuer.issuer_xinfin_address,
          values.collateralValue.toString()
        );
        if (res) {
          enqueueSnackbar('Collateral withdrawal Request submitted successfully', {
            variant: 'info'
          });
        }
      } catch (e) {
        throwErrorMessage(e);
      }
      setSubmitting(false);
      handleClose();
      resetForm();
    }
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <FormikProvider value={formik}>
          <Form autoComplete="off" onSubmit={handleSubmit}>
            <DialogTitle id="alert-dialog-title">Withdraw</DialogTitle>
            <DialogContent sx={{ p: 3 }}>
              <DialogContentText>
                Enter Collateral Value , Please make sure you have same balance.
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                type="number"
                {...getFieldProps('collateralValue')}
                error={errors.collateralValue && touched.collateralValue}
                helperText={
                  errors.collateralValue && touched.collateralValue && errors.collateralValue
                }
                fullWidth
                variant="standard"
              />
            </DialogContent>
            <DialogActions sx={{ mr: 3, mb: 3 }}>
              <Button
                variant="contained"
                color="secondary"
                sx={{ mr: 1, width: '9rem' }}
                onClick={() => {
                  formik.resetForm();
                  handleClose();
                }}
              >
                Cancel
              </Button>
              <LoadingButton
                sx={{ width: '9rem' }}
                loadingPosition="start"
                variant="gradient"
                type="submit"
                loading={isSubmitting}
              >
                Withdraw
              </LoadingButton>
            </DialogActions>
          </Form>
        </FormikProvider>
      </Dialog>
    </>
  );
};

const InsurerCollateral = ({ fetchIssuance }) => {
  const [openDialog, dialogProps] = useDialog(); // custom Hook
  const [openDialogRemove, dialogPropsRemove] = useDialog();
  const { lockedInsurerCollateralBalanceByIssuer, insurerCollateralBalanceByIssuer, web3 } =
    useWeb3();
  const { currCreditIssuer, currCreditInsurer, insurerIssuerIssuanceEscrowOrder } =
    useBusinessEntityState();
  const [collateral, setCollateral] = useState(0);
  const [lockedCollateral, setLockedCollateral] = useState(0);
  const [totalCollateralRequired, setTotalCollateralRequired] = useState(0);
  const { throwErrorMessage } = useAppState();
  console.log('Current Credit Issuer', currCreditIssuer);
  console.log('Current Credit Insurer', currCreditInsurer);

  const _fetchData = async () => {
    try {
      const res = await insurerCollateralBalanceByIssuer(
        currCreditInsurer.primary_insurer_xinfin_address,
        currCreditIssuer.issuer_xinfin_address
      );

      const res1 = await lockedInsurerCollateralBalanceByIssuer(
        currCreditInsurer.primary_insurer_xinfin_address,
        currCreditIssuer.issuer_xinfin_address
      );

      if (res) {
        setCollateral(web3.utils.fromWei(res, 'ether'));
      }
      if (res1) {
        setLockedCollateral(web3.utils.fromWei(res1, 'ether'));
      }
    } catch (e) {
      throwErrorMessage(e);
    }
  };

  const fetchData = () => {
    fetchIssuance();
    _fetchData();
  };

  useEffect(() => {
    if (insurerIssuerIssuanceEscrowOrder.length !== 0) {
      let totalCollateral = 0;
      insurerIssuerIssuanceEscrowOrder.map((item) => {
        totalCollateral += item.insurer_collateral;
        return item;
      });
      setTotalCollateralRequired(totalCollateral);
    }
    _fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currCreditIssuer, insurerIssuerIssuanceEscrowOrder]);

  return (
    <>
      <IconButton onClick={fetchData}>
        <SyncIcon />
      </IconButton>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Asset</TableCell>
            <TableCell>Total Collateral Req.</TableCell>
            <TableCell>Deposited Collateral</TableCell>
            <TableCell>Locked Value</TableCell>
            <TableCell>Unlocked Value</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  m: 0,
                  p: 0,
                  alignItems: 'center'
                }}
              >
                <Box
                  component="img"
                  sx={{
                    pr: 2
                  }}
                  src={xdcPayIcon} // TODO: Change icon
                />
                XDC
              </Box>
            </TableCell>
            <TableCell>
              {/* {Number(totalCollateralRequired)} */}
              {Number(totalCollateralRequired) - Number(collateral) < 0
                ? `${Math.abs(Number(totalCollateralRequired) - Number(collateral))} (Excess)`
                : Number(totalCollateralRequired) - Number(collateral)}
              {/* {Number(totalCollateralRequired) - (Number(lockedCollateral) + Number(collateral))} */}
            </TableCell>
            <TableCell>{Number(lockedCollateral) + Number(collateral)}</TableCell>
            <TableCell>{lockedCollateral}</TableCell>
            <TableCell>{collateral}</TableCell>

            <TableCell>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  m: 0,
                  p: 0,
                  alignItems: 'center'
                }}
              >
                <AddCollateralDailogBox {...dialogProps} />
                <IconButton onClick={openDialog} sx={{ mt: -1 }} aria-label="subs detail">
                  <AddIcon sx={{ fontSize: '2.188rem', m: 0, p: 1, backgroundColor: '#F5F8FA' }} />
                </IconButton>
                <RemoveCollateralDailogBox {...dialogPropsRemove} />
                <IconButton onClick={openDialogRemove} sx={{ mt: -1 }} aria-label="subs detail">
                  <RemoveIcon
                    sx={{ fontSize: '2.188rem', m: 0, p: 1, backgroundColor: '#F5F8FA' }}
                  />
                </IconButton>
              </Box>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </>
  );
};

export default InsurerCollateral;
