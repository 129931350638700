import Page from 'components/Page';
import React from 'react';
import { Card, Container, CardContent } from '@mui/material';
import Breadcrumbs from 'components/Breadcrumbs';
import IssuerTable from 'components/masterData/Issuer/IssuerTable';
import CommonOverlay from 'components/CommonOverlay';
import FundManagerTable from 'components/masterData/fundManager/FundManagerTable';

const AllFundManagers = () => {
  const allowedRolesList = ['OWNER', 'PRIMARY_ISSUER'];
  return (
    <Page title="Fund Manager - Master Data">
      <CommonOverlay
        allowedRoles={allowedRolesList}
        overlayTitle="You don't have access to View Primary Fund Manager Account"
      />
      <Container>
        <Breadcrumbs pageHead="All Fund Managers" />

        <Card sx={{ mt: 2 }}>
          <CardContent>
            <FundManagerTable />
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
};

export default AllFundManagers;
