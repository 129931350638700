import React, { useState, useRef, useEffect } from 'react';
import {
  Grid,
  Divider,
  Typography,
  TextField,
  FormLabel,
  Box,
  FormControl,
  Select,
  FormHelperText,
  MenuItem,
  Alert
} from '@mui/material';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { useBusinessEntityState, useHttpApi, useAppState } from 'state';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router';
import InsurersOfIssuerTable from './InsurersOfIssuerTable';

const MapInsurertoIssuer = () => {
  const { onChainInsurers: insurers, currentIssuer } = useBusinessEntityState();
  const { createIssuerInsurerMapping } = useHttpApi();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { throwErrorMessage } = useAppState();
  const contractDoc = useRef(null);
  const [error, setError] = useState('');
  const MapInsurertoIssuerSchema = Yup.object().shape({
    insurer_id: Yup.string().required('Select Insurer'),
    insurer_contri: Yup.number()
      .required('Insurer Contribution is required')
      .max(100, 'Contribution cannot be more than 100%'),
    issuer_contri: Yup.number().required('Issuer Contribution is required'),
    fees_per_month: Yup.number().required('Fees Per Month is required')
    /*  contract_doc_hash: Yup.mixed()
      .required('Upload Contract Document')
      .test('type', 'Only .pdf formats are accepted', (value) => {
        return value && value.substring(value.lastIndexOf('.')) === '.pdf';
      }) */
  });
  const formik = useFormik({
    initialValues: {
      issuer_id: currentIssuer?.issuer_id,
      insurer_id: '',
      insurer_contri: '',
      issuer_contri: '',
      fees_per_month: ''
      // contract_doc_hash: ''
    },
    validationSchema: MapInsurertoIssuerSchema,
    onSubmit: async (data, { resetForm }) => {
      console.log(data);
      try {
        /* const contractDocFile = contractDoc.current.files[0];
        data.contract_doc_hash = contractDocFile;
        console.log(JSON.stringify(data, null, ' ')); */
        data.issuer_id = currentIssuer?.issuer_id;
        await createIssuerInsurerMapping(data);
        formik.setFieldValue('contract_doc_hash', '');
        resetForm();
        // navigate('/masterdata/issuer');
        enqueueSnackbar('Insurer Mapped successfully', {
          variant: 'success'
        });
      } catch (e) {
        formik.setFieldValue('contract_doc_hash', '');
        throwErrorMessage(e);
      }
    }
  });
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  useEffect(() => {
    if (formik.values.insurer_contri <= 100) {
      formik.setFieldValue('issuer_contri', 100 - values.insurer_contri);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.insurer_contri]);

  return (
    <>
      <Typography sx={{ fontWeight: 600 }}>Add Credit Insurer</Typography>
      <Divider
        sx={{
          width: '100%',
          backgroundColor: '#f5f8fa',
          border: '2px solid #f5f8fa',
          my: 2
        }}
      />
      <FormikProvider value={formik}>
        <Form autoComplete="off" onSubmit={handleSubmit}>
          {error && (
            <Box mb={4}>
              <Alert
                severity="error"
                sx={{
                  textTransform: 'capitalize'
                }}
              >
                {error}
              </Alert>
            </Box>
          )}

          <Grid container spacing={3} sx={{ width: '100%' }}>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <FormLabel>Insurer</FormLabel>
              <FormControl size="small" variant="outlined" fullWidth sx={{ mt: 1.5 }}>
                <Select
                  displayEmpty
                  inputProps={{ 'aria-label': 'Without label' }}
                  {...getFieldProps('insurer_id')}
                  id="insurer_id"
                  error={Boolean(touched.insurer_id && errors.insurer_id)}
                  helperText={touched.insurer_id && errors.insurer_id}
                >
                  {insurers.map((insurer) => {
                    return (
                      <MenuItem
                        id="insurer_id_menu_items"
                        value={insurer.insurer_id}
                        key={insurer.insurer_id}
                      >
                        {insurer.insurer_name}
                      </MenuItem>
                    );
                  })}
                </Select>

                <FormHelperText sx={{ color: '#d32f2f' }}>
                  {touched.insurer_id && errors.insurer_id}
                </FormHelperText>
              </FormControl>
            </Grid>
            {/*   <Grid item lg={6} md={6} sm={12} xs={12}>
              <FormLabel>Contract Document</FormLabel>
              <TextField
                sx={{ mt: 1.5 }}
                fullWidth
                size="small"
                autoComplete="off"
                type="file"
                inputRef={contractDoc}
                inputProps={{ accept: '.pdf' }}
                {...getFieldProps('contract_doc_hash')}
                id="contract_doc_hash"
                error={Boolean(touched.contract_doc_hash && errors.contract_doc_hash)}
                helperText={touched.contract_doc_hash && errors.contract_doc_hash}
              />
              <Grid container sx={{ width: '100%', mt: 2 }}>
                <Grid item lg={4} md={4} sm={4}>
                  <Typography
                    sx={{
                      fontSize: '0.688rem',
                      textAlign: 'left',
                      color: '#161c2d'
                    }}
                  >
                    Maximum 50KB file size
                  </Typography>
                </Grid>
                <Grid item lg={8} md={8} sm={8}>
                  <Typography
                    sx={{
                      fontSize: '0.688rem',
                      textAlign: 'right',
                      color: '#8e8ea7'
                    }}
                  >
                    Accepted File Type .jpg, .jpeg, .png & .svg only
                  </Typography>
                </Grid>
              </Grid>
            </Grid> */}
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <FormLabel>Insurer Contribution (%)</FormLabel>
              <TextField
                sx={{ mt: 1.5 }}
                fullWidth
                size="small"
                autoComplete="off"
                type="number"
                {...getFieldProps('insurer_contri')}
                error={Boolean(touched.insurer_contri && errors.insurer_contri)}
                helperText={touched.insurer_contri && errors.insurer_contri}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <FormLabel>Issuer Contribution (%)</FormLabel>
              <TextField
                sx={{ mt: 1.5 }}
                fullWidth
                size="small"
                autoComplete="off"
                type="number"
                inputProps={{ readOnly: true }}
                {...getFieldProps('issuer_contri')}
                error={Boolean(touched.issuer_contri && errors.issuer_contri)}
                helperText={touched.issuer_contri && errors.issuer_contri}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <FormLabel>Insurer Fees (%)</FormLabel>
              <TextField
                sx={{ mt: 1.5 }}
                fullWidth
                size="small"
                autoComplete="off"
                type="number"
                {...getFieldProps('fees_per_month')}
                error={Boolean(touched.fees_per_month && errors.fees_per_month)}
                helperText={touched.fees_per_month && errors.fees_per_month}
              />
            </Grid>
          </Grid>
          <Box display="flex" justifyContent="flex-end">
            <LoadingButton
              sx={{
                width: '8rem',
                height: '2.5rem',
                mt: 4
              }}
              variant="gradient"
              loadingPosition="start"
              id="create-token"
              type="submit"
            >
              Add
            </LoadingButton>
          </Box>
        </Form>
      </FormikProvider>
      <Divider
        sx={{
          width: '100%',
          backgroundColor: '#f5f8fa',
          border: '2px solid #f5f8fa',
          my: 2
        }}
      />
      <Typography sx={{ fontWeight: 600, my: 2 }}>View Mapped Insurers</Typography>
      <InsurersOfIssuerTable />
    </>
  );
};

export default MapInsurertoIssuer;
