// import * as React, {useEffect} from 'react';
import { useEffect, useState } from 'react';
import { useBusinessEntityState } from 'state';
import EnhancedTable from 'components/tables/EnhancedTable';
// import Chip from 'theme/overrides/Chip';
import { TableCell, Chip } from '@mui/material';

// const marginbottom = {
//   mb: 1
// };

function KycChip(props) {
  if (props.statusname === 'VERIFIED') {
    return <MarginnedChip label="VERIFIED" color="success" />;
  } else {
    return <MarginnedChip label="APPLIED" color="warning" />;
  }
}

function MarginnedChip(props) {
  return <Chip label={props.label} color={props.color} />;
}

export default function XinFinTable() {
  const { allXinfinAccounts, fetchAllXinfinAccounts } = useBusinessEntityState();
  const [data, setData] = useState(allXinfinAccounts);
  const [skipPageReset, setSkipPageReset] = useState(false);
  const [filteredAccounts, setFilteredAccounts] = useState([]);

  useEffect(() => {
    fetchAllXinfinAccounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const headCells = [
    {
      accessor: 'xinfin_account_address',
      Header: 'Xinfin Address',
      show: true
    },
    {
      accessor: 'is_eoa',
      Header: 'Is EOA',
      show: true,
      Cell: (c) =>
        c.row.values.is_eoa ? (
          <MarginnedChip label="Yes" color="success" />
        ) : (
          <MarginnedChip label="No" color="warning" />
        )
    },
    {
      accessor: 'is_multi_sig_wallet',
      Header: 'Is Multi Sig Wallet',
      show: true,
      Cell: (c) =>
        c.row.values.is_multi_sig_wallet ? (
          <MarginnedChip label="Yes" color="success" />
        ) : (
          <MarginnedChip label="No" color="warning" />
        )
    },
    {
      accessor: 'is_valid',
      Header: 'Is Valid',
      show: true,
      Cell: (c) =>
        c.row.values.is_valid ? (
          <MarginnedChip label="Yes" color="success" />
        ) : (
          <MarginnedChip label="No" color="warning" />
        )
    },
    {
      accessor: 'kyc_status.kyc_status_name',
      Header: 'KYC Status',
      show: true,
      Cell: (c) => <KycChip statusname={c.row.original.kyc_status.kyc_status_name} />
    }
  ];

  const updateMyData = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true);
    setData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value
          };
        }
        return row;
      })
    );
  };

  return (
    <>
      <EnhancedTable
        tableTitle="Investors"
        columns={headCells}
        refreshFunction={fetchAllXinfinAccounts}
        data={data}
        setData={setData}
        updateMyData={updateMyData}
        skipPageReset={skipPageReset}
      />
    </>
  );
}
