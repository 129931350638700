import { Container, Card, Box, Button, CardContent } from '@mui/material';
import Page from 'components/Page';
import React from 'react';
import Breadcrumbs from 'components/Breadcrumbs';
import { useBusinessEntityState } from 'state';
import { useNavigate } from 'react-router';
import TokenTable from 'components/issuerDashboard/TokenTable';
import AllInvestorTableForDocMgt from 'components/DocMangement/AllInvestorTableForDocMgt';

const DocMgtDashboard = () => {
  const navigate = useNavigate();
  const { currentToken: token } = useBusinessEntityState();
  return (
    <>
      <Page title="Document Mangement">
        <Container sx={{ mt: 3 }}>
          <Breadcrumbs aria-label="breadcrumb" pageHead="Manage Token Documents" />
          {/* Token Table */}
          <TokenTable currToken={token} />

          <Box sx={{ width: '100%', typography: 'body1', mt: 3 }}>
            <Card sx={{ p: 2 }}>
              <AllInvestorTableForDocMgt />
            </Card>
          </Box>
          <Card sx={{ mt: 4, p: 0 }}>
            <CardContent
              sx={{
                p: '1 !important',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                flexDirection: 'row'
              }}
            >
              <Button
                variant="contained"
                sx={{
                  mx: 2,
                  px: 3,
                  fontWeight: 400,
                  width: '7.75rem'
                }}
                color="secondary"
                onClick={() => {
                  navigate('/home/token-dashboard');
                }}
              >
                Close
              </Button>
            </CardContent>
          </Card>
        </Container>
      </Page>
    </>
  );
};

export default DocMgtDashboard;
