import { CardContent, Divider, Typography, Card, Box, Grid, Link } from '@mui/material';
import React, { useEffect } from 'react';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useAppState } from 'state';
import { useNavigate } from 'react-router-dom';
import { compareAsc, format, formatDistance, subYears } from 'date-fns';

const Notification = () => {
  const { notifications } = useAppState();
  const navigate = useNavigate();

  function timeGo(date) {
    const todayDate = new Date();
    const yearOld = subYears(todayDate, 1);
    const comapredDate = compareAsc(new Date(date), yearOld);

    if (comapredDate === 1) {
      return `${formatDistance(new Date(date), new Date())} ago`;
    } else {
      return format(new Date(date), 'MMM dd, yyyy');
    }
  }

  return (
    <>
      <Card sx={{ p: 1 }}>
        <CardContent>
          <Typography
            fontWeight="bold"
            variant="body1"
            sx={{
              pb: 1
            }}
          >
            Notifications
          </Typography>
          <Divider />
          {notifications.length <= 0 && (
            <Box sx={{ textAlign: 'center', width: 400, p: 1 }}>
              <Typography variant="body2">No new notifications</Typography>
            </Box>
          )}
          {notifications.slice(0, 5).map((val) => {
            console.log('val', val);
            return (
              <Link
                color="inherit"
                underline="hover"
                sx={{
                  cursor: 'pointer'
                }}
              >
                <Box
                  display="flex"
                  sx={{
                    p: 1
                  }}
                >
                  <FiberManualRecordIcon
                    fontSize="inherit"
                    color="secondary"
                    sx={{
                      mr: 2
                    }}
                  />

                  <Box>
                    <Typography>{val.body}</Typography>
                    <Typography sx={{ color: '#949494', fontSize: '0.75rem' }}>
                      {timeGo(val.created_at)}
                    </Typography>
                  </Box>
                </Box>
                <Divider />
              </Link>
            );
          })}

          <Link
            color="inherit"
            underline="hover"
            sx={{
              cursor: 'pointer'
            }}
            onClick={() => {
              navigate('/home/notifications');
            }}
          >
            <Typography
              fontWeight="bold"
              variant="body2"
              textAlign="center"
              sx={{
                pt: 2
              }}
            >
              View Notifications
            </Typography>
          </Link>
        </CardContent>
      </Card>
    </>
  );
};

export default Notification;
