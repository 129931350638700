import { Box, Card, CardContent, Container, Divider, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useBusinessEntityState } from 'state';

const LineInfo = ({ infoKey, infoValue }) => {
  return (
    <>
      <Divider />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <Typography variant="p" sx={{ fontWeight: 'bold' }}>
          {infoKey}
        </Typography>
        <Typography variant="p">{infoValue}</Typography>
      </Box>
    </>
  );
};

const PoolStatus = () => {
  const { poolHoldings, poolDetails, poolAssetDetails, currentSubsToken } =
    useBusinessEntityState();
  const [statusValue, setStatusValue] = useState({
    assetValue: 0,
    avgfinancingFee: 0,
    avgMaturity: 0
  });
  console.log(currentSubsToken);

  useEffect(() => {
    let _assetValue = 0;
    let _sumFinancingFee = 0;
    let _sumAvgMaturity = 0;
    if (poolAssetDetails.length > 0) {
      poolAssetDetails.forEach((asset) => {
        if (asset) {
          _assetValue += asset.amount_invested;
          _sumFinancingFee += parseFloat(asset.financing_fee) * parseFloat(asset.amount_invested);
          _sumAvgMaturity +=
            ((new Date(asset.maturity_date) - new Date(asset.financing_date)) /
              (1000 * 60 * 60 * 24)) *
            parseFloat(asset.amount_invested);
        }
      });
    }
    const _avgFinancingFee = _assetValue > 0 ? _sumFinancingFee / _assetValue : 0;
    const _avgMaturity = _assetValue > 0 ? (_sumAvgMaturity / _assetValue) * 0.032855 : 0;
    setStatusValue({
      ...statusValue,
      assetValue: _assetValue,
      avgfinancingFee: _avgFinancingFee,
      avgMaturity: _avgMaturity
    });
  }, [poolAssetDetails]);

  return (
    <Card sx={{ mt: 4 }}>
      <CardContent sx={{ p: 4 }}>
        <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
          Pool Status
        </Typography>
        <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 2 }}>
          Assets
        </Typography>
        <LineInfo
          infoKey="Asset value"
          infoValue={`${statusValue.assetValue} ${currentSubsToken.token_currency.currency_code}`}
        />
        <LineInfo
          infoKey="Number of assets"
          infoValue={poolAssetDetails ? poolAssetDetails.length : 'NA'}
        />
        <LineInfo infoKey="Average Financing Fee" infoValue={`${statusValue.avgfinancingFee} %`} />
        <LineInfo
          infoKey="Average maturity"
          infoValue={`${parseFloat(statusValue.avgMaturity).toFixed(2)} months`}
        />
      </CardContent>
    </Card>
  );
};

export default PoolStatus;
