/* eslint-disable react/prop-types */
import {
  Dialog,
  DialogTitle,
  Table,
  TableCell,
  Typography,
  TableRow,
  IconButton,
  styled,
  Button,
  DialogActions,
  DialogContent
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import { useBusinessEntityState, useHttpApi, useWeb3, useCoreTableState, useAppState } from 'state';
import { ethToXdcAddress, stringToBytes32 } from 'helpers/web3';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import { daysToSeconds, parseTokenDecimal } from 'helpers/numbers';
import { isDevEnv } from 'helpers/dev';
import { USE_TIME_IN_DAYS } from 'config';
import NAIcon from 'components/NAIcon';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(0.25)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(0.25)
  }
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired
};

const PublishTokenDialog = (props) => {
  const { openPopup, setOpenPopup } = props;
  const [rules, setRules] = useState(null);
  const { getRuleTemplateByToken, getTokenDetailsById, fetchCurrentToken } = useHttpApi();
  const { currentToken: token } = useBusinessEntityState();
  const { throwErrorMessage } = useAppState();
  const { paymentTokens } = useCoreTableState();
  const { enqueueSnackbar } = useSnackbar();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  useEffect(() => {
    const _fetchData = async () => {
      setRules(await getRuleTemplateByToken(token.token_id));
    };
    _fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleClose = () => {
    setOpenPopup(false);
  };
  const navigate = useNavigate();

  const { publishToken, account, web3 } = useWeb3();

  // console.log('token from publish', token);

  const collateralValue = parseTokenDecimal(
    token.collateral_per_token ? token.collateral_per_token : 0,
    18
  );

  const issueSwapRatio = parseTokenDecimal(
    token.swap_ratio_issue === '0.00' ? 0 : token?.swap_ratio_issue,

    token.payment_token_id !== null ? token?.payment_token_id.decimals : paymentTokens[0]?.decimals
  );

  const redeemSwapRatio = parseTokenDecimal(
    token.swap_ratio_redeem === '0.00' ? 0 : token.swap_ratio_redeem,
    token.payment_token_id !== null ? token.payment_token_id.decimals : paymentTokens[0].decimals
  );
  // console.log('checl', token.collateral_per_token);
  return (
    <>
      <BootstrapDialog
        open={openPopup}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          sx={{ fontWeight: '900' }}
          onClose={handleClose}
        >
          Publish Token on XinFin Blockchain
        </BootstrapDialogTitle>

        <DialogContent>
          <Table aria-label="simple table">
            <TableRow>
              <TableCell>
                <Typography sx={{ fontWeight: '600' }}>Token Name</Typography>
              </TableCell>
              <TableCell>
                <Typography>{token.token_name}</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography sx={{ fontWeight: '600' }}>Token Symbol</Typography>
              </TableCell>
              <TableCell>
                <Typography>{token.token_symbol}</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography sx={{ fontWeight: '600' }}>Token Supply</Typography>
              </TableCell>
              <TableCell>
                <Typography>{token.total_supply}</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography sx={{ fontWeight: '600' }}>Decimals</Typography>
              </TableCell>
              <TableCell>
                <Typography>{token.decimals}</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography sx={{ fontWeight: '600' }}>Token Value</Typography>
              </TableCell>
              <TableCell>
                <Typography>{token.token_value}</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography sx={{ fontWeight: '600' }}>Currency</Typography>
              </TableCell>
              <TableCell>
                <Typography>
                  {token.token_currency ? token.token_currency.currency_code : 'NA'}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography sx={{ fontWeight: '600' }}>Issuer Address</Typography>
              </TableCell>
              <TableCell>
                <Typography>
                  {ethToXdcAddress(token.issuer_id.primary_issuer_xinfin_address)}
                </Typography>
              </TableCell>
            </TableRow>
            {/* <TableRow>
              <TableCell>
                <Typography sx={{ fontWeight: '600' }}>Custodian Address</Typography>
              </TableCell>
              <TableCell>
                <Typography>
                  {ethToXdcAddress(token.custodian_id.primary_custodian_xinfin_address)}
                </Typography>
              </TableCell>
            </TableRow> */}
            {/* <TableRow>
              <TableCell>
                <Typography sx={{ fontWeight: '600' }}>KYCProvider Address</Typography>
              </TableCell>
              <TableCell>
                <Typography>
                  {ethToXdcAddress(token.kyc_provider_id.primary_kycprovider_xinfin_address)}
                </Typography>
              </TableCell>
            </TableRow> */}
            <TableRow>
              <TableCell>
                <Typography sx={{ fontWeight: '600' }}>Early Redemption</Typography>
              </TableCell>
              <TableCell>
                <Typography>{token.allow_early_redemption ? 'True' : 'False'}</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography sx={{ fontWeight: '600' }}>Min Subs</Typography>
              </TableCell>
              <TableCell>
                <Typography>{token.minimum_subscription}</Typography>
              </TableCell>
            </TableRow>

            {token?.payment_token_id?.contract_address && (
              <>
                <TableRow>
                  <TableCell>
                    <Typography sx={{ fontWeight: '600' }}>Payment Tokens</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      {token.payment_token_id.contract_name} |
                      {ethToXdcAddress(token.payment_token_id.contract_address)}
                    </Typography>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <Typography sx={{ fontWeight: '600' }}>Issuance Swap Ratio</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{token.swap_ratio_issue}</Typography>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <Typography sx={{ fontWeight: '600' }}>Redeem Swap Ratio</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{token.swap_ratio_redeem}</Typography>
                  </TableCell>
                </TableRow>
              </>
            )}

            <TableRow>
              <TableCell>
                <Typography sx={{ fontWeight: '600' }}>Settlement Period</Typography>
              </TableCell>
              <TableCell>
                <Typography>{token.application_processing_time} (Seconds)</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography sx={{ fontWeight: '600' }}>Maturity Period</Typography>
              </TableCell>
              <TableCell>
                <Typography>{token.tenure_months}</Typography>
              </TableCell>
            </TableRow>

            {token?.payment_token_id?.contract_address && (
              <>
                <TableRow>
                  <TableCell>
                    <Typography sx={{ fontWeight: '600' }}>XDC Collateral Per Token</Typography>
                  </TableCell>
                  <TableCell>
                    {token.collateral_per_token === null ? (
                      <NAIcon />
                    ) : (
                      <Typography>{token.collateral_per_token}</Typography>
                    )}
                  </TableCell>
                </TableRow>
              </>
            )}
          </Table>
        </DialogContent>
        <DialogActions sx={{ mt: 2, mb: 2 }}>
          <Button
            autoFocus
            sx={{ width: '7.75rem', height: '3.125rem' }}
            variant="contained"
            color="secondary"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <LoadingButton
            autoFocus
            loadingPosition="start"
            loading={isSubmitting}
            variant="gradient"
            sx={{ width: '7.75rem', height: '3.125rem' }}
            onClick={async () => {
              try {
                if (account === token.issuer_id.primary_issuer_xinfin_address) {
                  // Trying Publishing token from Issuer Account without restriction

                  // if (token.issuer_id.primary_issuer_xinfin_address) {
                  setIsSubmitting(true);
                  const insurerAccount =
                    token.collateral_provider_entity === null
                      ? token.issuer_id.primary_issuer_xinfin_address
                      : token.collateral_provider_entity.primary_insurer_xinfin_address;
                  const issuerCollateral = token.issuer_contri !== null ? token.issuer_contri : 0;
                  const insurerCollateral = parseTokenDecimal(
                    token.insurer_contri !== null ? token.insurer_contri : 0,
                    18
                  );
                  console.log({
                    insurerAccount,
                    issuerCollateral,
                    insurerCollateral
                  });
                  const _paymentTokenList = [];
                  const _issuanceRatio = [];
                  const _redeemRatio = [];
                  const allowedCountries = rules.allowed_countries.map((country) =>
                    stringToBytes32(country)
                  );
                  const classification = {
                    isExempted: rules.is_exempted,
                    isAccredited: rules.is_accredited,
                    isAffiliated: rules.is_affiliated
                  };
                  if (token.payment_token_id) {
                    _paymentTokenList.push(token.payment_token_id.contract_address);
                    _issuanceRatio.push(issueSwapRatio);
                    _redeemRatio.push(redeemSwapRatio);
                  } else {
                    _paymentTokenList.push(paymentTokens[0].contract_address);
                    _issuanceRatio.push(issueSwapRatio);
                    _redeemRatio.push(redeemSwapRatio);
                  }
                  console.log({ USE_TIME_IN_DAYS });
                  const tenure = USE_TIME_IN_DAYS
                    ? daysToSeconds(Number(token.tenure_months))
                    : Number(token.tenure_months);
                  const settlement = USE_TIME_IN_DAYS
                    ? daysToSeconds(Number(token.application_processing_time))
                    : Number(token.application_processing_time);

                  const tokenType = token.token_type === 'POOL_LENDING' ? '1' : '0';
                  const liquidityPool =
                    token.liquidity_pool !== null
                      ? token.liquidity_pool.liquidity_pool_xinfin_address
                      : '0x0000000000000000000000000000000000000000';
                  const issuerSettlementAddress =
                    token.token_type === 'PROXY_TOKEN'
                      ? token.liquidity_pool.liquidity_pool_xinfin_address
                      : token.issuer_id.primary_issuer_xinfin_address;
                  console.log(
                    '🚀 ~ file: PublishTokenDialog.js ~ line 351 ~ onClick={ ~ issuerSettlementAddress',
                    issuerSettlementAddress
                  );
                  const tokenIssueType = token.issue_type_id.issue_calc_type === 'NAV' ? 1 : 0;
                  const res = await publishToken(
                    token.token_name,
                    token.token_symbol,
                    Number(token.total_supply),
                    Number(token.token_value),
                    token.token_currency.currency_code,
                    token.issuer_id.primary_issuer_xinfin_address,
                    // token.custodian_id.primary_custodian_xinfin_address,
                    // token.kyc_provider_id.primary_kycprovider_xinfin_address,
                    insurerAccount,
                    token.allow_early_redemption,
                    Number(token.minimum_subscription),
                    _paymentTokenList,
                    _issuanceRatio,
                    _redeemRatio,
                    tenure,
                    settlement,
                    collateralValue,
                    insurerCollateral,
                    allowedCountries,
                    classification,
                    rules.use_issuer_whitelist,
                    // token.onchain_kyc,
                    stringToBytes32(token.document_name),
                    token.document_uri,
                    token.document_hash,
                    tokenType,
                    liquidityPool,
                    issuerSettlementAddress,
                    tokenIssueType
                  );
                  if (res) {
                    console.log('res', res);
                    handleClose();
                    enqueueSnackbar('Token Published Successfully', {
                      variant: 'success'
                    });
                    navigate('/home/token-dashboard');
                  }
                  setIsSubmitting(false);
                } else {
                  enqueueSnackbar(
                    'You are not authorised to publish this token, Please use a issuer account',
                    {
                      variant: 'error'
                    }
                  );
                }
              } catch (e) {
                throwErrorMessage(e);
                handleClose();
                setIsSubmitting(false);
              }
            }}
          >
            Submit
          </LoadingButton>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
};

export default PublishTokenDialog;
