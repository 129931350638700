import {
  Container,
  Card,
  Grid,
  Box,
  CardContent,
  Typography,
  TextField,
  FormLabel,
  Button,
  Divider,
  FormControl,
  Select,
  MenuItem,
  Alert,
  FormHelperText
} from '@mui/material';
import Breadcrumbs from 'components/Breadcrumbs';
import React, { useState } from 'react';
import Page from 'components/Page';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { leiRegex, xdcRegex } from 'helpers/regex';
import { xdcToEthAddress, toChecksumAddress } from 'helpers/web3';
import {
  useBusinessEntityState,
  useAuthStateShared,
  useHttpApi,
  useCoreTableState,
  useAppState
} from 'state';
import { useNavigate } from 'react-router';
import { LeiInfoButton, XdcInfoButton } from 'helpers/infoButton';
import CommonOverlay from 'components/CommonOverlay';
import { onlyIssuer } from 'helpers/rbac';

const EditInvestor = () => {
  const { editOriginator } = useHttpApi();
  const { currentOriginator, fetchOriginator } = useBusinessEntityState();
  const { throwErrorMessage } = useAppState();

  const { role } = useAuthStateShared();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [error, setError] = useState(null);
  const { countries } = useCoreTableState();
  const navigate = useNavigate();

  const EditInvestorSchema = Yup.object().shape({
    investor_name: Yup.string().required('Name is required'),
    investor_email: Yup.string().email('Invalid Email Format').required('Email is required'),
    investor_phone: Yup.number()
      .required('Phone Number is required')
      .positive('Phone Number cannot be negative')
      .integer('Phone Number must be an integer')
      .max(9999999999, 'Please Enter Valid Phone Number'),
    lei: Yup.string()
      .matches(leiRegex, 'please enter a valid LEI')
      .min(20, 'LEI must be 20 characters')
      .max(20, 'LEI must be 20 characters')
      .required('LEI is required'),
    investor_country_code: Yup.string().required('Country code is required'),
    investor_xinfin_address: Yup.string()
      .matches(xdcRegex, 'Please enter a valid XDCPay Wallet Address')
      .required('Investor XDCPay Wallet Address is required'),
    requested_qty: Yup.number()
      .required('Token Quantity is required')
      .positive('Token Quantity must be positive number'),
    investor_address_line_1: Yup.string()
      .required('Address line 1 is required')
      .max(100, 'Address line 1 cannot be more than 100 characters'),
    investor_address_line_2: Yup.string()
      .required('Address line 2 is required')
      .max(100, 'Address line 2 cannot be more than 100 characters'),
    investor_city: Yup.string()
      .required('City is required')
      .max(30, 'City cannot be more than 30 characters'),
    investor_state: Yup.string()
      .required('State is required')
      .max(30, 'State cannot be more than 30 characters'),
    investor_zip_code: Yup.string()
      .required('zip code is required')
      .max(30, 'zip code cannot be more than 30 characters')
  });

  const formik = useFormik({
    initialValues: currentOriginator,
    validationSchema: EditInvestorSchema,
    onSubmit: async (data, { resetForm }) => {
      console.log('EditOriginator', data);
      try {
        const _editOriginator = await editOriginator(currentOriginator.originator_id, data);
        if (_editOriginator.originator_id) {
          enqueueSnackbar('Investor Updated Successfully', {
            variant: 'success'
          });
          resetForm();
          fetchOriginator();
          navigate('/masterdata/investor');
        }
      } catch (e) {
        const errorMessage = await throwErrorMessage(e);
        setError(errorMessage);
      }
    }
  });
  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;
  const allowedRolesList = ['PRIMARY_ISSUER'];

  return (
    <Page title="Edit Imvestor - Master Data">
      <CommonOverlay
        allowedRoles={allowedRolesList}
        overlayTitle="You don't have access to Edit Investor Account"
      />
      <Container sx={{ my: 2 }}>
        <Box sx={{ my: 2 }}>
          <Breadcrumbs aria-label="breadcrumb" pageHead="Edit Orignator" />
        </Box>
        <Card>
          {error && (
            <Box mb={4}>
              <Alert severity="error">{error}</Alert>
            </Box>
          )}
          <Container>
            <Typography
              sx={{
                fontSize: '1.125rem',
                fontWeight: 'bold',
                mt: 4,
                mb: 1,
                ml: 4
              }}
            >
              Investor Details
            </Typography>
            <Divider
              sx={{
                width: '100%',
                backgroundColor: '#f5f8fa',
                border: '2px solid #f5f8fa'
              }}
            />
            <FormikProvider value={formik}>
              <Form autocomplete="off" onSubmit={handleSubmit}>
                <CardContent sx={{ m: 2 }}>
                  <Grid container spacing={3} sx={{ width: '100%' }}>
                    <Grid item lg={6} md={6} xs={12} sm={12}>
                      <FormLabel>Investor name</FormLabel>
                      <TextField
                        sx={{ mt: 1.5 }}
                        fullWidth
                        size="small"
                        autoComplete="off"
                        type="text"
                        // placeholder="Enter Investor Name"
                        {...getFieldProps('investor_name')}
                        error={Boolean(touched.investor_name && errors.investor_name)}
                        helperText={touched.investor_name && errors.investor_name}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xs={12} sx={{ pl: 0.5 }}>
                      <FormLabel>Email</FormLabel>
                      <TextField
                        sx={{ mt: 1.5 }}
                        fullWidth
                        size="small"
                        autoComplete="email"
                        type="email"
                        // placeholder="Enter Email address"
                        {...getFieldProps('investor_email')}
                        error={Boolean(touched.investor_email && errors.investor_email)}
                        helperText={touched.investor_email && errors.investor_email}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xs={12}>
                      <FormLabel>Phone Number</FormLabel>
                      <TextField
                        sx={{ mt: 1.5 }}
                        fullWidth
                        size="small"
                        autoComplete="Phone number"
                        type="number"
                        // placeholder="Enter Phone Number"
                        {...getFieldProps('investor_phone')}
                        error={Boolean(touched.investor_phone && errors.investor_phone)}
                        helperText={touched.investor_phone && errors.investor_phone}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xs={12} sm={12}>
                      <FormLabel>
                        LEI
                        <LeiInfoButton />
                      </FormLabel>
                      <TextField
                        maxLength={50}
                        sx={{ mt: 1.5 }}
                        fullWidth
                        size="small"
                        autoComplete="off"
                        type="text"
                        // placeholder="LEI"
                        {...getFieldProps('lei')}
                        error={Boolean(touched.lei && errors.lei)}
                        helperText={touched.lei && errors.lei}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xs={12} sm={12}>
                      <FormLabel>
                        Investor Primary XinFin Address
                        <XdcInfoButton />
                      </FormLabel>
                      <TextField
                        sx={{ mt: 1.5 }}
                        fullWidth
                        type="text"
                        size="small"
                        // placeholder="Primary XinFin Address"
                        {...getFieldProps('investor_xinfin_address')}
                        error={Boolean(
                          touched.investor_xinfin_address && errors.investor_xinfin_address
                        )}
                        helperText={
                          touched.investor_xinfin_address && errors.investor_xinfin_address
                        }
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xs={12} sm={12}>
                      <FormLabel>Investor Country Code</FormLabel>

                      <FormControl size="small" variant="outlined" fullWidth sx={{ mt: 1.5 }}>
                        <Select
                          // placeholder="Select Country"
                          inputProps={{ 'aria-label': 'Without label' }}
                          {...getFieldProps('investor_country_code')}
                          error={Boolean(
                            touched.investor_country_code && errors.investor_country_code
                          )}
                          helperText={touched.investor_country_code && errors.investor_country_code}
                        >
                          {countries &&
                            countries.map((country, index) => {
                              return (
                                <MenuItem key={country.id} value={country.id}>
                                  {country.country_name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                        <FormHelperText sx={{ color: '#d32f2f' }}>
                          {touched.investor_country_code && errors.investor_country_code}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item lg={6} md={6} xs={12}>
                      <FormLabel>Address Line 1</FormLabel>
                      <TextField
                        sx={{ mt: 1.5 }}
                        fullWidth
                        size="small"
                        autoComplete="phone number"
                        type="text"
                        // placeholder="Home / Street"
                        {...getFieldProps('investor_address_line_1')}
                        error={Boolean(
                          touched.investor_address_line_1 && errors.investor_address_line_1
                        )}
                        helperText={
                          touched.investor_address_line_1 && errors.investor_address_line_1
                        }
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xs={12}>
                      <FormLabel>Address Line 2</FormLabel>
                      <TextField
                        sx={{ mt: 1.5 }}
                        fullWidth
                        size="small"
                        autoComplete="Landmark , etc."
                        type="text"
                        // placeholder="Home / Street"
                        {...getFieldProps('investor_address_line_2')}
                        error={Boolean(
                          touched.investor_address_line_2 && errors.investor_address_line_2
                        )}
                        helperText={
                          touched.investor_address_line_2 && errors.investor_address_line_2
                        }
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xs={12}>
                      <FormLabel>City</FormLabel>

                      <TextField
                        sx={{ mt: 1.5 }}
                        size="small"
                        fullWidth
                        type="text"
                        // placeholder="City"
                        {...getFieldProps('investor_city')}
                        error={Boolean(touched.investor_city && errors.investor_city)}
                        helperText={touched.investor_city && errors.investor_city}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xs={12}>
                      <FormLabel>State / Province / Region</FormLabel>
                      <TextField
                        sx={{ mt: 1.5 }}
                        fullWidth
                        size="small"
                        type="text"
                        // placeholder="Enter your State / Province / Region"
                        {...getFieldProps('investor_state')}
                        error={Boolean(touched.investor_state && errors.investor_state)}
                        helperText={touched.investor_state && errors.investor_state}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xs={12}>
                      <FormLabel>ZipCode</FormLabel>
                      <TextField
                        sx={{ mt: 1.5 }}
                        fullWidth
                        type="text"
                        size="small"
                        // placeholder="Your zip code"
                        {...getFieldProps('investor_zip_code')}
                        error={Boolean(touched.investor_zip_code && errors.investor_zip_code)}
                        helperText={touched.investor_zip_code && errors.investor_zip_code}
                      />
                    </Grid>
                  </Grid>

                  <Box
                    sx={{
                      mt: 4,
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-end'
                    }}
                  >
                    <Button
                      sx={{
                        margin: 1,
                        borderColor: '#24ABDF',
                        px: 3,
                        width: '7.75rem',
                        height: '2.5rem'
                      }}
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        navigate('/masterdata/investor');
                      }}
                    >
                      Cancel
                    </Button>
                    <LoadingButton
                      type="submit"
                      variant="gradient"
                      disabled={!onlyIssuer(role)}
                      fullWidth
                      size="large"
                      loadingPosition="start"
                      loading={isSubmitting}
                      sx={{ margin: 1, px: 3, width: '7.75rem', height: '2.5rem' }}
                    >
                      Save
                    </LoadingButton>
                  </Box>
                </CardContent>
              </Form>
            </FormikProvider>
          </Container>
        </Card>
      </Container>
    </Page>
  );
};

export default EditInvestor;
