import React, { useCallback, useEffect, useMemo, useState } from 'react';
import EnhancedTable from 'components/tables/EnhancedTable';
import {
  Box,
  IconButton,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  Alert,
  AlertTitle
} from '@mui/material';

import Delete from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useBusinessEntityState } from 'state/useBusinessEntityState';
import { ethToXdcAddress } from 'helpers/web3';
import { useAppState, useHttpApi } from 'state';
import { useSnackbar } from 'notistack';
import { HeaderName } from 'helpers/tableHelper';
import CopyAddress from 'components/CopyAddress';
import { useIsOwner } from 'helpers/rbac';
import LoadingActionButton from 'helpers/LoadingActionButton';
import EditMapInsurertoIssuer from './EditMapInsurertoIssuer';

const InsurerName = HeaderName('Insurer Name');
const InsurerContri = HeaderName('Insurer Contribution (%)');
const ActionButtons = ({ original, insurerId, insurerName }) => {
  const { currentIssuer, fetchIssuerInsurerMappingByIssuerId, setEditCurrentIssuerInsurer } =
    useBusinessEntityState();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { deleteIssuerInsurerMapping } = useHttpApi();
  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const { throwErrorMessage } = useAppState();
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleEditClose = () => {
    setEditOpen(false);
  };
  const handleEditOpen = () => {
    setEditOpen(true);
  };
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          m: 0,
          p: 0,
          alignItems: 'center'
        }}
      >
        <IconButton
          aria-label="subs detail"
          onClick={() => {
            setEditCurrentIssuerInsurer(original);
            handleEditOpen();
          }}
        >
          <EditIcon sx={{ fontSize: '2.188rem', m: 0, p: 1, backgroundColor: '#F5F8FA' }} />
        </IconButton>
        <Dialog open={editOpen} onClose={handleEditClose} aria-labelledby="alert-dialog-title">
          <DialogContent>
            <EditMapInsurertoIssuer handleEditClose={handleEditClose} />
          </DialogContent>
        </Dialog>
        <IconButton aria-label="subs detail" onClick={handleOpen}>
          <Delete sx={{ fontSize: '2.188rem', p: 1, backgroundColor: '#F5F8FA' }} />
        </IconButton>
      </Box>
      <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title">
        <Alert severity="warning">
          <AlertTitle>Delete Insurer Mapping</AlertTitle>
          <DialogContent>
            Are you sure you want to delete Insurer Mapping, {insurerName}?
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleClose}
              sx={{ width: '6.7rem' }}
            >
              Cancel
            </Button>
            <LoadingActionButton
              variant="outlined"
              color="error"
              loadingPosition="start"
              sx={{ width: '6.7rem' }}
              onClick={async () => {
                try {
                  await deleteIssuerInsurerMapping(currentIssuer.issuer_id, insurerId);
                  enqueueSnackbar('Insurer Mapping deleted successfully', {
                    variant: 'success'
                  });
                  fetchIssuerInsurerMappingByIssuerId(currentIssuer.issuer_id);
                } catch (e) {
                  throwErrorMessage(e);
                }
                handleClose();
              }}
            >
              Delete
            </LoadingActionButton>
          </DialogActions>
        </Alert>
      </Dialog>
    </>
  );
};

export default function InsurersOfIssuerTable() {
  const { issuerInvestorMapByIssuerId, fetchIssuerInsurerMappingByIssuerId, currentIssuer } =
    useBusinessEntityState();
  const isOwner = useIsOwner();
  const fetchData = () => {
    fetchIssuerInsurerMappingByIssuerId(currentIssuer.issuer_id);
  };
  const headCells = useMemo(
    () => [
      {
        accessor: 'insurer_name',
        Header: InsurerName,
        show: true
      },
      {
        accessor: 'insurer_xinfin_address',
        Header: 'Xinfin Address',
        width: 200,
        Cell: ({ value }) => {
          return <CopyAddress addr={ethToXdcAddress(value)} />;
        },
        show: true
      },
      {
        accessor: 'insurer_contri',
        Header: InsurerContri,
        width: 200,
        show: true
      },
      {
        accessor: 'fees_per_month',
        Header: 'Insurer Fees (%)',
        show: true
      },
      {
        accessor: 'actions',
        Header: 'Actions',
        Cell: ({ row: { original } }) => {
          return (
            <ActionButtons
              original={original}
              insurerId={original.insurer_id}
              insurerName={original.insurer_name}
            />
          );
        },
        show: isOwner
      }
    ],
    [isOwner]
  );

  return (
    <>
      <EnhancedTable
        tableTitle="Issuer"
        columns={headCells}
        refreshFunction={fetchData}
        data={issuerInvestorMapByIssuerId}
      />
    </>
  );
}
