const TRANSACTION_HASH_STORAGE_KEY = 'TRANSACTION_HASH_STORAGE_KEY';

const get = (key) => {
  const value = localStorage.getItem(`${TRANSACTION_HASH_STORAGE_KEY}-${key}`);

  if (value === null) {
    return value;
  }

  return JSON.parse(value);
};

const set = (key, value) =>
  localStorage.setItem(`${TRANSACTION_HASH_STORAGE_KEY}-${key}`, JSON.stringify(value));

const clear = (key) => localStorage.removeItem(`${TRANSACTION_HASH_STORAGE_KEY}-${key}`);

export default { get, set, clear };
