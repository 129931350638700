import React from 'react';
import Page from 'components/Page';

import {
  Container,
  Card,
  Grid,
  Box,
  CardContent,
  Typography,
  TextField,
  FormLabel,
  Button,
  Divider,
  FormControl,
  Select,
  MenuItem
} from '@mui/material';
import Breadcrumbs from 'components/Breadcrumbs';

const EditCryptoTxn = () => {
  return (
    <Page title="Edit Crypto Transaction - Master Data">
      <Container sx={{ my: 2 }}>
        <Box sx={{ my: 2 }}>
          <Breadcrumbs aria-label="breadcrumb" pageHead="Crypto Asset Transaction" />
        </Box>
        <Card>
          <Container>
            <Typography
              sx={{
                fontSize: '1.125rem',
                fontWeight: 'bold',
                mt: 4,
                mb: 1,
                ml: 4
              }}
            >
              Edit Crypto Asset Transaction
            </Typography>
            <Divider
              sx={{
                width: '100%',
                backgroundColor: '#f5f8fa',
                border: '2px solid #f5f8fa'
              }}
            />

            {/* refer design on implementation  */}
            {/* whether to use dropdown or textfield */}

            <CardContent sx={{ m: 2 }}>
              <Grid container spacing={3} sx={{ width: '100%' }}>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <FormLabel>Crypto Asset id</FormLabel>
                  <TextField
                    sx={{ mt: 1.5 }}
                    fullWidth
                    size="small"
                    autoComplete=""
                    type="text"
                    // placeholder="2546987"
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <FormLabel>Crypto Asset type</FormLabel>
                  <TextField
                    sx={{ mt: 1.5 }}
                    fullWidth
                    size="small"
                    autoComplete=""
                    type="text"
                    // placeholder="lorem ipsum"
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <FormLabel>Transaction Currency Code</FormLabel>

                  <FormControl size="small" variant="outlined" fullWidth sx={{ mt: 1.5 }}>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      displayEmpty
                      inputProps={{ 'aria-label': 'Without label' }}
                    >
                      <MenuItem value={10}>USD</MenuItem>
                      <MenuItem value={20}>INR</MenuItem>
                      <MenuItem value={30}>SGD</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <FormLabel>Transaction Amount</FormLabel>
                  <TextField
                    sx={{ mt: 1.5 }}
                    fullWidth
                    type="text"
                    size="small"
                    // placeholder="100000"
                  />
                </Grid>

                <Grid item lg={6} md={6} xs={12}>
                  <FormLabel>Token id</FormLabel>

                  <FormControl size="small" variant="outlined" fullWidth sx={{ mt: 1.5 }}>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      displayEmpty
                      inputProps={{ 'aria-label': 'Without label' }}
                    >
                      <MenuItem value={10}>Ten</MenuItem>
                      <MenuItem value={20}>Twenty</MenuItem>
                      <MenuItem value={30}>Thirty</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <FormLabel>XinFin Address</FormLabel>
                  <TextField
                    sx={{ mt: 1.5 }}
                    fullWidth
                    type="text"
                    size="small"
                    // placeholder="xinfin address"
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <FormLabel>Token Subscription id</FormLabel>
                  <TextField
                    sx={{ mt: 1.5 }}
                    fullWidth
                    type="text"
                    size="small"
                    // placeholder=""
                  />
                </Grid>
              </Grid>

              <Box
                sx={{
                  mt: 4,
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end'
                }}
              >
                <Button
                  sx={{
                    margin: 1,
                    borderColor: '#24ABDF',
                    px: 3,
                    width: '7.75rem',
                    height: '2.5rem'
                  }}
                  variant="outlined"
                  color="secondary"
                >
                  Cancel
                </Button>
                <Button
                  variant="gradient"
                  sx={{ margin: 1, px: 3, width: '7.75rem', height: '2.5rem' }}
                  onClick={{}}
                >
                  Save
                </Button>
              </Box>
            </CardContent>
          </Container>
        </Card>
      </Container>
    </Page>
  );
};

export default EditCryptoTxn;
