import {
  Container,
  Card,
  Grid,
  CardContent,
  Divider,
  Typography,
  TextField,
  FormLabel,
  Box,
  Button,
  Select,
  MenuItem,
  FormHelperText,
  FormControl,
  Alert,
  IconButton,
  InputAdornment
} from '@mui/material';
import Page from 'components/Page';
import { LoadingButton } from '@mui/lab';
import Breadcrumbs from 'components/Breadcrumbs';
import React, { useRef, useState } from 'react';
import { useFormik, Form, FormikProvider, Formik } from 'formik';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { xdcRegex, leiRegex } from 'helpers/regex';
import { useCoreTableState } from 'state/useCoreTableState';
import { useNavigate } from 'react-router';
import {
  useBusinessEntityState,
  useHttpApi,
  useWeb3,
  useAuthStateShared,
  useAppState
} from 'state';
import { toChecksumAddress, xdcToEthAddress } from 'helpers/web3';
import { LeiInfoButton, XdcInfoButton } from 'helpers/infoButton';
import CommonOverlay from 'components/CommonOverlay';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { Icon } from '@iconify/react';
import { onlyOwner } from 'helpers/rbac';
import FocusError from 'components/FocusError';

const AddKYCProvider = () => {
  const [error, setError] = useState(null);
  const { createKycProvider } = useHttpApi();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { fetchKycProvider } = useBusinessEntityState();
  const { role } = useAuthStateShared();
  const { throwErrorMessage } = useAppState();

  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const kycProviderLogo = useRef(null);

  const AddKYCProviderSchema = Yup.object().shape({
    kyc_provider_name: Yup.string()
      .required('Unique KYC Provider Name is required')
      .max(30, 'KYC Provider Name cannot be more than 30 characters'),

    kyc_provider_country_code: Yup.string().required('Country Code is required'),

    primary_kycprovider_xinfin_address: Yup.string()
      .matches(xdcRegex, 'Invalid XDC Address Format')
      .required('XinFin Address is required'),
    username: Yup.string().required('Username is required'),
    password: Yup.string().required('Password is required'),
    email: Yup.string().email('Invalid email address').required('Email is required'),
    kyc_provider_logo: Yup.mixed().required('KYC Provider Logo is required')
  });

  const { addKycProvider: addKycProviderBlockchain } = useWeb3();

  const formik = useFormik({
    validate: (values) => {
      const errors = {};
      if (values.kyc_provider_logo) {
        if (kycProviderLogo && kycProviderLogo.current.files[0].size > 50000) {
          errors.kyc_provider_logo = 'File size greater than 50KB';
        }
      }

      return errors;
    },
    initialValues: {
      kyc_provider_name: '',
      // lei: '',
      // license_No: '',
      kyc_provider_country_code: '',
      primary_kycprovider_xinfin_address: '',
      kyc_provider_logo: ''
    },
    validationSchema: AddKYCProviderSchema,
    onSubmit: async (data, { resetForm }) => {
      console.log('KYC Provider', data);
      try {
        setError(null);
        const kycLogoFile = kycProviderLogo.current.files[0];
        data.kyc_provider_logo = kycLogoFile;
        data.primary_kycprovider_xinfin_address = xdcToEthAddress(
          data.primary_kycprovider_xinfin_address
        );
        data.primary_kycprovider_xinfin_address = toChecksumAddress(
          data.primary_kycprovider_xinfin_address
        );
        const countryCode = countries.find(
          (country) => country.id === data.kyc_provider_country_code
        );
        console.log('countryCode', countryCode.country_code);

        const _kycresponse = await createKycProvider(data);

        formik.setFieldValue('kyc_provider_logo', '');

        console.log('_kycresponse', _kycresponse);
        if (_kycresponse.kyc_provider_id) {
          enqueueSnackbar('KYC Provider created successfully', {
            variant: 'success'
          });
          resetForm();
          fetchKycProvider();
          navigate('/masterdata/kyc-provider/');
        }
        const blockchainRes = await addKycProviderBlockchain(
          countryCode.country_code,
          data.primary_kycprovider_xinfin_address
        );
      } catch (e) {
        formik.setFieldValue('kyc_provider_logo', '');
        throwErrorMessage(e);
      }
    }
  });
  const { errors, touched, isSubmitting, values, handleSubmit, getFieldProps } = formik;

  const { countries } = useCoreTableState();

  const allowedRolesList = ['OWNER'];
  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };
  return (
    <>
      <CommonOverlay
        allowedRoles={allowedRolesList}
        overlayTitle="You don't have access to Add Primary KYC Provider Account"
      />
      <Page title="Add KYC Provider - Master Data">
        <Container sx={{ my: 2 }}>
          <Box sx={{ my: 2 }}>
            <Breadcrumbs pageHead="Add KYC Provider" aria-label="breadcrumb" />
          </Box>
          <Card>
            <Container>
              <Typography
                sx={{
                  fontSize: '1.125rem',
                  fontWeight: 'bold',
                  mt: 4,
                  mb: 1,
                  ml: 4
                }}
              >
                KYC Provider Details
              </Typography>
              <Divider
                sx={{
                  width: '100%',
                  backgroundColor: '#f5f8fa',
                  border: '2px solid #f5f8fa'
                }}
              />
              <FormikProvider value={formik}>
                <Form autoComplete="off" onSubmit={handleSubmit}>
                  {error && (
                    <Box mb={4}>
                      <Alert
                        severity="error"
                        sx={{
                          textTransform: 'capitalize'
                        }}
                      >
                        {error}
                      </Alert>
                    </Box>
                  )}
                  <CardContent sx={{ m: 2 }}>
                    <Grid container spacing={3} sx={{ width: '100%' }}>
                      <Grid item lg={6} sm={12} md={6} xs={12}>
                        <FormLabel>Name</FormLabel>
                        <TextField
                          sx={{ mt: 1.5 }}
                          fullWidth
                          size="small"
                          autoComplete="off"
                          type="text"
                          // placeholder="Enter KYC Provider Name"
                          {...getFieldProps('kyc_provider_name')}
                          error={Boolean(touched.kyc_provider_name && errors.kyc_provider_name)}
                          helperText={touched.kyc_provider_name && errors.kyc_provider_name}
                        />
                      </Grid>
                      <Grid item lg={6} md={6} xs={12} sm={12}>
                        <FormLabel>Username</FormLabel>
                        <TextField
                          sx={{ mt: 1.5 }}
                          fullWidth
                          size="small"
                          autoComplete="off"
                          type="text"
                          {...getFieldProps('username')}
                          error={Boolean(touched.username && errors.username)}
                          helperText={touched.username && errors.username}
                        />
                      </Grid>
                      <Grid item lg={6} md={6} xs={12} sm={12}>
                        <FormLabel>Email</FormLabel>
                        <TextField
                          sx={{ mt: 1.5 }}
                          fullWidth
                          size="small"
                          autoComplete="off"
                          type="text"
                          {...getFieldProps('email')}
                          error={Boolean(touched.email && errors.username)}
                          helperText={touched.email && errors.email}
                        />
                      </Grid>
                      <Grid item lg={6} md={6} xs={12} sm={12}>
                        <FormLabel>Password</FormLabel>
                        <TextField
                          sx={{ mt: 1.5 }}
                          fullWidth
                          size="small"
                          autoComplete="off"
                          type={showPassword ? 'text' : 'password'}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton onClick={handleShowPassword} edge="end">
                                  <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                          {...getFieldProps('password')}
                          error={Boolean(touched.password && errors.password)}
                          helperText={touched.password && errors.password}
                        />
                      </Grid>
                      <Grid item lg={6} sm={12} md={6} xs={12}>
                        <FormLabel>
                          Xinfin address
                          <XdcInfoButton />
                        </FormLabel>
                        <TextField
                          sx={{ mt: 1.5 }}
                          fullWidth
                          size="small"
                          autoComplete="off"
                          type="text"
                          // placeholder="Primary XinFin Address"
                          {...getFieldProps('primary_kycprovider_xinfin_address')}
                          error={Boolean(
                            touched.primary_kycprovider_xinfin_address &&
                              errors.primary_kycprovider_xinfin_address
                          )}
                          helperText={
                            touched.primary_kycprovider_xinfin_address &&
                            errors.primary_kycprovider_xinfin_address
                          }
                        />
                      </Grid>
                      <Grid item lg={6} md={6} sm={12} xs={12} sx={{ pr: 1 }}>
                        <FormLabel>Logo</FormLabel>
                        <TextField
                          sx={{ mt: 1.5 }}
                          fullWidth
                          size="small"
                          autoComplete="off"
                          type="file"
                          inputRef={kycProviderLogo}
                          inputProps={{ accept: '.jpg, .jpeg, .png, .svg' }}
                          {...getFieldProps('kyc_provider_logo')}
                          id="kyc_provider_logo"
                          error={Boolean(touched.kyc_provider_logo && errors.kyc_provider_logo)}
                          helperText={touched.kyc_provider_logo && errors.kyc_provider_logo}
                        />

                        <Grid container sx={{ width: '100%', mt: 2 }}>
                          <Grid item lg={4} md={4} sm={4}>
                            <Typography
                              sx={{
                                fontSize: '0.688rem',
                                textAlign: 'left',
                                color: '#161c2d'
                              }}
                            >
                              Maximum 50KB file size
                            </Typography>
                          </Grid>
                          <Grid item lg={8} md={8} sm={8}>
                            <Typography
                              sx={{
                                fontSize: '0.688rem',
                                textAlign: 'right',
                                color: '#8e8ea7'
                              }}
                            >
                              Accepted File Type .jpg, .jpeg, .png & .svg only
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item lg={6} sm={12} md={6} xs={12}>
                        <FormLabel>Country Code</FormLabel>

                        <FormControl size="small" variant="outlined" fullWidth sx={{ mt: 1.5 }}>
                          <Select
                            // placeholder="Select Country"
                            inputProps={{ 'aria-label': 'Without label' }}
                            {...getFieldProps('kyc_provider_country_code')}
                            error={Boolean(
                              touched.kyc_provider_country_code && errors.kyc_provider_country_code
                            )}
                            helperText={
                              touched.kyc_provider_country_code && errors.kyc_provider_country_code
                            }
                          >
                            {countries &&
                              countries.map((country, index) => {
                                return (
                                  <MenuItem key={country.id} value={country.id}>
                                    {country.country_name}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                          <FormHelperText sx={{ color: '#d32f2f' }}>
                            {touched.kyc_provider_country_code && errors.kyc_provider_country_code}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                    </Grid>

                    <Box
                      sx={{
                        mt: 4,
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end'
                      }}
                    >
                      <Button
                        sx={{
                          margin: 1,
                          borderColor: '#24ABDF',
                          px: 3,
                          width: '7.75rem',
                          height: '2.5rem'
                        }}
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                          navigate('/masterdata/kyc-provider');
                        }}
                      >
                        Cancel
                      </Button>
                      <LoadingButton
                        disabled={!onlyOwner(role)}
                        type="submit"
                        variant="gradient"
                        fullWidth
                        size="large"
                        loadingPosition="start"
                        loading={isSubmitting}
                        sx={{ margin: 1, px: 3, width: '7.75rem', height: '2.5rem' }}
                      >
                        Save
                      </LoadingButton>
                    </Box>
                    <FocusError />
                  </CardContent>
                </Form>
              </FormikProvider>
            </Container>
          </Card>
        </Container>
      </Page>
    </>
  );
};

export default AddKYCProvider;
