import React, { useEffect, useState } from 'react';
import EnhancedTable from 'components/tables/EnhancedTable';
import { useBusinessEntityState } from 'state/useBusinessEntityState';

export default function ViewRedemptionsTables() {
  const { reedemedTokens, fetchAllReddemedAllotmentsByToken } = useBusinessEntityState();
  const [data, setData] = useState(reedemedTokens);
  const [skipPageReset, setSkipPageReset] = React.useState(false);

  useEffect(() => {
    fetchAllReddemedAllotmentsByToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setData(reedemedTokens);
  }, [reedemedTokens]);

  const refreshAllotments = () => {
    fetchAllReddemedAllotmentsByToken();
  };

  const headCells = [
    {
      accessor: 'investor_name',
      Header: 'Investor',
      width: 110,
      show: true
    },
    {
      accessor: 'token_symbol',
      Header: 'Token Symbol',
      width: 110,
      show: true
    },
    {
      accessor: 'redeemed_qty',
      Header: 'Quantity',
      width: 110,
      show: true
    },
    {
      accessor: 'allotment_date',
      Header: 'Allotment Date',
      width: 110,
      show: true
    },
    {
      accessor: 'redemption_date',
      Header: 'Redemption Date',
      width: 110,
      show: true
    }
  ];

  const updateMyData = (rowIndex, columnId, value) => {
    setSkipPageReset(true);
    setData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value
          };
        }
        return row;
      })
    );
  };

  return (
    <>
      <EnhancedTable
        tableTitle="ViewRedemptions"
        columns={headCells}
        data={data}
        setData={setData}
        updateMyData={updateMyData}
        skipPageReset={skipPageReset}
        refreshFunction={refreshAllotments}
      />
    </>
  );
}
