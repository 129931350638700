import React, { useCallback, useEffect, useMemo, useState } from 'react';
import EnhancedTable from 'components/tables/EnhancedTable';
import {
  Box,
  IconButton,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  Alert,
  AlertTitle
} from '@mui/material';

import Delete from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useBusinessEntityState } from 'state/useBusinessEntityState';
import { ethToXdcAddress } from 'helpers/web3';
import { useAppState, useHttpApi } from 'state';
import { useSnackbar } from 'notistack';
import { HeaderName } from 'helpers/tableHelper';
import CopyAddress from 'components/CopyAddress';
import { useIsOwner } from 'helpers/rbac';
import LoadingActionButton from 'helpers/LoadingActionButton';
import EditMapInsurertoIssuer from './EditMapInsurertoIssuer';

const InsurerName = HeaderName('Insurer Name');
const InsurerContri = HeaderName('Insurer Contribution (%)');
const ActionButtons = ({ original, liquidityPoolId, liquidityPoolName, refreshFunction }) => {
  const { currentIssuer, fetchIssuerInsurerMappingByIssuerId, setEditCurrentIssuerInsurer } =
    useBusinessEntityState();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { deleteIssuerLiquidityPoolMapping } = useHttpApi();
  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const { throwErrorMessage } = useAppState();
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleEditClose = () => {
    setEditOpen(false);
  };
  const handleEditOpen = () => {
    setEditOpen(true);
  };
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          m: 0,
          p: 0,
          alignItems: 'center'
        }}
      >
        <IconButton aria-label="subs detail" onClick={handleOpen}>
          <Delete sx={{ fontSize: '2.188rem', p: 1, backgroundColor: '#F5F8FA' }} />
        </IconButton>
      </Box>
      <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title">
        <Alert severity="warning">
          <AlertTitle>Delete Liquidity Pool Mapping</AlertTitle>
          <DialogContent>
            Are you sure you want to delete the Liquidity Pool Mapping for {liquidityPoolName}?
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleClose}
              sx={{ width: '6.7rem' }}
            >
              Cancel
            </Button>
            <LoadingActionButton
              variant="outlined"
              color="error"
              loadingPosition="start"
              sx={{ width: '6.7rem' }}
              onClick={async () => {
                try {
                  await deleteIssuerLiquidityPoolMapping(currentIssuer.issuer_id, liquidityPoolId);
                  enqueueSnackbar('Liquidity Mapping deleted successfully', {
                    variant: 'success'
                  });
                  refreshFunction();
                } catch (e) {
                  throwErrorMessage(e);
                }
                handleClose();
              }}
            >
              Delete
            </LoadingActionButton>
          </DialogActions>
        </Alert>
      </Dialog>
    </>
  );
};

export default function InsurersOfIssuerTable({ mappings, fetchData }) {
  const { issuerInvestorMapByIssuerId, fetchIssuerInsurerMappingByIssuerId, currentIssuer } =
    useBusinessEntityState();
  const isOwner = useIsOwner();
  useEffect(() => {
    fetchData();
  }, []);
  const headCells = useMemo(
    () => [
      {
        accessor: 'liquidity_pool_name',
        Header: 'Liquidity Pool',
        show: true
      },
      {
        accessor: 'liquidity_pool_xinfin_address',
        Header: 'Xinfin Address',
        width: 200,
        Cell: ({ value }) => {
          return <CopyAddress addr={ethToXdcAddress(value)} />;
        },
        show: true
      },
      {
        accessor: 'actions',
        Header: 'Actions',
        Cell: ({ row: { original } }) => {
          return (
            <ActionButtons
              original={original}
              liquidityPoolId={original.liquidity_pool_id}
              liquidityPoolName={original.liquidity_pool_name}
              refreshFunction={fetchData}
            />
          );
        },
        show: isOwner
      }
    ],
    [isOwner]
  );

  return (
    <>
      <EnhancedTable
        tableTitle="Issuer"
        columns={headCells}
        refreshFunction={fetchData}
        data={mappings}
      />
    </>
  );
}
