import React, { useEffect, useState } from 'react';
import EnhancedTable from 'components/tables/EnhancedTable';
import { useAuthStateShared, useSubscriptionState, useWeb3 } from 'state';

export default function IssuerViewRedemptions({ poolAddr }) {
  const { redeemedAllotments, fetchRedeemedAllotments } = useSubscriptionState();
  const [data, setData] = useState(redeemedAllotments);
  const { account } = useWeb3();
  const [skipPageReset, setSkipPageReset] = React.useState(false);

  const fetchAccount = poolAddr || account;

  useEffect(() => {
    fetchRedeemedAllotments(fetchAccount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [poolAddr, account]);

  useEffect(() => {
    setData(redeemedAllotments);
  }, [redeemedAllotments]);

  const refreshAllotments = () => {
    fetchRedeemedAllotments(fetchAccount);
  };

  const headCells = [
    {
      accessor: 'token_symbol',
      Header: 'Token Symbol',
      width: 110,
      show: true
    },
    {
      accessor: 'token_name',
      Header: 'Token Name',
      width: 110,
      show: true
    },
    {
      accessor: 'redeemed_qty',
      Header: 'Quantity',
      width: 110,
      show: true
    },
    {
      accessor: 'allotment_date',
      Header: 'Allotment Date',
      width: 110,
      show: true
    },
    {
      accessor: 'redemption_date',
      Header: 'Redemption Date',
      width: 110,
      show: true
    }
  ];

  const updateMyData = (rowIndex, columnId, value) => {
    setSkipPageReset(true);
    setData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value
          };
        }
        return row;
      })
    );
  };

  return (
    <>
      <EnhancedTable
        tableTitle="InvestorViewRedemption"
        columns={headCells}
        data={data}
        setData={setData}
        updateMyData={updateMyData}
        skipPageReset={skipPageReset}
        refreshFunction={refreshAllotments}
      />
    </>
  );
}
