import React, { useEffect, useState } from 'react';
import EnhancedTable from 'components/tables/EnhancedTable';
import { useBusinessEntityState, useWeb3 } from 'state/index';
import CopyAddress from 'components/CopyAddress';
import { ethToXdcAddress } from 'helpers/web3';

export default function InsurerAllotments({ fetchData: _fetchData }) {
  const {
    insurerIssuerAllotments,
    fetchAllotmentsByInsurerIssuerId,
    currCreditIssuer,
    currCreditInsurer
  } = useBusinessEntityState();

  // const _fetchData = async () => {
  //   await fetchAllotmentsByInsurerIssuerId(
  //     currCreditIssuer.issuer_id,
  //     currCreditInsurer.insurer_id
  //   );
  // };

  // useEffect(() => {
  //   _fetchData();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const headCells = [
    {
      accessor: 'token_subscription_id.investor_id.investor_name',
      Header: 'Investor Name',
      width: 100,
      show: true
    },
    {
      accessor: 'token_subscription_id.xinfin_setl_account.xinfin_account_address',
      Header: 'Xinfin Address',
      width: 300,
      Cell: ({ value }) => <CopyAddress addr={ethToXdcAddress(value)} />,
      show: true
    },
    {
      accessor: 'allotment_date',
      Header: 'Allotment Date',
      width: 100,
      show: true
    },
    {
      accessor: 'allotment_maturity_date',
      Header: 'Maturity Date',
      width: 100,
      show: true
    },
    {
      accessor: 'alloted_qty',
      Header: 'Alloted Qty',
      width: 80,
      show: true
    }
  ];

  return (
    <>
      <EnhancedTable
        tableTitle="InsurerAllotments"
        columns={headCells}
        refreshFunction={_fetchData}
        data={insurerIssuerAllotments}
      />
    </>
  );
}
