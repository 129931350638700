import React, { useEffect, useState } from 'react';
import { Box, IconButton, Chip } from '@mui/material';
import { useBusinessEntityState, useAuthStateShared } from 'state/index';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { ethToXdcAddress } from 'helpers/web3';
import EnhancedTableCheck from 'components/tables/EnhancedTableCheck';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SubsDetails from 'components/masterData/Dashboard/SubsDetails';
import Modal from '@mui/material/Modal';
import CopyAddress from 'components/CopyAddress';
import CanIssueChip from 'components/issuerDashboard/CanIssueChip';

const headCells = [
  {
    accessor: 'investor_id.investor_name',
    Header: 'Investor',
    show: true
  },
  {
    accessor: 'xinfin_address',
    Header: 'Xinfin Address',
    width: 200,
    show: true,
    Cell: (c) => <CopyAddress addr={ethToXdcAddress(c.row.original.xinfin_setl_account)} />
  },
  // {
  //   accessor: 'requested_qty',
  //   Header: 'Requested Qty',
  //   show: 'requested_qty' !== 0
  // },
  {
    accessor: 'kyc_status',
    Header: 'KYC Status',
    show: true,
    Cell: (c) =>
      c.value === 'ONCHAINCOMPLETE' ? (
        <Chip label="COMPLETE" color="success" />
      ) : (
        <Chip label={c.value === 'PROGRESS' ? 'IN PROGRESS' : c.value} />
      )
  },
  {
    accessor: 'issuer_whitelisted',
    Header: 'Whitelist Type',
    show: true,
    Cell: (c) => {
      console.log('WWW', c.row.original.issuer_whitelisted);
      return c.row.original.issuer_whitelisted ? 'Issuer Whitelist' : 'Token Whitelist';
    }
  },
  {
    accessor: 'actions',
    Header: 'Subs. Details',
    show: true,
    Cell: (c) => <ActionButtons investor={c.row.original.investor_id} />
  },
  {
    Header: 'Can Issue',
    show: true,
    Cell: (c) => (
      <CanIssueChip
        tokenAddress={c.row.original.token_deployment_address}
        investor={c.row.original.investor_id.xinfin_account}
        value={c.row.original.requested_qty}
        nonce={Math.random()}
      />
    )
  }
];
const ActionButtons = ({ investor }) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <>
      <IconButton aria-label="subs detail" onClick={handleOpen} sx={{ mt: -1 }}>
        <VisibilityIcon sx={{ fontSize: '2rem', p: 1, m: 0, backgroundColor: '#F5F8FA' }} />
      </IconButton>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <SubsDetails investor={investor} handleClose={handleClose} />
      </Modal>
    </>
  );
};

export default function CurrentWhiteListTable() {
  const { currentWhiteList, setRemoveFromWhiteListAddresses, fetchWhiteList } =
    useBusinessEntityState();

  const { role } = useAuthStateShared();
  const [data, setData] = useState(currentWhiteList);

  const [skipPageReset, setSkipPageReset] = React.useState(false);

  useEffect(() => {
    setData(currentWhiteList);
  }, [currentWhiteList]);

  // We need to keep the table from resetting the pageIndex when we
  // Update data. So we can keep track of that flag with a ref.

  // When our cell renderer calls updateMyData, we'll use
  // the rowIndex, columnId and new value to update the
  // original data

  const updateMyData = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true);
    setData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value
          };
        }
        return row;
      })
    );
  };

  return (
    <>
      <EnhancedTableCheck
        tableTitle="CurrentWhitelist"
        columns={headCells}
        refreshFunction={fetchWhiteList}
        setSelectedRowsToState={setRemoveFromWhiteListAddresses}
        data={data}
        setData={setData}
        updateMyData={updateMyData}
        skipPageReset={skipPageReset}
        isRoleAllowed={['PRIMARY_ISSUER', 'PRIMARY_KYC_PROVIDER', 'KYC_PROVIDER_OPERATOR'].includes(
          role
        )}
      />
    </>
  );
}
