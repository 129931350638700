import {
  Container,
  Card,
  Grid,
  Box,
  CardContent,
  Typography,
  TextField,
  Divider,
  Button,
  IconButton,
  InputLabel,
  FormLabel,
  ListItem,
  Select,
  FormControl,
  FormControlLabel,
  Switch
} from '@mui/material';
import Page from 'components/Page';
import React, { useEffect, useState } from 'react';
import Breadcrumbs from 'components/Breadcrumbs';
import PropTypes from 'prop-types';
import { useAuthStateShared, useBusinessEntityState, useWeb3, useHttpApi } from 'state';
import { useNavigate } from 'react-router';
import { useSnackbar } from 'notistack';
import { ethToXdcAddress, stringToBytes32 } from 'helpers/web3';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import LoadingActionButton from 'helpers/LoadingActionButton';
import { format, isBefore } from 'date-fns';

const ViewToken = () => {
  const { currentToken: token, currentTokenRuleDetails: rules } = useBusinessEntityState();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { web3, getTokenDocument } = useWeb3();
  const { get0xSha256Hash } = useHttpApi();
  const [fetchedHash, setFetchedHash] = useState('');
  const [contractDocumentHash, setContractDocumentHash] = useState('Fetching...');
  const [enterHashManually, setEnterHashManually] = useState(false);
  const today = new Date();
  // get today as per issue date format
  const todayDate = format(today, 'yyyy-MM-dd');

  console.log('Check', token);
  const TextFieldInput = ({ header, subheader, readOnlyValue = true }) => {
    return (
      <TextField
        id="read-only-input"
        label={header}
        defaultValue={subheader}
        fullWidth
        fontFamily="Poppins"
        sx={{ fontWeight: '900' }}
        InputProps={{
          readOnly: readOnlyValue
        }}
        variant="standard"
      />
    );
  };
  TextFieldInput.propTypes = {
    header: PropTypes.string,
    subheader: PropTypes.string
  };

  useEffect(() => {
    const fetchHashFromContract = async () => {
      try {
        const res = await getTokenDocument(
          token.deployment_address,
          stringToBytes32(token.document_name)
        );
        setContractDocumentHash(res['1']);
      } catch (e) {
        enqueueSnackbar(e.message, { variant: 'error' });
      }
    };
    fetchHashFromContract();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const get0xedHash = (hString) => {
    if (!hString.startsWith('0x')) {
      return `0x${hString}`;
    }
    return hString;
  };

  const getVerificationButtonVariant = () => {
    if (fetchedHash === '') {
      return 'outlined';
    }
    if (get0xedHash(fetchedHash) === contractDocumentHash) {
      return 'gradient';
    }
    return 'contained';
  };

  const getVerificationButtonColor = () => {
    if (fetchedHash === '') {
      return 'primary';
    }
    if (get0xedHash(fetchedHash) === contractDocumentHash) {
      return 'success';
    }
    return 'error';
  };

  const getVerificationButtonText = () => {
    if (fetchedHash === '') {
      return enterHashManually ? 'Enter Hash' : 'Verify Document';
    }
    if (get0xedHash(fetchedHash) === contractDocumentHash) {
      return `${enterHashManually ? 'Hashes' : 'Documents'} Match`;
    }
    return `${enterHashManually ? 'Hashes' : 'Documents'} Do Not Match`;
  };

  const getVerificationButtonDisableRipple = () => {
    if (fetchedHash === '') {
      return false;
    }
    return true;
  };

  return (
    <>
      <Page title="View Token">
        <Container sx={{ my: 2 }}>
          <Box sx={{ my: 2 }}>
            <Breadcrumbs pageHead="Token Term Sheet" />
          </Box>
          <Card>
            <Container>
              <Typography
                sx={{
                  fontSize: '1.125rem',
                  fontWeight: 'bold',
                  mt: 4,
                  mb: 1,
                  ml: 4
                }}
              >
                View Token Details
              </Typography>
              <Divider
                sx={{
                  width: '100%',
                  backgroundColor: '#f5f8fa',
                  border: '2px solid #f5f8fa'
                }}
              />
              <CardContent sx={{ mb: 2 }}>
                <Card sx={{ mt: 2, backgroundColor: '#f5f8fa' }}>
                  <CardContent>
                    <Typography sx={{ pl: 6, fontWeight: '600' }}>Token Header</Typography>
                    <Grid container spacing={2} sx={{ width: '100%', mt: 1, pr: 6, pl: 6 }}>
                      <Grid item lg={4} md={6} xs={12} sx={{ pr: 1 }}>
                        <TextFieldInput
                          header="Issuer name"
                          subheader={token.issuer_id.issuer_name}
                        />
                      </Grid>
                      <Grid item lg={4} md={6} xs={12} sx={{ pr: 1 }}>
                        <TextFieldInput
                          header="Issue Currency"
                          subheader={token.token_currency.currency_name}
                        />
                      </Grid>
                      <Grid item lg={4} md={6} xs={12} sx={{ pr: 1 }}>
                        <TextFieldInput header="Token Name" subheader={token.token_name} />
                      </Grid>
                      <Grid item lg={4} md={6} xs={12} sx={{ pr: 1 }}>
                        <TextFieldInput header="Token Symbol" subheader={token.token_symbol} />
                      </Grid>
                      <Grid item lg={4} md={6} xs={12} sx={{ pr: 1 }}>
                        <TextFieldInput header="Token Supply" subheader={token.total_supply} />
                      </Grid>
                      <Grid item lg={4} md={6} xs={12} sx={{ pr: 1 }}>
                        <TextFieldInput header="Token Value" subheader={token.token_value} />
                      </Grid>
                      <Grid item lg={4} md={6} xs={12} sx={{ pr: 1 }}>
                        <TextFieldInput header="Issue Size" subheader={token.issue_size} />
                      </Grid>
                      <Grid item lg={4} md={6} xs={12} sx={{ pr: 1 }}>
                        <TextFieldInput
                          header="Token Category"
                          subheader={token.issue_category_id.issue_category_name}
                        />
                      </Grid>
                      <Grid item lg={4} md={6} xs={12} sx={{ pr: 1 }}>
                        <TextFieldInput
                          header="Token Sub Category"
                          subheader={token.issue_sub_category_id.issue_sub_category_name}
                        />
                      </Grid>
                      <Grid item lg={4} md={6} xs={12} sx={{ pr: 1 }}>
                        <TextFieldInput
                          header="Issue Type"
                          subheader={token.issue_type_id.issue_type_name}
                        />
                      </Grid>
                    </Grid>
                    <Divider sx={{ mt: 4 }} />
                    <Divider sx={{ mb: 4 }} />
                    <Typography sx={{ pl: '50px', fontWeight: '600' }}>
                      Primary Economics Terms
                    </Typography>
                    <Grid container spacing={2} sx={{ width: '100%', mt: 1, pr: 6, pl: 6 }}>
                      <Grid item lg={4} md={6} xs={12} sx={{ pr: 1 }}>
                        <TextFieldInput
                          header="Minimum Subscription (Tokens)"
                          subheader={token.minimum_subscription}
                        />
                      </Grid>
                      {token.token_issue_desc_percent && (
                        <>
                          <Grid item lg={4} md={6} xs={12} sx={{ pr: 1 }}>
                            <TextFieldInput
                              header="Discount (% P.A)"
                              subheader={token.token_issue_desc_percent}
                            />
                          </Grid>
                        </>
                      )}
                      {token.token_issue_interest_percent && (
                        <>
                          <Grid item lg={4} md={6} xs={12} sx={{ pr: 1 }}>
                            <TextFieldInput
                              header="Interest (% P.A)"
                              subheader={token.token_issue_interest_percent}
                            />
                          </Grid>
                        </>
                      )}
                      <Grid item lg={4} md={6} xs={12} sx={{ pr: 1 }}>
                        <TextFieldInput
                          header="Token Issue Price"
                          subheader={token.token_issue_price}
                        />
                      </Grid>
                      <Grid item lg={4} md={6} xs={12} sx={{ pr: 1 }}>
                        <TextFieldInput
                          header="Token Redemption Price"
                          subheader={token.token_redemption_price}
                        />
                      </Grid>
                      <Grid item lg={4} md={6} xs={12} sx={{ pr: 1 }}>
                        <TextFieldInput
                          header="Issue Start Date"
                          subheader={token.token_create_date}
                        />
                      </Grid>
                      <Grid item lg={4} md={6} xs={12} sx={{ pr: 1 }}>
                        <TextFieldInput
                          header="Issue Closure Date"
                          subheader={token.final_maturity_date}
                        />
                      </Grid>
                      {token.lockin_till && (
                        <>
                          <Grid item lg={4} md={6} xs={12} sx={{ pr: 1 }}>
                            <TextFieldInput header="Lock In Till" subheader={token.lockin_till} />
                          </Grid>
                        </>
                      )}

                      <Grid item lg={4} md={6} xs={12} sx={{ pr: 1 }}>
                        <TextFieldInput
                          header="Payment Frequency"
                          subheader={token.payment_frequency_id.payment_frequency_name}
                        />
                      </Grid>

                      <Grid item lg={4} md={6} xs={12} sx={{ pr: 1 }}>
                        <TextFieldInput
                          header="Payment Basis"
                          subheader={token.payment_basis_id.payment_basis_name}
                        />
                      </Grid>
                      <Grid item lg={4} md={6} xs={12} sx={{ pr: 1 }}>
                        <TextFieldInput
                          header="Interest Type"
                          subheader={token.interest_type_id.interest_type_name}
                        />
                      </Grid>
                      <Grid item lg={4} md={6} xs={12} sx={{ pr: 1 }}>
                        <TextFieldInput
                          header="Interest Calc. Method"
                          subheader={token.interest_calc_method_id.interest_calc_method_name}
                        />
                      </Grid>
                      <Grid item lg={4} md={6} xs={12} sx={{ pr: 1 }}>
                        <TextFieldInput header="Tenure (Days)" subheader={token.tenure_months} />
                      </Grid>
                      <Grid item lg={4} md={6} xs={12} sx={{ pr: 1 }}>
                        <TextFieldInput
                          header="Accrual Frequency"
                          subheader={token.accural_frequncy_id.accrual_frequency_name}
                        />
                      </Grid>
                      <Grid item lg={4} md={6} xs={12} sx={{ pr: 1 }}>
                        <TextFieldInput header="Token NAV" subheader={token.token_nav} />
                      </Grid>

                      <Grid item lg={4} md={6} xs={12} sx={{ pr: 1 }} />
                      <Grid item lg={4} md={6} xs={12} sx={{ pr: 1 }} />
                      <Grid item lg={4} md={6} xs={12} sx={{ pr: 1 }} />

                      <Grid item lg={4} md={6} xs={12} sx={{ pr: 1 }} />
                      <Grid item lg={4} md={6} xs={12} sx={{ pr: 1 }} />
                      <Grid item lg={4} md={6} xs={12} sx={{ pr: 1 }} />

                      <Grid item lg={4} md={6} xs={12} sx={{ pr: 1 }} />
                      <Grid item lg={4} md={6} xs={12} sx={{ pr: 1 }} />

                      <Grid item lg={6} md={6} xs={12} sx={{ pr: 1, mt: 1 }}>
                        <Button
                          variant="outlined"
                          sx={{ width: '100%', display: 'grid' }}
                          onClick={() => {
                            window.open(token.contract_doc_hash, '_blank');
                          }}
                        >
                          <Grid
                            item
                            lg={12}
                            md={6}
                            xs={12}
                            sx={{ pr: 1, display: 'flex', placeSelf: 'center center' }}
                          >
                            <Typography>Token Terms Document</Typography>
                            <OpenInNewIcon sx={{ ml: 1 }} />
                          </Grid>
                        </Button>
                      </Grid>

                      <Grid item lg={6} md={6} xs={12} sx={{ pr: 1, display: 'grid' }}>
                        <TextField
                          fullWidth
                          label="Document Hash"
                          value={contractDocumentHash}
                          variant="standard"
                        />
                      </Grid>

                      <Grid item lg={4} md={6} xs={12} sx={{ pr: 1, mt: 2, display: 'grid' }}>
                        <Typography
                          sx={{
                            placeSelf: 'center end',
                            fontWeight: 'bold'
                          }}
                        >
                          Verify Document Hash
                        </Typography>
                      </Grid>

                      <Grid item lg={4} md={6} xs={12} sx={{ pr: 1, mt: 2 }}>
                        {enterHashManually ? (
                          <TextField
                            fullWidth
                            placeholder="SHA256 Hash"
                            size="small"
                            value={fetchedHash}
                            onChange={(e) => setFetchedHash(e.target.value)}
                          />
                        ) : (
                          <TextField
                            fullWidth
                            id="document-to-verify"
                            placeholder="Upload Document"
                            type="file"
                            size="small"
                            inputProps={{ accept: '.pdf' }}
                            onChange={(e) => {
                              setFetchedHash('');
                            }}
                          />
                        )}
                      </Grid>

                      <Grid item lg={4} md={6} xs={12} sx={{ pr: 1, mt: 2, display: 'grid' }}>
                        {fetchedHash === '' && enterHashManually ? (
                          <></>
                        ) : (
                          <Button
                            variant={getVerificationButtonVariant()}
                            color={getVerificationButtonColor()}
                            sx={{ placeSelf: 'center start' }}
                            disableRipple={fetchedHash !== '' || enterHashManually}
                            onClick={async () => {
                              if (!enterHashManually) {
                                if (fetchedHash === '') {
                                  const fileToUpload =
                                    document.getElementById('document-to-verify').files[0];
                                  if (fileToUpload) {
                                    const res = await get0xSha256Hash({
                                      file: fileToUpload
                                    });
                                    setFetchedHash(res.sha256_hash);
                                  }
                                }
                              }
                            }}
                          >
                            <Typography>{getVerificationButtonText()}</Typography>
                          </Button>
                        )}
                      </Grid>

                      <Grid item lg={4} md={6} xs={12} sx={{ pr: 1 }} />

                      <Grid item lg={4} md={6} xs={12} sx={{ display: 'grid' }}>
                        <FormControlLabel
                          sx={{ placeSelf: 'center center' }}
                          control={
                            <Switch
                              checked={enterHashManually}
                              onChange={(e) => {
                                setEnterHashManually(e.target.checked);
                                setFetchedHash('');
                              }}
                            />
                          }
                          label="Enter Hash Manually"
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      </Grid>
                    </Grid>

                    <Divider sx={{ mt: 4 }} />
                    <Divider sx={{ mb: 4 }} />
                    <Typography sx={{ pl: 6, fontWeight: '600' }}>Settlement Terms</Typography>
                    <Grid container spacing={2} sx={{ width: '100%', mt: 1, pr: 6, pl: 6 }}>
                      <Grid item lg={6} md={6} xs={12} sx={{ pr: 1 }}>
                        <TextFieldInput
                          header="Settlement Address"
                          subheader={ethToXdcAddress(token.issuer_id.primary_issuer_xinfin_address)} // DEMO
                        />
                      </Grid>
                      <Grid item lg={6} md={6} xs={12} sx={{ pr: 1 }}>
                        <TextFieldInput
                          header="Settlement Cycle (T + Days)"
                          subheader={token.application_processing_time}
                        />
                      </Grid>
                        {/* The below fields will be conditionally displayed if 
                        the payemnt token is present, for cash it will not be displayed */}
                      { token.payment_token_id && (<><Grid item lg={6} md={6} xs={12} sx={{ pr: 3 }}>
                        <TextFieldInput
                          header="Payment Token"
                          subheader={token.payment_token_id?.contract_name}
                        />
                      </Grid>
                      <Grid item lg={6} md={6} xs={12} sx={{ pr: 3 }}>
                        <TextFieldInput
                          header="Issuance Swap Ratio"
                          subheader={token.swap_ratio_issue}
                        />
                      </Grid>
                      <Grid item lg={6} md={6} xs={12} sx={{ pr: 3 }}>
                        <TextFieldInput
                          header="Redemption Swap Ratio"
                          subheader={token.swap_ratio_redeem}
                        />
                      </Grid>
                      <Grid item lg={6} md={6} xs={12} sx={{ pr: 3 }}>
                        <TextFieldInput
                          header="Collateralized"
                          subheader={token.is_collateralized === true ? 'YES' : 'NO'}
                        />
                      </Grid>
                      {token.is_collateralized === true && (
                        <>
                          {/* <Grid item lg={6} md={6} xs={12} sx={{ pr: 3 }}>
                            <TextFieldInput
                              header="Collateral Token"
                              subheader={token.collateral_token.collateral_asset_name}
                            />
                          </Grid> */}
                          <Grid item lg={6} md={6} xs={12} sx={{ pr: 3 }}>
                            <TextFieldInput
                              header="Collateral(Per Token)"
                              subheader={token.collateral_per_token}
                            />
                          </Grid>
                          <Grid item lg={6} md={6} xs={12} sx={{ pr: 3 }}>
                            <TextFieldInput
                              header="Collateral Provider"
                              subheader={token.collateral_provider.collateral_provider_name}
                            />
                          </Grid>
                          {token.collateral_provider.collateral_provider_name === 'Insurer' && (
                            <>
                              <Grid item lg={6} md={6} xs={12} sx={{ pr: 3 }}>
                                <TextFieldInput
                                  header="Insurer Name"
                                  subheader={token.collateral_provider_entity.insurer_name}
                                />
                              </Grid>
                              <Grid item lg={6} md={6} xs={12} sx={{ pr: 3 }}>
                                <TextFieldInput
                                  header="Collateral Account Address"
                                  subheader={ethToXdcAddress(
                                    token.collateral_provider_entity.primary_insurer_xinfin_address
                                  )}
                                />
                              </Grid>
                              <Grid item lg={6} md={6} xs={12} sx={{ pr: 3 }}>
                                <TextFieldInput
                                  header="Insurer Contribution"
                                  subheader={token.insurer_contri}
                                />
                              </Grid>
                              <Grid item lg={6} md={6} xs={12} sx={{ pr: 3 }}>
                                <TextFieldInput
                                  header="Issuer Contribution"
                                  subheader={token.issuer_contri}
                                />
                              </Grid>
                            </>
                          )}
                          {token.collateral_provider.collateral_provider_name === 'Issuer' && (
                            <Grid item lg={6} md={6} xs={12} sx={{ pr: 3 }}>
                              <TextFieldInput
                                header="Collateral Account Address"
                                subheader={ethToXdcAddress(
                                  token.issuer_id.primary_issuer_xinfin_address
                                )}
                              />
                            </Grid>
                          )}
                        </>
                      )}
                      <Grid item lg={6} md={6} xs={12} sx={{ pr: 3 }}>
                        <TextFieldInput
                          header="Payment Token Address"
                          subheader={ethToXdcAddress(token.payment_token_id?.contract_address)}
                        />
                      </Grid>
                      <Grid item lg={6} md={6} xs={12} sx={{ pr: 3 }}>
                        <TextFieldInput
                          header="Payment Token Symbol"
                          subheader={token.payment_token_id?.symbol}
                        />
                      </Grid>

                      <Grid item lg={6} md={6} xs={12} sx={{ pr: 3 }}>
                        <TextFieldInput
                          header="Payment Token Decimal"
                          subheader={token.payment_token_id?.decimals}
                        />
                      </Grid></>)}
                      { !token.payment_token_id && (
                          <Grid item lg={6} md={6} xs={12} sx={{ pr: 3 }}>
                          <TextFieldInput
                            header="Payment Leg"
                            subheader="CASH"
                          />
                        </Grid>

                      )}
                    </Grid>
                    {/* Token Rules */}
                    {token.onchain_restrictions === true && rules !== '' && (
                      <>
                        <Divider sx={{ mt: 4 }} />
                        <Divider sx={{ mb: 4 }} />
                        <Typography sx={{ pl: 6, fontWeight: '600' }}>Token Rules</Typography>
                        <Grid container spacing={2} sx={{ width: '100%', mt: 1, pr: 6, pl: 6 }}>
                          <Grid item lg={6} md={6} xs={12} sx={{ pr: 3 }}>
                            <TextFieldInput
                              header="Template Name"
                              subheader={rules.template_name}
                            />
                          </Grid>
                          <Grid item lg={6} md={6} xs={12} sx={{ pr: 3 }}>
                            <TextFieldInput
                              header="Template Type"
                              subheader={rules.template_type}
                            />
                          </Grid>
                          {rules.investor_country && rules.investor_country.length !== 0 && (
                            <Grid item lg={6} md={6} xs={12} sx={{ pr: 3 }}>
                              <FormLabel sx={{ fontSize: '12px', color: '#8e8ea7' }}>
                                Investor Countries
                              </FormLabel>
                              <FormControl
                                fullWidth
                                size="small"
                                variant="outlined"
                                sx={{ mt: 1.5 }}
                              >
                                <InputLabel id="demo-simple-select-label">Countries</InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  label="Countries"
                                >
                                  {rules.investor_country &&
                                    rules.investor_country.map((code, index) => {
                                      return (
                                        <ListItem key={code} value={code} readOnly>
                                          {code}- {rules.investor_country_name[index]}
                                        </ListItem>
                                      );
                                    })}
                                </Select>
                              </FormControl>
                            </Grid>
                          )}
                          {rules.investor_classification &&
                            rules.investor_classification.length !== 0 && (
                              <>
                                <Grid item lg={6} md={6} xs={12} sx={{ pr: 3 }}>
                                  <FormLabel sx={{ fontSize: '12px', color: '#8e8ea7' }}>
                                    Investor Classifications
                                  </FormLabel>
                                  <FormControl
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    sx={{ mt: 1.5 }}
                                  >
                                    <InputLabel id="demo-simple-select-label">
                                      Classifications
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      label="Classifications"
                                    >
                                      {rules.investor_classification &&
                                        rules.investor_classification.map((name) => {
                                          return (
                                            <ListItem key={name} value={name} readOnly>
                                              {name}
                                            </ListItem>
                                          );
                                        })}
                                    </Select>
                                  </FormControl>
                                </Grid>
                              </>
                            )}
                          <Grid item lg={6} md={6} xs={12} sx={{ pr: 3 }}>
                            <TextFieldInput
                              header="Use Investor Whitelist"
                              subheader={rules.use_issuer_whitelist === true ? 'YES' : 'NO'}
                            />
                          </Grid>
                        </Grid>
                      </>
                    )}
                    <Divider sx={{ mt: 4 }} />
                    {/* <Divider sx={{ mb: 4 }} />
                    <Typography sx={{ pl: 6, fontWeight: '600' }}>Other Highlights</Typography>
                    <Grid container spacing={2} sx={{ width: '100%', mt: 1, pr: 6, pl: 6 }}>
                      {token.originator_id && (
                        <Grid item lg={6} md={6} xs={12} sx={{ pr: 3 }}>
                          <TextFieldInput
                            header="Originator"
                            subheader={token.originator_id.originator_name}
                          />
                        </Grid>
                      )}
                      <Grid item lg={6} md={6} xs={12} sx={{ pr: 3 }}>
                        <TextFieldInput
                          header="KYC Provider"
                          subheader={token.kyc_provider_id.kyc_provider_name}
                        />
                      </Grid>

                      <Grid item lg={6} md={6} xs={12} sx={{ pr: 3 }}>
                        <Typography sx={{ fontSize: '0.7rem', color: '#888888' }}>
                          Use of proceeds
                        </Typography>
                        <Typography>{token.use_of_proceeds}</Typography>
                      </Grid>
                      <Grid item lg={6} md={6} xs={12} sx={{ pr: 3 }}>
                        <Typography sx={{ fontSize: '0.7rem', color: '#888888' }}>
                          Reference Assets
                        </Typography>
                        <Typography>{token.reference_assets}</Typography>
                      </Grid>
                    </Grid> */}
                  </CardContent>
                </Card>
                <Grid
                  item
                  lg={12}
                  md={12}
                  xs={12}
                  sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}
                >
                  <Button
                    sx={{
                      px: 3,
                      mr: 1,
                      width: '7.75rem',
                      height: '2.5rem'
                    }}
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      navigate('/subscription');
                    }}
                  >
                    Cancel
                  </Button>
                  {/* This below "Add to Wallet button has been commented out 
                  because we are using invisible wallet." */}
                  {/* <LoadingActionButton
                    loadingPosition="start"
                    variant="contained"
                    color="primary"
                    sx={{ mr: 1 }}
                    onClick={() => {
                      const provider = web3.currentProvider;
                      provider.sendAsync(
                        {
                          method: 'metamask_watchAsset',
                          params: {
                            type: 'ERC20',
                            options: {
                              address: token.deployment_address,
                              symbol: token.token_symbol,
                              decimals: 0
                            }
                          },
                          id: Math.round(Math.random() * 100000)
                        },
                        (err, added) => {
                          console.log('provider returned', err, added);
                          if (err || 'error' in added) {
                            enqueueSnackbar('Something went wrong!', { variant: 'error' });
                          } else if (added.result) {
                            enqueueSnackbar('Token added to wallet!', { variant: 'success' });
                          }
                        }
                      );
                    }}
                  >
                    Add to wallet
                  </LoadingActionButton> */}
                  <LoadingActionButton
                    loadingPosition="start"
                    sx={{
                      width: '7.5rem',
                      height: '2.5rem'
                    }}
                    variant="gradient"
                    onClick={() => {
                      if (isBefore(new Date(token.final_maturity_date), new Date(todayDate))) {
                        enqueueSnackbar('Cannot Subscribe After Issue Closer Date', {
                          variant: 'warning'
                        });
                      } else {
                        navigate(`/subscription/form/${token.deployment_address}`);
                      }
                    }}
                  >
                    Subscribe
                  </LoadingActionButton>
                </Grid>
              </CardContent>
            </Container>
          </Card>
        </Container>
      </Page>
    </>
  );
};

export default ViewToken;
