import {
  Card,
  Grid,
  CardContent,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  MenuItem,
  FormControl,
  Select,
  IconButton,
  TextField,
  Snackbar,
  Box,
  Alert,
  InputLabel,
  Checkbox
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import React, { useState } from 'react';
import ListItemText from '@mui/material/ListItemText';
import OutlinedInput from '@mui/material/OutlinedInput';
import { useCoreTableState } from 'state/useCoreTableState';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

/* Add Rules */
const sdata = [
  {
    id: 1,
    rules: 'REG D(506C SEC)'
  },
  {
    id: 2,
    rules: 'REG S(506C SEC)'
  }
];
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 210
    }
  }
};

const nam = ['Affiliated', 'Exempted', 'Accrediated'];
const add = ['BANK A', 'BANK B', 'BANK C'];

const Restrictions = () => {
  const [personName, setPersonName] = React.useState([]);
  const [personNam, setPersonNam] = React.useState([]);
  const [accountmultiple, setaccountmultiple] = React.useState([]);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const handleChange = (event) => {
    const {
      target: { value }
    } = event;
    setPersonName(
      // On autofill we get a the stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };
  const handleChange1 = (event) => {
    const {
      target: { value }
    } = event;
    setPersonNam(
      // On autofill we get a the stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };
  const handleChange2 = (event) => {
    const {
      target: { value }
    } = event;
    setaccountmultiple(typeof value === 'string' ? value.split(',') : value);
  };
  const CardsTemplate = ({ id, rules }) => {
    return (
      <>
        <Card sx={{ width: '19rem', height: '3rem', mt: 2 }} xs={4}>
          <CardContent sx={{ display: 'flex', flexDirection: 'coloumn' }}>
            <Typography>{rules}</Typography>
          </CardContent>
        </Card>
      </>
    );
  };

  const { countries, tokenStatus } = useCoreTableState();

  const [rows, setRows] = useState([
    {
      id: 1,
      restrictions: '',
      lock_in_period: '',
      investor_country_mustbe: '',
      investor_country: '',
      classificatn_mustbe: '',
      classificatn_select: '',
      account_mustbe: '',
      account_country: '',
      account_address: '',
      sanction_check: '',
      preset_check: '',
      token_status: ''
    }
  ]);

  const [open, setOpen] = React.useState(false);
  const [isEdit, setEdit] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleAdd = () => {
    setRows([
      ...rows,
      {
        id: rows.length + 1,
        criteria: '',
        mustbe: '',
        kyc: ''
      }
    ]);
    setEdit(true);
  };

  const handleSave = () => {
    setRows(rows);
    // post data
    enqueueSnackbar('Restrictions Added Successfully', {
      variant: 'success'
    });
    setOpen(true);
    setRows([]);
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...rows];
    list[index][name] = value;
    setRows(list);
  };

  const handleRemoveClick = (i) => {
    const list = [...rows];
    list.splice(i, 1);
    setRows(list);
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={8} lg={4}>
          <Typography> Rule Templates</Typography>
          <Card sx={{ width: '19rem', height: '5rem', mt: 2 }} xs={4}>
            <CardContent sx={{ display: 'flex', flexDirection: 'coloumn' }}>
              <TextField
                sx={{ fontSize: '1rem', width: '15rem', height: '0.5rem' }}
                size="small"
                type="text"
              />
            </CardContent>
          </Card>
          {sdata.map((val) => {
            return <CardsTemplate key={val.id} rules={val.rules} />;
          })}
        </Grid>
        <Grid item xs={12} lg={8}>
          <Grid display="flex">
            <Typography>Transfer Restriction</Typography>
            {/*  <Chip label="You have unsubmitted changes" color="warning" sx={{ ml: 1 }} /> */}
          </Grid>
          {error && (
            <Box mb={4}>
              <Alert severity="error">{error}</Alert>
            </Box>
          )}
          <TableContainer component={Paper} sx={{ width: 720, mr: 1, mt: 1, boxShadow: 'none' }}>
            <Table aria-label="simple table">
              <TableBody>
                {rows.map((row, i) => (
                  <TableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      <FormControl sx={{ m: 1, width: '13rem' }} size="small">
                        <InputLabel id="demo-simple-select-helper-label">Restrictions</InputLabel>
                        <Select
                          labelId="demo-simple-select-helper-label"
                          id="demo-simple-select-helper"
                          label="Restrictions"
                          name="restrictions"
                          value={row.restrictions}
                          onChange={(e) => handleInputChange(e, i)}
                          displayEmpty
                          inputProps={{ 'aria-label': 'Without label' }}
                        >
                          <MenuItem value="Investor_Country">Investor Country </MenuItem>
                          <MenuItem value="Lock_in_Period">Lock in Period </MenuItem>
                          <MenuItem value="Investor_Classification">
                            Investor Classification
                          </MenuItem>
                          <MenuItem value="Investor_Account">Investor</MenuItem>
                          <MenuItem value="ctfchecks">CTF Checks</MenuItem>
                          <MenuItem value="issuerWhitelist">Issuer Whitelist</MenuItem>
                        </Select>
                      </FormControl>
                    </TableCell>
                    {row.restrictions === 'Investor_Country' && (
                      <>
                        <TableCell align="right">
                          <FormControl sx={{ m: 1, width: '7.1rem' }} size="small">
                            <InputLabel id="demo-simple-select-helper-label">Select</InputLabel>
                            <Select
                              labelId="demo-simple-select-helper-label"
                              id="demo-simple-select-helper"
                              label="Select"
                              name="investor_country_mustbe"
                              value={row.investor_country_mustbe}
                              onChange={(e) => handleInputChange(e, i)}
                            >
                              <MenuItem value="is any of">is any of</MenuItem>
                              <MenuItem value="not in">not in</MenuItem>
                              <MenuItem value="must be">must be</MenuItem>
                            </Select>
                          </FormControl>
                        </TableCell>
                        {row.investor_country_mustbe === 'is any of' && (
                          <>
                            <TableCell align="right">
                              <FormControl sx={{ m: 1, width: 180 }}>
                                <InputLabel id="demo-multiple-checkbox-label">Country</InputLabel>
                                <Select
                                  labelId="demo-multiple-checkbox-label"
                                  id="demo-multiple-checkbox"
                                  multiple
                                  size="small"
                                  value={personName}
                                  onChange={handleChange}
                                  input={<OutlinedInput label="Country" />}
                                  renderValue={(selected) => selected.join(', ')}
                                  MenuProps={MenuProps}
                                >
                                  {countries &&
                                    countries.map((country, index) => {
                                      return (
                                        <MenuItem key={country.id} value={country.country_name}>
                                          <Checkbox
                                            checked={personName.indexOf(country.country_name) > -1}
                                          />
                                          {country.country_name}
                                        </MenuItem>
                                      );
                                    })}
                                </Select>
                              </FormControl>
                            </TableCell>
                          </>
                        )}
                        {row.investor_country_mustbe === 'not in' && (
                          <>
                            <TableCell align="right">
                              <FormControl sx={{ m: 1, width: 180 }}>
                                <InputLabel id="demo-multiple-checkbox-label">Country</InputLabel>
                                <Select
                                  labelId="demo-multiple-checkbox-label"
                                  id="demo-multiple-checkbox"
                                  multiple
                                  size="small"
                                  value={personName}
                                  onChange={handleChange}
                                  input={<OutlinedInput label="Country" />}
                                  renderValue={(selected) => selected.join(', ')}
                                  MenuProps={MenuProps}
                                >
                                  {countries &&
                                    countries.map((country, index) => {
                                      return (
                                        <MenuItem key={country.id} value={country.country_name}>
                                          <Checkbox
                                            checked={personName.indexOf(country.country_name) > -1}
                                          />
                                          {country.country_name}
                                        </MenuItem>
                                      );
                                    })}
                                </Select>
                              </FormControl>
                            </TableCell>
                          </>
                        )}
                        {row.investor_country_mustbe === 'must be' && (
                          <>
                            <TableCell align="right">
                              <FormControl sx={{ m: 1, width: 180 }} size="small">
                                <InputLabel id="demo-simple-select-helper-label">
                                  Country
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-helper-label"
                                  id="demo-simple-select-helper"
                                  label="Country"
                                  name="investor_country"
                                  value={row.investor_country}
                                  onChange={(e) => handleInputChange(e, i)}
                                >
                                  {countries &&
                                    countries.map((country, index) => {
                                      return (
                                        <MenuItem key={country.id} value={country.country_code}>
                                          {country.country_name}
                                        </MenuItem>
                                      );
                                    })}
                                </Select>
                              </FormControl>
                            </TableCell>
                          </>
                        )}
                      </>
                    )}
                    {row.restrictions === 'Investor_Classification' && (
                      <>
                        <TableCell align="right">
                          <FormControl sx={{ m: 1, width: '7.1rem' }} size="small">
                            <InputLabel id="demo-simple-select-helper-label">Select</InputLabel>
                            <Select
                              labelId="demo-simple-select-helper-label"
                              id="demo-simple-select-helper"
                              label="Select"
                              name="classificatn_mustbe"
                              value={row.classificatn_mustbe}
                              onChange={(e) => handleInputChange(e, i)}
                            >
                              <MenuItem value={10}>is any of</MenuItem>
                              <MenuItem value={20}>not in</MenuItem>
                              <MenuItem value={30}>must be</MenuItem>
                            </Select>
                          </FormControl>
                        </TableCell>
                        {row.classificatn_mustbe === 10 && (
                          <>
                            <TableCell align="right">
                              <FormControl sx={{ m: 1, width: 180 }}>
                                <InputLabel id="demo-multiple-checkbox-label">Status</InputLabel>
                                <Select
                                  labelId="demo-multiple-checkbox-label"
                                  id="demo-multiple-checkbox"
                                  multiple
                                  size="small"
                                  value={personNam}
                                  onChange={handleChange1}
                                  input={<OutlinedInput label="Status" />}
                                  renderValue={(selected) => selected.join(', ')}
                                  MenuProps={MenuProps}
                                >
                                  {nam.map((name) => (
                                    <MenuItem key={name} value={name}>
                                      <Checkbox checked={personNam.indexOf(name) > -1} />
                                      <ListItemText primary={name} />
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </TableCell>
                          </>
                        )}
                        {row.classificatn_mustbe === 20 && (
                          <>
                            <TableCell align="right">
                              <FormControl sx={{ m: 1, width: 180 }}>
                                <InputLabel id="demo-multiple-checkbox-label">Status</InputLabel>
                                <Select
                                  labelId="demo-multiple-checkbox-label"
                                  id="demo-multiple-checkbox"
                                  multiple
                                  size="small"
                                  value={personNam}
                                  onChange={handleChange1}
                                  input={<OutlinedInput label="Status" />}
                                  renderValue={(selected) => selected.join(', ')}
                                  MenuProps={MenuProps}
                                >
                                  {nam.map((name) => (
                                    <MenuItem key={name} value={name}>
                                      <Checkbox checked={personNam.indexOf(name) > -1} />
                                      <ListItemText primary={name} />
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </TableCell>
                          </>
                        )}
                        {row.classificatn_mustbe === 30 && (
                          <>
                            <TableCell align="right">
                              <FormControl sx={{ m: 1, width: 180 }} size="small">
                                <InputLabel id="demo-simple-select-helper-label">Status</InputLabel>
                                <Select
                                  labelId="demo-simple-select-helper-label"
                                  id="demo-simple-select-helper"
                                  label="Status"
                                  name="investor_country"
                                  value={row.investor_country}
                                  onChange={(e) => handleInputChange(e, i)}
                                >
                                  <MenuItem value={10}>Affiliated</MenuItem>
                                  <MenuItem value={20}>Exempted</MenuItem>
                                  <MenuItem value={30}>Accrediated</MenuItem>
                                </Select>
                              </FormControl>
                            </TableCell>
                          </>
                        )}
                      </>
                    )}
                    {row.restrictions === 'Investor_Account' && (
                      <>
                        <TableCell align="right">
                          <FormControl sx={{ m: 1, width: '7.1rem' }} size="small">
                            <InputLabel id="demo-simple-select-helper-label">Select</InputLabel>
                            <Select
                              labelId="demo-simple-select-helper-label"
                              id="demo-simple-select-helper"
                              label="Select"
                              name="account_mustbe"
                              value={row.account_mustbe}
                              onChange={(e) => handleInputChange(e, i)}
                            >
                              <MenuItem value={10}>is any of</MenuItem>
                              <MenuItem value={20}>not in</MenuItem>
                              <MenuItem value={30}>must be</MenuItem>
                            </Select>
                          </FormControl>
                        </TableCell>
                        {row.account_mustbe === 10 && (
                          <>
                            <TableCell align="right">
                              <FormControl sx={{ m: 1, width: 180 }}>
                                <InputLabel id="demo-multiple-checkbox-label">Investor</InputLabel>
                                <Select
                                  labelId="demo-multiple-checkbox-label"
                                  id="demo-multiple-checkbox"
                                  multiple
                                  size="small"
                                  value={accountmultiple}
                                  onChange={handleChange2}
                                  input={<OutlinedInput label="Investor" />}
                                  renderValue={(selected) => selected.join(', ')}
                                  MenuProps={MenuProps}
                                >
                                  {add.map((name) => (
                                    <MenuItem key={name} value={name}>
                                      <Checkbox checked={accountmultiple.indexOf(name) > -1} />
                                      <ListItemText primary={name} />
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </TableCell>
                          </>
                        )}
                        {row.account_mustbe === 20 && (
                          <>
                            <TableCell align="right">
                              <FormControl sx={{ m: 1, width: 180 }}>
                                <InputLabel id="demo-multiple-checkbox-label">Investor</InputLabel>
                                <Select
                                  labelId="demo-multiple-checkbox-label"
                                  id="demo-multiple-checkbox"
                                  multiple
                                  size="small"
                                  value={accountmultiple}
                                  onChange={handleChange2}
                                  input={<OutlinedInput label="Investor" />}
                                  renderValue={(selected) => selected.join(', ')}
                                  MenuProps={MenuProps}
                                >
                                  {add.map((name) => (
                                    <MenuItem key={name} value={name}>
                                      <Checkbox checked={accountmultiple.indexOf(name) > -1} />
                                      <ListItemText primary={name} />
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </TableCell>
                          </>
                        )}
                        {row.account_mustbe === 30 && (
                          <>
                            <TableCell align="right">
                              <FormControl sx={{ m: 1, width: 180 }} size="small">
                                <InputLabel id="demo-simple-select-helper-label">
                                  Investor
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-helper-label"
                                  id="demo-simple-select-helper"
                                  label="Investor"
                                  name="account_address"
                                  value={row.account_address}
                                  onChange={(e) => handleInputChange(e, i)}
                                >
                                  <MenuItem value={10}>BANK A</MenuItem>
                                  <MenuItem value={20}>BANK B</MenuItem>
                                  <MenuItem value={30}>BANK C</MenuItem>
                                </Select>
                              </FormControl>
                            </TableCell>
                          </>
                        )}
                      </>
                    )}
                    {row.restrictions === 'Lock_in_Period' && (
                      <>
                        <TableCell align="left">
                          <FormControl sx={{ m: 1, width: '6rem' }} size="small">
                            <Typography>in months</Typography>
                          </FormControl>
                        </TableCell>
                        <TableCell align="right">
                          <FormControl sx={{ m: 1, width: '7.1rem' }} size="small">
                            <InputLabel id="demo-simple-select-helper-label">Months</InputLabel>
                            <Select
                              labelId="demo-simple-select-helper-label"
                              id="demo-simple-select-helper"
                              name="lock_in_period"
                              value={row.lock_in_period}
                              label="Status"
                              onChange={(e) => handleInputChange(e, i)}
                            >
                              <MenuItem value="1">1</MenuItem>
                              <MenuItem value="2">2</MenuItem>
                              <MenuItem value="3">3</MenuItem>
                              <MenuItem value="4">4</MenuItem>
                              <MenuItem value="5">5</MenuItem>
                              <MenuItem value="6">6</MenuItem>
                              <MenuItem value="7">7</MenuItem>
                              <MenuItem value="8">8</MenuItem>
                              <MenuItem value="9">9</MenuItem>
                              <MenuItem value="10">10</MenuItem>
                              <MenuItem value="11">11</MenuItem>
                              <MenuItem value="12">12</MenuItem>
                            </Select>
                          </FormControl>
                        </TableCell>
                      </>
                    )}
                    {row.restrictions === 'ctfchecks' && (
                      <>
                        <TableCell align="left">
                          <FormControl sx={{ m: 1 }} size="small">
                            <Checkbox
                              name="sanction_check"
                              value={row.sanction_check}
                              onChange={(e) => handleInputChange(e, i)}
                              inputProps={{ 'aria-label': 'controlled' }}
                            />
                          </FormControl>
                        </TableCell>
                      </>
                    )}
                    {row.restrictions === 'issuerWhitelist' && (
                      <>
                        <TableCell align="left">
                          <FormControl sx={{ m: 1 }} size="small">
                            <Checkbox
                              name="preset_check"
                              value={row.preset_check}
                              onChange={(e) => handleInputChange(e, i)}
                              inputProps={{ 'aria-label': 'controlled' }}
                            />
                          </FormControl>
                        </TableCell>
                      </>
                    )}
                    <TableCell align="left">
                      <IconButton aria-label="delete" onClick={() => handleRemoveClick(i)}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Grid container sx={{ mt: 4 }}>
            <Grid item xs={12} lg={6}>
              <Button onClick={handleAdd}>+ Add restrictions</Button>
            </Grid>
            <Grid item xs={6} lg={6}>
              <Button
                variant="outlined"
                onClick={() => {
                  navigate('/home/token-dashboard');
                }}
              >
                Close
              </Button>
              <Button variant="gradient" sx={{ ml: 2 }} onClick={handleSave}>
                Submit Changes
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Restrictions;
