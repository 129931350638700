import React from 'react';
import Page from 'components/Page';
import {
  Container,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Box,
  Tab,
  Chip
} from '@mui/material';
import Breadcrumbs from 'components/Breadcrumbs';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import OwnerTokenTable from 'components/owner/OwnerTokenTabe';
import AddPaymentTokens from 'components/owner/AddPaymentTokens';
import ManageTokenTable from 'components/owner/ManageTokenTable';
import { useWeb3 } from 'state';
import { currentNetwork, ethToXdcAddress } from 'helpers/web3';
import AddressFieldTools from 'components/AddressFieldTools';
import AccordionLayout from 'helpers/AccordionLayout';
import TransactionsTable from 'components/owner/monitored-events/TransactionsTable';
import HealthCheck from 'components/owner/HealthCheck';

const OwnerDashboard = () => {
  const { getERC20Name, chainId } = useWeb3();
  const [value, setValue] = React.useState('1');
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  console.log('🚀 ~ file: OwnerDashboard.js ~ line 42 ~ currentNetwork', currentNetwork);
  return (
    <>
      <Page title="Admin Dashboard">
        <Container>
          <Breadcrumbs aria-label="breadcrumb" pageHead="Admin Dashboard" />
          <Accordion
            defaultExpanded
            sx={{
              boxShadow: 'none',
              border: '1px solid #D2D2D2',
              borderRadius: '6px',
              px: 4,
              py: 1,
              mt: '29px'
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography
                sx={{
                  fontSize: '1.125rem',
                  fontWeight: 'bold'
                }}
              >
                Smart Contracts
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TableContainer>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Contract Name</TableCell>
                      <TableCell>Contract Address</TableCell>
                      <TableCell
                        sx={{
                          textAlign: 'center'
                        }}
                      >
                        View On Blocks Scan
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 'bold' }}>Token Controller</TableCell>
                      <TableCell>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            m: 0,
                            p: 0,
                            alignItems: 'center'
                          }}
                        >
                          <Box
                            sx={{
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden'
                            }}
                          >
                            {ethToXdcAddress(currentNetwork.custodianContractAddress)}
                          </Box>
                          <IconButton
                            aria-label="subs detail"
                            onClick={() =>
                              navigator.clipboard.writeText(
                                ethToXdcAddress(currentNetwork.custodianContractAddress)
                              )
                            }
                          >
                            <ContentCopyIcon sx={{ fontSize: '1rem' }} />
                          </IconButton>
                        </Box>
                      </TableCell>
                      <TableCell
                        sx={{
                          display: 'flex',
                          justifyContent: 'center'
                        }}
                      >
                        <AddressFieldTools
                          address={currentNetwork.custodianContractAddress}
                          showInBlockExplorer
                          showAddress={false}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 'bold' }}>Escrow & Settlement</TableCell>
                      <TableCell>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            m: 0,
                            p: 0,
                            alignItems: 'center'
                          }}
                        >
                          <Box
                            sx={{
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden'
                            }}
                          >
                            {ethToXdcAddress(currentNetwork.escrowManagerAddress)}
                          </Box>
                          <IconButton
                            aria-label="subs detail"
                            onClick={() =>
                              navigator.clipboard.writeText(
                                ethToXdcAddress(currentNetwork.escrowManagerAddress)
                              )
                            }
                          >
                            <ContentCopyIcon sx={{ fontSize: '1rem' }} />
                          </IconButton>
                          {/* <Chip
                            variant="outlined"
                            color="success"
                            label="XDC Balance (150.5)"
                            sx={{ width: '10rem', height: '2.45rem' }}
                          /> */}
                        </Box>
                      </TableCell>
                      <TableCell
                        sx={{
                          display: 'flex',
                          justifyContent: 'center'
                        }}
                      >
                        <AddressFieldTools
                          address={currentNetwork.escrowManagerAddress}
                          showInBlockExplorer
                          showAddress={false}
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </AccordionDetails>
          </Accordion>

          <AccordionLayout title="Add Stable Coins" content={<AddPaymentTokens />} />
          <AccordionLayout title="Stable Coins" content={<ManageTokenTable />} />
          <AccordionLayout title="Token Dashboard" content={<OwnerTokenTable />} />

          {/* Monitor Events */}
          {/* <Accordion
            sx={{
              boxShadow: 'none',
              border: '1px solid #D2D2D2',
              borderRadius: '6px',
              px: 4,
              py: 1,
              mt: '29px'
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography
                sx={{
                  fontSize: '1.125rem',
                  fontWeight: 'bold'
                }}
              >
                Monitor Events
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TabContext value={value}>
                <TabList onChange={handleChange} aria-label="lab API tabs example" sx={{ p: 2 }}>
                  <Tab
                    label="Health Check"
                    value="1"
                    sx={{ fontWeight: 600, color: '#161c2d', fontSize: '1.125rem' }}
                  />
                  <Tab
                    label="Transaction Logs"
                    value="4"
                    sx={{ fontWeight: 600, color: '#161c2d', fontSize: '1.125rem' }}
                  />
                </TabList>
                <TabPanel value="1">
                  <HealthCheck />
                </TabPanel>
                <TabPanel value="4">
                  <TransactionsTable />
                </TabPanel>
              </TabContext>
            </AccordionDetails>
          </Accordion> */}
        </Container>
      </Page>
    </>
  );
};

export default OwnerDashboard;
