import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import Page from 'components/Page';
import {
  Container,
  Card,
  Grid,
  Box,
  CardContent,
  Typography,
  TextField,
  FormLabel,
  Button,
  Divider,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  Alert,
  List,
  ListItem,
  ListItemText,
  IconButton,
  InputAdornment
} from '@mui/material';
import Breadcrumbs from 'components/Breadcrumbs';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { useFormik, Form, FormikProvider } from 'formik';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
import { useAppState, useAuthState, useCoreTableState, useHttpApi } from 'state';

const ForgotPassword = () => {
  const { changePassword } = useAuthState();
  const { enqueueSnackbar } = useSnackbar();
  const [showNewPassword, setShowNewPassword] = useState(false);
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const { fetchOrganizations } = useCoreTableState();
  const { throwErrorMessage } = useAppState();
  const { confirmForgotPassword } = useHttpApi();
  const { token } = useParams();
  const ChangePasswordSchema = Yup.object().shape({
    new_password: Yup.string()
      .required('New Password is required')
      .min(8, 'Password must be minimum 8 characters long')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])[A-Za-z\d!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{8,}$/,
        'Must Contain atleast one Uppercase, one Lowercase, one Number and one special case Character'
      ),
    confirm_new_password: Yup.string()
      .oneOf([Yup.ref('new_password'), null], 'Passwords must match')
      .required('Confirm New Password is required')
  });
  const formik = useFormik({
    initialValues: {
      new_password: '',
      confirm_new_password: ''
    },
    validationSchema: ChangePasswordSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        setError(null);
        console.log(values.new_password);
        values.password = values.new_password;
        values.token = token;
        console.log(values);
        await confirmForgotPassword(values);
        enqueueSnackbar('Password Changed Successfully', { variant: 'success' });
        resetForm();
        navigate('/login');
        fetchOrganizations();
      } catch (e) {
        const errorMessage = await throwErrorMessage(e);
        setError(errorMessage);
      }
    }
  });
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;
  return (
    <>
      <Page title="Change Password" sx={{ maxWidth: 1000, margin: 'auto' }}>
        <Typography
          sx={{
            fontSize: '1.5rem',
            fontWeight: 'bold',
            mt: '5px',
            textAlign: 'center'
          }}
        >
          Create New Password
        </Typography>
        <Container sx={{ my: 2 }}>
          {error && (
            <Box mb={4}>
              <Alert
                severity="error"
                sx={{
                  textTransform: 'capitalize'
                }}
              >
                {error}
              </Alert>
            </Box>
          )}
          <Card>
            <Container>
              <FormikProvider value={formik}>
                <Form autoComplete="off" onSubmit={handleSubmit}>
                  <CardContent sx={{ m: 2 }}>
                    <Grid container spacing={3} sx={{ width: '100%', mt: 1 }}>
                      <Grid item lg={6} md={6} sm={12} xs={12} sx={{ pr: 1 }}>
                        <Grid item lg={12} md={6} xs={12} sm={12} sx={{ mt: 2 }}>
                          <FormLabel>New Password</FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            {...getFieldProps('new_password')}
                            error={Boolean(touched.new_password && errors.new_password)}
                            helperText={touched.new_password && errors.new_password}
                            fullWidth
                            size="small"
                            autoComplete="off"
                            type={showNewPassword ? 'text' : 'password'}
                            placeholder="Enter New Password"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={() => {
                                      setShowNewPassword((show) => !show);
                                    }}
                                    edge="end"
                                  >
                                    <Icon icon={showNewPassword ? eyeFill : eyeOffFill} />
                                  </IconButton>
                                </InputAdornment>
                              )
                            }}
                          />
                        </Grid>
                        <Grid item lg={12} md={6} xs={12} sm={12} sx={{ mt: 2 }}>
                          <FormLabel>New Password Confirmation</FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            {...getFieldProps('confirm_new_password')}
                            error={Boolean(
                              touched.confirm_new_password && errors.confirm_new_password
                            )}
                            helperText={touched.confirm_new_password && errors.confirm_new_password}
                            fullWidth
                            size="small"
                            autoComplete="off"
                            type="password"
                            placeholder="Confirm New Password"
                          />
                        </Grid>
                      </Grid>
                      <Grid item lg={6} md={6} sm={12} xs={12} sx={{ pr: 1 }}>
                        <Grid item lg={12} md={6} xs={12} sm={12}>
                          <Typography sx={{ fontWeight: 'bold' }}>
                            In order to protect your account, make sure your password is:
                          </Typography>
                          <List>
                            <ListItem>
                              <ListItemText secondary="New password must be different from previously used old password" />
                            </ListItem>
                            <ListItem>
                              <ListItemText secondary="At least 8 characters long" />
                            </ListItem>
                            <ListItem>
                              <ListItemText secondary="Characters from 3 different groups(lowercase, uppercase, numbers, special characters)" />
                            </ListItem>
                          </List>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Box
                      sx={{
                        mt: 4,
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center'
                      }}
                    >
                      <Button
                        sx={{
                          margin: 1,
                          borderColor: '#24ABDF',
                          px: 3,
                          width: '7.75rem',
                          height: '2.5rem'
                        }}
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                          navigate('/login');
                        }}
                      >
                        Cancel
                      </Button>
                      <LoadingButton
                        type="submit"
                        variant="gradient"
                        fullWidth
                        size="large"
                        loadingPosition="start"
                        loading={isSubmitting}
                        sx={{ margin: 1, px: 3, width: '12rem', height: '2.5rem' }}
                      >
                        Change Password
                      </LoadingButton>
                    </Box>
                  </CardContent>
                </Form>
              </FormikProvider>
            </Container>
          </Card>
        </Container>
      </Page>
    </>
  );
};

export default ForgotPassword;
