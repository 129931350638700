import Page from 'components/Page';
import React from 'react';
import { Card, Container, CardContent } from '@mui/material';
import Breadcrumbs from 'components/Breadcrumbs';
import CreditInsurerTable from 'components/masterData/CreditInsurer/CreditInsurerTable';

const AllCreditInsurer = () => {
  return (
    <Page title="Credit Insurer - Master Data">
      <Container>
        <Breadcrumbs pageHead="All Credit Insurers" />
        <Card sx={{ mt: 2 }}>
          <CardContent>
            <CreditInsurerTable />
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
};

export default AllCreditInsurer;
