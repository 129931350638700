import React, { useCallback, useEffect, useMemo, useState } from 'react';
import EnhancedTable from 'components/tables/EnhancedTable';
import {
  Box,
  IconButton,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  Alert,
  AlertTitle
} from '@mui/material';
import { useIsOwner } from 'helpers/rbac';
import Delete from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useBusinessEntityState } from 'state/useBusinessEntityState';
import { ethToXdcAddress, xdcToEthAddress } from 'helpers/web3';
import { useNavigate } from 'react-router';
import { useHttpApi, useWeb3, useAuthStateShared, useAppState } from 'state';
import { useSnackbar } from 'notistack';
import { HeaderName } from 'helpers/tableHelper';
import TransactionHandler from 'components/TransactionHandler';
import { LoadingButton } from '@mui/lab';
import CopyAddress from 'components/CopyAddress';

const CreditInsurerName = HeaderName('Insurer Name');
const CountryCode = HeaderName('Country Code');

const ActionButtons = ({ insurerId, insurerStatus, insurerXInfin, insurerName }) => {
  const { fetchInsurer, setEditInsurer } = useBusinessEntityState();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  console.log(insurerId);
  const { getInsurerById, deleteInsurer } = useHttpApi();
  const navigate = useNavigate();
  const { removeInsurer: removeInsurerBlockchain } = useWeb3();
  const [open, setOpen] = useState(false);
  const { throwErrorMessage } = useAppState();
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const [loadingState, setLoadingState] = useState(false);
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          m: 0,
          p: 0,
          alignItems: 'center'
        }}
      >
        <IconButton
          aria-label="subs detail"
          onClick={async () => {
            // setBusinessEntity(insurerId);
            const response = await getInsurerById(insurerId);
            console.log('ressss', response);
            response.primary_insurer_xinfin_address = ethToXdcAddress(
              response.primary_insurer_xinfin_address
            );
            setEditInsurer(response);
            navigate('/masterdata/credit-insurer/edit-credit-insurer');
            console.log('edit insurer id', insurerId);
          }}
        >
          <EditIcon sx={{ fontSize: '2.188rem', m: 0, p: 1, backgroundColor: '#F5F8FA' }} />
        </IconButton>

        <IconButton aria-label="subs detail" onClick={handleOpen}>
          <Delete sx={{ fontSize: '2.188rem', p: 1, backgroundColor: '#F5F8FA' }} />
        </IconButton>
      </Box>
      <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title">
        <Alert severity="warning">
          <AlertTitle>Delete Insurer</AlertTitle>
          <DialogContent>Are you sure you want to delete Insurer, {insurerName}?</DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleClose}
              sx={{ width: '6.7rem' }}
            >
              Cancel
            </Button>
            <LoadingButton
              variant="outlined"
              color="error"
              loadingPosition="start"
              sx={{ width: '6.7rem' }}
              loading={loadingState}
              onClick={async () => {
                setLoadingState(true);
                console.log('delete insurer id', insurerId, insurerStatus);
                if (insurerStatus === 'ONCHAIN') {
                  try {
                    const res = await removeInsurerBlockchain(xdcToEthAddress(insurerXInfin));
                    if (res) {
                      enqueueSnackbar('Credit Insurer is submitted to Delete', {
                        variant: 'info'
                      });
                      fetchInsurer();
                    }
                  } catch (e) {
                    throwErrorMessage(e);
                  }
                } else {
                  await deleteInsurer(insurerId);
                  enqueueSnackbar('Credit Insurer deleted successfully', {
                    variant: 'success'
                  });
                  fetchInsurer();
                }
                setLoadingState(false);
                handleClose();
              }}
            >
              Delete
            </LoadingButton>
          </DialogActions>
        </Alert>
      </Dialog>
    </>
  );
};
const InsurerChainStatus = ({ status, countryCode, address }) => {
  const { addInsurer } = useWeb3();

  const commitTransaction = useCallback(
    (hash) => {
      return addInsurer(countryCode, address, false, hash);
    },
    [addInsurer, address, countryCode]
  );

  const { fetchInsurer } = useBusinessEntityState();

  const onConfirmed = useCallback(() => {
    fetchInsurer();
  }, [fetchInsurer]);

  return (
    <TransactionHandler
      id={`add-insurer-${address}`}
      commitTransaction={commitTransaction}
      done={status === 'ONCHAIN'}
      onConfirmed={onConfirmed}
    >
      {({ status: txStatus, error, receipt, send }) => {
        console.log('TransactionHandler', txStatus, error && error.message, receipt);

        return (
          <Chip
            // onClick={status==="OFFCHAIN"?send:null}
            label={status}
            color={status === 'OFFCHAIN' ? 'warning' : 'success'}
          />
        );
      }}
    </TransactionHandler>
  );
};

export default function CreditInsurerTable() {
  const { insurers, fetchInsurer } = useBusinessEntityState();
  console.log(insurers);
  const isOwner = useIsOwner();

  const headCells = useMemo(
    () => [
      {
        accessor: 'insurer_name',
        Header: CreditInsurerName,
        show: true
      },
      // {
      //   accessor: 'lei',
      //   Header: 'LEI',
      //   show: true
      // },
      // {
      //   accessor: 'license_No',
      //   Header: 'License No',
      //   show: true
      // },
      {
        accessor: 'primary_insurer_xinfin_address',
        Header: 'Xinfin Address',
        width: 200,
        Cell: ({ value }) => {
          return <CopyAddress addr={ethToXdcAddress(value)} />;
        },
        show: true
      },

      {
        accessor: 'insurer_country_code.country_code',
        Header: CountryCode,
        show: true
      },

      {
        accessor: 'status',
        Header: 'Status',
        Cell: ({ row: { original } }) => {
          return (
            <InsurerChainStatus
              status={original.status}
              countryCode={original.insurer_country_code.country_code}
              address={original.primary_insurer_xinfin_address}
            />
          );
        },
        show: true
      },
      {
        accessor: 'actions',
        Header: 'Actions',
        Cell: ({ row: { original } }) => {
          return (
            <ActionButtons
              insurerId={original.insurer_id}
              insurerName={original.insurer_name}
              insurerStatus={original.status}
              insurerXInfin={original.primary_insurer_xinfin_address}
            />
          );
        },
        show: isOwner
      }
    ],
    [isOwner]
  );

  return (
    <>
      <EnhancedTable
        tableTitle="CreditInsurer"
        columns={headCells}
        refreshFunction={fetchInsurer}
        data={insurers}
        // setData={setData}
        // updateMyData={updateMyData}
        // skipPageReset={skipPageReset}
      />
    </>
  );
}
