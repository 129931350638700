// import * as React, {useEffect} from 'react';
import { useEffect, useState, useMemo } from 'react';
import {
  useBusinessEntityState,
  useAuthStateShared,
  useHttpApi,
  useWeb3,
  useAppState
} from 'state';
import EnhancedTableCheck from 'components/tables/EnhancedTableCheck';
import {
  Box,
  IconButton,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  Alert,
  AlertTitle
} from '@mui/material';
import { onlyIssuer, useIsIssuer } from 'helpers/rbac';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import Delete from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router';
import CopyAddress from 'components/CopyAddress';
import { ethToXdcAddress, xdcToEthAddress } from 'helpers/web3';
import LoadingActionButton from 'helpers/LoadingActionButton';

export default function IssuerWhiteListTable() {
  const { role, user } = useAuthStateShared();
  const { addToIssuerWhitelist, removeFromIssuerWhitelist } = useHttpApi();
  const [selectedInvestors, setSelectedInvestors] = useState();
  const [selectedData, setSelectedData] = useState([]);
  const { investorsByIssuer, fetchInvestorsByIssuer, fetchWhiteList, currentToken } =
    useBusinessEntityState();
  const [data, setData] = useState(investorsByIssuer);
  const [skipPageReset, setSkipPageReset] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { addIssuerWhiteList, removeIssuerWhitelist } = useWeb3();
  const { throwErrorMessage } = useAppState();

  const issuerButtons = onlyIssuer(role) ? (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end'
      }}
    >
      <LoadingActionButton
        loadingPosition="start"
        variant="gradient"
        sx={{
          mt: '2rem',
          width: '13.4rem'
        }}
        onClick={async () => {
          try {
            console.log(selectedData);
            const addressList = selectedData.map((d) => d.investor_xinfin_account);
            console.log(addressList);
            if (selectedData.length > 0) {
              // blockchain call
              const blockchainResponse = await addIssuerWhiteList(
                user.xinfin_address.xinfin_account_address,
                addressList
              );
              console.log(blockchainResponse);
              // django call
              const res = await addToIssuerWhitelist(user.xinfin_address.xinfin_account_address, {
                current_token_id: currentToken.token_id,
                investors: selectedData
              });
              /* if (res.message) {
                fetchWhiteList();
              } */
              enqueueSnackbar('Added to Issuer Whitelist!', { variant: 'success' });
            } else {
              enqueueSnackbar('No address selected!', { variant: 'error' });
            }
            refreshInvestors();
          } catch (e) {
            throwErrorMessage(e);
          }
        }}
      >
        Add to Issuer Whitelist
      </LoadingActionButton>
      <LoadingActionButton
        loadingPosition="start"
        variant="gradient"
        sx={{
          mt: '2rem',
          ml: '1rem',
          width: '16.4rem'
        }}
        onClick={async () => {
          try {
            const addressList = selectedData.map((d) => d.investor_xinfin_account);
            console.log(addressList);
            const blockchainResponse = await removeIssuerWhitelist(
              user.xinfin_address.xinfin_account_address,
              addressList
            );
            console.log(blockchainResponse);
            if (selectedData.length > 0) {
              const res = await removeFromIssuerWhitelist(
                user.xinfin_address.xinfin_account_address,
                {
                  investors: selectedData
                }
              );
              /*   if (res.message) {
                fetchWhiteList();
              } */
              enqueueSnackbar('Removed from Issuer Whitelist!', { variant: 'success' });
            } else {
              enqueueSnackbar('No address selected!', { variant: 'error' });
            }
            refreshInvestors();
          } catch (e) {
            throwErrorMessage(e);
          }
        }}
      >
        Remove from Issuer Whitelist
      </LoadingActionButton>
    </Box>
  ) : (
    <></>
  );

  const refreshInvestors = () => {
    fetchInvestorsByIssuer(user.xinfin_address.xinfin_account_address);
  };

  useEffect(() => {
    refreshInvestors();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const _investors = [];
    if (selectedInvestors) {
      selectedInvestors.forEach((i) => {
        _investors.push({
          ...i.investor,
          investor_xinfin_account: i.investor_xinfin_account
        });
      });
    }
    setSelectedData(_investors);
  }, [selectedInvestors]);

  useEffect(() => {
    setData(investorsByIssuer);
  }, [investorsByIssuer]);

  const ActionButtons = ({ investorId, investorName }) => {
    const [open, setOpen] = useState(false);
    // const { fetchInvestor, setEditInvestor } = useBusinessEntityState();
    // const { getInvestorById, deleteInvestor } = useHttpApi();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { throwErrorMessage } = useAppState();
    const navigate = useNavigate();
    const handleClose = () => {
      setOpen(false);
    };
    const handleOpen = () => {
      setOpen(true);
    };
    const [loadingState, setLoadingState] = useState(false);

    return (
      <>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            m: 0,
            p: 0,
            alignItems: 'center'
          }}
        >
          <IconButton
            aria-label="subs detail"
            onClick={() => {
              /*  const response = await getInvestorById(investorId); 
              console.log('ressss', response);
              setEditInvestor(response); */
              navigate('/masterdata/investor/edit-investor');
              console.log('edit investor id', investorId);
            }}
          >
            <EditIcon sx={{ fontSize: '2.188rem', m: 0, p: 1, backgroundColor: '#F5F8FA' }} />
          </IconButton>

          <IconButton aria-label="subs detail" onClick={handleOpen}>
            <Delete sx={{ fontSize: '2.188rem', p: 1, backgroundColor: '#F5F8FA' }} />
          </IconButton>
        </Box>
        <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title">
          <Alert severity="warning">
            <AlertTitle>Delete Investor</AlertTitle>
            <DialogContent>Are you sure you want to delete Investor, {investorName}?</DialogContent>
            <DialogActions>
              <Button variant="outlined" color="secondary" onClick={handleClose}>
                Cancel
              </Button>
              <LoadingButton
                variant="outlined"
                color="error"
                onClick={async () => {
                  setLoadingState(true);
                  console.log('delete investor id', investorId);
                  try {
                    // const res = await deleteInvestor(investorId);
                    // console.log('res', res);
                    // if (res) {
                    console.log('deleted');
                    enqueueSnackbar('Investor deleted successfully', {
                      variant: 'success'
                    });
                    // fetchInvestor();
                    // }
                  } catch (e) {
                    throwErrorMessage(e);
                  }
                  setLoadingState(false);
                  handleClose();
                }}
              >
                Delete
              </LoadingButton>
            </DialogActions>
          </Alert>
        </Dialog>
      </>
    );
  };

  const isIssuer = useIsIssuer();
  const headCells = useMemo(
    () => [
      {
        accessor: 'investor.investor_name',
        Header: 'Investor',
        width: 100,
        show: true
      },
      {
        accessor: 'investor_xinfin_account',
        Header: 'Xinfin Address',
        width: 200,
        Cell: ({ value }) => {
          return <CopyAddress addr={ethToXdcAddress(value)} />;
        },
        show: true
      },
      {
        accessor: 'is_whitelisted',
        Header: 'Is Whitelisted',
        width: 100,
        show: true,
        Cell: (c) =>
          c.row.values.is_whitelisted ? (
            <Chip label="Yes" color="success" sx={{ mb: '0.5rem' }} />
          ) : (
            <Chip label="No" color="warning" sx={{ mb: '0.5rem' }} />
          )
      }
      // {
      //   accessor: 'actions',
      //   Header: 'Actions',
      //   Cell: ({ row: { original } }) => {
      //     return (
      //       <ActionButtons
      //         investorId={original.id}
      //         investorName={original.investor.investor_name}
      //       />
      //     );
      //   },
      //   show: isIssuer
      // }
    ],
    []
  );

  const updateMyData = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true);
    setData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value
          };
        }
        return row;
      })
    );
  };

  return (
    <>
      <EnhancedTableCheck
        tableTitle="Investors"
        columns={headCells}
        refreshFunction={refreshInvestors}
        setSelectedRowsToState={setSelectedInvestors}
        data={data}
        setData={setData}
        updateMyData={updateMyData}
        skipPageReset={skipPageReset}
        isRoleAllowed={onlyIssuer(role)}
      />
      {issuerButtons}
    </>
  );
}
