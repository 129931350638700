import * as Yup from 'yup';

const today = new Date();
today.setHours(0, 0, 0, 0);
const tmr = new Date() + 1;

const CreateTokenSchema = Yup.object().shape({
  token_name: Yup.string()
    .required('Token Name is required')
    .max(50, 'Token Name cannot be more than 50 characters'),
  token_symbol: Yup.string()
    .required('Token Symbol is required')
    .max(11, 'Token Symbol cannot be more than 11 characters'),
  issuer_id: Yup.string().required('Issuer is required'),
  token_currency: Yup.object().required('Currency is required').nullable(),
  total_supply: Yup.number()
    .required('Token Supply is required')
    .min(1, 'Token Supply must be positive number')
    .max(100000000000, 'Token Supply must be less'),
  token_value: Yup.number()
    .required('Token Value is required')
    .min(1, 'Token Value must be positive number')
    .max(1000000000, 'Token Value must be less'),
  issue_size: Yup.number()
    .positive('Issue Size must be positive number')
    .required('Issue Size is required'),
  token_type: Yup.string().required('Token Type is required'),
  liquidity_pool: Yup.string().when('token_type', {
    is: (item) => item === 'POOL_LENDING' || item === 'PROXY_TOKEN',
    then: Yup.string().required('Liquidity Pool is required')
  }),
  issue_type_id: Yup.object().required('Issue Type is required').nullable(),
  issue_category_id: Yup.string().required('Issue Category is required'),
  issue_sub_category_id: Yup.string().required('Issue Sub Category is required'),

  // Primary Economic Terms

  minimum_subscription: Yup.number()
    .required('Minimum Subscription is required')
    .min(1, 'Minimum Subscription cannot be less than 1'),
  token_issue_interest_percent: Yup.number().when('issue_type_id.issue_calc_type', {
    is: (item) => item !== 'NAV',
    then: Yup.number()
      .required('Interest Percent is required')
      .min(0, 'Interest Percent cannot be less than 0')
      .max(100, 'Interest cannot be more than 100%')
  }),
  token_issue_desc_percent: Yup.number().when('issue_type_id.issue_calc_type', {
    is: 'DISCOUNT',
    then: Yup.number()
      .required('Discount % is required')
      .min(0, 'Discount % cannot be less than 0')
      .max(100, 'Discount % cannot be more than 100%')
  }),

  token_issue_price: Yup.number().required('Token Issue Price is required'),
  token_redemption_price: Yup.number()
    .positive('Token redemption price must be positive number')
    .required('Token Redemption Price is required'),
  token_create_date: Yup.date()
    .required('Issue Start Date is required')
    .min(today, 'Issue Start date cannont be past date'),
  final_maturity_date: Yup.date()
    .required('Issue Closure Date is required')
    .min(tmr, 'Issue Closure Date cannot be past date or today'),

  lockin_till: Yup.date().min(today, 'Lock in Date cannot be past date'),

  application_processing_time: Yup.string().required('Settlement Period is required'),
  // payment_leg_id: Yup.object().required('Payment Leg is required').nullable(),
  payment_frequency_id: Yup.string().required('Payment Frequency is required'),
  // payment_reminder_days: Yup.number()
  //   .positive('Maturity Alert must be positive number')
  //   .required('Maturity Alert Days is required'),
  payment_basis_id: Yup.string().required('Payment Basis is required'),
  interest_type_id: Yup.string().required('Interest Type is required'),
  interest_calc_method_id: Yup.string().required('Interest Calc. Method is required'),
  tenure_months: Yup.number()
    .required('Tenure is required')
    .min(1, 'Tenure has to be atleast 1 year')
    .integer('Tenure must be an integer value'),
  accural_frequncy_id: Yup.string().required('Accrual Frequency is required'),
  token_nav: Yup.number().required('Token NAV is required'),
  payment_token_id: Yup.string().required('Payment Token is required'),
  // collateral_token: Yup.string().when('is_collateralized', {
  //   is: true,
  //   then: Yup.string().required('Select Collateral Token')
  // }),
  collateral_per_token: Yup.number().when('is_collateralized', {
    is: true,
    then: Yup.number()
      .required('Collateral Per Token required')
      .min(1, 'Collateral value must be positive number')
      .max(10000000000000000000, 'Collateral value must be less')
  }),
  collateral_provider: Yup.object()
    .nullable()
    .when('is_collateralized', {
      is: true,
      then: Yup.object().required('Select Collateral Provider Entity')
    }),
  collateral_provider_entity: Yup.object().when('collateral_provider.collateral_provider_name', {
    is: 'Insurer',
    then: Yup.object().required('Select the Insurer Collateral Provider Entity')
  }),
  contract_doc_hash: Yup.mixed()
    .required('Contract Document required')
    .test('type', 'Only .pdf formats are accepted', (value) => {
      return value && value.substring(value.lastIndexOf('.')) === '.pdf';
    }),
  // Token Other Highlights
  // originator_id: Yup.string().required('Originator is required'),
  // kyc_provider_id: Yup.string().required('KYC Provider is required'),
  // custodian_id: Yup.string().required('Custodian is required'),
  // blockchain: Yup.string().required('Blockchain is required'),
  use_of_proceeds: Yup.string().required('Use of Proceeds is required'),
  reference_assets: Yup.string().required('Reference Assets is required'),
  bond_issuer: Yup.string(),
  guarantor: Yup.string(),
  announcement_date: Yup.date(),
  issue_date: Yup.date(),
  maturity_date: Yup.date(),
  years_to_maturity_to_next_call: Yup.number(),
  issue_to_reoffer_price: Yup.number(),
  issue_to_reoffer_yield: Yup.number(),
  coupon_type: Yup.string(),
  annual_coupon_rate: Yup.number(),
  coupon_frequency: Yup.string(),
  seniority: Yup.string(),
  exchange_listed: Yup.string(),
  reference_rate: Yup.number(),
  isin: Yup.string(),
  cusip: Yup.string(),
  bond_currency: Yup.string(),
  total_issue_size: Yup.number(),
  min_investment_quantity_nominal: Yup.string(),
  incremental_quantity_nominal: Yup.string(),
  bond_registration: Yup.string(),
  bond_type: Yup.string(),
  bond_sector: Yup.string(),
  bond_sub_sector: Yup.string(),
  issuer_credit_rating: Yup.string(),
  bond_credit_rating: Yup.string(),
  shariah_compliant: Yup.boolean(),
  sukuk_investing: Yup.string()
});

export default CreateTokenSchema;
