import {
  Container,
  Card,
  Table,
  TableCell,
  TableBody,
  Box,
  Chip,
  TableRow,
  TableHead,
  CardContent,
  IconButton,
  Tab,
  Button
} from '@mui/material';
import { TabContext, TabList, TabPanel, LoadingButton } from '@mui/lab';
import Page from 'components/Page';
import React, { useEffect, useState } from 'react';
import Breadcrumbs from 'components/Breadcrumbs';
import VisibilityIcon from '@mui/icons-material/Visibility';
import KYCBasicDetails from 'components/KYCAMLCTF/KYCBasicDetails';
import Attestations from 'components/KYCAMLCTF/Attestations';
import AMLCTF from 'components/KYCAMLCTF/AMLCTF';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {
  useBusinessEntityState,
  useWeb3,
  useAuthStateShared,
  useHttpApi,
  useAppState
} from 'state';
import CopyAddress from 'components/CopyAddress';
import { ethToXdcAddress, stringToBytes32 } from 'helpers/web3';
import Modal from '@mui/material/Modal';
import KYCSubmittedModal from 'components/KYCSubmittedModal';
import { useSnackbar } from 'notistack';
import SubsDetails from 'components/masterData/Dashboard/SubsDetails';

const KYCSubscriber = () => {
  const [value, setValue] = React.useState('1');
  const { user } = useAuthStateShared();
  const { updateKycVerification, getKycVerification } = useHttpApi();
  const { currentKycProvider } = useBusinessEntityState();
  const { currentToken: token } = useBusinessEntityState();
  const { throwErrorMessage } = useAppState();

  const {
    currentKycInvestor: investor,
    currentKycIssuer: issuer,
    currentKycKycProvider: kycProvider
  } = useBusinessEntityState();
  const { updateKyc } = useWeb3();
  const [kycAddedOpen, setKycAddedOpen] = useState(false);
  const showModal = () => setKycAddedOpen(true);
  const hideModal = () => setKycAddedOpen(false);
  const { enqueueSnackbar } = useSnackbar();
  const [modalOpen, setModalOpen] = React.useState(false);
  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  const initialData = {
    lei_verification: 'UNVERIFIED',
    bank_verification: 'UNVERIFIED',
    citizenship_verification: 'UNVERIFIED',
    address_verification: 'UNVERIFIED',
    accredation_status: 'UNVERIFIED',
    affiliation_status: 'UNVERIFIED',
    exempted_status: 'UNVERIFIED',
    pep_status: 'UNVERIFIED',
    sanction_screening_status: 'UNVERIFIED',
    suspicious_activity_report_status: 'UNVERIFIED',
    cdd_report_status: 'UNVERIFIED',
    fatf_compliance_status: 'UNVERIFIED'
  };

  const initialBasicInfo = {
    lei: 'UNKNOWN',
    bank: 'UNKNOWN',
    citizenship: 'UNKNOWN',
    address: 'UNKNOWN'
  };

  const [verificationDataState, setVerificationDataState] = useState(initialData);
  const [fetchedVerificationData, setFetchedVerificationData] = useState(initialData);
  const [basicInfo, setbasicInfo] = useState(initialBasicInfo);

  const [unsavedChangesExist, setUnsavedChangesExist] = useState(false);
  const [canUpdateOnChain, setCanUpdateOnChain] = useState(false);

  const [saveButtonLoadingState, setSaveButtonLoadingState] = useState(false);

  const getOverallKycStatus = (verificationData) => {
    const states = [];
    let kycStatus = 'PENDING';

    Object.keys(verificationData).forEach((key) => {
      if (key !== 'kyc_status') {
        states.push(verificationData[key]);
      }
    });

    if (!states.includes('VERIFIED') && !states.includes('NA')) {
      kycStatus = 'PENDING';
    } else if (states.includes('UNVERIFIED')) {
      kycStatus = 'PROGRESS';
    } else {
      kycStatus = 'COMPLETE';
    }

    return kycStatus;
  };

  useEffect(() => {
    async function _setbasicInfo() {
      const updatedBasicInfo = {
        ...basicInfo,
        lei: investor.lei,
        citizenship: investor.investor_country_code.country_code,
        address: investor.address_line_1,
        bank: investor.bank_account_name
      };
      setbasicInfo(updatedBasicInfo);
    }
    async function _getKycVerification() {
      const response = await getKycVerification(issuer.issuer_id, investor.investor_id);
      response.kyc_status = getOverallKycStatus(response);
      setFetchedVerificationData(response);
    }
    _getKycVerification();
    _setbasicInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setVerificationDataState(fetchedVerificationData);
  }, [fetchedVerificationData]);

  useEffect(() => {
    setUnsavedChangesExist(
      JSON.stringify(fetchedVerificationData) !== JSON.stringify(verificationDataState)
    );
    setCanUpdateOnChain(
      verificationDataState.kyc_status === 'COMPLETE' ||
        (fetchedVerificationData.kyc_status === 'COMPLETE' &&
          verificationDataState.kyc_status !== 'COMPLETE')
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verificationDataState]);

  const handleRadioButtonSelect = (e) => {
    // update state
    const updatedState = { ...verificationDataState };
    updatedState[e.target.name] = e.target.value;
    updatedState.kyc_status = getOverallKycStatus(updatedState);
    setVerificationDataState(updatedState);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Modal
        open={modalOpen}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <SubsDetails investor={investor} handleClose={closeModal} />
      </Modal>
      <Page title="KYC/AML/CTF">
        <Container sx={{ mt: 3 }}>
          <Breadcrumbs aria-label="breadcrumb" pageHead="KYC / AML / CTF" />
          <Card sx={{ mt: 2, p: 3 }}>
            <Box>
              <Card sx={{ mt: 2, boxShadow: 'none', border: 'solid 2px #eff1f2' }}>
                <CardContent>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>XinFin Address</TableCell>
                        <TableCell>Email Verification</TableCell>
                        <TableCell>Phone Verification</TableCell>
                        <TableCell>Sub Details</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>{investor.investor_name}</TableCell>
                        <TableCell>
                          <CopyAddress addr={ethToXdcAddress(investor.xinfin_account)} />
                        </TableCell>
                        <TableCell>
                          <Chip label="Verfified" color="success" />
                        </TableCell>
                        <TableCell>
                          <Chip label="Verfified" color="success" />
                        </TableCell>
                        <TableCell>
                          <IconButton aria-label="subs detail" onClick={openModal}>
                            <VisibilityIcon
                              sx={{ fontSize: '2.188rem', p: 1, backgroundColor: '#F5F8FA' }}
                            />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </CardContent>
              </Card>
            </Box>
          </Card>
          <Card sx={{ my: 2 }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleChange} aria-label="lab API tabs example" sx={{ p: 2 }}>
                  <Tab
                    label="Basic Details"
                    value="1"
                    sx={{ fontWeight: 600, color: '#161c2d', fontSize: '1.125rem' }}
                  />
                  <Tab
                    label="Attestations"
                    value="2"
                    sx={{ fontWeight: 600, color: '#161c2d', fontSize: '1.125rem' }}
                  />
                  <Tab
                    label="AML/CTF"
                    value="3"
                    sx={{ fontWeight: 600, color: '#161c2d', fontSize: '1.125rem' }}
                  />
                </TabList>
              </Box>
              <TabPanel value="1">
                <KYCBasicDetails
                  verificationDataState={verificationDataState}
                  handleRadioButtonSelect={handleRadioButtonSelect}
                  basicInfo={basicInfo}
                />
              </TabPanel>
              <TabPanel value="2">
                <Attestations
                  verificationDataState={verificationDataState}
                  handleRadioButtonSelect={handleRadioButtonSelect}
                />
              </TabPanel>
              <TabPanel value="3">
                <AMLCTF
                  verificationDataState={verificationDataState}
                  handleRadioButtonSelect={handleRadioButtonSelect}
                />
              </TabPanel>
            </TabContext>
          </Card>
          <Card
            sx={{
              my: 2,
              p: 2,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end'
            }}
          >
            {unsavedChangesExist ? (
              <Chip
                label="UNSAVED CHANGES"
                color="error"
                sx={{
                  alignSelf: 'flex-start',
                  marginRight: 'auto'
                }}
              />
            ) : (
              <></>
            )}
            <LoadingButton
              variant={unsavedChangesExist ? 'gradient' : 'outlined'}
              color="primary"
              sx={{ borderColor: '#24ABDF', width: '15rem' }}
              loadingPosition="start"
              loading={saveButtonLoadingState}
              disabled={!unsavedChangesExist}
              onClick={async () => {
                try {
                  setSaveButtonLoadingState(true);

                  // contract call
                  if (canUpdateOnChain) {
                    await updateKyc(issuer.primary_issuer_xinfin_address, investor.xinfin_account, {
                      countryCode: stringToBytes32(investor.investor_country_code.country_code),
                      kycStatus: verificationDataState.kyc_status === 'COMPLETE',
                      accredation: verificationDataState.accredation_status === 'VERIFIED',
                      affiliation: verificationDataState.affiliation_status === 'VERIFIED',
                      exempted: verificationDataState.exempted_status === 'VERIFIED',
                      kycBasicDetails: {
                        leiCheck: verificationDataState.lei_verification === 'VERIFIED',
                        bankCheck: verificationDataState.bank_verification === 'VERIFIED',
                        citizenshipCheck:
                          verificationDataState.citizenship_verification === 'VERIFIED',
                        addressCheck: verificationDataState.address_verification === 'VERIFIED'
                      },
                      kycAmlCtf: {
                        pepCheck: verificationDataState.pep_status === 'VERIFIED',
                        sanctionScreening:
                          verificationDataState.sanction_screening_status === 'VERIFIED',
                        suspiciousActivityReport:
                          verificationDataState.suspicious_activity_report_status === 'VERIFIED',
                        cddReport: verificationDataState.cdd_report_status === 'VERIFIED',
                        fatfComplianceCheck:
                          verificationDataState.fatf_compliance_status === 'VERIFIED'
                      }
                    });
                  }

                  // backend call
                  await updateKycVerification(
                    issuer.issuer_id,
                    investor.investor_id,
                    verificationDataState
                  );

                  // prompt
                  enqueueSnackbar(
                    `KYC Details Updated ${
                      canUpdateOnChain ? 'and Published On-Chain ' : ''
                    }Succesfully!`,
                    { variant: 'success' }
                  );

                  // all changes have been saved
                  setUnsavedChangesExist(false);
                  setFetchedVerificationData(verificationDataState);

                  // loading state
                  setSaveButtonLoadingState(false);
                } catch (e) {
                  throwErrorMessage(e);
                  setVerificationDataState(fetchedVerificationData);
                }
              }}
            >
              UPDATE
              {!unsavedChangesExist && verificationDataState.kyc_status !== 'PENDING'
                ? 'D'
                : ''}{' '}
              {canUpdateOnChain ? 'ON-CHAIN' : ''}
            </LoadingButton>
          </Card>
        </Container>
      </Page>
    </>
  );
};

export default KYCSubscriber;
