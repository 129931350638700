import { Icon } from '@iconify/react';
import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill';

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    title: 'Main View',
    path: '/app/main',
    icon: getIcon(pieChart2Fill)
  },
  {
    title: 'Another View',
    path: '/app/another',
    icon: getIcon(pieChart2Fill)
  }
];

export default sidebarConfig;
