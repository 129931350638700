import React, { useEffect, useState } from 'react';
import EnhancedTable from 'components/tables/EnhancedTable';
import {
  Box,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  Table,
  TableCell,
  TableRow,
  DialogActions,
  DialogContent
} from '@mui/material';
import { useBusinessEntityState, useWeb3 } from 'state/index';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { ethToXdcAddress } from 'helpers/web3';
import { HeaderName } from 'helpers/tableHelper';
import { useAuthStateShared, useAppState } from 'state';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useSnackbar } from 'notistack';
import CopyAddress from 'components/CopyAddress';
import { LoadingButton } from '@mui/lab';
import DataNotAvailable from 'helpers/DataNotAvailable';
import { parseTokenDecimal } from 'helpers/numbers';
import AddressFieldTools from 'components/AddressFieldTools';

const ActionButtons = (row) => {
  const { currentToken } = useBusinessEntityState();
  const { currentToken: token } = useBusinessEntityState();
  const { paymentTokenGiveAllowance, swapRedeem, web3 } = useWeb3();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = React.useState(false);
  const [loadingState, setLoadingState] = useState(false);
  const { throwErrorMessage } = useAppState();

  const handleClickOpen = () => {
    if (row.row.token_cal_type === 'INTEREST' && row.row.accrued_interest > 0) {
      enqueueSnackbar('Please pay the accrued interest before continuing.', {
        variant: 'warning'
      });
    } else {
      setOpen(true);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          m: 0,
          p: 0,
          alignItems: 'center'
        }}
      >
        {/* <Button variant="contained" color="success" sx={{ mb: 1, mr: 1 }} onClick={() => {}}>
          <Typography sx={{ fontSize: '12px' }}>Notify Investor</Typography>
        </Button> */}
        <Button
          variant="contained"
          color="secondary"
          sx={{ ml: 1 }}
          // disabled={token.token_status_id.token_status === 'BURNT'}
          onClick={async () => {
            try {
              await swapRedeem(row.row.escrow_order_id);
            } catch (e) {
              throwErrorMessage(e);
            }
          }}
        >
          <Typography sx={{ fontSize: '12px' }}>Redeem</Typography>
        </Button>
        {currentToken?.payment_token_id?.contract_address && (
          <Button
            // disabled={token.token_status_id.token_status === 'BURNT'}
            variant="gradient"
            color="success"
            sx={{ ml: 1 }}
            onClick={handleClickOpen}
          >
            <Typography sx={{ fontSize: '12px' }}>Escrow </Typography>
          </Button>
        )}
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Escrow Payment Token</DialogTitle>
        <DialogContent>
          <Table aria-label="simple table">
            <TableRow sx={{ p: 5 }}>
              <TableCell>
                <Typography sx={{ fontWeight: '600' }}>Escrow Requirement</Typography>
              </TableCell>
              <TableCell>
                <Typography>{row.row.payment_token_amount}</Typography>
              </TableCell>
            </TableRow>
            <TableRow sx={{ p: 5 }}>
              <TableCell>
                <Typography sx={{ fontWeight: '600' }}>Redemption Price</Typography>
              </TableCell>
              <TableCell>
                <Typography>{row.row.token_redemption_price}</Typography>
              </TableCell>
            </TableRow>
            <TableRow sx={{ p: 5 }}>
              <TableCell>
                <Typography sx={{ fontWeight: '600' }}>Redeem Quantity</Typography>
              </TableCell>
              <TableCell>
                <Typography>{row.row.trade_token_amount}</Typography>
              </TableCell>
            </TableRow>
            <TableRow sx={{ p: 5 }}>
              <TableCell>
                <Typography sx={{ fontWeight: '600' }}>Payment Token Address</Typography>
              </TableCell>
              <TableCell>
                {currentToken?.payment_token_id?.contract_address ? (
                  <AddressFieldTools
                    address={currentToken.payment_token_id.contract_address}
                    symbol={currentToken.payment_token_id.symbol}
                    decimals={currentToken.payment_token_id.decimals}
                    showAddToWallet
                    showCopyButton
                    showAddress={false}
                  />
                ) : (
                  'NA'
                )}
              </TableCell>
            </TableRow>
          </Table>
        </DialogContent>
        <DialogActions sx={{ mt: 2, mb: 2, mr: 1 }}>
          <Button autoFocus variant="contained" color="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <LoadingButton
            autoFocus
            variant="gradient"
            sx={{ width: '9rem' }}
            loadingPosition="start"
            loading={loadingState}
            onClick={async () => {
              setLoadingState(true);
              try {
                if (currentToken.payment_token_id.contract_address) {
                  const paymentTokenValue = parseTokenDecimal(
                    row.row.payment_token_amount,
                    currentToken.payment_token_id.decimals
                  );
                  await paymentTokenGiveAllowance(
                    currentToken.payment_token_id.contract_address,
                    paymentTokenValue
                  );
                }
              } catch (e) {
                throwErrorMessage(e);
              }
              setLoadingState(false);
              handleClose();
            }}
          >
            Submit
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

const IssuerEscrowStatus = ({ status }) => {
  return <>{status ? <CheckCircleIcon color="success" /> : <CancelIcon color="error" />}</>;
};

const SubcriberEscrowStatus = ({ status }) => {
  return <>{status ? <CheckCircleIcon color="success" /> : <CancelIcon color="error" />}</>;
};

export default function RedemptionEscrowTable() {
  const [data, setData] = useState([]);

  const [isLoaded, setIsLoaded] = useState(false);
  const [skipPageReset, setSkipPageReset] = React.useState(false);
  const [owner, setOwner] = useState(false);
  const { role } = useAuthStateShared();
  const { issuerRedemptionEscrow, fetchAllRedemptionEscrow, currentToken } =
    useBusinessEntityState();
  const { getRedemptionEscrowConditions } = useWeb3();
  useEffect(() => {
    if (role === 'OWNER') {
      setOwner(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _fetchData = async () => {
    await fetchAllRedemptionEscrow();
  };

  useEffect(() => {
    _fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const headCells = [
    {
      accessor: 'investor.investor_name',
      Header: 'Investor Name',
      width: 100,
      show: true
    },
    {
      accessor: 'xinfin_address',
      Header: 'Xinfin Address',
      width: 200,
      show: true
    },
    {
      accessor: 'trade_token_amount',
      Header: 'Alloted Qty',
      width: 100,
      show: true
    },

    {
      accessor: 'subcriber_status',
      Header: 'Investor Escrowed',
      width: 110,
      show: true
    },
    {
      accessor: 'issuer_status',
      Header: 'Issuer Escrowed',
      width: 100,
      show: true
    },

    {
      accessor: 'actions',
      Header: 'Actions',
      width: 350,
      show: currentToken.token_type !== 'PROXY_TOKEN'
    }
  ];

  useEffect(() => {
    // TODO: Fetch Investors
    const _prepareRow = async () => {
      if (issuerRedemptionEscrow.length !== 0) {
        const _rows = await Promise.all(
          issuerRedemptionEscrow.map(async (row) => {
            row.xinfin_address = (
              <CopyAddress addr={ethToXdcAddress(row.investor_xinfin_account)} />
            );
            const condition = await getRedemptionEscrowConditions(row.escrow_order_id);
            row.issuer_status = <IssuerEscrowStatus status={condition[0]} />;
            row.subcriber_status = <SubcriberEscrowStatus status={condition[1]} />;
            row.actions = <ActionButtons row={{ ...row }} />;
            return row;
          })
        );
        setData(_rows);
        setIsLoaded(true);
      }
    };
    _prepareRow();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [issuerRedemptionEscrow]);
  const updateMyData = (rowIndex, columnId, value) => {
    setSkipPageReset(true);
    setData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value
          };
        }
        return row;
      })
    );
  };

  return (
    <>
      <EnhancedTable
        tableTitle="IssuerRedemptionEscrow"
        columns={headCells}
        data={issuerRedemptionEscrow}
        setData={setData}
        // updateMyData={updateMyData}
        // skipPageReset={skipPageReset}
        refreshFunction={_fetchData}
      />
    </>
  );
}
