import React, { useState } from 'react';
import Page from 'components/Page';
import {
  Container,
  Card,
  Grid,
  Box,
  CardContent,
  Typography,
  TextField,
  FormControl,
  MenuItem,
  FormLabel,
  Button,
  Divider,
  Select,
  RadioGroup,
  Radio,
  FormControlLabel,
  Alert,
  FormHelperText
} from '@mui/material';
import Breadcrumbs from 'components/Breadcrumbs';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { useCoreTableState } from 'state/useCoreTableState';

const AddBankAccount = () => {
  const { currencies } = useCoreTableState();
  const [error, setError] = useState('');

  const AddBankAccountSchema = Yup.object().shape({
    bank_name: Yup.string().required('Select Bank Name'),
    branch: Yup.string().required('Select Branch'),
    acct_no: Yup.string().required('Account Number is required'),
    bic: Yup.string().required('BIC is required'),
    iban: Yup.string().required('IBAN is required'),
    address_line_1: Yup.string().required('Address line 1 is required'),
    address_line_2: Yup.string().required('Address line 2 is required'),
    city: Yup.string().required('City is required'),
    state: Yup.string().required('State is required'),
    zip_code: Yup.string().required('Zip code is required'),
    acc_currency: Yup.string().required('Select Account Currency'),
    beneficiary_type: Yup.string().required('Select Beneficiary Type'),
    account_type: Yup.string().required('Select Account Type')
  });

  const formik = useFormik({
    initialValues: {
      bank_name: '',
      branch: '',
      acct_no: '',
      bic: '',
      iban: '',
      address_line_1: '',
      address_line_2: '',
      city: '',
      state: '',
      zip_code: '',
      acc_currency: '',
      beneficiary_type: '',
      account_type: ''
    },
    validationSchema: AddBankAccountSchema,
    onSubmit: (values) => {
      alert(JSON.stringify(values));
    }
  });
  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;
  return (
    <>
      <Page title="Add Bank Account - Master Data">
        <Container sx={{ my: 2 }}>
          <Box sx={{ my: 2 }}>
            <Breadcrumbs aria-label="breadcrumb" pageHead="Bank account" />
          </Box>
          <Card>
            <Container>
              <Typography
                sx={{
                  fontSize: '1.125rem',
                  fontWeight: 'bold',
                  mt: 4,
                  mb: 1,
                  ml: 4
                }}
              >
                Add Bank Account
              </Typography>
              <Divider
                sx={{
                  width: '100%',
                  backgroundColor: '#f5f8fa',
                  border: '2px solid #f5f8fa'
                }}
              />
              <FormikProvider value={formik}>
                <Form autoComplete="off" onSubmit={handleSubmit}>
                  {error && (
                    <Box mb={4}>
                      <Alert
                        severity="error"
                        sx={{
                          textTransform: 'capitalize'
                        }}
                      >
                        {error}
                      </Alert>
                    </Box>
                  )}
                  <CardContent sx={{ m: 2 }}>
                    <Grid container spacing={3} sx={{ width: '100%' }}>
                      {/* <Grid item lg={6} md={6} xs={12}>
                        <FormControl fullWidth size="small">
                          <FormLabel>Bank Name</FormLabel>
                          <FormControl size="small" variant="outlined" fullWidth sx={{ mt: 1.5 }}>
                            <Select
                              // placeholder="Select Bank"
                              displayEmpty
                              inputProps={{ 'aria-label': 'Without label' }}
                              {...getFieldProps('bank_name')}
                              error={Boolean(touched.bank_name && errors.bank_name)}
                              helperText={touched.bank_name && errors.bank_name}
                            >
                              {/*  Replace with Bankname api */}
                      {/* {bankAccounts &&
                                bankAccounts.map((bankAccounts, index) => {
                                  return (
                                    <MenuItem key={bankAccounts.id} value={bankAccounts.bank_name}>
                                      {bankAccounts.bank_name}
                                    </MenuItem>
                                  );
                                })}
                            </Select>
                            <FormHelperText sx={{ color: '#d32f2f' }}>
                              {touched.bank_name && errors.bank_name}
                            </FormHelperText>
                          </FormControl>
                        </FormControl>
                      </Grid> */}
                      {/* <Grid item lg={6} md={6} xs={12}>
                        <FormControl fullWidth size="small">
                          <FormLabel>Branch Name</FormLabel>
                          <FormControl size="small" variant="outlined" fullWidth sx={{ mt: 1.5 }}>
                            <Select
                              // placeholder="Select Bank"
                              displayEmpty
                              inputProps={{ 'aria-label': 'Without label' }}
                              {...getFieldProps('branch')}
                              error={Boolean(touched.branch && errors.branch)}
                              helperText={touched.branch && errors.branch}
                            >
                              {/*  Replace with Branch name api */}
                      {/* {bankAccounts &&
                                bankAccounts.map((bankAccounts, index) => {
                                  return (
                                    <MenuItem key={bankAccounts.id} value={bankAccounts.branch}>
                                      {bankAccounts.branch}
                                    </MenuItem>
                                  );
                                })}
                            </Select>
                            <FormHelperText sx={{ color: '#d32f2f' }}>
                              {touched.branch && errors.branch}
                            </FormHelperText>
                          </FormControl>
                        </FormControl>
                      </Grid> */}
                      <Grid item lg={6} md={6} xs={12}>
                        <FormLabel>Account No</FormLabel>
                        <TextField
                          sx={{ mt: 1.5 }}
                          fullWidth
                          size="small"
                          autoComplete=""
                          type="text"
                          // placeholder="Enter Your Bank Account No"
                          {...getFieldProps('acct_no')}
                          error={Boolean(touched.acct_no && errors.acct_no)}
                          helperText={touched.acct_no && errors.acct_no}
                        />
                      </Grid>
                      <Grid item lg={6} md={6} xs={12}>
                        <FormLabel>BIC Code</FormLabel>
                        <TextField
                          sx={{ mt: 1.5 }}
                          fullWidth
                          size="small"
                          autoComplete=""
                          type="text"
                          // placeholder="Bank Identification Code"
                          {...getFieldProps('bic')}
                          error={Boolean(touched.bic && errors.bic)}
                          helperText={touched.bic && errors.bic}
                        />
                      </Grid>
                      <Grid item lg={6} md={6} xs={12}>
                        <FormLabel>IBAN Code</FormLabel>
                        <TextField
                          sx={{ mt: 1.5 }}
                          fullWidth
                          size="small"
                          autoComplete=""
                          type="text"
                          // placeholder="International Bank Account Number"
                          {...getFieldProps('iban')}
                          error={Boolean(touched.iban && errors.iban)}
                          helperText={touched.iban && errors.iban}
                        />
                      </Grid>
                      <Grid item lg={12}>
                        <Divider />
                      </Grid>
                      <Grid item lg={6} md={6} xs={12}>
                        <FormLabel>Address Line 01</FormLabel>
                        <TextField
                          sx={{ mt: 1.5 }}
                          fullWidth
                          size="small"
                          autoComplete=""
                          type="text"
                          // placeholder="Home / Street"
                          {...getFieldProps('address_line_1')}
                          error={Boolean(touched.address_line_1 && errors.address_line_1)}
                          helperText={touched.address_line_1 && errors.address_line_1}
                        />
                      </Grid>
                      <Grid item lg={6} md={6} xs={12}>
                        <FormLabel>Address Line 02</FormLabel>
                        <TextField
                          sx={{ mt: 1.5 }}
                          fullWidth
                          size="small"
                          autoComplete=""
                          type="text"
                          // placeholder="Home / Street"
                          {...getFieldProps('address_line_2')}
                          error={Boolean(touched.address_line_2 && errors.address_line_2)}
                          helperText={touched.address_line_2 && errors.address_line_2}
                        />
                      </Grid>
                      <Grid item lg={6} md={6} xs={12}>
                        <FormLabel>City</FormLabel>
                        <TextField
                          sx={{ mt: 1.5 }}
                          fullWidth
                          size="small"
                          autoComplete=""
                          type="text"
                          // placeholder="Enter name of city"
                          {...getFieldProps('city')}
                          error={Boolean(touched.city && errors.city)}
                          helperText={touched.city && errors.city}
                        />
                      </Grid>
                      <Grid item lg={6} md={6} xs={12}>
                        <FormLabel>State / Province / Region</FormLabel>
                        <TextField
                          sx={{ mt: 1.5 }}
                          fullWidth
                          size="small"
                          autoComplete=""
                          type="text"
                          // placeholder="State"
                          {...getFieldProps('state')}
                          error={Boolean(touched.state && errors.state)}
                          helperText={touched.state && errors.state}
                        />
                      </Grid>
                      <Grid item lg={6} md={6} xs={12}>
                        <FormLabel>ZipCode</FormLabel>
                        <TextField
                          sx={{ mt: 1.5 }}
                          fullWidth
                          size="small"
                          autoComplete=""
                          type="text"
                          // placeholder="Enter ZipCode"
                          {...getFieldProps('zip_code')}
                          error={Boolean(touched.zip_code && errors.zip_code)}
                          helperText={touched.zip_code && errors.zip_code}
                        />
                      </Grid>
                      <Grid item lg={12}>
                        <Divider />
                      </Grid>
                      <Grid item lg={6} md={6} xs={12}>
                        <FormControl fullWidth size="small">
                          <FormLabel>Account Currency</FormLabel>
                          <FormControl size="small" variant="outlined" fullWidth sx={{ mt: 1.5 }}>
                            <Select
                              // placeholder="Select Currency"
                              displayEmpty
                              inputProps={{ 'aria-label': 'Without label' }}
                              {...getFieldProps('acc_currency')}
                              error={Boolean(touched.acc_currency && errors.acc_currency)}
                              helperText={touched.acc_currency && errors.acc_currency}
                            >
                              {currencies &&
                                currencies.map((currency, index) => {
                                  return (
                                    <MenuItem
                                      key={currency.currency_code}
                                      value={currency.currency_code}
                                    >
                                      {currency.currency_code} | {currency.currency_name}
                                    </MenuItem>
                                  );
                                })}
                            </Select>
                            <FormHelperText sx={{ color: '#d32f2f' }}>
                              {touched.acc_currency && errors.acc_currency}
                            </FormHelperText>
                          </FormControl>
                        </FormControl>
                      </Grid>
                      {/* <Grid item lg={6} md={6} xs={12}>
                        <FormControl fullWidth size="small">
                          <FormLabel>Beneficiary Type</FormLabel>
                          <FormControl size="small" variant="outlined" fullWidth sx={{ mt: 1.5 }}>
                            <Select
                              labelId="demo-simple-select-helper-label"
                              id="demo-simple-select-helper"
                              displayEmpty
                              inputProps={{ 'aria-label': 'Without label' }}
                              {...getFieldProps('beneficiary_type')}
                              error={Boolean(touched.beneficiary_type && errors.beneficiary_type)}
                              helperText={touched.beneficiary_type && errors.beneficiary_type}
                            >
                              {beneficieryTypes &&
                                beneficieryTypes.map((beneficieryTypes, index) => {
                                  return (
                                    <MenuItem
                                      key={beneficieryTypes.id}
                                      value={beneficieryTypes.beneficiery_type}
                                    >
                                      {beneficieryTypes.beneficiery_type}
                                    </MenuItem>
                                  );
                                })}
                            </Select>
                            <FormHelperText sx={{ color: '#d32f2f' }}>
                              {touched.beneficiary_type && errors.beneficiary_type}
                            </FormHelperText>
                          </FormControl>
                        </FormControl>
                      </Grid> */}
                      <Grid item lg={6} md={6} xs={12}>
                        <FormLabel>Account type</FormLabel>
                        <Grid item lg={6} sm={6} md={6} xs={6}>
                          <RadioGroup
                            row
                            aria-label="account_type"
                            name="row-radio-buttons-group"
                            {...getFieldProps('account_type')}
                            error={Boolean(touched.account_type && errors.account_type)}
                          >
                            <FormControlLabel value="JOINT" control={<Radio />} label="JOINT" />
                            <FormControlLabel
                              value="INDIVIDUAL"
                              control={<Radio />}
                              label="INDIVIDUAL"
                            />
                            <FormHelperText sx={{ color: '#d32f2f' }}>
                              {touched.account_type && errors.account_type}
                            </FormHelperText>
                          </RadioGroup>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Box
                      sx={{
                        mt: 2,
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end'
                      }}
                    >
                      <Button
                        sx={{
                          margin: 1,
                          borderColor: '#24ABDF',
                          px: 3,
                          width: '7.75rem',
                          height: '2.5rem'
                        }}
                        variant="contained"
                        color="secondary"
                      >
                        Cancel
                      </Button>
                      <LoadingButton
                        variant="gradient"
                        sx={{ margin: 1, px: 3, width: '7.75rem', height: '2.5rem' }}
                        onClick={handleSubmit}
                      >
                        Save
                      </LoadingButton>
                    </Box>
                  </CardContent>
                </Form>
              </FormikProvider>
            </Container>
          </Card>
        </Container>
      </Page>
    </>
  );
};

export default AddBankAccount;
