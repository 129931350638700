import * as React from 'react';
import { styled } from '@mui/material/styles';
import Page from './Page';
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Grid,
  Paper,
  Divider,
  Typography,
  Box,
  Button
} from '@mui/material';
import Breadcrumbs from './Breadcrumbs';
import { useBusinessEntityState } from 'state';
import TokenSummaryModal from './TokenSummaryModal';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

const BondInformation = () => {
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    fontSize: 14
    // Add borderRadius to TableCell
  }));

  const [openTokenSummaryModal, setOpenTokenSummaryModal] = React.useState(false);
  const handleOpenTokenSummaryModal = () => setOpenTokenSummaryModal(true);
  const handleCloseTokenSummaryModal = () => setOpenTokenSummaryModal(false);

  const { currentToken: token, fetchTokenSummaryData } = useBusinessEntityState();

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: '#F2F5FC'
    },
    '&:last-child td, &:last-child th': {
      border: 'none',
      borderBottom: 'none'
    },
    borderRadius: '10px' // Add border radius to the last row
  }));

  function createData(name, value) {
    return { name, value };
  }

  console.log(token);

  const status = {
    true: 'Yes',
    false: 'No'
  };

  const rows = [
    createData('Investment Vehicle Issuer', token?.bond?.bond_issuer),
    createData('Guarantor', token?.bond?.guarantor),
    createData('Announcement Date', token?.bond?.announcement_date),
    createData('Issue Date', token?.bond?.issue_date),
    createData('Maturity Date', token?.bond?.maturity_date),
    createData('Years to Maturity / Next Call', token?.bond?.years_to_maturity_to_next_call),
    createData('Issue / Reoffer Price', token?.bond?.issue_to_reoffer_price),
    createData('Issue / Reoffer yield', token?.bond?.issue_to_reoffer_yield),
    createData('Coupon Type', token?.bond?.coupon_type),
    createData('Annual Coupon Rate', token?.bond?.annual_coupon_rate),
    createData('Coupon Frequency', token?.bond?.coupon_frequency),
    createData('Security', token?.bond?.seniority),
    createData('Exchange Listed', token?.bond?.exchange_listed),
    createData('Reference Rate (%)', token?.bond?.reference_rate),
    createData('ISIN', token?.bond?.isin),
    createData('CUSIP', token?.bond?.cusip),
    createData('Investment Currency', token?.bond?.bond_currency),
    createData('Total Issue Size', token?.bond?.total_issue_Size),
    createData('Min. Investment Quantity (Nominal)', token?.bond?.min_investment_quantity_nominal),
    createData('Incremental Quantity (Nominal)', token?.bond?.incremental_quantity_nominal),
    createData('Investment Registration', token?.bond?.bond_registration),
    createData('Investment Type', token?.bond?.bond_type),
    createData('Investment Sector', token?.bond?.bond_sector),
    createData('Investment Sub Sector', token?.bond?.bond_sub_sector),
    createData('Issuer Credit Rating (S&P/ Fitch)', token?.bond?.issuer_credit_rating),
    createData('Bond Credit Rating (S&P/ Fitch)', token?.bond?.bond_credit_rating)
    // createData('Shariah Compliant', status[token?.bond?.shariah_complaint] ?? ''),
    // createData('Sukuk Investing', token?.bond?.sukuk_investing)
  ];

  return (
    <Page title="Safi Information">
      <Container sx={{ mt: 4 }}>
        <Breadcrumbs pageHead="Safi Information" />
        <Box
          sx={{
            mt: 2,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column'
          }}
        >
          <Typography component="th" variant="h2" textAlign="center">
            Safi Information
          </Typography>
          <Typography component="th" variant="body2" textAlign="start">
            Tailored Digital Assets develop real estate projects globally by acquiring large land
            parcels and working in collaboration with renowned real estate developers. The projects
            are financed by tokenising the Safi issued by recognised banks in the project country.
            The tokenised Safi are issued for investment by accredited US investors. These tokens
            provide safe and lucrative investment options for the US investors with an option to
            diversify and invest in the assets that provide stable yield and are un-corelated to US
            Bond & equity market assets.
          </Typography>
        </Box>
        <Box
          sx={{
            mt: 2,
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center'
          }}
        >
          <Button
            variant="outlined"
            onClick={() => {
              window.open(token.contract_doc_hash, '_blank');
            }}
            sx={{
              mr: 2
            }}
          >
            <Grid
              item
              lg={12}
              md={6}
              xs={12}
              sx={{ pr: 1, display: 'flex', placeSelf: 'center center' }}
            >
              <Typography>Token Terms Document</Typography>
              <OpenInNewIcon sx={{ ml: 1 }} />
            </Grid>
          </Button>
          <Button
            variant="contained"
            onClick={async () => {
              await fetchTokenSummaryData();
              handleOpenTokenSummaryModal();
            }}
          >
            View Token Holder Ship
          </Button>
        </Box>
        <Grid
          container
          spacing={2}
          display="flex"
          justifyContent="center"
          alignItems="center"
          marginTop={2}
        >
          <Grid item lg={5} md={5} sm={5} xs={5}>
            <TableContainer component={Paper}>
              <Table aria-label="left-table">
                <TableBody>
                  {rows.slice(0, Math.ceil(rows.length / 2)).map((row) => (
                    <StyledTableRow key={row.name}>
                      <StyledTableCell component="th" scope="row">
                        <Typography component="tbody" variant="body2">
                          {row.name}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        <Typography component="text" variant="subtitle2">
                          {row.value}
                        </Typography>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Divider
            orientation="vertical"
            sx={{ borderColor: 'rgba(0, 0, 0, 0.30)', ml: 2 }}
            flexItem
            variant="fullWidth"
          />
          <Grid item lg={5} md={5} sm={5} xs={5}>
            <TableContainer component={Paper}>
              <Table aria-label="right-table">
                <TableBody>
                  {rows.slice(Math.ceil(rows.length / 2)).map((row) => (
                    <StyledTableRow key={row.name}>
                      <StyledTableCell component="th" scope="row">
                        <Typography component="tbody" variant="body2">
                          {row.name}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        <Typography component="text" variant="subtitle2">
                          {row.value}
                        </Typography>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <TokenSummaryModal
            open={openTokenSummaryModal}
            handleClose={handleCloseTokenSummaryModal}
          />
        </Grid>
      </Container>
    </Page>
  );
};

export default BondInformation;
