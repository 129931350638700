import React, { useEffect, useState } from 'react';
import EnhancedTable from 'components/tables/EnhancedTable';
import { useBusinessEntityState } from 'state/useBusinessEntityState';

export default function InsurerAllRedemptions({ fetchData: _fetchData }) {
  const {
    insurerIssuerAllRedemptions,
    fetchAllRedemptionsByInsurerIssuerId,
    currCreditIssuer,
    currCreditInsurer
  } = useBusinessEntityState();

  // const _fetchData = async () => {
  //   await fetchAllRedemptionsByInsurerIssuerId(
  //     currCreditIssuer.issuer_id,
  //     currCreditInsurer.insurer_id
  //   );
  // };

  // useEffect(() => {
  //   _fetchData();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const headCells = [
    {
      accessor: 'investor_name',
      Header: 'Investor',
      width: 110,
      show: true
    },
    {
      accessor: 'token_symbol',
      Header: 'Token Symbol',
      width: 110,
      show: true
    },
    {
      accessor: 'redeemed_qty',
      Header: 'Quantity',
      width: 110,
      show: true
    },
    {
      accessor: 'allotment_date',
      Header: 'Allotment Date',
      width: 110,
      show: true
    },
    {
      accessor: 'redemption_date',
      Header: 'Redemption Date',
      width: 110,
      show: true
    }
  ];

  return (
    <>
      <EnhancedTable
        tableTitle="InsurerAllRedemptionsTable"
        columns={headCells}
        data={insurerIssuerAllRedemptions}
        refreshFunction={_fetchData}
      />
    </>
  );
}
