import React, { useEffect } from 'react';
import Page from 'components/Page';
import { Container, Typography } from '@mui/material';
import AccordionLayout from 'helpers/AccordionLayout';
import InsurerIssuanceEscrowTable from 'components/masterData/CreditInsurer/InsurerIssuanceEscrowTable';
import InsurerRedemptionsTable from 'components/masterData/CreditInsurer/InsurerRedemptionsTable';
import InsurerCollateral from 'components/masterData/CreditInsurer/InsurerCollateral';
import InsurerTokenTable from 'components/masterData/CreditInsurer/InsurerTokenTable';
import FeesTxnTable from 'components/masterData/CreditInsurer/FeesTxnTable';
import { useBusinessEntityState, useAuthStateShared } from 'state';
import InsurerAllotments from 'components/masterData/CreditInsurer/InsurerAllotments';
import InsurerAllRedemptions from 'components/masterData/CreditInsurer/InsurerAllRedemptions';

const CreditInsurerDashboard = () => {
  const {
    currCreditIssuer,
    currCreditInsurer,
    fetchInsurerIssuerTokens,
    fetchIssuanceEscrowOrderByInsurerIssuerId,
    fetchRedemptionEscrowOrderByInsurerIssuerId,
    fetchAllotmentsByInsurerIssuerId,
    fetchAllRedemptionsByInsurerIssuerId,
    fetchCryptoAssetTxnByIssuerInsurerXinfin
  } = useBusinessEntityState();

  const _fetchInsurerIssuerTokens = () =>
    fetchInsurerIssuerTokens(currCreditIssuer.issuer_id, currCreditInsurer.insurer_id);

  const _fetchIssuanceEscrowOrderByInsurerIssuerId = () =>
    fetchIssuanceEscrowOrderByInsurerIssuerId(
      currCreditIssuer.issuer_id,
      currCreditInsurer.insurer_id
    );

  const _fetchRedemptionEscrowOrderByInsurerIssuerId = () =>
    fetchRedemptionEscrowOrderByInsurerIssuerId(
      currCreditIssuer.issuer_id,
      currCreditInsurer.insurer_id
    );

  const _fetchAllotmentsByInsurerIssuerId = () =>
    fetchAllotmentsByInsurerIssuerId(currCreditIssuer.issuer_id, currCreditInsurer.insurer_id);

  const _fetchAllRedemptionsByInsurerIssuerId = () =>
    fetchAllRedemptionsByInsurerIssuerId(currCreditIssuer.issuer_id, currCreditInsurer.insurer_id);

  const _fetchCryptoAssetTxnByIssuerInsurerXinfin = () =>
    fetchCryptoAssetTxnByIssuerInsurerXinfin();

  useEffect(() => {
    _fetchInsurerIssuerTokens();
    _fetchIssuanceEscrowOrderByInsurerIssuerId();
    _fetchRedemptionEscrowOrderByInsurerIssuerId();
    _fetchAllotmentsByInsurerIssuerId();
    _fetchAllRedemptionsByInsurerIssuerId();
    _fetchCryptoAssetTxnByIssuerInsurerXinfin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Page title="Credit Insurer Dashboard">
        <Container>
          <Typography
            sx={{
              fontSize: '1.125rem',
              fontWeight: 'bold',
              mt: '1rem'
            }}
          >
            Credit Insurer Dashboard
          </Typography>
          <AccordionLayout
            title="Issuance Escrow"
            content={
              <InsurerIssuanceEscrowTable fetchData={_fetchIssuanceEscrowOrderByInsurerIssuerId} />
            }
          />
          <AccordionLayout
            title="Collateral"
            content={
              <InsurerCollateral fetchIssuance={_fetchIssuanceEscrowOrderByInsurerIssuerId} />
            }
          />
          <AccordionLayout
            title="Redemption Escrow"
            content={
              <InsurerRedemptionsTable fetchData={_fetchRedemptionEscrowOrderByInsurerIssuerId} />
            }
          />
          <AccordionLayout
            title="View Allotments"
            content={<InsurerAllotments fetchData={_fetchAllotmentsByInsurerIssuerId} />}
          />
          <AccordionLayout
            title="View Redemptions"
            content={<InsurerAllRedemptions fetchData={_fetchAllRedemptionsByInsurerIssuerId} />}
          />
          <AccordionLayout
            title="Token Dashboard"
            content={<InsurerTokenTable fetchData={_fetchInsurerIssuerTokens} />}
          />
          <AccordionLayout
            title="Fees Transactions"
            content={<FeesTxnTable fetchData={_fetchCryptoAssetTxnByIssuerInsurerXinfin} />}
          />
        </Container>
      </Page>
    </>
  );
};

export default CreditInsurerDashboard;
