import { Box } from '@mui/material';

export const Ellipsis = (name, elipWidth = '6.75rem') => {
  return (
    <Box
      sx={{
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        width: elipWidth
        /* '&:hover': {
            overflow: 'visible',
            cursor: 'pointer'
          } */
      }}
    >
      {name}
    </Box>
  );
};
