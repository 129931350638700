/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import Page from 'components/Page';

import {
  Container,
  Card,
  Grid,
  Box,
  CardContent,
  Typography,
  TextField,
  FormLabel,
  Button,
  Divider,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  Alert
} from '@mui/material';
import Breadcrumbs from 'components/Breadcrumbs';
import EditIssuerAccodian from 'components/masterData/Issuer/EditIssuerAccodian';
import {
  useHttpApi,
  useCoreTableState,
  useWeb3,
  useBusinessEntityState,
  useAuthStateShared,
  useAppState
} from 'state';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import NotifierSnackbar from 'components/Notifier/NotifierSnackbar';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { leiRegex, xdcRegex } from 'helpers/regex';
import { xdcToEthAddress, toChecksumAddress } from 'helpers/web3';
import { useNavigate } from 'react-router';
import { LeiInfoButton } from 'helpers/infoButton';
import CommonOverlay from 'components/CommonOverlay';
import { onlyOwner } from 'helpers/rbac';

const AddOriginator = () => {
  const { createOriginator } = useHttpApi();
  const { fetchOriginator } = useBusinessEntityState();
  const { role } = useAuthStateShared();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { throwErrorMessage } = useAppState();

  const [error, setError] = useState(null);

  const AddOriginatorSchema = Yup.object().shape({
    originator_name: Yup.string().required('Originator Name is required'),
    originator_country_code: Yup.string().required('Country Code is required')
  });

  // Formik
  const formik = useFormik({
    initialValues: {
      originator_name: '',
      originator_country_code: ''
    },
    validationSchema: AddOriginatorSchema,
    onSubmit: async (data, { resetForm }) => {
      console.log('AddOriginator', data);
      try {
        setError(null);
        const _originator = await createOriginator(data);
        if (_originator.originator_id) {
          enqueueSnackbar('Originator Created Successfully', {
            variant: 'success'
          });
          fetchOriginator();
          resetForm();
          navigate('/masterdata/originator');
        }
      } catch (e) {
        const errorMessage = await throwErrorMessage(e);
        setError(errorMessage);
      }
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const { countries } = useCoreTableState();

  const allowedRolesList = ['OWNER'];

  return (
    <Page title="Add Originator - Master Data">
      <CommonOverlay
        allowedRoles={allowedRolesList}
        overlayTitle="You don't have access to Add Primary Originator Account"
      />
      <Container sx={{ my: 2 }}>
        <Box sx={{ my: 2 }}>
          <Breadcrumbs aria-label="breadcrumb" pageHead="Add Originator" />
        </Box>
        {error && (
          <Box mb={4}>
            <Alert severity="error">{error}</Alert>
          </Box>
        )}
        <Card>
          <Container>
            <Typography
              sx={{
                fontSize: '1.125rem',
                fontWeight: 'bold',
                mt: 4,
                mb: 1,
                ml: 4
              }}
            >
              Originator Details
            </Typography>
            <Divider
              sx={{
                width: '100%',
                backgroundColor: '#f5f8fa',
                border: '2px solid #f5f8fa'
              }}
            />
            <FormikProvider value={formik}>
              <Form autocomplete="off" onSubmit={handleSubmit}>
                <CardContent sx={{ m: 2 }}>
                  <Grid container spacing={3} sx={{ width: '100%' }}>
                    <Grid item lg={6} md={6} xs={12} sm={12}>
                      <FormLabel>Originator name</FormLabel>
                      <TextField
                        sx={{ mt: 1.5 }}
                        fullWidth
                        size="small"
                        autoComplete="off"
                        type="text"
                        // placeholder="Enter Originator Name"
                        {...getFieldProps('originator_name')}
                        error={Boolean(touched.originator_name && errors.originator_name)}
                        helperText={touched.originator_name && errors.originator_name}
                      />
                    </Grid>

                    <Grid item lg={6} md={6} xs={12} sm={12}>
                      <FormLabel>Originator Country Code</FormLabel>

                      <FormControl size="small" variant="outlined" fullWidth sx={{ mt: 1.5 }}>
                        <Select
                          // placeholder="Select Country"
                          inputProps={{ 'aria-label': 'Without label' }}
                          {...getFieldProps('originator_country_code')}
                          error={Boolean(
                            touched.originator_country_code && errors.originator_country_code
                          )}
                          helperText={
                            touched.originator_country_code && errors.originator_country_code
                          }
                        >
                          {countries &&
                            countries.map((country, index) => {
                              return (
                                <MenuItem key={country.id} value={country.id}>
                                  {country.country_name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                        <FormHelperText sx={{ color: '#d32f2f' }}>
                          {touched.originator_country_code && errors.originator_country_code}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Box
                    sx={{
                      mt: 4,
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-end'
                    }}
                  >
                    <Button
                      sx={{
                        margin: 1,
                        borderColor: '#24ABDF',
                        px: 3,
                        width: '7.75rem',
                        height: '2.5rem'
                      }}
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        navigate('/masterdata/originator');
                      }}
                    >
                      Cancel
                    </Button>
                    <LoadingButton
                      type="submit"
                      disabled={!onlyOwner(role)}
                      variant="gradient"
                      fullWidth
                      size="large"
                      loadingPosition="start"
                      loading={isSubmitting}
                      sx={{ margin: 1, px: 3, width: '7.75rem', height: '2.5rem' }}
                    >
                      Save
                    </LoadingButton>
                  </Box>
                </CardContent>
              </Form>
            </FormikProvider>
          </Container>
        </Card>
      </Container>
    </Page>
  );
};

export default AddOriginator;
