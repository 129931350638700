import {
  AppBar,
  Container,
  IconButton,
  Toolbar,
  Typography,
  Button,
  Menu,
  MenuItem,
  Box,
  Divider,
  Popover,
  Badge
} from '@mui/material';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import React from 'react';
import dashboard from '../assets/images/dashboard.svg';
import ownerDashboard from '../assets/images/ownerDashboard.svg';
import masterdata from '../assets/images/masterdata.svg';
import Logo from './Logo';
import AccountPopover from '../layouts/main/AccountPopover';
import { useNavigate } from 'react-router';
import Notification from './masterData/Notification';
import { useAppState, useAuthStateShared } from 'state';

import WalletNavbar from 'components/WalletNavbar';
import Overlay from 'components/Overlay';
import RequireNetwork from 'components/RequireNetwork';
import MasterDataButton from './MasterDataButton';
import { useIsPrimaryIssuer } from 'helpers/rbac';

const IssuerNavbar = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { notifications, fetchUserNotifications } = useAppState();
  const { user } = useAuthStateShared();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const { role } = useAuthStateShared();
  const isPrimaryIssuer = useIsPrimaryIssuer();

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return (
    <AppBar
      position="sticky"
      sx={{
        backgroundColor: '#fff',
        height: user?.is_password_changed ? '8.125rem' : '4.9rem'
      }}
    >
      <Toolbar>
        <Box
          sx={{
            m: 2
          }}
        >
          <Logo sx={{ height: '2.9rem' }} />
          {/* <Box component="img" src="https://www.tradeteq.com/assets/images/tt-horizontal.svg" /> */}
        </Box>
        <Box sx={{ flexGrow: 1 }} />
        <WalletNavbar />
        <Box sx={{ flexGrow: 1 }} />
        {user?.is_password_changed && (
          <IconButton sx={{ p: 2, mx: 1 }} onClick={handleClick}>
            <Badge badgeContent={notifications.length} color="primary">
              <NotificationsActiveIcon />
            </Badge>
          </IconButton>
        )}

        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
        >
          <Notification />
        </Popover>
        {/* <IconButton sx={{ p: 2, mx: 1 }} size="large" edge="start">
          <AccountCircleIcon sx={{ fontSize: '2.5rem' }} />
        </IconButton> */}
        <AccountPopover />
      </Toolbar>
      <Divider />
      {user?.is_password_changed && (
        <Box sx={{ position: 'relative', flex: '1' }}>
          <RequireNetwork not>
            <Overlay show />
          </RequireNetwork>
          <Container sx={{ p: '0 !important' }}>
            <Toolbar>
              <Button
                onClick={() => navigate('/home')}
                sx={{
                  color: '#161c2d'
                }}
              >
                <Box sx={{ mr: 2 }} component="img" src={dashboard} alt="dashboard  icon" />
                Dashboard
              </Button>
              {role !== 'LIQUIDITY_POOL_OWNER' && (
                <PopupState variant="popover" popupId="demo-popup-menu">
                  {(popupState) => (
                    <>
                      <Button sx={{ color: '#161c2d' }} variant="text" {...bindTrigger(popupState)}>
                        <Box
                          component="img"
                          src={masterdata}
                          sx={{ mx: 2 }}
                          alt="dashboard  icon"
                        />{' '}
                        Master Data
                      </Button>
                      <Menu {...bindMenu(popupState)}>
                        <Typography
                          variant="body2"
                          sx={{ color: '#8e8ea7', mx: 2, fontWeight: '500' }}
                        >
                          Business Entities
                        </Typography>
                        <Divider />

                        {role === 'OWNER' && (
                          <MenuItem
                            onClick={() => {
                              popupState.close();
                              navigate('/masterdata/issuer');
                            }}
                          >
                            Issuer
                          </MenuItem>
                        )}

                        <MenuItem
                          onClick={() => {
                            popupState.close();
                            navigate('/masterdata/kyc-provider');
                          }}
                        >
                          KYC Provider
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            popupState.close();
                            navigate('/masterdata/fund-manager');
                          }}
                        >
                          Fund Manager
                        </MenuItem>
                        {role === 'OWNER' && (
                          <MenuItem
                            onClick={() => {
                              popupState.close();
                              navigate('/masterdata/investor');
                            }}
                          >
                            Investors
                          </MenuItem>
                        )}

                        {role !== 'OWNER' && (
                          <>
                            <Divider />

                            <Typography
                              variant="body2"
                              sx={{ color: '#8e8ea7', mx: 2, fontWeight: '500' }}
                            >
                              Account Set Up
                            </Typography>

                            <Divider />
                            <MenuItem
                              onClick={() => {
                                popupState.close();
                                navigate('/masterdata/investor');
                              }}
                            >
                              Investors
                            </MenuItem>

                            {/* {isPrimaryIssuer && (
                              <MenuItem
                                onClick={() => {
                                  popupState.close();
                                  navigate('/masterdata/issuer-insurer-map');
                                }}
                              >
                                Insurer A/C
                              </MenuItem>
                            )}
                            {/* <MenuItem
                            onClick={() => {
                              popupState.close();
                              navigate('/masterdata/bank-account');
                            }}
                          >
                            Bank A/C
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              popupState.close();
                              navigate('/masterdata/cash-txn');
                            }}
                          >
                            Cash Txn
                          </MenuItem> */}
                            {/* <MenuItem
                              onClick={() => {
                                popupState.close();
                                navigate('/masterdata/crypto-asset-txn');
                              }}
                            >
                              Crypto Asset Txn
                            </MenuItem> */}
                            {/* <MenuItem
                              onClick={() => {
                                popupState.close();
                                navigate('/masterdata/xinfin');
                              }}
                            >
                              Xinfin A/C
                            </MenuItem> */}
                            {/* <MenuItem
                              onClick={() => {
                                popupState.close();
                                navigate('/masterdata/user');
                              }}
                            >
                              User
                            </MenuItem> */}
                            {/* </> */}
                            {/* )}  */}

                            {/* {role === 'PRIMARY_ISSUER' && (
                          <MenuItem
                            onClick={() => {
                              popupState.close();
                              navigate('/masterdata/collateral-txn');
                            }}
                          >
                            Collateral Txn
                          </MenuItem>
                        )} */}
                            {/* {role === 'INSURER' && (
                          <MenuItem
                            onClick={() => {
                              popupState.close();
                              navigate('/masterdata/collateral-txn');
                            }}
                          >
                            Collateral Txn
                          </MenuItem>
                        )} */}
                          </>
                        )}
                      </Menu>
                    </>
                  )}
                </PopupState>
              )}

              {role === 'OWNER' && (
                <PopupState variant="popover" popupId="demo-popup-menu">
                  {(popupState) => (
                    <>
                      {/* <Button sx={{ color: '#161c2d' }} variant="text" {...bindTrigger(popupState)}>
                        <Box
                          component="img"
                          src={masterdata}
                          sx={{ mx: 2 }}
                          alt="dashboard  icon"
                        />{' '}
                        Product Set-Up
                      </Button> */}
                      <Menu {...bindMenu(popupState)}>
                        {/* <MenuItem
                        onClick={() => {
                          popupState.close();
                          navigate('/home/add-issue-type');
                        }}
                      >
                        Issue Type
                      </MenuItem> */}

                        <MenuItem
                          onClick={() => {
                            popupState.close();
                            navigate('/home/add-doc-type');
                          }}
                        >
                          Document Type
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            popupState.close();
                            navigate('/home/add-doc-sub-type');
                          }}
                        >
                          Document SubType
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            popupState.close();
                            navigate('/home/map-doc-types');
                          }}
                        >
                          Document Type - Token Lifecycle
                        </MenuItem>
                      </Menu>
                    </>
                  )}
                </PopupState>
              )}

              {role === 'LIQUIDITY_POOL_OWNER' && (
                <Button
                  onClick={() => navigate('/home/performance-dashboard')}
                  sx={{
                    color: '#161c2d'
                  }}
                >
                  <Box sx={{ mr: 2 }} component="img" src={masterdata} alt="dashboard  icon" />
                  Performance Dashboard
                </Button>
              )}
              <Box sx={{ flexGrow: 1 }} />
              <MasterDataButton />
            </Toolbar>
          </Container>
        </Box>
      )}
    </AppBar>
  );
};

export default IssuerNavbar;
