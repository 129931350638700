import { Table, TableCell, TableBody, TableRow, TableHead, Radio } from '@mui/material';
import React from 'react';
import DownloadIcon from '@mui/icons-material/Download';

const Attestations = ({ verificationDataState, handleRadioButtonSelect }) => {
  const rows = [
    {
      verification: 'Accrediation Status',
      doc: 'Document Name',
      icon: <DownloadIcon />,
      verificationStatus: verificationDataState.accredation_status,
      property_name: 'accredation_status'
    },
    {
      verification: 'Affiliation Status',
      doc: 'Document Name',
      icon: <DownloadIcon />,
      verificationStatus: verificationDataState.affiliation_status,
      property_name: 'affiliation_status'
    },
    {
      verification: 'Exempted Status',
      doc: 'Document Name',
      icon: <DownloadIcon />,
      verificationStatus: verificationDataState.exempted_status,
      property_name: 'exempted_status'
    }
  ];

  return (
    <>
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: '50%' }}>Verification Type</TableCell>
            <TableCell sx={{ width: '15%' }}>Not Applicable</TableCell>
            <TableCell sx={{ width: '15%' }}>Unverified</TableCell>
            <TableCell sx={{ width: '15%' }}>Verified</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.verification}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row" sx={{ fontWeight: 600 }}>
                {row.verification}
              </TableCell>

              <TableCell>
                <Radio
                  checked={row.verificationStatus === 'NA'}
                  value="NA"
                  name={row.property_name}
                  onChange={handleRadioButtonSelect}
                  color="default"
                />
              </TableCell>

              <TableCell>
                <Radio
                  checked={row.verificationStatus === 'UNVERIFIED'}
                  value="UNVERIFIED"
                  name={row.property_name}
                  onChange={handleRadioButtonSelect}
                  color="error"
                />
              </TableCell>

              <TableCell>
                <Radio
                  checked={row.verificationStatus === 'VERIFIED'}
                  value="VERIFIED"
                  name={row.property_name}
                  onChange={handleRadioButtonSelect}
                  color="success"
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};

export default Attestations;
