import {
  Container,
  Card,
  Grid,
  CardContent,
  Divider,
  Typography,
  TextField,
  FormLabel,
  Button,
  Box,
  FormControl,
  Select,
  MenuItem
} from '@mui/material';

import Page from 'components/Page';
import React from 'react';
import Breadcrumbs from 'components/Breadcrumbs';

const EditCashTxn = () => {
  const [input, setinput] = React.useState('');

  const handleChange1 = (event) => {
    setinput(event.target.value);
  };
  return (
    <>
      <Page title="Edit Cash Transaction - Master Data">
        <Container sx={{ my: 2 }}>
          <Box sx={{ my: 2 }}>
            <Breadcrumbs pageHead="Edit Cash Transaction" aria-label="breadcrumb" />
          </Box>
          <Card>
            <Container>
              <Typography
                sx={{
                  fontSize: '1.125rem',
                  fontWeight: 'bold',
                  mt: 4,
                  mb: 1,
                  ml: 4
                }}
              >
                Edit Cash Transaction
              </Typography>
              <Divider
                sx={{
                  width: '100%',
                  backgroundColor: '#f5f8fa',
                  border: '2px solid #f5f8fa'
                }}
              />

              <CardContent sx={{ m: 2 }}>
                <Grid container spacing={3} sx={{ width: '100%' }}>
                  <Grid item lg={6} sm={12} md={6} xs={12} sx={{ pr: 1 }}>
                    <FormLabel>Cash transaction type id</FormLabel>
                    <FormControl sx={{ mt: 1.5 }} fullWidth size="small">
                      <Select
                        value={input}
                        onChange={handleChange1}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                      >
                        <MenuItem value="">
                          <Typography>5256465254</Typography>
                        </MenuItem>
                        <MenuItem value={10}>Ten</MenuItem>
                        <MenuItem value={20}>Twenty</MenuItem>
                        <MenuItem value={30}>Thirty</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item lg={6} sm={12} md={6} xs={12} sx={{ pl: 0.5 }}>
                    <FormLabel>Transaction currency code</FormLabel>
                    <FormControl sx={{ mt: 1.5 }} fullWidth size="small">
                      <Select
                        value={input}
                        onChange={handleChange1}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                      >
                        <MenuItem value="">
                          <Typography>5256465254</Typography>
                        </MenuItem>
                        <MenuItem value={10}>Ten</MenuItem>
                        <MenuItem value={20}>Twenty</MenuItem>
                        <MenuItem value={30}>Thirty</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item lg={6} sm={12} md={6} xs={12}>
                    <FormLabel>Transaction amount</FormLabel>
                    <TextField
                      sx={{ mt: 1.5 }}
                      fullWidth
                      size="small"
                      autoComplete="off"
                      type="text"
                      // placeholder="525"
                    />
                  </Grid>
                  <Grid item lg={6} sm={12} md={6} xs={12}>
                    <FormLabel>Token id</FormLabel>
                    <FormControl sx={{ mt: 1.5 }} fullWidth size="small">
                      <Select
                        value={input}
                        onChange={handleChange1}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                      >
                        <MenuItem value="">
                          <Typography>5256465254</Typography>
                        </MenuItem>
                        <MenuItem value={10}>Ten</MenuItem>
                        <MenuItem value={20}>Twenty</MenuItem>
                        <MenuItem value={30}>Thirty</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item lg={6} sm={12} md={6} xs={12}>
                    <FormLabel>Bank account id</FormLabel>
                    <TextField
                      sx={{ mt: 1.5 }}
                      fullWidth
                      size="small"
                      autoComplete="off"
                      type="text"
                      // placeholder="XInfin"
                    />
                  </Grid>
                  <Grid item lg={6} sm={12} md={6} xs={12}>
                    <FormLabel>Lorem Ipsum</FormLabel>
                    <FormControl sx={{ mt: 1.5 }} fullWidth size="small">
                      <Select
                        value={input}
                        onChange={handleChange1}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                      >
                        <MenuItem value="">
                          <Typography>Lorem Ipsum</Typography>
                        </MenuItem>
                        <MenuItem value={10}>Ten</MenuItem>
                        <MenuItem value={20}>Twenty</MenuItem>
                        <MenuItem value={30}>Thirty</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Divider
                  sx={{
                    width: '100%',
                    backgroundColor: '#f5f8fa',
                    border: '2px solid #f5f8fa',
                    my: 4
                  }}
                />

                <Grid>
                  <Grid item lg={6} sm={12} md={6} xs={12}>
                    <FormLabel>Direction</FormLabel>
                    <TextField
                      multiline="true"
                      rows="3"
                      sx={{ mt: 1.5 }}
                      fullWidth
                      size="small"
                      maxRows="4"
                      autoComplete="off"
                      type="text"
                      // placeholder="Quisque feugiat magna quis semper suscipit. Nulla maximus diam eget efficitur varius"
                    />
                  </Grid>

                  <Grid
                    item
                    lg={12}
                    sm={12}
                    md={12}
                    xs={12}
                    sx={{ display: 'flex', justifyContent: 'flex-end' }}
                  >
                    <Button
                      variant="outlined"
                      color="secondary"
                      sx={{ mt: 4, ml: 2, height: '2.7rem', width: '7rem' }}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="gradient"
                      sx={{ mt: 4, ml: 2, height: '2.7rem', width: '7rem' }}
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Container>
          </Card>
        </Container>
      </Page>
    </>
  );
};

export default EditCashTxn;
