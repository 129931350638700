import {
  Container,
  Card,
  Grid,
  Box,
  CardContent,
  Typography,
  TextField,
  FormLabel,
  Button,
  Alert,
  AlertTitle,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  FormGroup,
  FormControlLabel,
  Checkbox
} from '@mui/material';

import Page from 'components/Page';
import SubscriptionHead from 'components/subscritiption/SubscriptionHead';
import React, { useState, useRef, useEffect } from 'react';
import InvestorTokenTable from 'components/subscritiption/InvestorTokenTable';
import { useFormik, Form, FormikProvider, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import {
  useBusinessEntityState,
  useSubscriptionState,
  useCoreTableState,
  useHttpApi,
  useWeb3,
  useAppState,
  useAuthStateShared
} from 'state';

import { useNavigate } from 'react-router';
import { useSnackbar } from 'notistack';
import FocusError from 'components/FocusError';
// import { ethToXdcAddress } from 'helpers/web3';

import MuiPhoneInput from 'material-ui-phone-number';
import parsePhoneNumber from 'libphonenumber-js';
import { debounce } from 'helpers/handler';
// import WalletRequireOverlay from 'components/WalletRequireOverlay';
import KYCModal from 'components/masterData/Investor/KYCModal';
// import { rules } from 'eslint-config-prettier';
import CircularProgress from '@material-ui/core/CircularProgress';
import PaymentModal from 'components/PaymentModal';
import TermsAndConditionsmodal from 'components/subscritiption/TermsAndConditionsmodal';

const SubscriptionForm = () => {
  const { tokenAddress } = useParams();
  const {
    setSubsToken,
    currentSubsToken,
    setCurrentTokenRuleDetails,
    currentTokenRuleDetails,
    fetchInvestorsByIssuer,
    investorsByIssuer
  } = useBusinessEntityState();
  const { account } = useWeb3();
  const {
    createSubscription: subscribe,
    getTokenByDeploymentAddress,
    getRuleTemplateDetailedByToken,
    getInvestorIssuerMapping,
    createIssuerInvestorMapping,
    getIDVList,
    createAstraRoutine
  } = useHttpApi();
  const { subscription, toUpdate, getSubscriberDetails } = useSubscriptionState();
  const [readOnlyTranch, setReadOnlyTranch] = useState(false);
  const [kycModalOpen, setKycModalOpen] = React.useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [issuerInvestorMap, setIssuerInvestorMap] = useState([]);
  const [astraStatus, setAstraStatus] = useState(false);
  const [astraAuthUrl, setAstraAuthUrl] = useState('');

  const [showTermsAndCondition, setShowTermsAndCondition] = useState(false);

  // const openKycModal = () => setKycModalOpen(true);
  // const closeKycModal = () => {
  //   setKycModalOpen(false);
  //   navigate('/subscription/investor-dashboard');
  // };
  const [isLoading, setIsLoading] = useState(true);

  const [openPaymentModal, setOpenPaymentModal] = useState(false);
  const handleOpenPaymentModal = () => setOpenPaymentModal(true);
  const handleClosePaymentModal = () => {
    setOpenPaymentModal(false);
    navigate('/subscription/investor-dashboard');
  };

  // Handle Terms and Conditions Modal
  const handleOpenTermsAndCondition = () => setShowTermsAndCondition(true);
  const handleCloseTermsAndCondition = () => setShowTermsAndCondition(false);

  // To fetch token from token address in URL and maintain state of susbsToken
  useEffect(async () => {
    const res = await getTokenByDeploymentAddress(tokenAddress);
    if (res) {
      setSubsToken(res);
      if (res.onchain_restrictions) {
      }
      const rules = await getRuleTemplateDetailedByToken(res.token_id);
      setCurrentTokenRuleDetails(rules);
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps

    // Fetching the Astra status
    const astraResponse = await getIDVList();
    setAstraAuthUrl(astraResponse.astra_auth_url)
    if(astraResponse.astra_status === true){
      // Create a transaction otherwise redirect to the astra auth page.
      setAstraStatus(true);
    }
  }, []);

  const [fetchedData, setFetchedData] = useState(false);

  const fetchData = async () => {
    setIsLoading(true);
    setDisableSubmit(false);
    setIsTokenSubscribedByInvestor(false);
    setReadOnlyTranch(false);

    if (account && currentSubsToken) {
      const res = await getSubscriberDetails(account, currentSubsToken?.token_id);
      if (res) {
        setFetchedData(true);
      }
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
  };

  useEffect(() => {
    fetchData();
  }, [account, currentSubsToken]);

  const { countries } = useCoreTableState();
  const { throwErrorMessage } = useAppState();
  const [error, setError] = useState(null);
  const [sessionId, setSessionId] = useState('');
  const { enqueueSnackbar } = useSnackbar();

  const [isTokenSubscribedByInvestor, setIsTokenSubscribedByInvestor] = useState(false);

  const [subInitialValues, setSubInitialValues] = useState({
    investor_name: '',
    investor_email: '',
    investor_phone: '',
    requested_qty: '',
    investor_country_code: '',
    investor_type: '',
    redeemcheck: false,
    redeemdate: '',
    tranche: '',
    terms_conditions_accepted: false
  });

  const navigate = useNavigate();

  const [investorPhone, setInvestorPhone] = useState(subscription?.investor.investor_phone_no);
  const [invalidPhone, setInvalidPhone] = useState();
  const [sessionType, setSessionType] = useState('');

  useEffect(() => {
    setInvalidPhone(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscription?.investor.investor_phone_no]);

  useEffect(async () => {
    console.log('subscription', subscription);
    if (subscription) {
      const response = await getInvestorIssuerMapping(
        currentSubsToken?.issuer_id?.issuer_id,
        subscription?.investor_id
      );
      setIssuerInvestorMap(response);
    }
  }, [subscription]);

  const CreateUserSchema = Yup.object().shape({
    investor_name: Yup.string().required('Name is required'),
    investor_email: Yup.string().email('Invalid Email Format').required('Email is required'),
    investor_country_code: Yup.string().required('Country code is required'),
    investor_type: Yup.string().required('Select the type of investor'),
    requested_qty: Yup.number()
      .required('Token Quantity is required')
      .positive('Token Quantity must be positive number'),
    terms_conditions_accepted: Yup.boolean().oneOf([true], 'Please accept the Terms and Conditions')
  });

  useEffect(() => {
    if (
      (subscription?.token_id === currentSubsToken?.token_id &&
        subscription?.token_subscription_details?.investor_id.xinfin_account ===
          issuerInvestorMap?.investor_xinfin_account &&
        subscription?.token_subscription_details?.current_active_allotment > 0) ||
      subscription?.token_subscription_details?.is_issuance_initiated
    ) {
      setDisableSubmit(true);
    }
  }, [subscription, issuerInvestorMap]);

  useEffect(() => {
    setIsTokenSubscribedByInvestor(false);
    if (
      toUpdate &&
      // subscription?.token_subscription_details &&
      // subscription?.token_id === currentSubsToken?.token_id &&
      issuerInvestorMap &&
      issuerInvestorMap?.investor_xinfin_account === account
    ) {
      setIsTokenSubscribedByInvestor(true);
    }

    if (
      toUpdate &&
      subscription?.token_subscription_details &&
      subscription?.token_id === currentSubsToken?.token_id &&
      issuerInvestorMap &&
      issuerInvestorMap?.investor_xinfin_account === account
    ) {
      setReadOnlyTranch(true);
    }

    formik.setFieldValue('investor_name', toUpdate ? subscription?.investor?.investor_name : '');
    formik.setFieldValue(
      'investor_email',
      toUpdate ? subscription?.investor?.investor_email_id : ''
    );
    formik.setFieldValue(
      'investor_phone',
      toUpdate ? subscription?.investor?.investor_phone_no : ''
    );
    formik.setFieldValue('lei', toUpdate ? subscription?.investor?.lei : '');
    formik.setFieldValue(
      'investor_country_code',
      toUpdate ? subscription?.investor?.investor_country_code : ''
    );
    formik.setFieldValue(
      'tranche',
      toUpdate &&
        subscription?.token_subscription_details &&
        subscription?.token_id === currentSubsToken?.token_id
        ? subscription?.token_subscription_details?.tranche
        : ''
    );
    formik.setFieldValue('investor_type', toUpdate ? subscription?.investor?.investor_type : '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscription, toUpdate, currentSubsToken, navigate, account, issuerInvestorMap]);

  const subscriptionData = async () => {
    const data = formik.values;
    data.xinfin_address = account;
    // check if investor.investor_country_code is in rules.investor_country array
    if (currentSubsToken.onchain_restrictions) {
      const investorCountry = countries.find(
        (country) => country.id === data.investor_country_code
      );

      if (currentTokenRuleDetails.investor_country?.indexOf(investorCountry?.country_code) === -1) {
        throw new Error('Investor Country fails Distribution Criteria', {
          variant: 'error'
        });
      }
    }

    data.token_id = currentSubsToken?.token_id;
    if (currentSubsToken?.token_type !== 'PROXY_TOKEN') {
      data.tranche = 'SENIOR';
    }

    const res = await subscribe(data);

    if (res) {
      const sessionId = res.session_id;
      setSessionId(sessionId);
      setSessionType(res.investor_type.toLowerCase());
      // open kyc model

      // if (
      //   subscription === null ||
      //   (subscription && issuerInvestorMap && issuerInvestorMap?.issuer === undefined)
      // ) {
      //   if (currentSubsToken.kyc_provider_id.kyc_provider_name === 'SYNAPS') {
      //     setKycModalOpen(true);
      //   } else {
      //     throw new Error('KYC Provider not integrated');
      //   }
      // }
      // resetForm();

      enqueueSnackbar('Subscription created successfully', {
        variant: 'success'
      });
    }
    return res;
  };

  const formik = useFormik({
    initialValues: subInitialValues,

    validate: (values) => {
      const errors = {};
      if (values.requested_qty > currentSubsToken?.total_supply) {
        errors.requested_qty = 'Token Quantity cannot be more than total supply';
      } else if (values.requested_qty < currentSubsToken?.minimum_subscription) {
        errors.requested_qty = 'Token Quantity cannot be less than Minimum Subscription';
      }
      if (values.investor_phone === '') {
        errors.investor_phone = 'Enter Phone Number';
      }
      if (invalidPhone) {
        errors.investor_phone = 'Enter Valid Phone Number';
      }

      return errors;
    },

    validationSchema: CreateUserSchema,
    onSubmit: async (data, { resetForm }) => {
      try {
        const kycStatusCheck = await createIssuerInvestorMapping({
          issuer_id: currentSubsToken?.issuer_id?.issuer_id,
          investor_id: subscription?.investor_id
        });

        if(astraStatus === true){
          // create a transaction
          const postData = {
            latest_nav:currentSubsToken?.token_nav,
            requested_qty:values.requested_qty
          }
          try{
            await createAstraRoutine(postData);
          }
          catch{
            enqueueSnackbar('Something went wrong with Astra Payment', {
              variant: 'error'
            });

          }
          const res = await subscriptionData();
          if(res){
            // handleOpenPaymentModal();
            navigate('/subscription/investor-dashboard');
          }
          
        }
        else{
          // open astra page if astra auth is not done
          window.location.href=astraAuthUrl;

        }


        // -> The below kyc code has been commented to show astra page on click of submit button.


        // Original kyc code starts here
        // setSessionId(kycStatusCheck.session_id);
        // setSessionType(kycStatusCheck?.investor?.investor_type.toLowerCase());

        // if (res) {
        //   if (kycStatusCheck?.kyc_status?.kyc_status_name === 'PENDING') {
        //     setKycModalOpen(true);
        //   } else if (kycStatusCheck?.kyc_status?.kyc_status_name === 'ONCHAINCOMPLETE') {
        //     handleOpenPaymentModal();
        //   } else {
        //     enqueueSnackbar('Your KYC is under review!', {
        //       variant: 'info'
        //     });
        //     navigate('/subscription/investor-dashboard');
        //   }
        // }
        // original kyc code ends here 

        // navigate('/subscription');
      } catch (e) {
        throwErrorMessage(e);
      }
    }
  });

  const phoneNumberHandler = (value) => {
    let countryCode;
    const errors = {};
    setInvalidPhone(true);
    errors.investor_phone = 'Enter Valid Phone Number';
    const phoneNumber = parsePhoneNumber(value);
    if (phoneNumber) {
      countryCode = phoneNumber.country;
      if (countryCode) {
        const _phoneNumberValidityParse = parsePhoneNumber(value, countryCode);
        if (_phoneNumberValidityParse.isValid()) {
          formik.setFieldValue('investor_phone', _phoneNumberValidityParse.number);
          delete errors.investor_phone;
          console.log('phone details', _phoneNumberValidityParse);
          console.log('Phone Validity', phoneNumber.isValid());
          console.log('Phone Num', _phoneNumberValidityParse.number);
          setInvalidPhone(false);
        }
      }
    }
    return errors;
  };

  // eslint-disable-next-line no-unused-vars
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;
  return (
    <>
      {/* {!account && <WalletRequireOverlay />} */}
      <Page title="Subscription Form">
        <SubscriptionHead
          title={`${currentSubsToken?.token_name} | ${currentSubsToken?.token_symbol}`}
        />
        <Container sx={{ mt: 4 }}>
          {error && (
            <Box mb={4}>
              <Alert severity="error">{error}</Alert>
            </Box>
          )}

          <Card>
            <CardContent sx={{ m: 4 }}>
              {isLoading || !account || !fetchedData ? (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    my: 4
                  }}
                >
                  <CircularProgress size={48} />
                  <Typography variant="subtitle1" style={{ marginTop: '8px' }}>
                    Loading Investor Details...
                  </Typography>
                </Box>
              ) : (
                <FormikProvider value={formik} validator={() => ({})}>
                  <Form autoComplete="off" onSubmit={handleSubmit}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        my: 4
                      }}
                    >
                      {currentSubsToken && <InvestorTokenTable {...currentSubsToken} />}
                    </Box>
                    {disableSubmit && (
                      <Box mb={4}>
                        <Alert severity="warning">
                          <AlertTitle>Subscription Currently Unavailable</AlertTitle>
                          {currentSubsToken.token_name} is under issuance at the moment. Please try
                          again later to subscribe.
                        </Alert>
                      </Box>
                    )}

                    <Card
                      sx={{
                        boxShadow: 'none',
                        border: '1px solid #D2D2D2',
                        borderRadius: '6px',
                        px: 4,
                        py: 1
                      }}
                    >
                      <Typography sx={{ mt: 2, fontSize: '1.25rem', fontWeight: 'bold' }}>
                        Invest
                      </Typography>
                      <Grid container spacing={3} sx={{ width: '100%', py: 2 }}>
                        {/* {JSON.stringify(errors)} */}

                        <Grid item lg={6} md={6} xs={12} sm={12}>
                          <FormLabel>Investor Type</FormLabel>

                          <FormControl size="small" variant="outlined" fullWidth sx={{ mt: 1.5 }}>
                            <Select
                              // inputProps={{ readOnly: isTokenSubscribedByInvestor }}
                              inputProps={{ readOnly: true }}
                              {...getFieldProps('investor_type')}
                              error={Boolean(touched.investor_type && errors.investor_type)}
                              helperText={touched.investor_type && errors.investor_type}
                            >
                              <MenuItem key="INDIVIDUAL" value="INDIVIDUAL">
                                Individual
                              </MenuItem>
                              <MenuItem key="CORPORATE" value="CORPORATE">
                                Corporate
                              </MenuItem>
                            </Select>
                            <FormHelperText sx={{ color: '#d32f2f' }}>
                              {touched.investor_type && errors.investor_type}
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                        {/* {account && (
                          <Grid item lg={6} md={6} xs={12}>
                            <FormLabel>Settlement Account</FormLabel>
                            <TextField
                              value={ethToXdcAddress(account)}
                              inputProps={{ readOnly: true }}
                              sx={{ mt: 1.5 }}
                              fullWidth
                              size="small"
                              autoComplete="Eth Address"
                              type="text"
                            />
                          </Grid>
                        )} */}
                        <Grid item lg={6} md={6} xs={12}>
                          <FormLabel>Name</FormLabel>
                          <TextField
                            // inputProps={{ readOnly: isTokenSubscribedByInvestor }}
                            inputProps={{ readOnly: true }}
                            sx={{ mt: 1.5 }}
                            fullWidth
                            size="small"
                            autoComplete="Name"
                            type="text"
                            // placeholder="Enter Your Name"
                            {...getFieldProps('investor_name')}
                            error={Boolean(touched.investor_name && errors.investor_name)}
                            helperText={touched.investor_name && errors.investor_name}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} xs={12}>
                          <FormLabel>Email</FormLabel>
                          <TextField
                            // inputProps={{ readOnly: isTokenSubscribedByInvestor }}
                            inputProps={{ readOnly: true }}
                            sx={{ mt: 1.5 }}
                            fullWidth
                            size="small"
                            autoComplete="email"
                            type="email"
                            {...getFieldProps('investor_email')}
                            error={Boolean(touched.investor_email && errors.investor_email)}
                            helperText={touched.investor_email && errors.investor_email}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} xs={12} sm={12}>
                          <FormLabel>Country Code</FormLabel>

                          <FormControl size="small" variant="outlined" fullWidth sx={{ mt: 1.5 }}>
                            <Select
                              // inputProps={{ readOnly: isTokenSubscribedByInvestor }}
                              inputProps={{ readOnly: true }}
                              {...getFieldProps('investor_country_code')}
                              error={Boolean(
                                touched.investor_country_code && errors.investor_country_code
                              )}
                              helperText={
                                touched.investor_country_code && errors.investor_country_code
                              }
                            >
                              {countries &&
                                countries.map((country, index) => {
                                  return (
                                    <MenuItem key={country.id} value={country.id}>
                                      {country.country_name}
                                    </MenuItem>
                                  );
                                })}
                            </Select>
                            <FormHelperText sx={{ color: '#d32f2f' }}>
                              {touched.investor_country_code && errors.investor_country_code}
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                        <Grid item lg={6} md={6} xs={12}>
                          <FormLabel>Phone Number</FormLabel>

                          <MuiPhoneInput
                            // inputProps={{ readOnly: isTokenSubscribedByInvestor }}
                            inputProps={{ readOnly: true }}
                            value={investorPhone}
                            sx={{ mt: 1.5 }}
                            fullWidth
                            defaultCountry="us"
                            variant="outlined"
                            size="small"
                            {...getFieldProps('investor_phone')}
                            onChange={(value) => {
                              debounce(phoneNumberHandler)(value);
                            }}
                            error={Boolean(touched.investor_phone && errors.investor_phone)}
                            helperText={touched.investor_phone && errors.investor_phone}
                          />
                        </Grid>

                        <Grid item lg={6} md={6} xs={12}>
                          <FormLabel>Token Quantity</FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            fullWidth
                            size="small"
                            autoComplete="Token Quantity"
                            type="number"
                            {...getFieldProps('requested_qty')}
                            error={Boolean(touched.requested_qty && errors.requested_qty)}
                            helperText={touched.requested_qty && errors.requested_qty}
                          />
                        </Grid>

                        {/* Dummy Field for Settlement Account */}

                        {currentSubsToken?.token_type === 'PROXY_TOKEN' && (
                          <Grid item lg={6} md={6} xs={12} sm={12}>
                            <FormLabel>Tranche</FormLabel>

                            <FormControl size="small" variant="outlined" fullWidth sx={{ mt: 1.5 }}>
                              <Select
                                // placeholder="Select Country"
                                inputProps={{ readOnly: readOnlyTranch }}
                                {...getFieldProps('tranche')}
                                error={Boolean(touched.tranche && errors.tranche)}
                                helperText={touched.tranche && errors.tranche}
                              >
                                <MenuItem value="SENIOR">Senior</MenuItem>
                                <MenuItem value="JUNIOR">Junior</MenuItem>
                              </Select>
                              <FormHelperText sx={{ color: '#d32f2f' }}>
                                {touched.tranche && errors.tranche}
                              </FormHelperText>
                            </FormControl>
                          </Grid>
                        )}

                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <FormGroup
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center' // Use alignItems to vertically center the elements
                            }}
                          >
                            <FormControlLabel
                              sx={{ margin: 0, mr: 1 }}
                              control={<Checkbox />}
                              label="I accept the"
                              {...getFieldProps('terms_conditions_accepted')}
                            />
                            <Typography
                              sx={{
                                margin: 0,
                                display: 'flex',
                                alignItems: 'center',
                                cursor: 'pointer',
                                color: 'blue',
                                textDecoration: 'underline'
                              }}
                              component="p"
                              onClick={handleOpenTermsAndCondition}
                            >
                              Terms and Conditions
                            </Typography>
                          </FormGroup>
                          {touched.terms_conditions_accepted &&
                            errors.terms_conditions_accepted && (
                              <FormHelperText sx={{ color: '#d32f2f', ml: 1.5 }}>
                                {errors.terms_conditions_accepted}
                              </FormHelperText>
                            )}
                        </Grid>
                      </Grid>
                    </Card>

                    <Grid
                      container
                      sx={{
                        width: '100%',
                        mt: 2,
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-end'
                      }}
                    >
                      <Grid
                        item
                        lg={12}
                        md={12}
                        xs={12}
                        sx={{ display: 'flex', justifyContent: 'flex-end' }}
                      >
                        <Button
                          sx={{
                            px: 3,
                            mr: 1,
                            width: '7.75rem',
                            height: '2.5rem'
                          }}
                          variant="contained"
                          color="secondary"
                          onClick={() => {
                            navigate('/subscription');
                          }}
                        >
                          Cancel
                        </Button>
                        <LoadingButton
                          sx={{
                            width: '7.5rem',
                            height: '2.5rem'
                          }}
                          variant="gradient"
                          type="submit"
                          loadingPosition="start"
                          loading={isSubmitting}
                          disabled={disableSubmit}
                        >
                          Submit
                        </LoadingButton>
                      </Grid>
                    </Grid>
                    <FocusError />
                  </Form>
                </FormikProvider>
              )}

              {/* <KYCModal
                service={sessionType}
                sessionId={sessionId}
                kycModalOpen={kycModalOpen}
                closeKycModal={closeKycModal}
              /> */}
              <PaymentModal open={openPaymentModal} handleClose={handleClosePaymentModal} />
              <TermsAndConditionsmodal
                open={showTermsAndCondition}
                handleClose={handleCloseTermsAndCondition}
              />
            </CardContent>
          </Card>
        </Container>
      </Page>
    </>
  );
};

export default SubscriptionForm;
