import {
  Table,
  TableCell,
  TableBody,
  TableRow,
  TableHead,
  Radio,
  IconButton,
  Button,
  Typography
} from '@mui/material';
import React from 'react';
import { useBusinessEntityState } from 'state';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { isDevEnv } from 'helpers/dev';
import { API_URL } from 'config';

const InestorDocMgt = ({ requiredDocuments, handleRadioButtonSelect }) => {
  return (
    <>
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>Document Type</TableCell>
            <TableCell>View Document</TableCell>
            <TableCell>Not Approved</TableCell>
            <TableCell>Approved</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {requiredDocuments
            .filter((row) => row.uploaded_document)
            .map((row) => (
              <TableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 }, height: '2px' }}
              >
                <TableCell component="th" scope="row" sx={{ fontWeight: 600 }}>
                  {row.name}
                </TableCell>

                <TableCell>
                  <Button
                    variant="outlined"
                    onClick={() =>
                      window.open(
                        isDevEnv
                          ? `${API_URL}${row.uploaded_document.document.substring(1)}`
                          : `${row.uploaded_document.document}`
                      )
                    }
                  >
                    <Typography sx={{ fontSize: '14px' }}>
                      {row.uploaded_document.document_subtype.name}
                    </Typography>
                    <OpenInNewIcon sx={{ ml: 1 }} />
                  </Button>
                </TableCell>

                <TableCell>
                  <Radio
                    checked={!row.is_approved}
                    value="UNVERIFIED"
                    name={row.id}
                    onChange={handleRadioButtonSelect}
                    color="error"
                  />
                </TableCell>

                <TableCell>
                  <Radio
                    checked={row.is_approved}
                    value="VERIFIED"
                    name={row.id}
                    onChange={handleRadioButtonSelect}
                    color="success"
                  />
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </>
  );
};

export default InestorDocMgt;
