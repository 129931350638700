import React, { useEffect, useState } from 'react';
import { Box, IconButton, Chip } from '@mui/material';
import { useBusinessEntityState } from 'state/useBusinessEntityState';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { ethToXdcAddress } from 'helpers/web3';
import EnhancedTableCheck from 'components/tables/EnhancedTableCheck';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SubsDetails from 'components/masterData/Dashboard/SubsDetails';
import Modal from '@mui/material/Modal';
import { useAuthStateShared } from 'state';
import CopyAddress from 'components/CopyAddress';
import EnhancedTable from 'components/tables/EnhancedTable';

const headCells = [
  {
    accessor: 'token_subscription_id.investor_id.investor_name',
    Header: 'Name',
    width: 100,
    show: true
  },
  {
    accessor: 'xinfin_address',
    Header: 'Xinfin Address',
    width: 200,
    show: true
  },
  {
    accessor: 'allotment_date',
    Header: 'Allotment Date',
    width: 100,
    show: true
  },
  {
    accessor: 'allotment_maturity_date',
    Header: 'Maturity Date',
    width: 100,

    show: true
  },
  {
    accessor: 'alloted_qty',
    Header: 'Alloted Qty',
    width: 80,
    show: true
  },
  {
    accessor: 'onchain_status',
    Header: 'Status',
    show: true
  },
  {
    accessor: 'redeemrequested_status',
    Header: 'Redeem Requested',
    show: true
  }
];
const ActionButtons = ({ investor }) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <>
      <IconButton aria-label="subs detail" onClick={handleOpen} sx={{ mt: -1 }}>
        <VisibilityIcon sx={{ fontSize: '2rem', p: 1, m: 0, backgroundColor: '#F5F8FA' }} />
      </IconButton>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <SubsDetails investor={investor} handleClose={handleClose} />
      </Modal>
    </>
  );
};

const AllotmentChainStatus = ({ status }) => {
  return (
    <Chip sx={{ mb: 1.5 }} label={status} color={status === 'ONCHAIN' ? 'success' : 'warning'} />
  );
};
const RedeemRequestStatus = ({ status }) => {
  return (
    <Chip sx={{ mb: 1.5 }} label={status ? 'YES' : 'NO'} color={status ? 'success' : 'warning'} />
  );
};
export default function ViewAllotmentTable() {
  const {
    allAllotments,
    fetchAllAllotments,
    setRemoveFromWhiteListAddresses,
    setCurrentRedeemAllotmentList
  } = useBusinessEntityState();
  const [data, setData] = useState(allAllotments);
  const { role } = useAuthStateShared();

  const [skipPageReset, setSkipPageReset] = React.useState(false);

  useEffect(() => {
    const _currentWhiteList = allAllotments.map((subscriber) => {
      const xinfinAddress =
        subscriber.token_subscription_id.xinfin_setl_account.xinfin_account_address;
      subscriber.xinfin_address = <CopyAddress addr={ethToXdcAddress(xinfinAddress)} />;

      subscriber.actions = (
        <ActionButtons investor={subscriber.token_subscription_id.investor_id} />
      );
      subscriber.onchain_status = <AllotmentChainStatus status={subscriber.status} />;
      subscriber.redeemrequested_status = (
        <RedeemRequestStatus status={subscriber.is_redeem_requested} />
      );
      return subscriber;
    });
    setData(_currentWhiteList);
  }, [allAllotments]);
  // We need to keep the table from resetting the pageIndex when we
  // Update data. So we can keep track of that flag with a ref.

  // When our cell renderer calls updateMyData, we'll use
  // the rowIndex, columnId and new value to update the
  // original data
  const updateMyData = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true);
    setData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value
          };
        }
        return row;
      })
    );
  };

  return (
    <>
      <EnhancedTable
        tableTitle="View Allotment"
        columns={headCells}
        refreshFunction={fetchAllAllotments}
        setSelectedRowsToState={setCurrentRedeemAllotmentList}
        data={data}
        setData={setData}
        updateMyData={updateMyData}
        skipPageReset={skipPageReset}
        isRoleAllowed={['PRIMARY_ISSUER', 'ISSUER_OPERATOR', 'INVESTOR'].includes(role)}
      />
    </>
  );
}
