import { AppBar, Container, Toolbar, Button, Box, IconButton, Badge, Popover } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Logo from './Logo';
import WalletNavbar from 'components/WalletNavbar';
import dashboard from '../assets/images/dashboard.svg';
import ownerDashboard from '../assets/images/ownerDashboard.svg';
import { useNavigate } from 'react-router';
import { useCoreTableState, useAppState } from 'state';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import Notification from './masterData/Notification';
import InvestorNotifications from './InvestorNotifications';
import AccountPopover from 'layouts/main/AccountPopover';

const SubscriptionNavbar = () => {
  const { fetchOrganizations, orgnizations } = useCoreTableState();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { notifications, fetchUserNotifications } = useAppState();
  const location = useLocation();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  useEffect(() => {
    fetchOrganizations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const navigate = useNavigate();
  return (
    <AppBar
      position="sticky"
      sx={{
        backgroundColor: '#fff',
        height: '4.9rem'
      }}
    >
      <Toolbar>
        <Box
          sx={{
            m: 2
            // width: '11.1rem',
          }}
        >
          {orgnizations[0] && (
            <Box
              component="img"
              src={orgnizations[0].logo}
              sx={{ height: '2.9rem' }}
              alt="Tokenisation Logo"
            />
          )}
        </Box>

        <Box sx={{ flexGrow: 1.5 }} />
        {/* <WalletNavbar /> */}
        <Box sx={{ flexGrow: 1 }} />
        <Box
          sx={{
            ml: 3,
            mt: 1.5
          }}
        >
          <Button
            onClick={() => navigate('/subscription')}
            sx={{
              color: '#161c2d',
              backgroundColor: location.pathname === '/subscription' && '#f5f8fa'
            }}
          >
            <Box sx={{ mr: 1 }} component="img" src={dashboard} alt="dashboard  icon" /> Token
            Dashboard
          </Button>
          <Button
            onClick={() => navigate('/subscription/investor-dashboard')}
            sx={{
              color: '#161c2d',
              mr: 1,
              backgroundColor: location.pathname === '/subscription/investor-dashboard' && '#f5f8fa'
            }}
          >
            <Box sx={{ mr: 1 }} component="img" src={ownerDashboard} alt="dashboard  icon" /> My
            Investments
          </Button>
          {/* <Button
            onClick={() => navigate('/subscription/liquidity-pools')}
            sx={{
              color: '#161c2d',
              mr: 1,
              backgroundColor: location.pathname === '/subscription/liquidity-pools' && '#f5f8fa'
            }}
          >
            <Box sx={{ mr: 1 }} component="img" src={ownerDashboard} alt="dashboard  icon" />{' '}
            Liquidity Pool
          </Button> */}

          <IconButton sx={{ mx: 1 }} onClick={handleClick}>
            <Badge badgeContent={notifications.length} color="primary">
              <NotificationsActiveIcon />
            </Badge>
          </IconButton>

          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
          >
            <InvestorNotifications />
          </Popover>

          <AccountPopover />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default SubscriptionNavbar;
