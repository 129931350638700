import { useState, useCallback, useMemo } from 'react';
import constate from 'constate';

const useRbac = () => {
  const [groups, setGroups] = useState(new Set());
  const [permissions, setPermissions] = useState(new Set());

  const initRbac = useCallback((groups, permissions) => {
    setGroups(new Set(groups.map(({ name }) => name)));
    setPermissions(new Set(permissions));
  }, []);

  const hasGroup = useCallback((groupId) => groups.has(groupId), [groups]);
  const hasPermission = useCallback((permissionId) => permissions.has(permissionId), [permissions]);

  const actions = useMemo(() => ({ initRbac }), [initRbac]);
  const predicates = useMemo(() => ({ hasGroup, hasPermission }), [hasGroup, hasPermission]);

  return { actions, predicates };
};

export const [RbacProvider, useRbacActions, useRbacPredicates] = constate(
  useRbac,
  ({ actions }) => actions,
  ({ predicates }) => predicates
);
