import 'simplebar/src/simplebar.css';
import './index.css';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import 'helpers/log';

import App from './App';

console.log(`Application Version: ${process.env.REACT_APP_APP_VERSION ?? 'dev'}`);

ReactDOM.render(
  <HelmetProvider>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </HelmetProvider>,
  document.getElementById('root')
);
