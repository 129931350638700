import {
  Box,
  Table,
  TableRow,
  TableCell,
  TableHead,
  Card,
  CardContent,
  Chip,
  Typography,
  Grid,
  TableContainer,
  IconButton,
  Button
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import React, { useState, useEffect } from 'react';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 3
};

export default function SubsDetails({ investor, handleClose }) {
  return (
    <>
      <Box sx={style}>
        <Card sx={{ boxShadow: 'none' }}>
          <CardContent>
            <TableContainer>
              <Table>
                <TableRow>
                  <TableCell sx={{ fontWeight: '600' }}>Investor Name</TableCell>
                  <TableCell>{investor.investor_name}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ fontWeight: '600' }}>Email ID</TableCell>
                  <TableCell>{investor.investor_email_id}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ fontWeight: '600' }}>Country Code</TableCell>
                  <TableCell>{investor.investor_country_code.country_code}</TableCell>
                </TableRow>
                {/* <TableRow>
                  <TableCell sx={{ fontWeight: '600' }}>LEI</TableCell>
                  <TableCell>{investor.lei}</TableCell>
                </TableRow> */}

                {/*  <TableCell sx={{ fontWeight: '600' }}>Subsription Document</TableCell>
                  <TableCell sx={{ color: '#5178f9' }}>
                    <IconButton
                      aria-label="subs detail"
                      onClick={() => window.open(subsdoc, '_blank')}
                    >
                      <DownloadIcon
                        sx={{ fontSize: '2.188rem', p: 1, backgroundColor: '#F5F8FA' }}
                      />
                    </IconButton>
                    {fileNamesubs}
                  </TableCell> */}

                {/* <TableRow>
                  <TableCell sx={{ fontWeight: '600' }}>KYC Document</TableCell>
                  <TableCell sx={{ color: '#5178f9' }}>
                    <IconButton
                      aria-label="subs detail"
                      onClick={() => window.open(kycdoc, '_blank')}
                    >
                      <DownloadIcon
                        sx={{ fontSize: '2.188rem', p: 1, backgroundColor: '#F5F8FA' }}
                      />
                    </IconButton>
                    {fileNamekyc}
                  </TableCell>
                </TableRow> */}
                {/*   <TableRow>
                  <TableCell sx={{ fontWeight: '600' }}>KYC Status</TableCell>
                  <TableCell>
                    <Chip label={investor.kyc_status.kyc_status_name} color="success" />
                  </TableCell>
                </TableRow> */}
              </Table>
            </TableContainer>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                sx={{
                  mt: 1,
                  width: '7.75rem',
                  height: '2.5rem'
                }}
                variant="contained"
                color="secondary"
                onClick={handleClose}
              >
                Close
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </>
  );
}
