import {
  Box,
  Table,
  TableRow,
  TableCell,
  TableHead,
  Card,
  CardContent,
  Chip,
  Typography,
  Grid,
  TableContainer,
  TableBody,
  Radio,
  IconButton,
  Button,
  FormLabel,
  TextField,
  FormHelperText,
  MenuItem,
  Select,
  FormControl
} from '@mui/material';
import React, { useState, useRef, useEffect } from 'react';
import { LoadingButton } from '@mui/lab';
import { useFormik, Form, FormikProvider, Field } from 'formik';
import DownloadIcon from '@mui/icons-material/Download';
import { isDevEnv } from 'helpers/dev';
import { API_URL } from 'config';
import { useSnackbar } from 'notistack';
import { useHttpApi, useAppState, useWeb3, useSubscriptionState } from 'state';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 3
};

export default function InvestorDocVewUploadModal({ handleClose, row }) {
  console.log('Check', row);
  const { enqueueSnackbar } = useSnackbar();
  const { updateSubscriptionDocuments } = useHttpApi();
  const { throwErrorMessage } = useAppState();
  const { account, active } = useWeb3();
  const { fetchOpenTransactionData } = useSubscriptionState();
  const formik = useFormik({
    initialValues: {
      required_docs: row.required_docs
    },
    onSubmit: async (values, { resetForm }) => {
      try {
        console.log('submit values', values);
        let select = 0;
        values.row.forEach((r) => {
          if (r) {
            if (r.uploaded_document) {
              if (!r.sub_types) {
                select += 1;
              }
            }
          }
        });
        if (select >= 1) {
          throw new Error('Please Select Document Name', {
            variant: 'error'
          });
        }
        let count = 0;
        values.row.forEach((r) => {
          if (r) {
            if (r.sub_types.id !== null) {
              if (!r.uploaded_document) {
                count += 1;
              }
            }
          }
        });
        if (count >= 1) {
          throw new Error('Please upload Documents required', {
            variant: 'error'
          });
        }
        let docWithInvalidSize = 0;
        values.row.forEach((r) => {
          if (r) {
            if (r.uploaded_document && r.sub_types) {
              if (r.uploaded_document.document.size > 2000000) docWithInvalidSize += 1;
            }
          }
        });
        if (docWithInvalidSize >= 1) {
          throw new Error('Please upload Documents less than 2MB', {
            variant: 'error'
          });
        }
        const data = {
          subscription_id: row.id
        };
        values.row.forEach((r) => {
          if (r) {
            const modifier = r.id;
            data[`document_${modifier}_documentid`] = r.uploaded_document.id;
            data[`document_${modifier}_file`] = r.uploaded_document.document;
            data[`document_${modifier}_subttype_id`] = r.sub_types.id;
          }
        });
        const res = await updateSubscriptionDocuments(data);
        if (res) {
          enqueueSnackbar('Documents Updated Successfully', { variant: 'success' });
          fetchOpenTransactionData(account);
          handleClose();
        }
      } catch (e) {
        throwErrorMessage(e);
      }
    }
  });

  const refs = useRef([]);
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;
  return (
    <>
      <Box sx={style}>
        <Card sx={{ boxShadow: 'none', height: '30rem', width: '65rem' }}>
          <CardContent sx={{ overflow: 'auto', maxHeight: '100%' }}>
            <FormikProvider value={formik}>
              <Form autoComplete="off" onSubmit={handleSubmit}>
                <Typography sx={{ fontWeight: 600, fontSize: '1.25rem' }}>
                  View and Upload Documents
                </Typography>
                <Chip
                  label="Accepted Files are .pdf with maximum 2MB file size"
                  color="warning"
                  sx={{ mt: 1 }}
                />
                <Table size="small" aria-label="a dense table" sx={{ mt: 3 }}>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 600, fontSize: '1rem' }}>
                        Document Type
                      </TableCell>
                      <TableCell sx={{ fontWeight: 600, fontSize: '1rem' }}>
                        Document Name
                      </TableCell>
                      <TableCell sx={{ fontWeight: 600, fontSize: '1rem' }}>
                        Upload Documents
                      </TableCell>
                      <TableCell sx={{ fontWeight: 600, fontSize: '1rem' }}>
                        Issuer Verification Status
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {formik.values.required_docs.map((row, i) => (
                      <TableRow
                        key={row.name}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 }, height: '2px' }}
                      >
                        <TableCell component="th" scope="row" sx={{ fontWeight: 600 }}>
                          {row.name}
                        </TableCell>

                        <TableCell>
                          {/*  1st condition */}
                          {row.is_approved === false && row.uploaded_document === null && (
                            <>
                              <FormControl
                                size="small"
                                variant="outlined"
                                fullWidth
                                sx={{ mt: 1.5 }}
                              >
                                <Select
                                  onChange={(e) => {
                                    formik.setFieldValue(`row[${i}].sub_types.id`, e.target.value);
                                    formik.setFieldValue(`row[${i}].id`, row.id);
                                  }}
                                >
                                  {row.sub_types.map((subtype) => {
                                    return (
                                      <MenuItem key={subtype.id} value={subtype.id}>
                                        {subtype.name}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                            </>
                          )}
                          {/*  {2nd condition} */}
                          {row.is_approved === true && row.uploaded_document !== null && (
                            <FormControl size="small" variant="outlined" fullWidth sx={{ mt: 1.5 }}>
                              <Select id="demo-simple-select-outlined" displayEmpty disabled>
                                <MenuItem values="">
                                  {row.uploaded_document.document_subtype.name}
                                </MenuItem>
                              </Select>
                            </FormControl>
                          )}
                          {/*  {3rd condition} */}
                          {row.is_approved === false && row.uploaded_document !== null && (
                            <>
                              <FormControl
                                size="small"
                                variant="outlined"
                                fullWidth
                                sx={{ mt: 1.5 }}
                              >
                                <Select
                                  // defaultValue={row.uploaded_document.document_subtype.id}
                                  onChange={(e) => {
                                    formik.setFieldValue(`row[${i}].sub_types.id`, e.target.value);
                                    formik.setFieldValue(`row[${i}].id`, row.id);
                                  }}
                                >
                                  {row.sub_types.map((subtype) => {
                                    return (
                                      <MenuItem key={subtype.id} value={subtype.id}>
                                        {subtype.name}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                            </>
                          )}
                        </TableCell>
                        {/*  1st condition */}
                        {row.is_approved === false && row.uploaded_document === null && (
                          <TableCell>
                            <TextField
                              sx={{ mt: 1.5 }}
                              fullWidth
                              size="small"
                              autoComplete="off"
                              type="file"
                              inputProps={{ accept: '.pdf' }}
                              inputRef={(element) => {
                                refs.current[i] = element;
                              }}
                              onChange={(e) => {
                                formik.setFieldValue(
                                  `row[${i}].uploaded_document.document`,
                                  e.target.files[0]
                                );
                              }}
                            />
                          </TableCell>
                        )}
                        {/*  {2nd condition} */}
                        {row.is_approved === true && row.uploaded_document !== null && (
                          <TableCell>
                            <IconButton
                              aria-label="subs detail"
                              onClick={() =>
                                window.open(
                                  isDevEnv
                                    ? `${API_URL}${row.uploaded_document.document.substring(1)}`
                                    : `${row.uploaded_document.document}`
                                )
                              }
                            >
                              <DownloadIcon
                                sx={{ fontSize: '2.188rem', p: 1, backgroundColor: '#F5F8FA' }}
                              />
                            </IconButton>
                          </TableCell>
                        )}
                        {/*  {3rd condition} */}
                        {row.is_approved === false && row.uploaded_document !== null && (
                          <TableCell>
                            <Grid container sx={{ width: '100%' }}>
                              <Grid item lg={10} md={12} sm={12}>
                                <TextField
                                  sx={{ mt: 1.5 }}
                                  fullWidth
                                  size="small"
                                  autoComplete="off"
                                  type="file"
                                  inputProps={{ accept: '.pdf' }}
                                  ref={(element) => {
                                    refs.current[i] = element;
                                  }}
                                  onChange={(e) => {
                                    formik.setFieldValue(
                                      `row[${i}].uploaded_document.document`,
                                      e.target.files[0]
                                    );
                                    formik.setFieldValue(
                                      `row[${i}].uploaded_document.id`,
                                      row.uploaded_document.id
                                    );
                                  }}
                                />
                              </Grid>
                              <Grid item lg={2} md={12} sm={12} sx={{ mt: 1, pl: 1 }}>
                                <IconButton
                                  aria-label="subs detail"
                                  onClick={() =>
                                    window.open(
                                      isDevEnv
                                        ? `${API_URL}${row.uploaded_document.document.substring(1)}`
                                        : `${row.uploaded_document.document}`
                                    )
                                  }
                                >
                                  <DownloadIcon
                                    sx={{ fontSize: '2.188rem', p: 1, backgroundColor: '#F5F8FA' }}
                                  />
                                </IconButton>
                              </Grid>
                            </Grid>
                          </TableCell>
                        )}
                        <TableCell>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                              justifyContent: 'center'
                            }}
                          >
                            {row.is_approved ? (
                              <Chip label="APPROVED" color="success" />
                            ) : (
                              <Chip label="NOT APPROVED" color="error" />
                            )}
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                  <LoadingButton
                    type="submit"
                    variant="gradient"
                    fullWidth
                    size="large"
                    loadingPosition="start"
                    loading={isSubmitting}
                    sx={{ margin: 1, px: 3, width: '7.75rem', height: '2.5rem' }}
                  >
                    Upload
                  </LoadingButton>
                  <Button
                    sx={{
                      mt: 1,
                      width: '7.75rem',
                      height: '2.5rem'
                    }}
                    variant="contained"
                    color="secondary"
                    onClick={handleClose}
                  >
                    Close
                  </Button>
                </Box>
              </Form>
            </FormikProvider>
          </CardContent>
        </Card>
      </Box>
    </>
  );
}
