import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import React, { useMemo } from 'react';
import { useBusinessEntityState } from 'state';
import EnhancedTable from 'components/tables/EnhancedTable';

const NavHistory = () => {
  const { tokenNavHistoryPool } = useBusinessEntityState();
  const headCells = useMemo(
    () => [
      {
        accessor: 'token_nav_date',
        Header: 'Token NAV Date',
        show: true
      },
      {
        accessor: 'token_nav',
        Header: 'Pool Nav',
        show: true
      }
    ],
    []
  );
  return (
    <EnhancedTable
      tableTitle="Token NAV History"
      columns={headCells}
      refreshFunction={() => {}}
      data={tokenNavHistoryPool}
    />
  );
};

export default NavHistory;
