import React, { useEffect, useState } from 'react';
import { Box, Table, TableCell, TableHead, TableRow, TableBody } from '@mui/material';
import { useHttpApi } from 'state';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

const Status = ({ status }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      {status ? <CheckCircleIcon color="success" /> : <CancelIcon color="error" />}
    </Box>
  );
};
const HealthCheck = () => {
  const { getHealth } = useHttpApi();
  const [health, setHealth] = useState(null);

  useEffect(() => {
    const _fetchData = async () => {
      const res = await getHealth();
      setHealth(res);
    };
    _fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {health &&
            Object.keys(health).map((item) => {
              const val = health[item];
              return (
                <TableRow key={item}>
                  <TableCell>{item}</TableCell>
                  <TableCell>
                    <Status status={val === 'working'} />
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </Box>
  );
};
export default HealthCheck;
