/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import Page from 'components/Page';

import {
  Container,
  Card,
  Grid,
  Box,
  CardContent,
  Typography,
  TextField,
  FormLabel,
  Button,
  Divider,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  Alert,
  IconButton,
  InputAdornment
} from '@mui/material';
import Breadcrumbs from 'components/Breadcrumbs';
import EditIssuerAccodian from 'components/masterData/Issuer/EditIssuerAccodian';
import {
  useHttpApi,
  useCoreTableState,
  useWeb3,
  useBusinessEntityState,
  useAuthStateShared,
  useAppState
} from 'state';
import { useFormik, Form, FormikProvider, Field } from 'formik';
import * as Yup from 'yup';
import NotifierSnackbar from 'components/Notifier/NotifierSnackbar';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { xdcRegex, leiRegex } from 'helpers/regex';
import { xdcToEthAddress, toChecksumAddress } from 'helpers/web3';
import { useNavigate } from 'react-router';
import { LeiInfoButton, XdcInfoButton } from 'helpers/infoButton';
import CommonOverlay from 'components/CommonOverlay';
import { onlyOwner } from 'helpers/rbac';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { Icon } from '@iconify/react';

const AddLiquidityPool = () => {
  const { createLiquidityPool, checkUserNameAvailbility } = useHttpApi();
  const { fetchLiquidityPool } = useBusinessEntityState();
  const { throwErrorMessage } = useAppState();
  const { role } = useAuthStateShared();
  const [showPassword, setShowPassword] = useState(false);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [error, setError] = useState(null);

  const liquidityPoolLogo = useRef(null);

  // Issuer Schema
  const AddLiquidityPoolSchema = Yup.object().shape({
    liquidity_pool_name: Yup.string()
      .required('Issuer Name is required')
      .max(30, 'Issuer Name cannot be more than 30 characters'),

    country_code: Yup.string().required('Country Code is required'),

    liquidity_pool_xinfin_address: Yup.string()
      .matches(xdcRegex, 'Please enter a valid Liquidity Pool Address')
      .required('Liquidity Pool Address is required'),
    liquidity_pool_settlement_address: Yup.string()
      .matches(xdcRegex, 'Please enter a valid Settlement Address')
      .required('Settlement Address is required'),

    email: Yup.string().email('Enter A Valid Email').required('Email is Required'),
    liquidity_pool_logo: Yup.mixed().required('Issuer Logo is required'),
    username: Yup.string().required('Username is required'),
    password: Yup.string().required('Password is required'),
    liquidity_pool_owner_xinfin_address: Yup.string()
      .matches(xdcRegex, 'Please enter a valid Owner Address')
      .required('Settlement Address is required')
  });

  const { addLiquidityPool: AddLiquidityPoolBlockchain } = useWeb3();

  // Formik
  const formik = useFormik({
    initialValues: {
      liquidity_pool_name: '',
      country_code: '',
      liquidity_pool_xinfin_address: '',
      liquidity_pool_settlement_address: '',
      email: '',
      liquidity_pool_logo: '',
      username: '',
      password: '',
      liquidity_pool_owner_xinfin_address: ''
    },
    validationSchema: AddLiquidityPoolSchema,
    onSubmit: async (data, { resetForm }) => {
      console.log('AddLiquidityPool', data);
      try {
        const userNameRes = await checkUserNameAvailbility(data.username);
        if (userNameRes.exist) {
          formik.setFieldError('username', 'Username already exist');
          throw Error('Username Already Exist');
        }
        setError(null);

        const liquidityPoolLogoFile = liquidityPoolLogo.current.files[0];
        data.liquidity_pool_logo = liquidityPoolLogoFile;

        data.liquidity_pool_xinfin_address = xdcToEthAddress(data.liquidity_pool_xinfin_address);
        data.liquidity_pool_xinfin_address = toChecksumAddress(data.liquidity_pool_xinfin_address);
        data.liquidity_pool_settlement_address = toChecksumAddress(
          xdcToEthAddress(data.liquidity_pool_settlement_address)
        );
        data.liquidity_pool_owner_xinfin_address = toChecksumAddress(
          xdcToEthAddress(data.liquidity_pool_owner_xinfin_address)
        );
        // const countryCode = countries.find((country) => country.id === data.country_code);
        console.log('countryCode', data.country_code);

        // Django Call
        const _liquidityPool = await createLiquidityPool(data);
        formik.setFieldValue('liquidity_pool_logo', '');
        if (_liquidityPool.liquidity_pool_id) {
          enqueueSnackbar('Liquidity Pool Created Successfully', {
            variant: 'success'
          });
          fetchLiquidityPool();
          resetForm();
          navigate('/masterdata/liquidity-pool/');
        }

        // Blockchain Call
        const res = await AddLiquidityPoolBlockchain(
          data.liquidity_pool_xinfin_address,
          data.liquidity_pool_settlement_address
        );
        console.log('res', res);
      } catch (e) {
        formik.setFieldValue('liquidity_pool_logo', '');
        const errorMessage = await throwErrorMessage(e);
        setError(errorMessage);
      }
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;
  const { countries } = useCoreTableState();
  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const allowedRolesList = ['OWNER'];
  return (
    <Page title="Add Liquidity Pool - Master Data">
      <CommonOverlay
        allowedRoles={allowedRolesList}
        overlayTitle="You don't have access to Add Liquidity Pool"
      />
      <Container sx={{ my: 2 }}>
        <Box sx={{ my: 2 }}>
          <Breadcrumbs aria-label="breadcrumb" pageHead="Add Liquidity Pool" />
        </Box>
        {error && (
          <Box mb={4}>
            <Alert severity="error">{error}</Alert>
          </Box>
        )}
        <Card>
          <Container>
            <Typography
              sx={{
                fontSize: '1.125rem',
                fontWeight: 'bold',
                mt: 4,
                mb: 1,
                ml: 4
              }}
            >
              Liquidity Pool Details
            </Typography>
            <Divider
              sx={{
                width: '100%',
                backgroundColor: '#f5f8fa',
                border: '2px solid #f5f8fa'
              }}
            />
            <FormikProvider value={formik}>
              <Form autoComplete="off" onSubmit={handleSubmit}>
                <CardContent sx={{ m: 2 }}>
                  <Grid container spacing={3} sx={{ width: '100%' }}>
                    <Grid item lg={6} md={6} xs={12} sm={12}>
                      <FormLabel> Pool Name</FormLabel>
                      <TextField
                        sx={{ mt: 1.5 }}
                        fullWidth
                        size="small"
                        autoComplete="off"
                        type="text"
                        {...getFieldProps('liquidity_pool_name')}
                        error={Boolean(touched.liquidity_pool_name && errors.liquidity_pool_name)}
                        helperText={touched.liquidity_pool_name && errors.liquidity_pool_name}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xs={12} sm={12}>
                      <FormLabel> Username</FormLabel>
                      <TextField
                        sx={{ mt: 1.5 }}
                        fullWidth
                        size="small"
                        autoComplete="off"
                        type="text"
                        {...getFieldProps('username')}
                        error={Boolean(touched.username && errors.username)}
                        helperText={touched.username && errors.username}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xs={12} sm={12}>
                      <FormLabel> Email</FormLabel>
                      <TextField
                        sx={{ mt: 1.5 }}
                        fullWidth
                        size="small"
                        autoComplete="off"
                        type="text"
                        {...getFieldProps('email')}
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xs={12} sm={12}>
                      <FormLabel>Password</FormLabel>
                      <TextField
                        sx={{ mt: 1.5 }}
                        fullWidth
                        size="small"
                        autoComplete="off"
                        type={showPassword ? 'text' : 'password'}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton onClick={handleShowPassword} edge="end">
                                <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                        {...getFieldProps('password')}
                        error={Boolean(touched.password && errors.password)}
                        helperText={touched.password && errors.password}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12} sx={{ pr: 1 }}>
                      <FormLabel>Pool Logo</FormLabel>
                      <TextField
                        sx={{ mt: 1.5 }}
                        fullWidth
                        size="small"
                        autoComplete="off"
                        type="file"
                        inputRef={liquidityPoolLogo}
                        inputProps={{ accept: '.jpg, .jpeg, .png, .svg' }}
                        {...getFieldProps('liquidity_pool_logo')}
                        id="liquidity_pool_logo"
                        error={Boolean(touched.liquidity_pool_logo && errors.liquidity_pool_logo)}
                        helperText={touched.liquidity_pool_logo && errors.liquidity_pool_logo}
                      />

                      <Grid container sx={{ width: '100%', mt: 2 }}>
                        <Grid item lg={4} md={4} sm={4}>
                          <Typography
                            sx={{
                              fontSize: '0.688rem',
                              textAlign: 'left',
                              color: '#161c2d'
                            }}
                          >
                            Maximum 50KB file size
                          </Typography>
                        </Grid>
                        <Grid item lg={8} md={8} sm={8}>
                          <Typography
                            sx={{
                              fontSize: '0.688rem',
                              textAlign: 'right',
                              color: '#8e8ea7'
                            }}
                          >
                            Accepted File Type .jpg, .jpeg, .png & .svg only
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item lg={6} md={6} xs={12} sm={12}>
                      <FormLabel>Country Code</FormLabel>

                      <FormControl size="small" variant="outlined" fullWidth sx={{ mt: 1.5 }}>
                        <Select
                          // placeholder="Select Country"
                          inputProps={{ 'aria-label': 'Without label' }}
                          {...getFieldProps('country_code')}
                          error={Boolean(touched.country_code && errors.country_code)}
                        >
                          {countries &&
                            countries.map((country, index) => {
                              return (
                                <MenuItem key={country.id} value={country.country_code}>
                                  {country.country_name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                        <FormHelperText sx={{ color: '#d32f2f' }}>
                          {touched.country_code && errors.country_code}
                        </FormHelperText>
                      </FormControl>
                    </Grid>

                    <Grid item lg={6} md={6} xs={12} sm={12}>
                      <FormLabel>
                        XinFin Address
                        <XdcInfoButton />
                      </FormLabel>
                      <TextField
                        sx={{ mt: 1.5 }}
                        fullWidth
                        type="text"
                        size="small"
                        // placeholder="Primary XinFin Address"
                        {...getFieldProps('liquidity_pool_xinfin_address')}
                        error={Boolean(
                          touched.liquidity_pool_xinfin_address &&
                            errors.liquidity_pool_xinfin_address
                        )}
                        helperText={
                          touched.liquidity_pool_xinfin_address &&
                          errors.liquidity_pool_xinfin_address
                        }
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xs={12} sm={12}>
                      <FormLabel>
                        Settlement Address
                        <XdcInfoButton />
                      </FormLabel>
                      <TextField
                        sx={{ mt: 1.5 }}
                        fullWidth
                        type="text"
                        size="small"
                        // placeholder="Primary XinFin Address"
                        {...getFieldProps('liquidity_pool_settlement_address')}
                        error={Boolean(
                          touched.liquidity_pool_settlement_address &&
                            errors.liquidity_pool_settlement_address
                        )}
                        helperText={
                          touched.liquidity_pool_settlement_address &&
                          errors.liquidity_pool_settlement_address
                        }
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xs={12} sm={12}>
                      <FormLabel>
                        Owner Address
                        <XdcInfoButton />
                      </FormLabel>
                      <TextField
                        sx={{ mt: 1.5 }}
                        fullWidth
                        type="text"
                        size="small"
                        // placeholder="Primary XinFin Address"
                        {...getFieldProps('liquidity_pool_owner_xinfin_address')}
                        error={Boolean(
                          touched.liquidity_pool_owner_xinfin_address &&
                            errors.liquidity_pool_owner_xinfin_address
                        )}
                        helperText={
                          touched.liquidity_pool_owner_xinfin_address &&
                          errors.liquidity_pool_owner_xinfin_address
                        }
                      />
                    </Grid>
                  </Grid>

                  <Box
                    sx={{
                      mt: 4,
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-end'
                    }}
                  >
                    <Button
                      sx={{
                        margin: 1,
                        borderColor: '#24ABDF',
                        px: 3,
                        width: '7.75rem',
                        height: '2.5rem'
                      }}
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        navigate('/masterdata/liquidity-pool/');
                      }}
                    >
                      Cancel
                    </Button>
                    <LoadingButton
                      type="submit"
                      variant="gradient"
                      disabled={!onlyOwner(role)}
                      fullWidth
                      size="large"
                      loadingPosition="start"
                      loading={isSubmitting}
                      sx={{ margin: 1, px: 3, width: '7.75rem', height: '2.5rem' }}
                    >
                      Save
                    </LoadingButton>
                  </Box>
                  {/* <EditIssuerAccodian
                    rows={organizationrows}
                    title="Custodian Mapping"
                    tableTitle="Custodian Name"
                  />
                  <EditIssuerAccodian
                    rows={originatorrows}
                    title="Originator Mapping"
                    tableTitle="Originator Name"
                  />
                  <EditIssuerAccodian
                    rows={trusteerows}
                    title="Trustee Mapping"
                    tableTitle="Trustee Name"
                  />
                  <EditIssuerAccodian rows={kycrows} title="KYC Mapping" tableTitle="KYC Name" /> */}
                </CardContent>
              </Form>
            </FormikProvider>
          </Container>
        </Card>
      </Container>
    </Page>
  );
};

export default AddLiquidityPool;
