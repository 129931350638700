import { Box, Button, Card, CardContent, Container, Typography } from '@mui/material';
import Breadcrumbs from 'components/Breadcrumbs';
import TokenTable from 'components/issuerDashboard/TokenTable';
import PastPaymentTxn from 'components/PaymentTxn/PastPaymentTxn';
import PendingPaymentTxn from 'components/PaymentTxn/PendingPaymentTxn';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useBusinessEntityState } from 'state';

const PaymentTxn = () => {
  const { currentToken: token } = useBusinessEntityState();

  const navigate = useNavigate();

  return (
    <Container>
      <Breadcrumbs aria-label="breadcrumb" pageHead="Interest Accruals" />

      {/* Token Table */}
      <TokenTable currToken={token} />

      <Box sx={{ mt: 2 }}>
        <Typography
          variant="body2"
          color="initial"
          sx={{
            mt: 2,
            mb: 2,
            fontSize: '1.125rem',
            fontWeight: 'bold'
          }}
        >
          Interest Accruals
        </Typography>

        <PendingPaymentTxn />
      </Box>

      {/* <Box sx={{ mt: 2 }}>
        <Typography
          variant="body2"
          color="initial"
          sx={{
            mt: 2,
            mb: 2,
            fontSize: '1.125rem',
            fontWeight: 'bold'
          }}
        >
          Past Payment Transactions
        </Typography>
        <Card>
          <CardContent>
            <PastPaymentTxn tokenId={token.token_id} />
          </CardContent>
        </Card>

        <Card sx={{ mt: 4, p: 0 }}>
          <CardContent
            sx={{
              p: '1 !important',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              flexDirection: 'row'
            }}
          >
            <Button
              variant="contained"
              sx={{
                mx: 2,
                px: 3,
                fontWeight: 400,
                width: '7.75rem'
              }}
              color="secondary"
              onClick={() => {
                navigate('/home/token-dashboard');
              }}
            >
              Close
            </Button>
          </CardContent>
        </Card>
      </Box> */}
    </Container>
  );
};

export default PaymentTxn;
