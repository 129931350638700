import { Container, Card, Box, Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import React from 'react';

import Page from 'components/Page';
import Breadcrumbs from 'components/Breadcrumbs';
import Distribution from 'components/CreateToken/Distribution';
import Restrictions from 'components/CreateToken/Restrictions';

const ManageCriteria = () => {
  const [value, setValue] = React.useState('1');
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Page title="Token Rule Management">
        <Container sx={{ my: 2 }}>
          <Box sx={{ my: 2 }}>
            <Breadcrumbs pageHead="Token Rule Management" />
          </Box>
          <Card>
            <Box sx={{ width: '100%', typography: 'body1' }}>
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <TabList onChange={handleChange} aria-label="lab API tabs example">
                    <Tab label="Distribution Criteria" value="1" />
                    <Tab label="Transfer Restrictions" value="2" />
                  </TabList>
                </Box>
                <TabPanel value="1">
                  <Distribution />
                </TabPanel>
                <TabPanel value="2">
                  <Restrictions />
                </TabPanel>
              </TabContext>
            </Box>
          </Card>
        </Container>
      </Page>
    </>
  );
};

export default ManageCriteria;
