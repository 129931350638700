import {
  Container,
  Card,
  Grid,
  Box,
  CardContent,
  Typography,
  TextField,
  FormLabel,
  Button,
  Divider,
  FormControl,
  Select,
  MenuItem,
  Alert,
  FormHelperText
} from '@mui/material';
import Page from 'components/Page';
import React, { useState } from 'react';
import Breadcrumbs from 'components/Breadcrumbs';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { useCoreTableState } from 'state/useCoreTableState';
import { xdcRegex } from 'helpers/regex';
import { useBusinessEntityState, useHttpApi, useWeb3, useAppState } from 'state';
import { ethToXdcAddress, toChecksumAddress, xdcToEthAddress } from 'helpers/web3';
import { titleCase } from 'helpers/text';
import { useNavigate } from 'react-router-dom';

const getRandomHash = () => {
  const characters = 'abcdef1234567890';
  const lenString = 64;
  let xinfin = '';
  for (let i = 0; i < lenString; i += 1) {
    const rnum = Math.floor(Math.random() * characters.length);
    xinfin += characters.substring(rnum, rnum + 1);
  }
  return `0x${xinfin}`;
};

const AddCryptoTxn = () => {
  const [error, setError] = useState('');

  const { account, sendXdc } = useWeb3();
  const { createCryptoAssetTransaction } = useHttpApi();
  const {
    onChainIssuers: issuers,
    // onChainCustodians: custodians,
    onChainKycProviders: kycProviders,
    onChainInsurers: insurers
  } = useBusinessEntityState();

  const { enqueueSnackbar } = useSnackbar();
  const { throwErrorMessage } = useAppState();

  const navigate = useNavigate();

  const AddCryptoTxnSchema = Yup.object().shape({
    select_entity: Yup.string().required('Entity is Required'),
    transaction_amount: Yup.string().required('Enter Trasaction Amount'),
    recipient_xinfin_address: Yup.string().required('Enter Recipient XDC Address'),
    transaction_type: Yup.string().required('Transaction Type is Required')
  });

  const formik = useFormik({
    initialValues: {
      select_entity: '',
      transaction_amount: '',
      recipient_xinfin_address: '',
      xinfin_address: '',
      transaction_type: ''
    },
    validationSchema: AddCryptoTxnSchema,
    onSubmit: async (data) => {
      // console.log('data', data);
      try {
        data.recipient_xinfin_address = xdcToEthAddress(data.recipient_xinfin_address);
        data.recipient_xinfin_address = toChecksumAddress(data.recipient_xinfin_address);

        data.sender_address = xdcToEthAddress(account);
        data.sender_address = toChecksumAddress(account);

        console.log('values submit', data);

        // data.transaction_hash = res;

        const res = await sendXdc(
          xdcToEthAddress(data.recipient_xinfin_address),
          data.transaction_amount.toString()
        );
        if (res) {
          console.log(res);
          data.transaction_hash = res;
          const djangores = await createCryptoAssetTransaction(data);
          console.log('djangores', djangores);
          console.log('end');
          enqueueSnackbar('Transaction Created Successfully', { variant: 'success' });
          navigate('/masterdata/crypto-asset-txn');
        }
      } catch (e) {
        console.log('e', e);
        // enqueueSnackbar('Something Went Wrong, Please Try again later', { variant: 'error' });
        throwErrorMessage(e);
      }
    }
  });
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;
  return (
    <Page title="Add Crypto Transaction - Master Data">
      <Container sx={{ my: 2 }}>
        <Box sx={{ my: 2 }}>
          <Breadcrumbs aria-label="breadcrumb" pageHead="Crypto Asset Transaction" />
        </Box>
        <Card>
          <Container>
            <Typography
              sx={{
                fontSize: '1.125rem',
                fontWeight: 'bold',
                mt: 4,
                mb: 1,
                ml: 4
              }}
            >
              Crypto Fees Payment
            </Typography>
            <Divider
              sx={{
                width: '100%',
                backgroundColor: '#f5f8fa',
                border: '2px solid #f5f8fa'
              }}
            />
            <FormikProvider value={formik}>
              <Form autoComplete="off" onSubmit={handleSubmit}>
                {error && (
                  <Box mb={4}>
                    <Alert
                      severity="error"
                      sx={{
                        textTransform: 'capitalize'
                      }}
                    >
                      {error}
                    </Alert>
                  </Box>
                )}

                {/* refer design on implementation  */}
                {/* whether to use dropdown or textfield */}

                <CardContent sx={{ m: 2 }}>
                  <Grid container spacing={3} sx={{ width: '100%' }}>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <FormLabel>Select Entity</FormLabel>
                      <FormControl size="small" variant="outlined" fullWidth sx={{ mt: 1.5 }}>
                        <Select
                          displayEmpty
                          inputProps={{ 'aria-label': 'Without label' }}
                          {...getFieldProps('select_entity')}
                          error={Boolean(touched.select_entity && errors.select_entity)}
                          helperText={touched.select_entity && errors.select_entity}
                        >
                          <MenuItem key="ISSUER" value="ISSUER">
                            Issuer
                          </MenuItem>
                          <MenuItem key="CREDIT_INSURER" value="CREDIT_INSURER">
                            Credit Insurer
                          </MenuItem>
                          <MenuItem key="CUSTODIAN" value="CUSTODIAN">
                            Custodian
                          </MenuItem>
                          <MenuItem KEY="KYC_PROVIDER" value="KYC_PROVIDER">
                            KYC Provider
                          </MenuItem>
                        </Select>
                        <FormHelperText sx={{ color: '#d32f2f' }}>
                          {touched.select_entity && errors.select_entity}
                        </FormHelperText>
                      </FormControl>
                    </Grid>

                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <FormLabel>Select {titleCase(values.select_entity)}</FormLabel>
                      <FormControl size="small" variant="outlined" fullWidth sx={{ mt: 1.5 }}>
                        <Select
                          displayEmpty
                          inputProps={{ 'aria-label': 'Without label' }}
                          {...getFieldProps('recipient_xinfin_address')}
                          error={Boolean(
                            touched.recipient_xinfin_address && errors.recipient_xinfin_address
                          )}
                          helperText={
                            touched.recipient_xinfin_address && errors.recipient_xinfin_address
                          }
                        >
                          {values.select_entity === 'ISSUER' &&
                            issuers.map((issuer, index) => {
                              return (
                                <MenuItem
                                  key={issuer.primary_issuer_xinfin_address}
                                  value={issuer.primary_issuer_xinfin_address}
                                >
                                  {issuer.issuer_name}
                                </MenuItem>
                              );
                            })}
                          {/* {values.select_entity === 'CUSTODIAN' &&
                            custodians.map((custodian, index) => {
                              return (
                                <MenuItem
                                  key={custodian.primary_custodian_xinfin_address}
                                  value={custodian.primary_custodian_xinfin_address}
                                >
                                  {custodian.custodian_name}
                                </MenuItem>
                              );
                            })} */}
                          {values.select_entity === 'KYC_PROVIDER' &&
                            kycProviders.map((kyc, index) => {
                              return (
                                <MenuItem
                                  key={kyc.primary_kycprovider_xinfin_address}
                                  value={kyc.primary_kycprovider_xinfin_address}
                                >
                                  {kyc.kyc_provider_name}
                                </MenuItem>
                              );
                            })}
                          {values.select_entity === 'CREDIT_INSURER' &&
                            insurers.map((insurer, index) => {
                              return (
                                <MenuItem
                                  key={insurer.primary_insurer_xinfin_address}
                                  value={insurer.primary_insurer_xinfin_address}
                                >
                                  {insurer.insurer_name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                        <FormHelperText sx={{ color: '#d32f2f' }}>
                          {touched.recipient_xinfin_address && errors.recipient_xinfin_address}
                        </FormHelperText>
                      </FormControl>
                    </Grid>

                    {/* <Grid item lg={6} md={6} sm={12} xs={12}>
                      <FormLabel>Transaction Currency Code</FormLabel>

                      <FormControl size="small" variant="outlined" fullWidth sx={{ mt: 1.5 }}>
                        <Select
                          displayEmpty
                          inputProps={{ 'aria-label': 'Without label' }}
                          {...getFieldProps('select_entity')}
                          error={Boolean(
                            touched.select_entity && errors.select_entity
                          )}
                          helperText={
                            touched.select_entity && errors.select_entity
                          }
                        >
                          {currencies &&
                            currencies.map((currency, index) => {
                              return (
                                <MenuItem
                                  key={currency.currency_code}
                                  value={currency.currency_code}
                                >
                                  {currency.currency_code} | {currency.currency_name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                        <FormHelperText sx={{ color: '#d32f2f' }}>
                          {touched.select_entity && errors.select_entity}
                        </FormHelperText>
                      </FormControl>
                    </Grid> */}

                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <FormLabel>Transaction Type</FormLabel>
                      <FormControl size="small" variant="outlined" fullWidth sx={{ mt: 1.5 }}>
                        <Select
                          displayEmpty
                          inputProps={{ 'aria-label': 'Without label' }}
                          {...getFieldProps('transaction_type')}
                          error={Boolean(touched.transaction_type && errors.transaction_type)}
                          helperText={touched.transaction_type && errors.transaction_type}
                        >
                          <MenuItem key="FEES_PAYMENT" value="FEES_PAYMENT">
                            Fees Payment
                          </MenuItem>
                        </Select>
                        <FormHelperText sx={{ color: '#d32f2f' }}>
                          {touched.transaction_type && errors.transaction_type}
                        </FormHelperText>
                      </FormControl>
                    </Grid>

                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <FormLabel>Transaction Amount</FormLabel>
                      <TextField
                        sx={{ mt: 1.5 }}
                        fullWidth
                        size="small"
                        autoComplete="off"
                        type="number"
                        // placeholder="Enter Transaction Amount"
                        {...getFieldProps('transaction_amount')}
                        error={Boolean(touched.transaction_amount && errors.transaction_amount)}
                        helperText={touched.transaction_amount && errors.transaction_amount}
                      />
                    </Grid>
                    {/* TODO:ADD GET token_id */}
                    {/* <Grid item lg={6} md={6} xs={12}>
                      <FormLabel>Token id</FormLabel>
                      <FormControl size="small" variant="outlined" fullWidth sx={{ mt: 1.5 }}>
                        <Select
                          displayEmpty
                          inputProps={{ 'aria-label': 'Without label' }}
                          {...getFieldProps('token_id')}
                          error={Boolean(touched.token_id && errors.token_id)}
                          helperText={touched.token_id && errors.token_id}
                        >
                        
                          {currencies &&
                            currencies.map((currency, index) => {
                              return (
                                <MenuItem
                                  key={currency.currency_code}
                                  value={currency.currency_code}
                                >
                                  {currency.currency_code} | {currency.currency_name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                        <FormHelperText sx={{ color: '#d32f2f' }}>
                          {touched.token_id && errors.token_id}
                        </FormHelperText>
                      </FormControl>
                    </Grid> */}

                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <FormLabel>XinFin Address</FormLabel>
                      <TextField
                        sx={{ mt: 1.5 }}
                        fullWidth
                        type="text"
                        size="small"
                        value={ethToXdcAddress(values.recipient_xinfin_address) || ''}
                        inputProps={{ readOnly: true }}
                        // placeholder="xinfin address"
                        // {...getFieldProps('xinfin_address')}
                        error={Boolean(touched.xinfin_address && errors.xinfin_address)}
                        helperText={touched.xinfin_address && errors.xinfin_address}
                      />
                    </Grid>
                    {/* TODO:ADD GET token_subscription_id */}
                    {/* <Grid item lg={6} md={6} sm={12} xs={12}>
                      <FormLabel>Token Subscription id</FormLabel>
                      <FormControl size="small" variant="outlined" fullWidth sx={{ mt: 1.5 }}>
                        <Select
                          displayEmpty
                          inputProps={{ 'aria-label': 'Without label' }}
                          {...getFieldProps('token_subscription_id')}
                          error={Boolean(
                            touched.token_subscription_id && errors.token_subscription_id
                          )}
                          helperText={touched.token_subscription_id && errors.token_subscription_id}
                        >
                          {currencies &&
                            currencies.map((currency, index) => {
                              return (
                                <MenuItem
                                  key={currency.currency_code}
                                  value={currency.currency_code}
                                >
                                  {currency.currency_code} | {currency.currency_name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                        <FormHelperText sx={{ color: '#d32f2f' }}>
                          {touched.token_subscription_id && errors.token_subscription_id}
                        </FormHelperText>
                      </FormControl>
                    </Grid> */}
                  </Grid>

                  <Box
                    sx={{
                      mt: 4,
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-end'
                    }}
                  >
                    <Button
                      sx={{
                        margin: 1,
                        borderColor: '#24ABDF',
                        px: 3,
                        width: '7.75rem',
                        height: '2.5rem'
                      }}
                      variant="outlined"
                      color="secondary"
                    >
                      Cancel
                    </Button>
                    <LoadingButton
                      loadingPosition="start"
                      type="submit"
                      loading={isSubmitting}
                      variant="gradient"
                      sx={{ margin: 1, px: 3, width: '7.75rem', height: '2.5rem' }}
                    >
                      Pay
                    </LoadingButton>
                  </Box>
                </CardContent>
              </Form>
            </FormikProvider>
          </Container>
        </Card>
      </Container>
    </Page>
  );
};

export default AddCryptoTxn;
