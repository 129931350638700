import { Box, Card, CardContent, Container, Divider, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useBusinessEntityState, useHttpApi } from 'state';
import { getCurrencySymbol } from 'helpers/currency';
import Setting from 'assets/images/setting.svg';

const LineInfo = ({ infoKey, infoValue, sx }) => {
  return (
    <Box sx={sx}>
      <Divider />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <Typography variant="p" sx={{ fontWeight: 'bold' }}>
          {infoKey}
        </Typography>
        <Typography variant="p">{infoValue}</Typography>
      </Box>
    </Box>
  );
};

const PoolTranch = () => {
  const { getPoolByPoolAddress, getAssetsByPoolAddress } = useHttpApi();
  const { currentSubsToken, poolDetails, poolAssetDetails } = useBusinessEntityState();

  return (
    <>
      <Card sx={{ mt: 4 }}>
        <CardContent sx={{ p: 4 }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Box
                component="img"
                src={Setting}
                sx={{ height: '1.2rem', width: '1.2rem', objectFit: 'contain', mr: 2 }}
              />
              <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                Senior Tranche
              </Typography>
            </Box>
            <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
              {poolDetails.senior_tranche} USD
            </Typography>
          </Box>
          <Typography variant="h7" sx={{ fontWeight: '', color: 'gray' }}>
            USXD token — Lower risk, stable return
          </Typography>
          <LineInfo
            sx={{ mt: 2 }}
            infoKey="Token Price"
            infoValue={`${getCurrencySymbol(currentSubsToken.pegged_currency)} ${
              currentSubsToken.token_nav
            }`}
          />
          <LineInfo infoKey="Senior Yield (30days APY)" infoValue={`${poolDetails.senior_apy}%`} />
        </CardContent>
      </Card>
      <Card sx={{ mt: 4 }}>
        <CardContent sx={{ p: 4 }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Box
                component="img"
                src={Setting}
                sx={{ height: '1.2rem', width: '1.2rem', objectFit: 'contain', mr: 2 }}
              />
              <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                Junior Tranche
              </Typography>
            </Box>
            <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
              {poolDetails.junior_tranche} USD
            </Typography>
          </Box>
          <Typography variant="h7" sx={{ fontWeight: '', color: 'gray' }}>
            USXD token — Higher risk, variable return
          </Typography>
          <LineInfo
            sx={{ mt: 2 }}
            infoKey="Token Price"
            infoValue={`${getCurrencySymbol(currentSubsToken.pegged_currency)} ${poolDetails.nav}`}
          />

          <LineInfo infoKey="Junior Yield (30days APY)" infoValue={`${poolDetails.junior_apy} %`} />
        </CardContent>
      </Card>
    </>
  );
};

export default PoolTranch;
