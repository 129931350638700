/* eslint-disable react-hooks/exhaustive-deps */
import {
  Container,
  Card,
  Grid,
  CardContent,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  TextField,
  FormLabel,
  Checkbox,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Select,
  FormControl,
  FormHelperText,
  Divider,
  IconButton,
  InputAdornment,
  Box,
  Alert,
  Button
} from '@mui/material';
import { DesktopDatePicker, LoadingButton, LocalizationProvider } from '@mui/lab';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Page from 'components/Page';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import Breadcrumbs from 'components/Breadcrumbs';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { useCoreTableState } from 'state/useCoreTableState';
import {
  useAppState,
  useAuthStateShared,
  useBusinessEntityState,
  useHttpApi,
  useWeb3
} from 'state';
import { useSnackbar } from 'notistack';
import DateAdapter from '@mui/lab/AdapterDateFns';
import {
  format,
  addDays,
  differenceInMonths,
  isAfter,
  isBefore,
  addWeeks,
  addMonths,
  addYears
} from 'date-fns';
import { useNavigate } from 'react-router';
import FocusError from 'components/FocusError';
import { ceil, floor } from 'lodash';

import { ethToXdcAddress, xdcToEthAddress } from 'helpers/web3';
import axios from 'axios';
import AddressFieldTools from 'components/AddressFieldTools';
import { CURRENCY_URL, CURRENCY_API_KEY } from 'config';
import { parseTokenValue, roundToNearest100 } from 'helpers/numbers';
import CreateTokenSchema from 'components/issuerDashboard/TokenValidationSchema';
import AccordionLayout from 'helpers/AccordionLayout';

const CreateToken = () => {
  const {
    issueType,
    issueCategories,
    issueSubCategories,
    paymentBasis,
    paymentFreq,
    interestType,
    interestCalcMethod,
    accrualFreq,
    paymentTokens,
    collateralProvider,
    paymentTokenCurrencies,
    defaultDataAsPerIssueType,
    defaultTokenValues,
    paymentLegs
  } = useCoreTableState();
  const {
    originators,
    onChainLiquidityPools: pools,
    issuerInvestorMapByIssuerId,
    fetchIssuerInsurerMappingByIssuerId,
    fetchOriginator
  } = useBusinessEntityState();

  const { user, entity } = useAuthStateShared();
  const { getBalanceOf } = useWeb3();
  const [tokenSubCategory, setTokenSubCategory] = useState([]);
  const [tokenTypes, setTokenTypes] = useState([]);
  const [paymentTokensByCurrency, setPaymentTokensByCurrency] = useState(paymentTokens);
  const [readPaymentFrequency, setReadPaymentFrequency] = useState(true);
  const [readInterestType, setReadInterestType] = useState(true);
  const [readAccrualFrequency, setAccrualFrequency] = useState(true);
  const [ifProxyToken, setIfProxyToken] = useState(false);

  const { throwErrorMessage } = useAppState();
  const { createToken, getLiquidityPoolsByIssuerId, getPaymentTokenByCurrency } = useHttpApi();
  const [error, setError] = useState('');

  const [XDCValue, setXDCValue] = useState('');
  const contractDoc = useRef(null);

  const [liquidityPools, setLiquidityPools] = useState();

  const [selectedPool, setSelectedPool] = useState(null);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(async () => {
    _fetchLiquidityPool();
  }, []);

  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const tmr = new Date() + 1;

  const issueDatehandleChange = (newDate) => {
    try {
      formik.setFieldValue('token_create_date', format(newDate, 'yyyy-MM-dd'));
    } catch (err) {
      console.log(err);
    }
  };

  const lockinTillDatehandleChange = (newDate) => {
    try {
      formik.setFieldValue('lockin_till', format(newDate, 'yyyy-MM-dd'));
    } catch (err) {
      console.log(err);
    }
  };

  const finalMaturityDatehandleChange = (newDate) => {
    try {
      formik.setFieldValue('final_maturity_date', format(newDate, 'yyyy-MM-dd'));
    } catch (err) {
      console.log(err);
    }
  };

  const _fetchLiquidityPool = async () => {
    const _liquidityPools = await getLiquidityPoolsByIssuerId(entity?.issuer_id);
    setLiquidityPools(_liquidityPools);
    console.log('pools', _liquidityPools);
  };

  const _setSettlementaddressAsUser = () => {
    if (user?.parent_xinfin_account) {
      formik.setFieldValue(
        'xinfin_setl_account',
        ethToXdcAddress(user?.parent_xinfin_account?.xinfin_account_address)
      );
    } else {
      formik.setFieldValue('xinfin_setl_account', null);
    }
  };

  // Formik
  const formik = useFormik({
    validate: (values) => {
      const errors = {};

      if (values.contract_doc_hash) {
        if (contractDoc && contractDoc.current.files[0].size > 20971520) {
          errors.contract_doc_hash = 'File size greater than 20MB';
        }
      }

      if (!isAfter(new Date(values.final_maturity_date), new Date(values.token_create_date))) {
        errors.final_maturity_date =
          'Token Closure Date should be greater than Token Issue Start Date';
      }

      if (values.minimum_subscription > values.total_supply) {
        errors.minimum_subscription = 'Minimum Subscription should be less than Total Supply';
      }
      return errors;
    },
    initialValues: {
      token_name: '',
      token_symbol: '',
      total_supply: '',
      token_value: '',
      token_type: 'DIRECT_LENDING',
      liquidity_pool: '',
      issue_size: '',
      allow_early_redemption: false,
      minimum_subscription: defaultTokenValues.minimum_subscription,
      token_issue_interest_percent: '',
      token_issue_price: '',
      token_redemption_price: '',
      token_create_date: format(Date.now(), 'yyyy-MM-dd'),
      application_processing_time: defaultTokenValues.application_processing_time,
      final_maturity_date: format(addMonths(Date.now(), 1), 'yyyy-MM-dd'),
      lockin_till: '',
      tenure_months: defaultTokenValues.tenure_months,
      token_nav: '',
      use_of_proceeds: 'REAL ESTATE PROJECT FINANCE',
      reference_assets: 'REAL ESTATE PROJECT FINANCE',
      contract_doc_hash: '',
      issuer_id: '',
      originator_id: '',
      kyc_provider_id: entity?.kyc_provider,
      token_currency: defaultTokenValues.token_currency,
      issue_type_id: '',
      issue_category_id: '',
      issue_sub_category_id: '',
      payment_frequency_id: defaultTokenValues.payment_frequency_id,
      payment_basis_id: defaultTokenValues.payment_basis_id,
      interest_calc_method_id: defaultTokenValues.interest_calc_method_id,
      interest_type_id: defaultTokenValues.interest_type_id,
      accural_frequncy_id: defaultTokenValues.accural_frequncy_id,
      token_status_id: '',
      collateral_per_token: '',
      collateral_provider: '',
      collateral_provider_entity: '',
      payment_token_id: '',
      is_collateralized: '',

      insurer_contri: '',
      token_issue_desc_percent: '',
      issuer_contri: '',
      xinfin_setl_account: '',
      bank_interest: '0',
      interest_accrual_start_date: '',

      // bond fields:
      bond_issuer: '',
      guarantor: '',
      announcement_date: '',
      issue_date: '',
      maturity_date: '',
      years_to_maturity_to_next_call: '',
      issue_to_reoffer_price: '',
      issue_to_reoffer_yield: '',
      coupon_type: '',
      annual_coupon_rate: '',
      coupon_frequency: '',
      seniority: '',
      exchange_listed: '',
      reference_rate: '',
      isin: '',
      cusip: '',
      bond_currency: '',
      total_issue_Size: '',
      min_investment_quantity_nominal: '',
      incremental_quantity_nominal: '',
      bond_registration: '',
      bond_type: '',
      bond_sector: '',
      bond_sub_sector: '',
      issuer_credit_rating: '',
      bond_credit_rating: '',
      shariah_compliant: '',
      sukuk_investing: ''
    },
    validationSchema: CreateTokenSchema,
    onSubmit: async (data, { resetForm, setErrors }) => {
      try {
        console.log('check', data);
        const contractDocFile = contractDoc.current.files[0];
        data.kyc_provider_id = entity?.kyc_provider;
        data.contract_doc_hash = contractDocFile;
        data.token_currency = data.token_currency.id;
        data.token_currency_rate = Number(currencyRate).toFixed(6);
        // if (data.shariah_compliant){
        //   data.shariah_compliant = data.shariah_compliant === 'True';
        // }

        if (data?.token_type === 'DIRECT_LENDING') {
          delete data.liquidity_pool;
        }

        if (data?.issue_type_id?.issue_calc_type === 'INTEREST') {
          delete data.token_issue_desc_percent;
        }

        if (data?.payment_leg_id?.payment_leg_name === 'Cash') {
          delete data.payment_token_id;
          delete data.swap_ratio_issue;
          delete data.swap_ratio_redeem;
          delete data.is_collateralized;
          // delete data.collateral_token;
          delete data.collateral_per_token;
          delete data.collateral_provider;
          delete data.collateral_provider_entity;
          delete data.insurer_contri;
          delete data.issuer_contri;
          // delete data.token_currency_rate;
        } else if (
          data.payment_leg_id.payment_leg_name === 'Stable Coin' &&
          !data.is_collateralized
        ) {
          delete data.allow_early_redemption;
          // delete data.collateral_token;
          delete data.collateral_per_token;
          delete data.collateral_provider;
          delete data.collateral_provider_entity;
          delete data.insurer_contri;
          delete data.issuer_contri;
        }
        if (data.payment_leg_id.payment_leg_name === 'Stable Coin') {
          delete data.allow_early_redemption;
        }

        data.token_create_date = format(new Date(data.token_create_date), 'yyyy-MM-dd');
        data.final_maturity_date = format(new Date(data.final_maturity_date), 'yyyy-MM-dd');
        data.interest_accrual_start_date = format(new Date(data.final_maturity_date), 'yyyy-MM-dd');
        // data.maturity_date = format(new Date(data.maturity_date), 'yyyy-MM-dd');
        // console.log(data.announcement_date, "checkkkkkkkkk")
        // data.announcement_date = format(new Date(data.announcement_date), 'yyyy-MM-dd');
        // data.issue_date = format(new Date(data.issue_date), 'yyyy-MM-dd');
        if (data.collateral_provider) {
          if (data.collateral_provider.collateral_provider_name === 'Insurer') {
            data.collateral_provider_entity = data.collateral_provider_entity.insurer_id;
          }
          data.collateral_provider = data.collateral_provider.id;
        }
        // if (
        //   !(
        //     data.issue_type_id.issue_calc_type !== 'NAV' ||
        //     (data.issue_type_id.issue_calc_type === 'NAV' &&
        //       data.issue_type_id.issue_type_name === 'Pool Investment')
        //   )
        // ) {
        //   data.token_issue_interest_percent = 0;
        // }

        data.xinfin_setl_account = xdcToEthAddress(data.xinfin_setl_account);
        data.issue_type_id = data.issue_type_id.id;

        console.log(JSON.stringify(data, null, ' '));
        await createToken(data);
        formik.setFieldValue('contract_doc_hash', '');
        resetForm();

        navigate('/home');
        enqueueSnackbar('Token created successfully', {
          variant: 'success'
        });
      } catch (e) {
        formik.setFieldValue('contract_doc_hash', '');
        throwErrorMessage(e);
      }
    }
  });

  const { errors, touched, values, handleSubmit, getFieldProps } = formik;
  console.log('🚀 ~ CreateToken ~ errors:', errors);
  console.log('ENTITYYY VALUES KYCCCCCCCCC', formik.values.kyc_provider_id);
  const [payment, setPayment] = useState([]);
  const [collateralProviders, setCollateralProviders] = useState({
    id: '',
    collateral_provider_name: '',
    collateral_provider_desc: ''
  });
  const [collateralAcc, setCollateralAcc] = useState({
    insurer_id: '',
    insurer_xinfin_address: ''
  });
  const [paymentLeg, setPaymentLeg] = useState({
    id: '',
    payment_leg_name: '',
    payment_leg_desc: ''
  });
  const [issueTypes, setissueTypes] = useState({
    id: '',
    issue_calc_type: '',
    issue_sub_category_id: '',
    issue_type_desc: '',
    issue_type_name: ''
  });
  const [currencyRate, setCurrencyRate] = useState(1);

  useEffect(() => {
    if (!paymentLegs || !paymentTokenCurrencies || !paymentTokens) return;
    const _stablepaymentLeg = paymentLegs.find((item) => item?.payment_leg_name === 'Stable Coin');
    console.log('paymentLegs', _stablepaymentLeg);
    formik.setFieldValue('payment_leg_id', _stablepaymentLeg);

    const _usdpaymentToken = paymentTokenCurrencies.find((item) => item?.currency_code === 'USD');
    formik.setFieldValue('token_currency', _usdpaymentToken);
    GetPaymentTokenByCurrency(values?.token_currency?.currency_code);
  }, [paymentTokenCurrencies, paymentTokens, defaultTokenValues, paymentLegs]);

  useEffect(() => {
    if (!entity?.issuer_id) return;
    fetchIssuerInsurerMappingByIssuerId(entity.issuer_id);
    formik.setFieldValue('issuer_id', entity.issuer_id);
  }, [entity]);

  // use effect for handling issue categories and other fields
  useEffect(() => {
    if (values.token_type !== 'PROXY_TOKEN') {
      setTokenSubCategory(issueSubCategories);
      setTokenTypes(issueType);
      formik.setFieldValue('issue_category_id', '');
      formik.setFieldValue('issue_sub_category_id', '');
      setIfProxyToken(false);
    }
  }, [issueCategories, issueSubCategories, issueType, values.token_type]);

  useEffect(() => {
    if (values.token_type !== 'PROXY_TOKEN') {
      const _subCategory = issueSubCategories.filter(
        (item) => item.issue_category_id === values.issue_category_id
      );
      setTokenSubCategory(_subCategory);
      formik.setFieldValue('issue_sub_category_id', '');
    }
  }, [values.issue_category_id]);

  useEffect(() => {
    if (values.token_type !== 'PROXY_TOKEN') {
      const _type = issueType.filter(
        (item) => item.issue_sub_category_id === values.issue_sub_category_id
      );
      setTokenTypes(_type);
      _setSettlementaddressAsUser();
    }
  }, [values.issue_sub_category_id]);

  useEffect(() => {
    if (values.token_type === 'PROXY_TOKEN') {
      formik.setFieldValue(
        'issue_category_id',
        defaultDataAsPerIssueType?.proxy_issue_category_data[0]?.id
      );

      formik.setFieldValue(
        'issue_sub_category_id',
        defaultDataAsPerIssueType?.proxy_issue_sub_category_data[0]?.id
      );
      setTokenSubCategory(defaultDataAsPerIssueType?.proxy_issue_sub_category_data);
      formik.setFieldValue('issue_type_id', defaultDataAsPerIssueType?.proxy_issue_type_data[0]);
      setTokenTypes(defaultDataAsPerIssueType?.proxy_issue_type_data);
      setIfProxyToken(true);
      handleLiquidityPoolChange();
    }
  }, [values.token_type]);

  useEffect(() => {
    if (values.issue_type_id) {
      const _data = defaultDataAsPerIssueType.issue_type_data.filter(
        (value) => value.issue_calc_type === values.issue_type_id.issue_calc_type
      );
      console.log('data', _data);
      formik.setFieldValue('payment_basis_id', _data[0]?.payment_basis?.id);
      formik.setFieldValue('accural_frequncy_id', _data[0]?.accrual_frequency?.id);
      formik.setFieldValue('payment_frequency_id', _data[0]?.payment_frequency?.id);
      formik.setFieldValue('interest_type_id', _data[0]?.interest_type?.id);
      formik.setFieldValue('interest_calc_method_id', _data[0]?.interest_calc_method?.id);
      setAccrualFrequency(_data[0]?.accrual_frequency?.accrual_frequency_name_freeze);
      setReadInterestType(_data[0]?.interest_type?.interest_type_name_freeze);
      setReadPaymentFrequency(_data[0]?.payment_frequency?.payment_frequency_name_freeze);
    }
  }, [values.issue_type_id]);
  // calculate issue size
  useEffect(() => {
    formik.setFieldValue('issue_size', values.total_supply * values.token_value);
    // formik.setFieldValue('token_redemption_price', values.token_value);
    formik.setFieldValue('token_nav', values.token_value);
  }, [values.total_supply, values.token_value]);

  // const handleTokenValueChange = (e) => {
  //   formik.setFieldValue('token_redemption_price', values.token_value);
  //   formik.setFieldValue('token_nav', values.token_value);
  // };

  useEffect(() => {
    formik.setFieldValue(
      'token_issue_price',
      values.issue_type_id.issue_calc_type === 'DISCOUNT'
        ? ceil(values.token_value - (values.token_value / 100) * values.token_issue_desc_percent)
        : values.token_value
    );
  }, [values.token_value, values.token_issue_desc_percent, values.issue_type_id]);

  useEffect(() => {
    if (values.issue_type_id.issue_calc_type === 'DISCOUNT') {
      const interestRate =
        (((values.token_redemption_price - values.token_issue_price) *
          (365 / values.tenure_months)) /
          values.token_issue_price) *
        100;
      formik.setFieldValue('token_issue_interest_percent', interestRate.toFixed(2));
    }
  }, [
    values.tenure_months,
    values.token_issue_desc_percent,
    values.token_redemption_price,
    values.token_issue_price,
    issueTypes
  ]);

  useEffect(() => {
    CalculateTenure();
  }, [formik.values.token_create_date, formik.values.final_maturity_date]);

  const CalculateTenure = () => {
    if (formik.values.token_create_date && formik.values.final_maturity_date) {
      const tenure = Number(
        differenceInMonths(
          new Date(formik.values.final_maturity_date),
          new Date(formik.values.token_create_date)
        )
      );
      formik.setFieldValue('tenure_months', tenure);
    }
  };

  const GetPaymentTokenByCurrency = async (currencyCode) => {
    const paymentToken = await getPaymentTokenByCurrency(currencyCode);
    setPaymentTokensByCurrency(paymentToken);
    setPaymentObject(paymentToken[0]?.payment_token_id);
  };

  useEffect(() => {
    GetPaymentTokenByCurrency(values.token_currency?.currency_code);
    if (values?.token_currency?.currency_code) {
      setXDCValue(null);
      // eslint-disable-next-line
      (async function () {
        if (
          values?.token_currency.currency_code !== '' &&
          values?.token_currency.currency_code !== undefined
        ) {
          const IssueCurrency = values?.token_currency.currency_code.toLowerCase();
          const res = await axios.get(
            `https://api.coingecko.com/api/v3/simple/price?ids=xdce-crowd-sale&vs_currencies=${IssueCurrency}`
          );
          console.log('Coin Gecko Res', Object.values(res.data)[0][IssueCurrency]);
          setXDCValue(Object.values(res.data)[0][IssueCurrency]);
        }
      })();
    }
  }, [values?.token_currency]);

  useEffect(() => {
    if (values?.token_currency !== '' && payment?.backed_currency !== undefined) {
      const _currencyRate = async () => {
        try {
          console.log('Currency Code', values?.token_currency.currency_code);
          const res = await axios.get(
            `${CURRENCY_URL}api/v7/convert?q=${values?.token_currency.currency_code}_${payment?.backed_currency}&compact=ultra&apiKey=${CURRENCY_API_KEY}`
          );

          setCurrencyRate(Object.values(res.data)[0]);
        } catch (error) {
          setCurrencyRate(1); // set default value of 1 if there's an error
        }
      };
      _currencyRate();
    }
  }, [values?.token_currency, payment]);
  useEffect(() => {
    if (
      !values?.token_issue_price ||
      values?.token_issue_price === '' ||
      values?.token_issue_price === 0
    )
      return;

    formik.setFieldValue('swap_ratio_issue', (values?.token_issue_price * currencyRate).toFixed(2));
    formik.setFieldValue(
      'swap_ratio_redeem',
      (values?.token_redemption_price * currencyRate).toFixed(2)
    );

    const collateralPerToken = values?.token_value / XDCValue;
    const collateralPerTokenWithHairCut = Math.round(collateralPerToken + collateralPerToken * 0.5);
    console.table(collateralPerToken, collateralPerTokenWithHairCut);
    formik.setFieldValue('collateral_per_token', roundToNearest100(collateralPerTokenWithHairCut));
  }, [values.token_issue_price, values.token_redemption_price, currencyRate]);

  // useEffect(() => {
  //   if (values.issue_type_id.issue_type_name !== 'Pool Investment') return;
  //   if (
  //     !values.token_issue_price ||
  //     values.token_issue_price === '' ||
  //     values.token_issue_price === 0
  //   )
  //     return;

  //   const tokenIssueInterest = values.token_issue_interest_percent || 0;
  //   // formik.setFieldValue(
  //   //   'token_redemption_price',
  //   //   (values.token_issue_price + values.token_issue_price * (tokenIssueInterest / 100)).toFixed(0)
  //   // );
  // }, [
  //   values.token_issue_price,
  //   values.token_issue_interest_percent,
  //   values.issue_type_id,
  //   values.token_value
  // ]);

  useEffect(() => {
    if (!values.collateral_provider_entity) return;

    const issuerCollateral = ceil(
      (values.collateral_provider_entity.issuer_contri * values.collateral_per_token) / 100
    );
    const insurerCollateral = values.collateral_per_token - issuerCollateral;

    formik.setFieldValue('insurer_contri', insurerCollateral);
    formik.setFieldValue('issuer_contri', issuerCollateral);
  }, [values.collateral_provider_entity, values.collateral_per_token]);

  // Liquidity Pool
  const handleLiquidityPoolChange = () => {
    if (values?.liquidity_pool) {
      const res = liquidityPools.find((pool) => pool?.liquidity_pool_id === values?.liquidity_pool);
      setSelectedPool(res);
      formik.setFieldValue('xinfin_setl_account', res?.liquidity_pool_settlement_address);
      return res;
    }
  };

  const getPaymentTokenBalance = async (
    tokenAddr = payment?.contract_address,
    walletAddr = selectedPool?.liquidity_pool_xinfin_address
  ) => {
    const _balance = await getBalanceOf(tokenAddr, walletAddr);

    payment.balance = parseTokenValue(_balance, payment.decimals);
    setPayment(payment);
    return payment;
  };
  const navigate = useNavigate();

  useEffect(async () => {
    if (values.token_type === 'POOL_LENDING' && values.liquidity_pool) {
      if (payment && selectedPool) {
        const balance = await getBalanceOf(
          payment?.contract_address,
          selectedPool.liquidity_pool_xinfin_address
        );
        payment.balance = parseTokenValue(balance, payment?.decimals);
        setPayment(payment);
      }
    }
  }, [values.token_type, values.liquidity_pool, selectedPool, payment]);

  const setPaymentObject = async (value) => {
    if (value) {
      const _paymentToken = paymentTokens.find((token) => value === token?.payment_token_id);

      if (values.token_type === 'POOL_LENDING') {
        const balance = await getBalanceOf(
          _paymentToken?.contract_address,
          selectedPool?.liquidity_pool_xinfin_address
        );

        _paymentToken.balance = parseTokenValue(balance, _paymentToken?.decimals);
      }
      setPayment(_paymentToken);
      formik.setFieldValue('payment_token_id', value);
    }
  };

  useEffect(() => {
    if (values.payment_leg_id === undefined || values.payment_leg_id === null) {
      const _paymentLeg = paymentLegs.find((item) => item?.payment_leg_name === 'Cash');
      console.log('paymentLegssssss', _paymentLeg);
      if (_paymentLeg) {
        formik.setFieldValue('payment_leg_id', _paymentLeg);
      }
    }
  }, []);

  const handleTokenRedemptionValue = () => {
    if (values.token_value && values.token_issue_interest_percent && values.tenure_months) {
      return formik.setFieldValue(
        'token_redemption_price',
        Math.round(
          values.token_value +
            (values.token_value * values.token_issue_interest_percent * values.tenure_months) / 100
        )
      );
    } else if (values.token_value) {
      return formik.setFieldValue('token_redemption_price', Math.round(values.token_value));
    }
  };

  console.log('ENTITY', entity);

  const longTermBondCategoryId = issueCategories.find(category => category.issue_category_name === 'Long Term Bond')?.id;

  return (
    <>
      <Page title="Create Token">
        <Container sx={{ mt: 3 }}>
          <Breadcrumbs aria-label="breadcrumb" pageHead="Token Term Sheet" />
          <Card sx={{ mt: 2 }}>
            <CardContent sx={{ m: 4 }}>
              {error && (
                <Box mb={4}>
                  <Alert severity="error">{error}</Alert>
                </Box>
              )}
              <FormikProvider value={formik}>
                <Form autoComplete="off" onSubmit={handleSubmit}>
                  <AccordionLayout
                    defaultExpanded
                    title="Token Header"
                    content={
                      <Grid container spacing={3} sx={{ width: '100%' }}>
                        <Grid item lg={6} md={6} sm={12} xs={12} sx={{ pr: 1 }}>
                          <FormLabel>Issuer Name</FormLabel>
                          <FormControl size="small" variant="outlined" fullWidth sx={{ mt: 1.5 }}>
                            <Select
                              // defaultValue={issuerId}
                              IconComponent="none"
                              inputProps={{ readOnly: true }}
                              {...getFieldProps('issuer_id')}
                              error={Boolean(touched.issuer_id && errors.issuer_id)}
                              helperText={touched.issuer_id && errors.issuer_id}
                            >
                              {/* {issuers.map((issuer) => {
                                return (
                                  <MenuItem
                                    selected
                                    className="Mui-selected"
                                    key={issuer.issuer_id}
                                    value={issuer.issuer_id}
                                  >
                                    {issuer.issuer_name}
                                  </MenuItem>
                                );
                              })} */}
                              {entity?.issuer_id && (
                                <MenuItem
                                  selected
                                  className="Mui-selected"
                                  key={entity?.issuer_id}
                                  value={entity?.issuer_id}
                                >
                                  {entity?.issuer_name}
                                </MenuItem>
                              )}
                            </Select>

                            <FormHelperText sx={{ color: '#d32f2f' }}>
                              {touched.issuer_id && errors.issuer_id}
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12} sx={{ pl: 0.5 }}>
                          <FormLabel>Issue Currency</FormLabel>
                          <FormControl size="small" variant="outlined" fullWidth sx={{ mt: 1.5 }}>
                            <Select
                              // placeholder="Select Currency"
                              displayEmpty
                              inputProps={{ 'aria-label': 'Without label' }}
                              {...getFieldProps('token_currency')}
                              id="token_currency"
                              error={Boolean(touched.token_currency && errors.token_currency)}
                              helperText={touched.token_currency && errors.token_currency}
                            >
                              {paymentTokenCurrencies &&
                                paymentTokenCurrencies.map((currency) => {
                                  return (
                                    <MenuItem key={currency.id} value={currency}>
                                      {currency.currency_code} | {currency.currency_name}
                                    </MenuItem>
                                  );
                                })}
                            </Select>
                            <FormHelperText sx={{ color: '#d32f2f' }}>
                              {touched.token_currency && errors.token_currency}
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Token Name</FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            fullWidth
                            size="small"
                            autoComplete="off"
                            type="text"
                            // placeholder="Token Name"
                            id="token_name"
                            inputProps={{ maxLength: 50 }}
                            {...getFieldProps('token_name')}
                            error={Boolean(touched.token_name && errors.token_name)}
                            helperText={touched.token_name && errors.token_name}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Token Symbol</FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            fullWidth
                            size="small"
                            autoComplete="off"
                            type="text"
                            // placeholder="XDCYP"
                            {...getFieldProps('token_symbol')}
                            id="token_symbol"
                            inputProps={{ maxLength: 11 }}
                            error={Boolean(touched.token_symbol && errors.token_symbol)}
                            helperText={touched.token_symbol && errors.token_symbol}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Token Supply</FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            fullWidth
                            size="small"
                            autoComplete="off"
                            type="number"
                            // placeholder="100000000"
                            id="total_supply"
                            onWheel={(e) => {
                              e.target.blur();
                            }}
                            {...getFieldProps('total_supply')}
                            error={Boolean(touched.total_supply && errors.total_supply)}
                            helperText={touched.total_supply && errors.total_supply}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Token Value {formik.values.issuer_currency}</FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            fullWidth
                            size="small"
                            autoComplete="off"
                            type="number"
                            // placeholder="100000000"
                            id="token_value"
                            onWheel={(e) => {
                              e.target.blur();
                            }}
                            {...getFieldProps('token_value')}
                            onBlur={() => {
                              handleTokenRedemptionValue();
                            }}
                            error={Boolean(touched.token_value && errors.token_value)}
                            helperText={touched.token_value && errors.token_value}
                          />
                        </Grid>

                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>
                            Issue Size{' '}
                            {formik.values.token_currency
                              ? `(${formik.values.token_currency?.currency_code})`
                              : ''}
                          </FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            fullWidth
                            type="number"
                            size="small"
                            inputProps={{ readOnly: true }}
                            // placeholder="10000000"
                            {...getFieldProps('issue_size')}
                            error={Boolean(touched.issue_size && errors.issue_size)}
                            helperText={touched.issue_size && errors.issue_size}
                          />
                        </Grid>

                        {/* <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>
                            Token Type {formik.values.token_currency ? `(${formik.values.token_currency?.currency_code})` : ""}
                          </FormLabel>
                          <FormControl size="small" variant="outlined" fullWidth sx={{ mt: 1.5 }}>
                            <Select
                              displayEmpty
                              inputProps={{ 'aria-label': 'Without label' }}
                              {...getFieldProps('token_type')}
                              id="token_type"
                              onWheel={(e) => {
                                e.target.blur();
                              }}
                              error={Boolean(touched.token_type && errors.token_type)}
                              helperText={touched.token_type && errors.token_type}
                              onBlur={async (e) => {
                                if (e.target.value === 'DIRECT_LENDING') {
                                  _setSettlementaddressAsUser();
                                }
                              }}
                            >
                              <MenuItem key="DIRECT_LENDING" value="DIRECT_LENDING">
                                P2P Finance
                              </MenuItem>
                              <MenuItem key="POOL_LENDING" value="POOL_LENDING">
                                Pool Finance
                              </MenuItem>
                              <MenuItem key="PROXY_TOKEN" value="PROXY_TOKEN">
                                Pool Token
                              </MenuItem>
                            </Select>

                            <FormHelperText sx={{ color: '#d32f2f' }}>
                              {touched.token_type && errors.token_type}
                            </FormHelperText>
                          </FormControl>
                        </Grid> */}

                        {values.token_type === 'PROXY_TOKEN' && (
                          <Grid item lg={6} md={6} sm={12} xs={12}>
                            <FormLabel>Liquidity Pool</FormLabel>
                            <FormControl size="small" variant="outlined" fullWidth sx={{ mt: 1.5 }}>
                              <Select
                                displayEmpty
                                {...getFieldProps('liquidity_pool')}
                                // onWheel={(e) => {
                                //   e.target.blur();
                                // }}
                                onBlur={() => {
                                  const currPool = handleLiquidityPoolChange();
                                  if (payment !== '') {
                                    getPaymentTokenBalance(
                                      payment?.contract_address,
                                      currPool?.liquidity_pool_xinfin_address
                                    );
                                  }
                                }}
                                id="liquidity_pool"
                                error={Boolean(touched.liquidity_pool && errors.liquidity_pool)}
                                helperText={touched.liquidity_pool && errors.liquidity_pool}
                              >
                                {
                                  // .filter((pool) => pool.issuer_ids.includes(entity?.issuer_id))
                                  liquidityPools?.map((pool) => {
                                    return (
                                      !pool?.has_proxy_token && (
                                        <MenuItem
                                          key={pool.liquidity_pool_id}
                                          value={pool.liquidity_pool_id}
                                        >
                                          {pool.liquidity_pool_name}
                                        </MenuItem>
                                      )
                                    );
                                  })
                                }
                              </Select>

                              <FormHelperText sx={{ color: '#d32f2f' }}>
                                {touched.liquidity_pool && errors.liquidity_pool}
                              </FormHelperText>
                              {values.token_type === 'PROXY_TOKEN' &&
                                (liquidityPools?.length === 0 ||
                                  liquidityPools?.filter((pool) => !pool.has_proxy_token).length ===
                                    0) && (
                                  <FormHelperText sx={{ color: '#d32f2f' }}>
                                    No Liquidity Pool Found!
                                  </FormHelperText>
                                )}
                            </FormControl>
                          </Grid>
                        )}

                        {values.token_type === 'POOL_LENDING' && (
                          <Grid item lg={6} md={6} sm={12} xs={12}>
                            <FormLabel>Liquidity Pool</FormLabel>
                            <FormControl size="small" variant="outlined" fullWidth sx={{ mt: 1.5 }}>
                              <Select
                                displayEmpty
                                {...getFieldProps('liquidity_pool')}
                                onBlur={() => {
                                  const currPool = handleLiquidityPoolChange();
                                  if (payment !== '') {
                                    getPaymentTokenBalance(
                                      payment?.contract_address,
                                      currPool?.liquidity_pool_xinfin_address
                                    );
                                  }
                                }}
                                id="liquidity_pool"
                                error={Boolean(touched.liquidity_pool && errors.liquidity_pool)}
                                helperText={touched.liquidity_pool && errors.liquidity_pool}
                              >
                                {liquidityPools
                                  // .filter((pool) => pool.issuer_ids.includes(entity?.issuer_id))
                                  ?.map((pool) => {
                                    return (
                                      <MenuItem
                                        key={pool.liquidity_pool_id}
                                        value={pool.liquidity_pool_id}
                                      >
                                        {pool.liquidity_pool_name}
                                      </MenuItem>
                                    );
                                  })}
                              </Select>

                              <FormHelperText sx={{ color: '#d32f2f' }}>
                                {touched.liquidity_pool && errors.liquidity_pool}
                              </FormHelperText>
                              {(values.token_type === 'POOL_LENDING' &&
                                liquidityPools?.length === 0) ||
                                (liquidityPools?.length === 0 && (
                                  <FormHelperText sx={{ color: '#d32f2f' }}>
                                    No Liquidity Pool Found!
                                  </FormHelperText>
                                ))}
                            </FormControl>
                          </Grid>
                        )}

                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Token Category</FormLabel>
                          <FormControl size="small" variant="outlined" fullWidth sx={{ mt: 1.5 }}>
                            <Select
                              displayEmpty
                              inputProps={{ readOnly: ifProxyToken }}
                              {...getFieldProps('issue_category_id')}
                              id="issue_category_id"
                              error={Boolean(touched.issue_category_id && errors.issue_category_id)}
                              helperText={touched.issue_category_id && errors.issue_category_id}
                            >
                              {issueCategories?.map((category) => {
                                return (
                                  <MenuItem key={category.id} value={category.id}>
                                    {category.issue_category_name === 'Long Term Bond'
                                      ? 'SAFI'
                                      : category.issue_category_name}
                                  </MenuItem>
                                );
                              })}
                            </Select>

                            <FormHelperText sx={{ color: '#d32f2f' }}>
                              {touched.issue_category_id && errors.issue_category_id}
                            </FormHelperText>
                          </FormControl>
                        </Grid>

                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Token Sub Category</FormLabel>
                          <FormControl size="small" variant="outlined" fullWidth sx={{ mt: 1.5 }}>
                            <Select
                              // placeholder="Select Currency"
                              displayEmpty
                              inputProps={{ readOnly: ifProxyToken }}
                              {...getFieldProps('issue_sub_category_id')}
                              id="issue_sub_category_id"
                              error={Boolean(
                                touched.issue_sub_category_id && errors.issue_sub_category_id
                              )}
                              helperText={
                                touched.issue_sub_category_id && errors.issue_sub_category_id
                              }
                            >
                              {tokenSubCategory &&
                                tokenSubCategory.map((issueSubCategory) => {
                                  return (
                                    <MenuItem key={issueSubCategory.id} value={issueSubCategory.id}>
                                      {issueSubCategory.issue_sub_category_name}
                                    </MenuItem>
                                  );
                                })}
                            </Select>
                            <FormHelperText sx={{ color: '#d32f2f' }}>
                              {touched.issue_sub_category_id && errors.issue_sub_category_id}
                            </FormHelperText>
                          </FormControl>
                        </Grid>

                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Issue Type</FormLabel>

                          <FormControl size="small" variant="outlined" fullWidth sx={{ mt: 1.5 }}>
                            <Select
                              // placeholder="Select Issue Type"
                              displayEmpty
                              inputProps={{ readOnly: ifProxyToken }}
                              {...getFieldProps('issue_type_id')}
                              id="issue_type_id"
                              error={Boolean(touched.issue_type_id && errors.issue_type_id)}
                              helperText={touched.issue_type_id && errors.issue_type_id}
                            >
                              {tokenTypes.map((issueType) => {
                                return (
                                  <MenuItem key={issueType.id} value={issueType}>
                                    {issueType.issue_type_name}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                            <FormHelperText sx={{ color: '#d32f2f' }}>
                              {touched.issue_type_id && errors.issue_type_id}
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                      </Grid>
                    }
                  />
                  <AccordionLayout
                    defaultExpanded
                    title="Primary Economics Terms"
                    content={
                      <Grid container spacing={3} sx={{ width: '100%' }}>
                        {values.issue_type_id.issue_calc_type === 'DISCOUNT' && (
                          <>
                            <Grid item lg={6} md={6} sm={12} xs={12}>
                              <FormLabel>Discount (% P.A)</FormLabel>
                              <TextField
                                sx={{ mt: 1.5 }}
                                // placeholder="5"
                                fullWidth
                                type="number"
                                size="small"
                                autoComplete="off"
                                onWheel={(e) => {
                                  e.target.blur();
                                }}
                                {...getFieldProps('token_issue_desc_percent')}
                                id="token_issue_desc_percent"
                                error={Boolean(
                                  touched.token_issue_desc_percent &&
                                    errors.token_issue_desc_percent
                                )}
                                helperText={
                                  touched.token_issue_desc_percent &&
                                  errors.token_issue_desc_percent
                                }
                              />
                            </Grid>
                          </>
                        )}
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Tenure (Years)</FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            // placeholder="0"
                            fullWidth
                            size="small"
                            autoComplete="off"
                            type="number"
                            onWheel={(e) => {
                              e.target.blur();
                            }}
                            // inputProps={{ readOnly: true }}
                            {...getFieldProps('tenure_months')}
                            onBlur={() => {
                              handleTokenRedemptionValue();
                            }}
                            id="tenure_months"
                            error={Boolean(touched.tenure_months && errors.tenure_months)}
                            helperText={touched.tenure_months && errors.tenure_months}
                          />
                        </Grid>
                        {(values.issue_type_id.issue_calc_type !== 'NAV' ||
                          (values.issue_type_id.issue_calc_type === 'NAV' &&
                            values.issue_type_id.issue_type_name === 'Pool Investment') ||
                          (values.issue_type_id.issue_calc_type === 'NAV' &&
                            values.issue_type_id.issue_type_name === 'Project Finance')) && (
                          <Grid item lg={6} md={6} sm={12} xs={12}>
                            <FormLabel>Token Interest (% P.A)</FormLabel>
                            <TextField
                              sx={{ mt: 1.5 }}
                              inputProps={{
                                readOnly: values.issue_type_id.issue_calc_type === 'DISCOUNT'
                              }}
                              fullWidth
                              type="number"
                              size="small"
                              autoComplete="off"
                              onWheel={(e) => {
                                e.target.blur();
                              }}
                              {...getFieldProps('token_issue_interest_percent')}
                              onBlur={handleTokenRedemptionValue}
                              id="token_issue_interest_percent"
                              error={Boolean(
                                touched.token_issue_interest_percent &&
                                  errors.token_issue_interest_percent
                              )}
                              helperText={
                                touched.token_issue_interest_percent &&
                                errors.token_issue_interest_percent
                              }
                            />
                          </Grid>
                        )}
                        {/* <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Bank Interest (% P.A)</FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            inputProps={{
                              readOnly: values.issue_type_id.issue_calc_type === 'DISCOUNT'
                            }}
                            fullWidth
                            type="number"
                            size="small"
                            autoComplete="off"
                            onWheel={(e) => {
                              e.target.blur();
                            }}
                            {...getFieldProps('bank_interest')}
                            id="bank_interest"
                            error={Boolean(touched.bank_interest && errors.bank_interest)}
                            helperText={touched.bank_interest && errors.bank_interest}
                          />
                        </Grid> */}
                        <Grid item lg={6} md={6} sm={12} xs={12} sx={{ pr: 1 }}>
                          <FormLabel>Contract Document</FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            fullWidth
                            size="small"
                            autoComplete="off"
                            type="file"
                            inputRef={contractDoc}
                            inputProps={{ accept: '.pdf' }}
                            {...getFieldProps('contract_doc_hash')}
                            id="contract_doc_hash"
                            error={Boolean(touched.contract_doc_hash && errors.contract_doc_hash)}
                            helperText={touched.contract_doc_hash && errors.contract_doc_hash}
                          />
                          <Grid container sx={{ width: '100%', mt: 2 }}>
                            <Grid item lg={4} md={4} sm={4}>
                              <Typography
                                sx={{
                                  fontSize: '0.688rem',
                                  textAlign: 'left',
                                  color: '#161c2d'
                                }}
                              >
                                Maximum 20MB file size
                              </Typography>
                            </Grid>
                            <Grid item lg={8} md={8} sm={8}>
                              <Typography
                                sx={{
                                  fontSize: '0.688rem',
                                  textAlign: 'right',
                                  color: '#8e8ea7'
                                }}
                              >
                                Accepted File Type .pdf only
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Minimum Susbcription (Tokens)</FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            fullWidth
                            size="small"
                            type="number"
                            autoComplete="off"
                            onWheel={(e) => {
                              e.target.blur();
                            }}
                            // placeholder="1"
                            {...getFieldProps('minimum_subscription')}
                            id="minimum_subscription"
                            error={Boolean(
                              touched.minimum_subscription && errors.minimum_subscription
                            )}
                            helperText={touched.minimum_subscription && errors.minimum_subscription}
                          />
                        </Grid>

                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Token Issue Price {formik.values.issuer_currency}</FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            // placeholder="1000"
                            fullWidth
                            size="small"
                            autoComplete="off"
                            type="number"
                            onWheel={(e) => {
                              e.target.blur();
                            }}
                            {...getFieldProps('token_issue_price')}
                            error={Boolean(touched.token_issue_price && errors.token_issue_price)}
                            helperText={touched.token_issue_price && errors.token_issue_price}
                          />
                        </Grid>

                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>
                            Token Redemption Price {formik.values.issuer_currency}
                          </FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            fullWidth
                            size="small"
                            type="number"
                            onWheel={(e) => {
                              e.target.blur();
                            }}
                            inputProps={{
                              readOnly:
                                values.issue_type_id.issue_calc_type === 'NAV' &&
                                values.issue_type_id.issue_type_name === 'Pool Investment'
                            }}
                            autoComplete="off"
                            // placeholder="10000"
                            {...getFieldProps('token_redemption_price')}
                            error={Boolean(
                              touched.token_redemption_price && errors.token_redemption_price
                            )}
                            helperText={
                              touched.token_redemption_price && errors.token_redemption_price
                            }
                          />
                        </Grid>

                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Issue Start Date</FormLabel>
                          <LocalizationProvider dateAdapter={DateAdapter}>
                            <DesktopDatePicker
                              minDate={new Date()}
                              fullWidth
                              size="small"
                              inputFormat="MM-dd-yyyy"
                              sx={{ mt: 1.5 }}
                              value={formik.values.token_create_date}
                              onChange={issueDatehandleChange}
                              renderInput={(params) => (
                                <TextField
                                  sx={{ mt: 1.5 }}
                                  fullWidth
                                  size="small"
                                  {...params}
                                  {...getFieldProps('token_create_date')}
                                  id="token_create_date"
                                  error={Boolean(
                                    touched.token_create_date && errors.token_create_date
                                  )}
                                  helperText={touched.token_create_date && errors.token_create_date}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </Grid>

                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Issue Closure Date</FormLabel>
                          {/* MUI Date Fns date picker */}
                          <LocalizationProvider dateAdapter={DateAdapter}>
                            <DesktopDatePicker
                              minDate={new Date()}
                              fullWidth
                              size="small"
                              inputFormat="MM-dd-yyyy"
                              sx={{ mt: 1.5 }}
                              value={formik.values.final_maturity_date}
                              onChange={finalMaturityDatehandleChange}
                              renderInput={(params) => (
                                <TextField
                                  sx={{ mt: 1.5 }}
                                  fullWidth
                                  size="small"
                                  {...params}
                                  {...getFieldProps('final_maturity_date')}
                                  id="final_maturity_date"
                                  error={Boolean(
                                    touched.final_maturity_date && errors.final_maturity_date
                                  )}
                                  helperText={
                                    touched.final_maturity_date && errors.final_maturity_date
                                  }
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Interest Accural Start Date</FormLabel>
                          {/* MUI Date Fns date picker */}
                          <LocalizationProvider dateAdapter={DateAdapter}>
                            <DesktopDatePicker
                              readOnly
                              minDate={new Date()}
                              fullWidth
                              size="small"
                              inputFormat="MM-dd-yyyy"
                              sx={{ mt: 1.5 }}
                              value={formik.values.final_maturity_date}
                              // onChange={finalMaturityDatehandleChange}
                              renderInput={(params) => (
                                <TextField
                                  sx={{ mt: 1.5 }}
                                  fullWidth
                                  size="small"
                                  {...params}
                                  {...getFieldProps('interest_accrual_start_date')}
                                  id="interest_accrual_start_date"
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Lock In Till</FormLabel>

                          <LocalizationProvider dateAdapter={DateAdapter}>
                            <DesktopDatePicker
                              minDate={Date.now()}
                              fullWidth
                              size="small"
                              inputFormat="MM-dd-yyyy"
                              sx={{ mt: 1.5 }}
                              value={formik.values.lockin_till}
                              onChange={lockinTillDatehandleChange}
                              renderInput={(params) => (
                                <TextField
                                  sx={{ mt: 1.5 }}
                                  fullWidth
                                  size="small"
                                  {...params}
                                  {...getFieldProps('lockin_till')}
                                  id="lockin_till"
                                  error={Boolean(touched.lockin_till && errors.lockin_till)}
                                  helperText={touched.lockin_till && errors.lockin_till}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Payment Frequency </FormLabel>
                          <FormControl size="small" variant="outlined" fullWidth sx={{ mt: 1.5 }}>
                            <Select
                              inputProps={{ readOnly: readPaymentFrequency }}
                              {...getFieldProps('payment_frequency_id', {
                                initialValue: defaultTokenValues.payment_frequency_id
                              })}
                              id="payment_frequency_id"
                              error={Boolean(
                                touched.payment_frequency_id && errors.payment_frequency_id
                              )}
                              helperText={
                                touched.payment_frequency_id && errors.payment_frequency_id
                              }
                            >
                              {paymentFreq.map((payFreq) => {
                                return (
                                  <MenuItem key={payFreq.id} value={payFreq.id}>
                                    {payFreq.payment_frequency_name}
                                  </MenuItem>
                                );
                              })}
                            </Select>

                            <FormHelperText sx={{ color: '#d32f2f' }}>
                              {touched.payment_frequency_id && errors.payment_frequency_id}
                            </FormHelperText>
                          </FormControl>
                        </Grid>

                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Payment Basis </FormLabel>
                          <FormControl size="small" variant="outlined" fullWidth sx={{ mt: 1.5 }}>
                            <Select
                              inputProps={{ readOnly: true }}
                              {...getFieldProps('payment_basis_id', {
                                initialValue: defaultTokenValues.payment_basis_id
                              })}
                              id="payment_basis_id"
                              error={Boolean(touched.payment_basis_id && errors.payment_basis_id)}
                              helperText={touched.payment_basis_id && errors.payment_basis_id}
                            >
                              {paymentBasis.map((payBasis) => {
                                return (
                                  <MenuItem key={payBasis.id} value={payBasis.id}>
                                    {payBasis.payment_basis_name}
                                  </MenuItem>
                                );
                              })}
                            </Select>

                            <FormHelperText sx={{ color: '#d32f2f' }}>
                              {touched.payment_basis_id && errors.payment_basis_id}
                            </FormHelperText>
                          </FormControl>
                        </Grid>

                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Interest Type </FormLabel>
                          <FormControl size="small" variant="outlined" fullWidth sx={{ mt: 1.5 }}>
                            <Select
                              inputProps={{ readOnly: readInterestType }}
                              {...getFieldProps('interest_type_id', {
                                initialValue: defaultTokenValues.interest_type_id
                              })}
                              id="interest_type_id"
                              error={Boolean(touched.interest_type_id && errors.interest_type_id)}
                              helperText={touched.interest_type_id && errors.interest_type_id}
                            >
                              {interestType.map((interest) => {
                                return (
                                  <MenuItem key={interest.id} value={interest.id}>
                                    {interest.interest_type_name}
                                  </MenuItem>
                                );
                              })}
                            </Select>

                            <FormHelperText sx={{ color: '#d32f2f' }}>
                              {touched.interest_type_id && errors.interest_type_id}
                            </FormHelperText>
                          </FormControl>
                        </Grid>

                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Interest Calc. Method </FormLabel>
                          <FormControl size="small" variant="outlined" fullWidth sx={{ mt: 1.5 }}>
                            <Select
                              inputProps={{ 'aria-label': 'Without label' }}
                              {...getFieldProps('interest_calc_method_id', {
                                initialValue: defaultTokenValues.interest_calc_method_id
                              })}
                              id="interest_calc_method_id"
                              error={Boolean(
                                touched.interest_calc_method_id && errors.interest_calc_method_id
                              )}
                              helperText={
                                touched.interest_calc_method_id && errors.interest_calc_method_id
                              }
                            >
                              {interestCalcMethod.map((intCal) => {
                                return (
                                  <MenuItem key={intCal.id} value={intCal.id}>
                                    {intCal.interest_calc_method_name}
                                  </MenuItem>
                                );
                              })}
                            </Select>

                            <FormHelperText sx={{ color: '#d32f2f' }}>
                              {touched.interest_calc_method_id && errors.interest_calc_method_id}
                            </FormHelperText>
                          </FormControl>
                        </Grid>

                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Accrual Frequency </FormLabel>
                          <FormControl size="small" variant="outlined" fullWidth sx={{ mt: 1.5 }}>
                            <Select
                              inputProps={{ readOnly: readAccrualFrequency }}
                              {...getFieldProps('accural_frequncy_id', {
                                initialValue: defaultTokenValues.accural_frequncy_id
                              })}
                              id="accural_frequncy_id"
                              error={Boolean(
                                touched.accural_frequncy_id && errors.accural_frequncy_id
                              )}
                              helperText={touched.accural_frequncy_id && errors.accural_frequncy_id}
                            >
                              {accrualFreq.map((accFreq) => {
                                return (
                                  <MenuItem key={accFreq.id} value={accFreq.id}>
                                    {accFreq.accrual_frequency_name}
                                  </MenuItem>
                                );
                              })}
                            </Select>

                            <FormHelperText sx={{ color: '#d32f2f' }}>
                              {touched.accural_frequncy_id && errors.accural_frequncy_id}
                            </FormHelperText>
                          </FormControl>
                        </Grid>

                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Token NAV {formik.values.issuer_currency}</FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            // placeholder="1000"
                            fullWidth
                            size="small"
                            onWheel={(e) => {
                              e.target.blur();
                            }}
                            autoComplete="off"
                            type="number"
                            inputProps={{ readOnly: true }}
                            {...getFieldProps('token_nav')}
                            error={Boolean(touched.token_nav && errors.token_nav)}
                            helperText={touched.token_nav && errors.token_nav}
                          />
                        </Grid>
                      </Grid>
                    }
                  />
                  <AccordionLayout
                    defaultExpanded
                    title="Settlement Terms"
                    content={
                      <Grid container spacing={3} sx={{ width: '100%' }}>
                        {/* Dummy Field for Settlement Account */}
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Settlement Account</FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            fullWidth
                            size="small"
                            autoComplete="off"
                            // placeholder="45"
                            type="text"
                            {...getFieldProps('xinfin_setl_account')}
                            id="xinfin_setl_account"
                            error={Boolean(
                              touched.xinfin_setl_account && errors.xinfin_setl_account
                            )}
                            helperText={touched.xinfin_setl_account && errors.xinfin_setl_account}
                            // inputProps={{ readOnly: true }}
                          />
                        </Grid>

                        {/* Settlement Time Period */}
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Settlement Cycle (T + Days)</FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            fullWidth
                            size="small"
                            autoComplete="off"
                            // placeholder="45"
                            onWheel={(e) => {
                              e.target.blur();
                            }}
                            type="number"
                            {...getFieldProps('application_processing_time')}
                            id="application_processing_time"
                            error={Boolean(
                              touched.application_processing_time &&
                                errors.application_processing_time
                            )}
                            helperText={
                              touched.application_processing_time &&
                              errors.application_processing_time
                            }
                          />
                        </Grid>

                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Payment Leg </FormLabel>
                          <FormControl size="small" variant="outlined" fullWidth sx={{ mt: 1.5 }}>
                            <Select
                              displayEmpty
                              inputProps={{ 'aria-label': 'Without label' }}
                              id="payment_leg_id"
                              {...getFieldProps('payment_leg_id', {
                                initialValue: paymentLegs[0]
                              })}
                              error={Boolean(touched.payment_leg_id && errors.payment_leg_id)}
                              helperText={touched.payment_leg_id && errors.payment_leg_id}
                              defaultValue={paymentLegs[0]}
                            >
                              {/* Restored the Stable Coin dropdown item */}
                              {/* {paymentLegs?.map((pay) => {

                                return (
                                  <MenuItem key={pay.id} value={pay}>
                                    {pay.payment_leg_name}
                                  </MenuItem>
                                );
                              })} */}
                              {/* Restored the Stable Coin dropdown item */}

                              {/* Commented the hardcoded Cash */}
                              <MenuItem value={paymentLegs[0]}>
                                {paymentLegs[0].payment_leg_name}
                              </MenuItem>
                            </Select>
                            <FormHelperText sx={{ color: '#d32f2f' }}>
                              {touched.payment_leg_id && errors.payment_leg_id}
                            </FormHelperText>
                          </FormControl>
                        </Grid>

                        {values?.payment_leg_id?.payment_leg_name === 'Stable Coin' && (
                          <>
                            <Grid item lg={6} md={6} sm={12} xs={12}>
                              <FormLabel>Payment Token </FormLabel>
                              <FormControl
                                size="small"
                                variant="outlined"
                                fullWidth
                                sx={{ mt: 1.5 }}
                              >
                                <Select
                                  value={payment?.payment_token_id}
                                  id="payment_token_id"
                                  {...getFieldProps('payment_token_id')}
                                  onChange={(event) => {
                                    setPaymentObject(event.target.value);
                                  }}
                                  error={Boolean(
                                    touched.payment_token_id && errors.payment_token_id
                                  )}
                                  helperText={touched.payment_token_id && errors.payment_token_id}
                                >
                                  {paymentTokensByCurrency.map((pay) => {
                                    return (
                                      <MenuItem
                                        id="payment_token_id_menu_items"
                                        key={pay.payment_token_id}
                                        value={pay.payment_token_id}
                                      >
                                        {pay.contract_name}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                              <FormHelperText sx={{ color: '#d32f2f' }}>
                                {touched.payment_token_id && errors.payment_token_id}
                              </FormHelperText>
                            </Grid>

                            <Grid item lg={6} md={6} sm={12} xs={12}>
                              <FormLabel>Issuance Swap Ratio</FormLabel>
                              <TextField
                                sx={{ mt: 1.5 }}
                                fullWidth
                                inputProps={{ readOnly: true }}
                                size="small"
                                autoComplete="off"
                                type="number"
                                {...getFieldProps('swap_ratio_issue')}
                              />
                            </Grid>

                            <Grid item lg={6} md={6} sm={12} xs={12}>
                              <FormLabel>Redemption Swap Ratio </FormLabel>
                              <TextField
                                sx={{ mt: 1.5 }}
                                fullWidth
                                inputProps={{ readOnly: true }}
                                size="small"
                                autoComplete="off"
                                type="number"
                                {...getFieldProps('swap_ratio_redeem')}
                              />
                            </Grid>

                            <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: '30px' }}>
                              <FormGroup>
                                <FormControlLabel
                                  control={<Checkbox />}
                                  label="Collateralized"
                                  {...getFieldProps('is_collateralized')}
                                />
                              </FormGroup>
                            </Grid>

                            {values.is_collateralized && (
                              <>
                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                  <FormLabel>Collateral (Per Token)</FormLabel>
                                  <TextField
                                    sx={{ mt: 1.5 }}
                                    fullWidth
                                    size="small"
                                    autoComplete="off"
                                    type="number"
                                    {...getFieldProps('collateral_per_token')}
                                    error={Boolean(
                                      touched.collateral_per_token && errors.collateral_per_token
                                    )}
                                    helperText={
                                      touched.collateral_per_token && errors.collateral_per_token
                                    }
                                  />
                                </Grid>

                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                  <FormLabel>Collateral Provider</FormLabel>
                                  <FormControl
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                    sx={{ mt: 1.5 }}
                                  >
                                    <Select
                                      displayEmpty
                                      inputProps={{ 'aria-label': 'Without label' }}
                                      {...getFieldProps('collateral_provider')}
                                      id="collateral_provider"
                                      error={Boolean(
                                        touched.collateral_provider && errors.collateral_provider
                                      )}
                                      helperText={
                                        touched.collateral_provider && errors.collateral_provider
                                      }
                                    >
                                      {collateralProvider.map((provider) => {
                                        return (
                                          <MenuItem key={provider.id} value={provider}>
                                            {provider.collateral_provider_name}
                                          </MenuItem>
                                        );
                                      })}
                                    </Select>

                                    <FormHelperText sx={{ color: '#d32f2f' }}>
                                      {touched.collateral_provider && errors.collateral_provider}
                                    </FormHelperText>
                                  </FormControl>
                                </Grid>

                                {values.collateral_provider.collateral_provider_name ===
                                  'Issuer' && (
                                  <Grid item lg={6} md={6} sm={12} xs={12}>
                                    <FormLabel>Collateral Xinfin Address </FormLabel>
                                    <TextField
                                      sx={{ mt: 1.5 }}
                                      // placeholder="10"
                                      fullWidth
                                      size="small"
                                      autoComplete="off"
                                      type="text"
                                      value={ethToXdcAddress(entity.primary_issuer_xinfin_address)}
                                      inputProps={{ readOnly: true }}
                                    />
                                  </Grid>
                                )}
                                {values.collateral_provider.collateral_provider_name ===
                                  'Insurer' && (
                                  <Grid item lg={6} md={6} sm={12} xs={12}>
                                    <FormLabel>Collateral Account</FormLabel>
                                    <FormControl
                                      size="small"
                                      variant="outlined"
                                      fullWidth
                                      sx={{ mt: 1.5 }}
                                    >
                                      <Select
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                        {...getFieldProps('collateral_provider_entity')}
                                        id="collateral_provider_entity"
                                        error={Boolean(
                                          touched.collateral_provider_entity &&
                                            errors.collateral_provider_entity
                                        )}
                                        helperText={
                                          touched.collateral_provider_entity &&
                                          errors.collateral_provider_entity
                                        }
                                      >
                                        {issuerInvestorMapByIssuerId.map((insurer) => {
                                          return (
                                            <MenuItem key={insurer} value={insurer}>
                                              {insurer.insurer_name}
                                            </MenuItem>
                                          );
                                        })}
                                      </Select>
                                    </FormControl>
                                    <FormHelperText sx={{ color: '#d32f2f' }}>
                                      {touched.collateral_provider_entity &&
                                        errors.collateral_provider_entity}
                                    </FormHelperText>
                                    {values.collateral_provider.collateral_provider_name ===
                                      'Insurer' &&
                                      issuerInvestorMapByIssuerId.length === 0 && (
                                        <Grid item lg={6} md={6} sm={12} xs={12}>
                                          <FormHelperText sx={{ color: '#d32f2f' }}>
                                            No Insurer is mapped!
                                          </FormHelperText>
                                        </Grid>
                                      )}
                                  </Grid>
                                )}

                                {values.collateral_provider.collateral_provider_name ===
                                  'Insurer' &&
                                  values.collateral_provider_entity !== '' && (
                                    <>
                                      <Grid item lg={6} md={6} sm={12} xs={12}>
                                        <FormLabel>Collateral Xinfin Address </FormLabel>
                                        <TextField
                                          sx={{ mt: 1.5 }}
                                          // placeholder="10"
                                          fullWidth
                                          size="small"
                                          autoComplete="off"
                                          type="text"
                                          value={ethToXdcAddress(
                                            values.collateral_provider_entity.insurer_xinfin_address
                                          )}
                                          inputProps={{ readOnly: true }}
                                        />
                                      </Grid>
                                      <Grid item lg={6} md={6} sm={12} xs={12}>
                                        <FormLabel>Insurer Contribution</FormLabel>
                                        <TextField
                                          sx={{ mt: 1.5 }}
                                          // placeholder="10"
                                          fullWidth
                                          size="small"
                                          autoComplete="off"
                                          type="number"
                                          {...getFieldProps('insurer_contri')}
                                          inputProps={{ readOnly: true }}
                                        />
                                      </Grid>
                                      <Grid item lg={6} md={6} sm={12} xs={12}>
                                        <FormLabel>Issuer Contribution</FormLabel>
                                        <TextField
                                          sx={{ mt: 1.5 }}
                                          // placeholder="10"
                                          fullWidth
                                          size="small"
                                          autoComplete="off"
                                          type="number"
                                          {...getFieldProps('issuer_contri')}
                                          inputProps={{ readOnly: true }}
                                        />
                                      </Grid>
                                    </>
                                  )}
                              </>
                            )}

                            {values.payment_token_id && (
                              <>
                                <Divider
                                  sx={{
                                    width: '100%',
                                    mt: 3
                                  }}
                                />

                                <Grid item lg={3} md={6} sm={12} xs={12}>
                                  <FormLabel>Name</FormLabel>
                                  <TextField
                                    sx={{ mt: 1.5 }}
                                    // placeholder="10"
                                    fullWidth
                                    size="small"
                                    autoComplete="off"
                                    type="text"
                                    value={payment?.contract_name}
                                    inputProps={{ readOnly: true }}
                                  />
                                </Grid>

                                <Grid item lg={3} md={6} sm={12} xs={12}>
                                  <FormLabel>Symbol</FormLabel>
                                  <TextField
                                    sx={{ mt: 1.5 }}
                                    // placeholder="10"
                                    fullWidth
                                    size="small"
                                    autoComplete="off"
                                    type="text"
                                    value={payment?.symbol}
                                    inputProps={{ readOnly: true }}
                                  />
                                </Grid>
                                <Grid item lg={3} md={6} sm={12} xs={12}>
                                  <FormLabel>Decimal</FormLabel>
                                  <TextField
                                    sx={{ mt: 1.5 }}
                                    // placeholder="10"
                                    fullWidth
                                    size="small"
                                    autoComplete="off"
                                    type="number"
                                    onWheel={(e) => {
                                      e.target.blur();
                                    }}
                                    value={payment?.decimals}
                                    inputProps={{ readOnly: true }}
                                  />
                                </Grid>
                                <Grid item lg={3} md={6} sm={12} xs={12}>
                                  <FormLabel>Pegged CCY</FormLabel>
                                  <TextField
                                    sx={{ mt: 1.5 }}
                                    // placeholder="10"
                                    fullWidth
                                    size="small"
                                    autoComplete="off"
                                    type="text"
                                    value={payment?.backed_currency}
                                    inputProps={{ readOnly: true }}
                                  />
                                </Grid>

                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                  <Grid container sx={{ width: '100%' }}>
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                      <FormLabel>Payment Token Address</FormLabel>
                                      <TextField
                                        sx={{ mt: 1.5 }}
                                        fullWidth
                                        size="small"
                                        autoComplete="off"
                                        type="text"
                                        value={ethToXdcAddress(payment?.contract_address)}
                                        InputProps={{
                                          readOnly: true,
                                          endAdornment: (
                                            <InputAdornment position="end">
                                              <AddressFieldTools
                                                address={payment?.contract_address}
                                                symbol={payment?.symbol}
                                                decimals={payment?.decimals}
                                                showAddress={false}
                                                showCopyButton
                                                showInBlockExplorer
                                                showAddToWallet
                                              />
                                            </InputAdornment>
                                          )
                                        }}
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>
                                {values.token_type === 'POOL_LENDING' && (
                                  <Grid item lg={6} md={6} sm={12} xs={12}>
                                    <FormLabel>Liquidity Pool Balance</FormLabel>
                                    <TextField
                                      sx={{ mt: 1.5 }}
                                      // placeholder="10"
                                      fullWidth
                                      size="small"
                                      autoComplete="off"
                                      type="text"
                                      value={payment?.balance}
                                      inputProps={{ readOnly: true }}
                                    />
                                  </Grid>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </Grid>
                    }
                  />
                  {/* SAFI DETAILS START HERE */}
                  {values.issue_category_id === longTermBondCategoryId && (<AccordionLayout
                    title="Safi Details"
                    defaultExpanded
                    content={
                      <Grid container spacing={3} sx={{ width: '100%' }}>
                        {/* <Grid item lg={6} md={6} sm={12} xs={12} sx={{ pr: 1 }}>
                          <FormLabel>Originator</FormLabel>
                          <FormControl size="small" variant="outlined" fullWidth sx={{ mt: 1.5 }}>
                            <Select
                              displayEmpty
                              inputProps={{ 'aria-label': 'Without label' }}
                              onClick={() => {
                                if (originators.length === 0) {
                                  console.log('No originators found, Fething originators...');
                                  fetchOriginator();
                                }
                              }}
                              {...getFieldProps('originator_id')}
                              id="originator_id"
                              error={Boolean(touched.originator_id && errors.originator_id)}
                              helperText={touched.originator_id && errors.originator_id}
                            >
                              {originators.map((originator, val) => {
                                return (
                                  <MenuItem
                                    id="originator_id_menu_items"
                                    key={originator.originator_id}
                                    value={originator.originator_id}
                                  >
                                    {originator.originator_name}
                                  </MenuItem>
                                );
                              })}
                            </Select>

                            <FormHelperText sx={{ color: '#d32f2f' }}>
                              {touched.originator_id && errors.originator_id}
                            </FormHelperText>
                          </FormControl>
                        </Grid> */}
                        {/* <Grid item lg={3} md={6} sm={12} xs={12}>
                          <FormLabel>KYC Provider</FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            fullWidth
                            inputProps={{ readOnly: true }}
                            size="small"
                            autoComplete="off"
                            type="text"
                            value={entity?.kyc_provider_name}
                          />
                        </Grid> */}
                        {/* <Grid item lg={3} md={6} sm={12} xs={12}>
                                  <FormLabel>Pegged CCY</FormLabel>
                                  <TextField
                                    sx={{ mt: 1.5 }}
                                    // placeholder="10"
                                    fullWidth
                                    size="small"
                                    autoComplete="off"
                                    type="text"
                                    value={payment?.backed_currency}
                                    inputProps={{ readOnly: true }}
                                  />
                                </Grid> */}
                        {/* <Grid item lg={6} md={6} sm={12} xs={12} sx={{ pr: 1 }}>
                          
                          <FormLabel>KYC Provider</FormLabel>
                          <FormControl size="small" variant="outlined" fullWidth sx={{ mt: 1.5 }}>
                            <Select
                              displayEmpty
                              inputProps={{ 'aria-label': 'Without label' }}
                              // onClick={() => {
                              //   if (kycProviders.length === 0) {
                              //     console.log('No kyc providers found, Fething kyc providers...');
                              //     fetchKycProvider();
                              //   }
                              // }}
                              {...getFieldProps('kyc_provider_id')}
                              id="kyc_provider_id"
                              error={Boolean(touched.kyc_provider_id && errors.kyc_provider_id)}
                              helperText={touched.kyc_provider_id && errors.kyc_provider_id}
                            >
                              {kycProviders.map((kycProvider) => {
                                return (
                                  <MenuItem
                                    id="kyc_provider_id_menu_items"
                                    value={kycProvider.kyc_provider_id}
                                    key={kycProvider.kyc_provider_id}
                                  >
                                    {kycProvider.kyc_provider_name}
                                  </MenuItem>
                                );
                              })}
                            </Select>

                            <FormHelperText sx={{ color: '#d32f2f' }}>
                              {touched.kyc_provider_id && errors.kyc_provider_id}
                            </FormHelperText>
                          </FormControl>
                        </Grid> */}

                        {/* <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Custodian</FormLabel>
                          <FormControl size="small" variant="outlined" fullWidth sx={{ mt: 1.5 }}>
                            <Select
                              displayEmpty
                              inputProps={{ 'aria-label': 'Without label' }}
                              onClick={() => {
                                if (custodians.length === 0) {
                                  console.log('No Custodian Found,Fetching Custodian...');
                                  fetchCustodian();
                                }
                              }}
                              {...getFieldProps('custodian_id')}
                              id="custodian_id"
                              error={Boolean(touched.custodian_id && errors.custodian_id)}
                              helperText={touched.custodian_id && errors.custodian_id}
                            >
                              {custodians.map((custodian) => {
                                return (
                                  <MenuItem
                                    id="custodian_id_menu_items"
                                    value={custodian.custodian_id}
                                    key={custodian.custodian_id}
                                  >
                                    {custodian.custodian_name}
                                  </MenuItem>
                                );
                              })}
                            </Select>

                            <FormHelperText sx={{ color: '#d32f2f' }}>
                              {touched.custodian_id && errors.custodian_id}
                            </FormHelperText>
                          </FormControl>
                        </Grid> */}

                        {/* <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Use of proceeds</FormLabel>
                          <TextField
                            multiline="true"
                            rows="5"
                            sx={{ mt: 1.5 }}
                            fullWidth
                            size="small"
                            autoComplete="off"
                            type="text"
                            {...getFieldProps('use_of_proceeds')}
                            id="use_of_proceeds"
                            error={Boolean(touched.use_of_proceeds && errors.use_of_proceeds)}
                            helperText={touched.use_of_proceeds && errors.use_of_proceeds}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Reference Assets</FormLabel>
                          <TextField
                            multiline="true"
                            rows="5"
                            sx={{ mt: 1.5 }}
                            fullWidth
                            size="small"
                            autoComplete="off"
                            type="text"
                            {...getFieldProps('reference_assets')}
                            id="reference_assets"
                            error={Boolean(touched.reference_assets && errors.reference_assets)}
                            helperText={touched.reference_assets && errors.reference_assets}
                          />
                        </Grid> */}
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Investment Vehicle Issuer</FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            fullWidth
                            size="small"
                            autoComplete="off"
                            type="text"
                            {...getFieldProps('bond_issuer')}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Guarantor</FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            fullWidth
                            size="small"
                            autoComplete="off"
                            type="text"
                            {...getFieldProps('guarantor')}
                          />
                        </Grid>
                        {/* <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Announcement Date</FormLabel>
                          <LocalizationProvider dateAdapter={DateAdapter}>
                            <DesktopDatePicker
                              minDate={Date.now()}
                              fullWidth
                              size="small"
                              inputFormat="MM-dd-yyyy"
                              sx={{ mt: 1.5 }}
                              value={formik.values.announcement_date}
                              onChange={(newDate) => {
                                formik.setFieldValue(
                                  'announcement_date',
                                  format(newDate, 'yyyy-MM-dd')
                                );
                              }}
                              renderInput={(params) => (
                                <TextField
                                  sx={{ mt: 1.5 }}
                                  fullWidth
                                  size="small"
                                  {...params}
                                  {...getFieldProps('announcement_date')}
                                  id="announcement_date"
                                  error={Boolean(
                                    touched.announcement_date && errors.announcement_date
                                  )}
                                  helperText={touched.announcement_date && errors.announcement_date}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </Grid> */}
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Announcement Date</FormLabel>
                          <LocalizationProvider dateAdapter={DateAdapter}>
                            <DesktopDatePicker
                              minDate={Date.now()}
                              fullWidth
                              size="small"
                              inputFormat="MM-dd-yyyy"
                              sx={{ mt: 1.5 }}
                              value={formik.values.announcement_date}
                              onChange={(newDate) => {
                                formik.setFieldValue(
                                  'announcement_date',
                                  format(newDate, 'yyyy-MM-dd')
                                );
                              }}
                              renderInput={(params) => (
                                <TextField
                                  sx={{ mt: 1.5 }}
                                  fullWidth
                                  size="small"
                                  {...params}
                                  {...getFieldProps('announcement_date')}
                                  id="announcement_date"
                                  error={Boolean(
                                    touched.announcement_date && errors.announcement_date
                                  )}
                                  helperText={touched.announcement_date && errors.announcement_date}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Issue Date</FormLabel>
                          <LocalizationProvider dateAdapter={DateAdapter}>
                            <DesktopDatePicker
                              minDate={Date.now()}
                              fullWidth
                              size="small"
                              inputFormat="MM-dd-yyyy"
                              sx={{ mt: 1.5 }}
                              value={formik.values.issue_date}
                              onChange={(newDate) => {
                                formik.setFieldValue('issue_date', format(newDate, 'yyyy-MM-dd'));
                              }}
                              renderInput={(params) => (
                                <TextField
                                  sx={{ mt: 1.5 }}
                                  fullWidth
                                  size="small"
                                  {...params}
                                  {...getFieldProps('issue_date')}
                                  id="issue_date"
                                  error={Boolean(touched.issue_date && errors.issue_date)}
                                  helperText={touched.issue_date && errors.issue_date}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Maturity Date</FormLabel>
                          <LocalizationProvider dateAdapter={DateAdapter}>
                            <DesktopDatePicker
                              minDate={Date.now()}
                              fullWidth
                              size="small"
                              inputFormat="MM-dd-yyyy"
                              sx={{ mt: 1.5 }}
                              value={formik.values.maturity_date}
                              onChange={(newDate) => {
                                formik.setFieldValue(
                                  'maturity_date',
                                  format(newDate, 'yyyy-MM-dd')
                                );
                              }}
                              renderInput={(params) => (
                                <TextField
                                  sx={{ mt: 1.5 }}
                                  fullWidth
                                  size="small"
                                  {...params}
                                  {...getFieldProps('maturity_date')}
                                  id="maturity_date"
                                  error={Boolean(touched.maturity_date && errors.maturity_date)}
                                  helperText={touched.maturity_date && errors.maturity_date}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Years to Maturity/Next Call</FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            fullWidth
                            type="number"
                            size="small"
                            autoComplete="off"
                            {...getFieldProps('years_to_maturity_to_next_call')}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Issue / Reoffer Price</FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            fullWidth
                            size="small"
                            autoComplete="off"
                            type="number"
                            {...getFieldProps('issue_to_reoffer_price')}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Issue / Reoffer yield</FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            fullWidth
                            size="small"
                            autoComplete="off"
                            type="number"
                            {...getFieldProps('issue_to_reoffer_yield')}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Coupon Type</FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            fullWidth
                            size="small"
                            autoComplete="off"
                            type="text"
                            {...getFieldProps('coupon_type')}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Annual Coupon Rate</FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            fullWidth
                            size="small"
                            autoComplete="off"
                            type="number"
                            {...getFieldProps('annual_coupon_rate')}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Coupon Frequency</FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            fullWidth
                            size="small"
                            autoComplete="off"
                            type="text"
                            {...getFieldProps('coupon_frequency')}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Security</FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            fullWidth
                            size="small"
                            autoComplete="off"
                            type="text"
                            {...getFieldProps('seniority')}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Exchange Listed</FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            fullWidth
                            size="small"
                            autoComplete="off"
                            type="text"
                            {...getFieldProps('exchange_listed')}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Reference Rate (%)</FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            fullWidth
                            size="small"
                            autoComplete="off"
                            type="number"
                            {...getFieldProps('reference_rate')}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>ISIN</FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            fullWidth
                            size="small"
                            autoComplete="off"
                            type="text"
                            {...getFieldProps('isin')}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>CUSIP</FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            fullWidth
                            size="small"
                            autoComplete="off"
                            type="text"
                            {...getFieldProps('cusip')}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Investment Currency</FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            fullWidth
                            size="small"
                            autoComplete="off"
                            type="text"
                            {...getFieldProps('bond_currency')}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Total Issue Size</FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            fullWidth
                            size="small"
                            autoComplete="off"
                            type="number"
                            {...getFieldProps('total_issue_Size')}
                            onWheel={(e) => {
                              e.target.blur();
                            }}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Min. Investment Quantity(Nominal)</FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            fullWidth
                            size="small"
                            autoComplete="off"
                            type="text"
                            {...getFieldProps('min_investment_quantity_nominal')}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Incremental Quantity (Nominal)</FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            fullWidth
                            size="small"
                            autoComplete="off"
                            type="text"
                            {...getFieldProps('incremental_quantity_nominal')}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Investment Registration</FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            fullWidth
                            size="small"
                            autoComplete="off"
                            type="text"
                            {...getFieldProps('bond_registration')}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Investment Type</FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            fullWidth
                            size="small"
                            autoComplete="off"
                            type="text"
                            {...getFieldProps('bond_type')}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Investment Sector</FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            fullWidth
                            size="small"
                            autoComplete="off"
                            type="text"
                            {...getFieldProps('bond_sector')}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Investment Sub Sector</FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            fullWidth
                            size="small"
                            autoComplete="off"
                            type="text"
                            {...getFieldProps('bond_sub_sector')}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Issuer Credit Rating (S&P/Fitch)</FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            fullWidth
                            size="small"
                            autoComplete="off"
                            type="text"
                            {...getFieldProps('issuer_credit_rating')}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Bond Credit Rating (S&P/Fitch)</FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            fullWidth
                            size="small"
                            autoComplete="off"
                            type="text"
                            {...getFieldProps('bond_credit_rating')}
                          />
                        </Grid>
                        {/* <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Shariah Compliant</FormLabel>
                          <FormControl size="small" variant="outlined" fullWidth sx={{ mt: 1.5 }}>
                            <Select
                              // placeholder="Select Currency"
                              displayEmpty
                              inputProps={{ 'aria-label': 'Without label' }}
                              {...getFieldProps('shariah_compliant')}
                              id="shariah_compliant"
                              error={Boolean(touched.shariah_compliant && errors.shariah_compliant)}
                              helperText={touched.shariah_compliant && errors.shariah_compliant}
                            >
                              <MenuItem value="True">Yes</MenuItem>
                              <MenuItem value="False">No</MenuItem>
                            </Select>
                            <FormHelperText sx={{ color: '#d32f2f' }}>
                              {touched.shariah_compliant && errors.shariah_compliant}
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Sukuk Investing</FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            fullWidth
                            size="small"
                            autoComplete="off"
                            type="text"
                            {...getFieldProps('sukuk_investing')}
                          />
                        </Grid> */}
                      </Grid>
                    }
                  />
                  )}
                  {/* SAFI DETAILS END HERE */}
                  {/* No added in form  */}

                  <Box display="flex" justifyContent="flex-end">
                    <Button
                      variant="outlined"
                      color="secondary"
                      sx={{
                        width: '7.5rem',
                        height: '2.5rem',
                        mr: 2,
                        mt: 2
                      }}
                      onClick={() => {
                        navigate('/home');
                      }}
                    >
                      Cancel
                    </Button>
                    <LoadingButton
                      sx={{
                        width: '7.5rem',
                        height: '2.5rem',

                        mt: 2
                      }}
                      variant="gradient"
                      loadingPosition="start"
                      id="create-token"
                      type="submit"
                    >
                      Save
                    </LoadingButton>
                  </Box>
                  <FocusError />
                </Form>
              </FormikProvider>
            </CardContent>
          </Card>
        </Container>
      </Page>
    </>
  );
};
export default CreateToken;
