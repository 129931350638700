import React, { useState, useRef, useEffect } from 'react';
import {
  Grid,
  Divider,
  Typography,
  TextField,
  FormLabel,
  Box,
  FormControl,
  Select,
  FormHelperText,
  MenuItem,
  Alert,
  Container,
  Card,
  CardContent
} from '@mui/material';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { useBusinessEntityState, useHttpApi, useAppState } from 'state';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router';
import InsurersOfIssuerTable from 'components/masterData/Issuer/InsurersOfIssuerTable';
import Page from 'components/Page';
import AllIssueTypes from 'components/DocMangement/AllIssueTypes';

const AddIssueType = () => {
  const { createIssueType } = useHttpApi();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { throwErrorMessage } = useAppState();
  const [error, setError] = useState('');
  const CreteIssueType = Yup.object().shape({
    issue_type_name: Yup.string().required('Issue Type Name is required')
  });
  const formik = useFormik({
    initialValues: {
      issue_type_name: ''
    },
    validationSchema: CreteIssueType,
    onSubmit: async (data, { resetForm }) => {
      console.log(data);
      try {
        console.log(JSON.stringify(data, null, ' '));
      } catch (e) {
        throwErrorMessage(e);
      }
    }
  });
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <>
      <Page title="Issue Type">
        <Container sx={{ my: 2 }}>
          <Card>
            {error && (
              <Box mb={4}>
                <Alert severity="error">{error}</Alert>
              </Box>
            )}

            <Container>
              <Grid
                conatiner
                spacing={2}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  mt: 2
                }}
              >
                <Grid item lg={5} md={12} sm={12} xs={12}>
                  <Typography
                    sx={{
                      fontSize: '1.125rem',
                      fontWeight: 'bold',
                      mt: 4,
                      mb: 1,
                      ml: 4
                    }}
                  >
                    Add Issue Type
                  </Typography>

                  <FormikProvider value={formik}>
                    <Form autoComplete="off" onSubmit={handleSubmit}>
                      <CardContent sx={{ ml: 2, mr: 2 }}>
                        <Grid item lg={12} md={6} sm={12} xs={12}>
                          <FormLabel>Issue Type</FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            fullWidth
                            size="small"
                            autoComplete="off"
                            type="text"
                            {...getFieldProps('issue_type_name')}
                            error={Boolean(touched.issue_type_name && errors.issue_type_name)}
                            helperText={touched.issue_type_name && errors.issue_type_name}
                          />
                        </Grid>
                        <Grid item lg={12} md={6} sm={12} xs={12}>
                          <LoadingButton
                            sx={{
                              width: '8rem',
                              height: '2.5rem',
                              mt: 4
                            }}
                            variant="gradient"
                            loadingPosition="start"
                            id="create-token"
                            type="submit"
                          >
                            Add
                          </LoadingButton>
                        </Grid>
                      </CardContent>
                    </Form>
                  </FormikProvider>
                </Grid>
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{
                    backgroundColor: '#f5f8fa',
                    border: '2px solid #f5f8fa',
                    mb: 2
                  }}
                />
                <Grid item lg={7} md={12} sm={12} xs={12}>
                  <Typography
                    sx={{
                      fontSize: '1.125rem',
                      fontWeight: 'bold',
                      mt: 4,
                      mb: 1,
                      ml: 4
                    }}
                  >
                    View Issue Types
                  </Typography>

                  <CardContent sx={{ ml: 2, mr: 2 }}>
                    <AllIssueTypes />
                  </CardContent>
                </Grid>
              </Grid>
            </Container>
          </Card>
        </Container>
      </Page>
    </>
  );
};

export default AddIssueType;
