import { useState, useCallback } from 'react';

const useStatePersistence = (persistence, cacheKey, defaultValue) => {
  const [value, setValue] = useState(() => {
    const cachedValue = persistence.get(cacheKey);

    return cachedValue !== null ? cachedValue : defaultValue;
  });

  const setValue_ = useCallback((newValue) => {
    persistence.set(cacheKey, newValue);
    setValue(newValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [value, setValue_];
};

export default useStatePersistence;
