import {
  Container,
  Card,
  Grid,
  CardContent,
  Divider,
  Typography,
  TextField,
  FormLabel,
  Button,
  Box,
  FormControl,
  Select,
  MenuItem,
  Checkbox,
  Alert,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormHelperText
} from '@mui/material';

import Page from 'components/Page';
import Breadcrumbs from 'components/Breadcrumbs';
import React, { useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { xdcRegex } from 'helpers/regex';
import { useCoreTableState } from 'state/useCoreTableState';

const AddXinfin = () => {
  const [error, setError] = useState('');
  const { kycStatus } = useCoreTableState();

  const AddXinfinAcSchema = Yup.object().shape({
    xinfin_address: Yup.string()
      .matches(xdcRegex, 'Invalid XDC Address Format')
      .required('XinFin Address is required'),
    kyc_status: Yup.string().required('Select KYC Status'),
    is_multi_sig: Yup.string().required('Select MultiSig Wallet Status'),
    is_eoa: Yup.string().required('Select EoA Status'),
    is_valid: Yup.string().required('Select is XinFin A/C validS'),
    is_whitelisted: Yup.string().required('Select is whitelisted')
  });

  const formik = useFormik({
    initialValues: {
      xinfin_address: '',
      is_multi_sig: '',
      is_eoa: '',
      is_valid: '',
      kyc_status: '',
      is_whitelisted: ''
    },
    validationSchema: AddXinfinAcSchema,
    onSubmit: (values) => {
      alert(JSON.stringify(values));
    }
  });
  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <>
      <Page title="Add XinFin Account - Master Data">
        <Container sx={{ my: 2 }}>
          <Box sx={{ my: 2 }}>
            <Breadcrumbs pageHead="XinFin Account" aria-label="breadcrumb" />
          </Box>
          <Card>
            <Container>
              <Typography
                sx={{
                  fontSize: '1.125rem',
                  fontWeight: 'bold',
                  mt: 4,
                  mb: 1,
                  ml: 4
                }}
              >
                Add XinFin Account
              </Typography>
              <Divider
                sx={{
                  width: '100%',
                  backgroundColor: '#f5f8fa',
                  border: '2px solid #f5f8fa'
                }}
              />
              <FormikProvider value={formik}>
                <Form autoComplete="off" onSubmit={handleSubmit}>
                  {error && (
                    <Box mb={4}>
                      <Alert
                        severity="error"
                        sx={{
                          textTransform: 'capitalize'
                        }}
                      >
                        {error}
                      </Alert>
                    </Box>
                  )}
                  <CardContent sx={{ m: 2 }}>
                    <Grid container spacing={3} sx={{ width: '100%' }}>
                      <Grid item lg={6} sm={12} md={6} xs={12} sx={{ pr: 1 }}>
                        <FormLabel>XinFin Account Address</FormLabel>
                        <TextField
                          sx={{ mt: 1.5 }}
                          fullWidth
                          size="small"
                          autoComplete="off"
                          type="text"
                          // placeholder="Enter the XinFin account address"
                          {...getFieldProps('xinfin_address')}
                          error={Boolean(touched.xinfin_address && errors.xinfin_address)}
                          helperText={touched.xinfin_address && errors.xinfin_address}
                        />
                      </Grid>
                      <Grid item lg={6} sm={12} md={6} xs={12} sx={{ pl: 0.5 }}>
                        <FormLabel>Is Multi Sig Wallet</FormLabel>
                        <Grid container sx={{ mt: 1 }}>
                          <Grid item lg={6} sm={6} md={6} xs={6}>
                            <RadioGroup
                              row
                              aria-label="multi-sig"
                              name="row-radio-buttons-group"
                              {...getFieldProps('is_multi_sig')}
                              error={Boolean(touched.is_multi_sig && errors.is_multi_sig)}
                            >
                              <FormControlLabel value="TRUE" control={<Radio />} label="TRUE" />
                              <FormControlLabel value="FALSE" control={<Radio />} label="FALSE" />
                              <FormHelperText sx={{ color: '#d32f2f' }}>
                                {touched.is_multi_sig && errors.is_multi_sig}
                              </FormHelperText>
                            </RadioGroup>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item lg={6} sm={12} md={6} xs={12} sx={{ pl: 0.5 }}>
                        <FormLabel>Is EoA</FormLabel>
                        <Grid container sx={{ mt: 1 }}>
                          <Grid item lg={6} sm={6} md={6} xs={6}>
                            <RadioGroup
                              row
                              aria-label="is_eoa"
                              name="row-radio-buttons-group"
                              {...getFieldProps('is_eoa')}
                              error={Boolean(touched.is_eoa && errors.is_eoa)}
                            >
                              <FormControlLabel value="TRUE" control={<Radio />} label="TRUE" />
                              <FormControlLabel value="FALSE" control={<Radio />} label="FALSE" />
                              <FormHelperText sx={{ color: '#d32f2f' }}>
                                {touched.is_eoa && errors.is_eoa}
                              </FormHelperText>
                            </RadioGroup>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item lg={6} sm={12} md={6} xs={12} sx={{ pl: 0.5 }}>
                        <FormLabel>Is Valid</FormLabel>
                        <Grid container sx={{ mt: 1 }}>
                          <Grid item lg={6} sm={6} md={6} xs={6}>
                            <RadioGroup
                              row
                              aria-label="is_valid"
                              name="row-radio-buttons-group"
                              {...getFieldProps('is_valid')}
                              error={Boolean(touched.is_valid && errors.is_valid)}
                            >
                              <FormControlLabel value="TRUE" control={<Radio />} label="TRUE" />
                              <FormControlLabel value="FALSE" control={<Radio />} label="FALSE" />
                              <FormHelperText sx={{ color: '#d32f2f' }}>
                                {touched.is_valid && errors.is_valid}
                              </FormHelperText>
                            </RadioGroup>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item lg={6} sm={12} md={6} xs={12}>
                        <FormLabel>KYC status</FormLabel>

                        <FormControl size="small" variant="outlined" fullWidth sx={{ mt: 1.5 }}>
                          <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                            {...getFieldProps('kyc_status')}
                            error={Boolean(touched.kyc_status && errors.kyc_status)}
                            helperText={touched.kyc_status && errors.kyc_status}
                          >
                            {kycStatus &&
                              kycStatus.map((kycStatus, index) => {
                                return (
                                  <MenuItem
                                    key={kycStatus.kyc_status_name}
                                    value={kycStatus.kyc_status_name}
                                  >
                                    {kycStatus.kyc_status_name}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                          <FormHelperText sx={{ color: '#d32f2f' }}>
                            {touched.kyc_status && errors.kyc_status}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item lg={6} sm={12} md={6} xs={12} sx={{ pl: 0.5 }}>
                        <FormLabel>Is whitelisted</FormLabel>
                        <Grid container sx={{ mt: 1 }}>
                          <Grid item lg={6} sm={6} md={6} xs={6}>
                            <RadioGroup
                              row
                              aria-label="whitelist"
                              name="row-radio-buttons-group"
                              {...getFieldProps('is_whitelisted')}
                              error={Boolean(touched.is_whitelisted && errors.is_whitelisted)}
                            >
                              <FormControlLabel value="TRUE" control={<Radio />} label="TRUE" />
                              <FormControlLabel value="FALSE" control={<Radio />} label="FALSE" />
                              <FormHelperText sx={{ color: '#d32f2f' }}>
                                {touched.is_whitelisted && errors.is_whitelisted}
                              </FormHelperText>
                            </RadioGroup>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        sx={{ display: 'flex', justifyContent: 'flex-end' }}
                      >
                        <Button
                          variant="contained"
                          color="secondary"
                          sx={{ mt: 4, ml: 2, height: '2.7rem', width: '7rem' }}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="gradient"
                          sx={{ mt: 4, ml: 2, height: '2.7rem', width: '7rem' }}
                          onClick={handleSubmit}
                        >
                          Save
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Form>
              </FormikProvider>
            </Container>
          </Card>
        </Container>
      </Page>
    </>
  );
};

export default AddXinfin;
