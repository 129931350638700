import { useEffect, useState } from 'react';
import constate from 'constate';

import { useWeb3State } from 'state/useWeb3State';

const POLL_INTERVAL = 1000;

const useCurrentAccountBalance_ = () => {
  const { account, web3 } = useWeb3State();

  const [balance, setBalance] = useState('0');

  useEffect(() => {
    if (!web3 || !account) {
      setBalance('0');
      return () => {};
    }

    let timerId = null;
    let canceled = false;

    const poll = async () => {
      timerId = null;

      try {
        const balance_ = await web3.eth.getBalance(account);

        if (!canceled) {
          setBalance(balance_);
        }
      } catch (e) {
        console.warn(`Something is wrong when polling for account balance: ${e}`);
      }

      if (!canceled) {
        timerId = setTimeout(poll, POLL_INTERVAL);
      }
    };

    poll();

    return () => {
      if (timerId) {
        clearTimeout(timerId);
      }

      canceled = true;
    };
  }, [web3, account]);

  return balance;
};

export const [CurrentAccountBalanceProvider, useCurrentAccountBalance] =
  constate(useCurrentAccountBalance_);
