import { Box, Typography } from '@mui/material';
import React from 'react';

const NAIcon = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        borderRadius: '50%',
        justifyContent: 'center',
        p: 0,
        height: '1.3rem',
        width: '1.3rem',
        backgroundColor: '#808080'
      }}
    >
      <Typography
        sx={{
          fontWeight: 'bold',
          fontSize: '0.6rem',
          color: '#fff'
        }}
      >
        NA
      </Typography>
    </Box>
  );
};

export default NAIcon;
