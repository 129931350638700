import { IconButton, Box } from '@mui/material';
import React from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { ethToXdcAddress, xdcToEthAddress } from 'helpers/web3';

const CopyAddress = ({ addr, ethAddr = false, txHash = false }) => {
  const addrWidth = txHash ? '38rem' : '25rem';
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        m: 0,
        p: 0,
        alignItems: 'center'
      }}
    >
      <Box
        sx={{
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          width: addrWidth
        }}
      >
        {ethAddr ? xdcToEthAddress(addr) : ethToXdcAddress(addr)}
      </Box>

      <IconButton
        aria-label="subs detail"
        onClick={() =>
          navigator.clipboard.writeText(ethAddr ? xdcToEthAddress(addr) : ethToXdcAddress(addr))
        }
      >
        <ContentCopyIcon sx={{ fontSize: '1rem' }} />
      </IconButton>
    </Box>
  );
};

export default CopyAddress;
