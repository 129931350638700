import React, { useState, useRef, useEffect } from 'react';
import {
  Grid,
  Typography,
  Box,
  Alert,
  Container,
  Card,
  IconButton,
  Select,
  FormControl,
  MenuItem,
  Divider,
  FormLabel,
  CardContent
} from '@mui/material';
import { useBusinessEntityState, useHttpApi, useAppState } from 'state';
import Page from 'components/Page';
import AssociateDocTypeTable from 'components/DocMangement/AssociateDocTypeTable';
import SyncIcon from '@mui/icons-material/Sync';
import EmptyDashboard from 'assets/images/emptyassets.png';

const AssociateDocType = () => {
  const { fetchIssueTypes, issueTypes, fetchAllDocumentTypes, allDocumentTypes } =
    useBusinessEntityState();
  const [data, setData] = useState([]);
  const [sourcesFetched, setsourcesFetched] = useState(0);
  const [sortissueType, setSortissueType] = useState('');

  const refreshData = async () => {
    await fetchIssueTypes();
    await fetchAllDocumentTypes();
  };

  const buildData = () => {
    const newData = issueTypes
      .filter((i) => i.issue_type_name === sortissueType)
      .map((i) => {
        return {
          id: i.id,
          issue_type_name: i.issue_type_name,
          doc_type: allDocumentTypes.map((d) => {
            return {
              id: d.id,
              doc_type_name: d.name
            };
          })
        };
      });
    setData(newData);
    console.log(data);
  };

  useEffect(() => {
    refreshData();
  }, []);
  useEffect(() => {
    setsourcesFetched(sourcesFetched + 1);
  }, [issueTypes]);

  useEffect(() => {
    setsourcesFetched(sourcesFetched + 1);
  }, [allDocumentTypes]);

  useEffect(() => {
    if (sourcesFetched > 2 && sortissueType !== '') {
      buildData();
    }
  }, [sourcesFetched, sortissueType]);

  const DataNotAvailable = () => {
    return (
      <>
        <Box
          sx={{
            m: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <img src={EmptyDashboard} alt="Empty Dashboard" />
          <Typography variant="p">Issue Type Not Selected</Typography>
        </Box>
      </>
    );
  };
  return (
    <>
      <Page title="Associate Data">
        <Container sx={{ my: 2 }}>
          <Card>
            <Container>
              <Box sx={{ mt: 4, ml: 4, display: 'flex', justifyContent: 'flex-between' }}>
                <Typography
                  sx={{
                    fontSize: '1.125rem',
                    fontWeight: 'bold',
                    mt: '5px'
                  }}
                >
                  Associate Document Type with Issue Type
                </Typography>
                <IconButton sx={{ ml: 1 }} onClick={refreshData}>
                  <SyncIcon />
                </IconButton>
              </Box>
              <Grid
                container
                spacing={1}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  mt: 2
                }}
              >
                <Grid item lg={4} md={12} sm={12} xs={12}>
                  <CardContent sx={{ ml: 2, mr: 2 }}>
                    <Grid item lg={12} md={6} sm={12} xs={12}>
                      <FormLabel>Select Issue Type</FormLabel>
                      <FormControl size="small" variant="outlined" fullWidth sx={{ mt: 2 }}>
                        <Select
                          displayEmpty
                          inputProps={{ 'aria-label': 'Without label' }}
                          onChange={(event) => {
                            setSortissueType(event.target.value);
                          }}
                        >
                          {issueTypes.map((item, index) => (
                            <MenuItem key={index} value={item.issue_type_name}>
                              <em>{item.issue_type_name}</em>
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </CardContent>
                </Grid>
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{
                    backgroundColor: '#f5f8fa',
                    border: '2px solid #f5f8fa',
                    mb: 2
                  }}
                />
                <Grid item lg={7} md={10} sm={12} xs={12}>
                  {sortissueType === '' ? (
                    <DataNotAvailable />
                  ) : (
                    data.map((row) => <AssociateDocTypeTable rows={row} key={row.id} />)
                  )}
                </Grid>
              </Grid>
            </Container>
          </Card>
        </Container>
      </Page>
    </>
  );
};

export default AssociateDocType;
