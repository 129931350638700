import {
  Container,
  Card,
  Grid,
  CardContent,
  Divider,
  Typography,
  TextField,
  FormLabel,
  Button,
  Box,
  IconButton,
  InputAdornment,
  FormControl,
  Select,
  FormHelperText,
  MenuItem,
  Alert
} from '@mui/material';
import Page from 'components/Page';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import Breadcrumbs from 'components/Breadcrumbs';
import React, { useState, useEffect } from 'react';
import { LoadingButton } from '@mui/lab';
import { useFormik, Form, FormikProvider } from 'formik';
import { useHttpApi } from '../../../state/useHttpApi';
import * as Yup from 'yup';
import { xdcRegex } from 'helpers/regex';
import { useAuthStateShared, useBusinessEntityState, useWeb3, useAppState } from 'state';
import { useSnackbar } from 'notistack';
import { toChecksumAddress, xdcToEthAddress } from 'helpers/web3';
import { useNavigate } from 'react-router';
import CommonOverlay from 'components/CommonOverlay';
import { onlyPrimaryUser } from 'helpers/rbac';

const AddUser = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { throwErrorMessage } = useAppState();

  const [userTypes, setuserTypes] = useState('');
  const [list, setList] = useState([]);
  const {
    onChainIssuers: issuers,
    onChainCustodians: custodians,
    onChainKycProviders: kycProviders,
    originators,
    fetchUser
  } = useBusinessEntityState();
  const { userRegister } = useHttpApi();
  const [error, setError] = useState('');
  const { addIssuerAccounts, addCustodianAccounts, addKycProviderAccounts } = useWeb3();
  const navigate = useNavigate();

  const { role, user } = useAuthStateShared();

  const CreateUserSchema = Yup.object().shape({
    name: Yup.string().required('User Name is required'),
    user_type: Yup.string().required('User Type is required'),
    parent_xinfin_account: Yup.string().required('Select Business Entity'),
    email: Yup.string().email('Invalid Email Format').required('Email is required'),
    password: Yup.string()
      .min(6, 'Password must be minimum 6 characters long')
      .required('Password is required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Password is required'),
    xinfin_address: Yup.string()
      .matches(xdcRegex, 'Invalid XDC Address Format')
      .required('XinFin Address is required'),
    username: Yup.string().required('Username is required')
  });

  const formik = useFormik({
    // validate: (values) => {
    //   const errors = {};
    //   if (
    //     values.user_type === ' ' ||
    //     values.user_type === 'Business Entity' ||
    //     values.user_type === ''
    //   ) {
    //     errors.user_type = 'Please select user type';
    //   }
    //   if (values.parent_xinfin_account === '' || values.parent_xinfin_account === ' ') {
    //     errors.parent_xinfin_account = 'Please select business entity';
    //     formik.setTouched('parent_xinfin_account', true);
    //     formik.setFieldError('parent_xinfin_account', 'Please select business entity');
    //   }
    //   return errors;
    // },
    initialValues: {
      name: '',
      email: '',
      userType: ' ',
      user_type: '',
      username: '',
      xinfin_address: '',
      password: '',
      confirmPassword: ''
    },
    validationSchema: CreateUserSchema,
    onSubmit: async (values, { resetForm }) => {
      /* post data */
      try {
        // alert(JSON.stringify(values));

        values.xinfin_address = xdcToEthAddress(values.xinfin_address);
        values.xinfin_address = toChecksumAddress(values.xinfin_address);
        console.log('values', values);

        console.log(userTypes);
        const response = await userRegister(values);

        if (userTypes === 'ISSUER_OPERATOR') {
          console.log('inside addIssuerAccounts method');
          await addIssuerAccounts(values.parent_xinfin_account, [values.xinfin_address]);
        }
        if (userTypes === 'CUSTODIAN_OPERTOR') {
          console.log('inside addCustodianAccounts method');
          await addCustodianAccounts(values.parent_xinfin_account, [values.xinfin_address]);
        }
        if (userTypes === 'KYC_PROVIDER_OPERATOR') {
          console.log('inside addKycProviderAccounts method');
          await addKycProviderAccounts(values.parent_xinfin_account, [values.xinfin_address]);
        }
        if (response.id) {
          enqueueSnackbar('User Created Successfully', { variant: 'success' });
        }
        resetForm();
        fetchUser();
      } catch (e) {
        const errorMessage = await throwErrorMessage(e);
        setError(errorMessage);
      }
    }
  });

  const handleChangeforTitle = (event) => {
    setuserTypes(event.target.value);
  };

  useEffect(() => {
    if (userTypes === 'ISSUER_OPERATOR' && role === 'PRIMARY_ISSUER') {
      const _primaryIssuer = issuers.filter(
        (issuer) =>
          user.parent_xinfin_account.xinfin_account_address === issuer.primary_issuer_xinfin_address
      );
      console.log('_primaryIssuer', _primaryIssuer);
      setList({
        primary_xinfin_account: _primaryIssuer[0].primary_issuer_xinfin_address,
        name: _primaryIssuer[0].issuer_name
      });
    } else if (userTypes === 'CUSTODIAN_OPERTOR' && role === 'PRIMARY_CUSTODIAN') {
      const _primaryCustodian = custodians.filter(
        (custodian) =>
          user.parent_xinfin_account.xinfin_account_address ===
          custodian.primary_custodian_xinfin_address
      );
      console.log('_primaryCustodian', _primaryCustodian);
      setList({
        primary_xinfin_account: _primaryCustodian[0].primary_custodian_xinfin_address,
        name: _primaryCustodian[0].custodian_name
      });
    } else if (userTypes === 'KYC_PROVIDER_OPERATOR' && role === 'PRIMARY_KYC_PROVIDER') {
      const _primaryKycProvider = kycProviders.filter(
        (kyc) =>
          user.parent_xinfin_account.xinfin_account_address ===
          kyc.primary_kycprovider_xinfin_address
      );
      console.log('_primaryKycProvider', _primaryKycProvider);
      setList({
        primary_xinfin_account: _primaryKycProvider[0].primary_kycprovider_xinfin_address,
        name: _primaryKycProvider[0].kyc_provider_name
      });
    } else if (role === 'OWNER') {
      if (userTypes === 'ISSUER_OPERATOR') {
        const _issuers = issuers.map((item) => {
          return {
            primary_xinfin_account: item.primary_issuer_xinfin_address,
            name: item.issuer_name
          };
        });
        setList(_issuers);
      } else if (userTypes === 'CUSTODIAN_OPERTOR') {
        const _custodians = custodians.map((item) => {
          return {
            primary_xinfin_account: item.primary_custodian_xinfin_address,
            name: item.custodian_name
          };
        });
        setList(_custodians);
      } else if (userTypes === 'KYC_PROVIDER_OPERATOR') {
        const _kycProviders = kycProviders.map((item) => {
          return {
            primary_xinfin_account: item.primary_kycprovider_xinfin_address,
            name: item.kyc_provider_name
          };
        });
        setList(_kycProviders);
      } else if (userTypes === 'Originator') {
        setList(originators);
      } else setList([]);
      console.log('list', list);
    }

    formik.values.user_type = userTypes;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userTypes]);

  console.log('list', list);

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  const allowedRolesList = ['OWNER', 'PRIMARY_ISSUER', 'PRIMARY_CUSTODIAN', 'PRIMARY_KYC_PROVIDER'];
  return (
    <>
      <CommonOverlay
        allowedRoles={allowedRolesList}
        overlayTitle="You don't have access to Add User"
      />
      <Page title="Add User - Master Data">
        <Container sx={{ my: 2 }}>
          <Box sx={{ my: 2 }}>
            <Breadcrumbs pageHead="Add User" aria-label="breadcrumb" />
          </Box>
          <Card>
            {/* {JSON.stringify(errors)} */}
            <Container>
              <Typography
                sx={{
                  fontSize: '1.125rem',
                  fontWeight: 'bold',
                  mt: 4,
                  mb: 1,
                  ml: 4
                }}
              >
                Create User Account Details
              </Typography>
              <Divider
                sx={{
                  width: '100%',
                  backgroundColor: '#f5f8fa',
                  border: '2px solid #f5f8fa'
                }}
              />
              <FormikProvider value={formik}>
                <Form autoComplete="off" onSubmit={handleSubmit}>
                  {error && (
                    <Box mb={4}>
                      <Alert
                        severity="error"
                        sx={{
                          textTransform: 'capitalize'
                        }}
                      >
                        {error}
                      </Alert>
                    </Box>
                  )}
                  <CardContent sx={{ m: 2 }}>
                    <Grid container spacing={3} sx={{ width: '100%' }}>
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <FormLabel>Name</FormLabel>
                        <TextField
                          sx={{ mt: 1.5 }}
                          fullWidth
                          size="small"
                          autoComplete="off"
                          type="text"
                          // placeholder="Enter Valid User Name"
                          {...getFieldProps('name')}
                          error={Boolean(touched.name && errors.name)}
                          helperText={touched.name && errors.name}
                        />
                      </Grid>
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <FormLabel>Email</FormLabel>
                        <TextField
                          sx={{ mt: 1.5 }}
                          fullWidth
                          size="small"
                          autoComplete="off"
                          type="email"
                          // placeholder="Enter Email Address"
                          {...getFieldProps('email')}
                          error={Boolean(touched.email && errors.email)}
                          helperText={touched.email && errors.email}
                        />
                      </Grid>
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <FormLabel>Username</FormLabel>
                        <TextField
                          sx={{ mt: 1.5 }}
                          fullWidth
                          size="small"
                          autoComplete="off"
                          type="text"
                          // placeholder="Enter username"
                          {...getFieldProps('username')}
                          error={Boolean(touched.username && errors.username)}
                          helperText={touched.username && errors.username}
                        />
                      </Grid>
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <FormLabel>User type</FormLabel>

                        <FormControl size="small" variant="outlined" fullWidth sx={{ mt: 1.5 }}>
                          <Select
                            {...getFieldProps('user_type')}
                            error={Boolean(touched.user_type && errors.user_type)}
                            onChange={handleChangeforTitle}
                          >
                            {(role === 'PRIMARY_ISSUER' || role === 'OWNER') && (
                              <MenuItem value="ISSUER_OPERATOR">Issuer</MenuItem>
                            )}
                            {(role === 'PRIMARY_CUSTODIAN' || role === 'OWNER') && (
                              <MenuItem value="CUSTODIAN_OPERTOR">Custodian</MenuItem>
                            )}
                            {(role === 'PRIMARY_KYC_PROVIDER' || role === 'OWNER') && (
                              <MenuItem value="KYC_PROVIDER_OPERATOR">KYC Provider</MenuItem>
                            )}
                          </Select>
                          <FormHelperText sx={{ color: '#d32f2f' }}>
                            {touched.user_type && errors.user_type}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <FormLabel>Select Entity</FormLabel>
                        <FormControl size="small" variant="outlined" fullWidth sx={{ mt: 1.5 }}>
                          <Select
                            {...getFieldProps('parent_xinfin_account')}
                            error={Boolean(
                              touched.parent_xinfin_account && errors.parent_xinfin_account
                            )}
                          >
                            {/* {userTypes &&
                              list.map((list) => {
                                console.log('primary list', list);
                                return (
                                  <MenuItem
                                    key={list.primary_xinfin_account}
                                    value={list.primary_xinfin_account}
                                  >
                                    {list.name}
                                  </MenuItem>
                                );
                              })} */}
                            {role === 'OWNER' ? (
                              list.map((list) => {
                                console.log('primary list', list);
                                return (
                                  <MenuItem
                                    key={list.primary_xinfin_account}
                                    value={list.primary_xinfin_account}
                                  >
                                    {list.name}
                                  </MenuItem>
                                );
                              })
                            ) : (
                              <MenuItem
                                key={list.primary_xinfin_account}
                                value={list.primary_xinfin_account}
                              >
                                {list.name}
                              </MenuItem>
                            )}
                          </Select>

                          <FormHelperText sx={{ color: '#d32f2f' }}>
                            {touched.parent_xinfin_account && errors.parent_xinfin_account}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item lg={6} md={6} sm={12} xs={12} sx={{ pr: 1 }}>
                        <FormLabel> XinFin Address</FormLabel>
                        <TextField
                          sx={{ mt: 1.5 }}
                          fullWidth
                          size="small"
                          autoComplete="off"
                          type="text"
                          // placeholder="Enter Xinfin Address"
                          {...getFieldProps('xinfin_address')}
                          error={Boolean(touched.xinfin_address && errors.xinfin_address)}
                          helperText={touched.xinfin_address && errors.xinfin_address}
                        />
                      </Grid>
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <FormLabel>Password</FormLabel>
                        <TextField
                          sx={{ mt: 1.5 }}
                          fullWidth
                          size="small"
                          autoComplete="off"
                          type={showPassword ? 'text' : 'password'}
                          // placeholder="New Password"
                          {...getFieldProps('password')}
                          error={Boolean(touched.password && errors.password)}
                          helperText={touched.password && errors.password}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={() => setShowPassword((show) => !show)}
                                  edge="end"
                                >
                                  <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                        />
                      </Grid>
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <FormLabel>Confirm Password</FormLabel>
                        <TextField
                          sx={{ mt: 1.5 }}
                          fullWidth
                          size="small"
                          autoComplete="off"
                          type={showConfirmPassword ? 'text' : 'password'}
                          // placeholder="**********"
                          {...getFieldProps('confirmPassword')}
                          error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                          helperText={touched.confirmPassword && errors.confirmPassword}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={() => setShowConfirmPassword((show) => !show)}
                                  edge="end"
                                >
                                  <Icon icon={showConfirmPassword ? eyeFill : eyeOffFill} />
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                        />
                      </Grid>

                      <Grid
                        item
                        lg={12}
                        xs={12}
                        sm={12}
                        sx={{ display: 'flex', justifyContent: 'flex-end' }}
                      >
                        <Button
                          variant="contained"
                          color="secondary"
                          sx={{ mt: 4, ml: 2, height: '2.5rem', width: '7rem' }}
                          onClick={() => {
                            navigate('/masterdata/user');
                          }}
                        >
                          Cancel
                        </Button>
                        <LoadingButton
                          type="submit"
                          disabled={!onlyPrimaryUser(role)}
                          variant="gradient"
                          fullWidth
                          size="large"
                          loading={isSubmitting}
                          loadingPosition="start"
                          sx={{ mt: 4, ml: 3, width: '7.75rem', height: '2.5rem' }}
                        >
                          Save
                        </LoadingButton>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Form>
              </FormikProvider>
            </Container>
          </Card>
        </Container>
      </Page>
      )
    </>
  );
};

export default AddUser;
