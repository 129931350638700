import {
  Container,
  Card,
  Grid,
  Box,
  CardContent,
  Typography,
  TextField,
  FormLabel,
  Button,
  Alert,
  AlertTitle,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  IconButton,
  InputAdornment
} from '@mui/material';

import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import {
  useBusinessEntityState,
  useSubscriptionState,
  useCoreTableState,
  useHttpApi,
  useWeb3,
  useAppState,
  useMagicState
} from 'state';
import React, { useState, useRef, useEffect } from 'react';
import Page from 'components/Page';
import { LoadingButton } from '@mui/lab';
import { useFormik, Form, FormikProvider, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { useSnackbar } from 'notistack';
import FocusError from 'components/FocusError';
import { ethToXdcAddress } from 'helpers/web3';

import MuiPhoneInput from 'material-ui-phone-number';
import parsePhoneNumber from 'libphonenumber-js';
import { debounce } from 'helpers/handler';

import MagicLinkModal from 'components/authentication/login/MagicLinkModal';

const AddInvestor = () => {
  const { submitLoginForm, setSubmitLoginForm } = useMagicState();
  const [showPassword, setShowPassword] = useState(false);
  const [openOtpModal, setOpenOtpModal] = useState(false);
  const [subInitialValues, setSubInitialValues] = useState({
    investor_username: '',
    investor_name: '',
    investor_email_id: '',
    investor_phone_no: '',
    investor_country_code: '',
    investor_password: '',
    investor_type: ''
  });

  // otp modal
  // const {otpVerificationOngoing, setOtpVerificationOngoing } = useAppState();
  const otpLaunchModalRef = useRef(null);
  const otpModalCloseRef = useRef(null);
  const modelRef = useRef(null);
  const [submitting, setSubmitting] = useState(false);
  const [tryCount, setTryCount] = useState(0);
  const [otp, setOtp] = useState(['', '', '', '', '', '']);
  const {
    verifyOtp,
    account,
    connectMagicWallet,
    otpVerificationOngoing,
    setOtpVerificationOngoing,
    connectedAccount,
    otpVerificationComplete,
    setOtpVerificationComplete
  } = useWeb3();
  const inputRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null)
  ];

  const _createInvestor = async () => {
    try {
      if (otpVerificationComplete && connectedAccount && formik.dirty) {
        const data = formik.values;
        data.investor_name = data.investor_username.toLowerCase();
        data.xinfin_address = connectedAccount;
        const res = await createInvestor(data);
        if (res) {
          setOtpVerificationComplete(false);
          formik.resetForm();
          navigate('/login');
        }
      }
    } catch (e) {
      throwErrorMessage(e);
    }
  };

  useEffect(() => {
    if (otpVerificationComplete) {
      _createInvestor();
    }
  }, [otpVerificationComplete]);

  const CreateUserSchema = Yup.object().shape({
    investor_username: Yup.string().required('Username is required'),
    investor_name: Yup.string().required('Name is required'),
    investor_email_id: Yup.string().email('Invalid Email Format').required('Email is required'),
    investor_country_code: Yup.string().required('Country code is required'),
    investor_type: Yup.string().required('Select the type of investor'),
    investor_password: Yup.string().required('Password is required')
  });
  const navigate = useNavigate();

  const [disableSubmit, setDisableSubmit] = useState(false);
  const [investorPhone, setInvestorPhone] = useState('');
  const [invalidPhone, setInvalidPhone] = useState();
  const { createInvestor } = useHttpApi();
  const { throwErrorMessage } = useAppState();
  const [error, setError] = useState(null);
  const { countries } = useCoreTableState();
  const phoneNumberHandler = (value) => {
    let countryCode;
    const errors = {};
    setInvalidPhone(true);
    errors.investor_phone_no = 'Enter Valid Phone Number';
    const phoneNumber = parsePhoneNumber(value);
    if (phoneNumber) {
      countryCode = phoneNumber.country;
      if (countryCode) {
        const _phoneNumberValidityParse = parsePhoneNumber(value, countryCode);
        if (_phoneNumberValidityParse.isValid()) {
          formik.setFieldValue('investor_phone_no', _phoneNumberValidityParse.number);
          delete errors.investor_phone_no;
          console.log('phone details', _phoneNumberValidityParse);
          console.log('Phone Validity', phoneNumber.isValid());
          console.log('Phone Num', _phoneNumberValidityParse.number);
          setInvalidPhone(false);
        }
      }
    }
    return errors;
  };
  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const formik = useFormik({
    initialValues: subInitialValues,

    validate: (values) => {
      const errors = {};
      if (values.investor_phone_no === '') {
        errors.investor_phone_no = 'Enter Phone Number';
      }
      if (invalidPhone) {
        errors.investor_phone_no = 'Enter Valid Phone Number';
      }

      return errors;
    },

    validationSchema: CreateUserSchema,
    onSubmit: async (data, { resetForm }) => {
      try {
        setSubmitting(true);
        setTryCount(0);
        setOtp(['', '', '', '', '', '']);
        setSubmitLoginForm(true);
        const wallet = await connectMagicWallet(
          data.investor_email_id,
          otpLaunchModalRef,
          inputRefs
        );
      } catch (e) {
        console.log(e);
        setSubmitting(false);
        throwErrorMessage(e);
      }
    }
  });
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <>
      <Container sx={{ mt: 4 }}>
        {error && (
          <Box mb={4}>
            <Alert severity="error">{error}</Alert>
          </Box>
        )}

        <Card>
          <CardContent sx={{ m: 4 }}>
            <FormikProvider value={formik} validator={() => ({})}>
              <Form autoComplete="off" onSubmit={handleSubmit}>
                <Card
                  sx={{
                    boxShadow: 'none',
                    border: '1px solid #D2D2D2',
                    borderRadius: '6px',
                    px: 4,
                    py: 1
                  }}
                >
                  <Typography sx={{ mt: 2, fontSize: '1.25rem', fontWeight: 'bold' }}>
                    Investor Sign up
                  </Typography>
                  <Grid container spacing={3} sx={{ width: '100%', py: 2 }}>
                    <Grid item lg={6} md={6} xs={12}>
                      <FormLabel>Name</FormLabel>
                      <TextField
                        sx={{ mt: 1.5 }}
                        fullWidth
                        size="small"
                        autoComplete="Name"
                        type="text"
                        // placeholder="Enter Your Name"
                        {...getFieldProps('investor_name')}
                        error={Boolean(touched.investor_name && errors.investor_name)}
                        helperText={touched.investor_name && errors.investor_name}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xs={12}>
                      <FormLabel>Email</FormLabel>
                      <TextField
                        sx={{ mt: 1.5 }}
                        fullWidth
                        size="small"
                        autoComplete="email"
                        type="email"
                        {...getFieldProps('investor_email_id')}
                        error={Boolean(touched.investor_email_id && errors.investor_email_id)}
                        helperText={touched.investor_email_id && errors.investor_email_id}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xs={12}>
                      <FormLabel>Username</FormLabel>
                      <TextField
                        sx={{ mt: 1.5 }}
                        fullWidth
                        size="small"
                        autoComplete="Name"
                        type="text"
                        // placeholder="Enter Your Name"
                        {...getFieldProps('investor_username')}
                        error={Boolean(touched.investor_username && errors.investor_username)}
                        helperText={touched.investor_username && errors.investor_username}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xs={12}>
                      <FormLabel>Password</FormLabel>

                      <TextField
                        fullWidth
                        autoComplete="current-investor_password"
                        type={showPassword ? 'text' : 'password'}
                        placeholder="Enter Your Password"
                        id="investor_password"
                        sx={{
                          outline: 'none'
                        }}
                        {...getFieldProps('investor_password')}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton onClick={handleShowPassword} edge="end">
                                <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                        error={Boolean(touched.investor_password && errors.investor_password)}
                        helperText={touched.investor_password && errors.investor_password}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xs={12} sm={12}>
                      <FormLabel>Investor Type</FormLabel>

                      <FormControl size="small" variant="outlined" fullWidth sx={{ mt: 1.5 }}>
                        <Select
                          {...getFieldProps('investor_type')}
                          error={Boolean(touched.investor_type && errors.investor_type)}
                          helperText={touched.investor_type && errors.investor_type}
                        >
                          <MenuItem key="INDIVIDUAL" value="INDIVIDUAL">
                            Individual
                          </MenuItem>
                          <MenuItem key="CORPORATE" value="CORPORATE">
                            Corporate
                          </MenuItem>
                        </Select>
                        <FormHelperText sx={{ color: '#d32f2f' }}>
                          {touched.investor_type && errors.investor_type}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item lg={6} md={6} xs={12} sm={12}>
                      <FormLabel>Country</FormLabel>

                      <FormControl size="small" variant="outlined" fullWidth sx={{ mt: 1.5 }}>
                        <Select
                          {...getFieldProps('investor_country_code')}
                          error={Boolean(
                            touched.investor_country_code && errors.investor_country_code
                          )}
                          helperText={touched.investor_country_code && errors.investor_country_code}
                        >
                          {countries &&
                            countries.map((country, index) => {
                              return (
                                <MenuItem key={country.id} value={country.id}>
                                  {country.country_name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                        <FormHelperText sx={{ color: '#d32f2f' }}>
                          {touched.investor_country_code && errors.investor_country_code}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item lg={6} md={6} xs={12}>
                      <FormLabel>Phone Number</FormLabel>

                      <MuiPhoneInput
                        value={investorPhone}
                        sx={{ mt: 1.5 }}
                        fullWidth
                        defaultCountry="us"
                        variant="outlined"
                        size="small"
                        {...getFieldProps('investor_phone_no')}
                        onChange={(value) => {
                          debounce(phoneNumberHandler)(value);
                        }}
                        error={Boolean(touched.investor_phone_no && errors.investor_phone_no)}
                        helperText={touched.investor_phone_no && errors.investor_phone_no}
                      />
                    </Grid>
                  </Grid>
                </Card>

                <Grid
                  container
                  sx={{
                    width: '100%',
                    mt: 2,
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-end'
                  }}
                >
                  <Grid
                    item
                    lg={12}
                    md={12}
                    xs={12}
                    sx={{ display: 'flex', justifyContent: 'flex-end' }}
                  >
                    <Button
                      sx={{
                        px: 3,
                        mr: 1,
                        width: '7.75rem',
                        height: '2.5rem'
                      }}
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        navigate('/login');
                      }}
                    >
                      Cancel
                    </Button>
                    <LoadingButton
                      sx={{
                        width: '7.5rem',
                        height: '2.5rem'
                      }}
                      variant="gradient"
                      type="submit"
                      loadingPosition="start"
                      loading={isSubmitting || submitLoginForm}
                      disabled={disableSubmit}
                    >
                      Submit
                    </LoadingButton>
                  </Grid>
                </Grid>
                <FocusError />
              </Form>

              <Button
                ref={otpLaunchModalRef}
                onClick={() => {
                  setOpenOtpModal(!openOtpModal);
                  setSubmitLoginForm(false);
                }}
                sx={{ display: 'none' }}
              >
                Open modal
              </Button>
              <MagicLinkModal
                openModal={openOtpModal}
                userEmail={formik.values.investor_email_id}
                setOpenOtpModal={() => setOpenOtpModal()}
              />
            </FormikProvider>
          </CardContent>
        </Card>
      </Container>
    </>
  );
};

export default AddInvestor;
