import {
  Container,
  Card,
  Grid,
  Box,
  CardContent,
  Divider,
  Typography,
  TextField,
  FormLabel,
  Button,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  Alert,
  Modal,
  IconButton
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';

import Page from 'components/Page';
import React, { useEffect, useState, useRef } from 'react';
import Breadcrumbs from 'components/Breadcrumbs';
import { useBusinessEntityState } from 'state/useBusinessEntityState';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { useHttpApi, useCoreTableState, useWeb3, useAuthStateShared, useAppState } from 'state';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { xdcRegex } from 'helpers/regex';
import { xdcToEthAddress, toChecksumAddress, ethToXdcAddress } from 'helpers/web3';
import { useNavigate } from 'react-router';
import { LeiInfoButton, XdcInfoButton } from 'helpers/infoButton';
import CommonOverlay from 'components/CommonOverlay';
import { onlyOwner } from 'helpers/rbac';
import LogoPreview from 'components/masterData/LogoPreview';

const EditLiquidityPool = () => {
  const { editLiquidityPool } = useHttpApi();
  const { role } = useAuthStateShared();

  const { currentLiquidityPool, fetchLiquidityPool } = useBusinessEntityState();
  const { throwErrorMessage } = useAppState();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [error, setError] = useState(null);

  // Issuer Schema
  const EditLiquidityPoolSchema = Yup.object().shape({
    liquidity_pool_name: Yup.string().required('Liquidity Pool Name is required'),
    liquidity_pool_country_code: Yup.string().required('Country Code is required'),
    liquidity_pool_xinfin_address: Yup.string()
      .matches(xdcRegex, 'Please enter a valid Liquidity Pool Address')
      .required('Liquidity Pool Address is required'),
    liquidity_pool_settlement_address: Yup.string()
      .matches(xdcRegex, 'Please enter a valid Settlement Address')
      .required('Settlement Address is required')
  });

  // blockchain call
  const { addLiquidityPool: addLiquidityPoolBlockchain } = useWeb3();

  // Formik
  const formik = useFormik({
    initialValues: currentLiquidityPool,
    validationSchema: EditLiquidityPoolSchema,
    onSubmit: async (data, { resetForm }) => {
      // console.log('EditLiquidityPool', data);
      try {
        setError(null);
        delete data.liquidity_pool_logo;

        if (liquidityPoolLogo.current.files[0]) {
          const liquidityPoolLogoFile = liquidityPoolLogo.current.files[0];
          data.liquidity_pool_logo = liquidityPoolLogoFile;
        }

        data.liquidity_pool_xinfin_address = xdcToEthAddress(data.liquidity_pool_xinfin_address);
        data.liquidity_pool_xinfin_address = toChecksumAddress(data.liquidity_pool_xinfin_address);
        data.liquidity_pool_settlement_address = toChecksumAddress(
          xdcToEthAddress(data.liquidity_pool_settlement_address)
        );

        console.log('EditLiquidityPool', data);
        const countryCode = countries.find(
          (country) => country.id === data.liquidity_pool_country_code
        );
        // console.log('countryCode', countryCode.country_code);

        if (currentLiquidityPool.status === 'OFFCHAIN') {
          try {
            const res = await addLiquidityPoolBlockchain(
              data.liquidity_pool_xinfin_address,
              data.liquidity_pool_settlement_address
            );
          } catch (err) {
            console.log(err);
          }
        }
        delete data.status;

        const _EditLiquidityPool = await editLiquidityPool(
          currentLiquidityPool.liquidity_pool_id,
          data
        );
        if (_EditLiquidityPool.liquidity_pool_id) {
          enqueueSnackbar('Liquidity Pool Updated Successfully', {
            variant: 'success'
          });
          resetForm();
          fetchLiquidityPool();
          navigate('/masterdata/liquidity-pool');
        }
      } catch (e) {
        throwErrorMessage(e);
      }
    }
  });
  const liquidityPoolLogo = useRef(null);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const { countries, orgnizations } = useCoreTableState();
  const navigate = useNavigate();

  const allowedRolesList = ['OWNER'];

  return (
    <>
      <CommonOverlay
        allowedRoles={allowedRolesList}
        overlayTitle="You don't have access to Edit Liquidity Pool"
      />
      <Page title="Edit Liquidity Pool - Master Data">
        <Container sx={{ my: 2 }}>
          <Box sx={{ my: 2 }}>
            <Breadcrumbs pageHead="Edit Liquidity Pool" aria-label="breadcrumb" />
          </Box>
          <Card>
            {error && (
              <Box mb={4}>
                <Alert severity="error">{error}</Alert>
              </Box>
            )}
            <Container>
              <Typography
                sx={{
                  fontSize: '1.125rem',
                  fontWeight: 'bold',
                  mt: 4,
                  mb: 1,
                  ml: 4
                }}
              >
                Liquidity Pool Details
              </Typography>
              <Divider
                sx={{
                  width: '100%',
                  backgroundColor: '#f5f8fa',
                  border: '2px solid #f5f8fa'
                }}
              />
              <FormikProvider value={formik}>
                <Form autocomplete="off" onSubmit={handleSubmit}>
                  <CardContent sx={{ m: 2 }}>
                    <Grid container spacing={3} sx={{ width: '100%' }}>
                      <Grid item lg={6} md={6} xs={12} sm={12}>
                        <FormLabel>Liquidity Pool Name</FormLabel>
                        <TextField
                          sx={{ mt: 1.5 }}
                          fullWidth
                          size="small"
                          autoComplete="off"
                          type="text"
                          {...getFieldProps('liquidity_pool_name')}
                          error={Boolean(touched.liquidity_pool_name && errors.liquidity_pool_name)}
                          helperText={touched.liquidity_pool_name && errors.liquidity_pool_name}
                        />
                      </Grid>

                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <FormLabel>Liquidity Pool Logo</FormLabel>
                        <Grid
                          Container
                          sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                        >
                          <Grid item lg={11} md={11} sm={11} xs={10}>
                            <TextField
                              sx={{ mt: 1.5 }}
                              fullWidth
                              size="small"
                              autoComplete="off"
                              type="file"
                              inputRef={liquidityPoolLogo}
                              inputProps={{ accept: '.jpg, .jpeg, .png, .svg' }}
                              {...getFieldProps('liquidity_pool_logo_doc')}
                              id="liquidity_pool_logo_doc"
                              error={Boolean(
                                touched.liquidity_pool_logo_doc && errors.liquidity_pool_logo_doc
                              )}
                              helperText={
                                touched.liquidity_pool_logo_doc && errors.liquidity_pool_logo_doc
                              }
                            />
                            <Grid container sx={{ width: '100%', mt: 2 }}>
                              <Grid item lg={4} md={4} sm={4}>
                                <Typography
                                  sx={{
                                    fontSize: '0.688rem',
                                    textAlign: 'left',
                                    color: '#161c2d'
                                  }}
                                >
                                  Maximum 50KB file size
                                </Typography>
                              </Grid>
                              <Grid item lg={8} md={8} sm={8}>
                                <Typography
                                  sx={{
                                    fontSize: '0.688rem',
                                    textAlign: 'right',
                                    color: '#8e8ea7'
                                  }}
                                >
                                  Accepted File Type .jpg, .jpeg, .png & .svg only
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid item lg={1} md={1} sm={1} xs={1}>
                            <IconButton
                              aria-label="subs detail"
                              onClick={handleOpen}
                              sx={{ mt: -3 }}
                            >
                              <VisibilityIcon
                                sx={{ fontSize: '2rem', p: 1, m: 0, backgroundColor: '#F5F8FA' }}
                              />
                            </IconButton>
                            <Modal
                              open={open}
                              onClose={handleClose}
                              aria-labelledby="modal-modal-title"
                              aria-describedby="modal-modal-description"
                            >
                              <LogoPreview
                                imgSrc={currentLiquidityPool.liquidity_pool_logo}
                                handleClose={handleClose}
                              />
                            </Modal>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item lg={6} md={6} xs={12} sm={12}>
                        <FormLabel>Liquidity Pool Username</FormLabel>
                        <TextField
                          inputProps={{ readOnly: true }}
                          sx={{ mt: 1.5 }}
                          fullWidth
                          size="small"
                          autoComplete="off"
                          type="text"
                          {...getFieldProps('username')}
                        />
                      </Grid>
                      <Grid item lg={6} md={6} xs={12} sm={12}>
                        <FormLabel>Liquidity Pool Email</FormLabel>
                        <TextField
                          inputProps={{ readOnly: true }}
                          sx={{ mt: 1.5 }}
                          fullWidth
                          size="small"
                          autoComplete="off"
                          type="text"
                          {...getFieldProps('email')}
                        />
                      </Grid>

                      {/* <Grid item lg={6} md={6} xs={12} sm={12}>
                        <FormLabel>
                          LEI
                          <LeiInfoButton />
                        </FormLabel>
                        <TextField
                          maxLength={50}
                          sx={{ mt: 1.5 }}
                          fullWidth
                          size="small"
                          autoComplete="off"
                          type="text"
                          // placeholder="LEI"
                          {...getFieldProps('lei')}
                          error={Boolean(touched.lei && errors.lei)}
                          helperText={touched.lei && errors.lei}
                        />
                      </Grid> */}

                      <Grid item lg={6} md={6} xs={12} sm={12}>
                        <FormLabel>Liquidity Pool Country Code</FormLabel>

                        <FormControl size="small" variant="outlined" fullWidth sx={{ mt: 1.5 }}>
                          <Select
                            // placeholder="Select Country"
                            inputProps={{ readOnly: true }}
                            {...getFieldProps('liquidity_pool_country_code')}
                            error={Boolean(
                              touched.liquidity_pool_country_code &&
                                errors.liquidity_pool_country_code
                            )}
                          >
                            {countries &&
                              countries.map((country, index) => {
                                return (
                                  <MenuItem key={country.id} value={country.id}>
                                    {country.country_name}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                          <FormHelperText sx={{ color: '#d32f2f' }}>
                            {touched.liquidity_pool_country_code &&
                              errors.liquidity_pool_country_code}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      {/* 
                      <Grid item lg={6} xs={12} sm={12} md={6}>
                        <FormLabel>License No</FormLabel>
                        <TextField
                          sx={{ mt: 1.5 }}
                          fullWidth
                          type="text"
                          size="small"
                          // placeholder="License No"
                          {...getFieldProps('license_No')}
                          error={Boolean(touched.license_No && errors.license_No)}
                          helperText={touched.license_No && errors.license_No}
                        />
                      </Grid> */}

                      <Grid item lg={6} md={6} xs={12} sm={12}>
                        <FormLabel>
                          Liquidity Pool XinFin Address
                          <XdcInfoButton />
                        </FormLabel>
                        <TextField
                          inputProps={{ readOnly: true }}
                          sx={{ mt: 1.5 }}
                          fullWidth
                          type="text"
                          size="small"
                          // placeholder="Primary XinFin Address"
                          {...getFieldProps('liquidity_pool_xinfin_address')}
                          error={Boolean(
                            touched.liquidity_pool_xinfin_address &&
                              errors.liquidity_pool_xinfin_address
                          )}
                          helperText={
                            touched.liquidity_pool_xinfin_address &&
                            errors.liquidity_pool_xinfin_address
                          }
                        />
                      </Grid>

                      <Grid item lg={6} md={6} xs={12} sm={12}>
                        <FormLabel>
                          Liquidity Pool Settlement Address
                          <XdcInfoButton />
                        </FormLabel>
                        <TextField
                          sx={{ mt: 1.5 }}
                          fullWidth
                          type="text"
                          size="small"
                          inputProps={{ readOnly: true }}
                          {...getFieldProps('liquidity_pool_settlement_address')}
                          error={Boolean(
                            touched.liquidity_pool_settlement_address &&
                              errors.liquidity_pool_settlement_address
                          )}
                          helperText={
                            touched.liquidity_pool_settlement_address &&
                            errors.liquidity_pool_settlement_address
                          }
                        />
                      </Grid>
                      <Grid item lg={6} md={6} xs={12} sm={12}>
                        <FormLabel>
                          Liquidity Pool Owner Address
                          <XdcInfoButton />
                        </FormLabel>
                        <TextField
                          sx={{ mt: 1.5 }}
                          fullWidth
                          type="text"
                          size="small"
                          {...getFieldProps('liquidity_pool_owner_xinfin_address')}
                          inputProps={{ readOnly: true }}
                        />
                      </Grid>

                      {/* <Grid item lg={6} md={6} xs={12}>
                        <FormLabel>Address Line 1</FormLabel>
                        <TextField
                          sx={{ mt: 1.5 }}
                          fullWidth
                          size="small"
                          autoComplete="phone number"
                          type="text"
                          // placeholder="Home / Street"
                          {...getFieldProps('address_line_1')}
                          error={Boolean(touched.address_line_1 && errors.address_line_1)}
                          helperText={touched.address_line_1 && errors.address_line_1}
                        />
                      </Grid> */}
                      {/* <Grid item lg={6} md={6} xs={12}>
                        <FormLabel>Address Line 2</FormLabel>
                        <TextField
                          sx={{ mt: 1.5 }}
                          fullWidth
                          size="small"
                          autoComplete="Landmark , etc."
                          type="text"
                          // placeholder="Home / Street"
                          {...getFieldProps('address_line_2')}
                          error={Boolean(touched.address_line_2 && errors.address_line_2)}
                          helperText={touched.address_line_2 && errors.address_line_2}
                        />
                      </Grid> */}
                      {/* <Grid item lg={6} md={6} xs={12}>
                        <FormLabel>City</FormLabel>

                        <TextField
                          sx={{ mt: 1.5 }}
                          size="small"
                          fullWidth
                          type="text"
                          // placeholder="City"
                          {...getFieldProps('city')}
                          error={Boolean(touched.city && errors.city)}
                          helperText={touched.city && errors.city}
                        />
                      </Grid> */}
                      {/* <Grid item lg={6} md={6} xs={12}>
                        <FormLabel>State / Province / Region</FormLabel>
                        <TextField
                          sx={{ mt: 1.5 }}
                          fullWidth
                          size="small"
                          type="text"
                          // placeholder="Enter your State / Province / Region"
                          {...getFieldProps('state')}
                          error={Boolean(touched.state && errors.state)}
                          helperText={touched.state && errors.state}
                        />
                      </Grid> */}
                      {/* <Grid item lg={6} md={6} xs={12}>
                        <FormLabel>ZipCode</FormLabel>
                        <TextField
                          sx={{ mt: 1.5 }}
                          fullWidth
                          type="text"
                          size="small"
                          // placeholder="Your zip code"
                          {...getFieldProps('zip_code')}
                          error={Boolean(touched.zip_code && errors.zip_code)}
                          helperText={touched.zip_code && errors.zip_code}
                        />
                      </Grid> */}
                    </Grid>

                    <Box
                      sx={{
                        mt: 4,
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end'
                      }}
                    >
                      <Button
                        sx={{
                          margin: 1,
                          borderColor: '#24ABDF',
                          px: 3,
                          width: '7.75rem',
                          height: '2.5rem'
                        }}
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                          navigate('/masterdata/issuer');
                        }}
                      >
                        Cancel
                      </Button>
                      <LoadingButton
                        type="submit"
                        disabled={!onlyOwner(role)}
                        variant="gradient"
                        fullWidth
                        size="large"
                        loadingPosition="start"
                        loading={isSubmitting}
                        sx={{ margin: 1, px: 3, width: '7.75rem', height: '2.5rem' }}
                      >
                        Save
                      </LoadingButton>
                    </Box>
                  </CardContent>
                </Form>
              </FormikProvider>
            </Container>
          </Card>
        </Container>
      </Page>
    </>
  );
};

export default EditLiquidityPool;
