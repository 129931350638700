import React, { useRef, useState } from 'react';
import {
  Container,
  Card,
  Grid,
  CardContent,
  Divider,
  Typography,
  TextField,
  FormLabel,
  Button,
  Box,
  Alert,
  IconButton
} from '@mui/material';

import { LoadingButton } from '@mui/lab';
import Page from 'components/Page';

import Breadcrumbs from 'components/Breadcrumbs';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router';

import { useFormik, Form, FormikProvider } from 'formik';

import {
  useBusinessEntityState,
  useHttpApi,
  useWeb3,
  useAuthStateShared,
  useAppState
} from 'state';
import { useCoreTableState } from 'state/useCoreTableState';
import { LeiInfoButton, XdcInfoButton } from 'helpers/infoButton';
import CommonOverlay from 'components/CommonOverlay';
import { onlyOwner } from 'helpers/rbac';
import LogoPreview from 'components/masterData/LogoPreview';
import DownloadIcon from '@mui/icons-material/Download';

const EditKYC = () => {
  const [error, setError] = useState(null);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const kycProviderDoc = useRef(null);

  const { editKycProvider } = useHttpApi();
  const { currentKycProvider, fetchKycProvider } = useBusinessEntityState();
  const { role } = useAuthStateShared();
  const { throwErrorMessage } = useAppState();

  const EditKYCProviderSchema = Yup.object().shape({
    kyc_provider_name: Yup.string().required('Full Name is required'),
    kyc_provider_doc1: Yup.mixed()
      .required('Contract Document required')
      .test('type', 'Only .pdf formats are accepted', (value) => {
        return value && value.substring(value.lastIndexOf('.')) === '.pdf';
      })
  });

  const { addKycProvider: addKycProviderBlockchain } = useWeb3();

  const formik = useFormik({
    initialValues: currentKycProvider,
    validationSchema: EditKYCProviderSchema,
    validate: (values, props) => {
      const errors = {};
      if (values.kyc_provider_doc1) {
        if (kycProviderDoc && kycProviderDoc.current.files[0].size > 2000000) {
          errors.kyc_provider_doc1 = 'File size greater than 2MB';
        }
      }

      return errors;
    },
    onSubmit: async (data, { resetForm }) => {
      console.log('Edit KYC Provider', data);
      try {
        setError(null);

        const kycProviderDocFile = kycProviderDoc.current.files[0];
        if (kycProviderDocFile) {
          data.kyc_provider_document = kycProviderDocFile;
          formik.setFieldValue('kyc_provider_doc1', '');
        } else {
          delete data.kyc_provider_document;
        }

        const _editkycresponse = await editKycProvider(currentKycProvider.kyc_provider_id, data);
        console.log('_kycresponse', _editkycresponse);
        if (_editkycresponse.kyc_provider_id) {
          enqueueSnackbar('KYC Provider updated successfully', {
            variant: 'success'
          });
          resetForm();
          fetchKycProvider();
          navigate('/masterdata/kyc-provider');
        }
      } catch (e) {
        throwErrorMessage(e);
      }
    }
  });

  const { errors, touched, isSubmitting, values, handleSubmit, getFieldProps } = formik;

  const { countries } = useCoreTableState();

  const allowedRolesList = ['OWNER'];
  return (
    <>
      <CommonOverlay
        allowedRoles={allowedRolesList}
        overlayTitle="You don't have access to Edit Primary KYC Provider Account"
      />
      <Page title="Edit KYC Provider - Master Data">
        <Container sx={{ my: 2 }}>
          <Box sx={{ my: 2 }}>
            <Breadcrumbs pageHead="Edit KYC Provider" aria-label="breadcrumb" />
          </Box>
          <Card>
            <Container>
              <Typography
                sx={{
                  fontSize: '1.125rem',
                  fontWeight: 'bold',
                  mt: 4,
                  mb: 1,
                  ml: 4
                }}
              >
                KYC Provider Details
              </Typography>
              <Divider
                sx={{
                  width: '100%',
                  backgroundColor: '#f5f8fa',
                  border: '2px solid #f5f8fa'
                }}
              />
              <FormikProvider value={formik}>
                <Form autoComplete="off" onSubmit={handleSubmit}>
                  {error && (
                    <Box mb={4}>
                      <Alert
                        severity="error"
                        sx={{
                          textTransform: 'capitalize'
                        }}
                      >
                        {error}
                      </Alert>
                    </Box>
                  )}
                  <CardContent sx={{ m: 2 }}>
                    <Grid container spacing={3} sx={{ width: '100%' }}>
                      <Grid item lg={6} sm={12} md={6} xs={12}>
                        <FormLabel>Name</FormLabel>
                        <TextField
                          sx={{ mt: 1.5 }}
                          fullWidth
                          size="small"
                          autoComplete="off"
                          type="text"
                          inputProps={{ readOnly: true }}
                          // placeholder="Enter KYC Provider Name"
                          {...getFieldProps('kyc_provider_name')}
                          error={Boolean(touched.kyc_provider_name && errors.kyc_provider_name)}
                          helperText={touched.kyc_provider_name && errors.kyc_provider_name}
                        />
                      </Grid>

                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <FormLabel>KYC Provider Document</FormLabel>
                        <Grid
                          container
                          sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                        >
                          <Grid item lg={11} md={10} sm={12} xs={12}>
                            <TextField
                              sx={{ mt: 1.5 }}
                              fullWidth
                              size="small"
                              autoComplete="off"
                              type="file"
                              inputProps={{ accept: '.pdf' }}
                              inputRef={kycProviderDoc}
                              {...getFieldProps('kyc_provider_doc1')}
                              error={Boolean(touched.kyc_provider_doc1 && errors.kyc_provider_doc1)}
                              helperText={touched.kyc_provider_doc1 && errors.kyc_provider_doc1}
                            />

                            <Grid container sx={{ width: '100%', mt: 2 }}>
                              <Grid item lg={4} md={4} sm={4}>
                                <Typography
                                  sx={{
                                    fontSize: '0.688rem',
                                    textAlign: 'left',
                                    color: '#161c2d'
                                  }}
                                >
                                  Maximum 2MB file size
                                </Typography>
                              </Grid>
                              <Grid item lg={8} md={8} sm={8}>
                                <Typography
                                  sx={{
                                    fontSize: '0.688rem',
                                    textAlign: 'right',
                                    color: '#8e8ea7'
                                  }}
                                >
                                  Accepted File Type .pdf only
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          {currentKycProvider.kyc_provider_document !== '' && (
                            <Grid item lg={1} md={2} sm={1} xs={1}>
                              <IconButton
                                sx={{ mt: -3 }}
                                aria-label="subs detail"
                                onClick={() =>
                                  window.open(currentKycProvider.kyc_provider_document, '_blank')
                                }
                              >
                                <DownloadIcon
                                  sx={{ fontSize: '2.188rem', p: 1, backgroundColor: '#F5F8FA' }}
                                />
                              </IconButton>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>

                    <Box
                      sx={{
                        mt: 4,
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end'
                      }}
                    >
                      <Button
                        sx={{
                          margin: 1,
                          borderColor: '#24ABDF',
                          px: 3,
                          width: '7.75rem',
                          height: '2.5rem'
                        }}
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                          navigate('/masterdata/kyc-provider');
                        }}
                      >
                        Cancel
                      </Button>
                      <LoadingButton
                        type="submit"
                        disabled={!onlyOwner(role)}
                        variant="gradient"
                        fullWidth
                        size="large"
                        loadingPosition="start"
                        loading={isSubmitting}
                        sx={{ margin: 1, px: 3, width: '7.75rem', height: '2.5rem' }}
                      >
                        Save
                      </LoadingButton>
                    </Box>
                  </CardContent>
                </Form>
              </FormikProvider>
            </Container>
          </Card>
        </Container>
      </Page>
    </>
  );
};

export default EditKYC;
