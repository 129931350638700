import {
  Container,
  Card,
  Grid,
  CardContent,
  Divider,
  Typography,
  TextField,
  FormLabel,
  Button,
  Box,
  FormControl,
  Select,
  MenuItem,
  Checkbox
} from '@mui/material';

import Page from 'components/Page';

import Breadcrumbs from 'components/Breadcrumbs';
import React, { useState } from 'react';

const EditXinFin = () => {
  const [userType, setUserType] = useState('');

  const handleChange = (event) => {
    setUserType(event.target.value);
  };
  return (
    <>
      <Page title="Edit XinFin Account - Master Data">
        <Container sx={{ my: 2 }}>
          <Box sx={{ my: 2 }}>
            <Breadcrumbs pageHead="XinFin Account" aria-label="breadcrumb" />
          </Box>
          <Card>
            <Container>
              <Typography
                sx={{
                  fontSize: '1.125rem',
                  fontWeight: 'bold',
                  mt: 4,
                  mb: 1,
                  ml: 4
                }}
              >
                Edit XinFin Account
              </Typography>
              <Divider
                sx={{
                  width: '100%',
                  backgroundColor: '#f5f8fa',
                  border: '2px solid #f5f8fa'
                }}
              />

              <CardContent sx={{ m: 2 }}>
                <Grid container spacing={3} sx={{ width: '100%' }}>
                  <Grid item lg={6} sm={12} md={6} xs={12} sx={{ pr: 1 }}>
                    <FormLabel>XinFin Account Address</FormLabel>
                    <TextField
                      sx={{ mt: 1.5 }}
                      fullWidth
                      size="small"
                      autoComplete="off"
                      type="text"
                      // placeholder="Enter the XinFin account address"
                    />
                  </Grid>
                  <Grid item lg={6} sm={12} md={6} xs={12} sx={{ pl: 0.5 }}>
                    <FormLabel>Is Multi Sig Wallet</FormLabel>
                    <Grid container sx={{ mt: 1 }}>
                      <Grid item lg={6} sm={6} md={6} xs={6}>
                        <Checkbox edge="start" />
                        TRUE
                      </Grid>
                      <Grid item lg={6} sm={6} md={6} xs={6}>
                        <Checkbox />
                        FALSE
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item lg={6} sm={12} md={6} xs={12} sx={{ pl: 0.5 }}>
                    <FormLabel>Is EoA</FormLabel>
                    <Grid container sx={{ mt: 1 }}>
                      <Grid item lg={6} sm={6} md={6} xs={6}>
                        <Checkbox edge="start" />
                        TRUE
                      </Grid>
                      <Grid item lg={6} sm={6} md={6} xs={6}>
                        <Checkbox />
                        FALSE
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item lg={6} sm={12} md={6} xs={12} sx={{ pl: 0.5 }}>
                    <FormLabel>Is Valid</FormLabel>
                    <Grid container sx={{ mt: 1 }}>
                      <Grid item lg={6} sm={6} md={6} xs={6}>
                        <Checkbox edge="start" />
                        TRUE
                      </Grid>
                      <Grid item lg={6} sm={6} md={6} xs={6}>
                        <Checkbox />
                        FALSE
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item lg={6} sm={12} md={6} xs={12}>
                    <FormLabel>KYC status</FormLabel>

                    <FormControl size="small" variant="outlined" fullWidth sx={{ mt: 1.5 }}>
                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                        value={userType}
                        onChange={handleChange}
                      >
                        <MenuItem value={10}>Issuer</MenuItem>
                        <MenuItem value={20}>Custodian</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item lg={6} sm={12} md={6} xs={12} sx={{ pl: 0.5 }}>
                    <FormLabel>Is whitelisted</FormLabel>
                    <Grid container sx={{ mt: 1 }}>
                      <Grid item lg={6} sm={6} md={6} xs={6}>
                        <Checkbox edge="start" />
                        TRUE
                      </Grid>
                      <Grid item lg={6} sm={6} md={6} xs={6}>
                        <Checkbox />
                        FALSE
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    sx={{ display: 'flex', justifyContent: 'flex-end' }}
                  >
                    <Button
                      variant="outlined"
                      sx={{ mt: 4, ml: 2, height: '2.7rem', width: '7rem' }}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="gradient"
                      sx={{ mt: 4, ml: 2, height: '2.7rem', width: '7rem' }}
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Container>
          </Card>
        </Container>
      </Page>
    </>
  );
};

export default EditXinFin;
