import React, { useCallback, useEffect, useMemo, useState } from 'react';
import EnhancedTable from 'components/tables/EnhancedTable';
import {
  Box,
  IconButton,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  Alert,
  AlertTitle,
  Typography
} from '@mui/material';

import Delete from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useBusinessEntityState, useCoreTableState, useAppState, useHttpApi } from 'state';
import { useSnackbar } from 'notistack';
import { useIsOwner } from 'helpers/rbac';
import LoadingActionButton from 'helpers/LoadingActionButton';
import EditIssueType from './EditIssueType';

const ActionButtons = ({ original, id, issueTypeName }) => {
  // const { currentIssuer, fetchIssuerInsurerMappingByIssuerId, setEditCurrentIssuerInsurer } =
  useBusinessEntityState();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  // const { deleteIssuerInsurerMapping } = useHttpApi();
  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const { throwErrorMessage } = useAppState();
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleEditClose = () => {
    setEditOpen(false);
  };
  const handleEditOpen = () => {
    setEditOpen(true);
  };
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          m: 0,
          p: 0,
          alignItems: 'center'
        }}
      >
        <IconButton
          aria-label="subs detail"
          onClick={() => {
            /*  setEditCurrentIssuerInsurer(original); */
            handleEditOpen();
          }}
        >
          <EditIcon sx={{ fontSize: '2.188rem', m: 0, p: 1, backgroundColor: '#F5F8FA' }} />
        </IconButton>
        <Dialog open={editOpen} onClose={handleEditClose} aria-labelledby="alert-dialog-title">
          <DialogContent sx={{ m: 3 }}>
            <EditIssueType handleEditClose={handleEditClose} original={original} />
          </DialogContent>
        </Dialog>
        <IconButton aria-label="subs detail" onClick={handleOpen}>
          <Delete sx={{ fontSize: '2.188rem', p: 1, backgroundColor: '#F5F8FA' }} />
        </IconButton>
      </Box>
      <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title">
        <Alert severity="warning">
          <AlertTitle>Delete Issue Type</AlertTitle>
          <DialogContent>
            Are you sure you want to delete Issue Type, {issueTypeName}?
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleClose}
              sx={{ width: '6.7rem' }}
            >
              Cancel
            </Button>
            <LoadingActionButton
              variant="outlined"
              color="error"
              loadingPosition="start"
              sx={{ width: '6.7rem' }}
              onClick={async () => {
                try {
                  console.log(id);
                  /* await deleteIssuerInsurerMapping(currentIssuer.issuer_id, id);
                  enqueueSnackbar('Issue Type deleted successfully', {
                    variant: 'success'
                  });
                  fetchIssuerInsurerMappingByIssuerId(currentIssuer.issuer_id); */
                } catch (e) {
                  throwErrorMessage(e);
                }
                handleClose();
              }}
            >
              Delete
            </LoadingActionButton>
          </DialogActions>
        </Alert>
      </Dialog>
    </>
  );
};

export default function AllIssueTypes() {
  const { issueTypes, fetchIssueTypes } = useBusinessEntityState();
  const [data, setData] = useState([]);
  const isOwner = useIsOwner();

  useEffect(() => {
    setData(issueTypes);
  }, issueTypes);

  useEffect(() => {
    const _fetchIssueTypes = async () => {
      await fetchIssueTypes();
    };
    _fetchIssueTypes();
  }, []);

  const headCells = useMemo(
    () => [
      {
        accessor: 'issue_type_name',
        Header: 'Issue Type',
        show: true
      },
      {
        accessor: 'actions',
        Header: 'Actions',
        Cell: ({ row: { original } }) => {
          return (
            <ActionButtons
              original={original}
              id={original.id}
              issueTypeName={original.issue_type_name}
            />
          );
        },
        show: isOwner
      }
    ],
    [isOwner]
  );

  return (
    <>
      <EnhancedTable
        tableTitle="Issue Types"
        columns={headCells}
        refreshFunction={fetchIssueTypes}
        data={data}
      />
    </>
  );
}
