import { Box, Card, CardContent, Typography, Switch, IconButton } from '@mui/material';
import React from 'react';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useWeb3 } from 'state';
import AddressFieldTools from 'components/AddressFieldTools';
import { useNavigate } from 'react-router';
// eslint-disable-next-line react/prop-types
const ToggleCard = ({ title, currPauseState, handleChange, disabled }) => {
  return (
    <Card sx={{ my: 1 }}>
      <CardContent
        sx={{
          p: '0 !important'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            mx: 2,
            justifyContent: 'space-between'
          }}
        >
          <Typography
            variant="body2"
            sx={{
              fontWeight: 'bold'
            }}
          >
            {title}
          </Typography>
          <Switch checked={currPauseState} onChange={handleChange} disabled={disabled} />
        </Box>
      </CardContent>
    </Card>
  );
};

// Block Explorer
export const ToggleBlockExplorer = ({ title, contractAdd }) => {
  const { chainId } = useWeb3();
  // console.log('chhhaiiinnn', chainId);
  return (
    <Card sx={{ my: 1 }}>
      <CardContent
        sx={{
          p: '0 !important'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            mx: 2,
            justifyContent: 'space-between'
          }}
        >
          <Typography
            variant="body2"
            sx={{
              fontWeight: 'bold'
            }}
          >
            {title}
          </Typography>
          <AddressFieldTools address={contractAdd} showAddress={false} showInBlockExplorer />
        </Box>
      </CardContent>
    </Card>
  );
};

export const ViewNotificationCard = ({ title, tokenNotifications }) => {
  // const { chainId } = useWeb3();
  // console.log('chhhaiiinnn', chainId);
  const navigate = useNavigate();
  return (
    <Card sx={{ my: 1 }}>
      <CardContent
        sx={{
          p: '0 !important'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            mx: 2,
            justifyContent: 'space-between',
            cursor: 'pointer',
            py: 1
          }}
          onClick={() => {
            navigate('/home/token-dashboard/token-notifications');
          }}
        >
          <Typography
            variant="body2"
            sx={{
              fontWeight: 'bold'
            }}
          >
            {title}
          </Typography>
          <Typography sx={{ fontSize: '0.9rem' }}>
            {tokenNotifications.length !== 0 ? tokenNotifications.length : 0}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export default ToggleCard;
