import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import TVL from 'mocks/TotalValueLock.json';
import { Area, AreaChart, Tooltip, YAxis } from 'recharts';
import { Box } from '@mui/system';
import { epochToDate } from 'helpers/handler';
import { useSubscriptionState } from 'state';
import { format } from 'date-fns';

const TVLChart = () => {
  //   const data = TVL.reverse();

  const { fetchDailyTvl, dailyTvl } = useSubscriptionState();

  useEffect(() => {
    fetchDailyTvl();
  }, []);

  // const dailyTvl = TVL;

  const maxPoolValue = Math.max(
    ...dailyTvl.map((tvl) => {
      return tvl.tvl_value;
    })
  );
  const currentDayTotalPoolValue =
    dailyTvl.length > 0 ? dailyTvl[dailyTvl.length - 1].tvl_value : 0;

  const [hoveredValue, setHoveredValue] = useState(undefined);
  const TVLDisplay = ({ value }) => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'baseline'
          }}
        >
          <Typography variant="h3">{value ? value.tvl_value : currentDayTotalPoolValue}</Typography>
          <Typography variant="h5"> USXD</Typography>
        </Box>
        <Typography variant="h6">
          {value
            ? `TVL as on ${format(new Date(value.tvl_timestamp), 'PPP')}`
            : 'Total Value Locked'}
        </Typography>
        <Typography variant="p" sx={{ color: '#8e8ea7' }}>
          (Total value equivalent to USXD)
        </Typography>
      </Box>
    );
  };

  return (
    <Card>
      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Box sx={{ m: 2 }}>
          <AreaChart
            width={500}
            height={200}
            data={dailyTvl}
            margin={{ top: 4, right: 4, left: 4, bottom: 4 }}
            onMouseMove={(item) => {
              if (item.activePayload) {
                // console.log(item.activePayload[0].payload);
                setHoveredValue(item.activePayload[0].payload);
              }
            }}
            onMouseLeave={() => {
              // console.log('leave');
              setHoveredValue(undefined);
            }}
          >
            <defs>
              <linearGradient id="colorPoolValue" x1="0" y1="0" x2="0" y2="1">
                <stop offset="12%" stopColor="#25aae1" stopOpacity={0.7} />
                <stop offset="45%" stopColor="#18bfc5" stopOpacity={0.5} />
                <stop offset="95%" stopColor="#0dd1ae" stopOpacity={0.05} />
              </linearGradient>
            </defs>
            <YAxis type="number" domain={[0, maxPoolValue]} hide />
            <Area
              type="monotone"
              dataKey="tvl_value"
              stroke="#0828BE"
              strokeWidth={2}
              fillOpacity={1}
              fill="url(#colorPoolValue)"
              name="Current Value Locked (DAI)"
            />
            <Tooltip content={<></>} />
          </AreaChart>
        </Box>
        <Box sx={{ m: 2 }}>
          <TVLDisplay value={hoveredValue} />
        </Box>
      </CardContent>
    </Card>
  );
};

export default TVLChart;
