import PropTypes from 'prop-types';

import { Box } from '@mui/material';
import yplogo from '../assets/images/yplogo.png';
import { useAuthStateShared } from 'state';
import { isDevEnv } from 'helpers/dev';
import { API_URL } from 'config';

const Logo = ({ sx }) => {
  const { user, orgLogo } = useAuthStateShared();
  return orgLogo ? (
    <Box
      component="img"
      src={isDevEnv ? `${API_URL}${orgLogo.substring(1)}` : `${orgLogo}`}
      sx={{ ...sx }}
    />
  ) : (
    <Box component="img" src={yplogo} sx={{ ...sx }} />
  );
};

Logo.propTypes = {
  sx: PropTypes.object
};

export default Logo;
