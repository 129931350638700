import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import Breadcrumbs from 'components/Breadcrumbs';
import Page from 'components/Page';
import { currentNetwork, ethToXdcAddress } from 'helpers/web3';
import AddressFieldTools from 'components/AddressFieldTools';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useAuthStateShared, useBusinessEntityState } from 'state';
import InvestorIssuanceEscrowTable from 'components/subscritiption/InvestorIssuanceEscrowTable';
import InvestorViewAllotment from 'components/subscritiption/InvestorViewAllotment';
import InvestorRedemptionEscrowTable from 'components/subscritiption/InvestorRedemptionEscrowTable';
import InvestorViewRedemption from 'components/subscritiption/InvestorViewRedemptions';
import AccordionLayout from 'helpers/AccordionLayout';
import AddPoolPaymentTokens from 'components/masterData/LiquidityPool/AddPoolPaymentTokens';
import PoolPaymentTokenTable from 'components/masterData/LiquidityPool/PoolPaymentTokenTable';
import IssuerViewRedemptions from 'components/subscritiption/IssuerViewRedemptions';
import PoolRedemptionEscrowTable from 'components/escrow/PoolRedemptionEscrowTable';

const LiquidityPoolDashboard = () => {
  const { entity } = useAuthStateShared();

  //   const { loggedInPool } = useBusinessEntityState();

  const poolAddr =
    entity?.liquidity_pool_xinfin_address && ethToXdcAddress(entity?.liquidity_pool_xinfin_address);

  return (
    <>
      <Page title="Liquidity Pool Dashboard" />
      <Container>
        <Breadcrumbs pageHead="Liquidity Pool Dashboard" />
        <Accordion
          defaultExpanded
          sx={{
            boxShadow: 'none',
            border: '1px solid #D2D2D2',
            borderRadius: '6px',
            px: 4,
            py: 1,
            mt: '29px'
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography
              sx={{
                fontSize: '1.125rem',
                fontWeight: 'bold'
              }}
            >
              Smart Contracts
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TableContainer>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>Contract Name</TableCell>
                    <TableCell>Contract Address</TableCell>
                    <TableCell
                      sx={{
                        textAlign: 'center'
                      }}
                    >
                      View On Blocks Scan
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell sx={{ fontWeight: 'bold' }}>Lending Pool</TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          m: 0,
                          p: 0,
                          alignItems: 'center'
                        }}
                      >
                        <Box
                          sx={{
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden'
                          }}
                        >
                          {poolAddr}
                        </Box>
                        <IconButton
                          aria-label="subs detail"
                          onClick={() => navigator.clipboard.writeText(poolAddr)}
                        >
                          <ContentCopyIcon sx={{ fontSize: '1rem' }} />
                        </IconButton>
                      </Box>
                    </TableCell>
                    <TableCell
                      sx={{
                        display: 'flex',
                        justifyContent: 'center'
                      }}
                    >
                      <AddressFieldTools
                        address={entity?.liquidity_pool_xinfin_address}
                        showInBlockExplorer
                        showAddress={false}
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell sx={{ fontWeight: 'bold' }}>Tokenization Platform</TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          m: 0,
                          p: 0,
                          alignItems: 'center'
                        }}
                      >
                        <Box
                          sx={{
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden'
                          }}
                        >
                          {ethToXdcAddress(currentNetwork.escrowManagerAddress)}
                        </Box>
                        <IconButton
                          aria-label="subs detail"
                          onClick={() =>
                            navigator.clipboard.writeText(
                              ethToXdcAddress(currentNetwork.escrowManagerAddress)
                            )
                          }
                        >
                          <ContentCopyIcon sx={{ fontSize: '1rem' }} />
                        </IconButton>
                        {/* <Chip
                            variant="outlined"
                            color="success"
                            label="XDC Balance (150.5)"
                            sx={{ width: '10rem', height: '2.45rem' }}
                          /> */}
                      </Box>
                    </TableCell>
                    <TableCell
                      sx={{
                        display: 'flex',
                        justifyContent: 'center'
                      }}
                    >
                      <AddressFieldTools
                        address={currentNetwork.escrowManagerAddress}
                        showInBlockExplorer
                        showAddress={false}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>

        {/* Payment Token */}

        <AccordionLayout
          title="Add Payment Tokens"
          content={<AddPoolPaymentTokens poolAddr={entity?.liquidity_pool_xinfin_address} />}
        />

        {/* payment Token table */}

        <AccordionLayout
          title="Payment Tokens"
          content={<PoolPaymentTokenTable poolAddr={entity?.liquidity_pool_xinfin_address} />}
        />

        {/* Issuance Escrow */}

        <Accordion
          sx={{
            boxShadow: 'none',
            border: '1px solid #D2D2D2',
            borderRadius: '6px',
            px: 4,
            py: 1,
            mt: 4
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography
              sx={{
                fontSize: '1.125rem',
                fontWeight: 'bold'
              }}
            >
              Issuance Escrow
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <InvestorIssuanceEscrowTable poolAddr={entity?.liquidity_pool_xinfin_address} />
          </AccordionDetails>
        </Accordion>

        {/* Redemption Escrow */}

        <Accordion
          sx={{
            boxShadow: 'none',
            border: '1px solid #D2D2D2',
            borderRadius: '6px',
            px: 4,
            py: 1,
            mt: 4
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography
              sx={{
                fontSize: '1.125rem',
                fontWeight: 'bold'
              }}
            >
              Redemption Escrow
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <InvestorRedemptionEscrowTable poolAddr={entity?.liquidity_pool_xinfin_address} />
          </AccordionDetails>
        </Accordion>

        {/* Holding/Allotments */}

        <Accordion
          sx={{
            boxShadow: 'none',
            border: '1px solid #D2D2D2',
            borderRadius: '6px',
            px: 4,
            py: 1,
            mt: 4
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography
              sx={{
                fontSize: '1.125rem',
                fontWeight: 'bold'
              }}
            >
              Holdings
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <InvestorViewAllotment poolAddr={entity?.liquidity_pool_xinfin_address} />
          </AccordionDetails>
        </Accordion>

        {/* Redemptions */}

        <Accordion
          sx={{
            boxShadow: 'none',
            border: '1px solid #D2D2D2',
            borderRadius: '6px',
            px: 4,
            py: 1,
            mt: 4
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography
              sx={{
                fontSize: '1.125rem',
                fontWeight: 'bold'
              }}
            >
              Redemptions
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <InvestorViewRedemption poolAddr={entity?.liquidity_pool_xinfin_address} />
          </AccordionDetails>
        </Accordion>

        <Accordion
          sx={{
            boxShadow: 'none',
            border: '1px solid #D2D2D2',
            borderRadius: '6px',
            px: 4,
            py: 1,
            mt: 4
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography
              sx={{
                fontSize: '1.125rem',
                fontWeight: 'bold'
              }}
            >
              Pool Redemptions
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <PoolRedemptionEscrowTable poolAddr={entity?.liquidity_pool_xinfin_address} />
          </AccordionDetails>
        </Accordion>
      </Container>
    </>
  );
};

export default LiquidityPoolDashboard;
