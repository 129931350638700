import { Container, Card, Box, Tab, Alert, IconButton, Button } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import React, { useEffect, useState } from 'react';
import { useAppState, useAuthState, useHttpApi, useBusinessEntityState } from 'state';
import Page from 'components/Page';
import { getNotifyColor } from 'helpers/handler';
import DoneIcon from '@mui/icons-material/Done';
import { useWeb3State } from 'state/useWeb3State';
import { useNavigate } from 'react-router-dom';
import DataNotAvailable from 'helpers/DataNotAvailable';

const TokenNotifications = () => {
  const [value, setValue] = React.useState('1');
  const { currentToken } = useBusinessEntityState();
  const navigate = useNavigate();
  const { isAuthorized } = useAuthState();
  const { account } = useWeb3State();
  const { markAllNotificationsAsRead, markNotificationAsRead, markAllNotificationAsReadByAddress } =
    useHttpApi();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const {
    fetchUserNotifications,
    fetchAllReadNotifications,
    tokenNotifications,
    readTokenNotifications
  } = useAppState();
  useEffect(() => {
    const _fetchData = async () => {
      await fetchAllReadNotifications();
    };
    _fetchData();
  }, [account]);
  return (
    <Page title="Token Notifications">
      <Container sx={{ my: 2 }}>
        <Card>
          <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleChange} aria-label="read-unread-tabs">
                  <Tab
                    label="Unread"
                    value="1"
                    sx={{ fontWeight: 600, color: '#161c2d', fontSize: '1rem' }}
                  />
                  <Tab
                    label="Read"
                    value="2"
                    sx={{ fontWeight: 600, color: '#161c2d', fontSize: '1rem' }}
                  />
                </TabList>
              </Box>
              <TabPanel value="2">
                {readTokenNotifications.length > 0 ? (
                  <Box>
                    {readTokenNotifications.map((val) => {
                      return (
                        <Alert
                          sx={{
                            mb: 2
                          }}
                          severity={getNotifyColor(val.notification_criticality)}
                        >
                          {val.body}
                        </Alert>
                      );
                    })}
                  </Box>
                ) : (
                  <DataNotAvailable />
                )}
              </TabPanel>
              <TabPanel value="1">
                {tokenNotifications.length > 0 ? (
                  <Box>
                    {tokenNotifications.map((val) => {
                      return (
                        <Alert
                          sx={{
                            mb: 2
                          }}
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={async () => {
                                await markNotificationAsRead(val.id, {
                                  address: currentToken.deployment_address
                                });
                                await fetchUserNotifications();
                                await fetchAllReadNotifications();
                              }}
                            >
                              <DoneIcon fontSize="inherit" />
                            </IconButton>
                          }
                          severity={getNotifyColor(val.notification_criticality)}
                        >
                          {val.body}
                        </Alert>
                      );
                    })}
                    <Box display="flex" justifyContent="end">
                      <Button
                        color="error"
                        sx={{ width: '11rem' }}
                        variant="gradient"
                        onClick={async () => {
                          await markAllNotificationAsReadByAddress({
                            xinfin_account_address: currentToken.deployment_address
                          });
                          await fetchUserNotifications();
                          await fetchAllReadNotifications();
                        }}
                      >
                        Mark All As Read
                      </Button>
                    </Box>
                  </Box>
                ) : (
                  <DataNotAvailable />
                )}
              </TabPanel>
            </TabContext>
          </Box>
        </Card>

        <Card
          sx={{ my: 2, p: 2, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}
        >
          <Button
            variant="contained"
            sx={{
              mx: 2,
              px: 3,
              fontWeight: 400,
              width: '7.75rem'
            }}
            color="secondary"
            onClick={() => {
              navigate('/home/token-dashboard');
            }}
          >
            Close
          </Button>
        </Card>
      </Container>
    </Page>
  );
};

export default TokenNotifications;
