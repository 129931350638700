import EnhancedTable from 'components/tables/EnhancedTable';
import React, { useMemo, useEffect } from 'react';
import { useBusinessEntityState } from 'state';
import { Chip } from '@mui/material';

export default function CollateralTxnTable() {
  const { collateralTransactions, fetchAllCollateralTransactions } = useBusinessEntityState();

  useEffect(() => {
    fetchAllCollateralTransactions();
  }, []);

  const headCells = useMemo(
    () => [
      {
        accessor: 'collateral_type',
        Header: 'Collateral Type',
        show: true
      },
      {
        accessor: 'collateral_value',
        Header: 'Collateral Value',
        show: true
      },
      {
        accessor: 'created_at',
        Header: 'Created At',
        show: true
      },
      {
        accessor: 'transaction_type',
        Header: 'Transaction Type',
        show: true,
        Cell: ({ value }) => {
          return <Chip label={value.toUpperCase()} color="success" />;
        }
      }
    ],
    []
  );

  return (
    <>
      <EnhancedTable
        tableTitle="Transactions"
        columns={headCells}
        data={collateralTransactions}
        refreshFunction={fetchAllCollateralTransactions}
      />
    </>
  );
}
