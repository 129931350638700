import {
  Card,
  Grid,
  CardContent,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  MenuItem,
  FormControl,
  Select,
  Box,
  TextField,
  Alert,
  Checkbox,
  InputLabel
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import ListItemText from '@mui/material/ListItemText';
import OutlinedInput from '@mui/material/OutlinedInput';
import { useCoreTableState, useBusinessEntityState, useHttpApi, useAppState } from 'state';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import DataNotAvailable from 'helpers/DataNotAvailable';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 5;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 210
    }
  }
};

const Distribution = () => {
  const [selectedCountries, setSelectedCountries] = React.useState([]);
  const [selectedClassifications, setSelectedClassification] = React.useState([]);
  const [templateName, setTemplateName] = useState([]);
  const [templateInUse, setTemplateInUse] = useState(false);
  const [templateHighlight, setTemplateHighlight] = useState([]);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { fetchRuleTemplates, ruleTemplates, currentToken, fetchCurrentTokenRuleDetails } =
    useBusinessEntityState();
  const { throwErrorMessage } = useAppState();
  const [currentTemplate, setCurrentTemplate] = useState(null);
  const {
    createRuleTemplate,
    updateRuleTemplate,
    setupTokenRuleTemplate,
    getRuleTemplateDetailedByToken
  } = useHttpApi();
  const [error, setError] = useState(null);
  const [useIssuerWhitelist, setUseIssuerWhitelist] = useState(false);
  const { countries, investorClassification } = useCoreTableState();

  const handleTemplateNameChange = (event) => {
    setTemplateName(event.target.value);
  };
  const handleCountriesChange = (event) => {
    const {
      target: { value }
    } = event;
    setSelectedCountries(typeof value === 'string' ? value.split(',') : value);
  };
  const handleClassificationChange = (event) => {
    const {
      target: { value }
    } = event;
    setSelectedClassification(typeof value === 'string' ? value.split(',') : value);
  };

  // Snackbar
  const [open, setOpen] = React.useState(false);

  const linkTemplate = async () => {
    try {
      const data = {};
      await setupTokenRuleTemplate({
        token: currentToken.token_id,
        rule_template: currentTemplate.template_id
      });
      enqueueSnackbar('Template linked successfully', {
        variant: 'success'
      });
      fetchData();
    } catch (e) {
      throwErrorMessage(e);
    }
  };
  const handleSave = async () => {
    try {
      const data = {};
      data.template_id = currentTemplate.template_id;
      if (selectedCountries.length > 0) {
        data.investor_country = selectedCountries;
      }
      if (selectedClassifications.length > 0) {
        data.investor_classification = selectedClassifications;
      }
      data.use_issuer_whitelist = useIssuerWhitelist;
      await updateRuleTemplate(data);
      await setupTokenRuleTemplate({
        token: currentToken.token_id,
        rule_template: currentTemplate.template_id
      });
      await fetchRuleTemplates();
      // post data
      enqueueSnackbar('Criteria Added Successfully', {
        variant: 'success'
      });
      fetchData();
      setOpen(true);
      fetchCurrentTokenRuleDetails();
    } catch (e) {
      throwErrorMessage(e);
    }
  };

  const setTemplate = (val) => {
    setCurrentTemplate(val);
    const list = [];
    if (val.investor_country !== null || val.investor_country !== []) {
      setSelectedCountries(val.investor_country);
      list.push({
        id: 1,
        criteria: 'Investor_Country',
        investor_country_status: 'is any of'
      });
    }
    if (val.investor_classification !== null || val.investor_classification !== []) {
      setSelectedClassification(val.investor_classification);
      list.push({
        id: 2,
        criteria: 'Investor_Classification',
        investor_classificatn_status: 'is any of'
      });
    }
    if (val.use_issuer_whitelist) {
      setUseIssuerWhitelist(true);
      list.push({
        id: 3,
        criteria: 'issuerwhitelist'
      });
    }
    setTemplateInUse(val.template_in_use);
  };
  const fetchData = async () => {
    await fetchRuleTemplates();
    const res = await getRuleTemplateDetailedByToken(currentToken.token_id);
    if (res.template_id) {
      setTemplate(res);
      setTemplateHighlight(res);
    }
  };
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const CardsTemplate = ({ id, rules, highlight }) => {
    return (
      <>
        <Card sx={{ width: '19rem', height: '3.2rem', mt: 2, backgroundColor: highlight }} xs={4}>
          <CardContent sx={{ display: 'flex', flexDirection: 'coloumn' }}>
            <Typography>{rules}</Typography>
          </CardContent>
        </Card>
      </>
    );
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={8} lg={4}>
          <Typography> Rule Templates</Typography>
          {currentToken.token_status_id.token_status === 'NEW' && (
            <Card sx={{ width: '19rem', height: '5rem', mt: 2, ml: 1 }} xs={4}>
              <CardContent sx={{ display: 'flex', flexDirection: 'coloumn' }}>
                <TextField
                  sx={{ fontSize: '1rem', width: '15rem', height: '0.5rem' }}
                  size="small"
                  onChange={handleTemplateNameChange}
                  type="text"
                />
                <Button
                  onClick={async () => {
                    if (!templateName) {
                      enqueueSnackbar('Please Enter Template Name', {
                        variant: 'error'
                      });
                      return;
                    }
                    await createRuleTemplate({
                      template_name: templateName,
                      template_type: 'DISTRIBUTION',
                      template_description: 'DISTRIBUTION'
                    });
                    enqueueSnackbar('Rule Template Created Successfully', {
                      variant: 'success'
                    });
                    fetchRuleTemplates();
                  }}
                >
                  Create
                </Button>
              </CardContent>
            </Card>
          )}
          {ruleTemplates.map((val) => {
            return (
              <Button
                key={val.id}
                onClick={() => {
                  setTemplate(val);
                }}
              >
                <CardsTemplate
                  key={val.id}
                  rules={val.template_name}
                  highlight={
                    val.template_name === templateHighlight.template_name ? '#e5f6fd' : '#ffffff'
                  }
                />
              </Button>
            );
          })}
        </Grid>
        <Grid item xs={12} lg={8}>
          <Grid display="flex">
            <Typography> Criteria Configuration</Typography>
          </Grid>
          {templateInUse && (
            <Alert severity="info">Template Is Currently In Use and Cannot be Edited!</Alert>
          )}
          {error && (
            <Box mb={4}>
              <Alert severity="error">{error}</Alert>
            </Box>
          )}
          {currentTemplate && (
            <>
              <TableContainer component={Paper} sx={{ width: 720, mt: 1, boxShadow: 'none' }}>
                <Table aria-label="simple table">
                  <TableBody>
                    <TableRow>
                      <TableCell>Investor Countries</TableCell>
                      <TableCell>is any of</TableCell>

                      <TableCell align="left">
                        <FormControl sx={{ m: 1, width: 350 }}>
                          <InputLabel id="demo-multiple-checkbox-label">Country</InputLabel>
                          <Select
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            multiple
                            size="small"
                            value={selectedCountries}
                            onChange={handleCountriesChange}
                            input={<OutlinedInput label="Country" />}
                            renderValue={(selected) => selected.join(', ')}
                            MenuProps={MenuProps}
                          >
                            {countries &&
                              countries.map((country, index) => {
                                return (
                                  <MenuItem key={country.id} value={country.country_code}>
                                    <Checkbox
                                      checked={selectedCountries.indexOf(country.country_code) > -1}
                                    />
                                    {country.country_code}-{country.country_name}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                        </FormControl>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Investor Classification</TableCell>
                      <TableCell>is any of</TableCell>
                      <TableCell align="left">
                        <FormControl sx={{ m: 1, width: 350 }}>
                          <InputLabel id="demo-multiple-checkbox-label">Status</InputLabel>
                          <Select
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            multiple
                            size="small"
                            value={selectedClassifications}
                            onChange={handleClassificationChange}
                            input={<OutlinedInput label="Status" />}
                            MenuProps={MenuProps}
                            renderValue={(selected) => selected.join(', ')}
                          >
                            {investorClassification.map((inv) => (
                              <MenuItem
                                key={inv.investor_classification_name}
                                value={inv.investor_classification_name}
                              >
                                <Checkbox
                                  checked={
                                    selectedClassifications.indexOf(
                                      inv.investor_classification_name
                                    ) > -1
                                  }
                                />
                                <ListItemText primary={inv.investor_classification_name} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Issuer Whitelist</TableCell>
                      <TableCell align="left">
                        <FormControl size="small">
                          <Checkbox
                            name="preset_check"
                            value={useIssuerWhitelist}
                            defaultChecked={useIssuerWhitelist}
                            onChange={(e) => {
                              setUseIssuerWhitelist(e.target.checked);
                            }}
                            inputProps={{ 'aria-label': 'controlled' }}
                          />
                        </FormControl>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              {templateInUse && currentToken.token_status_id.token_status === 'NEW' && (
                <Button sx={{ mt: '20px' }} variant="gradient" onClick={linkTemplate}>
                  Use Template
                </Button>
              )}
              {!templateInUse && currentToken.token_status_id.token_status === 'NEW' && (
                <Grid container sx={{ mt: '20px', flexDirection: 'row-reverse' }}>
                  <Grid item xs={6} lg={6}>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        navigate('/home/token-dashboard');
                      }}
                    >
                      Close
                    </Button>
                    <Button variant="gradient" sx={{ ml: 2 }} onClick={handleSave}>
                      Submit Changes
                    </Button>
                  </Grid>
                </Grid>
              )}
            </>
          )}

          {!currentTemplate && (
            <>
              <Alert severity="info" sx={{ width: '20rem', mt: 2 }}>
                No Template Selected
              </Alert>
              <DataNotAvailable />
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default Distribution;
