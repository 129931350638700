export const getChipColor = (status) => {
  switch (status) {
    case (status = 'NEW'):
      return 'default';

    case (status = 'PUBLISHED'):
      return 'success';

    case (status = 'ISSUED'):
      return 'primary';

    case (status = 'REDEEMED'):
      return 'primary';

    default:
      return 'warning';
  }
};

// Debouncing Function
// export const debounce = (fn, delay = 300) => {
//   let timer;
//   return function (...args) {
//     const context = this;
//     clearTimeout(timer);
//     timer = setTimeout(() => {
//       fn(args);
//     }, delay);
//   };
// };

export function debounce(func, timeout = 300) {
  let timer;
  return (...args) => {
    if (!timer) {
      func.apply(this, args);
    }
    clearTimeout(timer);
    timer = setTimeout(() => {
      timer = undefined;
    }, timeout);
  };
}
export const getNotifyColor = (status) => {
  switch (status) {
    case (status = 'low'):
      return 'info';

    case (status = 'medium'):
      return 'info';

    case (status = 'high'):
      return 'warning';

    case (status = 'critical'):
      return 'error';

    default:
      return 'warning';
  }
};

export const epochToDate = (unix) => {
  return new Date(unix * 1000).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  });
};
