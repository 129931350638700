import React, { useEffect, useState } from 'react';
import EnhancedTable from 'components/tables/EnhancedTable';

import { useBusinessEntityState, useWeb3 } from 'state/index';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { ethToXdcAddress } from 'helpers/web3';
import { HeaderName } from 'helpers/tableHelper';
import { useAuthStateShared } from 'state';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useSnackbar } from 'notistack';
import CopyAddress from 'components/CopyAddress';

const IssuerEscrowStatus = ({ status }) => {
  return <>{status ? <CheckCircleIcon color="success" /> : <CancelIcon color="error" />}</>;
};

const SubcriberEscrowStatus = ({ status }) => {
  return <>{status ? <CheckCircleIcon color="success" /> : <CancelIcon color="error" />}</>;
};

export default function InsurerRedemptionsTable({ fetchData: _fetchData }) {
  const [data, setData] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [skipPageReset, setSkipPageReset] = React.useState(false);
  const {
    insurerIssuerRedemptionEscrowOrder,
    fetchRedemptionEscrowOrderByInsurerIssuerId,
    currCreditIssuer,
    currCreditInsurer
  } = useBusinessEntityState();
  const { getRedemptionEscrowConditions } = useWeb3();

  // const _fetchData = async () => {
  //   await fetchRedemptionEscrowOrderByInsurerIssuerId(
  //     currCreditIssuer.issuer_id,
  //     currCreditInsurer.insurer_id
  //   );
  // };

  // useEffect(() => {
  //   _fetchData();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const headCells = [
    {
      accessor: 'investor.investor_name',
      Header: 'Investor Name',
      width: 100,
      show: true
    },
    {
      accessor: 'xinfin_address',
      Header: 'Xinfin Address',
      width: 200,
      show: true
    },
    {
      accessor: 'trade_token_amount',
      Header: 'Qty Applied',
      width: 100,
      show: true
    },
    // {
    //   accessor: 'payment_token_name',
    //   Header: 'Payment token',
    //   width: 110,
    //   show: true
    // },
    {
      accessor: 'subcriber_status',
      Header: 'Investor Escrowed',
      width: 110,
      show: true
    },
    {
      accessor: 'issuer_status',
      Header: 'Issuer Escrowed',
      width: 100,
      show: true
    }
    // {
    //   accessor: 'is_redeemed',
    //   Header: 'Redeemed',
    //   width: 100,
    //   show: true
    // }
  ];

  useEffect(() => {
    // TODO: Fetch Investors
    const _prepareRow = async () => {
      if (insurerIssuerRedemptionEscrowOrder.length !== 0) {
        const _rows = await Promise.all(
          insurerIssuerRedemptionEscrowOrder.map(async (row) => {
            row.xinfin_address = (
              <CopyAddress addr={ethToXdcAddress(row.investor_xinfin_account)} />
            );
            const condition = await getRedemptionEscrowConditions(row.escrow_order_id);
            row.issuer_status = <IssuerEscrowStatus status={condition[0]} />;
            row.subcriber_status = <SubcriberEscrowStatus status={condition[1]} />;
            return row;
          })
        );
        setData(_rows);
        setIsLoaded(true);
      }
    };
    _prepareRow();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insurerIssuerRedemptionEscrowOrder]);
  const updateMyData = (rowIndex, columnId, value) => {
    setSkipPageReset(true);
    setData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value
          };
        }
        return row;
      })
    );
  };

  return (
    <>
      <EnhancedTable
        tableTitle="InsurerRedemptionsTable"
        columns={headCells}
        data={data}
        setData={setData}
        updateMyData={updateMyData}
        skipPageReset={skipPageReset}
        refreshFunction={_fetchData}
      />
    </>
  );
}
