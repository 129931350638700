import React, { useEffect, useState } from 'react';
import { IconButton, Chip, Button, Typography } from '@mui/material';
import { useBusinessEntityState } from 'state/index';
import { ethToXdcAddress } from 'helpers/web3';
import EnhancedTable from 'components/tables/EnhancedTable';
import SubsDetails from 'components/masterData/Dashboard/SubsDetails';
import Modal from '@mui/material/Modal';
import CopyAddress from 'components/CopyAddress';
import { useNavigate } from 'react-router';

const headCells = [
  {
    accessor: 'investor_id.investor_name',
    Header: 'Investor',
    show: true
  },
  {
    accessor: 'xinfin_address',
    Header: 'Xinfin Address',
    width: 200,
    show: true
  },
  {
    accessor: 'count_string',
    Header: 'Uploaded',
    show: true
  },
  {
    accessor: 'document_upload_status',
    Header: 'Approval Status',
    show: true,
    Cell: (c) => {
      switch (c.row.original.document_upload_status) {
        case 'APPROVED':
          return <Chip label="APPROVED" color="success" />;
        case 'PENDING':
          return <Chip label="PENDING" color="primary" />;
        default:
          return <Chip label="NO UPLOADS" color="warning" />;
      }
    }
  },
  {
    accessor: 'actions',
    Header: 'Document Details',
    show: true
  }
];
const ActionButtons = ({
  investor,
  issuer,
  documentStatus,
  subscriptionDocuments,
  subscriptionId,
  kycStatus
}) => {
  const navigate = useNavigate();
  const { setToken, currentToken } = useBusinessEntityState();
  const {
    setDocumentPageInvestor,
    setDocumentPageIssuer,
    setDocumentPageDocuments,
    setSubscriptionId,
    setKycStatus
  } = useBusinessEntityState();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <>
      <Button
        variant={
          {
            NONE: 'contained',
            PENDING: 'gradient',
            APPROVED: 'outlined'
          }[documentStatus]
        }
        color={
          {
            NONE: 'secondary',
            PENDING: 'secondary',
            APPROVED: 'primary'
          }[documentStatus]
        }
        sx={{ ml: 1, mt: '8px', mb: '4px' }}
        onClick={() => {
          setToken(currentToken);
          setDocumentPageInvestor(investor);
          setDocumentPageIssuer(issuer);
          setDocumentPageDocuments(subscriptionDocuments);
          setSubscriptionId(subscriptionId);
          setKycStatus(kycStatus);
          navigate('/home/token-dashboard/doc-manage-user');
        }}
        disabled={documentStatus === 'NONE'}
      >
        <Typography sx={{ fontSize: '12px' }}>
          {
            {
              NONE: 'No Uploads',
              PENDING: 'Approve',
              APPROVED: 'Update'
            }[documentStatus]
          }
        </Typography>
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <SubsDetails investor={investor} handleClose={handleClose} />
      </Modal>
    </>
  );
};

export default function AllInvestorTableForDocMgt() {
  const { allSubscriptions, currentToken, fetchSubscription } = useBusinessEntityState();
  const [data, setData] = useState(allSubscriptions);

  const [skipPageReset, setSkipPageReset] = React.useState(false);
  const _fetchData = async () => {
    await fetchSubscription(currentToken.token_id);
  };
  useEffect(() => {
    _fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.log('xx', allSubscriptions);
    const _allSubscriptions = allSubscriptions.map((subscriber) => {
      subscriber.xinfin_address = (
        <CopyAddress addr={ethToXdcAddress(subscriber.xinfin_setl_account)} />
      );
      subscriber.actions = (
        <ActionButtons
          investor={{
            ...subscriber.investor_id
          }}
          issuer={subscriber.issuer}
          documentStatus={subscriber.document_upload_status}
          subscriptionDocuments={subscriber.required_docs}
          subscriptionId={subscriber.id}
          kycStatus={subscriber.kyc_status}
        />
      );
      return subscriber;
    });
    setData(_allSubscriptions);
  }, [allSubscriptions]);

  const updateMyData = (rowIndex, columnId, value) => {
    setSkipPageReset(true);
    setData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value
          };
        }
        return row;
      })
    );
  };

  return (
    <>
      <EnhancedTable
        tableTitle="AllInvestorTableForDocMgt"
        columns={headCells}
        data={data}
        setData={setData}
        updateMyData={updateMyData}
        refreshFunction={_fetchData}
        skipPageReset={skipPageReset}
      />
    </>
  );
}
