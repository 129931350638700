/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import Page from 'components/Page';

import {
  Container,
  Card,
  Grid,
  Box,
  CardContent,
  Typography,
  TextField,
  FormLabel,
  Button,
  Divider,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  Alert,
  IconButton,
  InputAdornment
} from '@mui/material';
import Breadcrumbs from 'components/Breadcrumbs';
import EditIssuerAccodian from 'components/masterData/Issuer/EditIssuerAccodian';
import {
  useHttpApi,
  useCoreTableState,
  useWeb3,
  useBusinessEntityState,
  useAuthStateShared,
  useAppState
} from 'state';
import { useFormik, Form, FormikProvider, Field } from 'formik';
import * as Yup from 'yup';
import NotifierSnackbar from 'components/Notifier/NotifierSnackbar';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { xdcRegex, leiRegex } from 'helpers/regex';
import { xdcToEthAddress, toChecksumAddress } from 'helpers/web3';
import { useNavigate } from 'react-router';
import { LeiInfoButton, XdcInfoButton } from 'helpers/infoButton';
import CommonOverlay from 'components/CommonOverlay';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { Icon } from '@iconify/react';
import { onlyOwner } from 'helpers/rbac';
import FocusError from 'components/FocusError';
import { set } from 'lodash';

// sample data
const organizationrows = [
  {
    name: 'ABC',
    hash: 'Nullam lacinia, metus amet ullamcorper facilisis, purus mauris rutrum eros, in',
    doc: 'Document Name'
  }
];

const originatorrows = [
  {
    name: 'xyz',
    hash: 'Nullam lacinia, metus amet ullamcorper facilisis, purus mauris rutrum eros, in',
    doc: 'Document Name'
  }
];

const trusteerows = [
  {
    name: 'xcs',
    hash: 'Nullam lacinia, metus amet ullamcorper facilisis, purus mauris rutrum eros, in',
    doc: 'Document Name'
  }
];

const kycrows = [
  {
    name: 'ABC',
    hash: 'Nullam lacinia, metus amet ullamcorper facilisis, purus mauris rutrum eros, in',
    doc: 'Document Name'
  }
];

const AddIssuer = () => {
  const { createIssuer, checkUserNameAvailbility } = useHttpApi();
  const { fetchIssuer, fetchAllKycProviders, kycProviders } = useBusinessEntityState();
  const { throwErrorMessage } = useAppState();
  const { role } = useAuthStateShared();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const issuerLogo = useRef(null);
  useEffect(async () => {
    fetchAllKycProviders();
    console.log('privders', kycProviders);
  }, []);
  // Issuer Schema
  const AddIssuerSchema = Yup.object().shape({
    issuer_name: Yup.string()
      .required('Issuer Name is required')
      .max(30, 'Issuer Name cannot be more than 30 characters'),
    // lei: Yup.string()
    //   .min(20, 'LEI must be 20 characters')
    //   .max(20, 'LEI must be 20 characters')
    //   .required('LEI is required')
    //   .matches(leiRegex, 'please enter a valid LEI'),
    country_code: Yup.string().required('Country Code is required'),
    // license_No: Yup.string()
    //   .required('License No. is required')
    //   .max(30, 'License No. should be atmost 30 characters'),
    primary_issuer_xinfin_address: Yup.string()
      .matches(xdcRegex, 'Please enter a valid XDCPay Wallet Address')
      .required('Issuer XDCPay Wallet Address is required'),
    username: Yup.string().required('Unique username is required'),
    email: Yup.string().email('Enter A Valid Email').required('Email is Required'),
    password: Yup.string().required('Password is required'),
    issuer_logo: Yup.mixed().required('Issuer Logo is required'),
    kyc_provider: Yup.string().required('Select a KYC Provider')
  });

  const { addIssuer: addIssuerBlockchain } = useWeb3();

  // Formik
  const formik = useFormik({
    validate: (values) => {
      const errors = {};
      if (values.issuer_logo) {
        if (issuerLogo && issuerLogo.current.files[0].size > 50000) {
          errors.issuer_logo = 'File size greater than 50KB';
        }
      }

      return errors;
    },
    initialValues: {
      issuer_name: '',
      // lei: '',
      country_code: '',
      // license_No: '',
      primary_issuer_xinfin_address: '',
      username: '',
      password: '',
      email: '',
      issuer_logo: '',
      kyc_provider: ''
    },
    validationSchema: AddIssuerSchema,
    onSubmit: async (data, { resetForm }) => {
      console.log('AddIssuer', data);
      try {
        setError(null);
        const userNameRes = await checkUserNameAvailbility(data.username);
        if (userNameRes.exist) {
          formik.setFieldError('username', 'Username already exist');
          formik.setFieldValue('username', '');
          const e = new Error('Username Already Exist');
          e.name = 'Username';
          throw e;
        }
        const issuerLogoFile = issuerLogo.current.files[0];
        data.issuer_logo = issuerLogoFile;
        data.primary_issuer_xinfin_address = xdcToEthAddress(data.primary_issuer_xinfin_address);
        data.primary_issuer_xinfin_address = toChecksumAddress(data.primary_issuer_xinfin_address);
        // const countryCode = countries.find((country) => country.id === data.country_code);
        console.log('countryCode', data.country_code);

        // Django Call
        data.username = data.username.toLowerCase();
        const _issuer = await createIssuer(data);
        formik.setFieldValue('issuer_logo', '');
        if (_issuer.issuer_id) {
          enqueueSnackbar('Issuer Created Successfully', {
            variant: 'success'
          });
          fetchIssuer();
          resetForm();
          navigate('/masterdata/issuer/');
        }

        // Blockchain Call
        const res = await addIssuerBlockchain(
          data.country_code,
          data.primary_issuer_xinfin_address
        );
        console.log('res', res);
      } catch (e) {
        if (e.name !== 'Username') {
          formik.setFieldValue('issuer_logo', '');
          throwErrorMessage(e);
        } else {
          throwErrorMessage(e);
        }
      }
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;
  const { countries } = useCoreTableState();
  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const allowedRolesList = ['OWNER'];
  return (
    <Page title="Add Issuer - Master Data">
      <CommonOverlay
        allowedRoles={allowedRolesList}
        overlayTitle="You don't have access to Add Primary Issuer Account"
      />
      <Container sx={{ my: 2 }}>
        <Box sx={{ my: 2 }}>
          <Breadcrumbs aria-label="breadcrumb" pageHead="Add Issuer" />
        </Box>
        {error && (
          <Box mb={4}>
            <Alert severity="error">{error}</Alert>
          </Box>
        )}
        <Card>
          <Container>
            <Typography
              sx={{
                fontSize: '1.125rem',
                fontWeight: 'bold',
                mt: 4,
                mb: 1,
                ml: 4
              }}
            >
              Issuer Details
            </Typography>
            <Divider
              sx={{
                width: '100%',
                backgroundColor: '#f5f8fa',
                border: '2px solid #f5f8fa'
              }}
            />
            <FormikProvider value={formik}>
              <Form autoComplete="off" onSubmit={handleSubmit}>
                <CardContent sx={{ m: 2 }}>
                  <Grid container spacing={3} sx={{ width: '100%' }}>
                    <Grid item lg={6} md={6} xs={12} sm={12}>
                      <FormLabel>Name</FormLabel>
                      <TextField
                        sx={{ mt: 1.5 }}
                        fullWidth
                        size="small"
                        autoComplete="off"
                        type="text"
                        // placeholder="Enter Issuer Name"
                        {...getFieldProps('issuer_name')}
                        error={Boolean(touched.issuer_name && errors.issuer_name)}
                        helperText={touched.issuer_name && errors.issuer_name}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xs={12} sm={12}>
                      <FormLabel>Username</FormLabel>
                      <TextField
                        sx={{ mt: 1.5 }}
                        fullWidth
                        size="small"
                        autoComplete="off"
                        type="text"
                        {...getFieldProps('username')}
                        error={Boolean(touched.username && errors.username)}
                        helperText={touched.username && errors.username}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xs={12} sm={12}>
                      <FormLabel>Email</FormLabel>
                      <TextField
                        sx={{ mt: 1.5 }}
                        fullWidth
                        size="small"
                        autoComplete="off"
                        type="text"
                        {...getFieldProps('email')}
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                      />
                    </Grid>

                    <Grid item lg={6} md={6} xs={12} sm={12}>
                      <FormLabel>Password</FormLabel>
                      <TextField
                        sx={{ mt: 1.5 }}
                        fullWidth
                        size="small"
                        autoComplete="off"
                        type={showPassword ? 'text' : 'password'}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton onClick={handleShowPassword} edge="end">
                                <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                        {...getFieldProps('password')}
                        error={Boolean(touched.password && errors.password)}
                        helperText={touched.password && errors.password}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xs={12} sm={12}>
                      <FormLabel>
                        XinFin Address
                        <XdcInfoButton />
                      </FormLabel>
                      <TextField
                        sx={{ mt: 1.5 }}
                        fullWidth
                        type="text"
                        size="small"
                        // placeholder="Primary XinFin Address"
                        {...getFieldProps('primary_issuer_xinfin_address')}
                        error={Boolean(
                          touched.primary_issuer_xinfin_address &&
                            errors.primary_issuer_xinfin_address
                        )}
                        helperText={
                          touched.primary_issuer_xinfin_address &&
                          errors.primary_issuer_xinfin_address
                        }
                      />
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12} sx={{ pr: 1 }}>
                      <FormLabel>Logo</FormLabel>
                      <TextField
                        sx={{ mt: 1.5 }}
                        fullWidth
                        size="small"
                        autoComplete="off"
                        type="file"
                        inputRef={issuerLogo}
                        inputProps={{ accept: '.jpg, .jpeg, .png, .svg' }}
                        {...getFieldProps('issuer_logo')}
                        id="issuer_logo"
                        error={Boolean(touched.issuer_logo && errors.issuer_logo)}
                        helperText={touched.issuer_logo && errors.issuer_logo}
                      />
                      <Grid container sx={{ width: '100%', mt: 2 }}>
                        <Grid item lg={4} md={4} sm={4}>
                          <Typography
                            sx={{
                              fontSize: '0.688rem',
                              textAlign: 'left',
                              color: '#161c2d'
                            }}
                          >
                            Maximum 50KB file size
                          </Typography>
                        </Grid>
                        <Grid item lg={8} md={8} sm={8}>
                          <Typography
                            sx={{
                              fontSize: '0.688rem',
                              textAlign: 'right',
                              color: '#8e8ea7'
                            }}
                          >
                            Accepted File Type .jpg, .jpeg, .png & .svg only
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item lg={6} md={6} xs={12} sm={12}>
                      <FormLabel>Country Code</FormLabel>

                      <FormControl size="small" variant="outlined" fullWidth sx={{ mt: 1.5 }}>
                        <Select
                          // placeholder="Select Country"
                          inputProps={{ 'aria-label': 'Without label' }}
                          {...getFieldProps('country_code')}
                          error={Boolean(touched.country_code && errors.country_code)}
                        >
                          {countries &&
                            countries.map((country, index) => {
                              return (
                                <MenuItem key={country.id} value={country.country_code}>
                                  {country.country_name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                        <FormHelperText sx={{ color: '#d32f2f' }}>
                          {touched.country_code && errors.country_code}
                        </FormHelperText>
                      </FormControl>
                    </Grid>

                    <Grid item lg={6} md={6} xs={12} sm={12}>
                      <FormLabel>KYC Provider</FormLabel>

                      <FormControl size="small" variant="outlined" fullWidth sx={{ mt: 1.5 }}>
                        <Select
                          // placeholder="Select Country"
                          inputProps={{ 'aria-label': 'Without label' }}
                          {...getFieldProps('kyc_provider')}
                          error={Boolean(touched.kyc_provider && errors.kyc_provider)}
                        >
                          {kycProviders &&
                            kycProviders.map((kycProvider) => {
                              return (
                                <MenuItem
                                  key={kycProvider.kyc_provider_id}
                                  value={kycProvider.kyc_provider_id}
                                >
                                  {kycProvider.kyc_provider_name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                        <FormHelperText sx={{ color: '#d32f2f' }}>
                          {touched.kyc_provider && errors.kyc_provider}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Box
                    sx={{
                      mt: 4,
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-end'
                    }}
                  >
                    <Button
                      sx={{
                        margin: 1,
                        borderColor: '#24ABDF',
                        px: 3,
                        width: '7.75rem',
                        height: '2.5rem'
                      }}
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        navigate('/masterdata/issuer');
                      }}
                    >
                      Cancel
                    </Button>
                    <LoadingButton
                      type="submit"
                      variant="gradient"
                      disabled={!onlyOwner(role)}
                      fullWidth
                      size="large"
                      loadingPosition="start"
                      loading={isSubmitting}
                      sx={{ margin: 1, px: 3, width: '7.75rem', height: '2.5rem' }}
                    >
                      Save
                    </LoadingButton>
                  </Box>
                  <FocusError />
                  {/* <EditIssuerAccodian
                    rows={organizationrows}
                    title="Custodian Mapping"
                    tableTitle="Custodian Name"
                  />
                  <EditIssuerAccodian
                    rows={originatorrows}
                    title="Originator Mapping"
                    tableTitle="Originator Name"
                  />
                  <EditIssuerAccodian
                    rows={trusteerows}
                    title="Trustee Mapping"
                    tableTitle="Trustee Name"
                  />
                  <EditIssuerAccodian rows={kycrows} title="KYC Mapping" tableTitle="KYC Name" /> */}
                </CardContent>
              </Form>
            </FormikProvider>
          </Container>
        </Card>
      </Container>
    </Page>
  );
};

export default AddIssuer;
