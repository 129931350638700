import { Alert, AlertTitle, Box } from '@mui/material';
import Overlay from 'components/Overlay';
import { over } from 'lodash';
import CommonOverlayCondition from './CommonOverlayCondition';

const CommonOverlay = ({ overlayTitle, overlayDesc, allowedRoles }) => {
  return (
    <>
      <CommonOverlayCondition allowedRoles={allowedRoles} not>
        <Overlay show />
        <Alert
          severity="warning"
          sx={{
            position: 'fixed',
            top: '50vh',
            left: 'calc(50vw - 550px / 2)',
            'z-index': 3,
            width: '550px'
          }}
        >
          <AlertTitle>{overlayTitle}</AlertTitle>
          {overlayDesc}
        </Alert>
      </CommonOverlayCondition>
    </>
  );
};

export default CommonOverlay;
