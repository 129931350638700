import { useWeb3 } from 'state';

const WalletRequire = ({ children, not = false }) => {
  const { active, account } = useWeb3();
  const isWallet = localStorage.getItem('selectedWallet');

  const condition = isWallet || (active && account);
  const show = not ? !condition : condition;

  return show ? children : null;
};

export default WalletRequire;
