import React from 'react';
import Page from 'components/Page';
import {
  Container,
  Card,
  Grid,
  Box,
  CardContent,
  Typography,
  TextField,
  FormControl,
  MenuItem,
  FormLabel,
  Button,
  Divider,
  Select,
  InputLabel,
  Checkbox
} from '@mui/material';
import Breadcrumbs from 'components/Breadcrumbs';

const EditBankAccount = () => {
  return (
    <Page title="Edit Bank Account - Master Data">
      <Container sx={{ my: 2 }}>
        <Box sx={{ my: 2 }}>
          <Breadcrumbs aria-label="breadcrumb" pageHead="Bank account" />
        </Box>
        <Card>
          <Container>
            <Typography
              sx={{
                fontSize: '1.125rem',
                fontWeight: 'bold',
                mt: 4,
                mb: 1,
                ml: 4
              }}
            >
              Edit Bank Account
            </Typography>
            <Divider
              sx={{
                width: '100%',
                backgroundColor: '#f5f8fa',
                border: '2px solid #f5f8fa'
              }}
            />

            <CardContent sx={{ m: 2 }}>
              <Grid container spacing={3} sx={{ width: '100%' }}>
                <Grid item lg={6} md={6} xs={12}>
                  <FormLabel>Bank Name</FormLabel>
                  <TextField
                    sx={{ mt: 1.5 }}
                    fullWidth
                    size="small"
                    autoComplete="custodianname"
                    type="text"
                    // placeholder="Enter Your Name"
                  />
                </Grid>
                <Grid item lg={6} md={6} xs={12}>
                  {/* Confirm whether it should be dropdown or textfield during implementations */}
                  <FormLabel>Branch</FormLabel>
                  <TextField
                    sx={{ mt: 1.5 }}
                    fullWidth
                    size="small"
                    s
                    type="text"
                    // placeholder="Your bank branch name"
                  />
                </Grid>
                <Grid item lg={6} md={6} xs={12}>
                  <FormLabel>Account No</FormLabel>
                  <TextField
                    sx={{ mt: 1.5 }}
                    fullWidth
                    size="small"
                    autoComplete=""
                    type="text"
                    // placeholder="Enter Your Bank Account No"
                  />
                </Grid>
                <Grid item lg={6} md={6} xs={12}>
                  <FormLabel>BIC Code</FormLabel>
                  <TextField
                    sx={{ mt: 1.5 }}
                    fullWidth
                    size="small"
                    autoComplete=""
                    type="text"
                    // placeholder="Bank Identification Code"
                  />
                </Grid>
                <Grid item lg={6} md={6} xs={12}>
                  <FormLabel>ISFC Code</FormLabel>
                  <TextField
                    sx={{ mt: 1.5 }}
                    fullWidth
                    size="small"
                    autoComplete=""
                    type="text"
                    // placeholder="Indian Financial System Code"
                  />
                </Grid>
                <Grid item lg={6} md={6} xs={12}>
                  <FormLabel>IBAN Code</FormLabel>
                  <TextField
                    sx={{ mt: 1.5 }}
                    fullWidth
                    size="small"
                    autoComplete=""
                    type="text"
                    // placeholder="International Bank Account Number"
                  />
                </Grid>
                <Grid item lg={12}>
                  <Divider />
                </Grid>
                <Grid item lg={6} md={6} xs={12}>
                  <FormLabel>Address Line 01</FormLabel>
                  <TextField sx={{ mt: 1.5 }} fullWidth size="small" autoComplete="" type="text" />
                </Grid>
                <Grid item lg={6} md={6} xs={12}>
                  <FormLabel>Address Line 02</FormLabel>
                  <TextField sx={{ mt: 1.5 }} fullWidth size="small" autoComplete="" type="text" />
                </Grid>
                <Grid item lg={6} md={6} xs={12}>
                  <FormLabel>City</FormLabel>
                  <TextField sx={{ mt: 1.5 }} fullWidth size="small" autoComplete="" type="text" />
                </Grid>
                <Grid item lg={6} md={6} xs={12}>
                  <FormLabel>State / Province / Region</FormLabel>
                  <TextField sx={{ mt: 1.5 }} fullWidth size="small" autoComplete="" type="text" />
                </Grid>
                <Grid item lg={6} md={6} xs={12}>
                  <FormLabel>Pincode</FormLabel>
                  <TextField sx={{ mt: 1.5 }} fullWidth size="small" autoComplete="" type="text" />
                </Grid>
                <Grid item lg={12}>
                  <Divider />
                </Grid>
                <Grid item lg={6} md={6} xs={12}>
                  <FormControl fullWidth size="small">
                    <InputLabel>Account Currency</InputLabel>
                    <Select>
                      <MenuItem>USD</MenuItem>
                      <MenuItem>INR</MenuItem>
                      <MenuItem>SGD</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item lg={6} md={6} xs={12}>
                  <FormControl fullWidth size="small">
                    <InputLabel>Beneficiary Type</InputLabel>
                    <Select>
                      <MenuItem>Individual</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item lg={6} md={6} xs={12}>
                  <FormLabel>Account type</FormLabel>
                  <Box>
                    <Checkbox />
                    Joint
                  </Box>
                  <Box>
                    <Checkbox />
                    Individual
                  </Box>
                </Grid>
              </Grid>

              <Box
                sx={{
                  mt: 2,
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end'
                }}
              >
                <Button
                  sx={{
                    margin: 1,
                    borderColor: '#24ABDF',
                    px: 3,
                    width: '7.75rem',
                    height: '2.5rem'
                  }}
                  variant="contained"
                  color="secondary"
                >
                  Cancel
                </Button>
                <Button
                  variant="gradient"
                  sx={{ margin: 1, px: 3, width: '7.75rem', height: '2.5rem' }}
                  onClick={{}}
                >
                  Save
                </Button>
              </Box>
            </CardContent>
          </Container>
        </Card>
      </Container>
    </Page>
  );
};

export default EditBankAccount;
