import { useEffect, useRef, useState, useMemo, useCallback } from 'react';
import { useWeb3React } from '@web3-react/core';
import { InjectedConnector, NoEthereumProviderError } from '@yodaplus/injected-connector';
import constate from 'constate';
import Onboard from 'bnc-onboard';
import { SafeAppConnector } from '@gnosis.pm/safe-apps-web3-react';

import WALLETS from 'config/wallets';
import {
  sendTransaction,
  sendTransactionHashOnly,
  WEB3_STATUS,
  currentNetwork,
  transformProviderFromXinfin,
  readOnlyWeb3,
  sendNativeTransaction,
  getTransactionHash
} from 'helpers/web3';
import { selectedWalletPersistence, userTokenPersistence } from 'persistence';
import { useAuthStateShared } from 'state/useAuthStateShared';
import tokenContractJson from '@yodaplus/dapps-lib/contracts/Token.json';
import tokenTvTJson from '@yodaplus/dapps-lib/contracts/TokenTvT.json';
import erc20ContractJson from '@yodaplus/dapps-lib/contracts/ERC20.json';
import LiquidityPoolJson from '@yodaplus/dapps-lib/contracts/LiquidityPool.json';
import PoolController from '@yodaplus/dapps-lib/contracts/PoolController.json';
import { useAppState } from './useAppState';
import { useIsInvestor } from 'helpers/rbac';
import { useSnackbar } from 'notistack';
// import { NETWORK_ID, NETWORKS, API_URL } from '../config';

// magic link
import { OAuthExtension } from '@magic-ext/oauth';
import { AuthExtension } from '@magic-ext/auth';
import { Magic as MagicBase } from 'magic-sdk';
import { MAGIC_LINK_KEY } from '../config';
import { ethers } from 'ethers';
import { useMagicState } from './useMagicState';
import Web3 from 'web3';
import { ContactPageSharp } from '@mui/icons-material';
import { useAuthState } from './useAuthState';

const safeAppConnector = new SafeAppConnector();

const getOnboard = ({ onProvider }) => {
  const onboard = Onboard({
    networkId: currentNetwork.id,
    networkName: currentNetwork.name,
    subscriptions: {
      wallet: (wallet) => {
        if (wallet.provider && 'qrcodeModalOptions' in wallet.provider) {
          wallet.provider.connector._qrcodeModalOptions = {
            desktopLinks: []
          };
        }

        if (wallet.provider) {
          onProvider(transformProviderFromXinfin(wallet.provider));
        }
      }
    },
    walletSelect: {
      description: 'Please select a wallet to connect to Yodaplus Tokenization Platform',
      explanation:
        'Wallets are used to send, receive, and store digital assets. Wallets come in many forms. They are either built into your browser, an extension added to your browser, a piece of hardware plugged into your computer or even an app on your phone.',
      wallets: WALLETS
    },
    walletCheck: [
      { checkName: 'derivationPath' },
      { checkName: 'connect' },
      { checkName: 'accounts' }
    ]
  });

  return onboard;
};

const useMultisigStatus = () => {
  const [isMultisig, setIsMultisig] = useState(null);

  useEffect(() => {
    safeAppConnector.isSafeApp().then(setIsMultisig);
  }, []);

  return { isMultisig };
};

const useWeb3State_ = () => {
  const {
    activate,
    deactivate,
    active: _active,
    account: _account,
    library: _walletWeb3,
    chainId
  } = useWeb3React();
  const { isInvestor } = useAuthState();
  // const [chainId, setChainId] = useState(_chainId?? ChainId.XDCApothem)
  const [account, setAccount] = useState(_account);
  const [walletWeb3, setwalletWeb3] = useState(null);
  const [active, setActive] = useState(_active);
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuthStateShared();

  const [web3Connecting, setWeb3Connecting] = useState(false);
  const { isAuthorized } = useAuthStateShared();

  const [status, setStatus] = useState(WEB3_STATUS.UNKNOWN);
  const { isMultisig } = useMultisigStatus();

  const { throwErrorMessage } = useAppState();

  const [otpVerificationOngoing, setOtpVerificationOngoing] = useState(false);
  const [otpVerificationComplete, setOtpVerificationComplete] = useState(false);
  const [connectedAccount, setConnectedAccount] = useState(_account);

  // magic link
  const [emailOtpHandler, setEmailOtpHandler] = useState(null);
  const [balance, setBalance] = useState('0');

  const [ethersProvider, setEthersProvider] = useState(null);
  const {
    magic,
    setMagic,
    initializeMagicSDK,
    disconnectMagic,
    submitLoginForm,
    setSubmitLoginForm
  } = useMagicState();
  const [userDetails, setUserDetails] = useState(null);

  const web3 = walletWeb3 ?? readOnlyWeb3;

  if (!web3) {
    throw new Error('web3 must be available at this point');
  }

  const contract = useMemo(() => {
    return new web3.eth.Contract(
      currentNetwork.custodianContractAbi,
      currentNetwork.custodianContractAddress,
      {
        from: account
      }
    );
  }, [web3, account]);

  const escrowContract = useMemo(() => {
    return new web3.eth.Contract(
      currentNetwork.escrowManagerAbi,
      currentNetwork.escrowManagerAddress,
      {
        from: account
      }
    );
  }, [web3, account]);
  // console.log(
  //   '🚀 ~ file: useWeb3State.js ~ line 111 ~ poolController ~ PoolController.abi',
  //   PoolController.address
  // );
  const poolController = useMemo(() => {
    return new web3.eth.Contract(PoolController.abi, PoolController.address, {
      from: account
    });
  }, [web3, account]);

  const tokenContract = useMemo(() => {
    return new web3.eth.Contract(tokenContractJson.abi, {
      from: account
    });
  }, [web3, account]);

  const tokenTvTContract = useMemo(() => {
    return new web3.eth.Contract(tokenTvTJson.abi, {
      from: account
    });
  }, [web3, account]);

  const liquidityPoolContract = useMemo(() => {
    return new web3.eth.Contract(LiquidityPoolJson.abi, {
      from: account
    });
  }, [web3, account]);

  const erc20Contract = useMemo(() => {
    return new web3.eth.Contract(erc20ContractJson.abi, {
      from: account
      // gasPrice: 1 * 10 ** 9
    });
  }, [web3, account]);

  if (!tokenContract || !tokenTvTContract || !erc20Contract) {
    throw new Error('contracts must be available at this point');
  }

  useEffect(() => {
    if (_active && _account) {
      console.log('🚀 ~ useEffect ~ _account:', _account);
      setActive(true);
      setAccount(_account);
      setwalletWeb3(_walletWeb3);
    }
  }, [_active, _account]);

  const wrapContractCall = useCallback(
    (func) => {
      return (...args) => {
        if (!contract) {
          throw new Error('Smart contract is not available');
        }

        return func(...args);
      };
    },
    [contract]
  );

  const onProvider = useCallback(
    async (provider) => {
      setStatus(WEB3_STATUS.UNKNOWN);

      const connector =
        typeof provider.safe !== 'undefined'
          ? safeAppConnector
          : new InjectedConnector({ provider });

      try {
        await activate(connector, undefined, true);
        setStatus(WEB3_STATUS.READY);
      } catch (e) {
        if (e instanceof NoEthereumProviderError) {
          setStatus(WEB3_STATUS.UNAVAILABLE);
        }
      }
    },
    [activate]
  );

  const onboard = useMemo(() => getOnboard({ onProvider }), [onProvider]);

  const connectWallet = useCallback(
    async (wallet) => {
      // console.log("WAllet",wallet)
      const selected = await onboard.walletSelect(wallet);
      if (!selected) {
        return false;
      }

      const ready = await onboard.walletCheck();
      if (!ready) {
        return false;
      }

      const {
        wallet: { name }
      } = onboard.getState();

      selectedWalletPersistence.set(name);

      return true;
    },
    [onboard]
  );

  const magicSDKSetup = () => {
    localStorage.setItem('currentNetwork', currentNetwork);
    const magicSDK = initializeMagicSDK();
    setMagic(magicSDK);
    const networkRPC = currentNetwork.rpcUrl;
    const _web3 = new Web3(transformProviderFromXinfin(magicSDK.rpcProvider));
    setwalletWeb3(_web3);
    return magicSDK;
  };

  useEffect(() => {
    if (!magic && isInvestor) {
      magicSDKSetup();
    }
  }, [isInvestor, currentNetwork, account, magic]);

  const connectMagicWallet = async (email, ref, inputRefs) => {
    // Check if magic is defined
    let _magic = magic;
    if (!_magic) {
      _magic = magicSDKSetup();
    }
    try {
      // Check if the user is already authenticated

      const isLoggedInResult = await _magic.user.isLoggedIn();

      if (typeof isLoggedInResult === 'boolean') {
        // isLoggedInResult is a boolean value, indicating the authentication status
        if (isLoggedInResult) {
          // If the user is already logged in, logout the user
          await _magic.user.logout();
          console.log('LOGOUT', await _magic.user.logout());
        }
        initiateMagicLogin(email, ref, inputRefs, _magic);
        setStatus(WEB3_STATUS.READY);
        // After logout or if the user was not logged in, initiate the login process using magic link
      } else {
        // Handle the case where isLoggedInResult is not a boolean (e.g., it might be an object)
        console.error('Unexpected result received from magic.user.isLoggedIn():', isLoggedInResult);
        setSubmitLoginForm(false);
      }

      return true;
    } catch (error) {
      console.error('Error checking authentication status or logging out:', error);
      setSubmitLoginForm(false);
    }
  };

  const initiateMagicLogin = (email, ref, inputRefs, magic) => {
    const _emailOtpHandler = magic?.auth.loginWithEmailOTP({
      email,
      showUI: false
    });
    _emailOtpHandler
      ?.on('email-otp-sent', () => {
        console.log('email-otp-sent');

        ref.current.click();
        console.log('inputRefs', inputRefs);
        setTimeout(() => {
          if (inputRefs[0].current) {
            console.log('inputRefs[0].current', inputRefs[0].current);
            inputRefs[0].current?.focus();
          }
        }, 1000);
        // Send the OTP for verification
        // _emailOtpHandler.emit('verify-email-otp', otp);
      })
      .on('error', (reason) => {
        // is called if the Promise rejects
        console.error('REASEDDDON', reason);
        setOtpVerificationOngoing(false);
      });
    setEmailOtpHandler(_emailOtpHandler);
    return _emailOtpHandler;
  };

  const verifyOtp = useCallback(
    async (otp, ref, count) => {
      try {
        emailOtpHandler?.emit('verify-email-otp', otp);
        emailOtpHandler?.on('invalid-email-otp', () => {
          enqueueSnackbar('Invalid OTP', { variant: 'error', preventDuplicate: true });
          setOtpVerificationOngoing(false);
          if (count === 2) {
            emailOtpHandler?.emit('cancel');
            ref?.current?.click();
            enqueueSnackbar('You have exceeded the maximum number of tries', {
              variant: 'error'
            });
          }
        });
        emailOtpHandler?.on('done', async () => {
          setOtpVerificationComplete(false);
          const userDetails = await magic?.user.getInfo();
          console.log('🚀 ~ emailOtpHandler?.on ~ userDetails:', userDetails);
          const balance = await ethersProvider?.getBalance(userDetails?.publicAddress ?? '');
          setBalance(balance ? ethers.utils.formatEther(balance) : '');
          localStorage.setItem('token', JSON.stringify(userDetails?.publicAddress));
          setUserDetails({ email: userDetails?.email ?? '' });
          setActive(true);
          setConnectedAccount(userDetails?.publicAddress ?? '');
          setWeb3Connecting(false);
          setAccount(userDetails?.publicAddress ?? '');
          ref?.current?.click();

          setOtpVerificationOngoing(false);
          setOtpVerificationComplete(true);
          setStatus(WEB3_STATUS.READY);
          // setIsReady(true)
        });
        emailOtpHandler?.on('error', (reason) => {
          // is called if the Promise rejects
          console.error('REASEDDDON', reason);
          setOtpVerificationOngoing(false);
        });
        return true;
      } catch (error) {
        console.log('error', error);
        throwErrorMessage(error);
        setOtpVerificationOngoing(false);
      }
    },
    [emailOtpHandler, magic]
  );

  useEffect(() => {
    const token = userTokenPersistence.get();

    try {
      const fetchdata = async () => {
        if (magic && token && isInvestor) {
          const isLoggedIn = await magic?.user.isLoggedIn();
          // console.log('isLoggedIn', isLoggedIn);
          if (isLoggedIn) {
            const userDetails = await magic?.user.getInfo();
            localStorage.setItem('token', JSON.stringify(userDetails?.publicAddress));
            setUserDetails({ email: userDetails?.email ?? '' });
            setActive(true);
            setAccount(userDetails?.publicAddress ?? '');
            setConnectedAccount(userDetails?.publicAddress ?? '');
            setWeb3Connecting(false);
          }
        }
      };
      fetchdata();
    } catch (error) {
      console.error('Error fetching data, might Logged OUT:', error);
    }
  }, [magic, isInvestor]);

  const disconnectMagicWallet = async () => {
    try {
      disconnectMagic();
      setAccount('');
      setActive(false);
      setwalletWeb3(readOnlyWeb3);
    } catch (e) {
      console.log(e);
    }
  };

  const disconnectWallet = async () => {
    onboard.walletReset();
    deactivate();
    selectedWalletPersistence.clear();
    setAccount('');
    setActive(false);
    setwalletWeb3(readOnlyWeb3);
  };

  // We disable the following eslint rule,
  // because we know what dependencies wrapContractCall(...) has
  /* eslint-disable react-hooks/exhaustive-deps */

  const addIssuer = useCallback(
    wrapContractCall((countryCode, primaryAddress, hashOnly = true, hash) => {
      return (hashOnly ? sendTransactionHashOnly : sendTransaction)(
        web3,
        contract.methods.addIssuer(countryCode, primaryAddress),
        hashOnly ? {} : { hash }
      );
    }),
    [wrapContractCall, web3, contract]
  );

  const addFundManager = useCallback(
    wrapContractCall((countryCode, primaryAddress, hashOnly = true, hash) => {
      return (hashOnly ? sendTransactionHashOnly : sendTransaction)(
        web3,
        contract.methods.addFundManager(countryCode, primaryAddress),
        hashOnly ? {} : { hash }
      );
    }),
    [wrapContractCall, web3, contract]
  );

  const removeFundManager = useCallback(
    wrapContractCall((primaryAddress) => {
      return sendTransactionHashOnly(web3, contract.methods.removeFundManager(primaryAddress));
    }),
    [wrapContractCall, web3, contract]
  );
  // const addCustodian = useCallback(
  //   wrapContractCall((countryCode, primaryAddress) => {
  //     return sendTransactionHashOnly(
  //       web3,
  //       contract.methods.addCustodian(countryCode, primaryAddress)
  //     );
  //   }),
  //   [wrapContractCall, web3, contract]
  // );

  const addInsurer = useCallback(
    wrapContractCall((countryCode, primaryAddress) => {
      return sendTransactionHashOnly(
        web3,
        contract.methods.addInsurer(countryCode, primaryAddress)
      );
    }),
    [wrapContractCall, web3, contract]
  );

  const addInsurerAccounts = useCallback(
    wrapContractCall((primaryAddress, addresses) => {
      return sendTransactionHashOnly(
        web3,
        contract.methods.addInsurerAccounts(primaryAddress, addresses)
      );
    }),
    [wrapContractCall, web3, contract]
  );

  const addIssuerAccounts = useCallback(
    wrapContractCall((primaryAddress, addresses) => {
      return sendTransactionHashOnly(
        web3,
        contract.methods.addIssuerAccounts(primaryAddress, addresses)
      );
    }),
    [wrapContractCall, web3, contract]
  );

  // const addCustodianAccounts = useCallback(
  //   wrapContractCall((primaryAddress, addresses) => {
  //     return sendTransactionHashOnly(
  //       web3,
  //       contract.methods.addCustodianAccounts(primaryAddress, addresses)
  //     );
  //   }),
  //   [wrapContractCall, web3, contract]
  // );

  const addKycProviderAccounts = useCallback(
    wrapContractCall((primaryAddress, addresses) => {
      return sendTransactionHashOnly(
        web3,
        contract.methods.addKycProviderAccounts(primaryAddress, addresses)
      );
    }),
    [wrapContractCall, web3, contract]
  );

  const removeIssuerAccounts = useCallback(
    wrapContractCall((primaryAddress, addresses) => {
      return sendTransactionHashOnly(
        web3,
        contract.methods.removeIssuerAccounts(primaryAddress, addresses)
      );
    }),
    [wrapContractCall, web3, contract]
  );

  // const removeCustodianAccounts = useCallback(
  //   wrapContractCall((primaryAddress, addresses) => {
  //     return sendTransactionHashOnly(
  //       web3,
  //       contract.methods.removeCustodianAccounts(primaryAddress, addresses)
  //     );
  //   }),
  //   [wrapContractCall, web3, contract]
  // );

  const removeKycProviderAccounts = useCallback(
    wrapContractCall((primaryAddress, addresses) => {
      return sendTransactionHashOnly(
        web3,
        contract.methods.removeKycProviderAccounts(primaryAddress, addresses)
      );
    }),
    [wrapContractCall, web3, contract]
  );

  const removeInsurerAccounts = useCallback(
    wrapContractCall((primaryAddress, addresses) => {
      return sendTransactionHashOnly(
        web3,
        contract.methods.removeInsurerAccounts(primaryAddress, addresses)
      );
    }),
    [wrapContractCall, web3, contract]
  );

  const removeIssuer = useCallback(
    wrapContractCall((primaryAddress) => {
      return sendTransactionHashOnly(web3, contract.methods.removeIssuer(primaryAddress));
    }),
    [wrapContractCall, web3, contract]
  );

  const removeCustodian = useCallback(
    wrapContractCall((primaryAddress) => {
      return sendTransactionHashOnly(web3, contract.methods.removeCustodian(primaryAddress));
    }),
    [wrapContractCall, web3, contract]
  );

  const removeInsurer = useCallback(
    wrapContractCall((primaryAddress) => {
      return sendTransactionHashOnly(web3, contract.methods.removeInsurer(primaryAddress));
    }),
    [wrapContractCall, web3, contract]
  );

  const isIssuer = useCallback(
    wrapContractCall((tokenAddress, investor, value) => {
      return contract.methods.isIssuer(tokenAddress, investor, value).call();
    }),
    [wrapContractCall, contract]
  );

  const canIssue = useCallback(
    wrapContractCall((tokenAddress, investor, value) => {
      return contract.methods.canIssue(tokenAddress, investor, value).call();
    }),
    [wrapContractCall, contract]
  );

  const isKycProvider = useCallback(
    wrapContractCall((address) => {
      return contract.methods.isKycProvider(address).call();
    }),
    [wrapContractCall, contract]
  );
  const tokenOwner = useCallback(
    wrapContractCall((address) => {
      tokenContract.options.address = address;
      return tokenContract.methods.owner().call();
    }),
    [wrapContractCall, tokenContract]
  );

  const addKycProvider = useCallback(
    wrapContractCall((countryCode, primaryAddress) => {
      return sendTransactionHashOnly(
        web3,
        contract.methods.addKycProvider(countryCode, primaryAddress)
      );
    }),
    [wrapContractCall, web3, contract]
  );

  const removeKycProvider = useCallback(
    wrapContractCall((primaryAddress) => {
      return sendTransactionHashOnly(web3, contract.methods.removeKycProvider(primaryAddress));
    }),
    [wrapContractCall, web3, contract]
  );

  const updateKyc = useCallback(
    wrapContractCall((issuerAddress, lei, investorData) => {
      return sendTransactionHashOnly(
        web3,
        contract.methods.updateKyc(issuerAddress, lei, investorData)
      );
    }),
    [wrapContractCall, web3, contract]
  );

  // Liquidity Pool

  const addLiquidityPool = useCallback(
    wrapContractCall((poolAddr, setlAddr) => {
      return sendTransactionHashOnly(web3, contract.methods.addLiqudityPool(poolAddr, setlAddr));
    }),
    [wrapContractCall, web3, contract]
  );

  const removeLiquidityPool = useCallback(
    wrapContractCall((poolAddr) => {
      return sendTransactionHashOnly(web3, contract.methods.removeLiquidityPool(poolAddr));
    }),
    [wrapContractCall, web3, contract]
  );

  const issueBatch = useCallback(
    wrapContractCall((tokenAddress, addresses, values) => {
      tokenContract.options.address = tokenAddress;

      return sendTransactionHashOnly(web3, tokenContract.methods.issueBatch(addresses, values));
    }),
    [wrapContractCall, web3, tokenContract]
  );

  const batchRedeem = useCallback(
    wrapContractCall((tokenAddress, addresses, values) => {
      tokenContract.options.address = tokenAddress;

      return sendTransactionHashOnly(web3, tokenContract.methods.redeemBatch(addresses, values));
    }),
    [wrapContractCall, web3, tokenContract]
  );

  const requestRedeem = useCallback(
    wrapContractCall(async (tokenAddress, owner, qty) => {
      tokenContract.options.address = tokenAddress;
      return sendTransactionHashOnly(web3, tokenContract.methods.approve(owner, qty));
    }),
    [wrapContractCall, web3, tokenContract]
  );

  const pause = useCallback(
    wrapContractCall((tokenAddress) => {
      tokenContract.options.address = tokenAddress;
      return sendTransactionHashOnly(web3, tokenContract.methods.pause());
    }),
    [wrapContractCall, web3, tokenContract]
  );

  const sendEth = useCallback(
    wrapContractCall(() => {
      return sendTransactionHashOnly(
        web3,
        web3.eth.sendTransaction({
          to: '0xf39240321a6aadf024bb33a4e89d1e5512dc78f8',
          from: account,
          value: web3.utils.toWei('0.001', 'ether')
        })
      );
    }),
    [wrapContractCall, web3]
  );

  const unpause = useCallback(
    wrapContractCall((tokenAddress) => {
      tokenContract.options.address = tokenAddress;
      return sendTransactionHashOnly(web3, tokenContract.methods.unpause());
    }),
    [wrapContractCall, web3, tokenContract]
  );
  const finalizeIssuance = useCallback(
    wrapContractCall((tokenAddress) => {
      tokenContract.options.address = tokenAddress;
      return sendTransactionHashOnly(web3, tokenContract.methods.finalizeIssuance());
    }),
    [wrapContractCall, web3, tokenContract]
  );

  const burnContract = useCallback(
    wrapContractCall((tokenAddress, addr = account) => {
      tokenContract.options.address = tokenAddress;
      return sendTransactionHashOnly(web3, tokenContract.methods.burnContract(addr));
    }),
    [wrapContractCall, web3, tokenContract]
  );

  const getTokenDocument = useCallback(
    wrapContractCall((address, documentName) => {
      tokenTvTContract.options.address = address;
      return tokenTvTContract.methods.getDocument(documentName).call();
    }),
    [wrapContractCall, web3, tokenTvTContract]
  );

  const publishToken = useCallback(
    wrapContractCall(
      (
        tokenName,
        tokenSymbol,
        // tokenDecimals,
        tokenTotalSupply,
        tokenValue,
        tokenCurrency,
        issuerPrimaryAddress,
        // custodianPrimaryAddress,
        // kycproviderPrimaryAddress,
        insurerPrimaryAddress,
        earlyRedemption,
        minSubscription,
        paymentTokens,
        issuanceSwapMultiple,
        redemptionSwapMultiple,
        maturityPeriod,
        settlementPeriod,
        collateral,
        insurerCollateral,
        allowedCountries,
        classification,
        useIssuerWhitelist,
        // onChainKyc,
        documentName,
        documentUri,
        documentHash,
        tokenType,
        liquidityPool,
        issuerSettlementAddress,
        issueType
      ) => {
        console.log('tokenName: ', tokenName);
        console.log('tokenSymbol: ', tokenSymbol);
        // console.log('tokenDecimals: ', tokenDecimals);
        console.log('tokenTotalSupply: ', tokenTotalSupply);
        console.log('tokenValue: ', tokenValue);
        console.log('tokenCurrency: ', tokenCurrency);
        console.log('earlyRedemption: ', earlyRedemption);
        console.log('minSubscription: ', minSubscription);
        console.log('issuerPrimaryAddress: ', issuerPrimaryAddress);
        // console.log('custodianPrimaryAddress: ', custodianPrimaryAddress);
        // console.log('kycproviderPrimaryAddress: ', kycproviderPrimaryAddress);
        console.log('paymentTokens', paymentTokens);
        console.log('issuanceSwapMultiple', issuanceSwapMultiple);
        console.log('redemptionSwapMultiple', redemptionSwapMultiple);
        console.log('maturityPeriod', maturityPeriod);
        console.log('settlementPeriod', settlementPeriod);
        console.log('collateral', documentName);
        console.log('collateral', documentUri);
        console.log('collateral', documentHash);
        console.log('tokenType', tokenType);
        console.log('liquidityPool', liquidityPool);
        console.log('issuerSettlementAddress', issuerSettlementAddress);

        return sendTransactionHashOnly(
          web3,
          contract.methods.publishToken({
            name: tokenName,
            symbol: tokenSymbol,
            // decimals: tokenDecimals,
            maxTotalSupply: tokenTotalSupply,
            value: tokenValue,
            currency: tokenCurrency,
            issuerPrimaryAddress,
            // custodianPrimaryAddress,
            // kycProviderPrimaryAddress: kycproviderPrimaryAddress,
            insurerPrimaryAddress,
            earlyRedemption,
            minSubscription,
            paymentTokens,
            issuanceSwapMultiple,
            redemptionSwapMultiple,
            maturityPeriod,
            settlementPeriod,
            collateral,
            insurerCollateralShare: insurerCollateral,
            countries: allowedCountries,
            investorClassifications: classification,
            useIssuerWhitelist,
            // onChainKyc,
            documentName,
            documentUri,
            documentHash,
            tokenType,
            liquidityPool,
            issuerSettlementAddress,
            issueType
          })
        );
      }
    ),
    [wrapContractCall, web3, contract]
  );

  const addWhiteList = useCallback(
    wrapContractCall((tokenAddress, addresslist) => {
      return sendTransactionHashOnly(
        web3,
        contract.methods.addWhitelist(tokenAddress, addresslist)
      );
    }),
    [wrapContractCall, web3, contract]
  );

  const addIssuerWhiteList = useCallback(
    wrapContractCall((issuerAddress, addresslist) => {
      return sendTransactionHashOnly(
        web3,
        contract.methods.addIssuerWhitelist(issuerAddress, addresslist)
      );
    }),
    [wrapContractCall, web3, contract]
  );

  const removeIssuerWhitelist = useCallback(
    wrapContractCall((issuerAddress, addresslist) => {
      return sendTransactionHashOnly(
        web3,
        contract.methods.removeIssuerWhitelist(issuerAddress, addresslist)
      );
    }),
    [wrapContractCall, web3, contract]
  );

  const checkRemoveWhiteList = useCallback(
    wrapContractCall((tokenAddress, addresslist) => {
      return contract.methods.removeWhitelist(tokenAddress, addresslist).call();
    }),
    [wrapContractCall, web3, contract]
  );

  const removeWhiteList = useCallback(
    wrapContractCall((tokenAddress, addresslist) => {
      return sendTransactionHashOnly(
        web3,
        contract.methods.removeWhitelist(tokenAddress, addresslist)
      );
    }),
    [wrapContractCall, web3, contract]
  );

  // ERC20 Contract Functions
  const getERC20Name = useCallback(
    wrapContractCall((tokenAddress) => {
      erc20Contract.options.address = tokenAddress;
      return erc20Contract.methods.name().call();
    }),
    [wrapContractCall, web3, erc20Contract]
  );

  const getERC20Symbol = useCallback(
    wrapContractCall((tokenAddress) => {
      erc20Contract.options.address = tokenAddress;
      return erc20Contract.methods.symbol().call();
    }),
    [wrapContractCall, web3, erc20Contract]
  );

  const getERC20Decimals = useCallback(
    wrapContractCall((tokenAddress) => {
      erc20Contract.options.address = tokenAddress;
      return erc20Contract.methods.decimals().call();
    }),
    [wrapContractCall, web3, erc20Contract]
  );

  const getBalanceOf = useCallback(
    wrapContractCall((tokenAddress, walletAddress = account) => {
      erc20Contract.options.address = tokenAddress;
      return erc20Contract.methods.balanceOf(walletAddress).call();
    }),
    [wrapContractCall, web3, erc20Contract]
  );
  const getTotalSupply = useCallback(
    wrapContractCall((tokenAddress) => {
      erc20Contract.options.address = tokenAddress;
      return erc20Contract.methods.totalSupply().call();
    }),
    [wrapContractCall, web3, erc20Contract]
  );

  const transferPaymentToken = useCallback(
    wrapContractCall(async (tokenAddress, to, amount) => {
      erc20Contract.options.address = tokenAddress;
      // const _balance = await erc20Contract.methods.balanceOf(account).call();
      // console.log("typeof balance", typeof _balance);
      // console.log("typeof amount", typeof amount);
      // if(web3.utils.toBn(_balance).lt(web3.utils.toBN(amount))) {
      //   throw new Error('Insufficient balance');
      // }
      return sendTransactionHashOnly(web3, erc20Contract.methods.transfer(to, amount));
    }),
    [wrapContractCall, web3, erc20Contract]
  );

  // Native Implementation to transfer XDC and get the Txn Hash

  // const sendXdc = useCallback(
  //   wrapContractCall((toAddr, value) => {
  //     // sendEth to the toAddr and wait only for the transaction hash
  //     return getTransactionHash(
  //       web3.eth.sendTransaction({
  //         from: account,
  //         to: toAddr,
  //         value: web3.utils.toWei(value, 'ether')
  //       })
  //     );
  //   }),
  //   [wrapContractCall, web3]
  // );

  const paymentTokenGiveAllowance = useCallback(
    wrapContractCall((tokenAddress, value) => {
      erc20Contract.options.address = tokenAddress;
      return sendTransactionHashOnly(
        web3,
        erc20Contract.methods.approve(currentNetwork.escrowManagerAddress, value)
      );
    })
  );

  // Payment Tokens
  const addPaymentToken = useCallback(
    wrapContractCall((tokenAddress) => {
      return sendTransactionHashOnly(web3, contract.methods.addPaymentToken(tokenAddress));
    }),
    [wrapContractCall, web3, contract]
  );

  const removePaymentToken = useCallback(
    wrapContractCall((tokenAddress) => {
      return sendTransactionHashOnly(web3, contract.methods.removePaymentToken(tokenAddress));
    }),
    [wrapContractCall, web3, contract]
  );

  // TVT Contract Functions
  const tvtIssue = useCallback(
    wrapContractCall((tokenAddress, subscriber, qty, tranche) => {
      tokenTvTContract.options.address = tokenAddress;
      return sendTransactionHashOnly(
        web3,
        tokenTvTContract.methods.issue(subscriber, qty, tranche)
      );
    }),
    [wrapContractCall, web3, tokenTvTContract]
  );
  const tvtRedeem = useCallback(
    wrapContractCall((tokenAddress, subscriber, qty, issuanceOrderId) => {
      tokenTvTContract.options.address = tokenAddress;
      return sendTransactionHashOnly(
        web3,
        tokenTvTContract.methods.redeem(subscriber, qty, issuanceOrderId)
      );
    }),
    [wrapContractCall, web3, tokenTvTContract]
  );
  const tvtRedeemWithSettlementAddress = useCallback(
    wrapContractCall((tokenAddress, subscriber, settlementAddress, issuerAddress, qty) => {
      tokenTvTContract.options.address = tokenAddress;
      return sendTransactionHashOnly(
        web3,
        tokenTvTContract.methods.redeem(subscriber, settlementAddress, issuerAddress, qty)
      );
    }),
    [wrapContractCall, web3, tokenTvTContract]
  );
  const matureBalanceOf = useCallback(
    wrapContractCall((tokenAddress, subscriber) => {
      tokenTvTContract.options.address = tokenAddress;
      return tokenTvTContract.methods.matureBalanceOf(subscriber).call();
    }),
    [wrapContractCall, web3, tokenTvTContract]
  );
  const updateTokenIssuanceSwapRatio = useCallback(
    wrapContractCall((tokenAddress, value) => {
      tokenTvTContract.options.address = tokenAddress;
      return sendTransactionHashOnly(
        web3,
        tokenTvTContract.methods.updateTokenIssuanceSwapRatio(value)
      );
    }),
    [wrapContractCall, web3, tokenTvTContract]
  );
  const setMaxSupply = useCallback(
    wrapContractCall((tokenAddress, value) => {
      tokenTvTContract.options.address = tokenAddress;
      return sendTransactionHashOnly(web3, tokenTvTContract.methods.setMaxSupply(value));
    }),
    [wrapContractCall, web3, tokenTvTContract]
  );

  // Escrow Contract Functions
  const getIssuanceEscrowCondition = useCallback(
    wrapContractCall((orderId) => {
      return escrowContract.methods.getIssuanceEscrowConditions(orderId).call();
    }),
    [wrapContractCall, web3, escrowContract]
  );
  const getRedemptionEscrowConditions = useCallback(
    wrapContractCall((orderId) => {
      return escrowContract.methods.getRedemptionEscrowConditions(orderId).call();
    }),
    [wrapContractCall, web3, escrowContract]
  );

  const depositCollateral = useCallback(
    wrapContractCall((account, value) => {
      return sendTransactionHashOnly(web3, escrowContract.methods.depositCollateral(account), {
        value: web3.utils.toWei(value, 'ether')
      });
    }),
    [wrapContractCall, web3, escrowContract]
  );

  const depositInsurerCollateral = useCallback(
    wrapContractCall((account, issuer, value) => {
      return sendTransactionHashOnly(
        web3,
        escrowContract.methods.depositInsurerCollateral(account, issuer),
        {
          value: web3.utils.toWei(value, 'ether')
        }
      );
    }),
    [wrapContractCall, web3, escrowContract]
  );

  const withdrawCollateral = useCallback(
    wrapContractCall((value) => {
      return sendTransactionHashOnly(
        web3,
        escrowContract.methods.withdrawCollateral((value = web3.utils.toWei(value, 'ether')))
      );
    }),
    [wrapContractCall, web3, escrowContract]
  );

  const withdrawInsurerCollateral = useCallback(
    wrapContractCall((account, issuer, value) => {
      return sendTransactionHashOnly(
        web3,
        escrowContract.methods.withdrawInsurerCollateral(
          account,
          issuer,
          web3.utils.toWei(value, 'ether')
        )
      );
    }),
    [wrapContractCall, web3, escrowContract]
  );

  const collateralBalance = useCallback(
    wrapContractCall((account) => {
      return escrowContract.methods.collateralBalance(account).call();
    }),
    [wrapContractCall, web3, escrowContract]
  );

  const insurerCollateralBalanceByIssuer = useCallback(
    wrapContractCall((account, issuer) => {
      return escrowContract.methods.insurerCollateralBalanceByIssuer(account, issuer).call();
    }),
    [wrapContractCall, web3, escrowContract]
  );

  const lockedCollateralBalance = useCallback(
    wrapContractCall((account) => {
      return escrowContract.methods.lockedCollateralBalance(account).call();
    }),
    [wrapContractCall, web3, escrowContract]
  );

  const lockedInsurerCollateralBalanceByIssuer = useCallback(
    wrapContractCall((account, issuer) => {
      return escrowContract.methods.lockedInsurerCollateralBalanceByIssuer(account, issuer).call();
    }),
    [wrapContractCall, web3, escrowContract]
  );

  const swapIssuance = useCallback(
    wrapContractCall((orderId) => {
      return sendTransactionHashOnly(web3, escrowContract.methods.swapIssuance(orderId));
    }),
    [wrapContractCall, web3, escrowContract]
  );

  const cancelIssuance = useCallback(
    wrapContractCall((orderId) => {
      return sendTransactionHashOnly(web3, escrowContract.methods.cancelIssuance(orderId));
    }),
    [wrapContractCall, web3, escrowContract]
  );

  const swapRedeem = useCallback(
    wrapContractCall((orderId) => {
      return sendTransactionHashOnly(web3, escrowContract.methods.swapRedemption(orderId));
    }),
    [wrapContractCall, web3, escrowContract]
  );

  const sendInterimPayment = useCallback(
    wrapContractCall((paymentTokenAddr, investorAddr, value) => {
      return sendTransactionHashOnly(
        web3,
        escrowContract.methods.sendInterimPayment(paymentTokenAddr, investorAddr, value)
      );
    }),
    [wrapContractCall, web3, escrowContract]
  );

  const sendXdc = useCallback(
    wrapContractCall((investorAddr, value) => {
      return sendTransactionHashOnly(web3, escrowContract.methods.sendXDC(investorAddr), {
        value: web3.utils.toWei(value, 'ether')
      });
    }),
    [wrapContractCall, web3, escrowContract]
  );

  // Liquidity Pool Calls

  const poolAllowanceOnPaymentToken = useCallback(
    wrapContractCall(
      (poolAddr, paymentTokenAddr, amount, spender = currentNetwork.escrowManagerAddress) => {
        liquidityPoolContract.options.address = poolAddr;
        return sendTransactionHashOnly(
          web3,
          liquidityPoolContract.methods.approve(spender, paymentTokenAddr, amount)
        );
      }
    ),
    [wrapContractCall, web3, liquidityPoolContract]
  );

  const poolRedeemTvt = useCallback(
    wrapContractCall((poolAddr, tradeTokenAddr, amount) => {
      liquidityPoolContract.options.address = poolAddr;
      return sendTransactionHashOnly(
        web3,
        liquidityPoolContract.methods.redeemTvT(tradeTokenAddr, amount)
      );
    }),
    [wrapContractCall, web3, liquidityPoolContract]
  );

  const poolAddPaymentToken = useCallback(
    wrapContractCall((poolAddr, tokenAddress) => {
      liquidityPoolContract.options.address = poolAddr;
      return sendTransactionHashOnly(
        web3,
        liquidityPoolContract.methods.addPaymentToken(tokenAddress)
      );
    }),
    [wrapContractCall, web3, liquidityPoolContract]
  );

  const poolRemovePaymentToken = useCallback(
    wrapContractCall((poolAddr, tokenAddress) => {
      liquidityPoolContract.options.address = poolAddr;
      return sendTransactionHashOnly(
        web3,
        liquidityPoolContract.methods.removePaymentToken(tokenAddress)
      );
    }),
    [wrapContractCall, web3, liquidityPoolContract]
  );

  const poolWithdrawPaymentToken = useCallback(
    wrapContractCall((poolAddr, to, tokenAddress, amount) => {
      liquidityPoolContract.options.address = poolAddr;
      return sendTransactionHashOnly(
        web3,
        liquidityPoolContract.methods.transfer(to, tokenAddress, amount)
      );
    }),
    [wrapContractCall, web3, liquidityPoolContract]
  );

  const publishPool = useCallback(
    wrapContractCall(() => {
      return poolController.methods.publishPool().send({ from: currentNetwork.account });
    }),
    [wrapContractCall, web3, poolController]
  );

  useEffect(() => {
    if (isMultisig === null || isMultisig === true) {
      return;
    }

    (async () => {
      const selectedWallet = selectedWalletPersistence.get();

      if (!selectedWallet) {
        return;
      }

      const connected = await connectWallet(selectedWallet);

      if (!connected) {
        selectedWalletPersistence.clear();
      }
    })();
  }, [isMultisig, isAuthorized]);

  /* eslint-enable react-hooks/exhaustive-deps */

  return {
    isMultisig,
    status,
    active,
    account,
    setAccount,
    chainId,
    web3,
    addIssuer,
    removeIssuer,
    connectWallet,
    isIssuer,
    disconnectWallet,
    addIssuerAccounts,
    // addCustodian,
    // removeCustodian,
    addKycProvider,
    publishToken,
    removeKycProvider,
    // addCustodianAccounts,
    addKycProviderAccounts,
    removeIssuerAccounts,
    // removeCustodianAccounts,
    removeKycProviderAccounts,
    addWhiteList,
    removeWhiteList,
    checkRemoveWhiteList,
    issueBatch,
    requestRedeem,
    batchRedeem,
    tokenOwner,
    pause,
    unpause,
    finalizeIssuance,
    getERC20Name,
    getERC20Symbol,
    getERC20Decimals,
    addPaymentToken,
    removePaymentToken,
    tvtIssue,
    getIssuanceEscrowCondition,
    depositCollateral,
    depositInsurerCollateral,
    withdrawCollateral,
    withdrawInsurerCollateral,
    collateralBalance,
    insurerCollateralBalanceByIssuer,
    lockedCollateralBalance,
    lockedInsurerCollateralBalanceByIssuer,
    swapIssuance,
    cancelIssuance,
    paymentTokenGiveAllowance,
    tvtRedeem,
    tvtRedeemWithSettlementAddress,
    getRedemptionEscrowConditions,
    swapRedeem,
    matureBalanceOf,
    sendEth,
    addInsurer,
    // addInsurerAccounts,
    removeInsurer,
    // removeInsurerAccounts,
    addIssuerWhiteList,
    removeIssuerWhitelist,
    updateTokenIssuanceSwapRatio,
    setMaxSupply,
    updateKyc,
    transferPaymentToken,
    sendInterimPayment,
    canIssue,
    sendXdc,
    getTokenDocument,
    getBalanceOf,
    burnContract,
    addLiquidityPool,
    removeLiquidityPool,
    poolAllowanceOnPaymentToken,
    poolRedeemTvt,
    poolAddPaymentToken,
    poolRemovePaymentToken,
    poolWithdrawPaymentToken,
    publishPool,
    getTotalSupply,
    verifyOtp,
    connectMagicWallet,
    otpVerificationOngoing,
    setOtpVerificationOngoing,
    connectedAccount,
    otpVerificationComplete,
    setOtpVerificationComplete,
    disconnectMagicWallet,
    addFundManager,
    removeFundManager
  };
};

export const [Web3StateProvider, useWeb3State] = constate(useWeb3State_);
