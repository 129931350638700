import {
  Container,
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Card,
  CardContent,
  TableBody,
  Button,
  Chip
} from '@mui/material';
import Breadcrumbs from 'components/Breadcrumbs';
import tokenTerms from '../../mocks/TokenTerms.json';
import { useBusinessEntityState, useAuthStateShared } from 'state';
import CreateAllotmentTable from 'components/issuerDashboard/CreateAllotmentTable';
import ReadOnlyNotifier from 'components/ReadOnlyNotifier';
import { onlyIssuer } from 'helpers/rbac';
import { getChipColor } from 'helpers/handler';
import TokenTable from 'components/issuerDashboard/TokenTable';

const CreateAllotment = () => {
  const { currentToken: token, createAllotmentsList } = useBusinessEntityState();
  const { role } = useAuthStateShared();
  console.log('token', token);
  return (
    <Container>
      <Breadcrumbs aria-label="breadcrumb" pageHead="Create Allotment" />

      {/* Token Table */}
      <TokenTable currToken={token} />
      {!onlyIssuer(role) && <ReadOnlyNotifier userRole={role} />}
      {/* Allotments  Table */}
      <Box>
        <CreateAllotmentTable
          tokenPrice={
            token.token_type === 'PROXY_TOKEN' ? token.token_nav : token.token_issue_price
          }
        />
      </Box>
      {/* SUBMIT BUTTONS */}
    </Container>
  );
};

export default CreateAllotment;
