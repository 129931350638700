import { useWeb3 } from 'state';

import { NETWORK_ID } from 'config';

const RequireNetwork = ({ children, not = false }) => {
  const { chainId, active } = useWeb3();

  const condition = !active || chainId === NETWORK_ID;
  const show = not ? !condition : condition;

  return show ? children : null;
};

export default RequireNetwork;
