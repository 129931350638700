import Page from 'components/Page';
import React from 'react';
import { Card, Container, Box } from '@mui/material';
import Breadcrumbs from 'components/Breadcrumbs';
import { CardContent } from '@material-ui/core';
import IssuerWhiteListTable from 'components/masterData/Issuer/IssuerWhiteListTable';
import { LoadingButton } from '@mui/lab';

const IssuerWhitelist = () => {
  return (
    <Page title="Issuer Whitelist">
      <Container>
        <Breadcrumbs pageHead="Issuer Whitelist" />

        <Card>
          <CardContent>
            <IssuerWhiteListTable />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end'
              }}
            >
              <LoadingButton
                loadingPosition="start"
                variant="gradient"
                sx={{
                  mt: '2rem'
                }}
              >
                Add to Issuer Whitelist
              </LoadingButton>
              <LoadingButton
                loadingPosition="start"
                variant="gradient"
                sx={{
                  mt: '2rem',
                  ml: '1rem'
                }}
              >
                Remove from Issuer Whitelist
              </LoadingButton>
            </Box>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
};

export default IssuerWhitelist;
