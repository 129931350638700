import { Alert } from '@mui/material';
import { Box } from '@mui/system';
import { titleCase } from 'helpers/text';
import React from 'react';

const ReadOnlyNotifier = ({ userRole }) => {
  return (
    <Box sx={{ mt: 2 }}>
      <Alert severity="warning">
        You have logged in as a {titleCase(userRole)}, which only has Read Only access to this page.
      </Alert>
    </Box>
  );
};

export default ReadOnlyNotifier;
