import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';

import Footer from './main/Footer';

import HelpButton from 'components/HelpButton';
import RequireNetworkOverlay from 'components/RequireNetworkOverlay';
import RequireUserXinFinAccountOverlay from 'components/userxinfin/UserXinFinAccountOverlay';
import WalletRequireOverlay from 'components/WalletRequireOverlay';
import IssuerNavbar from 'components/IssuerNavbar';

const FundManagerLayout = () => {
  return (
    <>
      <IssuerNavbar />
      <Box sx={{ position: 'relative', flex: '1' }}>
        <RequireNetworkOverlay />
        <RequireUserXinFinAccountOverlay />
        <WalletRequireOverlay />
        <Outlet />
        <HelpButton />
        <Footer />
      </Box>
    </>
  );
};

export default FundManagerLayout;
