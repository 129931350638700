import React, { useEffect, useState } from 'react';
import {
  Box,
  Container,
  Grid,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  Typography,
  Button
} from '@mui/material';
import Page from 'components/Page';
import SubscriptionCard, { TokenPriceCard } from 'components/subscritiption/SubscriptionCard';
import SubscriptionHead from 'components/subscritiption/SubscriptionHead';
import EmptyDashboard from 'assets/images/emptyassets.png';
import { useNavigate, useLocation } from 'react-router';
import { useCoreTableState, useSubscriptionState, useBusinessEntityState, useWeb3, useHttpApi } from 'state';
import TokenSubCard from 'components/subscritiption/TokenSubCard';
import { useSnackbar } from 'notistack';
import { usePlaidLink, PlaidLinkOptions, PlaidLinkOnSuccess } from 'react-plaid-link';

const DataNotAvailable = () => {
  return (
    <>
      <Box
        sx={{
          height: '60vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <img src={EmptyDashboard} alt="Empty Dashboard" />
        <Typography variant="p">No Token available</Typography>
      </Box>
    </>
  );
};
const SubscriptionHome = () => {
  const { account } = useWeb3();
  const { tokens } = useSubscriptionState();
  const { issueType, currencies } = useCoreTableState();
  const { issuers, fetchIssuer } = useBusinessEntityState();
  const [filter, setFilter] = useState('');
  const [filterTokens, setFilterTokens] = useState([]);
  const [sortissueType, setSortissueType] = useState('');
  const [sortbyCurrency, setSortbyCurrency] = useState('');
  const [sortbyIssuer, setSortbyIssuer] = useState('');
  const [isKycDone, setIsKycDone] = useState(false);
  const [isAuthDone, setIsAuthDone] = useState(false);
  const {createLinkToken, getIDVList, exchangePublicToken, getProcessorToken, getAstraAccessToken} = useHttpApi();

  const [token, setToken] = useState(null);
  const [linkData, setLinkData] = useState(null);
  const location = useLocation();

  // This will check the kyc status and Auth Status and set its values.
  const [kycStatus, setKycStatus] = useState("Not Done");
  const [bankAccountVerificationStatus, setBankAccountVerificationStatus] = useState("Not Done");

  const { open, ready, error, exit } = usePlaidLink({
    token,
    onSuccess: async (publicToken, metadata) => {
      setIsKycDone(true);
      setKycStatus('Done');
      if (publicToken){
        const exchangeResponse = await exchangePublicToken(publicToken, metadata.account_id);
        setIsAuthDone(true);
        setBankAccountVerificationStatus('Done');
        await getProcessorToken();
      }
      setToken(null);
      window.location.reload();
    },
    onExit: async (err, metadata) => {
      console.log(
        `Exited early. Error: ${JSON.stringify(err)} Metadata: ${JSON.stringify(metadata)}`
      )
      setToken(null);
      window.location.reload();
    }
});

  
  const getLinkToken = async (product) =>{
    const response = await createLinkToken(product);
    console.log('response from create token - - - -', response.link_token);
    setToken(response.link_token);
  };

  useEffect(() => {
    if (token) {
      if (ready) {
        open(); // Open the Plaid Link flow when token is set and link is ready
      }
    }
  }, [token, ready]); // Runs when `token` or `ready` changes

  // This useEffect will get all the PLAID related data on page load.
  const {enqueueSnackbar} = useSnackbar();
  useEffect( async ()=>{
    const idvLists = await getIDVList();
    if(idvLists.auth_status === true){
      setIsAuthDone(true);
      setBankAccountVerificationStatus('Done')
    }
    if(idvLists?.idv_response?.identity_verifications?.[0]?.status === 'success'){
      setIsKycDone(true);
      setKycStatus('Done');
    }
    else if(idvLists?.identity_verifications?.[0]?.status){
      setKycStatus(idvLists.identity_verifications[0]?.status);
    }

    
    const queryParams = new URLSearchParams(location.search);
    console.log('query params - - - -', queryParams);
    const authCode = queryParams.get('authorization_code');
  
    // Use authCode as needed
    if(authCode){
      // get the access code from the Auth Code.
      enqueueSnackbar('Astra Authorization completed. Click Invest again to buy tokens.', {
        variant: 'success'
      });
      await getAstraAccessToken(authCode);

    }

  },[])


  // console.log('tokens.', tokens);
  useEffect(() => {
    const _tokens = tokens.filter(
      (item) =>
        item.issue_type_id.issue_type_name === sortissueType ||
        item.token_currency.currency_code === sortbyCurrency ||
        item.issuer_id.issuer_name === sortbyIssuer
    );
    setFilterTokens(_tokens);
  }, [sortbyCurrency, sortissueType, sortbyIssuer, tokens]);
  const Data = () => {
    return (
      <>
        <Grid container spacing={3}>
          {filterTokens.map((token, index) => (
            <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
              <TokenSubCard {...token} account={account} isKycDone={isKycDone} isAuthDone={isAuthDone}  />
            </Grid>
          ))}
        </Grid>
        {filterTokens.length === 0 && <DataNotAvailable />}
      </>
    );
  };
  return (
    <Page title="Token Subscription">
      <SubscriptionHead title="Token Subscription" />
      <Container sx={{ mt: 3 }}>
        <Grid
          xs={12}
          sm={12}
          md={12}
          lg={12}
          sx={{
            direction: 'row',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            mb: 2
          }}
        >
          {/* Adding the buttons and label */}
          <Grid item xs={12} sm={8}>
            <Grid container spacing={2} direction="column">
              {/* First row in the first column */}
              <Grid item container xs={12} spacing={1} alignItems="center">
                <Grid item>
                  <InputLabel style={{ color: kycStatus === 'Done' ? 'green' : 'inherit' }} >KYC - {kycStatus}</InputLabel>
                </Grid>
                <Grid item mr={3}>
                  <Button variant="contained" size="small" onClick={()=>getLinkToken('KYC')} disabled={isKycDone}>Complete KYC</Button>
                </Grid>
                <Grid item>
                  <InputLabel style={{ color: bankAccountVerificationStatus === 'Done' ? 'green' : 'inherit' }} >Bank Account Verification - {bankAccountVerificationStatus}</InputLabel>
                </Grid>
                <Grid item>
                  <Button variant="contained" size="small" onClick={()=>getLinkToken('AUTH')}  disabled={(!(isKycDone)&&!(isAuthDone))|| isAuthDone}>Complete Auth</Button>
                </Grid>
              </Grid>
              {/* Second row in the first column */}
            </Grid>
          </Grid>
          {/* Adding the buttons and label */}
          <Grid item
            xs={12}
            sm={2}
            md={2}
            lg={2}
            sx={{ mb: 1, mr: 1, display: 'flex', justifyContent: 'flex-end' }}
          >
            <Typography variant="p">Filter by</Typography>
          </Grid>
          <Grid xs={12} sm={2} md={2} lg={2}>
            <FormControl size="small" variant="outlined" fullWidth>
              <Select
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
                value={filter}
                onChange={(event) => {
                  setFilter(event.target.value);
                  setSortissueType('');
                  setSortbyCurrency('');
                  setSortbyIssuer('');
                }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value="Issuers">
                  <em>Issuers</em>
                </MenuItem>
                <MenuItem value="IssueType">
                  <em>Issue Type</em>
                </MenuItem>
                <MenuItem value="Currency">
                  <em>Currency</em>
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          {filter === 'IssueType' && (
            <>
              <Grid
                xs={12}
                sm={12}
                md={12}
                lg={2}
                sx={{ mb: 1, mr: 1, display: 'flex', justifyContent: 'flex-end' }}
              >
                <Typography variant="p">Select </Typography>
              </Grid>
              <Grid xs={12} sm={12} md={12} lg={6}>
                <FormControl size="small" variant="outlined" fullWidth>
                  <Select
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    onChange={(event) => {
                      setSortissueType(event.target.value);
                      setSortbyCurrency('');
                      setSortbyIssuer('');
                    }}
                  >
                    <MenuItem value="">
                      <em>All</em>
                    </MenuItem>
                    {issueType.map((item, index) => (
                      <MenuItem key={index} value={item.issue_type_name}>
                        <em>{item.issue_type_name}</em>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </>
          )}
          {filter === 'Issuers' && (
            <>
              <Grid
                xs={6}
                sm={12}
                md={12}
                lg={2}
                sx={{ mb: 1, mr: 1, display: 'flex', justifyContent: 'flex-end' }}
              >
                <Typography variant="p">Select</Typography>
              </Grid>
              <Grid xs={6} sm={12} md={12} lg={6}>
                <FormControl size="small" variant="outlined" fullWidth>
                  <Select
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    onChange={(event) => {
                      setSortbyIssuer(event.target.value);
                      setSortbyCurrency('');
                      setSortissueType('');
                    }}
                  >
                    <MenuItem value="">
                      <em>All</em>
                    </MenuItem>
                    {issuers.map((issuer) => {
                      return (
                        <MenuItem value={issuer.issuer_name} key={issuer.issuer_id}>
                          {issuer.issuer_name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </>
          )}
          {filter === 'Currency' && (
            <>
              <Grid
                xs={6}
                sm={12}
                md={12}
                lg={2}
                sx={{ mb: 1, mr: 1, display: 'flex', justifyContent: 'flex-end' }}
              >
                <Typography variant="p">Select</Typography>
              </Grid>
              <Grid xs={6} sm={12} md={12} lg={6}>
                <FormControl size="small" variant="outlined" fullWidth>
                  <Select
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    onChange={(event) => {
                      setSortbyCurrency(event.target.value);
                      setSortissueType('');
                      setSortbyIssuer('');
                    }}
                  >
                    <MenuItem value="">
                      <em>All</em>
                    </MenuItem>
                    {currencies &&
                      currencies.map((currency) => {
                        return (
                          <MenuItem key={currency.id} value={currency.currency_code}>
                            {currency.currency_code} | {currency.currency_name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </Grid>
            </>
          )}
        </Grid>
        {tokens.length !== 0 ? (
          <>
            {!sortissueType && !sortbyCurrency && !sortbyIssuer ? (
              <Grid container spacing={2}>
                {tokens.map((token, index) => (
                  <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
                    <TokenSubCard {...token} account={account} isKycDone={isKycDone} isAuthDone={isAuthDone} />
                  </Grid>
                ))}
              </Grid>
            ) : (
              <Data />
            )}
          </>
        ) : (
          <DataNotAvailable />
        )}
      </Container>
    </Page>
  );
};

export default SubscriptionHome;
