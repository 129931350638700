import {
  Container,
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Card,
  CardContent,
  TableBody,
  Button,
  Typography,
  Chip
} from '@mui/material';
import Breadcrumbs from 'components/Breadcrumbs';
import tokenTerms from '../../mocks/TokenTerms.json';
import ViewAllotmentTable from 'components/viewAllotment/ViewAllotmentTable';

import { useBusinessEntityState, useWeb3, useAuthStateShared } from 'state';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import React, { useState } from 'react';
import { onlyIssuer } from 'helpers/rbac';
import ReadOnlyNotifier from 'components/ReadOnlyNotifier';
import ViewRedemptionsTables from 'components/viewAllotment/ViewRedemptionsTables';
import TokenTable from 'components/issuerDashboard/TokenTable';
import TokenSummaryModal from 'components/TokenSummaryModal';

const ViewAllotment = () => {
  const {
    currentToken: token,
    redeemAllotmentsList,
    fetchTokenSummaryData
  } = useBusinessEntityState();
  const { batchRedeem } = useWeb3();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { role } = useAuthStateShared();
  const [loadingState, setLoadingState] = useState(false);

  const [opentokenSummaryModal, setOpenTokenSummaryModal] = useState(false);
  const handleOpenTokenSummaryModal = () => setOpenTokenSummaryModal(true);
  const handleCloseTokenSummaryModal = () => setOpenTokenSummaryModal(false);
  return (
    <Container>
      <Breadcrumbs aria-label="breadcrumb" pageHead="Allotments & Redemptions" />

      {/* Token Table */}
      <TokenTable currToken={token} />
      {!onlyIssuer(role) && <ReadOnlyNotifier userRole={role} />}

      {/* View Allotment  Table */}
      <Box sx={{ mt: 4 }}>
        <Box
          sx={{
            mt: 2,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Typography
            variant="body2"
            color="initial"
            sx={{
              mt: 4,
              mb: 2,
              fontSize: '1.125rem',
              fontWeight: 'bold'
            }}
          >
            Allotments
          </Typography>
          <Button
            variant="contained"
            onClick={async () => {
              await fetchTokenSummaryData();
              handleOpenTokenSummaryModal();
            }}
          >
            View Token Holder Ship
          </Button>
        </Box>
        <Card>
          <CardContent>
            <ViewAllotmentTable />
          </CardContent>
        </Card>
        {/* <Typography
          sx={{
            mt: 4,
            fontSize: '1.125rem',
            fontWeight: 'bold'
          }}
        >
          View Past Redemptions
        </Typography>
        <Card
          sx={{
            mt: 4
          }}
        >
          <CardContent>
            <ViewAllotmentTable />
          </CardContent>
        </Card> */}
      </Box>

      {/* Redemptions Table */}
      <Box sx={{ mt: 2 }}>
        <Typography
          variant="body2"
          color="initial"
          sx={{
            mt: 4,
            mb: 2,
            fontSize: '1.125rem',
            fontWeight: 'bold'
          }}
        >
          Redemptions
        </Typography>
        <Card>
          <CardContent>
            <ViewRedemptionsTables />
          </CardContent>
        </Card>
        <Card
          sx={{ my: 2, p: 2, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}
        >
          <Button
            variant="contained"
            sx={{
              mx: 2,
              px: 3,
              fontWeight: 400,
              width: '7.75rem'
            }}
            color="secondary"
            onClick={() => {
              navigate('/home/token-dashboard');
            }}
          >
            Close
          </Button>
        </Card>
      </Box>
      <TokenSummaryModal open={opentokenSummaryModal} handleClose={handleCloseTokenSummaryModal} />
    </Container>
  );
};

export default ViewAllotment;
