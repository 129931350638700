import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToBottom = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: 'smooth' });
  }, [pathname]);

  return null;
};

export default ScrollToBottom;
