import {
  Container,
  Card,
  Grid,
  CardContent,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  TextField,
  FormLabel,
  Checkbox,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Select,
  FormControl,
  FormHelperText,
  Box,
  Alert,
  Button,
  IconButton,
  Divider,
  InputAdornment
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DesktopDatePicker, LoadingButton, LocalizationProvider } from '@mui/lab';
import Page from 'components/Page';
import React, { useState, useEffect, useRef, useMemo } from 'react';
import Breadcrumbs from 'components/Breadcrumbs';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { useCoreTableState } from 'state/useCoreTableState';
import {
  useAppState,
  useAuthStateShared,
  useBusinessEntityState,
  useHttpApi,
  useWeb3
} from 'state';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router';
import { format, addDays, differenceInMonths, addWeeks, addMonths, addYears } from 'date-fns';
import DateAdapter from '@mui/lab/AdapterDateFns';
import FocusError from 'components/FocusError';
import DownloadIcon from '@mui/icons-material/Download';
import { ceil, floor } from 'lodash';
import { ethToXdcAddress, xdcToEthAddress } from 'helpers/web3';
import axios from 'axios';
import AddressFieldTools from 'components/AddressFieldTools';
import UpdateTokenTerms from 'components/CreateToken/UpdateTokenTerms';
import AccordionLayout from 'helpers/AccordionLayout';
import { CURRENCY_URL, CURRENCY_API_KEY } from 'config';
import { parseTokenValue, roundToNearest100 } from 'helpers/numbers';

const EditToken = () => {
  const {
    tokenById,
    currentToken,
    fetchOriginator,
    fetAllTokenValuesByIssueType
    // fetchCustodian
  } = useBusinessEntityState();
  const [status, setStatus] = useState(false);
  const { editToken, getTokenDetailsById, getLiquidityPoolsByIssuerId } = useHttpApi();
  const { getBalanceOf, chainId } = useWeb3();
  const [liquidityPools, setLiquidityPools] = useState([]);
  const [readPaymentFrequency, setReadPaymentFrequency] = useState(true);
  const [readInterestType, setReadInterestType] = useState(true);
  const [readAccrualFrequency, setAccrualFrequency] = useState(true);
  const [ifProxyToken, setIfProxyToken] = useState(false);

  useEffect(() => {
    _fetchLiquidityPool();
  }, []);
  const _fetchLiquidityPool = async () => {
    const liquidityPools = await getLiquidityPoolsByIssuerId(entity?.issuer_id);
    setLiquidityPools(liquidityPools);
    console.log('pools', liquidityPools);
  };

  const _setSettlementaddressAsUser = () => {
    if (user?.parent_xinfin_account) {
      formik.setFieldValue(
        'xinfin_setl_account',
        xdcToEthAddress(user?.parent_xinfin_account?.xinfin_account_address)
      );
    } else {
      formik.setFieldValue('xinfin_setl_account', null);
    }
  };

  useEffect(() => {
    if (currentToken.token_status_id.token_status !== 'NEW') {
      setStatus(true);
    }
  }, [tokenById, currentToken]);

  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const tmr = new Date() + 1;

  const issueDatehandleChange = (newDate) => {
    try {
      formik.setFieldValue('token_create_date', format(newDate, 'yyyy-MM-dd'));
    } catch (err) {
      console.log(err);
    }
  };

  const finalMaturityDatehandleChange = (newDate) => {
    try {
      formik.setFieldValue('final_maturity_date', format(newDate, 'yyyy-MM-dd'));
      formik.setFieldValue('interest_accrual_start_date', format(newDate, 'yyyy-MM-dd'));
    } catch (err) {
      console.log(err);
    }
  };

  console.log('tokenById', tokenById);

  const {
    currencies,
    issueType,
    issueCategories,
    issueSubCategories,
    paymentBasis,
    paymentFreq,
    interestType,
    interestCalcMethod,
    accrualFreq,
    paymentTokens,
    collateralProvider,
    paymentTokenCurrencies,
    defaultDataAsPerIssueType,
    paymentLegs
  } = useCoreTableState();
  const {
    originators,
    onChainInsurers: insurers,
    onChainLiquidityPools: pools,
    setCurrentTokenById,
    setToken,
    fetchTokens,
    fetchLoggedInIssuer,
    loggedInIssuer,
    issuerInvestorMapByIssuerId,
    fetchIssuerInsurerMappingByIssuerId
  } = useBusinessEntityState();

  const { throwErrorMessage } = useAppState();
  const { user, entity } = useAuthStateShared();
  const [error, setError] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [tokenSubCategory, setTokenSubCategory] = useState([]);
  const [tokenTypes, setTokenTypes] = useState([]);
  const [isCollateralized, setIsCollateralized] = useState(false);
  const [checkCollateral, setCheckCollateral] = useState(false);
  const [XDCValue, setXDCValue] = useState('');
  // const [collateralProvider, setCollateralProvider] = useState('PRIMARY_ISSUER')

  const CreateTokenSchema = Yup.object().shape({
    token_name: Yup.string()
      .required('Token Name is required')
      .max(50, 'Token Name cannot be more than 50 characters'),
    token_symbol: Yup.string()
      .required('Token Symbol is required')
      .max(11, 'Token Symbol cannot be more than 11 characters'),
    issuer_id: Yup.string().required('Issuer is required'),
    token_currency: Yup.string().required('Currency is required'),
    total_supply: Yup.number()
      .required('Token Supply is required')
      .min(1, 'Token Supply must be positive number')
      .max(100000000000, 'Token Supply must be less'),
    token_value: Yup.number()
      .required('Token Value is required')
      .min(1, 'Token Value must be positive number')
      .max(1000000000, 'Token Value must be less'),
    issue_size: Yup.number()
      .positive('Issue Size must be positive number')
      .required('Issue Size is required'),
    issue_type_id: Yup.string().required('Issue Type is required'),
    issue_category_id: Yup.string().required('Issue Category is required'),
    issue_sub_category_id: Yup.string().required('Issue Sub Category is required'),

    // Primary Economic Terms

    minimum_subscription: Yup.number()
      .required('Minimum Subscription is required')
      .min(1, 'Minimum Subscription cannot be less than 1'),
    token_issue_price: Yup.number().required('Token Issue Price is required'),
    token_redemption_price: Yup.number()
      .positive('Token redemption price must be positive number')
      .required('Token Redemption Price is required'),
    token_create_date: Yup.date()
      .required('Issue Start Date is required')
      .min(today, 'Issue Start Date cannont be past date'),
    final_maturity_date: Yup.date()
      .required('Token Closure Date is required')
      .min(tmr, 'Token Closure Date cannot be past date or today'),
    lockin_till: Yup.date()
      .nullable()
      .default(null)

      .min(today, 'Lock in Date cannot be past date'),
    application_processing_time: Yup.string().required('Settlement Date is required'),

    payment_frequency_id: Yup.string().required('Payment Frequency is required'),

    payment_basis_id: Yup.string().required('Payment Basis is required'),
    interest_type_id: Yup.string().required('Interest Type is required'),
    token_type: Yup.string().required('Token Type is required'),
    liquidity_pool: Yup.string()
      .when('token_type', {
        is: (item) => item === 'POOL_LENDING' || item === 'PROXY_TOKEN',
        then: Yup.string('Liquidity Pool is required').required('Liquidity Pool is required')
      })
      .nullable(),
    interest_calc_method_id: Yup.string().required('Interest Calc. Method is required'),
    tenure_months: Yup.number()
      .required('Tenure is required')
      .min(1, 'Tenure should be atleast 1 year')
      .integer('Tenure must be an integer value'),
    accural_frequncy_id: Yup.string().required('Accrual Frequency is required'),
    token_nav: Yup.number().required('Token NAV is required'),
    contract_doc_hash1: Yup.mixed().test(
      'type',
      'Only .pdf .doc .docx formats are accepted',
      (value) => {
        let allGood = false;

        if (value) {
          allGood = value && value.substring(value.lastIndexOf('.')) === '.pdf';
        } else {
          allGood = true;
        }
        return allGood;
      }
    ),
    use_of_proceeds: Yup.string().required('Use of Proceeds is required'),
    reference_assets: Yup.string().required('Reference Assets is required'),
    token_issue_interest_percent: Yup.number().when('issue_type_id.issue_calc_type', {
      is: (item) => item !== 'NAV',
      then: Yup.number()
        .required('Interest Percent is required')
        .min(0, 'Interest Percent cannot be less than 0')
        .max(100, 'Interest cannot be more than 100%')
    }),
    token_issue_desc_percent: Yup.number().when('issue_type_id.issue_calc_type', {
      is: 'DISCOUNT',
      then: Yup.number()
        .required('Discount % is required')
        .min(0, 'Discount % cannot be less than 0')
        .max(100, 'Discount % cannot be more than 100%')
    })
  });
  const contractDoc = useRef(null);
  // Formik
  console.log('formikk', tokenById);
  const formik = useFormik({
    initialValues: { ...tokenById, ...currentToken?.bond },
    validate: (values, props) => {
      // delete values.lockin_till;
      const finalTokenCreate = addDays(new Date(values.token_create_date), 1);
      const errors = {};
      const dd = String(finalTokenCreate.getDate()).padStart(2, '0');
      const mm = String(finalTokenCreate.getMonth() + 1).padStart(2, '0');
      const yyyy = finalTokenCreate.getFullYear();
      const finalTokenCreateFormatted = `${yyyy}-${mm}-${dd}`;
      if (finalTokenCreateFormatted > values.final_maturity_date) {
        errors.final_maturity_date =
          'Issue Closure Date should be greater than Token Issue Start Date';
      }
      if (values.contract_doc_hash1) {
        if (contractDoc && contractDoc.current.files[0].size > 20971520) {
          errors.contract_doc_hash1 = 'File size greater than 20MB';
        }
      }
      return errors;
    },
    validationSchema: CreateTokenSchema,
    onSubmit: async (data, { resetForm }) => {
      console.log('EditToken', data);
      try {
        // delete data.interest_accrual_start_date;
        delete data.minted_balance;
        delete data.deployment_address;
        data.token_currency_rate = Number(currencyRate).toFixed(6);

        if (data.token_type === 'DIRECT_LENDING') {
          data.liquidity_pool = '';
        }

        if (issueTypes.issue_calc_type === 'INTEREST') {
          data.token_issue_desc_percent = 0;
        }

        if (data.payment_leg_id.payment_leg_name === 'Cash') {
          data.payment_token_id = '';
          data.swap_ratio_issue = 0.0;
          data.swap_ratio_redeem = 0.0;
          data.is_collateralized = false;
          // delete data.collateral_token;
          data.collateral_per_token = 0;
          data.collateral_provider = '';
          data.collateral_provider_entity = '';
          data.insurer_contri = 0;
          data.issuer_contri = 0;
          // delete data.token_currency_rate;
        } else if (
          data.payment_leg_id.payment_leg_name === 'Stable Coin' &&
          !data.is_collateralized
        ) {
          data.allow_early_redemption = false;
          // delete data.collateral_token;
          data.collateral_per_token = 0;
          data.collateral_provider = '';
          data.collateral_provider_entity = '';
          data.insurer_contri = 0;
          data.issuer_contri = 0;
        }
        if (data.payment_leg_id.payment_leg_name === 'Stable Coin') {
          data.allow_early_redemption = false;
        }

        delete data.next_payment_due_date;
        if (data.lockin_till === '' || data.lockin_till === null) {
          delete data.lockin_till;
        }
        if (!data.originator_id) {
          data.originator_id = '';
        }
        if (!isCollateralized) {
          data.collateral_per_token = 0;
          data.insurer_contri = 0;
          data.issuer_contri = 0;
          data.collateral_provider = '';
          data.collateral_provider_entity = '';
        }

        data.allow_early_redemption = false;

        if (collateralProviders.collateral_provider_name === 'Issuer') {
          data.collateral_provider_entity = '';
          data.insurer_contri = 0;
          data.issuer_contri = 0;
        }

        const contractDocFile = contractDoc.current.files[0];
        if (contractDocFile) {
          if (contractDocFile.size > 20971520) {
            throw new Error('Upload Contract File less than 20MB', {
              variant: 'error'
            });
          }
          data.contract_doc_hash = contractDocFile;
          formik.setFieldValue('contract_doc_hash1', '');
        } else {
          delete data.contract_doc_hash;
        }

        // if (
        //   !(
        //     data.issue_type_id.issue_calc_type !== 'NAV' ||
        //     (data.issue_type_id.issue_calc_type === 'NAV' &&
        //       data.issue_type_id.issue_type_name === 'Pool Investment')
        //   )
        // ) {
        //   data.token_issue_interest_percent = 0;
        // }
        console.log(JSON.stringify(data, null, ' '));
        const _editToken = await editToken(tokenById.token_id, data);
        if (_editToken.token_id) {
          setCurrentTokenById(_editToken);
          const res = await getTokenDetailsById(_editToken.token_id);
          console.log(res);
          if (res.token_id) {
            setToken(res);
          }
          enqueueSnackbar('Token Updated Successfully', {
            variant: 'success'
          });
          resetForm();
          navigate('/home/token-dashboard');
          fetchTokens();
        }
      } catch (e) {
        // setError(e.message || 'Operation failed');
        throwErrorMessage(e);
      }
    }
  });
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;
  // get FileName
  const [fileName, setFileName] = useState('');
  useEffect(() => {
    const url = tokenById.contract_doc_hash;
    setFileName(url.substring(url.lastIndexOf('/') + 1));
    formik.setFieldValue('contract_doc_hash', '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenById.contract_doc_hash]);

  // new fields not added in Form
  const [payment, setPayment] = useState('');

  const [issueTypes, setIssueTypes] = useState({
    id: '',
    issue_calc_type: '',
    issue_sub_category_id: '',
    issue_type_desc: '',
    issue_type_name: ''
  });
  const [collateralProviders, setCollateralProviders] = useState({
    id: '',
    collateral_provider_name: '',
    collateral_provider_desc: ''
  });
  const [collateralAccount, setCollateralAccount] = useState([]);
  const [currencyRate, setCurrencyRate] = useState(1);
  const [selectedPool, setSelectedPool] = useState(null);
  const [tokenCurrency, setTokenCurrency] = useState([]);

  const [paymentLeg, setPaymentLeg] = useState({
    id: '',
    payment_leg_name: '',
    payment_leg_desc: ''
  });

  const XdcValueHandler = async () => {
    const _currency = paymentTokenCurrencies.filter(
      (item) => item.id === formik.values.token_currency
    );

    if (_currency[0].currency_code) {
      const IssueCurrency = _currency[0].currency_code.toLowerCase();

      const res = await axios.get(
        `https://api.coingecko.com/api/v3/simple/price?ids=xdce-crowd-sale&vs_currencies=${IssueCurrency}`
      );
      if (res) {
        console.log('Coin Gecko Res', Object.values(res.data)[0][IssueCurrency]);
        setXDCValue(Object.values(res.data)[0][IssueCurrency]);
        return Object.values(res.data)[0][IssueCurrency];
      }
    }
    return '';
  };

  const filterIssueTypes = () => {
    if (tokenById.issue_type_id) {
      const _issueType = issueType.filter((pay) => tokenById.issue_type_id === pay.id);
      setIssueTypes(_issueType[0]);
      return _issueType[0];
    }
    return '';
  };

  // Fetch and set collateral_provider

  const filterCollateralProvider = () => {
    if (tokenById.collateral_provider) {
      const _collateralProvider = collateralProvider.filter(
        (provider) => tokenById.collateral_provider === provider.id
      );
      setCollateralProviders(_collateralProvider[0]);
      return _collateralProvider[0];
    }
    return '';
  };

  // fetch  and set Xinfin address of insurer

  const filterCollateralAccount = () => {
    let _collateralAccount;
    if (tokenById.collateral_provider_entity && issuerInvestorMapByIssuerId.length > 0) {
      const _insurer = issuerInvestorMapByIssuerId.filter(
        (insurer) => tokenById.collateral_provider_entity === insurer.insurer_id
      );
      setCollateralAccount(_insurer[0].insurer_xinfin_address);
      _collateralAccount = _insurer[0].insurer_xinfin_address;
    } else {
      setCollateralAccount('');
      _collateralAccount = '';
    }
    return _collateralAccount;
  };

  const filterPaymentToken = () => {
    if (tokenById.payment_token_id) {
      const _paymentToken = paymentTokens.find(
        (token) => tokenById.payment_token_id === token.payment_token_id
      );
      setPayment(_paymentToken);
      return _paymentToken;
    }
    return '';
  };

  const filterLiquidityPool = () => {
    if (tokenById.liquidity_pool) {
      const _liquidityPool = pools.find(
        (pool) => tokenById.liquidity_pool === pool.liquidity_pool_id
      );
      setSelectedPool(_liquidityPool);
      return _liquidityPool;
    }
    return '';
  };

  const getPaymentTokenBalance = async (
    tokenAddr = payment?.contract_address,
    walletAddr = selectedPool?.liquidity_pool_xinfin_address
  ) => {
    const _balance = await getBalanceOf(tokenAddr, walletAddr);
    console.log('🚀 ~ file: EditToken.js ~ line 463 ~ EditToken ~ _balance', _balance);

    payment.balance = parseTokenValue(_balance, payment.decimals);
    setPayment(payment);
    return payment;
  };

  useEffect(() => {
    const _currency = paymentTokenCurrencies.filter(
      (item) => item.id === formik.values.token_currency
    );
    setTokenCurrency(_currency[0]);
  }, [formik.values.token_currency]);

  const handleCurrencyRate = (_payment = payment) => {
    if (formik.values.token_currency !== '' && _payment?.backed_currency !== undefined) {
      const _currency = paymentTokenCurrencies.filter(
        (item) => item.id === formik.values.token_currency
      );
      const _currencyRate = async () => {
        try {
          const res = await axios.get(
            `${CURRENCY_URL}api/v7/convert?q=${_currency[0].currency_code}_${payment.backed_currency}&compact=ultra&apiKey=${CURRENCY_API_KEY}`
          );
          setCurrencyRate(Object.values(res.data)[0]);
          return Object.values(res.data)[0];
        } catch (error) {
          setCurrencyRate(1); // set default value of 1 if there's an error
          return 1;
        }
      };
      return _currencyRate();
    }
    setCurrencyRate(1);
    return 1;
  };

  const handleIssueSize = () => {
    const _issueSize = formik.values.total_supply * formik.values.token_value;
    formik.setFieldValue('issue_size', _issueSize);
    return _issueSize;
  };

  const handleInterestDiscount = (_issueTypes = issueTypes) => {
    let _issuePrice;
    if (issueTypes.issue_calc_type === 'DISCOUNT') {
      _issuePrice = ceil(
        values.token_value - (values.token_value / 100) * values.token_issue_desc_percent
      );
      formik.setFieldValue('token_issue_price', _issuePrice);
      return _issuePrice;
    }
    if (issueTypes.issue_calc_type === 'INTEREST') {
      _issuePrice = values.token_value;
      formik.setFieldValue('token_issue_price', _issuePrice);
      return _issuePrice;
    }
    _issuePrice = values.token_value;
    formik.setFieldValue('token_issue_price', _issuePrice);
    return _issuePrice;
  };
  // useEffect(() => {
  //   handleInterestDiscount(issueTypes);
  // }, [issueTypes]);

  useEffect(() => {
    if (issueTypes.issue_calc_type === 'DISCOUNT') {
      const interestRate =
        (((values.token_redemption_price - values.token_issue_price) *
          (365 / values.tenure_months)) /
          values.token_issue_price) *
        100;
      formik.setFieldValue('token_issue_interest_percent', interestRate.toFixed(2));
    }
  }, [
    values.tenure_months,
    values.token_issue_desc_percent,
    values.token_redemption_price,
    values.token_issue_price,
    issueTypes
  ]);

  // Fetch and set payment Leg
  useEffect(() => {
    XdcValueHandler();
    // filterPaymentLeg();
    filterIssueTypes();
    filterCollateralProvider();

    filterPaymentToken();
    filterLiquidityPool();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [issuerInvestorMapByIssuerId]);

  useEffect(() => {
    if (payment !== '' && selectedPool !== null) {
      const res = getPaymentTokenBalance();
      console.log('🚀 ~ file: EditToken.js ~ line 524 ~ useEffect ~ getPaymentTokenBalance', res);
    }
  }, [payment, selectedPool]);

  useEffect(() => {
    handleCurrencyRate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payment]);

  // Fetch and set is_collateralized
  useEffect(() => {
    if (tokenById.is_collateralized === true) {
      setCheckCollateral(true); // set CheckBox true
      setIsCollateralized(true);
    }
  }, [tokenById.is_collateralized]);

  // Fetch Insurer Issuer Mapping from logged in Issuer
  useEffect(() => {
    if (entity && entity.issuer_id) {
      fetchIssuerInsurerMappingByIssuerId(entity.issuer_id);
      console.log({ issuerInvestorMapByIssuerId });
    }
  }, [entity]);

  // Render FilterCollateralAccount on its dependency change
  useEffect(() => {
    filterCollateralAccount();
  }, [issuerInvestorMapByIssuerId]);

  useEffect(() => {
    if (values.token_type !== 'PROXY_TOKEN') {
      setTokenSubCategory(issueSubCategories);
      setTokenTypes(issueType);
      setIfProxyToken(false);
    }
  }, [issueCategories, issueSubCategories, issueType, values.token_type]);

  useEffect(() => {
    if (values.token_type !== 'PROXY_TOKEN') {
      const _subCategory = issueSubCategories.filter(
        (item) => item.issue_category_id === values.issue_category_id
      );
      setTokenSubCategory(_subCategory);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.issue_category_id, values.token_type]);

  useEffect(() => {
    if (values.token_type !== 'PROXY_TOKEN') {
      const _type = issueType.filter(
        (item) => item.issue_sub_category_id === formik.values.issue_sub_category_id
      );
      setTokenTypes(_type);
      _setSettlementaddressAsUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.issue_sub_category_id, values.token_type]);

  useEffect(() => {
    if (values.token_type === 'PROXY_TOKEN') {
      formik.setFieldValue(
        'issue_category_id',
        defaultDataAsPerIssueType?.proxy_issue_category_data[0]?.id
      );

      formik.setFieldValue(
        'issue_sub_category_id',
        defaultDataAsPerIssueType?.proxy_issue_sub_category_data[0]?.id
      );
      setTokenSubCategory(defaultDataAsPerIssueType?.proxy_issue_sub_category_data);
      formik.setFieldValue(
        'issue_type_id',
        defaultDataAsPerIssueType?.proxy_issue_type_data[0]?.id
      );
      setTokenTypes(defaultDataAsPerIssueType?.proxy_issue_type_data);
      setIssueTypes(defaultDataAsPerIssueType?.proxy_issue_type_data);
      handleInterestDiscount(defaultDataAsPerIssueType?.proxy_issue_type_data.issue_calc_type);
      handleLiquidityPoolChange();
      console.log('check', issueTypes);
      // setIfProxyToken(true);
    }
  }, [values.token_type, values.liquidity_pool]);

  useEffect(() => {
    if (values.issue_type_id && issueTypes) {
      const _data = defaultDataAsPerIssueType?.issue_type_data?.filter(
        (value) => value.issue_calc_type === issueTypes.issue_calc_type
      );

      if (_data) {
        if (issueTypes.id !== tokenById.issue_type_id) {
          formik.setFieldValue('payment_basis_id', _data[0]?.payment_basis?.id);
          formik.setFieldValue('accural_frequncy_id', _data[0]?.accrual_frequency?.id);
          formik.setFieldValue('payment_frequency_id', _data[0]?.payment_frequency?.id);
          formik.setFieldValue('interest_type_id', _data[0]?.interest_type?.id);
          formik.setFieldValue('interest_calc_method_id', _data[0]?.interest_calc_method?.id);
        } else {
          [
            'payment_basis_id',
            'accural_frequncy_id',
            'payment_frequency_id',
            'interest_type_id',
            'interest_calc_method_id'
          ].forEach((field) => {
            formik.setFieldValue(field, tokenById[field]);
          });
        }
        setAccrualFrequency(_data[0]?.accrual_frequency?.accrual_frequency_name_freeze);
        setReadInterestType(_data[0]?.interest_type?.interest_type_name_freeze);
        setReadPaymentFrequency(_data[0]?.payment_frequency?.payment_frequency_name_freeze);
      }
    }
  }, [issueTypes, values.issue_type_id]);

  useEffect(() => {
    if (issueTypes.issue_type_name === 'Pool Investment') {
      if (formik.values.token_issue_price !== '' && formik.values.token_issue_price !== 0) {
        const tokenIssueInterest =
          formik.values.token_issue_interest_percent === ''
            ? 0
            : formik.values.token_issue_interest_percent;
        // formik.setFieldValue(
        //   'token_redemption_price',
        //   (
        //     formik.values.token_issue_price +
        //     formik.values.token_issue_price * (tokenIssueInterest / 100)
        //   ).toFixed(0)
        // );
      }
    }
    handleCurrencyChange();
  }, [
    issueTypes,
    formik.values.token_issue_interest_percent,
    formik.values.token_redemption_price
  ]);

  useEffect(() => {
    if (
      !values?.token_issue_price ||
      values?.token_issue_price === '' ||
      values?.token_issue_price === 0
    )
      return;

    formik.setFieldValue('swap_ratio_issue', (values?.token_issue_price * currencyRate).toFixed(2));
    formik.setFieldValue(
      'swap_ratio_redeem',
      (values?.token_redemption_price * currencyRate).toFixed(2)
    );
  }, [values.token_issue_price, values.token_redemption_price, currencyRate]);

  const handleTokenValueChange = (e) => {
    // formik.setFieldValue('token_redemption_price', values.token_value);
    formik.setFieldValue('token_nav', formik.values.token_value);
    return values.token_value;
  };

  const CalculateTenure = () => {
    if (formik.values.token_create_date && formik.values.final_maturity_date) {
      const tenure = Number(
        differenceInMonths(
          new Date(formik.values.final_maturity_date),
          new Date(formik.values.token_create_date)
        )
      );
      formik.setFieldValue('tenure_months', tenure);
    }
  };

  const handleCollateralPerToken = (_XDCValue = XDCValue) => {
    const _collateralPerToken = values.token_value / _XDCValue;
    const _collateralPerTokenWithHairCut = Math.round(
      _collateralPerToken + _collateralPerToken * 0.5
    );
    console.log(_collateralPerToken, _collateralPerTokenWithHairCut);
    formik.setFieldValue('collateral_per_token', roundToNearest100(_collateralPerTokenWithHairCut));
    // formik.setFieldValue('collateral_per_token', ceil(values.token_value / _XDCValue));
  };

  const handleSwapPriceRatio = ({
    currRate = currencyRate,
    _XDCValue = XDCValue,
    issuePrice = formik.values.token_issue_price,
    redeemPrice = formik.values.token_redemption_price
  }) => {
    if (issuePrice || issuePrice !== 0) {
      formik.setFieldValue('swap_ratio_issue', (issuePrice * currRate).toFixed(2));
      formik.setFieldValue('swap_ratio_redeem', (redeemPrice * currRate).toFixed(2));
    }

    handleCollateralPerToken(_XDCValue);
  };

  // useEffect(() => {
  //   handleSwapPriceRatio();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // Set up issuer and insurer contribution

  useEffect(() => {
    if (values.collateral_provider_entity && issuerInvestorMapByIssuerId.length > 0) {
      const _insurer = issuerInvestorMapByIssuerId.filter(
        (insurer) => values.collateral_provider_entity === insurer.insurer_id
      );
      const contribution = _insurer[0];
      console.log('chehck collateral value', values.collateral_per_token);
      if (_insurer.length > 0) {
        const _issuerCollateral = ceil(
          (contribution.issuer_contri * values.collateral_per_token) / 100
        );
        const _insurerCollateral = Number(values.collateral_per_token) - Number(_issuerCollateral);
        formik.setFieldValue('issuer_contri', _issuerCollateral);

        formik.setFieldValue('insurer_contri', _insurerCollateral);
      }
      // formik.setFieldValue(
      //   'issuer_contri',
      //   ceil((contribution.issuer_contri * values.collateral_per_token) / 100)
      // );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.collateral_per_token, formik.values.collateral_provider_entity]);

  useEffect(() => {
    if (paymentLegs.length > 0) {
      console.log('🚀 ~ useEffect ~ tokenById?.payment_token_id:', tokenById?.payment_token_id);
      if (tokenById?.payment_token_id !== null && tokenById?.payment_token_id !== '') {
        const _paymentLeg = paymentLegs.find((leg) => leg.payment_leg_name === 'Stable Coin');
        console.log('🚀 ~ useEffect ~ _paymentLeg:', _paymentLeg);
        formik.setFieldValue('payment_leg_id', _paymentLeg);
        setPaymentLeg(_paymentLeg);
      } else {
        const _paymentLeg = paymentLegs.find((leg) => leg.payment_leg_name === 'Cash');
        formik.setFieldValue('payment_leg_id', _paymentLeg);
        setPaymentLeg(_paymentLeg);
      }
    }
  }, [paymentLegs, tokenById]);

  const handleCurrencyChange = async () => {
    console.log('TESTING...................');
    const _currencyRate = await handleCurrencyRate();
    const _XDCValue = await XdcValueHandler();
    handleSwapPriceRatio({ currRate: _currencyRate, _XDCValue });
  };

  const handleLiquidityPoolChange = () => {
    if (values.liquidity_pool !== '' && values.liquidity_pool !== null) {
      const res = liquidityPools.find((pool) => pool.liquidity_pool_id === values.liquidity_pool);
      setSelectedPool(res);
      formik.setFieldValue('xinfin_setl_account', res?.liquidity_pool_settlement_address);
      return res;
    }
    return null;
  };

  const handlePaymentTokenChange = async (event) => {
    const _paymentToken = paymentTokens.find(
      (token) => event.target.value === token.payment_token_id
    );
    if (values.token_type === 'POOL_LENDING' && selectedPool !== null) {
      const balance = await getBalanceOf(
        _paymentToken.contract_address,
        selectedPool?.liquidity_pool_xinfin_address
      );
      console.log('Balance', balance);
      _paymentToken.balance = parseTokenValue(balance, _paymentToken.decimals);
    }

    setPayment(_paymentToken);
    formik.setFieldValue('payment_token_id', event.target.value);
    const currRate = await handleCurrencyRate(_paymentToken);
    handleSwapPriceRatio({ currRate });
  };

  const handleTokenRedemptionValue = () => {
    if (values.token_value && values.token_issue_interest_percent && values.tenure_months) {
      return formik.setFieldValue(
        'token_redemption_price',
        Math.round(
          values.token_value +
            (values.token_value * values.token_issue_interest_percent * values.tenure_months) / 100
        )
      );
    } else if (values.token_value) {
      return formik.setFieldValue('token_redemption_price', Math.round(values.token_value));
    }
  };

  const longTermBondCategoryId = issueCategories.find(category => category.issue_category_name === 'Long Term Bond')?.id;

  return (
    <>
      <Page title="Edit Token">
        <Container sx={{ mt: 3 }}>
          <Breadcrumbs aria-label="breadcrumb" pageHead="Edit Token Term Sheet" />
          <Card sx={{ mt: 2 }}>
            <CardContent sx={{ m: 4 }}>
              {error && (
                <Box mb={4}>
                  <Alert severity="error">{error}</Alert>
                </Box>
              )}
              <FormikProvider value={formik}>
                <Form autoComplete="off" onSubmit={handleSubmit}>
                  <AccordionLayout
                    defaultExpanded
                    title="Token Header"
                    content={
                      <Grid container spacing={3} sx={{ width: '100%' }}>
                        <Grid item lg={6} md={6} sm={12} xs={12} sx={{ pr: 1 }}>
                          <FormLabel>Issuer Name</FormLabel>
                          <FormControl size="small" variant="outlined" fullWidth sx={{ mt: 1.5 }}>
                            <Select
                              inputProps={{
                                readOnly: status
                              }}
                              {...getFieldProps('issuer_id')}
                              error={Boolean(touched.issuer_id && errors.issuer_id)}
                              helperText={touched.issuer_id && errors.issuer_id}
                            >
                              {/* {entity.issuer_id && ( */}
                              <MenuItem
                                selected
                                className="Mui-selected"
                                key={entity?.issuer_id}
                                value={entity?.issuer_id}
                              >
                                {entity?.issuer_name}
                              </MenuItem>
                              {/* )} */}
                            </Select>

                            <FormHelperText sx={{ color: '#d32f2f' }}>
                              {touched.issuer_id && errors.issuer_id}
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12} sx={{ pl: 0.5 }}>
                          <FormLabel>Issue Currency</FormLabel>
                          <FormControl size="small" variant="outlined" fullWidth sx={{ mt: 1.5 }}>
                            <Select
                              // placeholder="Select Currency"
                              inputProps={{ 'aria-label': 'Without label', readOnly: status }}
                              {...getFieldProps('token_currency')}
                              error={Boolean(touched.token_currency && errors.token_currency)}
                              helperText={touched.token_currency && errors.token_currency}
                              onChange={(event) => {
                                formik.setFieldValue('token_currency', event.target.value);
                                handleCurrencyChange();
                              }}
                            >
                              {paymentTokenCurrencies &&
                                paymentTokenCurrencies.map((currency) => {
                                  return (
                                    <MenuItem key={currency.id} value={currency.id}>
                                      {currency.currency_code} | {currency.currency_name}
                                    </MenuItem>
                                  );
                                })}
                            </Select>
                            <FormHelperText sx={{ color: '#d32f2f' }}>
                              {touched.token_currency && errors.token_currency}
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Token Name</FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            fullWidth
                            inputProps={{
                              readOnly: status,
                              maxLength: 50
                            }}
                            size="small"
                            autoComplete="off"
                            type="text"
                            // placeholder="Token Name"
                            {...getFieldProps('token_name')}
                            error={Boolean(touched.token_name && errors.token_name)}
                            helperText={touched.token_name && errors.token_name}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Token Symbol</FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            fullWidth
                            size="small"
                            inputProps={{
                              readOnly: status,
                              maxLength: 11
                            }}
                            autoComplete="off"
                            type="text"
                            // placeholder="XDCYP"
                            {...getFieldProps('token_symbol')}
                            error={Boolean(touched.token_symbol && errors.token_symbol)}
                            helperText={touched.token_symbol && errors.token_symbol}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Token Supply</FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            fullWidth
                            size="small"
                            autoComplete="off"
                            type="number"
                            inputProps={{
                              readOnly: status
                            }}
                            // placeholder="100000000"
                            {...getFieldProps('total_supply')}
                            onBlur={() => {
                              handleIssueSize();
                            }}
                            error={Boolean(touched.total_supply && errors.total_supply)}
                            helperText={touched.total_supply && errors.total_supply}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Token Value {formik.values.issuer_currency}</FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            inputProps={{
                              readOnly: status
                            }}
                            // placeholder="1000"
                            fullWidth
                            size="small"
                            autoComplete="off"
                            type="number"
                            {...getFieldProps('token_value')}
                            id="token_value"
                            onBlur={() => {
                              // console.log('onBlur', e);
                              handleIssueSize();
                              const _issuePrice = handleInterestDiscount();
                              const _redeemPrice = handleTokenValueChange();
                              handleSwapPriceRatio({
                                issuePrice: _issuePrice,
                                redeemPrice: _redeemPrice
                              });
                              handleTokenRedemptionValue();
                            }}
                            error={Boolean(touched.token_value && errors.token_value)}
                            helperText={touched.token_value && errors.token_value}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Issue Size ({tokenCurrency.currency_code})</FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            fullWidth
                            type="number"
                            inputProps={{
                              readOnly: true
                            }}
                            size="small"
                            // placeholder="10000000"
                            {...getFieldProps('issue_size')}
                            error={Boolean(touched.issue_size && errors.issue_size)}
                            helperText={touched.issue_size && errors.issue_size}
                          />
                        </Grid>
                        {/* <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Token Type ({tokenCurrency.currency_code})</FormLabel>
                          <FormControl size="small" variant="outlined" fullWidth sx={{ mt: 1.5 }}>
                            <Select
                              displayEmpty
                              inputProps={{ 'aria-label': 'Without label' }}
                              {...getFieldProps('token_type')}
                              id="token_type"
                              error={Boolean(touched.token_type && errors.token_type)}
                              helperText={touched.token_type && errors.token_type}
                              onBlur={async (e) => {
                                if (
                                  e.target.value === 'POOL_LENDING' ||
                                  e.target.value === 'PROXY_TOKEN'
                                ) {
                                  if (pools.length === 0) {
                                    _fetchLiquidityPool();
                                  }
                                } else {
                                  _setSettlementaddressAsUser();
                                }
                              }}
                            >
                              <MenuItem key="DIRECT_LENDING" value="DIRECT_LENDING">
                                P2P Finance
                              </MenuItem>
                              <MenuItem key="POOL_LENDING" value="POOL_LENDING">
                                Pool Finance
                              </MenuItem>
                              <MenuItem key="PROXY_TOKEN" value="PROXY_TOKEN">
                                Pool Token
                              </MenuItem>
                            </Select>

                            <FormHelperText sx={{ color: '#d32f2f' }}>
                              {touched.token_type && errors.token_type}
                            </FormHelperText>
                          </FormControl>
                        </Grid> */}
                        {(values.token_type === 'PROXY_TOKEN' ||
                          values.token_type === 'POOL_TOKEN') &&
                          tokenById.liquidity_pool && (
                            <Grid item lg={6} md={6} sm={12} xs={12}>
                              <FormLabel>Current Liquidity Pool</FormLabel>
                              <FormControl
                                size="small"
                                variant="outlined"
                                fullWidth
                                sx={{ mt: 1.5 }}
                              >
                                <Select
                                  displayEmpty
                                  inputProps={{ readOnly: true }}
                                  value={tokenById.liquidity_pool}
                                >
                                  {liquidityPools.map((pool) => {
                                    return (
                                      <MenuItem
                                        key={pool.liquidity_pool_id}
                                        value={pool.liquidity_pool_id}
                                      >
                                        {pool.liquidity_pool_name}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                            </Grid>
                          )}

                        {!status && values.token_type === 'PROXY_TOKEN' && (
                          <Grid item lg={6} md={6} sm={12} xs={12}>
                            <FormLabel>Update Liquidity Pool</FormLabel>
                            <FormControl size="small" variant="outlined" fullWidth sx={{ mt: 1.5 }}>
                              <Select
                                displayEmpty
                                inputProps={{ 'aria-label': 'Without label' }}
                                {...getFieldProps('liquidity_pool')}
                                onChange={() => {
                                  const currPool = handleLiquidityPoolChange();
                                  console.log('currPool: ', currPool, 'payment: ', payment);
                                  if (payment !== '' && currPool !== null) {
                                    getPaymentTokenBalance(
                                      payment?.contract_address,
                                      currPool?.liquidity_pool_xinfin_address
                                    );
                                  }
                                }}
                                id="liquidity_pool"
                                error={Boolean(touched.liquidity_pool && errors.liquidity_pool)}
                                helperText={touched.liquidity_pool && errors.liquidity_pool}
                              >
                                {liquidityPools.map((pool) => {
                                  return (
                                    !pool?.has_proxy_token && (
                                      <MenuItem
                                        key={pool.liquidity_pool_id}
                                        value={pool.liquidity_pool_id}
                                      >
                                        {pool.liquidity_pool_name}
                                      </MenuItem>
                                    )
                                  );
                                })}
                              </Select>

                              <FormHelperText sx={{ color: '#d32f2f' }}>
                                {touched.liquidity_pool && errors.liquidity_pool}
                              </FormHelperText>
                              {!status &&
                                values.token_type === 'PROXY_TOKEN' &&
                                (liquidityPools?.length === 0 ||
                                  liquidityPools?.filter((pool) => !pool.has_proxy_token).length ===
                                    0) && (
                                  <FormHelperText sx={{ color: '#d32f2f' }}>
                                    No Liquidity Pool Found to update!
                                  </FormHelperText>
                                )}
                            </FormControl>
                          </Grid>
                        )}

                        {values.token_type === 'POOL_LENDING' && (
                          <Grid item lg={6} md={6} sm={12} xs={12}>
                            <FormLabel> Liquidity Pool</FormLabel>
                            <FormControl size="small" variant="outlined" fullWidth sx={{ mt: 1.5 }}>
                              <Select
                                displayEmpty
                                inputProps={{ readOnly: status }}
                                {...getFieldProps('liquidity_pool')}
                                onChange={() => {
                                  const currPool = handleLiquidityPoolChange();
                                  console.log('currPool: ', currPool, 'payment: ', payment);
                                  if (payment !== '' && currPool !== null) {
                                    getPaymentTokenBalance(
                                      payment.contract_address,
                                      currPool.liquidity_pool_xinfin_address
                                    );
                                  }
                                }}
                                id="liquidity_pool"
                                error={Boolean(touched.liquidity_pool && errors.liquidity_pool)}
                                helperText={touched.liquidity_pool && errors.liquidity_pool}
                              >
                                {liquidityPools.map((pool) => {
                                  return (
                                    <MenuItem
                                      key={pool.liquidity_pool_id}
                                      value={pool.liquidity_pool_id}
                                    >
                                      {pool.liquidity_pool_name}
                                    </MenuItem>
                                  );
                                })}
                              </Select>

                              <FormHelperText sx={{ color: '#d32f2f' }}>
                                {touched.liquidity_pool && errors.liquidity_pool}
                              </FormHelperText>
                              {!status &&
                                values.token_type === 'POOL_LENDING' &&
                                liquidityPools.length === 0 && (
                                  <FormHelperText sx={{ color: '#d32f2f' }}>
                                    No Liquidity Pool Found to update!
                                  </FormHelperText>
                                )}
                            </FormControl>
                          </Grid>
                        )}

                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Token Category</FormLabel>
                          <FormControl size="small" variant="outlined" fullWidth sx={{ mt: 1.5 }}>
                            <Select
                              displayEmpty
                              inputProps={{
                                'aria-label': 'Without label',
                                readOnly: status || ifProxyToken
                              }}
                              {...getFieldProps('issue_category_id')}
                              error={Boolean(touched.issue_category_id && errors.issue_category_id)}
                              helperText={touched.issue_category_id && errors.issue_category_id}
                            >
                              {issueCategories?.map((category) => {
                                return (
                                  <MenuItem key={category.id} value={category.id}>
                                    {category.issue_category_name === 'Long Term Bond'
                                      ? 'SAFI'
                                      : category.issue_category_name}
                                  </MenuItem>
                                );
                              })}
                            </Select>

                            <FormHelperText sx={{ color: '#d32f2f' }}>
                              {touched.issue_category_id && errors.issue_category_id}
                            </FormHelperText>
                          </FormControl>
                        </Grid>

                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Token Sub Category</FormLabel>
                          <FormControl size="small" variant="outlined" fullWidth sx={{ mt: 1.5 }}>
                            <Select
                              // placeholder="Select Currency"
                              displayEmpty
                              inputProps={{
                                'aria-label': 'Without label',
                                readOnly: status || ifProxyToken
                              }}
                              {...getFieldProps('issue_sub_category_id')}
                              error={Boolean(
                                touched.issue_sub_category_id && errors.issue_sub_category_id
                              )}
                              helperText={
                                touched.issue_sub_category_id && errors.issue_sub_category_id
                              }
                            >
                              {tokenSubCategory &&
                                tokenSubCategory.map((issueSubCategory, index) => {
                                  return (
                                    <MenuItem key={issueSubCategory.id} value={issueSubCategory.id}>
                                      {issueSubCategory.issue_sub_category_name}
                                    </MenuItem>
                                  );
                                })}
                            </Select>
                            <FormHelperText sx={{ color: '#d32f2f' }}>
                              {touched.issue_sub_category_id && errors.issue_sub_category_id}
                            </FormHelperText>
                          </FormControl>
                        </Grid>

                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Issue Type</FormLabel>

                          <FormControl size="small" variant="outlined" fullWidth sx={{ mt: 1.5 }}>
                            <Select
                              // placeholder="Select Issue Type"
                              displayEmpty
                              inputProps={{
                                'aria-label': 'Without label',
                                readOnly: status || ifProxyToken
                              }}
                              // {...getFieldProps('issue_type_id')}
                              id="issue_type_id"
                              value={issueTypes?.id || ''}
                              onChange={(event) => {
                                const _issueType = issueType.filter(
                                  (pay) => event.target.value === pay.id
                                );
                                setIssueTypes(_issueType[0]);
                                // setPaymentLeg(event.target.value);
                                formik.setFieldValue('issue_type_id', event.target.value);
                                handleInterestDiscount(_issueType[0]);
                              }}
                              error={Boolean(touched.issue_type_id && errors.issue_type_id)}
                              helperText={touched.issue_type_id && errors.issue_type_id}
                            >
                              {tokenTypes?.map((issueType, index) => {
                                return (
                                  <MenuItem key={issueType.id} value={issueType.id}>
                                    {issueType.issue_type_name}
                                  </MenuItem>
                                );
                              })}
                            </Select>

                            <FormHelperText sx={{ color: '#d32f2f' }}>
                              {touched.issue_type_id && errors.issue_type_id}
                            </FormHelperText>
                          </FormControl>
                        </Grid>

                        {currentToken.deployment_address && (
                          <Grid item lg={6} md={6} sm={12} xs={12}>
                            <FormLabel>Contract Address</FormLabel>
                            <Grid container>
                              <Grid item lg={12} md={12} sm={12}>
                                <TextField
                                  sx={{ mt: 1.5 }}
                                  fullWidth
                                  size="small"
                                  autoComplete="off"
                                  type="text"
                                  value={ethToXdcAddress(currentToken.deployment_address)}
                                  // {...getFieldProps('contract_address')}
                                  InputProps={{
                                    readOnly: true,
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <AddressFieldTools
                                          address={currentToken.deployment_address}
                                          showAddress={false}
                                          symbol={currentToken.token_symbol}
                                          decimals={0}
                                          showInBlockExplorer
                                          showCopyButton
                                          showAddToWallet
                                        />
                                      </InputAdornment>
                                    )
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        )}

                        {/* <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: '30px' }}>
                          <FormGroup>
                            <FormControlLabel
                              control={<Checkbox checked={tokenById.allow_early_redemption} />}
                              label="Allow Early Redemption"
                              readOnly={status}
                              {...getFieldProps('allow_early_redemption')}
                              error={Boolean(
                                touched.allow_early_redemption && errors.allow_early_redemption
                              )}
                              helperText={
                                touched.allow_early_redemption && errors.allow_early_redemption
                              }
                            />
                          </FormGroup>
                        </Grid> */}
                      </Grid>
                    }
                  />
                  <AccordionLayout
                    defaultExpanded
                    title="Primary Economics Terms"
                    content={
                      <Grid container spacing={3} sx={{ width: '100%' }}>
                        {issueTypes.issue_calc_type === 'DISCOUNT' && (
                          <>
                            <Grid item lg={6} md={6} sm={12} xs={12}>
                              <FormLabel>Discount (% P.A)</FormLabel>
                              <TextField
                                sx={{ mt: 1.5 }}
                                // placeholder="5"
                                fullWidth
                                type="number"
                                size="small"
                                autoComplete="off"
                                {...getFieldProps('token_issue_desc_percent')}
                                id="token_issue_desc_percent"
                                onBlur={() => {
                                  const _issuePrice = handleInterestDiscount();
                                  handleSwapPriceRatio({ issuePrice: _issuePrice });
                                }}
                                error={Boolean(
                                  touched.token_issue_desc_percent &&
                                    errors.token_issue_desc_percent
                                )}
                                helperText={
                                  touched.token_issue_desc_percent &&
                                  errors.token_issue_desc_percent
                                }
                              />
                            </Grid>
                          </>
                        )}
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Tenure (Years)</FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            // placeholder="0"
                            fullWidth
                            size="small"
                            autoComplete="off"
                            type="number"
                            // inputProps={{readOnly: status}}
                            {...getFieldProps('tenure_months')}
                            id="tenure_months"
                            onBlur={() => {
                              handleTokenRedemptionValue();
                            }}
                            error={Boolean(touched.tenure_months && errors.tenure_months)}
                            helperText={touched.tenure_months && errors.tenure_months}
                          />
                        </Grid>
                        {(issueTypes.issue_calc_type !== 'NAV' ||
                          (issueTypes.issue_calc_type === 'NAV' &&
                            issueTypes.issue_type_name === 'Pool Investment') ||
                          (issueTypes.issue_calc_type === 'NAV' &&
                            issueTypes.issue_type_name === 'Project Finance')) && (
                          <Grid item lg={6} md={6} sm={12} xs={12}>
                            <FormLabel>Token Interest (% P.A)</FormLabel>
                            <TextField
                              sx={{ mt: 1.5 }}
                              // placeholder="5"
                              fullWidth
                              type="number"
                              size="small"
                              autoComplete="off"
                              {...getFieldProps('token_issue_interest_percent')}
                              id="token_issue_interest_percent"
                              onBlur={handleTokenRedemptionValue}
                              inputProps={{ readOnly: issueTypes.issue_calc_type === 'DISCOUNT' }}
                              error={Boolean(
                                touched.token_issue_interest_percent &&
                                  errors.token_issue_interest_percent
                              )}
                              helperText={
                                touched.token_issue_interest_percent &&
                                errors.token_issue_interest_percent
                              }
                            />
                          </Grid>
                        )}
                        {/* <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Bank Interest (% P.A)</FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            // placeholder="5"
                            fullWidth
                            type="number"
                            size="small"
                            autoComplete="off"
                            {...getFieldProps('bank_interest')}
                            id="bank_interest"
                            // onBlur={handleTokenValueChange}
                            inputProps={{ readOnly: issueTypes.issue_calc_type === 'DISCOUNT' }}
                            error={Boolean(touched.bank_interest && errors.bank_interest)}
                            helperText={touched.bank_interest && errors.bank_interest}
                          />
                        </Grid> */}
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Lock In Till</FormLabel>
                          <LocalizationProvider dateAdapter={DateAdapter}>
                            <DesktopDatePicker
                              readOnly={status}
                              fullWidth
                              size="small"
                              inputFormat="MM-dd-yyyy"
                              sx={{ mt: 1.5 }}
                              value={formik.values.lockin_till}
                              // onChange={finalMaturityDatehandleChange}
                              onChange={(newDate) => {
                                formik.setFieldValue('lockin_till', format(newDate, 'yyyy-MM-dd'));
                              }}
                              renderInput={(params) => (
                                <TextField
                                  sx={{ mt: 1.5 }}
                                  fullWidth
                                  size="small"
                                  {...params}
                                  {...getFieldProps('lockin_till')}
                                  error={Boolean(touched.lockin_till && errors.lockin_till)}
                                  helperText={touched.lockin_till && errors.lockin_till}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Contract Document</FormLabel>
                          <Grid
                            container
                            sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                          >
                            <Grid item lg={11} md={10} sm={12} xs={12}>
                              <TextField
                                sx={{ mt: 1.5 }}
                                fullWidth
                                size="small"
                                autoComplete="off"
                                type="file"
                                inputProps={{ disabled: status, accept: '.pdf' }}
                                inputRef={contractDoc}
                                {...getFieldProps('contract_doc_hash1')}
                                error={Boolean(
                                  touched.contract_doc_hash1 && errors.contract_doc_hash1
                                )}
                                helperText={touched.contract_doc_hash1 && errors.contract_doc_hash1}
                              />

                              <Grid container sx={{ width: '100%', mt: 2 }}>
                                <Grid item lg={4} md={4} sm={4}>
                                  <Typography
                                    sx={{
                                      fontSize: '0.688rem',
                                      textAlign: 'left',
                                      color: '#161c2d'
                                    }}
                                  >
                                    Maximum 20MB file size
                                  </Typography>
                                </Grid>
                                <Grid item lg={8} md={8} sm={8}>
                                  <Typography
                                    sx={{
                                      fontSize: '0.688rem',
                                      textAlign: 'right',
                                      color: '#8e8ea7'
                                    }}
                                  >
                                    Accepted File Type .pdf only
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item lg={1} md={2} sm={1} xs={1}>
                              <IconButton
                                sx={{ mt: -3 }}
                                aria-label="subs detail"
                                onClick={() => window.open(tokenById.contract_doc_hash, '_blank')}
                              >
                                <DownloadIcon
                                  sx={{ fontSize: '2.188rem', p: 1, backgroundColor: '#F5F8FA' }}
                                />
                              </IconButton>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Minimum Susbcription (Tokens)</FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            fullWidth
                            size="small"
                            type="number"
                            inputProps={{
                              readOnly: status
                            }}
                            autoComplete="off"
                            // placeholder="1"
                            {...getFieldProps('minimum_subscription')}
                            error={Boolean(
                              touched.minimum_subscription && errors.minimum_subscription
                            )}
                            helperText={touched.minimum_subscription && errors.minimum_subscription}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Token Issue Price {formik.values.issuer_currency}</FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            // placeholder="1000"
                            fullWidth
                            size="small"
                            inputProps={{
                              readOnly: status
                            }}
                            autoComplete="off"
                            type="number"
                            {...getFieldProps('token_issue_price')}
                            onBlur={() => {
                              handleSwapPriceRatio({});
                            }}
                            error={Boolean(touched.token_issue_price && errors.token_issue_price)}
                            helperText={touched.token_issue_price && errors.token_issue_price}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>
                            Token Redemption Price {formik.values.issuer_currency}
                          </FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            fullWidth
                            size="small"
                            type="number"
                            autoComplete="off"
                            inputProps={{
                              readOnly:
                                status ||
                                (issueTypes.issue_calc_type === 'NAV' &&
                                  issueTypes.issue_type_name === 'Pool Investment')
                            }}
                            {...getFieldProps('token_redemption_price')}
                            // value={formik.values.token_redemption_price}
                            onBlur={() => {
                              handleSwapPriceRatio({});
                            }}
                            error={Boolean(
                              touched.token_redemption_price && errors.token_redemption_price
                            )}
                            helperText={
                              touched.token_redemption_price && errors.token_redemption_price
                            }
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Issue Start Date</FormLabel>
                          <LocalizationProvider dateAdapter={DateAdapter}>
                            <DesktopDatePicker
                              fullWidth
                              size="small"
                              inputFormat="MM-dd-yyyy"
                              sx={{ mt: 1.5 }}
                              value={formik.values.token_create_date}
                              onChange={issueDatehandleChange}
                              renderInput={(params) => (
                                <TextField
                                  sx={{ mt: 1.5 }}
                                  onBlur={CalculateTenure}
                                  fullWidth
                                  size="small"
                                  {...params}
                                  {...getFieldProps('token_create_date')}
                                  error={Boolean(
                                    touched.token_create_date && errors.token_create_date
                                  )}
                                  helperText={touched.token_create_date && errors.token_create_date}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Issue Closure Date</FormLabel>
                          <LocalizationProvider dateAdapter={DateAdapter}>
                            <DesktopDatePicker
                              fullWidth
                              size="small"
                              inputFormat="MM-dd-yyyy"
                              sx={{ mt: 1.5 }}
                              value={formik.values.final_maturity_date}
                              onChange={finalMaturityDatehandleChange}
                              renderInput={(params) => (
                                <TextField
                                  onBlur={CalculateTenure}
                                  sx={{ mt: 1.5 }}
                                  fullWidth
                                  size="small"
                                  {...params}
                                  {...getFieldProps('final_maturity_date')}
                                  error={Boolean(
                                    touched.final_maturity_date && errors.final_maturity_date
                                  )}
                                  helperText={
                                    touched.final_maturity_date && errors.final_maturity_date
                                  }
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Interest Accrual Start Date</FormLabel>
                          <LocalizationProvider dateAdapter={DateAdapter}>
                            <DesktopDatePicker
                              readOnly
                              fullWidth
                              size="small"
                              inputFormat="MM-dd-yyyy"
                              sx={{ mt: 1.5 }}
                              value={formik.values.interest_accrual_start_date}
                              // onChange={finalMaturityDatehandleChange}
                              renderInput={(params) => (
                                <TextField
                                  onBlur={CalculateTenure}
                                  sx={{ mt: 1.5 }}
                                  fullWidth
                                  size="small"
                                  {...params}
                                  {...getFieldProps('interest_accrual_start_date')}
                                  error={Boolean(
                                    touched.interest_accrual_start_date &&
                                      errors.interest_accrual_start_date
                                  )}
                                  helperText={
                                    touched.interest_accrual_start_date &&
                                    errors.interest_accrual_start_date
                                  }
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </Grid>

                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Payment Frequency </FormLabel>
                          <FormControl size="small" variant="outlined" fullWidth sx={{ mt: 1.5 }}>
                            <Select
                              displayEmpty
                              inputProps={{ readOnly: readPaymentFrequency || status }}
                              {...getFieldProps('payment_frequency_id')}
                              error={Boolean(
                                touched.payment_frequency_id && errors.payment_frequency_id
                              )}
                              helperText={
                                touched.payment_frequency_id && errors.payment_frequency_id
                              }
                            >
                              {paymentFreq.map((payFreq) => {
                                return (
                                  <MenuItem key={payFreq.id} value={payFreq.id}>
                                    {payFreq.payment_frequency_name}
                                  </MenuItem>
                                );
                              })}
                            </Select>

                            <FormHelperText sx={{ color: '#d32f2f' }}>
                              {touched.payment_frequency_id && errors.payment_frequency_id}
                            </FormHelperText>
                          </FormControl>
                        </Grid>

                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Payment Basis </FormLabel>
                          <FormControl size="small" variant="outlined" fullWidth sx={{ mt: 1.5 }}>
                            <Select
                              displayEmpty
                              inputProps={{ readOnly: true }}
                              {...getFieldProps('payment_basis_id')}
                              error={Boolean(touched.payment_basis_id && errors.payment_basis_id)}
                              helperText={touched.payment_basis_id && errors.payment_basis_id}
                            >
                              {paymentBasis.map((payBasis) => {
                                return (
                                  <MenuItem key={payBasis.id} value={payBasis.id}>
                                    {payBasis.payment_basis_name}
                                  </MenuItem>
                                );
                              })}
                            </Select>

                            <FormHelperText sx={{ color: '#d32f2f' }}>
                              {touched.payment_basis_id && errors.payment_basis_id}
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Interest Type </FormLabel>
                          <FormControl size="small" variant="outlined" fullWidth sx={{ mt: 1.5 }}>
                            <Select
                              displayEmpty
                              inputProps={{
                                'aria-label': 'Without label',
                                readOnly: status || readInterestType
                              }}
                              {...getFieldProps('interest_type_id')}
                              error={Boolean(touched.interest_type_id && errors.interest_type_id)}
                              helperText={touched.interest_type_id && errors.interest_type_id}
                            >
                              {interestType.map((interest) => {
                                return (
                                  <MenuItem key={interest.id} value={interest.id}>
                                    {interest.interest_type_name}
                                  </MenuItem>
                                );
                              })}
                            </Select>

                            <FormHelperText sx={{ color: '#d32f2f' }}>
                              {touched.interest_type_id && errors.interest_type_id}
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Interest Calc. Method </FormLabel>
                          <FormControl size="small" variant="outlined" fullWidth sx={{ mt: 1.5 }}>
                            <Select
                              displayEmpty
                              inputProps={{ 'aria-label': 'Without label', readOnly: status }}
                              {...getFieldProps('interest_calc_method_id')}
                              error={Boolean(
                                touched.interest_calc_method_id && errors.interest_calc_method_id
                              )}
                              helperText={
                                touched.interest_calc_method_id && errors.interest_calc_method_id
                              }
                            >
                              {interestCalcMethod.map((intCal) => {
                                return (
                                  <MenuItem key={intCal.id} value={intCal.id}>
                                    {intCal.interest_calc_method_name}
                                  </MenuItem>
                                );
                              })}
                            </Select>

                            <FormHelperText sx={{ color: '#d32f2f' }}>
                              {touched.interest_calc_method_id && errors.interest_calc_method_id}
                            </FormHelperText>
                          </FormControl>
                        </Grid>

                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Accrual Frequency </FormLabel>
                          <FormControl size="small" variant="outlined" fullWidth sx={{ mt: 1.5 }}>
                            <Select
                              displayEmpty
                              inputProps={{ 'aria-label': 'Without label', readOnly: status }}
                              {...getFieldProps('accural_frequncy_id')}
                              error={Boolean(
                                touched.accural_frequncy_id && errors.accural_frequncy_id
                              )}
                              helperText={touched.accural_frequncy_id && errors.accural_frequncy_id}
                            >
                              {accrualFreq.map((accFreq) => {
                                return (
                                  <MenuItem key={accFreq.id} value={accFreq.id}>
                                    {accFreq.accrual_frequency_name}
                                  </MenuItem>
                                );
                              })}
                            </Select>

                            <FormHelperText sx={{ color: '#d32f2f' }}>
                              {touched.accural_frequncy_id && errors.accural_frequncy_id}
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Token NAV </FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            // placeholder="1000"
                            fullWidth
                            inputProps={{
                              readOnly: status
                            }}
                            size="small"
                            autoComplete="off"
                            type="number"
                            {...getFieldProps('token_nav')}
                            error={Boolean(touched.token_nav && errors.token_nav)}
                            helperText={touched.token_nav && errors.token_nav}
                          />
                        </Grid>
                        {/* TODO: Get file */}
                      </Grid>
                    }
                  />
                  <AccordionLayout
                    title="Settlement Terms"
                    content={
                      <Grid container spacing={3} sx={{ width: '100%' }}>
                        {/* Dummy Field for Settlement Account */}
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Settlement Account</FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            fullWidth
                            size="small"
                            autoComplete="off"
                            // placeholder="45"
                            type="text"
                            {...getFieldProps('xinfin_setl_account')}
                            id="xinfin_setl_account"
                            error={Boolean(
                              touched.xinfin_setl_account && errors.xinfin_setl_account
                            )}
                            helperText={touched.xinfin_setl_account && errors.xinfin_setl_account}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Settlement Cycle (T + Days)</FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            fullWidth
                            size="small"
                            autoComplete="off"
                            inputProps={{ 'aria-label': 'Without label', readOnly: status }}
                            // placeholder="45"
                            type="number"
                            {...getFieldProps('application_processing_time')}
                            error={Boolean(
                              touched.application_processing_time &&
                                errors.application_processing_time
                            )}
                            helperText={
                              touched.application_processing_time &&
                              errors.application_processing_time
                            }
                          />
                        </Grid>

                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Payment Leg </FormLabel>
                          <FormControl size="small" variant="outlined" fullWidth sx={{ mt: 1.5 }}>
                            <Select
                              displayEmpty
                              inputProps={{ 'aria-label': 'Without label', readOnly: status }}
                              // id="payment_token_id"
                              value={paymentLeg}
                              // {...getFieldProps('payment_leg_id')}
                              onChange={(event) => {
                                const _paymentLeg = paymentLegs.filter(
                                  (pay) => event.target.value === pay
                                );
                                setPaymentLeg(_paymentLeg[0]);
                                // setPaymentLeg(event.target.value);
                                formik.setFieldValue('payment_leg_id', event.target.value);
                              }}
                              error={Boolean(touched.payment_leg_id && errors.payment_leg_id)}
                              helperText={touched.payment_leg_id && errors.payment_leg_id}
                            >
                              {/* {paymentLegs.map((pay) => {
                                return (
                                  <MenuItem key={pay.id} value={pay}>
                                    {pay.payment_leg_name}
                                  </MenuItem>
                                );
                              })} */}
                              <MenuItem value={paymentLegs[0]}>
                                {paymentLegs[0].payment_leg_name}
                              </MenuItem>
                            </Select>
                            <FormHelperText sx={{ color: '#d32f2f' }}>
                              {touched.payment_leg_id && errors.payment_leg_id}
                            </FormHelperText>
                          </FormControl>
                        </Grid>

                        {values?.payment_leg_id?.payment_leg_name === 'Stable Coin' && (
                          <>
                            <Grid item lg={6} md={6} sm={12} xs={12}>
                              <FormLabel>Payment Token </FormLabel>
                              <FormControl
                                size="small"
                                variant="outlined"
                                fullWidth
                                sx={{ mt: 1.5 }}
                              >
                                <Select
                                  inputProps={{ 'aria-label': 'Without label', readOnly: status }}
                                  displayEmpty
                                  value={payment?.payment_token_id || ''}
                                  onChange={handlePaymentTokenChange}
                                  error={Boolean(
                                    touched.payment_token_id && errors.payment_token_id
                                  )}
                                  helperText={touched.payment_token_id && errors.payment_token_id}
                                >
                                  {paymentTokens.map((pay) => {
                                    return (
                                      <MenuItem
                                        key={pay.payment_token_id}
                                        value={pay.payment_token_id}
                                      >
                                        {pay.contract_name}
                                      </MenuItem>
                                    );
                                  })}
                                  <FormHelperText sx={{ color: '#d32f2f' }}>
                                    {touched.payment_token_id && errors.payment_token_id}
                                  </FormHelperText>
                                </Select>
                              </FormControl>
                            </Grid>

                            <Grid item lg={6} md={6} sm={12} xs={12}>
                              <FormLabel> Issuance Swap Ratio</FormLabel>
                              <TextField
                                sx={{ mt: 1.5 }}
                                // placeholder="10"
                                fullWidth
                                size="small"
                                autoComplete="off"
                                type="number"
                                {...getFieldProps('swap_ratio_issue')}
                                inputProps={{ readOnly: true }}
                              />
                            </Grid>

                            <Grid item lg={6} md={3} sm={12} xs={12}>
                              <FormLabel>Redemption Swap Ratio </FormLabel>
                              <TextField
                                sx={{ mt: 1.5 }}
                                // placeholder="10"
                                fullWidth
                                size="small"
                                autoComplete="off"
                                type="number"
                                {...getFieldProps('swap_ratio_redeem')}
                                inputProps={{ readOnly: true }}
                              />
                            </Grid>

                            <Grid item lg={12} md={6} sm={12} xs={12} sx={{ mt: '30px' }}>
                              <FormGroup>
                                <FormControlLabel
                                  disabled={status}
                                  control={
                                    <Checkbox
                                      checked={checkCollateral}
                                      onChange={(event) => {
                                        setIsCollateralized(event.target.checked);
                                        if (event.target.checked) {
                                          formik.setFieldValue('is_collateralized', true);
                                          setCheckCollateral(true);
                                        } else {
                                          formik.setFieldValue('is_collateralized', false);
                                          setCheckCollateral(false);
                                        }
                                      }}
                                    />
                                  }
                                  // {...getFieldProps('is_collateralized')}
                                  label="Collateralized"
                                  error={Boolean(
                                    touched.is_collateralized && errors.is_collateralized
                                  )}
                                  helperText={touched.is_collateralized && errors.is_collateralized}
                                />
                              </FormGroup>
                            </Grid>
                            {isCollateralized && (
                              <>
                                {/* <Grid item lg={6} md={6} sm={12} xs={12}>
                              <FormLabel>Collateral Token </FormLabel>
                              <FormControl
                                size="small"
                                variant="outlined"
                                fullWidth
                                sx={{ mt: 1.5 }}
                              >
                                <Select
                                  displayEmpty
                                  {...getFieldProps('collateral_token')}
                                  onBlur={handleCollateralPerToken}
                                  inputProps={{
                                    'aria-label': 'Without label',
                                    readOnly: status
                                  }}
                                >
                                  {collateralTokens.map((token, index) => {
                                    return (
                                      <MenuItem key={index} value={token.id}>
                                        {token.collateral_symbol}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                            </Grid> */}

                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                  <FormLabel>Collateral (Per Token)</FormLabel>
                                  <TextField
                                    sx={{ mt: 1.5 }}
                                    // placeholder="10"
                                    fullWidth
                                    size="small"
                                    autoComplete="off"
                                    type="number"
                                    inputProps={{ 'aria-label': 'Without label', readOnly: status }}
                                    {...getFieldProps('collateral_per_token')}
                                    error={Boolean(
                                      touched.collateral_per_token && errors.collateral_per_token
                                    )}
                                    helperText={
                                      touched.collateral_per_token && errors.collateral_per_token
                                    }
                                  />
                                </Grid>

                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                  <FormLabel>Collateral Provider</FormLabel>
                                  <FormControl
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                    sx={{ mt: 1.5 }}
                                  >
                                    <Select
                                      displayEmpty
                                      inputProps={{ 'aria-label': 'Without label' }}
                                      id="collateral_provider"
                                      value={collateralProviders.id}
                                      onChange={(event) => {
                                        const _collateral = collateralProvider.filter(
                                          (provider) => event.target.value === provider.id
                                        );
                                        setCollateralProviders(_collateral[0]);
                                        formik.setFieldValue(
                                          'collateral_provider',
                                          event.target.value
                                        );
                                      }}
                                      error={Boolean(
                                        touched.collateral_provider && errors.collateral_provider
                                      )}
                                      helperText={
                                        touched.collateral_provider && errors.collateral_provider
                                      }
                                    >
                                      {collateralProvider.map((provider) => {
                                        return (
                                          <MenuItem key={provider.id} value={provider.id}>
                                            {provider.collateral_provider_name}
                                          </MenuItem>
                                        );
                                      })}
                                    </Select>

                                    <FormHelperText sx={{ color: '#d32f2f' }}>
                                      {touched.collateral_provider && errors.collateral_provider}
                                    </FormHelperText>
                                  </FormControl>
                                </Grid>
                                {collateralProviders.collateral_provider_name !== '' && (
                                  <>
                                    {collateralProviders.collateral_provider_name === 'Issuer' && (
                                      <Grid item lg={6} md={6} sm={12} xs={12}>
                                        <FormLabel>Collateral Xinfin Address </FormLabel>
                                        <TextField
                                          sx={{ mt: 1.5 }}
                                          // placeholder="10"
                                          fullWidth
                                          size="small"
                                          autoComplete="off"
                                          type="text"
                                          value={
                                            entity &&
                                            (entity.primary_issuer_xinfin_address !== undefined ||
                                            entity.primary_issuer_xinfin_address !== ''
                                              ? ethToXdcAddress(
                                                  entity?.primary_issuer_xinfin_address
                                                )
                                              : '')
                                          }
                                          inputProps={{ readOnly: true }}
                                        />
                                      </Grid>
                                    )}
                                  </>
                                )}
                                {collateralProviders.collateral_provider_name !== '' && (
                                  <>
                                    {collateralProviders.collateral_provider_name === 'Insurer' && (
                                      <Grid item lg={6} md={6} sm={12} xs={12}>
                                        <FormLabel>Collateral Account</FormLabel>
                                        <FormControl
                                          size="small"
                                          variant="outlined"
                                          fullWidth
                                          sx={{ mt: 1.5 }}
                                        >
                                          <Select
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                            {...getFieldProps('collateral_provider_entity')}
                                            error={Boolean(
                                              touched.collateral_provider_entity &&
                                                errors.collateral_provider_entity
                                            )}
                                            helperText={
                                              touched.collateral_provider_entity &&
                                              errors.collateral_provider_entity
                                            }
                                            onChange={(event) => {
                                              const _insurer = issuerInvestorMapByIssuerId.filter(
                                                (insurer) =>
                                                  event.target.value === insurer.insurer_id
                                              );
                                              setCollateralAccount(
                                                _insurer[0].insurer_xinfin_address
                                              );
                                              formik.setFieldValue(
                                                'collateral_provider_entity',
                                                event.target.value
                                              );
                                            }}
                                          >
                                            {issuerInvestorMapByIssuerId.map((insurer) => {
                                              return (
                                                <MenuItem key={insurer} value={insurer.insurer_id}>
                                                  {insurer.insurer_name}
                                                </MenuItem>
                                              );
                                            })}
                                          </Select>

                                          {collateralProviders.collateral_provider_name ===
                                            'Insurer' &&
                                            issuerInvestorMapByIssuerId.length === 0 && (
                                              <FormHelperText sx={{ color: '#d32f2f' }}>
                                                No Insurer is mapped!
                                              </FormHelperText>
                                            )}
                                        </FormControl>
                                      </Grid>
                                    )}

                                    {collateralProviders.collateral_provider_name === 'Insurer' &&
                                      collateralAccount !== '' && (
                                        <>
                                          <Grid item lg={6} md={6} sm={12} xs={12}>
                                            <FormLabel>Collateral Xinfin Address </FormLabel>
                                            <TextField
                                              sx={{ mt: 1.5 }}
                                              // placeholder="10"
                                              fullWidth
                                              size="small"
                                              autoComplete="off"
                                              type="text"
                                              value={ethToXdcAddress(collateralAccount)}
                                              inputProps={{ readOnly: true }}
                                            />
                                          </Grid>
                                          <Grid item lg={6} md={6} sm={12} xs={12}>
                                            <FormLabel>Insurer Contribution</FormLabel>
                                            <TextField
                                              sx={{ mt: 1.5 }}
                                              fullWidth
                                              size="small"
                                              autoComplete="off"
                                              {...getFieldProps('insurer_contri')}
                                              type="number"
                                              inputProps={{
                                                'aria-label': 'Without label',
                                                readOnly: true
                                              }}
                                            />
                                          </Grid>
                                          <Grid item lg={6} md={6} sm={12} xs={12}>
                                            <FormLabel>Issuer Contribution</FormLabel>
                                            <TextField
                                              sx={{ mt: 1.5 }}
                                              fullWidth
                                              size="small"
                                              autoComplete="off"
                                              {...getFieldProps('issuer_contri')}
                                              type="number"
                                              inputProps={{
                                                'aria-label': 'Without label',
                                                readOnly: true
                                              }}
                                            />
                                          </Grid>
                                        </>
                                      )}
                                  </>
                                )}
                              </>
                            )}

                            {values.payment_token_id && (
                              <>
                                <Divider
                                  sx={{
                                    width: '100%',
                                    mt: 3
                                  }}
                                />

                                <Grid item lg={3} md={6} sm={12} xs={12}>
                                  <FormLabel>Name</FormLabel>
                                  <TextField
                                    sx={{ mt: 1.5 }}
                                    // placeholder="10"
                                    fullWidth
                                    size="small"
                                    autoComplete="off"
                                    type="text"
                                    value={payment?.contract_name}
                                    inputProps={{ readOnly: true }}
                                  />
                                </Grid>
                                <Grid item lg={3} md={6} sm={12} xs={12}>
                                  <FormLabel>Symbol</FormLabel>
                                  <TextField
                                    sx={{ mt: 1.5 }}
                                    // placeholder="10"
                                    fullWidth
                                    size="small"
                                    autoComplete="off"
                                    type="text"
                                    value={payment?.symbol}
                                    inputProps={{ readOnly: true }}
                                  />
                                </Grid>
                                <Grid item lg={3} md={6} sm={12} xs={12}>
                                  <FormLabel>Decimal</FormLabel>
                                  <TextField
                                    sx={{ mt: 1.5 }}
                                    // placeholder="10"
                                    fullWidth
                                    size="small"
                                    autoComplete="off"
                                    type="number"
                                    value={payment?.decimals}
                                    inputProps={{ readOnly: true }}
                                  />
                                </Grid>
                                <Grid item lg={3} md={6} sm={12} xs={12}>
                                  <FormLabel>PEG CCY</FormLabel>
                                  <TextField
                                    sx={{ mt: 1.5 }}
                                    // placeholder="10"
                                    fullWidth
                                    size="small"
                                    autoComplete="off"
                                    type="text"
                                    value={payment?.backed_currency}
                                    inputProps={{ readOnly: true }}
                                  />
                                </Grid>

                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                  <Grid container sx={{ width: '100%' }}>
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                      <FormLabel>Payment Token Address</FormLabel>
                                      <TextField
                                        sx={{ mt: 1.5 }}
                                        fullWidth
                                        size="small"
                                        autoComplete="off"
                                        type="text"
                                        value={
                                          payment?.contract_address &&
                                          ethToXdcAddress(payment.contract_address)
                                        }
                                        InputProps={{
                                          readOnly: true,
                                          endAdornment: (
                                            <InputAdornment position="end">
                                              <AddressFieldTools
                                                address={payment?.contract_address}
                                                showAddToWallet
                                                showCopyButton
                                                showInBlockExplorer
                                                showAddress={false}
                                                symbol={payment?.symbol}
                                                decimals={payment?.decimals}
                                              />
                                            </InputAdornment>
                                          )
                                        }}
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>

                                {values.token_type === 'POOL_LENDING' && (
                                  <Grid item lg={6} md={6} sm={12} xs={12}>
                                    <FormLabel>Liquidity Pool Balance</FormLabel>
                                    <TextField
                                      sx={{ mt: 1.5 }}
                                      // placeholder="10"
                                      fullWidth
                                      size="small"
                                      autoComplete="off"
                                      type="text"
                                      value={payment?.balance}
                                      inputProps={{ readOnly: true }}
                                    />
                                  </Grid>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </Grid>
                    }
                  />
                  {/* SAFI DETAILS BEGIN HERE */}
                  {values.issue_category_id === longTermBondCategoryId && (<AccordionLayout
                    title="Safi Details"
                    defaultExpanded
                    content={
                      <Grid container spacing={3} sx={{ width: '100%' }}>
                        {/* {JSON.stringify(currentToken)} */}
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Investment Vehicle Issuer</FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            fullWidth
                            // value={currentToken?.bond?.bond_issuer}
                            size="small"
                            autoComplete="off"
                            type="text"
                            {...getFieldProps('bond_issuer')}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Guarantor</FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            fullWidth
                            size="small"
                            autoComplete="off"
                            type="text"
                            // value={currentToken?.bond?.guarantor}
                            {...getFieldProps('guarantor')}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Announcement Date</FormLabel>
                          <LocalizationProvider dateAdapter={DateAdapter}>
                            <DesktopDatePicker
                              minDate={Date.now()}
                              fullWidth
                              size="small"
                              inputFormat="MM-dd-yyyy"
                              sx={{ mt: 1.5 }}
                              value={formik.values.announcement_date}
                              onChange={(newDate) => {
                                formik.setFieldValue(
                                  'announcement_date',
                                  format(newDate, 'yyyy-MM-dd')
                                );
                              }}
                              renderInput={(params) => (
                                <TextField
                                  sx={{ mt: 1.5 }}
                                  fullWidth
                                  size="small"
                                  {...params}
                                  {...getFieldProps('announcement_date')}
                                  id="announcement_date"
                                  error={Boolean(
                                    touched.announcement_date && errors.announcement_date
                                  )}
                                  helperText={touched.announcement_date && errors.announcement_date}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Issue Date</FormLabel>
                          <LocalizationProvider dateAdapter={DateAdapter}>
                            <DesktopDatePicker
                              minDate={Date.now()}
                              fullWidth
                              size="small"
                              inputFormat="MM-dd-yyyy"
                              sx={{ mt: 1.5 }}
                              value={formik.values.issue_date}
                              onChange={(newDate) => {
                                formik.setFieldValue('issue_date', format(newDate, 'yyyy-MM-dd'));
                              }}
                              renderInput={(params) => (
                                <TextField
                                  sx={{ mt: 1.5 }}
                                  fullWidth
                                  size="small"
                                  {...params}
                                  {...getFieldProps('issue_date')}
                                  id="issue_date"
                                  error={Boolean(touched.issue_date && errors.issue_date)}
                                  helperText={touched.issue_date && errors.issue_date}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Maturity Date</FormLabel>
                          <LocalizationProvider dateAdapter={DateAdapter}>
                            <DesktopDatePicker
                              minDate={Date.now()}
                              fullWidth
                              size="small"
                              inputFormat="MM-dd-yyyy"
                              sx={{ mt: 1.5 }}
                              value={formik.values?.maturity_date}
                              onChange={(newDate) => {
                                formik.setFieldValue(
                                  'maturity_date',
                                  format(newDate, 'yyyy-MM-dd')
                                );
                              }}
                              renderInput={(params) => (
                                <TextField
                                  sx={{ mt: 1.5 }}
                                  fullWidth
                                  size="small"
                                  {...params}
                                  {...getFieldProps('maturity_date')}
                                  id="maturity_date"
                                  error={Boolean(touched.maturity_date && errors.maturity_date)}
                                  helperText={touched.maturity_date && errors.maturity_date}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Years to Maturity/Next Call</FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            fullWidth
                            type="number"
                            size="small"
                            autoComplete="off"
                            // value={currentToken?.bond?.years_to_maturity_to_next_call}
                            {...getFieldProps('years_to_maturity_to_next_call')}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Issue / Reoffer Price</FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            fullWidth
                            size="small"
                            autoComplete="off"
                            type="number"
                            // value={currentToken?.bond?.issue_to_reoffer_price}
                            {...getFieldProps('issue_to_reoffer_price')}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Issue / Reoffer yield</FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            fullWidth
                            size="small"
                            autoComplete="off"
                            type="number"
                            // value={currentToken?.bond?.issue_to_reoffer_yield}
                            {...getFieldProps('issue_to_reoffer_yield')}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Coupon Type</FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            fullWidth
                            size="small"
                            autoComplete="off"
                            type="text"
                            // value={currentToken?.bond?.coupon_type}
                            {...getFieldProps('coupon_type')}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Annual Coupon Rate</FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            fullWidth
                            size="small"
                            autoComplete="off"
                            type="number"
                            {...getFieldProps('annual_coupon_rate')}
                            // value={parseFloat(formik.values.annual_coupon_rate)}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Coupon Frequency</FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            fullWidth
                            size="small"
                            autoComplete="off"
                            type="text"
                            // value={currentToken?.bond?.coupon_frequency}
                            {...getFieldProps('coupon_frequency')}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Security</FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            fullWidth
                            size="small"
                            autoComplete="off"
                            type="text"
                            // value={currentToken?.bond?.seniority}
                            {...getFieldProps('seniority')}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Exchange Listed</FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            fullWidth
                            size="small"
                            autoComplete="off"
                            type="text"
                            // value={currentToken?.bond?.exchange_listed}
                            {...getFieldProps('exchange_listed')}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Reference Rate (%)</FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            fullWidth
                            size="small"
                            autoComplete="off"
                            type="number"
                            // value={currentToken?.bond?.reference_rate}
                            {...getFieldProps('reference_rate')}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>ISIN</FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            fullWidth
                            size="small"
                            autoComplete="off"
                            type="text"
                            // value={currentToken?.bond?.isin}
                            {...getFieldProps('isin')}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>CUSIP</FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            fullWidth
                            size="small"
                            autoComplete="off"
                            type="text"
                            // value={currentToken?.bond?.cusip}
                            {...getFieldProps('cusip')}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Investment Currency</FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            fullWidth
                            size="small"
                            autoComplete="off"
                            type="text"
                            // value={currentToken?.bond?.bond_currency}
                            {...getFieldProps('bond_currency')}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Total Issue Size</FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            fullWidth
                            size="small"
                            autoComplete="off"
                            type="number"
                            // value={parseFloat(formik.values.total_issue_Size)}
                            {...getFieldProps('total_issue_Size')}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Min. Investment Quantity(Nominal)</FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            fullWidth
                            size="small"
                            autoComplete="off"
                            type="text"
                            // value={currentToken?.bond?.min_investment_quantity_nominal}
                            {...getFieldProps('min_investment_quantity_nominal')}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Incremental Quantity (Nominal)</FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            fullWidth
                            size="small"
                            autoComplete="off"
                            type="text"
                            // value={currentToken?.bond?.incremental_quantity_nominal}
                            {...getFieldProps('incremental_quantity_nominal')}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Investment Registration</FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            fullWidth
                            size="small"
                            autoComplete="off"
                            type="text"
                            // value={currentToken?.bond?.bond_registration}
                            {...getFieldProps('bond_registration')}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Investment Type</FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            fullWidth
                            size="small"
                            autoComplete="off"
                            type="text"
                            // value={currentToken?.bond?.bond_type}
                            {...getFieldProps('bond_type')}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Investment Sector</FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            fullWidth
                            size="small"
                            autoComplete="off"
                            type="text"
                            // value={currentToken?.bond?.bond_sector}
                            {...getFieldProps('bond_sector')}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Investment Sub Sector</FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            fullWidth
                            size="small"
                            autoComplete="off"
                            type="text"
                            // value={currentToken?.bond?.bond_sub_sector}
                            {...getFieldProps('bond_sub_sector')}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Issuer Credit Rating (S&P/Fitch)</FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            fullWidth
                            size="small"
                            autoComplete="off"
                            type="text"
                            // value={currentToken?.bond?.issuer_credit_rating}
                            {...getFieldProps('issuer_credit_rating')}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Bond Credit Rating (S&P/Fitch)</FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            fullWidth
                            size="small"
                            autoComplete="off"
                            type="text"
                            // value={currentToken?.bond?.bond_credit_rating}
                            {...getFieldProps('bond_credit_rating')}
                          />
                        </Grid>
                        {/* <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Shariah Compliant</FormLabel>
                          <FormControl size="small" variant="outlined" fullWidth sx={{ mt: 1.5 }}>
                            <Select
                              // placeholder="Select Currency"
                              // disabled
                              displayEmpty
                              // inputProps={{ 'aria-label': 'Without label' }}
                              inputProps={{
                                readOnly: status
                              }}
                              // {...getFieldProps('issuer_id')}
                              value={currentToken?.bond?.shariah_compliant ? 'True' : 'False'}
                              id="shariah_compliant"
                              error={Boolean(touched.shariah_compliant && errors.shariah_compliant)}
                              helperText={touched.shariah_compliant && errors.shariah_compliant}
                            >
                              <MenuItem value="True">Yes</MenuItem>
                              <MenuItem value="False">No</MenuItem>
                            </Select>
                            <FormHelperText sx={{ color: '#d32f2f' }}>
                              {touched.shariah_compliant && errors.shariah_compliant}
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Sukuk Investing</FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            fullWidth
                            size="small"
                            autoComplete="off"
                            type="text"
                            value={currentToken?.bond?.sukuk_investing}
                          />
                        </Grid> */}
                      </Grid>
                    }
                  />)}

                  {/* SAFI DETAILS END HERE */}

                  {/* <AccordionLayout
                    title="Other Highlight"
                    content={
                      <Grid container spacing={3} sx={{ width: '100%' }}> */}
                  {/* <Grid item lg={6} md={6} sm={12} xs={12} sx={{ pr: 1 }}>
                          <FormLabel>Originator</FormLabel>
                          <FormControl size="small" variant="outlined" fullWidth sx={{ mt: 1.5 }}>
                            <Select
                              displayEmpty
                              inputProps={{ 'aria-label': 'Without label', readOnly: status }}
                              onClick={() => {
                                if (originators.length === 0) {
                                  console.log('No originators found, Fething originators...');
                                  fetchOriginator();
                                }
                              }}
                              {...getFieldProps('originator_id')}
                              error={Boolean(touched.originator_id && errors.originator_id)}
                              helperText={touched.originator_id && errors.originator_id}
                            >
                              {originators.map((originator, val) => {
                                return (
                                  <MenuItem
                                    key={originator.originator_id}
                                    value={originator.originator_id}
                                  >
                                    {originator.originator_name}
                                  </MenuItem>
                                );
                              })}
                            </Select>

                            <FormHelperText sx={{ color: '#d32f2f' }}>
                              {touched.originator_id && errors.originator_id}
                            </FormHelperText>
                          </FormControl>
                        </Grid> */}
                  {/* <Grid item lg={12} md={12} sm={12} xs={12}>
                          <FormLabel>KYC Provider</FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            fullWidth
                            inputProps={{ readOnly: true }}
                            size="small"
                            autoComplete="off"
                            type="text"
                            value={entity?.kyc_provider_name}
                          />
                        </Grid> */}
                  {/* <Grid item lg={6} md={6} sm={12} xs={12} sx={{ pr: 1 }}>
                          <FormLabel>KYC Provider</FormLabel>
                          <FormControl size="small" variant="outlined" fullWidth sx={{ mt: 1.5 }}>
                            <Select
                              displayEmpty
                              inputProps={{ 'aria-label': 'Without label', readOnly: status }}
                              onClick={() => {
                                if (kycProviders.length === 0) {
                                  console.log('No kyc providers found, Fething kyc providers...');
                                  fetchKycProvider();
                                }
                              }}
                              {...getFieldProps('kyc_provider_id')}
                              error={Boolean(touched.kyc_provider_id && errors.kyc_provider_id)}
                              helperText={touched.kyc_provider_id && errors.kyc_provider_id}
                            >
                              {kycProviders.map((kycProvider, val) => {
                                return (
                                  <MenuItem
                                    key={kycProvider.kyc_provider_id}
                                    value={kycProvider.kyc_provider_id}
                                  >
                                    {kycProvider.kyc_provider_name}
                                  </MenuItem>
                                );
                              })}
                            </Select>

                            <FormHelperText sx={{ color: '#d32f2f' }}>
                              {touched.kyc_provider_id && errors.kyc_provider_id}
                            </FormHelperText>
                          </FormControl>
                        </Grid> */}

                  {/* <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Use of proceeds</FormLabel>
                          <TextField
                            // multiline="true"
                            rows="5"
                            sx={{ mt: 1.5 }}
                            fullWidth
                            size="small"
                            autoComplete="off"
                            inputProps={{
                              readOnly: status
                            }}
                            type="text"
                            {...getFieldProps('use_of_proceeds')}
                            error={Boolean(touched.use_of_proceeds && errors.use_of_proceeds)}
                            helperText={touched.use_of_proceeds && errors.use_of_proceeds}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Reference Assets</FormLabel>
                          <TextField
                            // multiline="true"
                            rows="5"
                            sx={{ mt: 1.5 }}
                            fullWidth
                            size="small"
                            autoComplete="off"
                            inputProps={{
                              readOnly: status
                            }}
                            type="text"
                            {...getFieldProps('reference_assets')}
                            error={Boolean(touched.reference_assets && errors.reference_assets)}
                            helperText={touched.reference_assets && errors.reference_assets}
                          />
                        </Grid>
                      </Grid>
                    }
                  /> */}

                  <Grid display="flex" justifyContent="flex-end">
                    {currentToken.token_status_id.token_status === 'NEW' && (
                      <>
                        <Button
                          sx={{
                            mt: 2,
                            borderColor: '#24ABDF',
                            px: 3,
                            mr: 1,
                            width: '7.75rem',
                            height: '2.5rem'
                          }}
                          variant="contained"
                          color="secondary"
                          onClick={() => {
                            navigate('/home/token-dashboard');
                          }}
                        >
                          Cancel
                        </Button>
                      </>
                    )}
                    {!status && (
                      <LoadingButton
                        sx={{
                          width: '7.5rem',
                          height: '2.5rem',
                          mt: 2
                        }}
                        disabled={status}
                        variant="gradient"
                        loadingPosition="start"
                        onClick={handleSubmit}
                      >
                        Save
                      </LoadingButton>
                    )}
                  </Grid>
                  <FocusError />
                </Form>
              </FormikProvider>
              {currentToken.token_status_id.token_status !== 'NEW' && (
                <>
                  <Box sx={{ mt: 2 }}>
                    <AccordionLayout
                      title="Token Updates"
                      content={<UpdateTokenTerms currencyRate={currencyRate} />}
                    />
                  </Box>
                </>
              )}
              {currentToken.token_status_id.token_status !== 'NEW' && (
                <Grid display="flex" justifyContent="flex-end">
                  <Button
                    sx={{
                      mt: 2,
                      borderColor: '#24ABDF',
                      px: 3,
                      mr: 1,
                      width: '7.75rem',
                      height: '2.5rem'
                    }}
                    variant="outlined"
                    color="error"
                    onClick={() => {
                      navigate('/home/token-dashboard');
                    }}
                  >
                    Cancel
                  </Button>
                </Grid>
              )}
            </CardContent>
          </Card>
        </Container>
      </Page>
    </>
  );
};
export default EditToken;
