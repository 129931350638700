import React, { useCallback, useEffect, useMemo, useState } from 'react';
import EnhancedTable from 'components/tables/EnhancedTable';
import {
  Box,
  IconButton,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  Alert,
  AlertTitle,
  Typography
} from '@mui/material';

import { useBusinessEntityState } from 'state/useBusinessEntityState';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { ethToXdcAddress, xdcToEthAddress } from 'helpers/web3';
import { useNavigate } from 'react-router';
import { useHttpApi, useWeb3, useAuthStateShared, useAppState, useSubscriptionState } from 'state';
import { useSnackbar } from 'notistack';
import { HeaderName } from 'helpers/tableHelper';
import TransactionHandler from 'components/TransactionHandler';
import { LoadingButton } from '@mui/lab';
import CopyAddress from 'components/CopyAddress';
import pools from 'mocks/Issuers.json';
import Image404 from 'assets/images/yplogo.png';

const PoolTable = () => {
  // TODO: Table Data API Data
  const navigate = useNavigate();
  const { ptTokens, fetchPTTokens } = useSubscriptionState();
  const { setSubsToken } = useBusinessEntityState();
  const { getTotalSupply } = useWeb3();
  const EditableCell = ({
    value: initialValue,
    row: { index, original },
    column: { id },
    updateMyData // This is a custom function that we supplied to our table instance
  }) => {
    // We need to keep and update the state of the cell normally
    const [value, setValue] = React.useState(0);

    // If the initialValue is changed external, sync it up with our state
    React.useEffect(() => {
      const fetchData = async () => {
        const totalSupply = await getTotalSupply(original.deployment_address);

        setValue(totalSupply);
      };
      fetchData();
    }, [original]);

    return `${value * original.token_nav} ${original.payment_token_id.symbol}`;
  };
  const headCells = useMemo(
    () => [
      {
        accessor: 'liquidity_pool.liquidity_pool_name',
        Header: 'Pool',
        show: true,
        width: 250,
        // OnClick need to be integrated
        Cell: ({ value, row: { original } }) => {
          return (
            <Box
              sx={{
                cursor: 'pointer',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center'
              }}
              onClick={() => {
                setSubsToken(original);
                navigate('/subscription/liquidity-pool-detail');
              }}
            >
              <Box
                component="img"
                src={original.liquidity_pool.liquidity_pool_logo}
                sx={{
                  borderRadius: '0.6rem',
                  height: '4rem',
                  width: '4rem',
                  objectFit: 'contain'
                  // bgcolor: '#18bfc5'
                }}
              />
              <Typography variant="body2" sx={{ ml: 2 }}>
                {value} - {original.token_name}
              </Typography>
            </Box>
          );
        }
      },
      {
        accessor: 'issue_size',
        Header: 'Issue Size',
        show: true,
        width: 125,
        // DENOMINATION REPLACEMENT SHOULD BE TAKEN CARE OF (VUSD)
        Cell: ({ value, row: { original } }) => (
          <Chip label={`${value} ${original.payment_token_id.symbol}`} color="success" />
        )
      },
      {
        accessor: 'LEI',
        Header: 'Total Issued',
        show: true,
        width: 125,
        Cell: EditableCell
      },
      //   {
      //     accessor: 'primary_issuer_xinfin_address',
      //     Header: 'Xinfin Address',
      //     width: 200,
      //     Cell: ({ value }) => {
      //       return <CopyAddress addr={ethToXdcAddress(value)} />;
      //     },
      //     show: true
      //   },

      {
        accessor: 'senior_apy',
        Header: 'APY %',
        show: true,
        width: 100,
        Cell: ({ value }) => {
          return `${value ?? 0} %`;
        }
      }
      //   {
      //     accessor: 'status',
      //     Header: 'Status',
      //     Cell: ({ row: { original } }) => {
      //       return (
      //         <IssuerChainStatus
      //           status={original.status}
      //           countryCode={original.issuer_country_code.country_code}
      //           address={original.primary_issuer_xinfin_address}
      //         />
      //       );
      //     },
      //     show: true
      //   },
      //   {
      //     accessor: 'actions',
      //     Header: 'Actions',
      //     Cell: ({ row: { original } }) => {
      //       return (
      //         <ActionButtons
      //           issuerId={original.issuer_id}
      //           issuerName={original.issuer_name}
      //           issuerStatus={original.status}
      //           issuerXInfin={original.primary_issuer_xinfin_address}
      //         />
      //       );
      //     },
      //     show: isOwner
      //   }
    ],
    []
  );

  return (
    <>
      <EnhancedTable
        tableTitle="Issuer"
        columns={headCells}
        refreshFunction={fetchPTTokens}
        data={ptTokens}
        // sx={{ background: '#f5f8fa', color: 'yellow', borderRadius: '0.6rem' }}
        // setData={setData}
        // updateMyData={updateMyData}
        // skipPageReset={skipPageReset}
      />
    </>
  );
};

export default PoolTable;
