import {
  Box,
  Chip,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
  Button,
  Modal
} from '@mui/material';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  useBusinessEntityState,
  useWeb3,
  useSubscriptionState,
  useAppState,
  useHttpApi
} from 'state';
import NavCalculation from 'components/masterData/LiquidityPool/NavCalculation';
import NavHistory from 'components/masterData/LiquidityPool/NavHistory';
import { ethToXdcAddress } from 'helpers/web3';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';

const PoolAssetOriginator = () => {
  const {
    currentSubsToken,
    fetchTokenNavHistoryPool,
    fetchPoolToken,
    setToken,
    setSubsToken,
    setCurrentTokenRuleDetails
  } = useBusinessEntityState();
  const { getRuleTemplateDetailedByToken } = useHttpApi();
  //   const { loggedInPool } = useBusinessEntityState();
  const navigate = useNavigate();
  useEffect(() => {
    fetchTokenNavHistoryPool(currentSubsToken.liquidity_pool.liquidity_pool_xinfin_address);
    fetchPoolToken(currentSubsToken.liquidity_pool.liquidity_pool_xinfin_address);
  }, [currentSubsToken]);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [isPortfolio, setIsPortfolio] = React.useState(false);
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 3,
    borderRadius: '0.5rem'
  };
  const { chainId, web3 } = useWeb3();

  return (
    <Container>
      <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 2 }}>
        Asset Originator Details
      </Typography>
      <Card sx={{ mt: 2 }}>
        <CardContent sx={{ p: 2 }}>
          <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
            {currentSubsToken.liquidity_pool.liquidity_pool_name}
          </Typography>
          <br />
          <Typography variant="p">{currentSubsToken.use_of_proceeds}</Typography>
          <br />
          <Typography variant="p">
            Issuer <strong>{currentSubsToken.issuer_id.issuer_name}</strong>
          </Typography>
          <br />
          <Box
            sx={{
              flexDirection: 'row',
              justifyContent: 'left',
              alignItems: 'center',
              display: 'flex'
            }}
          >
            <Typography variant="p">View On Block Explorer</Typography>
            <Chip
              sx={{ ml: 1 }}
              label="View Pool"
              onClick={() => {
                switch (chainId) {
                  case 50:
                    window.open(
                      `https://explorer.xinfin.network/address/${ethToXdcAddress(
                        currentSubsToken.liquidity_pool.liquidity_pool_xinfin_address
                      )}`,
                      '_blank'
                    );
                    break;
                  case 51:
                    window.open(
                      `https://explorer.apothem.network/address/${ethToXdcAddress(
                        currentSubsToken.liquidity_pool.liquidity_pool_xinfin_address
                      )}`,
                      '_blank'
                    );
                    break;
                  default:
                    window.open('https://explorer.xinfin.network/ ', '_blank');
                }
              }}
              icon={<TravelExploreIcon sx={{ fontSize: '1rem' }} />}
            />
            <Chip
              sx={{ ml: 1 }}
              label="View Token Details"
              onClick={async () => {
                setToken(currentSubsToken);
                setSubsToken(currentSubsToken);
                const response = await getRuleTemplateDetailedByToken(currentSubsToken.token_id);
                setCurrentTokenRuleDetails(response);
                navigate(`/subscription/proxy-token-details`);
                // switch (chainId) {
                //   case 50:
                //     window.open(
                //       `https://explorer.xinfin.network/address/${ethToXdcAddress(
                //         currentSubsToken.deployment_address
                //       )}`,
                //       '_blank'
                //     );
                //     break;
                //   case 51:
                //     window.open(
                //       `https://explorer.apothem.network/address/${ethToXdcAddress(
                //         currentSubsToken.deployment_address
                //       )}`,
                //       '_blank'
                //     );
                //     break;
                //   default:
                //     window.open('https://explorer.xinfin.network/ ', '_blank');
                // }
              }}
              icon={<TravelExploreIcon sx={{ fontSize: '1rem' }} />}
            />
            <Chip
              sx={{ ml: 1 }}
              label="View Token on Blocksscan"
              onClick={() => {
                switch (chainId) {
                  case 50:
                    window.open(
                      `https://explorer.xinfin.network/address/${ethToXdcAddress(
                        currentSubsToken.deployment_address
                      )}`,
                      '_blank'
                    );
                    break;
                  case 51:
                    window.open(
                      `https://explorer.apothem.network/address/${ethToXdcAddress(
                        currentSubsToken.deployment_address
                      )}`,
                      '_blank'
                    );
                    break;
                  default:
                    window.open('https://explorer.xinfin.network/ ', '_blank');
                }
              }}
              icon={<TravelExploreIcon sx={{ fontSize: '1rem' }} />}
            />
          </Box>
        </CardContent>
      </Card>
      <Box sx={{ mt: 2, float: 'right' }}>
        <br />
        <Grid>
          <Grid item xs={12}>
            <Button
              sx={{ m: 1 }}
              variant="contained"
              onClick={() => {
                setIsPortfolio(true);
                handleOpen();
              }}
            >
              View Portfolio
            </Button>
            <Button sx={{ m: 1 }} variant="contained" onClick={handleOpen}>
              NAV History
            </Button>
          </Grid>
        </Grid>
      </Box>

      <Modal
        open={open}
        onClose={() => {
          handleClose();
          setIsPortfolio(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {isPortfolio ? (
            <NavCalculation
              poolAddr={currentSubsToken.liquidity_pool.liquidity_pool_xinfin_address}
            />
          ) : (
            <NavHistory />
          )}
        </Box>
      </Modal>
    </Container>
  );
};

export default PoolAssetOriginator;
