import React, { useEffect, useState, useMemo } from 'react';
import EnhancedTable from 'components/tables/EnhancedTable';
import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  Alert,
  AlertTitle,
  IconButton,
  Box,
  Chip,
  DialogTitle,
  FormLabel,
  TextField
} from '@mui/material';
import { useBusinessEntityState } from 'state/useBusinessEntityState';
import { useAuthStateShared, useCoreTableState, useHttpApi, useWeb3, useAppState } from 'state';
import { LoadingButton } from '@mui/lab';
import Delete from '@mui/icons-material/Delete';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useSnackbar } from 'notistack';
import CopyAddress from 'components/CopyAddress';
import AddressFieldTools from 'components/AddressFieldTools';
import { useIsOwner, useIsLiquidityPoolOwner } from 'helpers/rbac';
import { parseTokenDecimal, parseTokenValue } from 'helpers/numbers';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { toChecksumAddress, xdcToEthAddress } from 'helpers/web3';

const ActionButtons = ({
  tokenAddress,
  tokenId,
  tokenName,
  tokenSymbol,
  tokenDecimals,
  poolAddr
}) => {
  const { deletePoolPaymentToken } = useHttpApi();
  const { poolRemovePaymentToken: removePaymentTokenBlocakchain, chainId } = useWeb3();
  const { enqueueSnackbar } = useSnackbar();
  const { throwErrorMessage } = useAppState();
  const { fetchPoolPaymentTokensByPoolAddr } = useCoreTableState();

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const [loadingState, setLoadingState] = useState(false);
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          m: 0,
          p: 0,
          alignItems: 'center'
        }}
      >
        <IconButton aria-label="subs detail" onClick={handleOpen}>
          <Delete sx={{ fontSize: '1.8rem', p: 0.7, backgroundColor: '#F5F8FA' }} />
        </IconButton>
        <AddressFieldTools
          showAddToWallet
          showInBlockExplorer
          showAddress={false}
          address={tokenAddress}
          symbol={tokenSymbol}
          decimals={tokenDecimals}
          withBackground
        />
      </Box>
      <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title">
        <Alert severity="warning">
          <AlertTitle>Delete Stable Coin</AlertTitle>
          <DialogContent>Are you sure you want to delete Stable Coin, {tokenName}?</DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleClose}
              sx={{ width: '6.7rem' }}
            >
              Cancel
            </Button>
            <LoadingButton
              variant="outlined"
              color="error"
              loadingPosition="start"
              sx={{ width: '6.7rem' }}
              loading={loadingState}
              onClick={async () => {
                try {
                  setLoadingState(true);
                  const _res = await deletePoolPaymentToken(tokenId);
                  console.log({ _res });
                  fetchPoolPaymentTokensByPoolAddr(poolAddr);
                  const _blockChainRes = await removePaymentTokenBlocakchain(
                    poolAddr,
                    tokenAddress
                  );
                  console.log({ _blockChainRes });
                  enqueueSnackbar('Payment Token deleted successfully', {
                    variant: 'success'
                  });
                } catch (e) {
                  throwErrorMessage(e);
                }
                setLoadingState(false);
                handleClose();
              }}
            >
              Delete
            </LoadingButton>
          </DialogActions>
        </Alert>
      </Dialog>
    </>
  );
};

// Withdraw Payement Token

// const WithdrawPaymentToken = ({ poolAddr, tokenDetails }) => {
//   const { poolWithdrawPaymentToken } = useWeb3();
//   const { enqueueSnackbar } = useSnackbar();
//   const { throwErrorMessage } = useAppState();
//   const [open, setOpen] = useState(false);
//   const handleClose = () => {
//     setOpen(false);
//   };
//   const handleOpen = () => {
//     setOpen(true);
//   };
//   const [loadingState, setLoadingState] = useState(false);

//   const withdrawPaymentTokenSchema = Yup.object().shape({
//     toAddress: Yup.string().required('Address is required'),
//     amount: Yup.number().required('Amount is required')
//   });

//   const formik = useFormik({
//     initialValues: {
//       toAddress: '',
//       amount: ''
//     },
//     validationSchema: withdrawPaymentTokenSchema,
//     onSubmit: async (values, { resetForm }) => {
//       console.log('🚀 ~ file: PoolPaymentTokenTable.js ~ line 153 ~ onSubmit: ~ values', values);
//       try {
//         setLoadingState(true);
//         const _amount = parseTokenDecimal(values.amount, tokenDetails.decimals);
//         console.log(
//           '🚀 ~ file: PoolPaymentTokenTable.js ~ line 158 ~ onSubmit: ~ _amount',
//           _amount
//         );
//         const _toAddress = toChecksumAddress(xdcToEthAddress(values.toAddress));
//         console.log(
//           '🚀 ~ file: PoolPaymentTokenTable.js ~ line 160 ~ onSubmit: ~ _toAddress',
//           _toAddress
//         );
//         const _res = await poolWithdrawPaymentToken(
//           poolAddr,
//           _toAddress,
//           tokenDetails.contract_address,
//           _amount
//         );
//         console.log(
//           '🚀 ~ file: PoolPaymentTokenTable.js ~ line 168 ~ onSubmit: ~ contract_address',
//           tokenDetails.contract_address
//         );
//         // console.log({ _res });
//         enqueueSnackbar('Payment Token withdrawn successfully', {
//           variant: 'success'
//         });
//       } catch (e) {
//         throwErrorMessage(e);
//       }
//       setLoadingState(false);
//       resetForm();
//       handleClose();
//     }
//   });

//   const { errors, touched, values, handleSubmit, getFieldProps } = formik;

//   return (
//     <>
//       <Button variant="contained" color="secondary" onClick={handleOpen} sx={{ width: '6.7rem' }}>
//         Withdraw
//       </Button>
//       <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title">
//         <Alert severity="warning">
//           <DialogTitle>Withdraw Payment Token </DialogTitle>
//           <DialogContent>
//             Payment Token - {tokenDetails.symbol}
//             <FormikProvider value={formik}>
//               <Form autoComplete="off" onSubmit={handleSubmit}>
//                 <Box sx={{ mt: 2 }}>
//                   <FormLabel sx={{ mt: 2 }}>To Address</FormLabel>
//                   <TextField
//                     sx={{ mt: 0.7 }}
//                     fullWidth
//                     size="small"
//                     autoComplete="off"
//                     type="text"
//                     // placeholder="Token Name"
//                     id="toAddress"
//                     {...getFieldProps('toAddress')}
//                     error={Boolean(touched.toAddress && errors.toAddress)}
//                     helperText={touched.toAddress && errors.toAddress}
//                   />
//                 </Box>
//                 <Box sx={{ mt: 1.5 }}>
//                   <FormLabel sx={{ mt: 1.5 }}>Amount</FormLabel>
//                   <TextField
//                     sx={{ mt: 0.7 }}
//                     fullWidth
//                     size="small"
//                     autoComplete="off"
//                     type="number"
//                     // placeholder="Token Name"
//                     id="amount"
//                     {...getFieldProps('amount')}
//                     error={Boolean(touched.amount && errors.amount)}
//                     helperText={touched.amount && errors.amount}
//                   />
//                 </Box>

//                 <DialogActions sx={{ mt: 2 }}>
//                   <Button
//                     variant="outlined"
//                     color="secondary"
//                     onClick={handleClose}
//                     sx={{ width: '6.7rem' }}
//                   >
//                     Cancel
//                   </Button>
//                   <LoadingButton
//                     variant="outlined"
//                     color="success"
//                     loadingPosition="start"
//                     sx={{ width: '6.7rem' }}
//                     loading={loadingState}
//                     type="submit"
//                   >
//                     Withdraw
//                   </LoadingButton>
//                 </DialogActions>
//               </Form>
//             </FormikProvider>
//           </DialogContent>
//         </Alert>
//       </Dialog>
//     </>
//   );
// };

export default function PoolPaymentTokenTable({ poolAddr }) {
  // const { tokens, fetchTokens } = useBusinessEntityState(); // fetch stable coin
  const {
    poolPaymentTokens: tokens,
    fetchPaymentTokens,
    fetchPoolPaymentTokensByPoolAddr
  } = useCoreTableState();

  const { getBalanceOf } = useWeb3();

  const [data, setData] = useState(tokens);
  const [skipPageReset, setSkipPageReset] = useState(false);

  console.log(tokens);
  /*  const [data, setData] = useState(tokens);

  const [skipPageReset, setSkipPageReset] = React.useState(false);
  const [owner, setOwner] = useState(false);

  const { role } = useAuthStateShared();

  useEffect(() => {
    if (role === 'OWNER') {
      setOwner(true);
    }
  }, [role]); */

  const fetchData = async () => {
    await fetchPoolPaymentTokensByPoolAddr(poolAddr);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const isOwner = useIsLiquidityPoolOwner();
  const headCells = useMemo(
    () => [
      {
        accessor: 'payment_token.contract_name',
        Header: 'Name',
        show: true
      },
      {
        accessor: 'payment_token.symbol',
        Header: 'Symbol',
        show: true
      },
      {
        accessor: 'payment_token.decimals',
        Header: 'Decimal',
        show: true,
        width: 100
      },
      {
        accessor: 'backed_currency',
        Header: 'Pegged CCY',
        show: true
      },
      {
        accessor: 'payment_token.contract_address',
        Header: 'Contract Address',
        show: true,
        width: 200,
        Cell: ({ value }) => <CopyAddress addr={value} />
      },
      {
        accessor: 'balance',
        Header: 'Balance',
        show: true
      },
      // {
      //   accessor: 'withdraw',
      //   Header: 'Withdraw',
      //   Cell: ({ row: { original } }) => (
      //     <WithdrawPaymentToken
      //       tokenId={original.id}
      //       tokenDetails={original.payment_token}
      //       poolAddr={poolAddr}
      //     />
      //   ),
      //   show: isOwner
      // },
      {
        accessor: 'actions',
        Header: 'Actions',
        Cell: ({ row: { original } }) => (
          <ActionButtons
            tokenId={original.id}
            tokenName={original.payment_token.contract_name}
            tokenSymbol={original.payment_token.symbol}
            tokenDecimals={original.payment_token.decimals}
            tokenAddress={original.payment_token.contract_address}
            poolAddr={poolAddr}
          />
        ),
        show: isOwner
      }
    ],
    [isOwner]
  );

  useEffect(() => {
    // TODO: Fetch Investors
    const _prepareRow = async () => {
      const _rows = await Promise.all(
        tokens.map(async (row) => {
          const balance = await getBalanceOf(row.payment_token.contract_address, poolAddr);

          console.log(balance);
          const _balance = parseTokenValue(balance, row.payment_token.decimals);

          row.balance = <Chip label={_balance} />;

          return row;
        })
      );
      setData(_rows);
    };
    _prepareRow();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokens]);
  const updateMyData = (rowIndex, columnId, value) => {
    setSkipPageReset(true);
    setData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value
          };
        }
        return row;
      })
    );
  };

  return (
    <>
      <EnhancedTable
        tableTitle="Token"
        columns={headCells}
        refreshFunction={fetchData}
        data={data}
        setData={setData}
        updateMyData={updateMyData}
        skipPageReset={skipPageReset}
      />
    </>
  );
}
