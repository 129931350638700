import { Chip } from '@mui/material';
import CopyAddress from 'components/CopyAddress';
import EnhancedTable from 'components/tables/EnhancedTable';
import { titleCase } from 'helpers/text';
import React, { useEffect, useMemo } from 'react';
import { useBusinessEntityState } from 'state';

const FeesTxnTable = ({ fetchData }) => {
  const { cryptoAssetTxnByIssuerInsurerXinfin } = useBusinessEntityState();

  const headCells = useMemo(
    () => [
      //   {
      //     accessor: 'sender_name',
      //     Header: senderName,
      //     show: true
      //   },
      //   {
      //     accessor: 'recipient_name',
      //     Header: recipientName,
      //     show: true
      //   },
      {
        accessor: 'sender_address.xinfin_account_address',
        Header: 'From',
        show: true,
        Cell: ({ value }) => {
          return <CopyAddress addr={value} />;
        }
      },
      {
        accessor: 'recipient_xinfin_address.xinfin_account_address',
        Header: 'To',
        show: true,
        Cell: ({ value }) => {
          return <CopyAddress addr={value} />;
        }
      },
      {
        accessor: 'transaction_hash',
        Header: 'Txn. Hash',
        width: 200,
        Cell: ({ value }) => {
          return <CopyAddress addr={value} ethAddr />;
        },
        show: true
      },

      {
        accessor: 'transaction_amount',
        Header: 'Amount',
        show: true
      },
      {
        accessor: 'transaction_type',
        Header: 'Txn. Type',
        show: true,
        Cell: ({ value }) => <Chip label={titleCase(value)} color="success" />
      },
      {
        accessor: 'payment_date',
        Header: 'Payment Date',
        show: true
      }
    ],
    []
  );

  return (
    <>
      <EnhancedTable
        tableTitle="Fees Txn"
        columns={headCells}
        refreshFunction={fetchData}
        data={cryptoAssetTxnByIssuerInsurerXinfin}
        // setData={setData}
        // updateMyData={updateMyData}
        // skipPageReset={skipPageReset}
      />
    </>
  );
};

export default FeesTxnTable;
